<div class="flex flex-row justify-between px-6 w-full py-2 mb-4 bg-cdocbanner">
    <div class="flex flex-col">
        <span class="text-3xl text-white">cDoc {{"SUPPORT" | translate}}</span>
        <span class="text-white">Telefon: 035-10 81 89<br>E-mail: support@cdoc.se</span>
    </div>
    <div class="flex flex-row">
        <span class="text-xl text-white" id="version">Version: 4.2.8</span>
    </div>
</div>

<div class="container px-4">
    <ul class="flex mb-2">
      <li class="mr-1">
          <a (click)="showHelp()" [class.active]="isHelp"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"HELP" | translate}}</a>
      </li>
      <li class="mr-1">
        <a (click)="showDocument()" [class.active]="isDocument"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DOCUMENTS" | translate}}</a>
      </li>
      <li class="mr-1">
        <a (click)="showVideo()" [class.active]="isVideo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"VIDEOS" | translate}}</a>
      </li>
    </ul>
</div>

<div class="sm:mr-8" *ngIf="isHelp">
    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-row">
            <div class="w-1/6 flex justify-center align-top mt-2">
                <img title="{{ 'PRODUCTS' | translate }}" src="images/icons/flask-chemical-svgrepo-com.svg" class="w-10 h-10">
            </div>
            <div class="w-5/6 text-left">
                <span class="text-xl font-bold">{{"PRODUCTS" | translate}}</span><br>
                {{"PRODUCT_REGISTRY_PHRASE1" | translate}}.
                {{"PRODUCT_REGISTRY_PHRASE2" | translate}}.
                {{"PRODUCT_REGISTRY_PHRASE3" | translate}}.
            </div>
        </div>
    </div>

    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-row">
            <div class="w-1/6 flex justify-center align-top mt-2">
                <img title="{{ 'RISK_ASSESSMENTS' | translate }}" src="images/icons/caution-risk-svgrepo-com.svg" class="w-10 h-10">
            </div>
            <div class="w-5/6 text-left">
                <span class="text-xl font-bold">{{"RISK_ASSESSMENTS" | translate}}</span><br>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE1" | translate}}</p>
                <p class="mt-4 font-bold">{{"RISK_ASSESSMENTS_HELP_PHRASE2" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE3" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE4" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE5" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE6" | translate}}</p>
                <p class="mt-4 font-bold">{{"RISK_ASSESSMENTS_HELP_PHRASE7" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE8" | translate}}</p>
                <p>{{"RISK_ASSESSMENTS_HELP_PHRASE9" | translate}}</p>
            </div>
        </div>
    </div>

    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-row">
            <div class="w-1/6 flex justify-center align-top mt-2">
                <img title="{{ 'CHEMICAL_MANAGEMENT' | translate }}" src="images/icons/administration-calendar-calendars-svgrepo-com.svg" class="w-10 h-10">
            </div>
            <div class="w-5/6 text-left">
                <span class="text-xl font-bold">{{"CHEMICAL_MANAGEMENT" | translate}}</span><br>
                <p>{{"CHEMICAL_MANAGEMENT_HELP_PHRASE1" | translate}}</p>
                <p class="mt-4 font-bold">{{"SUBSTITUTION" | translate}}</p>
                <p>{{"SUBSTITUTION_HELP_PHRASE1" | translate}}</p>
                <p class="mt-4 font-bold">{{"COORDINATOR" | translate}}</p>
                <p>{{"COORDINATOR_HELP_PHRASE1" | translate}}.</p>
                <p class="mt-4 font-bold">{{"EXPOSURE_LIST" | translate}}</p>
                <p>{{"EXPOSURE_LIST_HELP_PHRASE1" | translate}}.</p>
                <p class="mt-4 font-bold">{{"HISTORY" | translate}}</p>
                <p>{{"HISTORY_HELP_PHRASE1" | translate}}.</p>
            </div>
        </div>
    </div>

    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-row">
            <div class="w-1/6 flex justify-center align-top mt-2">
                <img title="{{ 'REPORTS' | translate }}" src="images/icons/report-svgrepo-com.svg" class="w-10 h-10">
            </div>
            <div class="w-5/6 text-left">
                <span class="text-xl font-bold">{{"REPORTS" | translate}}</span><br>
                <p class="font-bold mt-4">{{"REPORT_SIMPLIFIED" | translate}}</p>
                <p>{{"REPORT_SIMPLIFIED_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_STATUTORY" | translate}}</p>
                <p>{{"REPORT_STATUTORY_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_FIRE" | translate}}</p>
                <p>{{"REPORT_FIRE_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_NOTE" | translate}}</p>
                <p>{{"REPORT_NOTE_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_ENVIROMENT" | translate}}</p>
                <p>{{"REPORT_ENVIROMENT_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_SDS" | translate}}</p>
                <p>{{"REPORT_SDS_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"GENERAL_RISK_ASSESSMENT" | translate}}</p>
                <p>{{"GENERAL_RISK_ASSESSMENT_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"CLASSIFICATION" | translate}}</p>
                <p>{{"CLASSIFICATION_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_SLL" | translate}}</p>
                <p>{{"REPORT_SLL_HELP_PHRASE1" | translate}}.</p>
                <p class="font-bold mt-4">{{"REPORT_K_SIMPLIFIED" | translate}}</p>
                <p>{{"REPORT_SIMPLIFIED_HELP_PHRASE1" | translate}}.</p>
            </div>
        </div>
    </div>

    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-20">
        <div class="flex flex-row">
            <div class="w-1/6 flex justify-center align-top mt-2">
                <img title="{{ 'SETTINGS' | translate }}" src="images/icons/gears-cog-svgrepo-com.svg" class="w-10 h-10">
            </div>
            <div class="w-5/6 text-left">
                <span class="text-xl font-bold">{{"SETTINGS" | translate}}</span><br>
                <p>{{"SETTINGS_HELP_PHRASE1" | translate}}</p>
                <p class="font-bold mt-4">{{"SETTINGS_HELP_PHRASE2" | translate}}</p>
                <p>{{"SETTINGS_HELP_PHRASE3" | translate}}</p><br>
                <p>{{"SETTINGS_HELP_PHRASE4" | translate}}</p><br>
                <p>{{"SETTINGS_HELP_PHRASE5" | translate}}</p><br>
                <p>{{"SETTINGS_HELP_PHRASE6" | translate}}</p><br>
                <p>{{"SETTINGS_HELP_PHRASE7" | translate}}</p><br>
                <p>{{"SETTINGS_HELP_PHRASE8" | translate}}</p><br>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isDocument">
    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-10">
        <div *ngIf="currentFiles?.length > 0" class="eight wide column">
            <span *ngFor="let file of currentFiles">
                <div class="flex flex-row items-center px-7 py-1">
                    <a (click)="openDocumentInNewWindow(file.id)">
                        <img class="w-6 h-6 cursor-pointer" title="Substitution" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> 
                    </a>
                    <p>{{file.filename}}</p>
                    <a *ngIf="currentUser.is_super_user" class="ml-4">
                        <i aria-hidden="true" (click)="removeDocument(file.id)">
                            <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </i> 
                    </a>
                </div>
            </span>
        </div>
        <div *ngIf="currentUser.is_super_user" class="mt-4 px-7">
            <div class="flex flex-row items-center">
                <input title="{{'ATTACHE_FILES' | translate}}" class="button-white border border-gray-300 mr-1" type="file" id="upload-file" accept="application/pdf" (change)="selectFile($event)" />
                <select class="border border-gray-300 rounded px-4 py-3 mr-4" [(ngModel)]="selectedLanguage" title="Select Language">
                    <option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</option>
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="currentUser.is_super_user" class="flex flex-row justify-center mb-10">
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="savecDocInfo()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
    </div>
</div>

<div *ngIf="isVideo" class="video-grid-container px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-20">
    <div class="video-grid-item">
        <label class="text-lg font-bold">{{"VIDEO_INTRO_PART_1" | translate}}</label>
        <iframe 
            src="https://player.vimeo.com/video/954791240" 
            width="640" 
            height="360" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture"
            title="cDoc - {{'VIDEO_INTRO_PART_1' | translate}}"
            allowfullscreen>
        </iframe>
    </div>
    <div class="video-grid-item">
        <label class="text-lg font-bold">{{"VIDEO_INTRO_PART_2" | translate}}</label>
        <iframe 
            src="https://player.vimeo.com/video/979053508" 
            width="640" 
            height="360" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture"
            title="cDoc - {{'VIDEO_INTRO_PART_2' | translate}}"
            allowfullscreen>
        </iframe>
    </div>
    <div class="video-grid-item">
        <label class="text-lg font-bold">{{"ADD_PRODUCTS" | translate}}</label>
        <iframe 
            src="https://player.vimeo.com/video/1019689004" 
            width="640" 
            height="360" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture"
            title="cDoc - {{'ADD_PRODUCTS' | translate}}"
            allowfullscreen>
        </iframe>
    </div>
</div>

