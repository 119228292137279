import { Component, OnInit, Optional, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import { AddProductComponent } from 'src/app/products/add-product/add-product.component';
import { UtilService } from 'src/app/services/util.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SareqService } from 'src/app/services/sareq.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-sareq-search',
  templateUrl: './sareq-search.component.html',
  styleUrls: ['./sareq-search.component.css']
})
export class SareqSearchComponent {

  @Output() dataOutput: EventEmitter<any> = new EventEmitter<any>();
  @Input() productInput: any;

  isInAddProductComponent: boolean = false;

  productSearch = {
    all:"",
    name: "",
    com_use: "",
    supplier_name: "",
    supplier_article_number: ""
  };

  currentUser: any;
  products: any = [];
  sareqProducts: any = [];
  sareqOverride: any = [];
  unitArray: any = [];
  itemsPerPage:number=10;
  pageNumber:number=1;
  loadingData: boolean=false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  searchAll:boolean=false;
  searchPageNumber0=1;
  numberOfItems0  = 10;
  numberOfItems1  = 10;
  orderBy = "";
  totalAmount;
  order=['asc','asc','asc','asc','asc','asc','asc'];

  constructor( private utilService: UtilService,
    private sareqService: SareqService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    @Optional() private addProductComponent: AddProductComponent ) {this.isInAddProductComponent = !!addProductComponent; }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productInput']) {
      if (this.productInput != "")
        {
          this.productSearch.name = this.productInput;
          this.searchProducts('','')
        }
    }
  }

  ngOnInit(): void {
    if (this.isInAddProductComponent) {
      this.unitArray = [10,25,100,1000,'alla'];
      if ( localStorage.getItem('addProductComponentItemNumber') != null ) {
        this.numberOfItems0 = parseInt(localStorage.getItem('addProductComponentItemNumber'));
        this.numberOfItems1 = parseInt(localStorage.getItem('addProductComponentItemNumber'));
        this.itemsPerPage = this.numberOfItems1
      } else {
        this.numberOfItems0  = 10;
        this.numberOfItems1  = 10;
        this.itemsPerPage=10;
      }
    } else {
      this.unitArray = [10,25,100,1000,'alla'];
      this.itemsPerPage=10;
      this.numberOfItems0  = 10;
      this.numberOfItems1  = 10;
    }
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
    {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }
  }

  logout() {
    this.authService.logout();
  }

  setItems(unit) {
    this.numberOfItems0 = unit;
    if(unit == "alla"){
      this.numberOfItems1 = 0;
      this.itemsPerPage = null;
    } else {
      this.numberOfItems1 = unit;
      this.itemsPerPage = unit;
    }
    if (this.isInAddProductComponent) {
      localStorage.setItem('addProductComponentItemNumber', this.numberOfItems1.toString());
    }
  }

  getAmount(item){
    if(item=='1'){
      var amount = this.numberOfItems0*this.searchPageNumber0;
      if(amount<this.totalAmount){
        return amount;
      } else {
        return this.totalAmount;
      }
    }
  }

  sortItem(type,id){
    if(this.order[id]=='asc'){
      this.order[id]='desc';
    } else if(this.order[id]=='desc'){
      this.order[id]='asc';
    }

   this.products = _.orderBy(
    this.products,
    [(item) => typeof item[type] === "string" ? item[type].toLowerCase() : item[type]],
    [this.order[id]]
  );
  }

  onKeyTabbEvent(id): void {
    if(id=='button-searchproduct'){
      setTimeout(()=>{let element=document.getElementById('productAddSearchname');element.focus();},100);
    }
  }

  changeArticleSearch(){
    for (var i = 0; i < this.productSearch.supplier_article_number.length; i++)
    {
      this.productSearch.supplier_article_number = this.productSearch.supplier_article_number.replace(' ', '');
    }

    this.filterSearchItems();
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  addProduct(product) {
    this.dataOutput.emit(product);
  }

  openSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: (pdfContent) => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_SDS"),
            this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_SDS"),
          this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  openGrbInNewWindow(product) {
    const filename = product.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_GRB"),
            this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_GRB"),
          this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

searchProducts( tabb, orderby ){
  this.searchAll = false;
  if ( orderby == '' && tabb == '' )
  {
    this.orderBy = '';
  }
  else if(orderby=='' && tabb=='Prev'||tabb=='Next') {

  } else {
    this.orderBy = orderby;
  }

  this.loadingData = true;
  if(tabb==''||tabb=='Prev'||tabb=='Next'){
    if(tabb=='Prev'){this.searchPageNumber0--;}
    if(tabb=='Next'){this.searchPageNumber0++;}
    if(tabb==''){this.searchPageNumber0=1;}
    if(!this.numberOfItems0){
      this.numberOfItems0=100;
    }
    var info = {
      'name': encodeURIComponent(this.productSearch.name==null?'':this.productSearch.name),
      'manufacturer': encodeURIComponent(this.productSearch.supplier_name==null?'':this.productSearch.supplier_name),
      'articlenumber': encodeURIComponent(this.productSearch.supplier_article_number==null?'':this.productSearch.supplier_article_number),
      'orderBy': this.orderBy,
      'limit': this.numberOfItems0,
      'page': this.searchPageNumber0,
      'status': 'allproductsWithNoOverrideAndHidden',
      'organisation': this.selectedOrganisation.id
    };
  }
  this.sareqProducts = [];
  this.sareqOverride = [];
  this.products = [];

  this.sareqService.getAllAndFilter(info);
  this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
    next: sareqProducts => {
      if(!sareqProducts||sareqProducts==null){
        this.loadingData = false;
    } else {
      if(sareqProducts.count){
        this.totalAmount = sareqProducts.count;
      }
      this.sareqProducts = sareqProducts.sareqProducts;
      this.sareqOverride = sareqProducts.sareqOverride;
      if (this.sareqProducts.some(product => 'sareq_number' in product)) {
        this.products = this.sareqProducts.reduce((unique, product) => {
          return product.sareq_number && unique.findIndex(item => item.sareq_number === product.sareq_number) < 0 ? [...unique, product] : unique;
        }, []);
      } else {
        this.products = this.sareqProducts;
      }

      this.loadingData = false;
        setTimeout( () => {
          let element=document.getElementById('productAddSearchname');
            element.focus();
        },100)
      }
    },
    error: error => {
      Swal.fire("Error excel", "Error", "error");
      this.loadingData = false;
    }
  })
}

  filterSearchItems(){
    this.products=this.sareqProducts;
    if(this.productSearch.all==''){
      this.productSearch.all=null;
    }
    if(this.productSearch.name==''){
      this.productSearch.name=null;
    }
    if(this.productSearch.com_use==''){
      this.productSearch.com_use=null;
    }
    if(this.productSearch.supplier_name==''){
      this.productSearch.supplier_name=null;
    }
    if(this.productSearch.supplier_article_number==''){
      this.productSearch.supplier_article_number=null;
    }
    if(this.productSearch.all!=null){
      this.products=this.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productSearch.all.toLowerCase())>-1);
    }
    if(this.productSearch.name!=null){
      this.products=this.products.filter(o => o.name?.toLowerCase().indexOf(this.productSearch.name.toLowerCase())>-1);
    }
    if(this.productSearch.com_use!=null){
      this.products=this.products.filter(o => o.com_use?.toLowerCase().indexOf(this.productSearch.com_use.toLowerCase())>-1);
    }
    if(this.productSearch.supplier_name!=null){
      this.products=this.products.filter(o => o.supplier_name?.toLowerCase().indexOf(this.productSearch.supplier_name.toLowerCase())>-1);
    }
    if(this.productSearch.supplier_article_number!=null){
      this.products=this.products.filter(o => o.supplier_article_number?.toString().toLowerCase().indexOf(this.productSearch.supplier_article_number.toLowerCase())>-1);
    }
  }

  openExtSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_SDS"),
            this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_SDS"),
          this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
          "error"
        );
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  getOverrideValues(id) {
    const items = this.sareqOverride.filter(item => item.override === id);
    if (items.length > 0) {
      return items.map(item => ({ name: item.name, lang: item.lang }));
    }
    return null;
  }
}
