import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { AuthService } from '../auth/auth.service';
import { ProductService } from "src/app/services/product.service";
import { CoordinatorService } from "src/app/services/coordinator.service";
import { SubstitutionService } from "src/app/services/substitution.service";
import { HeaderComponent } from "src/app/header/header.component";
import { AssessmentChemicalService } from "src/app/services/assessment-chemical.service";
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {

  ngAfterViewInit(): void {
    $(document).on('click', '[id^="pictogram"]' , function() {
      var text = $(this).attr('id');
      var searchFilter = [];
      switch(text) {
        case 'pictogramclp_acid-tiny':
          searchFilter[0]="clp_is_corrosive";
          break;
        case 'pictogramclp_bottle-tiny':
          searchFilter[0]="clp_is_gas_under_pressure";
          break;
        case 'pictogramclp_exclamation-tiny':
          searchFilter[0]="clp_is_caution";
          break;
        case 'pictogramclp_explosion-tiny':
          searchFilter[0]="clp_is_explosive";
          break;
        case 'pictogramclp_flame-tiny':
          searchFilter[0]="clp_is_flammable";
          break;
        case 'pictogramclp_pollution-tiny':
          searchFilter[0]="clp_is_dangerous_to_the_environment";
          break;
        case 'pictogramclp_roundflame-tiny':
          searchFilter[0]="clp_is_oxidising";
          break;
        case 'pictogramclp_silhouete-tiny':
          searchFilter[0]="clp_is_longer_term_health_hazards";
          break;
        case 'pictogramclp_skull-tiny':
          searchFilter[0]="clp_is_toxic";
          break;
        default:
          return false;
      }

      searchFilter[1]=1;
      localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
      window.location.href='#/productlist';
  });

  $(document).on('click','[id^="cmra"]',function() {
    var text = $(this).attr('id');
    switch(text) {
      case 'cmraCancerogent':
        localStorage.setItem('CMRASearch', JSON.stringify('C'));
        break;
      case 'cmraMutagent':
        localStorage.setItem('CMRASearch', JSON.stringify('M'));
        break;
      case 'cmraReproduktionshämmande':
        localStorage.setItem('CMRASearch', JSON.stringify('R'));
        break;
      case 'cmraAllergiskt':
        localStorage.setItem('CMRASearch', JSON.stringify('A'));
        break;
      default:
        return false;
    }
    window.location.href='#/productlist';
  });
  if(this.subscribesToSpecialDemands){
    $(document).on('click','[id^="specialdemands"]',function() {
      var text = $(this).attr('id');
      var searchFilter = [];
      switch(text) {
        case 'specialdemands0':
          searchFilter[0] = 0;
          break;
        case 'specialdemands1':
          searchFilter[0] = 1;
          break;
        case 'specialdemands2':
          searchFilter[0] = 2;
          break;
        case 'specialdemands3':
          searchFilter[0] = 3;
          break;
        case 'specialdemands4':
          searchFilter[0] = 4;
          break;
        case 'specialdemands5':
          searchFilter[0] = 5;
          break;
        default:
          return false;
      }
      searchFilter[1]=0;
      localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
      window.location.href='#/productlist';
    });
  }
}

  loadingData: boolean = true;
  loadingChangedProducts: boolean = false;
  showTour: boolean = false;
  currentNews: any[];
  tour: any;
  subscribesToCatalogue: boolean;
  subscribesToProducts: boolean;
  cataloguesCount: any;
  organisation: any;
  currentUser: any;
  catalogues: any;
  productsCount: any;
  styrenProductsCount: any;
  products: any;
  nbrProductMaxAmount: any;
  nbrProductAmountLargerMax: any;
  subscribesToCoordinator: any;
  coordinatorCount: any;
  coordinatorCountOngoing: any;
  coordinatorCountDone: any;
  currentCompanyFiles: any;
  subscribesToRiskAssessment: any;
  productsUniqueOwnRB: any;
  productsOwnRB: any;
  itemstoold: any;
  productsOwnRBDone: any;
  productsOwnRBOngoing: any;
  productsOwnARB: any;
  itemstooldARB: any;
  productsOwnARBDone: any;
  productsOwnARBOngoing: any;
  productsUniqueOwnARB: any;
  selectedOrganisation: any;
  selectedDepartment: any;
  currentDepartmentFiles: any;
  groupInfo: any;
  restrictionListsCount: any;
  restriktionList: any;
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToSpecialDemands: any;
  subscribesToReportEnvironment: any;
  subscribesToSubstitution: boolean;
  subscribesToFreemium: boolean;
  subscribesToStyren: boolean;
  inventoryhistory: any;
  includedEcoLabels: any;
  historyYear:any;
  historyDate:any;
  historyProductGroup:any;
  editIncludedEcoLabels2: boolean=false;
  newsItemsToShow=3;
  showAllNews=false;
  substitutionCount:any;
  substitutionCountNew:any;
  substitutionCountOngoing:any;
  substitutionCountDone:any;
  substitutionCountDoneAndSubstituted:any;
  productsById;
  productsByIdShort;
  productsByIdLength:number;
  newSDS:any[]=[];
  amountInProductsByIdShort;
  notInAmountInProductsByIdShort;
  showSupplierAmountInTable:number;
  showStyrenAmountInTable:number;
  showStyrenProductAmountInTable:number;
  showAllSuppliersInTable:boolean = false;
  showAllStyrenInTable:boolean = false;
  showAllStyrenProductInTable:boolean = false;
  showSDS:boolean = false;
  sareqIds: any;
  CMRA_amount: any;
  CMRADiagramIsFetched: boolean = false;
  changedProducts:any[] = [];
  changedProductsHeaders:any;
  changedProductsFrom;
  changedProductsTo;
  errorDate="";
  getProductsIsClicked: boolean = false;
  showStyrenSupplier: boolean = true;
  styrensupplierarray;
  styrenproducts;
  ecoCounter: any;
  isCompanyDoc: boolean = true;
  isDepartmentDoc: boolean = false;
  isRiskDiag: boolean = true;
  isProductDiag: boolean = false;
  groupItem;
  isDashboardRoot: boolean = true;
  isDashboardAnalysis: boolean = false;
  isDashboardAdmin: boolean = false;
  isDashboardHistoryStatistics: boolean = false;
  isDashboardYearlyReport: boolean = false;

  constructor(private organisationService: OrganisationService, private translate: TranslateService,
              private utilService: UtilService, private catalogueService: CatalogueService,
              private authService: AuthService, private productService: ProductService,
              private coordinatorService: CoordinatorService, private assessmentChemicalService: AssessmentChemicalService,
              private substitutionService: SubstitutionService, private router: Router,private datePipe: DatePipe) { }

  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  updateFlagClp = false;
  dynamicTitle = "";

  environmentDiagramChartConfig: Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickEco(event)
          }
        },
        data: [{
          name: "",
          y: 0,
          sliced: false,
          selected: false
        }, {
          name: "",
          y: 0
        }, {
          name: "",
          y: 0
        }]
    }]
  }

  restrictionDiagramChartConfig: Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickRestriction(event)
          }
        },
        data: [{
          name: "",
          y: 0,
          sliced: false,
          selected: false
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }]
    }]
  }

  productgroupDiagramChartConfig: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 400,
        width: 500,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickProductGroup(event)
          }
        },
        data: [{
          name: 'data',
          y: 0,
          sliced: false,
          selected: false
      }, {
          name: '',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }]
    }]
  }

  CMRADiagramChartConfig: Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 400,
        type: 'column'
    },
    title: {
        text: null
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:pointer;" id="cmra' + this.value + '">'+this.value+'<span/>';
        }
      },
      categories: ['Cancerogent', 'Mutagent', 'Reproduktionshämmande', 'Allergiskt']
    },
    yAxis: {
      min: 0,
      title: {
          text: ""
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            colorIndex: 2,
            allowPointSelect: false,
            cursor: 'pointer',

        },
        series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:1f}'
          }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px"></span><br>',
      pointFormat: '<span>{point.name}</span><br/><span>Antal: {point.y:1f}</span><br/>'
  },

    series: [{
        type: 'column',
        showInLegend: false,
        colorByPoint: true,
        color: '#FF0000',
        cursor: 'pointer',
        colors: ['#3B97B2',
          '#67BC42',
          '#FF56DE',
          '#E6D605'
        ],
        point: {
          events: {
            click: (event) => this.chartClickCMRA(event)
          }
        },
        data: [{
          name: '0',
          y: 0,
          id:'123',
          sliced: false,
          selected: false
        }
      ] // Setting the data later in the code
    }]
  }

  supplierDiagramChartConfig: Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickSupplier(event)
          }
        },
        data: [{
          name: '0',
          y: 0,
          sliced: false,
          selected: false
        }] // Setting the data later in the code
    }]
  }

  riskPrioDiagramChartConfig: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 400,
        width: 450,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer',
            colors: [
              '#7FFFD4',
              'rgb(25,251,139',
              '#FFEA00',
              '#FFA500',
              '#FF0000'
            ],
        }
    },
    series: [{
        type: 'pie',
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickRisk(event)
          }
        },
        data: [{
            name: 'data',
            y: 0,
            sliced: false,
            selected: false
        }, {
            name: '',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '',
            y: 0
        }]
    }]
  }

  specialDiagramChartConfig: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: false
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:pointer;" id="specialdemands' + this.pos + '">'+this.value+'<span/>';
        }
      },
      categories: ["information", 'training', 'health_control', 'fitness_for_duty','no_special_demands','not reviewed']
    },
    yAxis: {
      min: 0,
      title: {
        text: ""
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: true,
      cursor: 'pointer',
      point: {
        events: {
          click: (event) => this.chartClickSpecial(event)
        }
      },
      data: [0, 0, 0, 0, 0]
    }]
  }

  pictogramChartConfig: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: ["clp_acid-tiny", 'clp_bottle-tiny', 'clp_exclamation-tiny', 'clp_explosion-tiny', 'clp_flame-tiny',
                   "clp_pollution-tiny", 'clp_roundflame-tiny', 'clp_silhouete-tiny', 'clp_skull-tiny'],
      labels: {
        x: 5,
        useHTML: true,
        formatter: function () {
          if ( this.value == 'Produkter utan piktogram' ) {
            return '<span  class="pb-7 pt-1 pr-10 h-full max-w-full cursor-pointer" id="pictogram' + this.value + '">'+this.value+'<span/>';
          }
          else {
            return  '<img class="" title="' + this.value + '" id="pictogram' + this.value + '"' + 'style="padding-bottom: 30px; padding-top: 5px; padding-right:40px; max-width:100!important;cursor:pointer;" src="/images/pictograms/' + this.value + '.png" />';
          }
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'pointer',
      point: {
        events: {
          click: (event) => this.chartClickPictogram(event)
        }
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    }]
  }

  historyChart: Highcharts.Options = {
    chart: {
      height:'600px'
    },
    credits: {
      enabled: false
    },
    rangeSelector: {
      enabled: true
    },
    navigator: {
      enabled: true
    },
    title: {
      text: ""
    },
    subtitle: {
      text:'<b>Totalt</b> = Total liter av inköpta produkter<br/>'+
      '<b>Totalt miljömärkt</b> = Total liter av miljömärkta '+
      'produkter (mängden räknas '+
      'ut på följande sätt:  Svanen + EU-blomman - EU-blomman och Svanen = Totalt miljömärkt)<br/>'+
      '<b>Svanenmärkt</b> = Total liter av Svanenmärkta produkter<br/>'+
      '<b>EU-blomman</b> = Total liter av EU-blomman märkta produkter<br/>'+
      '<b>EU-blomman och Svanen</b> = Total liter av produkter som har båda märkningarna'+
      '<hr/>',
      useHTML:true,
      verticalAlign:'bottom',
    },
    series: [
      {
          name: 'Totalt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: 'l',
              valueDecimals: 0
          }
      },
      {
          name: 'Total Miljömärkt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: 'l',
              valueDecimals: 0
          }
      },
      {
          name: 'Svanenmärkt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: 'l',
              valueDecimals: 0
          }
      },
      {
          name: 'EU-blomman',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: 'l',
              valueDecimals: 0
          }
      },
      {
          name: "EU-blomman och Svanen",
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: 'l',
              valueDecimals: 0
          }
      }
    ],
    xAxis: {
      //  type : "datetime", //add this line  xpelenn
      gridLineWidth: 1,
      labels: {
          rotation: -45,
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      },
      type: 'datetime',
      endOnTick: true,
      //max: new Date('2021-12-31:23:59:59').valueOf(),
      min: new Date('2021-01-01').valueOf()
    },
    yAxis: [
      { // Primary yAxis
          labels: {
              format: '{value} l',
              style: {
                  //color: Highcharts.getOptions().colors[1]
              }
          },
          title: {
              text: 'Mängd',
              style: {
                  //color: Highcharts.getOptions().colors[1]
              }
          },
          min: 0
      },
    ],
    legend: {
        itemDistance: 50
    },
  }

  historyChartProcent: Highcharts.Options = {
    chart: {
      height:'600px'
    },
    credits: {
      enabled: false
    },
    rangeSelector: {
      enabled: true
    },
    navigator: {
      enabled: true
    },
    title: {
      text: ""
    },
    subtitle: {
      text:'<b>Totalt miljömärkt</b> = Total procent av miljömärkta produkter. '+
      'Är en summering av produkter märkta med Svanen och/eller EU-blomman <br/>'+
      '(procenten räknas ut på följande sätt:  '+
      'Svanen + EU-blomman - EU-blomman och Svanen = Totalt miljömärkt)<br/>'+
      '<b>Svanenmärkt</b> = Total procent av Svanenmärkta produkter<br/>'+
      '<b>EU-blomman</b> = Total procent av EU-blomman märkta produkter<br/>'+
      '<b>EU-blomman och Svanen</b> = Total procent av produkter som har båda märkningarna'+
      '<hr/>',
      useHTML:true,
      verticalAlign:'bottom',
    },
    series: [
      {
          name: 'Totalt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: '%',
              valueDecimals: 0
          }
      },
      {
          name: 'Total Miljömärkt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: '%',
              valueDecimals: 0
          }
      },
      {
          name: 'Svanenmärkt',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: '%',
              valueDecimals: 0
          }
      },
      {
          name: 'EU-blomman',
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: '%',
              valueDecimals: 0
          }
      },
      {
          name: "EU-blomman och Svanen",
          type: 'spline',
          yAxis: 0,
          data: [],
          visible: true,
          tooltip: {
              valueSuffix: '%',
              valueDecimals: 0
          }
      }
    ],
    xAxis: {
      //  type : "datetime", //add this line  xpelenn
        gridLineWidth: 1,
        labels: {
            rotation: -45,
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        },
        type: 'datetime',
        endOnTick: true,
        //max: new Date('2021-12-31:23:59:59').valueOf(),
        min: new Date('2021-01-01').valueOf()
    },

    yAxis: [
        { // Primary yAxis
            labels: {
                format: '{value} %',
                style: {
                    //color: Highcharts.getOptions().colors[1]
                }
            },
            title: {
                text: 'Procent',
                style: {
                    //color: Highcharts.getOptions().colors[1]
                }
            },
            min: 0,
            max: 100
        },
    ],
    legend: {
        itemDistance: 50
    },
  }

  styrenDiagramChartConfig: Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 400,
        width: 500,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name.toString() + ": " + this.y.toString(); // Return an empty string to remove the tooltip text
      }
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'pointer'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => this.chartClickStyren(event)
          }
        },
        data: [{
          name: '0',
          y: 0,
          sliced: false,
          selected: false
        }] // Setting the data later in the code
    }]
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.ecoCounter = 0;

    this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000));
    this.changedProductsTo = new Date();
    this.CMRADiagramIsFetched = false;
    this.changedProducts = [];
    this.CMRA_amount = ([
      {name:'Cancerogent',count:0},
      {name:'Mutagent',count:0},
      {name:'Reprodutionshämmande',count:0},
      {name:'Allergiskt',count:0}
    ]);
    this.productsByIdLength=30;  // Change this for more or less suppliers (or let it be set by user)
    this.showSupplierAmountInTable = 10; // Number of suppliers shown initially in table
    this.showAllSuppliersInTable = false; // Show all suppliers or not
    this.showStyrenAmountInTable = 10; // Number of styrenproducts shown initially in table
    this.showAllStyrenInTable = false; // Show all styrenproducts or not
    this.showStyrenProductAmountInTable = 10; // Number of styrenproducts shown initially in table
    this.showAllStyrenProductInTable = false; // Show all styrenproducts or not
    localStorage.removeItem('departmentchanged');

    this.cataloguesCount = 0;
    this.productsCount = 0;
    this.styrenProductsCount = 0;
    this.nbrProductMaxAmount = 0;
    this.nbrProductAmountLargerMax = 0;
    this.coordinatorCount = 0;
    this.coordinatorCountOngoing = 0;
    this.coordinatorCountDone = 0;
    this.currentCompanyFiles = [];
    this.productsUniqueOwnRB = 0;
    this.groupInfo=[];
    this.restriktionList=[];

    this.subscribesToCatalogue = this.utilService.subscribesTo('subscription_catalogue');
    this.subscribesToProducts = this.utilService.subscribesTo('subscription_products');
    this.subscribesToCoordinator = this.utilService.subscribesTo('subscription_coordinator');
    this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
    this.subscribesToRiskAssessment = this.utilService.subscribesTo('subscription_risk_assessment');
    this.subscribesToSIN = this.utilService.subscribesTo('subscription_sin');
    this.subscribesToSLL = this.utilService.subscribesTo('subscription_sll');
    this.subscribesToBASTA = this.utilService.subscribesTo('subscription_basta');
    this.subscribesToSpecialDemands = this.utilService.subscribesTo('subscription_special_demands');
    this.subscribesToReportEnvironment = this.utilService.subscribesTo('subscription_report_enviroment');
    this.subscribesToStyren = this.utilService.subscribesTo('subscription_styren');
    this.subscribesToFreemium = this.utilService.subscribesTo('subscription_freemium');

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    this.historyDate = localStorage.getItem('historyDate', );
    if (this.historyDate == null || this.historyDate == undefined)
    {
      this.historyDate = new Date().getFullYear() + "-01-01"
      localStorage.setItem('historyDate', this.historyDate);
    }

    this.historyYear = localStorage.getItem('historyYear', );
    if (this.historyYear == null || this.historyYear == undefined)
    {
      this.historyYear = new Date().getFullYear();
      localStorage.setItem('historyYear', this.historyYear);
    }

    this.historyProductGroup = localStorage.getItem('historyProductGroup');
    if ( this.historyProductGroup == null
          || this.historyProductGroup == undefined
          || this.historyProductGroup == 'undefined'
          || this.historyProductGroup == ''
          || this.historyProductGroup == 'null' )
    {
      this.historyProductGroup = 'all';
      localStorage.setItem('historyProductGroup', this.historyProductGroup );
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.includedEcoLabels = {};
    this.includedEcoLabels.eco_svanen = true;
    this.includedEcoLabels.eco_eu = true;
    this.includedEcoLabels.eco_GEC = true;
    this.includedEcoLabels.active = true;

    this.loadRestrictionList();

    this.loadCompanyFiles();
    this.loadNews();
    if (this.selectedDepartment) {
      this.loadDepartmentFiles(this.selectedDepartment.id);
    }

    // Set Login Status
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      localStorage.setItem('departmentchanged','set');
      this.ngOnInit();
    })

    setTimeout(() => {
      this.loadingData = false;
    }, 4000);
  }

  onToggleEcoLabelSettings() {
    this.updateFlagClp = false;
    this.loadHistory();
  }

  tabDashboard() {
    this.isDashboardRoot = true;
    this.isDashboardAnalysis = false;
    this.isDashboardAdmin = false;
    this.isDashboardHistoryStatistics = false;
    this.isDashboardYearlyReport = false;
    this.router.navigate(['/dashboard']);
  }

  tabDashboardAdmin() {
    this.isDashboardRoot = false;
    this.isDashboardAnalysis = false;
    this.isDashboardAdmin = true;
    this.isDashboardHistoryStatistics = false;
    this.isDashboardYearlyReport = false;
  }

  tabDashboardHistoryStatistics() {
    this.isDashboardRoot = false;
    this.isDashboardAnalysis = false;
    this.isDashboardAdmin = false;
    this.isDashboardHistoryStatistics = true;
    this.isDashboardYearlyReport = false;
  }

  tabDashboardYearlyReport() {
    this.isDashboardRoot = false;
    this.isDashboardAnalysis = false;
    this.isDashboardAdmin = false;
    this.isDashboardHistoryStatistics = false;
    this.isDashboardYearlyReport = true;
  }

  tabDashboardAnalysis() {
    this.isDashboardRoot = false;
    this.isDashboardAnalysis = true;
    this.isDashboardAdmin = false;
    this.isDashboardHistoryStatistics = false;
    this.isDashboardYearlyReport = false;
  }

  tabCompanyDoc() {
    this.isCompanyDoc = true;
    this.isDepartmentDoc = false;
  }

  tabDepartmentDoc() {
    this.isCompanyDoc = false;
    this.isDepartmentDoc = true;
  }

  tabRisk() {
    this.isRiskDiag = true;
    this.isProductDiag = false;
  }

  tabProduct() {
    this.isRiskDiag = false;
    this.isProductDiag = true;
  }

  chartClickRisk(event) {
    var searchFilter = [];
    searchFilter[0] = event.point.options.key.toString();
    searchFilter[1] = 2;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
    this.router.navigate(['/productlist']);
  }

  chartClickProductGroup( event ) {
    var searchFilter = [];
    searchFilter[0] = event.point.options.name.toString();
    searchFilter[1] = 6;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
    localStorage.setItem('productGroupSearch', JSON.stringify(event.point.options.name));
    this.router.navigate(['/productlist']);
  }


  chartClickEco(event) {
    var searchFilter = [];
    searchFilter[0] = event.point.options.name;
    searchFilter[1] = 3;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
    this.router.navigate(['/productlist']);
  }

  chartClickSpecial(event) {
    var searchFilter = [];
    searchFilter[0] = event.point.index;
    searchFilter[1] = 0;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));

    if(event.point.index==0){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('INFORMATION_DEMAND')));
    }
    if(event.point.index==1){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('TRAINING')));
    }
    if(event.point.index==2){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('HEALTH_CONTROL')));
    }
    if(event.point.index==3){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('FITNESS_FOR_DUTY')));
    }
    if(event.point.index==4){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('NO_SPECIAL_DEMANDS')));
    }
    if(event.point.index==5){
      localStorage.setItem('specialSearch', JSON.stringify(this.translate.instant('HAS_NOT_BEEN_CHECKED')));
    }
    this.router.navigate(['/productlist']);
  }

  chartClickPictogram(event) {

    var searchFilter = [];

    switch (event.point.index) {
      case 0:
        searchFilter[0] = "clp_is_corrosive";
        break;

      case 1:
        searchFilter[0] = "clp_is_gas_under_pressure";
        break;

      case 2:
        searchFilter[0] = "clp_is_caution";
        break;

      case 3:
        searchFilter[0] = "clp_is_explosive";
        break;

      case 4:
        searchFilter[0] = "clp_is_flammable";
        break;

      case 5:
        searchFilter[0] = "clp_is_dangerous_to_the_environment";
        break;

      case 6:
        searchFilter[0] = "clp_is_oxidising";
        break;

      case 7:
        searchFilter[0] = "clp_is_longer_term_health_hazards";
        break;

      case 8:
        searchFilter[0] = "clp_is_toxic";
        break;

      case 9:
        searchFilter[0] = "clp_none";
        break;

      default:
        searchFilter[0] = "";
        break;
    }

    searchFilter[1] = 1;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
    this.router.navigate(['/productlist']);
  }

  logout() {
    this.authService.logout();
  }

  updateHistoryYear(year)
  {
    this.historyYear = year;
    localStorage.setItem('historyDate', year + "-01-01");
    localStorage.setItem('historyYear', this.historyYear);
    this.ngOnInit();
  }

  productGroupSelected(groupItem){
    if ( groupItem == 'all' ) {
      this.groupItem = this.translate.instant("ALL");
      localStorage.setItem('historyProductGroup', 'all');
      this.ngOnInit();
    } else {
      this.groupItem = groupItem.name;
      localStorage.setItem( 'historyProductGroup', groupItem.id) ;
      this.ngOnInit();
    }
  }

  loadHistory() {
    if (_.isObject(this.selectedDepartment)) {

      this.inventoryhistory = [];
      var labels = 0;

      if(this.includedEcoLabels.eco_svanen)
      {
          labels += 1;
      }

      if(this.includedEcoLabels.eco_eu)
      {
          labels += 2;
      }

      if(this.includedEcoLabels.eco_GEC)
      {
          labels += 4;
      }

      this.productService.getHistoryForProducts(this.selectedDepartment.id, labels, this.historyYear, this.historyProductGroup);
      this.productService.getProductHistoryDataListener().pipe(take(1)).subscribe({
        next: inventoryhistory => {

          this.historyChart.xAxis['min'] = new Date(this.historyYear).valueOf();
          this.historyChartProcent.xAxis['min'] = new Date(this.historyYear).valueOf();

          this.historyChart.series[0]["data"] = [];
          this.historyChart.series[1]["data"] = [];
          this.historyChart.series[2]["data"] = [];
          this.historyChart.series[3]["data"] = [];
          this.historyChart.series[4]["data"] = [];

          this.historyChartProcent.series[0]["data"] = [];
          this.historyChartProcent.series[1]["data"] = [];
          this.historyChartProcent.series[2]["data"] = [];
          this.historyChartProcent.series[3]["data"] = [];
          this.historyChartProcent.series[4]["data"] = [];

          if (inventoryhistory == null) {}
          else {
            this.inventoryhistory = inventoryhistory;

            var partEco = 0;
            for(var i = 0; i < this.inventoryhistory.length; i++){
              this.historyChart.series[0]["data"].push([this.inventoryhistory[i].date, this.inventoryhistory[i].amount]);
              this.historyChart.series[1]["data"].push([this.inventoryhistory[i].date, this.inventoryhistory[i].eco_amount]);
              this.historyChart.series[2]["data"].push([this.inventoryhistory[i].date, this.inventoryhistory[i].svanen_amount]);
              this.historyChart.series[3]["data"].push([this.inventoryhistory[i].date, this.inventoryhistory[i].eu_amount]);
              this.historyChart.series[4]["data"].push([this.inventoryhistory[i].date, this.inventoryhistory[i].eu_svanen_amount]);
              this.historyChartProcent.series[0]["data"].push([this.inventoryhistory[i].date, (this.inventoryhistory[i].amount/this.inventoryhistory[i].amount) * 100]);
              this.historyChartProcent.series[4]["data"].push([this.inventoryhistory[i].date, (this.inventoryhistory[i].eu_svanen_amount/this.inventoryhistory[i].amount) * 100]);
              this.historyChartProcent.series[2]["data"].push([this.inventoryhistory[i].date, (this.inventoryhistory[i].svanen_amount/this.inventoryhistory[i].amount) * 100]);
              this.historyChartProcent.series[3]["data"].push([this.inventoryhistory[i].date, (this.inventoryhistory[i].eu_amount/this.inventoryhistory[i].amount) * 100]);
              this.historyChartProcent.series[1]["data"].push([this.inventoryhistory[i].date, (this.inventoryhistory[i].eco_amount/this.inventoryhistory[i].amount) * 100]);
            }

            this.updateFlagClp = true;
          }
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      })
    }
  }

  loadRestrictionList(){
    if (this.organisation) {
      this.productService.getRestriktionList(this.organisation.id);
      this.productService.getProductRestDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {}
          else {
            this.restriktionList = info;
            this.loadProducts();
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  loadDepartmentFiles(orgId) {
    this.currentDepartmentFiles = [];
    this.organisationService.getAllFilesDepartments(orgId);
    this.organisationService.getOrganisationDataListenerDepartmentFiles().pipe(take(1)).subscribe({
      next: documents => {
        if (documents == null) {}
        else {
          this.currentDepartmentFiles = documents;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  loadCompanyFiles() {
    this.currentCompanyFiles = [];

    if (this.organisation) {
      this.organisationService.getAllFiles(this.organisation.id);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: documents => {
          if (documents == null) {}
          else {
            this.currentCompanyFiles=[];
            documents.forEach(element => {
              if(element.filename){
                this.currentCompanyFiles.push(element);
              }
            });
            //this.currentCompanyFiles = documents;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  loadCatalogue() {

    if (this.selectedDepartment) {
      this.catalogueService.AllWithSuborganisations(this.selectedDepartment.id);
      this.catalogueService.getCatalogueDataListener().pipe(take(1)).subscribe({
        next: catalogues => {
          if (catalogues != null)
          {
            this.catalogues = catalogues;
            if (this.catalogues != null) {
              this.cataloguesCount = this.catalogues.length;
            }
          }
        },
        error: error => {
          console.log("Unable to fetch catalogues: ", error);
        }
      })
    }
  }

  gotoLocalRiskAssessments(status) {
    localStorage.setItem('lrbDBFilter', JSON.stringify(status));
    this.router.navigate(['/assessmentschemical']);
  }

  gotoActivityRiskAssessments(status) {
    localStorage.setItem('arbDBFilter', JSON.stringify(status));
    this.router.navigate(['/assessments']);
  }

  gotoSubstitution(status) {
    localStorage.setItem('substitutionDBFilter', JSON.stringify(status));
    this.router.navigate(['/substitution']);
  }

  openDocumentInNewWindow(fileId)
  {
    if (this.organisation) {
      this.organisationService.getFileWithId(this.organisation.id, fileId);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${fileId}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        },
        error: error => {
        }
      })
    }
  }

  openDocumentInNewWindowDepartment(fileId)
  {
    if (this.selectedDepartment) {
      this.organisationService.getFileWithId(this.selectedDepartment.id, fileId);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${fileId}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        },
        error: error => {
        }
      })
    }
  }

  startTour() {

  }

  loadNews() {
    this.currentNews = [];

    this.organisationService.getAllNews();
    this.organisationService.getOrganisationNewsDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response != null)
        {
          this.currentNews = response;
        }
      },
      error: error => {
        console.log("loadNews error: ", error);
      }
    })
  }

  getAllCoordinatorSubjects() {

    var i, arrayLength, coordinatorSubjectListOngoing = [], coordinatorSubjectListDone = [];

    this.coordinatorCount = 0;

    if (this.selectedDepartment) {
      this.coordinatorService.getAll();
      this.coordinatorService.getAllCoordinatorDataListener().pipe(take(1)).subscribe({
        next: coordinatorSubjects => {
          if (coordinatorSubjects != null)
          {
            this.coordinatorCount = coordinatorSubjects.length;

            for (i = 0; i < this.coordinatorCount; i++) {

              if (coordinatorSubjects[i].status === 0) {
              }
              else if (coordinatorSubjects[i].status > 0 && coordinatorSubjects[i].status < 4) {
                coordinatorSubjectListOngoing.push(coordinatorSubjects[i]);
              }
              else if (coordinatorSubjects[i].status === 4) {
                coordinatorSubjectListDone.push(coordinatorSubjects[i]);
              }
            }

            this.coordinatorCountOngoing = coordinatorSubjectListOngoing.length;
            this.coordinatorCountDone = coordinatorSubjectListDone.length;
          }
        },
        error: error => {
          console.log("loadNews error: ", error);
        }
      })
    }
  }

  getAllSubstitutionSubjects() {
      var subDepartmentsIds = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
      var listOfIds="";
      var tempIds = "";
      subDepartmentsIds.forEach(function(i, idx, subDepartmentsIds){
        if (idx === subDepartmentsIds.length - 1){
            tempIds += "'"+i+"'";
        }else{
          tempIds += "'"+i+"'"+",";
        }
     });
      listOfIds="("+tempIds+")";
    var iSubstitution,
        arrayLengthSubstitution,
        substitutionSubjectListNew = [],
        substitutionSubjectListOngoing = [],
        substitutionSubjectListDone = [],
        substitutionSubjectListDoneAndSubstituted = [];

    this.substitutionCount = 0;

    if (this.selectedDepartment) {
      this.substitutionService.getAllForDashboard(listOfIds);
      this.substitutionService.getAllSubstitutionDataListener().pipe(take(1)).subscribe({
        next: substitutionSubjects => {
          if (substitutionSubjects != null)
          {
            this.substitutionCount = substitutionSubjects.length;

            for (iSubstitution = 0; iSubstitution < this.substitutionCount; iSubstitution++) {

              if (substitutionSubjects[iSubstitution].substatus === 0) {
                substitutionSubjectListNew.push(substitutionSubjects[iSubstitution]);
              }
              else if (substitutionSubjects[iSubstitution].substatus > 0 && substitutionSubjects[iSubstitution].substatus < 4) {
                substitutionSubjectListOngoing.push(substitutionSubjects[iSubstitution]);
              }
              else if (substitutionSubjects[iSubstitution].substatus === 4) {
                substitutionSubjectListDone.push(substitutionSubjects[iSubstitution]);
              }
              else if (substitutionSubjects[iSubstitution].substatus === 5) {
                substitutionSubjectListDoneAndSubstituted.push(substitutionSubjects[iSubstitution]);
              }
            }

            this.substitutionCountNew = substitutionSubjectListNew.length;
            this.substitutionCountOngoing = substitutionSubjectListOngoing.length;
            this.substitutionCountDone = substitutionSubjectListDone.length;
            this.substitutionCountDoneAndSubstituted = substitutionSubjectListDoneAndSubstituted.length;
          }
        },
        error: error => {
          console.log("loadNews error: ", error);
        }
      })
    }
  }

  loadProducts() {
    var ecoCounter = 0;
    var ecoSwanCounter = 0;
    var ecoGECCounter = 0;
    this.newSDS = [];

    if (this.selectedDepartment) {
      this.productService.AllWithSuborganisationsLessInformation(this.selectedDepartment.id);
      this.productService.getAllProductDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products != null) {
            this.products = products;
            this.sareqIds = [];
            for(var i = 0; i < this.products.length; i++) {
              this.sareqIds.push(this.products[i].sareq_id);
            }

          this.getHHPhrases();
            this.productsCount = 0;
            this.groupInfo=[];
            var groupNameId=[];
            this.resetChartData();
            for( var i = 0; i < this.products.length; i++ ) {
              if (this.products[i]?.maximum_amount != null) {
                this.nbrProductMaxAmount++;

                if (this.products[i].amount != null) {
                    if (this.products[i].amount > this.products[i].maximum_amount) {
                      this.nbrProductAmountLargerMax++;
                    }
                }
              }
              if (this.products[i]?.override == null ) {
                this.productsCount++;
              } else {
                this.productsCount++;
                this.products.splice( i, 1 );
              }
              var group_id_exists=false;
              for( var ii = 0; ii < this.products.length; ii++ ) {
                if ( this.products [i] &&this.products[i].groups && this.products[i].groups.length > 0){
                  group_id_exists=true;
                }
              }

              if(group_id_exists==true){
                let groups = _.uniqBy(this.products[i].groups, 'id');
                for (var n = 0;n < groups.length;n++){
                  let this_group_id = groups[n].id;
                if (groupNameId.indexOf(this_group_id) == -1) {
                    groupNameId.push(groups[n].id);
                    this.groupInfo.push({'id': groups[n].id,
                                        'count': 1,
                                        'name': groups[n].name});
                } else {
                    for (var j = 0; j < this.groupInfo.length; j++) {
                      if(this.groupInfo[j].id == this_group_id) {
                        this.groupInfo[j].count++;
                      }
                    }
                  }
                }
              }

            if (this.products && this.products.length > 0){
              for (var xi = 0; xi < 10; xi++) {
                if (xi < this.groupInfo.length){
                  this.productgroupDiagramChartConfig.series[0]["data"][xi].y = this.groupInfo[xi].count;
                  this.productgroupDiagramChartConfig.series[0]["data"][xi].visible = true;
                    if (!this.groupInfo[xi].name || this.groupInfo[xi].name=="" || this.groupInfo[xi].name == null) {
                      this.productgroupDiagramChartConfig.series[0]["data"][xi].name = "Ej angett";
                    }else{
                      this.productgroupDiagramChartConfig.series[0]["data"][xi].name = this.groupInfo[xi].name;
                    }
                }else{
                  this.productgroupDiagramChartConfig.series[0]["data"][xi].name = "";
                  this.productgroupDiagramChartConfig.series[0]["data"][xi].visible = false;
                }
              }
            }

            if ( this.historyProductGroup == 'all' ) {
              this.groupItem = this.translate.instant("ALL");
            } else {
              for (var j = 0; j < this.groupInfo.length; j++) {
                if ( this.groupInfo[j].id == this.historyProductGroup ) {
                  this.groupItem = this.groupInfo[j].name;
                  break
                }
              }
            }
            /*if(this.products[i].sds_date){
              let date1 = new Date(this.products[i].sds_date);
              let date2 = new Date();
              let Time = date2.getTime() - date1.getTime();
              let Days = Time / (1000 * 3600 * 24); //Difference in Days
              if(Days<30){
                this.newSDS.push({id:this.products[i].sareq_id,name:this.products[i].name})
              }*/
              if ( this.products[i]?.cdoc_date ) {
                let date1 = new Date(this.products[i].cdoc_date);
                let date2 = new Date();
                let date3 = new Date(this.products[i].cdoc_date).toLocaleDateString();
                let Time = date2.getTime() - date1.getTime();
                let Days = Time / (1000 * 3600 * 24); //Difference in Days
                if(Days<30){
                  // this.newSDS.push({id:this.products[i].sareq_id,name:this.products[i].name,date:this.products[i].cdoc_date.substring(0,10)})
                  this.newSDS.push({id:this.products[i].sareq_id,name:this.products[i].name,date:date3})
                }
            }

            this.newSDS=_.sortBy(this.newSDS,['date']).reverse();
          }

          // Not sure why this is needed but it solved the problem
          for( var i = 0; i < this.products.length; i++ ) {
            if (this.products[i]?.override !== null ) {
              this.productsCount--;
              this.products.splice( i, 1 );
            }
          }
            this.restrictionListsCount = ({
              'item': "",
              'list_amv_a_count': 0,
              'list_amv_b_count': 0,
              'list_chem_limit_count': 0,
              'list_chem_prio_p_count': 0,
              'list_chem_prio_u_count': 0,
              'list_reach_14_count': 0,
              'list_reach_17_count': 0,
              'list_reach_candidate_count': 0,
              'list_sin_count': 0,
              'list_sll_count': 0,
              'list_water_directive_count': 0,
              'list_BASTA_count': 0,
              'no_list_count': 0
            });

            var duplicateaArray=[];
            var temprestriktionList=[];
            for (var key = 0; key < this.restriktionList.length; key++) {

              var count = 1;
              if (duplicateaArray.indexOf(this.restriktionList[key].Sareq_id) < 1) {
                count=1;
                /*for (var productItem = 0; productItem < this.products.length; productItem++) {
                  if (this.products[productItem].sareq_id == this.restriktionList[key].Sareq_id){
                    count = this.products[productItem].count_organisation_id;
                  }
                }*/

                duplicateaArray.push(this.restriktionList[key].Sareq_id);
                var item = ({
                    'item': this.restriktionList[key].Sareq_id,
                    'list_amv_a_count': 0,
                    'list_amv_b_count': 0,
                    'list_chem_limit_count': 0,
                    'list_chem_prio_p_count': 0,
                    'list_chem_prio_u_count': 0,
                    'list_reach_14_count': 0,
                    'list_reach_17_count': 0,
                    'list_reach_candidate_count': 0,
                    'list_sin_count': 0,
                    'list_sll_count': 0,
                    'list_water_directive_count': 0,
                    'list_BASTA_count': 0,
                    'count':count
                });

                for (var key2 = 0; key2 < this.restriktionList.length; key2++) {
                  if (item.item == this.restriktionList[key2].Sareq_id) {
                    var count=1;
                    if (this.restriktionList[key2].list_amv_a == 1) {
                        item.list_amv_a_count += count;
                    }
                    if (this.restriktionList[key2].list_amv_b == 1) {
                        item.list_amv_b_count += count;
                    }
                    if (this.restriktionList[key2].list_chem_limit == 1) {
                        item.list_chem_limit_count += count;
                    }
                    if (this.restriktionList[key2].list_chem_prio_p == 1) {
                        item.list_chem_prio_p_count += count;
                    }
                    if (this.restriktionList[key2].list_chem_prio_u == 1) {
                        item.list_chem_prio_u_count += count;
                    }
                    if (this.restriktionList[key2].list_reach_14 == 1) {
                        item.list_reach_14_count += count;
                    }
                    if (this.restriktionList[key2].list_reach_17 == 1) {
                        item.list_reach_17_count += count;
                    }
                    if (this.restriktionList[key2].list_reach_candidate == 1) {
                        item.list_reach_candidate_count += count;
                    }
                    if (this.restriktionList[key2].list_sin == 1) {
                        item.list_sin_count += count;
                    }
                    if (this.restriktionList[key2].list_sll == 1) {
                        item.list_sll_count += count;
                    }
                    if (this.restriktionList[key2].list_water_directive == 1) {
                        item.list_water_directive_count += count;
                    }
                    if (this.restriktionList[key2].list_BASTA == 1) {
                        item.list_BASTA_count += count;
                    }
                  }
                }
                temprestriktionList.push(item);

              } else {
                continue;
              }
            }

            if (this.selectedDepartment.id != this.selectedOrganisation.id) {

              var deps = [];
              var depProductsId = [];
              var depProductsIdandCount = [];
              for (var depsid = 0; depsid < this.selectedDepartment.orgs.length; depsid++) {
                deps.push(this.selectedDepartment.orgs[depsid]);
                if (this.selectedDepartment.orgs[depsid].orgs) {
                  for (var depsid2 = 0; depsid2 < this.selectedDepartment.orgs[depsid].orgs.length; depsid2++) {
                    deps.push(this.selectedDepartment.orgs[depsid].orgs[depsid2]);
                  }
                }
              }
              deps.push(this.selectedDepartment);
              for (var index = 0;index < this.products.length; index++) {
                if (deps.indexOf(this.products[index].organisation_id > -1)&&this.products[index].organisation_id_list) {
                  var depIndexList = this.products[index].organisation_id_list.split(',');
                  var depCount = 0;
                  for (var depIndex = 0; depIndex < depIndexList.length; depIndex++){
                    for (var depDep = 0; depDep < deps.length; depDep++){
                      if (depIndexList[depIndex] == deps[depDep].id){
                        depCount++;
                      }
                    }
                  }

                  depProductsId.push(this.products[index].sareq_id);
                  depProductsIdandCount.push({'id': this.products[index].sareq_id, 'count':depCount});
                }
              }

              var numOnList=0;
              var numNotOnList=0;
              temprestriktionList=_.uniqBy(temprestriktionList, 'item');
              // Remove items not in products
              temprestriktionList.forEach((element,index)=>{
                var found=false;
                this.products.forEach(element2=>{
                  if(element2.sareq_id==element.item){
                    found=true;
                    return;
                  }
                })
                if(found==false){
                  temprestriktionList.splice(index,1);
                }
              })
              for (var key = 0; key < temprestriktionList.length; key++){
                var isOnList = false;
                var count = 0;
                if (depProductsId.indexOf(temprestriktionList[key].item) >- 1){
                  for (var depProductsIdandCountindex = 0;
                      depProductsIdandCountindex < depProductsIdandCount.length;
                      depProductsIdandCountindex++)
                  {
                    if (depProductsIdandCount[depProductsIdandCountindex].id == temprestriktionList[key].item){
                      count = depProductsIdandCount[depProductsIdandCountindex].count;
                    }
                  }

                  if (temprestriktionList[key].list_amv_a_count > 0) {
                    this.restrictionListsCount.list_amv_a_count += count;
                    isOnList = true;
                  }

                  if (temprestriktionList[key].list_amv_b_count > 0) {
                    this.restrictionListsCount.list_amv_b_count += count;
                    isOnList = true;
                  }

                  if (temprestriktionList[key].list_chem_limit_count > 0) {
                    this.restrictionListsCount.list_chem_limit_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_chem_prio_p_count > 0) {
                    this.restrictionListsCount.list_chem_prio_p_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_chem_prio_u_count > 0) {
                    this.restrictionListsCount.list_chem_prio_u_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_reach_14_count > 0) {
                    this.restrictionListsCount.list_reach_14_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_reach_17_count > 0){
                    this.restrictionListsCount.list_reach_17_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_reach_candidate_count > 0){
                    this.restrictionListsCount.list_reach_candidate_count += count;
                    isOnList=true;
                  }

                  if (temprestriktionList[key].list_sin_count > 0) {
                    this.restrictionListsCount.list_sin_count += count;
                    if (this.subscribesToSIN == true) {
                    isOnList=true;
                    }
                  }

                  if(temprestriktionList[key].list_sll_count > 0) {
                    this.restrictionListsCount.list_sll_count += count;
                    if (this.subscribesToSLL == true) {
                    isOnList=true;
                    }
                  }

                  if(temprestriktionList[key].list_water_directive_count > 0) {
                    this.restrictionListsCount.list_water_directive_count += count;
                    isOnList=true;
                  }

                  if(temprestriktionList[key].list_BASTA_count > 0) {
                    this.restrictionListsCount.list_BASTA_count += count;
                    isOnList=true;
                  }
                }

                if(isOnList==true) {
                  numOnList++;
                }
              }

              this.restrictionListsCount.no_list_count = this.products.length - numOnList;
            } else {
              temprestriktionList=_.uniqBy(temprestriktionList, 'item');
              // Remove items not in products
              temprestriktionList.forEach((element,index)=>{
                var found=false;
                this.products.forEach(element2=>{
                  if(element2.sareq_id==element.item){
                    found=true;
                    return;
                  }
                })
                if(found==false){
                  temprestriktionList.splice(index,1);
                }
              })
              for (var key = 0; key < temprestriktionList.length; key++) {
                if (temprestriktionList[key].list_amv_a_count > 0) {
                  this.restrictionListsCount.list_amv_a_count += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_amv_b_count > 0) {
                  this.restrictionListsCount.list_amv_b_coun += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_chem_limit_count > 0) {
                  this.restrictionListsCount.list_chem_limit_count += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_chem_prio_p_count > 0) {
                  this.restrictionListsCount.list_chem_prio_p_count+=temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_chem_prio_u_count > 0) {
                  this.restrictionListsCount.list_chem_prio_u_count += temprestriktionList[key].count;
                }

                if(temprestriktionList[key].list_reach_14_count > 0) {
                  this.restrictionListsCount.list_reach_14_count += temprestriktionList[key].count;
                }
                if(temprestriktionList[key].list_reach_17_count > 0) {
                  this.restrictionListsCount.list_reach_17_count += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_reach_candidate_count > 0) {
                  this.restrictionListsCount.list_reach_candidate_count += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_sin_count > 0) {
                  this.restrictionListsCount.list_sin_count += temprestriktionList[key].count;
                }

                if(temprestriktionList[key].list_sll_count > 0) {
                  this.restrictionListsCount.list_sll_count += temprestriktionList[key].count;
                }

                if(temprestriktionList[key].list_water_directive_count > 0) {
                  this.restrictionListsCount.list_water_directive_count += temprestriktionList[key].count;
                }

                if (temprestriktionList[key].list_BASTA_count > 0) {
                  this.restrictionListsCount.list_BASTA_count += temprestriktionList[key].count;
                }
              }
              this.restrictionListsCount.no_list_count = this.products.length - temprestriktionList.length;
            }

            this.restrictionDiagramChartConfig.series[0]["data"][0].y = this.restrictionListsCount.list_amv_a_count;
            this.restrictionDiagramChartConfig.series[0]["data"][0].name = this.translate.instant("RESTRICTION_LIST_AMV_A");
            this.restrictionDiagramChartConfig.series[0]["data"][1].y = this.restrictionListsCount.list_amv_b_count;
            this.restrictionDiagramChartConfig.series[0]["data"][1].name = this.translate.instant("RESTRICTION_LIST_AMV_B");
            this.restrictionDiagramChartConfig.series[0]["data"][2].y = this.restrictionListsCount.list_chem_limit_count;
            this.restrictionDiagramChartConfig.series[0]["data"][2].name = this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT");
            this.restrictionDiagramChartConfig.series[0]["data"][3].y = this.restrictionListsCount.list_chem_prio_p_count;
            this.restrictionDiagramChartConfig.series[0]["data"][3].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P");
            this.restrictionDiagramChartConfig.series[0]["data"][4].y = this.restrictionListsCount.list_chem_prio_u_count;
            this.restrictionDiagramChartConfig.series[0]["data"][4].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U");
            this.restrictionDiagramChartConfig.series[0]["data"][5].y = this.restrictionListsCount.list_reach_14_count;
            this.restrictionDiagramChartConfig.series[0]["data"][5].name = this.translate.instant("RESTRICTION_LIST_REACH_14");
            this.restrictionDiagramChartConfig.series[0]["data"][6].y = this.restrictionListsCount.list_reach_17_count;
            this.restrictionDiagramChartConfig.series[0]["data"][6].name = this.translate.instant("RESTRICTION_LIST_REACH_17");
            this.restrictionDiagramChartConfig.series[0]["data"][7].y = this.restrictionListsCount.list_reach_candidate_count;
            this.restrictionDiagramChartConfig.series[0]["data"][7].name = this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE");
            this.restrictionDiagramChartConfig.series[0]["data"][8].y = this.restrictionListsCount.list_sin_count;
            this.restrictionDiagramChartConfig.series[0]["data"][8].name = this.translate.instant("RESTRICTION_LIST_SIN");
            this.restrictionDiagramChartConfig.series[0]["data"][9].y = this.restrictionListsCount.list_sll_count;
            this.restrictionDiagramChartConfig.series[0]["data"][9].name = this.translate.instant("RESTRICTION_LIST_SLL");
            this.restrictionDiagramChartConfig.series[0]["data"][10].y = this.restrictionListsCount.list_water_directive_count;
            this.restrictionDiagramChartConfig.series[0]["data"][10].name = this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE");
            this.restrictionDiagramChartConfig.series[0]["data"][11].y = this.restrictionListsCount.list_BASTA_count;
            this.restrictionDiagramChartConfig.series[0]["data"][11].name = this.translate.instant("RESTRICTION_LIST_BASTA");
            this.restrictionDiagramChartConfig.series[0]["data"][12].y = this.restrictionListsCount.no_list_count;
            this.restrictionDiagramChartConfig.series[0]["data"][12].name = this.translate.instant("NO_RESTRICION_LIST");

            for (var n = 0; n < 12; n++){
              this.restrictionDiagramChartConfig.series[0]["data"][n].y==0?
              this.restrictionDiagramChartConfig.series[0]["data"][n].visible=false:
              this.restrictionDiagramChartConfig.series[0]["data"][n].visible=true;
            }

            // The limitlist isnt visible (obsolete)
            this.restrictionDiagramChartConfig.series[0]["data"][2].visible = false;

            if (this.subscribesToSIN != true) {
              this.restrictionDiagramChartConfig.series[0]["data"][8].visible = false;
            }

            if (this.subscribesToSLL != true) {
              this.restrictionDiagramChartConfig.series[0]["data"][9].visible = false;
            }

            if(this.subscribesToBASTA != true){
              this.restrictionDiagramChartConfig.series[0]["data"][11].visible = false;
            }

            this.productsById = _(this.products)
              .groupBy('supplier_id')
              .map((items,supplier_id) => ({ id:supplier_id, count: items.length, name: items[0].supplier_name }))
              .value();
              this.productsById = _.sortBy(this.productsById, 'count').reverse();
              this.productsByIdShort=_.cloneDeep(this.productsById);
              if((this.productsByIdShort.length)<this.productsByIdLength){
                this.productsByIdLength=this.productsByIdShort.length;
              }else{
                this.productsByIdLength=this.productsByIdLength;
              }
              this.productsByIdShort.length=this.productsByIdLength;


              this.amountInProductsByIdShort=0;
              this.productsByIdShort.forEach((element,index)=>{
                this.amountInProductsByIdShort+=element.count;
              })

              this.notInAmountInProductsByIdShort=this.products.length-this.amountInProductsByIdShort;
              this.productsByIdShort.push({ id:'-', count: this.notInAmountInProductsByIdShort, name: 'Övriga' });
              if(this.productsByIdLength!=6){
                this.productsByIdLength++;
              }
              this.supplierDiagramChartConfig.series[0]["data"]=[];
              for(var n=0;n<this.productsByIdLength;n++){
                this.supplierDiagramChartConfig.series[0]["data"].push({name:this.productsByIdShort[n].name,y:this.productsByIdShort[n].count});
              }

              for (var n = 0; n < this.productsByIdLength; n++){
                this.supplierDiagramChartConfig.series[0]["data"][n].y==0?
                this.supplierDiagramChartConfig.series[0]["data"][n].visible=false:
                this.supplierDiagramChartConfig.series[0]["data"][n].visible=true;
              }

            this.setChartData();

            if (this.subscribesToRiskAssessment) {

              var thisDeps=[];
              var thisTempOrgs = JSON.parse(localStorage.getItem('selectedOrganisation', )).orgs;
              if(thisTempOrgs) {
                //thisDeps.push(JSON.parse(localStorage.getItem('selectedOrganisation', )).id)
                //for (var n = 0; n < thisTempOrgs.length; n++) {
                //  thisDeps.push(thisTempOrgs[n].id);
                thisDeps=this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
                //}
              } else {
                thisTempOrgs="";
              }

              this.productsOwnRB=[];
              this.itemstoold=[];
              this.productsOwnRBDone=0;
              this.productsOwnRBOngoing=0;
              this.productsUniqueOwnRB=0;

              this.productsOwnARB=[];
              this.itemstooldARB=[];
              this.productsOwnARBDone=0;
              this.productsOwnARBOngoing=0;
              this.productsUniqueOwnARB=0;

              this.assessmentChemicalService.getNumberOfRiskAssessments();
              this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
                next: info => {

                  if (info != null) {
                    // 1 year from now as a numeric value
                    var timeToCheck = moment().subtract('1', 'years').format("YYYYMMDD");
                    var tempprod=[];
                    var tempprodARB=[];

                    for (var n = 0; n < info.length; n++) {
                      if(info[n].type=='C'){
                        if(thisDeps.indexOf(info[n].organisation_id)!=-1) {
                          this.productsOwnRB.push(info[n].product_id);
                          if(info[n].created_date&&(info[n].created_date.substring(0,10).replaceAll('-','')<timeToCheck)){
                            this.itemstoold.push({'id':info[n].id,
                                                  'age':info[n].created_date.substring(0,10),
                                                  'product':info[n].product_id})
                          }
                          if(info[n].status==3){
                            this.productsOwnRBDone++;
                          }else{
                            this.productsOwnRBOngoing++;
                          }

                          if(tempprod.indexOf(info[n].product_id)==-1){
                            tempprod.push(info[n].product_id);
                            this.productsUniqueOwnRB++;
                          }
                        }
                      }
                      if(info[n].type=='A'){
                        if(thisDeps.indexOf(info[n].organisation_id)!=-1) {
                          this.productsOwnARB.push(info[n].id);
                          if(info[n].created_date&&(info[n].created_date.substring(0,10).replaceAll('-','')<timeToCheck)){
                            this.itemstooldARB.push({'id':info[n].id,
                                                  'age':info[n].created_date.substring(0,10)
                                                })
                          }
                          if(info[n].status==3){
                            this.productsOwnARBDone++;
                          }else{
                            this.productsOwnARBOngoing++;
                          }

                          if(tempprodARB.indexOf(info[n].id)==-1){
                            tempprodARB.push(info[n].id);
                            this.productsUniqueOwnARB++;
                          }
                        }
                      }
                    }
                  }
                },
                error: error => {
                }
              })
            }

            this.loadCatalogue();
            this.getAllCoordinatorSubjects();
            if(this.subscribesToSubstitution==true){
              this.getAllSubstitutionSubjects();
            }
            if(this.subscribesToStyren==true){
              this.getAllStyrenproducts();
            }
            this.loadHistory();
          }
        },
        error: error => {
        }
      });
    }
  }

  resetChartData() {
    for (var xi = 0; xi < 10; xi++) {
      this.productgroupDiagramChartConfig.series[0]["data"][xi].y = 0;
      this.productgroupDiagramChartConfig.series[0]["data"][xi].name = "";
    }

    this.restrictionDiagramChartConfig.series[0]["data"][0].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][1].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][2].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][3].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][4].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][5].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][6].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][7].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][8].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][9].y = 0;
    this.restrictionDiagramChartConfig.series[0]["data"][10].y = 0;
    this.specialDiagramChartConfig.series[0]["data"] = [0, 0, 0, 0, 0, 0];
    this.pictogramChartConfig.series[0]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.riskPrioDiagramChartConfig.series[0]["data"][0].y = 0;
    this.riskPrioDiagramChartConfig.series[0]["data"][1].y = 0;
    this.riskPrioDiagramChartConfig.series[0]["data"][2].y = 0;
    this.riskPrioDiagramChartConfig.series[0]["data"][3].y = 0;
    this.riskPrioDiagramChartConfig.series[0]["data"][4].y = 0;
    this.environmentDiagramChartConfig.series[0]["data"][0].y = 0;
    this.environmentDiagramChartConfig.series[0]["data"][1].y = 0;
    this.environmentDiagramChartConfig.series[0]["data"][2].y = 0;
  }

  setChartData() {
    this.dynamicTitle=this.translate.instant("QUANTITY");
    var pictogramsCount;
    for (var i = 0; i < this.productsCount; i += 1) {
      if ( this.products[i]) {
        if (this.subscribesToReportEnvironment) {

          if (this.products[i].eco_eu) {
            this.environmentDiagramChartConfig.series[0]["data"][0].y +=1;
            this.ecoCounter += 1;
          }

          if (this.products[i].eco_svanen) {
            this.environmentDiagramChartConfig.series[0]["data"][1].y +=1;
            this.ecoCounter += 1;
          }

          if (this.products[i].eco_GEC) {
            this.environmentDiagramChartConfig.series[0]["data"][2].y +=1;
            this.ecoCounter += 1;
          }

          this.environmentDiagramChartConfig.series[0]["data"][0].name = this.translate.instant("EU_FLOWER");
          this.environmentDiagramChartConfig.series[0]["data"][1].name = this.translate.instant("NORDIC_ECOLABELLED");
          this.environmentDiagramChartConfig.series[0]["data"][2].name = this.translate.instant("GOOD_ENVIROMENTAL_CHOICE");
        }
        if (this.products[i].risk_prio >= 1 && this.products[i].risk_prio <= 5) {
          var notNegativeController = (this.products[i].risk_prio - 1);
          if(notNegativeController == -1 || notNegativeController == undefined){
              notNegativeController = 0;
          }
          this.riskPrioDiagramChartConfig.series[0]["data"][notNegativeController].name = this.translate.instant("LEVEL") + " " + this.products[i].risk_prio;
          this.riskPrioDiagramChartConfig.series[0]["data"][notNegativeController].y += 1;
          this.riskPrioDiagramChartConfig.series[0]["data"][notNegativeController].key = this.products[i].risk_prio;
        }

        //ingen vacker lösning... Lånar den icke vackra lösningen
        if ((this.products[i].information != null) && (this.products[i].information != "") && (this.products[i].information != " ")) {
          this.specialDiagramChartConfig.series[0]["data"][0] +=1;
        }

        if ((this.products[i].training != null) && (this.products[i].training != "") && (this.products[i].training != " ")) {
          this.specialDiagramChartConfig.series[0]["data"][1] += 1;

        }

        if ((this.products[i].health_control != null) && (this.products[i].health_control != "") && (this.products[i].health_control != " ")) {
          this.specialDiagramChartConfig.series[0]["data"][2] +=1;

        }

        if ((this.products[i].fitness_for_duty != null) && (this.products[i].fitness_for_duty != "") && (this.products[i].fitness_for_duty != " ")) {
          this.specialDiagramChartConfig.series[0]["data"][3] +=1;
        }

        if ((this.products[i].no_special_demands != null) && (this.products[i].no_special_demands != "") && (this.products[i].no_special_demands != " ")) {
          this.specialDiagramChartConfig.series[0]["data"][4] +=1;
        }

        if ( this.products[i].information === null &&
          this.products[i].training === null &&
          this.products[i].health_control === null &&
          this.products[i].fitness_for_duty === null &&
          this.products[i].no_special_demands === null &&
          this.products[i].allergic === null) {
            this.specialDiagramChartConfig.series[0]["data"][5] +=1;
        }

        this.specialDiagramChartConfig['yAxis']['title'].text =this.dynamicTitle;
        this.pictogramChartConfig['yAxis']['title'].text =this.dynamicTitle;
        if ((this.products[i].pictograms) && (this.products[i].pictograms.length > 0)) {
          pictogramsCount = this.products[i].pictograms.length;
          for (var j = 0; j < pictogramsCount; j += 1) {
            switch (this.products[i].pictograms[j].name) {
              case "clp_is_corrosive":
                this.pictogramChartConfig.series[0]["data"][0] += 1;
                break;
              case "clp_is_gas_under_pressure":
                this.pictogramChartConfig.series[0]["data"][1] += 1;
                break;
              case "clp_is_caution":
                this.pictogramChartConfig.series[0]["data"][2] += 1;
                break;
              case "clp_is_explosive":
                this.pictogramChartConfig.series[0]["data"][3] += 1;
                break;
              case "clp_is_flammable":
                this.pictogramChartConfig.series[0]["data"][4] += 1;
                break;
              case "clp_is_dangerous_to_the_environment":
                this.pictogramChartConfig.series[0]["data"][5] += 1;
                break;
              case "clp_is_oxidising":
                this.pictogramChartConfig.series[0]["data"][6] += 1;
                break;
              case "clp_is_longer_term_health_hazards":
                this.pictogramChartConfig.series[0]["data"][7] += 1;
                break;
              case "clp_is_toxic":
                this.pictogramChartConfig.series[0]["data"][8] += 1;
                break;
              default:
                break;
              }
            }
          }
        }


    this.specialDiagramChartConfig.xAxis["categories"][0] = "<div class='text-center'>" +
          this.translate.instant("INFORMATION_DEMAND") + "<br>(AFS 2011:19 §37d)" +
      "</div>";

    this.specialDiagramChartConfig.xAxis["categories"][1] = "<div class='text-center'>" +
          this.translate.instant("TRAINING") + "<br>(AFS 2011:19 §37e)" +
      "</div>";

    this.specialDiagramChartConfig.xAxis["categories"][2] = "<div class='text-center'>" +
          this.translate.instant("HEALTH_CONTROL") + "<br>(AFS 2011:19 §37f)" +
      "</div>";

    this.specialDiagramChartConfig.xAxis["categories"][3] = "<div class='text-center'>" +
          this.translate.instant("FITNESS_FOR_DUTY") + "<br>(AFS 2011:19 §37g)" +
      "</div>";

    this.specialDiagramChartConfig.xAxis["categories"][4] = "<div class='text-center'>" +
      this.translate.instant("NO_SPECIAL_DEMANDS") +
    "</div>";

    this.specialDiagramChartConfig.xAxis["categories"][5] = "<div class='text-center'>" +
          this.translate.instant("HAS_NOT_BEEN_CHECKED") +
      "</div>";

    this.updateFlag = true;
  }
}

  chartClickRestriction(event){
    var searchFilter = [];
    switch (event.point.index) {
      case 0:
        searchFilter[0] = "list_amv_a";
        break;
      case 1:
        searchFilter[0] = "list_amv_b";
        break;

      case 2:
        searchFilter[0] = "list_chem_limit";
        break;

      case 3:
        searchFilter[0] = "list_chem_prio_p";
        break;

      case 4:
        searchFilter[0] = "list_chem_prio_u";
        break;

      case 5:
        searchFilter[0] = "list_reach_14";
        break;

      case 6:
        searchFilter[0] = "list_reach_17";
        break;

      case 7:
        searchFilter[0] = "list_reach_candidate";
        break;

      case 8:
        searchFilter[0] = "list_sin";
        break;

      case 9:
        searchFilter[0] = "list_sll";
        break;

      case 10:
        searchFilter[0] = "list_water_directive";
        break;

      case 11:
        searchFilter[0] = "list_BASTA";
        break;

      case 12:
        searchFilter[0] = "no_list";
        break;

      default:
        // No valid click
        return;
        break;
    }
    searchFilter[1] = 4;
    localStorage.setItem('searchFilter', JSON.stringify(searchFilter));
    this.router.navigate(['/productlist']);
  }

  chartClickSupplier(event){
    if(event.point.index||event.point.index===0){
      if(this.productsByIdShort[event.point.index].id!='-'){
        localStorage.setItem('supplierSearch', JSON.stringify(this.productsByIdShort[event.point.index].name));
        this.router.navigate(['/productlist']);
      }
    } else{
      return;
    }

  }

  chartClickCMRA(event){
    if(event.point.index||event.point.index===0){
      if(this.CMRA_amount[event.point.index]){
        if(event.point.index==0){
          localStorage.setItem('CMRASearch', JSON.stringify('C'));
        }
        if(event.point.index==1){
          localStorage.setItem('CMRASearch', JSON.stringify('M'));
        }
        if(event.point.index==2){
          localStorage.setItem('CMRASearch', JSON.stringify('R'));
        }
        if(event.point.index==3){
          localStorage.setItem('CMRASearch', JSON.stringify('A'));
        }
        this.router.navigate(['/productlist']);
      }
    } else {
      return;
    }
  }

  chartClickStyren(event){
  }

  tableClickSupplier(event){
    if(event){
      localStorage.setItem('supplierSearch', JSON.stringify(event.name));
      this.router.navigate(['/productlist']);
    } else{
      return;
    }
  }

  tableClickProduct(event){
    if(event){
      localStorage.setItem('supplierSearch', JSON.stringify(event.name));
      this.router.navigate(['/productlist']);
    } else{
      return;
    }
  }

  gotoProduct(id){
    if(id){
      localStorage.setItem('newsareqsearch', JSON.stringify(id));
      this.router.navigate(['/productlist']);
    } else {
      return;
    }

  }

  gotoStyrenProduct(id){
    if(id){
      localStorage.setItem('newstyren', JSON.stringify(id));
      this.router.navigate(['/productlist']);
    } else {
      return;
    }
  }

  getHHPhrases() {
    this.productService.getHPhrases(this.selectedOrganisation.id, this.sareqIds);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: hPhrases => {
        if (hPhrases == null) {}
        else {
          for(var i = 0; i < hPhrases.length; i++){
            for(var j = 0; j < this.products.length; j++) {
                if(hPhrases[i].sareq_id == this.products[j].sareq_id) {
                    if(this.products[j].h_phrases == undefined) {
                      this.products[j]['h_phrases'] = [];
                    }
                    this.products[j]['h_phrases'].push(hPhrases[i].hphrases);
                }
            }
          }
        }
        this.getCMRA_Category();
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getCMRA_Category(){
    this.CMRADiagramChartConfig['yAxis']['title'].text =this.dynamicTitle;
    this.products.forEach(element => {
      element.CMRA_Category=[];
      if((!element.h_phrases||element.h_phrases==null)&&element.allergic!=1){
        return;
      }
      if(_.toUpper(element.h_phrases).indexOf("H350")>-1||_.toUpper(element.h_phrases).indexOf("H351")>-1){
        element.CMRA_Category.push('C');
        this.CMRA_amount[0].count++;
      }
      if(_.toUpper(element.h_phrases).indexOf("340")>-1||_.toUpper(element.h_phrases).indexOf("H341")>-1){
        element.CMRA_Category.push('M');
        this.CMRA_amount[1].count++;
      }
      if(_.toUpper(element.h_phrases).indexOf("360")>-1||_.toUpper(element.h_phrases).indexOf("H361")>-1||_.toUpper(element.h_phrases).indexOf("H362")>-1){
        element.CMRA_Category.push('R');
        this.CMRA_amount[2].count++;
      }
      if(_.toUpper(element.h_phrases).indexOf("H317")>-1||_.toUpper(element.h_phrases).indexOf("H334")>-1
          ||_.toUpper(element.h_phrases).indexOf("EUH208")>-1||_.toUpper(element.h_phrases).indexOf("EUH205")>-1
          ||_.toUpper(element.h_phrases).indexOf("EUH204")>-1||_.toUpper(element.h_phrases).indexOf("EUH202")>-1
          ||element.allergic==1)
      {
        element.CMRA_Category.push('A');
        this.CMRA_amount[3].count++;
      }
    })

    this.CMRADiagramChartConfig.series[0]["data"]=[];
    for(var n=0;n<this.CMRA_amount.length;n++){
      this.CMRADiagramChartConfig.series[0]["data"].push({name:this.CMRA_amount[n].name,y:this.CMRA_amount[n].count});
    }

    for (var n = 0; n < this.CMRA_amount.length; n++){
      this.CMRADiagramChartConfig.series[0]["data"][n].y==0?
      this.CMRADiagramChartConfig.series[0]["data"][n].visible=false:
      this.CMRADiagramChartConfig.series[0]["data"][n].visible=true;
    }
    this.CMRADiagramIsFetched = true;
  }

  getProducts(){
    this.getProductsIsClicked=true;
    this.errorDate = "";
    this.changedProductsFrom=this.datePipe.transform(this.changedProductsFrom,'yyyy-MM-dd');
    this.changedProductsTo=this.datePipe.transform(this.changedProductsTo,'yyyy-MM-dd');
    if( (this.changedProductsFrom.substring(0,4)>1899 && this.changedProductsFrom.substring(0,4)<2100)&&
        (this.changedProductsTo.substring(0,4)>1899 && this.changedProductsTo.substring(0,4)<2100)&&
        this.changedProductsFrom.replaceAll("-","")<this.changedProductsTo.replaceAll("-",""))
    {

    } else {
      this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000))
      this.changedProductsTo = new Date();
      this.errorDate = "Ogiltigt datum";
      return;
    }
    var allSubdepartments=this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
    this.loadingChangedProducts=true;
    this.productService.getChangedProducts(this.organisation.id,allSubdepartments,this.changedProductsFrom,this.changedProductsTo);
    this.productService.getChangedProductsDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {this.loadingChangedProducts=false;}
        else {
          this.loadingChangedProducts=false;
          this.changedProducts=checkForChanges(info);
        }
      },
      error: error => {
        this.loadingChangedProducts=false;
        console.log("Error: ", error);
      }
    })
  }

  getAllStyrenproducts(){
    var subDepartmentsIdsStyren = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
      var listOfIdsStyren="";
      var tempIdsStyren = "";
      subDepartmentsIdsStyren.forEach(function(i, idDepartment, subDepartmentsIdsStyren){
        if (idDepartment === subDepartmentsIdsStyren.length - 1) {
          tempIdsStyren += "'"+i+"'";
        } else {
          tempIdsStyren += "'"+i+"'"+",";
        }
      });
      listOfIdsStyren="("+tempIdsStyren+")";

      this.productService.getStyrenProducts(this.organisation.id,listOfIdsStyren);
      this.productService.getProductStyrenDataListener().pipe(take(1)).subscribe({
        next: styrenProducts => {
          if (styrenProducts == null) {}
          else {
            this.styrenDiagramChartConfig.series[0]["data"]=[];
            this.styrensupplierarray = [];
            this.styrenproducts = [];
            var styrenuniquesupplierarray = [];
            var styrenuniquesareqid = [];
            for(var n=0;n<styrenProducts.length;n++){
              if(styrenuniquesupplierarray.length == 0){
                styrenuniquesupplierarray.push(styrenProducts[n].supplier_id);
                this.styrensupplierarray=[{
                  'sareq_id':styrenProducts[n].sareq_id,
                  'supplier_id':styrenProducts[n].supplier_id,
                  'supplier_name':styrenProducts[n].supplier_name,
                  'count':1
                }]
              } else if(styrenuniquesareqid.indexOf(styrenProducts[n].sareq_id)>-1) {
                // duplicate on other department
              } else if(styrenuniquesupplierarray.indexOf(styrenProducts[n].supplier_id)>-1) {
                this.styrensupplierarray.find(({ supplier_id }) => supplier_id === styrenProducts[n].supplier_id).count++;
              } else {
                this.styrensupplierarray.push({
                  'sareq_id':styrenProducts[n].sareq_id,
                  'supplier_id':styrenProducts[n].supplier_id,
                  'supplier_name':styrenProducts[n].supplier_name,
                  'count':1
                });
              styrenuniquesupplierarray.push(styrenProducts[n].supplier_id);
              }

              if(this.styrenproducts.length == 0){
                this.styrenproducts=[{
                  'sareq_id':styrenProducts[n].sareq_id,
                  'sareq_name':styrenProducts[n].sareq_name,
                  'org_name':styrenProducts[n].org_name,
                  'annual':styrenProducts[n].annual?styrenProducts[n].annual:''
                }]
                styrenuniquesareqid.push(styrenProducts[n].sareq_id);
              }
                // else if(styrenuniquesareqid.indexOf(styrenProducts[n].sareq_id)>-1) {
                // this.styrenproducts.find(({ sareqid }) => sareqid === styrenProducts[n].sareq_id).oname+=','+styrenProducts[n].Oname;
              // }
              else {
                this.styrenproducts.push({
                  'sareq_id':styrenProducts[n].sareq_id,
                  'sareq_name':styrenProducts[n].sareq_name,
                  'org_name':styrenProducts[n].org_name,
                  'annual':styrenProducts[n].annual?styrenProducts[n].annual:''
                });
                styrenuniquesareqid.push(styrenProducts[n].sareq_id);
              }
            }

            this.styrensupplierarray=_.sortBy(this.styrensupplierarray, 'count').reverse();
            this.styrenproducts=_.sortBy(this.styrenproducts, 'annual').reverse();

            for(var n=0;n<this.styrensupplierarray.length;n++){
              this.styrenDiagramChartConfig.series[0]["data"].push({
                'name':this.styrensupplierarray[n].supplier_name,
                'y':this.styrensupplierarray[n].count
              });
            }

            for (var n = 0; n < styrenProducts; n++){
              this.styrenDiagramChartConfig.series[0]["data"][n].y==0?
              this.styrenDiagramChartConfig.series[0]["data"][n].visible=false:
              this.styrenDiagramChartConfig.series[0]["data"][n].visible=true;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

function checkForChanges(products){
  var productsArray=[];
  var text=[];
  var date=[];
  var clp=[];
  var clpRemoved=[];
  var protective=[];
  var protectiveRemoved=[];
  var n = 1;
  products.forEach(element => {
    text = [];
    clp = [];
    clpRemoved = [];
    protective=[];
    protectiveRemoved=[];
    date = [];

    // Risknivå
    if(element.risk_prio!=element.risk_prio_history){text.push("Risknivå ändrad från "+element.risk_prio_history+" till "+element.risk_prio+" ")}

    // Pictogram
    if(element.clp_is_toxic!=element.clp_is_toxic_history){
      if(element.clp_is_toxic==1&&element.clp_is_toxic_history!=1){
        clp.push("/images/pictograms/clp_skull-tiny.png");
      }
      if(element.clp_is_toxic_history==1&&element.clp_is_toxic!=1){
        clpRemoved.push("/images/pictograms/clp_skull-tiny.png");
      }
    }
    if(element.clp_is_dangerous_to_the_environment!=element.clp_is_dangerous_to_the_environment_history){
      if(element.clp_is_dangerous_to_the_environment==1&&element.clp_is_dangerous_to_the_environment_history!=1){
        clp.push("/images/pictograms/clp_pollution-tiny.png");
      }
      if(element.clp_is_dangerous_to_the_environment_history==1&&element.clp_is_dangerous_to_the_environment!=1){
        clpRemoved.push("/images/pictograms/clp_pollution-tiny.png");
      }
    }
    if(element.clp_is_gas_under_pressure!=element.clp_is_gas_under_pressure_history){
      if(element.clp_is_gas_under_pressure==1&&element.clp_is_gas_under_pressure_history!=1){
        clp.push("/images/pictograms/clp_bottle-tiny.png");
      }
      if(element.clp_is_gas_under_pressure_history==1&&element.clp_is_gas_under_pressure!=1){
        clpRemoved.push("/images/pictograms/clp_bottle-tiny.png");
      }
    }
    if(element.clp_is_corrosive!=element.clp_is_corrosive_history){
      if(element.clp_is_corrosive==1&&element.clp_is_corrosive_history!=1){
        clp.push("/images/pictograms/clp_acid-tiny.png");
      }
      if(element.clp_is_corrosive_history==1&&element.clp_is_corrosive!=1){
        clpRemoved.push("/images/pictograms/clp_acid-tiny.png");
      }
    }
    if(element.clp_is_explosive!=element.clp_is_explosive_history){
      if(element.clp_is_explosive==1&&element.clp_is_explosive_history!=1){
        clp.push("/images/pictograms/clp_explosion-tiny.png");
      }
      if(element.clp_is_explosive_history==1&&element.clp_is_explosive!=1){
        clpRemoved.push("/images/pictograms/clp_explosion-tiny.png");
      }
    }
    if(element.clp_is_flammable!=element.clp_is_flammable_history){
      if(element.clp_is_flammable==1&&element.clp_is_flammable_history!=1){
        clp.push("/images/pictograms/clp_flame-tiny.png");
      }
      if(element.clp_is_flammable_history==1&&element.clp_is_flammable!=1){
        clpRemoved.push("/images/pictograms/clp_flame-tiny.png");
      }
    }
    if(element.clp_is_caution!=element.clp_is_caution_history){
      if(element.clp_is_caution==1&&element.clp_is_caution_history!=1){
        clp.push("/images/pictograms/clp_silhouete-tiny.png");
      }
      if(element.clp_is_caution_history==1&&element.clp_is_caution!=1){
        clpRemoved.push("/images/pictograms/clp_silhouete-tiny.png");
      }
    }
    if(element.clp_is_oxidising!=element.clp_is_oxidising_history){
      if(element.clp_is_oxidising==1&&element.clp_is_oxidising_history!=1){
        clp.push("/images/pictograms/clp_roundflame-tiny.png");
      }
      if(element.clp_is_oxidising_history==1&&element.clp_is_oxidising!=1){
        clpRemoved.push("/images/pictograms/clp_roundflame-tiny.png");
      }
    }
    if(element.clp_is_longer_term_health_hazards!=element.clp_is_longer_term_health_hazards_history){
      if(element.clp_is_longer_term_health_hazards==1&&element.clp_is_longer_term_health_hazards_history!=1){
        clp.push("/images/pictograms/clp_exclamation-tiny.png");
      }
      if(element.clp_is_longer_term_health_hazards_history==1&&element.clp_is_longer_term_health_hazards!=1){
        clpRemoved.push("/images/pictograms/clp_exclamation-tiny.png");
      }
    }
    if(element.kifs_is_harmful!=element.kifs_is_harmful_history){
      if(element.kifs_is_harmful==1&&element.kifs_is_harmful_history!=1){
        clp.push("/images/pictograms/kifs_korscol-tiny.png");
      }
      if(element.kifs_is_harmful_history==1&&element.kifs_is_harmful!=1){
        clpRemoved.push("/images/pictograms/kifs_korscol-tiny.png");
      }
    }
    if(element.kifs_is_toxic!=element.kifs_is_toxic_history){
      if(element.kifs_is_toxic==1&&element.kifs_is_toxic_history!=1){
        clp.push("/images/pictograms/kifs_giftcol-tiny.png");
      }
      if(element.kifs_is_toxic_history==1&&element.kifs_is_toxic!=1){
        clpRemoved.push("/images/pictograms/kifs_giftcol-tiny.png");
      }
    }
    if(element.kifs_is_corrosive!=element.kifs_is_corrosive_history){
      if(element.kifs_is_corrosive==1&&element.kifs_is_corrosive_history!=1){
        clp.push("/images/pictograms/kifs_fratcol-tiny.png");
      }
      if(element.kifs_is_corrosive_history==1&&element.kifs_is_corrosive!=1){
        clpRemoved.push("/images/pictograms/kifs_fratcol-tiny.png");
      }
    }
    if(element.kifs_is_dangerous_to_the_environment!=element.kifs_is_dangerous_to_the_environment_history){
      if(element.kifs_is_dangerous_to_the_environment==1&&element.kifs_is_dangerous_to_the_environment_history!=1){
        clp.push("/images/pictograms/kifs_miljocol-tiny.png");
      }
      if(element.kifs_is_dangerous_to_the_environment_history==1&&element.kifs_is_dangerous_to_the_environment!=1){
        clpRemoved.push("/images/pictograms/kifs_miljocol-tiny.png");
      }
    }
    if(element.kifs_is_flammable!=element.kifs_is_flammable_history){
      if(element.kifs_is_flammable==1&&element.kifs_is_flammable_history!=1){
        clp.push("/images/pictograms/kifs_brandcol-tiny.png");
      }
      if(element.kifs_is_flammable_history==1&&element.kifs_is_flammable!=1){
        clpRemoved.push("/images/pictograms/kifs_brandcol-tiny.png");
      }
    }
    if(element.kifs_is_flammable!=element.kifs_is_flammable_history){
      if(element.kifs_is_flammable==1&&element.kifs_is_flammable_history!=1){
        clp.push("/images/pictograms/kifs_brandcol-tiny.png");
      }
      if(element.kifs_is_flammable_history==1&&element.kifs_is_flammable!=1){
        clpRemoved.push("/images/pictograms/kifs_brandcol-tiny.png");
      }
    }
    if(element.kifs_is_explosive!=element.kifs_is_explosive_history){
      if(element.kifs_is_explosive==1&&element.kifs_is_explosive_history!=1){
        clp.push("/images/pictograms/kifs_explcol-tiny.png");
      }
      if(element.kifs_is_explosive_history==1&&element.kifs_is_explosive!=1){
        clpRemoved.push("/images/pictograms/kifs_explcol-tiny.png");
      }
    }
    if(element.kifs_is_oxidising!=element.kifs_is_oxidising_history){
      if(element.kifs_is_oxidising==1&&element.kifs_is_oxidising_history!=1){
        clp.push("/images/pictograms/kifs_oxidcol-tiny.png");
      }
      if(element.kifs_is_oxidising_history==1&&element.kifs_is_oxidising!=1){
        clpRemoved.push("/images/pictograms/kifs_oxidcol-tiny.png");
      }
    }
    if(element.kifs_is_warning!=element.kifs_is_warning_history){
      if(element.kifs_is_warning==1&&element.kifs_is_warning_history!=1){
        clp.push("/images/pictograms/kifs_varncol-tiny.png");
      }
      if(element.kifs_is_warning_history==1&&element.kifs_is_warning!=1){
        clpRemoved.push("/images/pictograms/kifs_varncol-tiny.png");
      }
    }

    // Skyddssymboler
    if(element.image_mask!=element.image_mask_history){
      if(element.image_mask==1&&element.image_mask_history!=1){
        protective.push("/images/protective/mask-tiny.png");
      }
      if(element.image_mask_history==1&&element.image_mask!=1){
        protectiveRemoved.push("/images/protective/mask-tiny.png");
      }
    }
    if(element.image_boots!=element.image_boots_history){
      if(element.image_boots==1&&element.image_boots_history!=1){
        protective.push("/images/protective/boots-tiny.png");
      }
      if(element.image_boots_history==1&&element.image_boots!=1){
        protectiveRemoved.push("/images/protective/boots-tiny.png");
      }
    }
    if(element.image_breath!=element.image_breath_history){
      if(element.image_breath==1&&element.image_breath_history!=1){
        protective.push("/images/protective/breath-tiny.png");
      }
      if(element.image_breath_history==1&&element.image_breath!=1){
        protectiveRemoved.push("/images/protective/breath-tiny.png");
      }
    }
    if(element.image_clothes!=element.image_clothes_history){
      if(element.image_clothes==1&&element.image_clothes_history!=1){
        protective.push("/images/protective/clothes-tiny.png");
      }
      if(element.image_clothes_history==1&&element.image_clothes!=1){
        protectiveRemoved.push("/images/protective/clothes-tiny.png");
      }
    }
    if(element.image_gloves!=element.image_gloves_history){
      if(element.image_gloves==1&&element.image_gloves_history!=1){
        protective.push("/images/protective/gloves-tiny.png");
      }
      if(element.image_gloves_history==1&&element.image_gloves!=1){
        protectiveRemoved.push("/images/protective/gloves-tiny.png");
      }
    }
    if(element.image_glasses!=element.image_glasses_history){
      if(element.image_glasses==1&&element.image_glasses_history!=1){
        protective.push("/images/protective/glasses-tiny.png");
      }
      if(element.image_glasses_history==1&&element.image_glasses!=1){
        protectiveRemoved.push("/images/protective/glasses-tiny.png");
      }
    }

    date.push("Revisionsdatum: "+element.cdoc_date.substring(0,10));
    date.push("Datum ändringar gjordes: "+element.created_date.substring(0,10));
    productsArray.push({
      'itemnumber':n,
      'name':element.name,
      'value':text,
      'date':date,
      'clp':clp,
      'clpRemoved':clpRemoved,
      'protective':protective,
      'protectiveRemoved':protectiveRemoved
    });
    n++;
  });
  return productsArray;
}

