import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { take } from 'rxjs/operators';
import { ModalAddDepartmentComponent } from 'src/app/modals/modal-add-department/modal-add-department.component';
import { ModalEditDepartmentComponent } from 'src/app/modals/modal-edit-department/modal-edit-department.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})

export class DepartmentsComponent implements OnInit, OnDestroy {
  maxLevel;
  currentUser: any;
  selectedOrganisation: any;
  organisation: any;
  selectedDepartment: any;
  departmentNames: any;
  allDepartments: any;
  oldid: number;
  itemArray: any;
  hideTree: boolean;
  newDepartmentName: any;
  departmentDeleted: boolean;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean;
  subscribesToFreemium: boolean;
  currentLanguage: any;
  dragDepartment: any;
  selectedDepartment2:any=null;
  departmentFiles:any;
  startProductCount: number = 0;
  productCount: number = 0;
  intervals: number = 0;
  intervalId: any;
  subscription: Subscription;
  loadingData: boolean=false;
  departmentName: any;

  constructor(private authService:AuthService,
              private modalService: NgbModal,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              private productService: ProductService,
              private router: Router,
              private utilService: UtilService) { }

  ngOnDestroy() {
    this.clearProductCountCheck();
  }

  ngOnInit(): void {
    this.maxLevel = this.getMaxLevel();
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.dragDepartment = null;
    this.selectedDepartment2=null;
    this.currentLanguage = localStorage.getItem('usedLanguage');

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
    {
      this.logout();
    }

    this.departmentNames = [];
    if (this.organisation) {
      for (var i = 0; i < this.organisation.orgs.length; i++)
      {
        this.departmentNames.push(this.organisation.orgs[i].name);
      }
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");
    this.itemArray = new Array();
    this.allDepartments = [];
    this.setOrgTree(this.organisation, -1);
  }

  logout() {
    this.authService.logout();
  }

  getDepartmentParent(department, newParent) {

    department.parent = newParent;
    this.organisationService.makeOrganisationTreeChanges(department);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: department => {
        if (department != null) {
          this.organisationService.getOrganisation(this.organisation.id);
          this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
            next: organisation => {
              if (organisation != null) {
                this.organisationService.getOrganisation(this.organisation.id);
                this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
                  next: organisation => {
                    if (organisation != null) {
                      localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                      localStorage.setItem('set','set');
                      window.location.reload();
                    }
                  },
                  error: error => {
                    console.log("Error: ", error);
                  }
                })
              }
            },
            error: error => {
              console.log("Error: ", error);
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  onDragStart(event, data) {
    event.dataTransfer.setData('data', JSON.stringify(data));
  }

  onDrop(event, newDepartment) {
    let tmp = event.dataTransfer.getData('data');
    var department = JSON.parse(tmp);
    var listOfIds=this.utilService.getAllSubdepartments(department,true);
    if(department.id==newDepartment.id){
      Swal.fire(
        this.translate.instant("TREE_ERROR_HEADER"),
        this.translate.instant("TREE_ERROR_SUBJECT1")+"<br/><hr/><br/>"+this.translate.instant("TREE_ERROR_SUBJECT2"),
        "error"
      );
      return;
    } else if(listOfIds.indexOf(newDepartment.id)>-1){
      Swal.fire(
        this.translate.instant("TREE_ERROR_HEADER"),
        '',
        "error"
      );
      return;
    } else {
      this.getDepartmentParent(department, newDepartment.id);
      event.preventDefault();
    }
  }

  allowDrop(event) {
    event.preventDefault();
    $(event.target).css('background-color','#FFF');
  }

  removeStyle(event) {
    $(event.target).css('background-color','#FFF');
  }

  setOrgTree(organisation, level) {

    if (organisation) {
      for (var i = 0; i < organisation.orgs.length; i++)
      {
        var tmpLevel = level;
        if (this.organisation.id == organisation.orgs[i].parent) {
          tmpLevel = 1;
        }
        organisation.orgs[i].level = tmpLevel;

        /* default status of the department tree */
        if (organisation.orgs[i].level == 1) {
          organisation.orgs[i].treeHide = false;
        }
        else {
          organisation.orgs[i].treeHide = false;
        }

        this.allDepartments.push(organisation.orgs[i]);
        if (organisation.orgs[i].orgs.length > 0) {
          tmpLevel += 1;
          this.setOrgTree(organisation.orgs[i], tmpLevel);
        }
      }
    }
  }

  hideSubDepartments(department) {
    for (let i = 0; i < department.orgs.length; i++) {
      if (department.orgs[i].treeHide == false) {
        this.itemArray.push(department.orgs[i].id);
      }
    }
  }

  showOrHideTreeItem(department) {
    if ((this.hideTree == true) && (department.level > 1)) {
      return true;
    }
    if (this.itemArray.length > 0) {
      if (this.itemArray.indexOf(department.id) > -1) {
        this.oldid = null;
        this.itemArray.splice(this.itemArray.indexOf(department.id), 1);
        if (department.treeHide == true) {
          department.treeHide = false;
        } else {
          department.treeHide = true;
          this.hideSubDepartments(department)
        }
        return department.treeHide;
      } else {
        return department.treeHide;
      }
    } else {
      return department.treeHide;
    }
  }

  hasChildren(department) {
    var count = this.allDepartments.length;
    for (let i = 0; i < count; i++) {
      if (this.allDepartments[i].parent == department.id) {
        let string = department.id;
        return string;
      }
    }
    let string = department.id;
    return string;
  }

  displayTreeItem(department) {
    if (this.hideTree == true) {
      this.hideTree = false;
      this.oldid = null;
      this.itemArray = [];
    }

    if (this.oldid != department.id) {
      this.oldid = department.id;
      var count = department.orgs.length;
      for (let i = 0; i < count; i++) {
        if (this.itemArray.indexOf(+department.orgs.parent) == -1) {
          this.itemArray.push(department.orgs[i].id);
        }
      }
    } else {
      this.oldid = null;
      this.itemArray = [];
    }
  }

  showAddDepartmentModal(parentDepartment) {
    const modalRef = this.modalService.open(ModalAddDepartmentComponent);
    modalRef.componentInstance.newDepartmentName = this.newDepartmentName;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((receivedObject) => {
      this.organisationService.createNewDepartment(this.organisation, receivedObject, parentDepartment.id);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: department => {
          if (department != null) {
            this.organisationService.getOrganisation(this.organisation.id);
            this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
              next: organisation => {
                if (organisation != null) {
                  localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                  localStorage.removeItem("allDepartments");
                  localStorage.setItem('set','set');
                  window.location.reload();
                }
              },
              error: error => {
                console.log("Error: ", error);
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    })
  }

  showEditDepartmentModal(department) {
    const modalRef = this.modalService.open(ModalEditDepartmentComponent);
    modalRef.componentInstance.depName = department.name;
    modalRef.componentInstance.depInfo = department.misc;
    modalRef.componentInstance.depAdressVisit = department.address_visit;
    modalRef.componentInstance.depId = department.id
    modalRef.componentInstance.newDepartmentName = this.newDepartmentName;
    var depId = department.id;

    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((receivedObject) => {
      if (receivedObject != null) {
        department.name = receivedObject.name;
        department.misc = receivedObject.info;
        department.address_visit = receivedObject.adress;

        this.organisationService.updateDepartment(department.id, department);
        this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
          next: department => {
            if (department != null) {
              this.organisationService.getOrganisation(this.organisation.id);
              this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
                next: organisation => {
                  if (organisation != null) {
                    localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                    if ( this.selectedDepartment.id == depId ) {
                      let department = this.utilService.getDepartmentObjectFromId(depId,JSON.parse(localStorage.getItem('rootOrganisation', )));
                      localStorage.setItem('selectedDepartment', JSON.stringify(department));
                    }
                    localStorage.removeItem("allDepartments");
                    localStorage.setItem('set','set');
                    window.location.reload();
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                }
              })
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    })
  }

  removeDepartment(department) {
    this.departmentName = department.name;

    // First check if the department has any subdepartments
    if ( department.orgs.length > 0 ) {
        Swal.fire(
          this.translate.instant("REMOVE_DEPARTMENT_SUBBDEPARTMENTS_ERROR_HEADER"),
          this.translate.instant("REMOVE_DEPARTMENT_SUBBDEPARTMENTS_ERROR_MESSAGE"),
          "error"
        );
      return;
    }

    // You should not be able to remove the department your currently on either
    if ( this.selectedDepartment.id == department.id ) {
      var text = this.translate.instant("REMOVE_DEPARTMENT_SAME_ERROR_MESSAGE_1") + '\n' + this.translate.instant("REMOVE_DEPARTMENT_SAME_ERROR_MESSAGE_2");
      Swal.fire(
        this.translate.instant("REMOVE_DEPARTMENT_SAME_ERROR_HEADER"),
        text,
        "error"
      );
      return;
    }

    Swal.fire({
      title: this.translate.instant("REMOVE_DEPARTMENT") + "?",
      html: this.translate.instant("REMOVE_DEPARTMENT_WARNING") + " " + department.name + "?" + "<br><br>" +
      `<span style="color: red;">${this.translate.instant("REMOVE_DEPARTMENT_WARNING2")}</span>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingData = true;
        this.startCheckingProductCount(department.id);
        /*setTimeout(() => {
          this.loadingData = false;
          this.clearProductCountCheck();
        }
        , 30000);
        return;*/
        var startTime = Date.now();
        this.organisationService.removeDepartment(department.id);
        this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
          next: response => {
            this.organisationService.getOrganisation(this.organisation.id);
            this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
              next: organisation => {
                var elapsedTime = Date.now() - startTime;
                var remainingTime = 15000 - elapsedTime;
                if ( remainingTime < 0 ) {remainingTime = 0;}
                const finalize = () => {
                  this.loadingData = false;
                  this.clearProductCountCheck();
                  if (organisation != null) {
                    localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                    localStorage.removeItem("allDepartments");
                    localStorage.setItem('set', 'set');
                    window.location.reload();
                  }
                };

                const checkProductCount = () => {
                  if (this.productCount <= 0) {
                    clearInterval(intervalId);
                    finalize();
                  }
                };

                const intervalId = setInterval(checkProductCount, 1000); // Check every 100ms

                if (remainingTime > 0) {
                  setTimeout(() => {
                    clearInterval(intervalId);
                    finalize();
                  }, remainingTime);
                } else {
                  finalize();
                }
              },
              error: error => {
                this.clearProductCountCheck();
                console.log("Error: ", error);
              }
            })
          },
          error: error => {
            this.clearProductCountCheck();
            console.log("Error: ", error);
          }
        })
      }
    })
  }

  submit() {

  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }

  selectDep(dep){
    this.selectedDepartment2=dep;
    this.loadFiles();
  }

  loadFiles(){
    this.departmentFiles = [];
    if (this.selectedDepartment2.id) {
      this.organisationService.getAllFiles(this.selectedDepartment2.id);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: documents => {
          if (documents == null) {}
          else {
            this.departmentFiles = documents;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  openDepartmentDocumentInNewWindow(fileId) {
    this.organisationService.getFileWithId(this.selectedDepartment2.id, fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          console.log("Error: Could not get document");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  getIndentation(level: number): string {
    return '-'.repeat(level);
  }

  getIndentationStyle(level: number): { [key: string]: string } {
    return { 'margin-left': `${level * 16}px` }; // Adjust the multiplier as needed
  }

  getMaxLevel() {
    // Should really be the level of the deepest department but couldnt find a good way to do that
    return 10;
  }

  startCheckingProductCount(id) {
    this.productCount = 0;
    this.startProductCount = 0;
    this.intervals = 0;
    this.intervalId = setInterval(() => {
      this.intervals++;
      this.productService.getNumberOfProducts(id);
      this.productService.getProductNumberDataListener().pipe(take(1)).subscribe({
        next: (count) => {
          if ( this.startProductCount == 0 ) {
            this.startProductCount = count.productCount;
          }
          this.productCount = count.productCount;
        },
        error: (error) => {
          console.error('Error fetching product count:', error);
        }
      })
    }, 1000); // Check every second
  }

  clearProductCountCheck() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
