import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { ModalSendMailComponent } from 'src/app/modals/modal-send-mail/modal-send-mail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-edit-material',
  templateUrl: './edit-material.component.html',
  styleUrls: ['./edit-material.component.css']
})
export class EditMaterialComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  project: any;
  prev_project: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  showSubdepartments: boolean = false;
  organisationProducts: any=[];
  materialId: any;
  loadingData: boolean = false;
  projectCopy: any;

  projectSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };

  ngAfterViewInit(): void {
    $('#materialtable').tablesort();

  }

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService,
    private route: ActivatedRoute, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.loadingData = true;

    this.route.params.pipe(take(1)).subscribe(params => {
      this.materialId = params['id'];
    });

    this.projectCopy = JSON.parse(localStorage.getItem('projectCopy', ));
    if (this.projectCopy) {
      localStorage.removeItem("projectCopy");
    }

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    if (this.currentUser != null) {
      this.projectCopy = {
        id: "",
        name: "",
        number: "",
        issuer: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        project_date: new Date(),
        comments: "",
        products: []
      };
    }

    this.loadOrganisationProducts();
    this.loadProject();
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.project) != JSON.stringify(this.prev_project);
  }

  cancel() {
    if (this.hasPostChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.save();
        } else {
          this.router.navigate(['/materiallist']);
          localStorage.removeItem("projectCopy");
        }
      })
    }
    else
    {
      this.router.navigate(['/materiallist']);
      localStorage.removeItem("projectCopy");
    }
  }

  logout() {
    this.authService.logout();
  }

  goToMaterialList() {
    this.router.navigate(['/materiallist']);
    localStorage.removeItem("projectCopy");
  }

  loadProject() {

    this.materialListService.getSingleProject(this.materialId);
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: project => {
        this.loadingData = false;
        if (project == null) {}
        else {
          this.project = project;
          this.prev_project = _.cloneDeep(this.project);
        }
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    })
  }

  mailMaterialList() {
    this.loadingData = true;
    const modalRef = this.modalService.open(ModalSendMailComponent);
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((email) => {
      if (email == null) {
        this.loadingData = false;
        return;
      }
      var sareqIds = this.project.products.map(function (a) {return a.product_id});
      this.materialListService.createMultipleSDSLink(sareqIds);
      this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
        next: data => {
          if (1 == null) {} // This is because pdfMerge doesnt work
          else {
            if(data&&data!=null&&data.file_id){
              this.project['multiple_sds'] = data.file_id;
            } else {
              this.project['multiple_sds'] = '';
            }
            if (email.email.email) {
              this.project['receiver'] = email.email.email;
              this.materialListService.sendProjectAsMail(this.project);
                this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                  next: info => {
                    if (info == null) {}
                    else {
                      if (email.email.copyToSelf === true) {
                        this.project['receiver'] = this.currentUser.email;
                        this.materialListService.sendProjectAsMail(this.project);
                        this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                          next: data => {
                          },
                          error: error => {
                            console.log("Error: ", error);
                            this.loadingData = false;
                          }
                        })
                      }
                      Swal.fire("Ok", "Mailet skickades till adressen: " + this.project['receiver'], "success");
                      this.loadingData = false;
                    }
                  },
                  error: error => {
                    console.log("Error: ", error);
                    Swal.fire("Error", "Mailet was to sent!", "error");
                    this.loadingData = false;
                  }
                })
            } else {
              if (email.email.copyToSelf === true) {
                this.project['receiver'] = this.currentUser.email;
                this.materialListService.sendProjectAsMail(this.project);
                this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                  next: data => {
                  },
                  error: error => {
                    console.log("Error: ", error);
                    this.loadingData = false;
                  }
                })
              }
              Swal.fire("Ok", "Mailet skickades till adressen: " + this.project['receiver'], "success");
              this.loadingData = false;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "Mailet was to sent!", "error");
          this.loadingData = false;
        }
      })
    });
  }

  printMaterialList(){
    this.loadingData = true;
      if(this.project.products&&this.project.products.length>0){
        var sareqIds = this.project.products.map(function (a) {return a.product_id});
      } else {
        Swal.fire("Inga produkter", "Det finns inga produkter att göra en lista av", "error");
        return;
      }

      this.materialListService.createMultipleSDSLink(sareqIds);
      this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
        next: data => {
          if (1 == null) { this.loadingData = false; } // This is because pdfMerge doesnt work
          else {
            if(data&&data!=null&&data.file_id){
              this.project['multiple_sds'] = data.file_id;
            } else {
              this.project['multiple_sds'] = '';
            }

              this.project['receiver'] = 'support@cdoc.se';
              this.materialListService.getProjectAsPdf(this.project);
                this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                  next: info => {
                    if (info == null) {
                      this.loadingData = false;
                    }
                    else {
                      var file: any, fileURL: string, contentType: string;
                      contentType = "application/pdf";
                      file = new Blob([info], { type: contentType });
                      fileURL = URL.createObjectURL(file);
                      var opened=window.open(fileURL, "_blank");
                      if (opened) {
                        const htmlContent = `
                          <html>
                            <head>
                              <title>${this.project.name}</title>
                            </head>
                            <body>
                              <iframe src="${fileURL}" width="100%" height="100%" style="border:none;"></iframe>
                            </body>
                          </html>
                        `;
                        opened.document.write(htmlContent);
                        opened.document.close();
                      } else {
                        window.location.href = fileURL;
                      }
                      this.loadingData = false;
                    }
                  },
                  error: error => {
                    console.log("Error: ", error);
                    Swal.fire("Error", "Mailet was to sent!", "error");
                    this.loadingData = false;
                  }
                })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "Mailet was to sent!", "error");
          this.loadingData = false;
        }
      })
    //  });
  }

  copyMaterialList() {
    this.loadingData = true;
    if(typeof(this.project.products)!="undefined"){
      for(var i = 0; i < this.project.products.length; i++) {
        this.project.products[i].sareq_id=this.project.products[i].id;
        this.projectCopy.products.push( this.project.products[i]);
      }
    }
    localStorage.setItem("projectCopy", JSON.stringify(this.projectCopy));
    this.router.navigate(['/materialadd']);
    this.loadingData = false;
  }

  incProductRows(product) {

    var productToAdd = cloneDeep(product);
    productToAdd.editColor = true;
    productToAdd.editSurface = true;
    productToAdd.disabled = false;
    productToAdd.new = true;
    if (typeof(this.project.products)=='undefined') {
      this.project.products=[];
      product.isSelected=true;
      this.project.products.push(productToAdd);
    } else {
      product.isSelected=true;
      this.project.products.push(productToAdd);
    }
    if(typeof(product.counter)!='undefined'){
        product.counter++;
    }
  }

  deleteProduct(project_id, product){
    this.loadingData = true;
    for(let n=0;n<this.organisationProducts.length;n++){
      if(this.organisationProducts[n].sareq_id==product.sareq_id){
        delete this.organisationProducts[n].isSelected;
      }
    }
    var i, index = 0;

    for (i = this.project.products.length-1; i >= 0; i--) {
        if (this.project.products[i].product_id === product.product_id)
        {
            index = i;
            break;
        }
    }

    var info = {
        color: product.color,
        surface: product.surface,
    }

    product.counter--;
    this.project.products.splice(index, 1);

    // We only remove from database if there is anything to remove
    // and not on existing project when we want to copy new

    this.materialListService.deleteProduct(project_id, product.product_id, info);
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: project => {
        this.loadingData = false;
        if (project == null) {}
        else {
          for (i = this.project.products.length-1; i >= 0; i--) {
            if (this.project.products[i].product_id === product.product_id)
            {
                index = i;
                break;
            }

            if (product.counter <= 0)
            {
                delete product.isSelected;
                delete product.counter;
            }
          }
          this.loadingData = false;
        }
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    })
  }

  loadOrganisationProducts() {

    this.productService.getAllProducts();
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: products => {
        if (products == null) {}
        else {
          this.organisationProducts = products;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  onToggleShowSubDepartments(){
    this.showSubdepartments = !this.showSubdepartments;
    if (this.showSubdepartments){
      this.productService.AllWithSuborganisations();
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {}
          else {
            this.organisationProducts = products;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    } else{
        this.loadOrganisationProducts();
    }
  }

  editProduct(product) {
    product.edited = true;
  }

  save() {
    this.loadingData = true;
    this.project.project_date = this.utilService.adjustDateForTimezoneOffset(this.project.project_date);
    if(typeof(this.project.products)=="undefined"){
      this.project.products=[];
    }
    this.materialListService.updateProject(this.materialId, this.project);
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: project => {
        if (project == null) {this.loadingData = false;}
        else {
          this.router.navigate(['/materiallist']);
          this.loadingData = false;
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
      }
    })
  }

  delete() {
    this.loadingData = true;
    Swal.fire({
      title: this.translate.instant("REMOVE"),
      text: this.translate.instant("CONFIRM_REMOVE_MATERIALLIST"),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("NO"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.materialListService.deleteProject(this.materialId);
        this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
          next: project => {
            if (project == null) { this.loadingData = false; }
            else {
              this.router.navigate(['/materiallist']);
              this.loadingData = false;
            }
          },
          error: error => {
            console.log("Error: ", error);
            this.loadingData = false;
          }
        })
      } else if (result.isDenied || result.isDismissed) {
        this.loadingData = false;
      }
    });
  }
}
