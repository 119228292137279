<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>
<div *ngIf="loadingData==false" class="overflow-auto mb-40 md:mb-20">
  <div class="flex flex-col min-h-screen border border-gray-300">
    <div class="flex flex-row">
      <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
          <path fill="#000000"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
        </svg>
      </button>
      <div class="flex flex-row justify-center items-center w-full">
        <span class="text-xl font-bold px-8 py-2 text-black">{{'RISK_ASSESSMENT' | translate}}</span>
      </div>
    </div>
    <div *ngIf="loadingData==false&&chemical" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
      <div class="w-2/3 mt-2">
        <div class="flex flex-col px-4">
          <p class="text-gray-200"><a (click)="showManufacturerInformation(product)" id="itemProductButton1"><strong>{{product.supplier_name}}</strong></a></p>
          <span class="text-white text-2xl font-bold mr-4">{{product?.product_name}}</span>
          <p class="text-gray-200"><strong>{{"ORIGINAL" | translate}} {{"RISK_LEVEL" | translate}}</strong><span class="italic text-sm">:&nbsp;{{product.risk_prio}}<br></span></p>
          <div class="flex flex-row mt-4">
            <img class="-mr-3 cursor-pointer" *ngIf="subscribesToSds && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))"
                  tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon"
                  src="/images/icons/SDB_white.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
            <img  class="-mr-3 cursor-pointer" *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))"
                  tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}"
                  id="EXTSDBICon" src="/images/icons/EXT_SDB_white.svg" area-hidden="true"
                  (click)="openSdsInNewWindow(product)" />
            <img  class="-mr-3 cursor-pointer"
                  *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))"
                  tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}"
                  id="EXTSDBICon" src="/images/icons/EXT_SDB_white.svg" area-hidden="true"
                  (click)="openExtSdsInNewWindow(product)" />
            <img  class="-mr-3 cursor-pointer"  *ngIf="subscribesToGrb && product.risk_prio" tooltips
                  tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon"
                  src="/images/icons/GRB_white.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
          </div>
        </div>
      </div>
      <div *ngIf="chemical.risk" class="flex justify-start items-center ml-4 md:ml-0 w-1/5 text-right mt-2 md:m2-0">
        <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="chemical.risk==1"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_1_DESCRIPTION' | translate}}">
              {{chemical.risk}}</span>
        <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="chemical.risk==2"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_2_DESCRIPTION' | translate}}">
              {{chemical.risk}}</span>
        <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="chemical.risk==3"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_3_DESCRIPTION' | translate}}">
              {{chemical.risk}}</span>
        <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="chemical.risk==4"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_4_DESCRIPTION' | translate}}">
              {{chemical.risk}}</span>
        <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="chemical.risk==5"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_5_DESCRIPTION' | translate}}">
              {{chemical.risk}}</span>
      </div>
      <div class="hidden md:block w-1/5 text-left mt-2">
        <p class="text-white" *ngIf='chemical.food_approved==1'><strong>{{'FOOD_APPROVED' | translate}}</strong><span class="italic text-sm">:&nbsp;{{'YES' | translate}}<br></span></p>
        <p class="text-white" *ngIf='chemical.food_approved==0'><strong>{{'FOOD_APPROVED' | translate}}</strong><span class="italic text-sm">:&nbsp;{{'NO' | translate}}<br></span></p>
        <p class="text-white" *ngIf='chemical.food_approved==2'><strong>{{'FOOD_APPROVED' | translate}}</strong><span class="italic text-sm">:&nbsp;{{'NOT_ASSESSED' | translate}}<br></span></p>
        <p class="text-white" *ngIf="product.allergic==1"><strong>{{"ALLERGIC" | translate}}</strong><span class="italic text-sm">:&nbsp;{{'YES' | translate}}<br></span></p>
        <p class="text-white" *ngIf="product.allergic!=1"><strong>{{"ALLERGIC" | translate}}</strong><span class="italic text-sm">:&nbsp;{{'NO' | translate}}<br></span></p>
        <p class="text-white" *ngIf="chemical.status==0"><strong>{{"STATUS" | translate}}</strong><span class="italic text-sm">:&nbsp;{{'ONGOING' | translate}}<br></span></p>
        <p class="text-white" *ngIf="chemical.status==2"><strong>{{"STATUS" | translate}}</strong><span class="italic text-sm">:&nbsp;{{'FOR_VERIFICATION' | translate}}<br></span></p>
        <p class="text-white" *ngIf="chemical.status==3"><strong>{{"STATUS" | translate}}</strong><span class="italic text-sm">:&nbsp;{{'APPROVED2' | translate}}<br></span></p>
        <p class="text-white" *ngIf='chemical.status == 3 && chemical.approved_by != ""'><strong>{{'APPROVED_BY' | translate}}:</strong><span class="italic text-sm">&nbsp;{{chemical.approved_by}}<br></span></p>
        <!--<p class="text-white" *ngIf='chemical.substitution==1'><strong>{{'ACTION' | translate}}</strong>:&nbsp;{{'SUBSTITUTION' | translate}}<br></p>
        <p class="text-white" *ngIf='chemical.phasing_out==1'><strong>{{'ACTION' | translate}}</strong>:&nbsp;{{'OUT_PHASING' | translate}}<br></p>
        <p class="text-white" *ngIf='chemical.risk_minimzation==1'><strong>{{'ACTION' | translate}}</strong>:&nbsp;{{'RISK_MINIMISATION' | translate}}<br></p>
        <p class="text-white" *ngIf='chemical.no_action_required==1'><strong>{{'ACTION' | translate}}</strong>:&nbsp;{{'NO_ACTION_REQUIRED' | translate}}<br></p>-->
        <span class="text-white" *ngIf="getActions().length > 0">
          <strong>{{ 'ACTION' | translate }}</strong>:&nbsp;
          <span class="italic text-sm">{{ getActions().join(', ') }}</span>
        </span>
      </div>
      <div class="hidden md:block w-1/6 mt-2 text-right">
        <div *ngIf="chemical" class="flex px-7 justify-end" (click)="createLRB()">
          <img class="cursor-pointer" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}" id="LRBIcon" src="/images/icons/LRB_white.svg" area-hidden="true" />
        </div>
      </div>
    </div>

    <div *ngIf="loadingData==false&&chemical" class="flex flex-col bg-grey-400 w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4">
          <div class="flex flex-col md:flex-row">
            <!-- Pictograms Column -->
            <div class="w-1/2 pl-4">
              <div class="flex items-center space-x-2 mt-2">
                <span *ngFor="let pictogram of product.pictograms" tooltips tooltip-smart="true" title="{{pictogram.description}}">
                  <img class="w-12 h-12" title="{{pictogram.description}}" src="/images/pictograms/{{pictogram.filename}}-small.png" />
                </span>
              </div>
            </div>
            <!-- H Statements Column -->
            <div class="w-1/2 mt-4 md:mt-0">
              <span class="font-bold mt-4 px-6">{{"H_STATEMENTS" | translate}}</span><br>
              <table class="table-auto mt-1 cursor-default pointer-events-none">
                <tr *ngFor="let phrase of chemical.h_phrases">
                  <td class="table-row-column cursor-default pointer-events-none">{{phrase.h_phrase}}</td>
                  <td class="table-row-column cursor-default pointer-events-none">{{"PHRASE_" + phrase.h_phrase | translate}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
          <div class="flex-1">
            <label class="font-bold mb-1">{{"DATE" | translate}}</label><br>
            <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date"
                    [ngModel]="chemical.updated_date | date:'yyyy-MM-dd'"
                    (ngModelChange)="$event?chemical.updated_date = $event:null"
                    [ngModelOptions]="{standalone: true}">
          </div>
          <div *ngIf="issuer" class="flex-1">
            <label class="font-bold mb-1">{{"REGISTRAR" | translate}}</label><br>
            <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text"
                    placeholder="{{issuer.first_name}} {{issuer.last_name}}" [required]="true"
                    [disabled]="true">
          </div>
          <div *ngIf="!issuer" class="flex-1">
            <label class="font-bold mb-1">{{"REGISTRAR" | translate}}</label><br>
            <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text"
                    placeholder="-" [required]="true" [disabled]="true">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"PARTICIPANTS" | translate}}</label><br>
            <input title="{{'PARTICIPANTS' | translate}}" class="w-full border border-gray-300 p-2"
                    type="text" [(ngModel)]="chemical.participants"
                    (ngModelChange)="chemical.participants = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}</label><br>
            <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2"
                    type="text" [(ngModel)]="chemical.responsible"
                    (ngModelChange)="chemical.responsible = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"STATUS" | translate}}:</label><br>
            <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                <button type="button" class="button-white w-full justify-between border border-gray-300">
                    <span *ngIf="chemical.status==0" class="text-sm">{{'ONGOING' | translate}}</span>
                    <span *ngIf="chemical.status==1" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                    <span *ngIf="chemical.status==2" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                    <span *ngIf="chemical.status==3" class="text-sm">{{'APPROVED2' | translate}}</span>
                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                    <div class="py-1">
                        <div data-value="0" class="dropdown-menu-item" (click)="chemical.status=0" tabindex="1">{{'ONGOING' | translate}}</div>
                        <div data-value="2" class="dropdown-menu-item" (click)="chemical.status=2" tabindex="2">{{'FOR_VERIFICATION' | translate}}</div>
                        <div data-value="3" class="dropdown-menu-item" (click)="chemical.status=3" tabindex="3">{{'APPROVED2' | translate}}</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <span class="text-2xl font-bold mb-4 italic underline">{{"RISK_DESCRIPTION" | translate}}</span>
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4">
          <div class="mb-1">
            <span *ngIf="chemical.usage_area?.length > 271" class="text-red-500">{{" (max 270 tecken) "}}</span>
            <label class="font-bold mb-1 flex items-center">{{"USAGE_AREA" | translate}}
              <sup class="relative group">
                <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext1=!showChemicalRiskAssessmentHelptext1">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                  <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                </svg>
              </sup>
            </label>
            <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext1==true">{{"LRB_INFO_1" | translate}}</span>
          </div>
          <input title="{{'USAGE_AREA' | translate}}" class="w-full border border-gray-300 p-2"
                  type="text" [(ngModel)]="chemical.usage_area" (ngModelChange)="chemical.usage_area = $event"
                  [ngModelOptions]="{standalone: true}" maxlength="271">
          </div>
          <div class="mb-4">
            <span *ngIf="chemical.cdoc_comment?.length > 361" class="text-red-500">{{" (max 360 tecken) "}}</span>
            <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
            <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2"
                      [(ngModel)]="chemical.cdoc_comment" (ngModelChange)="chemical.cdoc_comment = $event"
                      [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="361"></textarea>
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}</label><br>
            <input title="{{'ATTACHE_FILES' | translate}}"
                    class="button-white mt-2 border border-gray-300 mr-1" type="file" accept="application/pdf"
                    (change)="incomingfile($event)" />
            <div *ngFor="let file of chemical.assessmentchemicalfiles; let i = index">
              <div class="flex flex-row items-center mt-4">
                <a (click)="openFileInNewWindow(file)">
                  <img class="w-6 h-6 mr-1 cursor-pointer" id="SDBIcon"
                        src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true"
                        title="{{file.file_name}}" />
                </a>
                <span class="text-black mr-1">{{file.file_name}}</span>
                <a (click)="fileRemove(i)">
                  <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                            21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                            5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                            3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                            stroke="#ff0000" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label class="font-bold mb-4">{{"ATTACHE_PICTURES" | translate}}</label><br>
            <input title="{{'ATTACHE_PICTURES' | translate}}"
                    class="button-white mt-2 border border-gray-300 mr-1 mb-2" type="file" id="upload-file"
                    accept=".png, .jpg, .jpeg" (change)="incomingimage($event)" />
            <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
              <div class="flex flex-row">
                <a>
                  <img title="{{'ATTACHE_PICTURES' | translate}}" [src]=image
                        height="{{pictureHeight}}" width="{{pictureWidth}}" />
                </a>
                <a (click)="pictureRemove(i)">
                  <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                          21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                          5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                          3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
        <div class="flex flex-col w-full mt-2">
        <span class="text-2xl font-bold mt-1 mb-4 italic underline">{{"LOCAL_CONDITIONS" | translate}}</span>
        <div class="flex flex-col w-full mt-2">
          <div class="flex flex-col md:flex-row mb-4">
            <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
              <input title="{{'NEGLIGIBLE_USE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                      type="checkbox" name="negligible_use" [(ngModel)]="negligible_use"
                      (ngModelChange)="negligible_use = $event" [ngModelOptions]="{standalone: true}"
                      (change)="onToggleShowNegligibleUse()">
                <span class="text-sm mb-1">{{"NEGLIGIBLE_USE" | translate}}</span>
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext2=!showChemicalRiskAssessmentHelptext2">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
            </div>
            <span class="text-xs italic ml-4 mt-2" *ngIf="showChemicalRiskAssessmentHelptext2==true"><br/>
              {{"LRB_INFO_2_1" | translate}}<br/>
              {{"LRB_INFO_2_2" | translate}}<br/>
              {{"LRB_INFO_2_3" | translate}}
            </span>
          </div>

          <div class="mb-4" *ngIf="negligible_use==true">
            <label class="font-bold mb-1">{{"NEGLIGIBLE_USE_COMMENT" | translate}}</label>
            <textarea title="{{'NEGLIGIBLE_USE_COMMENT' | translate}}"
                      class="w-full border border-gray-300 p-2" rows="2"
                      [(ngModel)]="chemical.negligible_use_comment"
                      (ngModelChange)="chemical.negligible_use_comment = $event"
                      [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="255"></textarea>
          </div>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_0" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext3=!showChemicalRiskAssessmentHelptext3">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                      <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext3==true">
                {{"LRB_INFO_3_1" | translate}}<br/>
                {{"LRB_INFO_3_2" | translate}}<br/>
                <ul class="list-disc ml-4">
                  <li>{{"LRB_INFO_3_3" | translate}}</li>
                  <li>{{"LRB_INFO_3_4" | translate}}</li>
                  <li>{{"LRB_INFO_3_5" | translate}}</li>
                </ul>
              </span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_0' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2"
                      [(ngModel)]="chemical.general_risks" (ngModelChange)="chemical.general_risks = $event"
                      [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_2" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext5=!showChemicalRiskAssessmentHelptext5">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext5==true">{{"LRB_INFO_5" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_2' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_2"
                      (ngModelChange)="chemical.question_2 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_3" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext6=!showChemicalRiskAssessmentHelptext6">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext6==true">{{"LRB_INFO_6" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_3' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_3"
                      (ngModelChange)="chemical.question_3 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_4" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext7=!showChemicalRiskAssessmentHelptext7">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext7==true">{{"LRB_INFO_7" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_4' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_4"
                      (ngModelChange)="chemical.question_4 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_5" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext8=!showChemicalRiskAssessmentHelptext8">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext8==true">{{"LRB_INFO_8" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_5' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_5"
                      (ngModelChange)="chemical.question_5 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_6" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext9=!showChemicalRiskAssessmentHelptext9">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext9==true">{{"LRB_INFO_9" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_6' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_6"
                      (ngModelChange)="chemical.question_6 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="mb-4">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"CHEMICAL_QUESTION_1" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext4=!showChemicalRiskAssessmentHelptext4">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext4==true">{{"LRB_INFO_4" | translate}}</span>
            </div>
            <textarea title="{{'CHEMICAL_QUESTION_1' | translate}}"
                      class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_1"
                      (ngModelChange)="chemical.question_1 = $event" [ngModelOptions]="{standalone: true}"
                      [disabled]="false" maxlength="255"></textarea>
          </div>
          <div class="flex flex-col mt-2 mb-4">
            <label class="font-bold">{{"FOOD_APPROVED" | translate}}</label>
              <div class="flex flex-row">
                <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                  <input title="{{'YES' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-1"
                          type="checkbox" name="food_approved_yes" [(ngModel)]="food_approved_yes"
                          (ngModelChange)="food_approved_yes = $event" [ngModelOptions]="{standalone: true}"
                          (change)="onToggleFoodApproved(1)">
                  <span class="text-sm">{{"YES" | translate}}</span>
                </div>
              <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                <input title="{{'NO' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-1"
                        type="checkbox" name="food_approved_no" [(ngModel)]="food_approved_no"
                        (ngModelChange)="food_approved_no = $event" [ngModelOptions]="{standalone: true}"
                        (change)="onToggleFoodApproved(0)">
                <span class="text-sm">{{"NO" | translate}}</span>
              </div>
              <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                <input title="{{'NOT_ASSESSED' | translate}}"
                        class="form-checkbox h-5 w-5 text-cdocblue mr-1" type="checkbox"
                        name="food_approved_not_assessed" [(ngModel)]="food_approved_not_assessed"
                        (ngModelChange)="food_approved_not_assessed = $event"
                        [ngModelOptions]="{standalone: true}" (change)="onToggleFoodApproved(2)">
                <span class="text-sm">{{"NOT_ASSESSED" | translate}}</span>
              </div>
            </div>
          </div>
          <div class="mb-4 mt-2">
            <div class="mb-1">
              <label class="font-bold flex items-center">{{"LOCAL_RISK_LEVEL" | translate}}
                <sup class="relative group">
                  <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext11=!showChemicalRiskAssessmentHelptext11">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                    <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                  </svg>
                </sup>
              </label>
              <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext11==true">{{"LRB_INFO_11" | translate}}</span>
            </div>
            <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
              <button type="button" class="button-white w-full justify-between border border-gray-300">
                <span class="text-sm">{{chemical?.risk}}</span>
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
              <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                <div *ngFor="let i of [1, 2, 3, 4, 5]" data-value="i" class="dropdown-menu-item"
                      (click)="chemical.risk=i" tabindex="i">{{i}}</div>
                </div>
              </div>
            </div>
          </div>
    </div>

    <div *ngIf="loadingData==false&&chemical" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="mb-1 mt-1">
        <span class="text-2xl font-bold italic underline flex items-center">{{"RISK_LIMITATION" | translate}}
          <sup class="relative group">
            <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext10=!showChemicalRiskAssessmentHelptext10">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
              <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
            </svg>
          </sup>
        </span>
        <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext10==true">{{"LRB_INFO_10" | translate}}</span>
      </div>
      <div class="flex flex-col w-full overflow-hidden justify-center items-center mb-2 p-7">
        <span class="text-2xl py-2 mb-8">{{"PROTECTIVE_GEAR_SYMBOLS" | translate}}</span>
          <div class="flex flex-row">
            <span *ngFor="let protective of protectiveImages">
              <img title="{{protective.filename}}" class="w-20 h-20 cursor-pointer mr-1"
                    [class.selected-border]="protectiveInWorkstep(protective.name)"
                    src="/images/protective/{{protective.filename}}-small.png"
                    (click)="addOrRemoveProtective(protective.name)" />
            </span>
          </div>
        </div>
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4">
          <label class="font-bold mb-1">{{"GENERAL" | translate}}<span class="text-red-500"
                  *ngIf="chemical.general_own?.length > 180" id="texterror">{{" (max 180 tecken) "}}</span></label><br>
          <textarea title="{{'GENERAL' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                    rows="1" maxlength="181" placeholder="{{chemical.general_own}}"
                    [(ngModel)]="chemical.general_own" (ngModelChange)="chemical.general_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"EYE_PROTECTION" | translate}}<span class="text-red-500"
                  *ngIf="chemical.eye_protection_own?.length > 180" id="texterror">{{" (max 180 tecken) "}}</span></label><br>
          <textarea title="{{'EYE_PROTECTION' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                    placeholder="{{chemical.eye_protection_own}}" [(ngModel)]="chemical.eye_protection_own"
                    (ngModelChange)="chemical.eye_protection_own = $event" [ngModelOptions]="{standalone: true}"
                    [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"HAND_PROTECTION" | translate}}<span class="text-red-500"
                  *ngIf="chemical.hand_protection_own?.length > 180" id="texterror">{{" (max 180 tecken) "}}</span></label><br>
          <textarea title="{{'HAND_PROTECTION' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                    placeholder="{{chemical.hand_protection_own}}" [(ngModel)]="chemical.hand_protection_own"
                    (ngModelChange)="chemical.hand_protection_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"RESPIRATORY" | translate}}<span class="text-red-500"
                  *ngIf="chemical.breath_protection_own?.length > 180" id="texterror">{{" (max 180 tecken) "}}</span></label><br>
          <textarea title="{{'RESPIRATORY' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                    placeholder="{{chemical.breath_protection_own}}"
                    [(ngModel)]="chemical.breath_protection_own"
                    (ngModelChange)="chemical.breath_protection_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"PROTECTIVE_CLOTHING" | translate}}<span class="text-red-500"
                *ngIf="chemical.body_protection_own?.length > 180" id="texterror">{{" (max 180 tecken) "}}</span></label><br>
          <textarea title="{{'PROTECTIVE_CLOTHING' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                    placeholder="{{chemical.body_protection_own}}" [(ngModel)]="chemical.body_protection_own"
                    (ngModelChange)="chemical.body_protection_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"HANDLING" | translate}}<span class="text-red-500"
                  *ngIf="chemical.handling_own?.length > 400" id="texterror">{{" (max 400 tecken) "}}</span></label><br>
          <textarea title="{{'HANDLING' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                    rows="3" maxlength="401" placeholder="{{chemical.handling_own}}"
                    [(ngModel)]="chemical.handling_own" (ngModelChange)="chemical.handling_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"STORAGE" | translate}}<span class="text-red-500"
                  *ngIf="chemical.storage_own?.length > 300" id="texterror">{{" (max 300 tecken) "}}</span></label><br>
          <textarea title="{{'STORAGE' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                    rows="3" maxlength="301" placeholder="{{chemical.storage_own}}"
                    [(ngModel)]="chemical.storage_own" (ngModelChange)="chemical.storage_own = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"WASTE_HANDLING_2" | translate}}<span class="text-red-500"
                  *ngIf="chemical.waste_own?.length > 500" id="texterror">{{" (max 500 tecken) "}}</span></label><br>
          <textarea title="{{'WASTE_HANDLING_2' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="4" maxlength="501"
                    placeholder="{{chemical.waste_own}}" [(ngModel)]="chemical.waste_own"
                    (ngModelChange)="chemical.waste_own = $event" [ngModelOptions]="{standalone: true}"
                    [disabled]="false" maxlength="501"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"FIRE_MEASURES" | translate}}<span class="text-red-500"
                  *ngIf="chemical.fire_own?.length > 570" id="texterror">{{" (max 570 tecken) "}}</span></label><br>
          <textarea title="{{'FIRE_MEASURES' | translate}}"
                    class="w-full border border-gray-300 p-2 text-sm" rows="4" maxlength="571"
                    placeholder="{{chemical.fire_own}}" [(ngModel)]="chemical.fire_own"
                    (ngModelChange)="chemical.fire_own = $event" [ngModelOptions]="{standalone: true}"
                    [disabled]="false" maxlength="571"></textarea>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"REMARKS" | translate}}<span class="text-red-500"
                *ngIf="chemical.remark?.length > 270" id="texterror">{{" (max 270 tecken) "}}</span></label><br>
          <textarea title="{{'REMARKS' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                    rows="1" maxlength="271" placeholder="{{chemical.remark}}" [(ngModel)]="chemical.remark"
                    (ngModelChange)="chemical.remark = $event" [ngModelOptions]="{standalone: true}"
                    [disabled]="false"></textarea>
        </div>
      </div>
    </div>
      <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
        <div class="flex flex-col w-full mt-2">
          <div class="mb-1">
            <span class="text-2xl font-bold italic underline flex items-center">{{"ACTIONS" | translate}}
              <sup class="relative group">
                <svg class="w-4 h-4 cursor-pointer" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="showChemicalRiskAssessmentHelptext12=!showChemicalRiskAssessmentHelptext12">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 13V11C5.5 7.68629 8.18629 5 11.5 5H13.5C16.8137 5 19.5 7.68629 19.5 11V13C19.5 16.3137 16.8137 19 13.5 19H11.5C8.18629 19 5.5 16.3137 5.5 13Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 9.63895C10.5 8.54895 11.4 7.94895 12.747 7.99995C13.7191 8.03367 14.492 8.82731 14.5 9.79995C14.547 10.7095 14.116 11.5776 13.363 12.09C12.723 12.5 12.3847 13.2487 12.5 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.5 16.5C12.2243 16.5 12 16.2757 12 16C12 15.7243 12.2243 15.5 12.5 15.5C12.7757 15.5 13 15.7243 13 16C13 16.2757 12.7757 16.5 12.5 16.5Z" fill="#000000"/>
                  <path d="M12.5 15C13.0523 15 13.5 15.4477 13.5 16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16C11.5 15.4477 11.9477 15 12.5 15Z" fill="#000000"/>
                </svg>
              </sup>
            </span>
            <span class="text-xs italic" *ngIf="showChemicalRiskAssessmentHelptext12==true">{{"LRB_INFO_12" | translate}}</span>
          </div>
          <div class="flex flex-col md:flex-row mb-4">
            <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
              <input title="{{'SUBSTITUTION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                      type="checkbox" name="substitution" [(ngModel)]="substitution"
                      (ngModelChange)="substitution = $event" [ngModelOptions]="{standalone: true}"
                      (change)="onToggleShowSubstitution()">
              <span class="text-sm mr-1">{{"SUBSTITUTION" | translate}}</span>
            </div>
          <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
            <input title="{{'OUT_PHASING' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                    type="checkbox" name="phasing_out" [(ngModel)]="phasing_out"
                    (ngModelChange)="phasing_out = $event" [ngModelOptions]="{standalone: true}"
                    (change)="onToggleShowOutphasing()">
            <span class="text-sm mr-1">{{"OUT_PHASING" | translate}}</span>
          </div>
          <div class="flex items-center space-x-2 ml-4 mt-2 mdm-4">
            <input title="{{'RISK_MINIMISATION' | translate}}"
                    class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="risk_minimzation"
                    [(ngModel)]="risk_minimzation" (ngModelChange)="risk_minimzation = $event"
                    [ngModelOptions]="{standalone: true}" (change)="onToggleShowRiskMinimzation()">
            <span class="text-sm mr-1">{{"RISK_MINIMISATION" | translate}}</span>
          </div>
          <div class="flex items-center space-x-2 ml-4 mt-2 mdm-4">
            <input title="{{'NO_ACTION_REQUIRED' | translate}}"
                    class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="no_action_required"
                    [(ngModel)]="no_action_required" (ngModelChange)="no_action_required = $event"
                    [ngModelOptions]="{standalone: true}" (change)="onToggleNoActionRequired()">
            <span class="text-sm mr-1">{{"NO_ACTION_REQUIRED" | translate}}</span>
          </div>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
          <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2"
                    [(ngModel)]="chemical.comments" (ngModelChange)="chemical.comments = $event"
                    [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center items-center mb-20 mt-4">
      <button *ngIf="chemical.id" class="button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
        <img title="{{'REMOVE' | translate}}" src="images/icons/trash-alt-svgrepo-com.svg" class="h-6 w-5">
        <span class="text-white text-lg mr-1">{{ 'REMOVE' | translate }}</span>
      </button>
      <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
        <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="h-6 w-5">
        <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
      </button>
    </div>
  </div>
