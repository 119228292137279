import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: "root",
})
export class SareqService {
  getSareqDataUpdated = new Subject<any>();
  getSareqDataIdsUpdated = new Subject<any>();
  getSareqHistoryUpdated = new Subject<any>();
  getSareqDataUpdatedGetAllEditSareq = new Subject<any>();
  getSareqDataUpdated2 = new Subject<any>();
  getSareqDataUpdatedGetAllEditSareqAmount= new Subject<any>();
  getSareqUpdateOverride = new Subject<any>();
  getSareqQRDataUpdated = new Subject<any>();

  constructor(
    public router: Router,
    private http: HttpClient,
    private utilService: UtilService,
    private productConversions: ProductConversionsService,
    private authService: AuthService
  ) {}

  getAll() {
    this.http
      .get<any>(
        BackendUrl + "/sareq",
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (substance) => {
          this.getSareqDataUpdated.next(substance);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getAllAndFilter(info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("data", info);
    params = params.append("token", token);
    this.http
      .get<any>(
        BackendUrl + "/sareq/getallandfilter",
        this.utilService.setHeadersAndParamsObject(info, "data", "s")
      )
      .pipe(take(1)).subscribe({
        next: (substance) => {
          this.getSareqDataUpdated.next(substance);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getAllForSareqEditAnotherDatalistener() {
    this.http
      .get<any>(
        BackendUrl + "/sareq",
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (substance) => {
          this.getSareqDataUpdatedGetAllEditSareq.next(substance);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getSareqDataUpdatedGetAllEditSareq.next(null);
        },
      });
  }

  getAllWithTransport(){
    this.http
      .get<any>(
        BackendUrl + "/sareq/allwithtransport",
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (substance) => {
          this.getSareqDataUpdated.next(substance);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getRestriktionlist(substance) {
    this.http
      .get(
        BackendUrl + "/sareq/coordinator/getRestriktionlist",
        this.utilService.setHeadersAndParamsObject(substance, "data", "s")
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  updateSareqWithIdDate(sareq) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put(BackendUrl + "/sareq/" + sareq.id + "/date", sareq, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["/sareq"]));
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  getSingle(id) {
    this.http
      .get(
        BackendUrl + "/sareq/" + id.id,
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  createSpecificGrb(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/sareq/" + id + "/createGRB", {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  createSpecificGrbEng(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/sareq/" + id + "/createGRBEng", {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  createSpecificGrbFin(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/sareq/" + id + "/createGRBFin", {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getSpecificSds(sdsId) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/sareq/sds/" + sdsId, {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          if(response==null||response.byteLength==0){
            this.getSareqDataUpdated.next(null);
          } else {
            this.getSareqDataUpdated.next(response);
          }
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getSpecificGrb(grbId) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/sareq/grb/" + grbId, {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          if(response==null||response.byteLength==0){
            this.getSareqDataUpdated.next(null);
          } else {
            this.getSareqDataUpdated.next(response);
          }
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getDocumentHistoryForSareqProduct(sareqId) {
    var result = { sds: null, grb: null, esdb: null },
      l,
      i;
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/sareq/" + sareqId + "/sds/history", {
        headers: headers,
        observe: "response",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          if (response.status === 200) {
            result.sds = response.body;
            if (_.isArray(result.sds)) {
              l = result.sds.length;
              for (i = 0; i < l; i += 1) {
                result.sds[i].timestamp = this.utilService.convertStringToDate(
                  result.sds[i].timestamp
                );
              }
            }
            this.http
              .get<any>(BackendUrl + "/sareq/" + sareqId + "/grb/history", {
                headers: headers,
                observe: "response",
              })
              .pipe(take(1)).subscribe({
                next: (response) => {
                  if (response.status === 200) {
                    result.grb = response.body;
                    if (_.isArray(result.grb)) {
                      l = result.grb.length;
                      for (i = 0; i < l; i += 1) {
                        result.grb[i].timestamp =
                          this.utilService.convertStringToDate(
                            result.grb[i].timestamp
                          );
                      }
                    }
                    this.http
                      .get<any>(
                        BackendUrl + "/sareq/" + sareqId + "/extsds/history",
                        { headers: headers, observe: "response" }
                      )
                      .pipe(take(1)).subscribe({
                        next: (response) => {
                          if (response.status === 200) {
                            result.esdb = response.body;
                            if (_.isArray(result.esdb)) {
                              l = result.esdb.length;
                              for (i = 0; i < l; i += 1) {
                                result.esdb[i].timestamp =
                                  this.utilService.convertStringToDate(
                                    result.esdb[i].timestamp
                                  );
                              }
                            }
                          } else {
                            throw response;
                          }
                        },
                        error: (error) => {
                          this.getSareqDataUpdated.next(null);
                        },
                      });
                  } else {
                    throw response;
                  }
                },
                error: (error) => {
                  this.getSareqDataUpdated.next(null);
                },
              });
          } else {
            throw response;
          }
          this.getSareqDataUpdated.next(result);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  getCoordinatorProducts(info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("data", info);
    params = params.append("token", token);
    this.http
      .get(BackendUrl + "/sareq/coordinator/products",
      this.utilService.setHeadersAndParamsObject(info, "data", "s"))
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  // These calls occur simultaniously and thus need different listeners
  // (or a timeout)
  getCoordinatorCalls(info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("data", info);
    params = params.append("token", token);
    this.http
      .get(BackendUrl + "/sareq/coordinator/calls",
      this.utilService.setHeadersAndParamsObject(info, "data", "s"))
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated2.next(response);
        },
        error: (error) => {
          this.getSareqDataUpdated2.next(null);
        },
      });
  }



  updateSareqWithId(id, info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put(BackendUrl + "/sareq/" + id, info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  createSareqWithId(info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .post(BackendUrl + "/sareq/", info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListAmvA(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/amv_a",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListAmvB(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/amv_b",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListReach14(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/reach_14",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListReach17(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/reach_17",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListReachLimit(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/chem_limit",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListReachPrio(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/chem_prio_u",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListReachPrio_p(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/chem_prio_p",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListCandidate(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/reach_candidate",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListWater(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/water_directive",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListSLL(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/SLL",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListBASTA(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/BASTA",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListSIN(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/SIN",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListPOP(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/pop",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  postRestrictionListROHS(file_id){
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "text/csv",
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append('data', file_id );
    this.http
      .post(BackendUrl + "/sareq/update_lists/rohs",'', { headers:headers, params:params})
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getSareqDataUpdated.next(value);
        },
        error: (error) => {
          this.getSareqDataUpdated.error(error);
          console.log(error);
          return;
        },
      });
  }

  Delete(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .delete(BackendUrl + "/sareq/" + id, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next("ok");
        },
        error: (error) => {
          this.getSareqDataUpdated.next(null);
        },
      });
  }

  approveSareq(id){
    let token = localStorage.getItem('token');
    if (localStorage.getItem('selectedOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
    }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.put<any>(BackendUrl + '/sareq/' + id + '/approve',"",{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqDataUpdated.next(null);
      }
    });
  }

  checkifnameexists(name){
    let token = localStorage.getItem('token');
    if (localStorage.getItem('selectedOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
    }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + '/sareq/checkifnameexists/'+name,{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqDataUpdated.next(null);
      }
    });
  }

  getname(id){
    let token = localStorage.getItem('token');
    if (localStorage.getItem('selectedOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
    }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + '/sareq/getname/'+id,{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqDataUpdated.next(null);
      }
    });
  }

  getAmountOfSareqProducts(){
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + '/sareq/getamountofsareqproducts/',{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqDataUpdatedGetAllEditSareqAmount.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqDataUpdatedGetAllEditSareqAmount.next(null);
      }
    });
  }

  getOverrideProducts(){
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + '/sareq/getoverrideproducts/',{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqUpdateOverride.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqUpdateOverride.next(null);
      }
    });
  }

  getSareqHistory(sareqId){
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + '/sareq/' + sareqId + '/sareqHistory',{headers: headers}).pipe(take(1)).subscribe({next: (response) => {
        this.getSareqHistoryUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSareqHistoryUpdated.next(null);
      }
    });
  }

  getFileIdByDate(sareqList,date) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      let params = new HttpParams();
      params = params.append("sareqList", JSON.stringify(sareqList));
      params = params.append("date", date);

      this.http
        .get(BackendUrl + "/sareq/getfileidbydate/", {
          headers: headers,
          params: params
        })
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSareqDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSareqDataUpdated.next(null);
          },
        });
    }
  }

  getSareqForMonthAndYear(users, month, year) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = params.append("users", JSON.stringify(users));
    params = params.append("month", month);
    params = params.append("year", year);

    this.http
      .get(BackendUrl + "/sareq/getSareqForMonthAndYear/", {
        headers: headers,
        params: params
      })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
    });
  }

  getDistinctAssigned(month, year) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = params.append("month", month);
    params = params.append("year", year);

    this.http
      .get(BackendUrl + "/sareq/getDistinctAssigned/", {
        headers: headers,
        params: params
      })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
    });
  }

  getStatusForIds(ids: string[], month: number, year: number): Observable<any> {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token
    });

    let params = new HttpParams();
    params = params.append("ids", JSON.stringify(ids));
    params = params.append("month", month.toString());
    params = params.append("year", year.toString());

    return this.http.get<any>(BackendUrl + "/sareq/getStatusForIds/", {
      headers: headers,
      params: params
    });
  }

  getSareqForUserDone(user, year) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = params.append("user", user);
    params = params.append("year", year);

    this.http
      .get(BackendUrl + "/sareq/getSareqForUserDone/", {
        headers: headers,
        params: params
      })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
    });
  }

  getSareqForUserReturn(user, year) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = params.append("user", user);
    params = params.append("year", year);

    this.http
      .get(BackendUrl + "/sareq/getSareqForUserReturn/", {
        headers: headers,
        params: params
      })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSareqDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSareqDataUpdated.next(null);
        },
    });
  }

  createQRForId(id) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token
    });

    let params = new HttpParams();
    params = params.append("id", id);

    this.http.get<any>(BackendUrl + "/sareq/createQRForId/", {
      headers: headers,
      params: params
    }).pipe(take(1))
    .subscribe({
      next: (response) => {
        this.getSareqQRDataUpdated.next(response);
      },
      error: (error) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.getSareqQRDataUpdated.next(null);
      },
  });
  }

  updateSareqWithIdDateFromEditSareq(id) {
    this.http
      .put(
        BackendUrl + "/sareq/" + id + "/datefromeditsareq",
        "",
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["/sareq"]));
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
  }

  public getSareqDataIdsListener() {
    return this.getSareqDataIdsUpdated.asObservable();
  }

  public getSareqDataListener() {
    return this.getSareqDataUpdated.asObservable();
  }

  public getSareqHistoryListener() {
    return this.getSareqHistoryUpdated.asObservable();
  }

  // Two calls occur simultaniously and need timeout or different listeners
  public getSareqDataListener2() {
    return this.getSareqDataUpdated2.asObservable();
  }

  public getSareqDataListenerSareqEdit() {
    return this.getSareqDataUpdatedGetAllEditSareq.asObservable();
  }

  public getSareqDataListenerSareqAmount() {
    return this.getSareqDataUpdatedGetAllEditSareqAmount.asObservable();
  }

  public getSareqDataListenerOverride() {
    return this.getSareqUpdateOverride.asObservable();
  }

  public getSareqQRDataListener() {
    return this.getSareqQRDataUpdated.asObservable();
  }
}
