<div class="flex flex-col border border-gray-200 mb-20">
  <button class="px-4 mt-4" title="{{'BACK' | translate}}" (click)="cancel()">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>

  <div class="flex w-full p-4 mb-20">
    <!-- Form Container -->
    <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">

      <div class="flex flex-col w-full mt-2">
        <p class="text-2xl font-bold mb-4">{{"CREATE_USER" | translate}}/{{"EDIT" | translate}}</p>

        <div *ngIf="organisation" class="mb-4">
          <label class="font-bold mb-1">{{"COMPANY" | translate}}:</label><br>
          <input title="{{'COMPANY' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="organisation.name" (ngModelChange)="organisation.name=$event" type="text" readonly>
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"USERNAME" | translate}}:</label><br>
          <input title="{{'USERNAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.username" (ngModelChange)="user.username=$event;validate(user.username);" type="text">
          <label class="text-red" *ngIf="!isValid||isError&&(user.username==''||user.username==null)">
            <span *ngIf="isValid">{{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}</span>
            <span *ngIf="!isValid">{{"FIELD_CONTAINS_INVALID_CHARACTER" | translate}}</span>
          </label>
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
          <input title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.email" (ngModelChange)="user.email=$event" type="text">
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"FIRST_NAME" | translate}}:</label><br>
          <input title="{{'FIRST_NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.first_name" (ngModelChange)="user.first_name=$event" type="text">
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"LAST_NAME" | translate}}:</label><br>
          <input title="{{'LAST_NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.last_name" (ngModelChange)="user.last_name=$event" type="text">
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"PHONE" | translate}}:</label><br>
          <input title="{{'PHONE' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.phone" (ngModelChange)="user.phone=$event" type="text">
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"LANGUAGE" | translate}}: </label><br>
          <div class="relative inline-block text-left w-full z-50" appDropdown>
            <button title="{{'LANGUAGE' | translate}}" type="button" class="button-white border justify-between order-gray-300 w-full">
              <label *ngIf="user.language==0" class="mt-1">Svenska</label>
              <label *ngIf="user.language==1" class="mt-1">English</label>
              <label *ngIf="user.language==2" class="mt-1">Suomi</label>
              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu right-1" #dropdownMenuInside>
                <div class="py-1">
                  <div class="dropdown-menu-item" (click)="setLanguage('fi')" data-value="fi" tabindex="1">Suomi</div>
                  <div class="dropdown-menu-item" (click)="setLanguage('sv')" data-value="sv" tabindex="2">Svenska</div>
                  <div class="dropdown-menu-item" (click)="setLanguage('en')" data-value="en" tabindex="3">English</div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"USER_ROLE" | translate}}: </label><br>
          <div class="relative inline-block text-left w-full z-40" appDropdown>
            <button title="{{'USER_ROLE' | translate}}" type="button" class="button-white border border-gray-300 justify-between order-gray-300 w-full">
              <label class="mt-1">{{userRole}}</label>
              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu right-1 overflow-y-auto max-h-64" #dropdownMenuInside>
                <div class="py-1">
                    <div *ngFor="let role of availableRoles" data-value="role" class="dropdown-menu-item" (click)="setRole(role)" tabindex="1">{{ role }}</div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="user&&currentUser&&!currentUser.is_local_admin&&!(user?.is_local_admin||user?.is_local_user)" class="mb-4">
          <label class="font-bold mb-1">{{"DEPARTMENT" | translate}}: </label><br>
          <div class="relative inline-block text-left w-full z-30" appDropdown>
            <button title="{{'DEPARTMENT' | translate}}" type="button" class="button-white border border-gray-300 justify-between order-gray-300 w-full">
              <label class="mt-1">{{currentDepartmentName}}</label>
              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu right-1 overflow-y-auto max-h-64" #dropdownMenuInside>
                <div class="py-1">
                    <div *ngFor="let subdepartment2 of departmentNames | FilterPipe : searchD" class="dropdown-menu-item" (click)="setFirstDepartment(subdepartment2)" tabindex="1">{{ subdepartment2.name }}</div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="(user&&currentUser&&!currentUser.is_local_admin)&&(user?.is_local_user||user?.is_local_admin||user?.is_local_assignee)" class="mb-4">
          <label class="font-bold mb-1">{{"DEPARTMENT" | translate}}: </label><br>
          <div class="relative inline-block text-left w-full z-20" appDropdown>
            <div class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white bg-teal-100 border border-gray-300 p-2">
              <div *ngFor="let department of userlocalDepartments">
                <div class="flex flex-row border bg-gray-200 border-blue-500 p-2 mr-2 rounded">
                  <div class="text-xs font-normal leading-none max-w-full flex-initial">{{department.name}}</div>
                  <div class="flex flex-auto flex-row-reverse">
                    <div>
                        <svg (click)="removeDepartment(department)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                  </div>
                </div>
              </div>
              <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div id="dropdownMenu" class="hidden dropdown-menu right-1 overflow-y-auto max-h-64" #dropdownMenuInside>
                <div class="py-1">
                    <div *ngFor="let department of departmentNames | FilterPipe : searchD" data-value="role" class="dropdown-menu-item" (click)="setDepartment(department)" tabindex="1">{{ department.name }}</div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="user" class="mb-4">
          <label class="font-bold mb-1">{{"PASSWORD" | translate}}:</label><br>
          <input *ngIf="showFakePass" title="{{'PASSWORD' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="displayPassword" (ngModelChange)="user.password=$event" type="text">
          <input *ngIf="!showFakePass" title="{{'PASSWORD' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="user.password" (ngModelChange)="user.password=$event" type="text">
          <label class="text-red-700" *ngIf="isError&&(user.password==''||user.password==null)">{{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}</label>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"STATIC_TOKEN" | translate}}:</label><br>
          <div class="flex flex-row items-center">
            <input title="{{'STATIC_TOKEN' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="cdocToken" (ngModelChange)="cdocToken=$event" type="text">
            <img class="h-6 w-6 ml-1 cursor-pointer" (click)="copyToClip()" title="{{'COPY' | translate}}" src="/images/icons/copy-to-clipboard-svgrepo-com.svg"/>
          </div>
          <div class="flex flex-row mt-2">
            <button class="button-green" title="{{'GENERATE_TOKEN' | translate}}" (click)="createToken()">
              <span class="text-lg">{{ 'GENERATE_TOKEN' | translate }}</span>
            </button>
            <button class="button-red" title="{{'REMOVE_TOKEN' | translate}}" (click)="deleteToken()">
              <span class="text-lg">{{ 'REMOVE_TOKEN' | translate }}</span>
            </button>
            <button *ngIf="cdocToken && cdocToken != '' && subscribesToQRCode" class="button-cdocblue" title="{{'GET_QR_STATIC' | translate}}" (click)="qrcodeStaticToken('statictoken')">
              <span class="text-lg text-white font-bold">{{ 'GET_QR_STATIC' | translate }}</span>
            </button>
          </div>
        </div>
        <div *ngIf="subscribesToSamtec" class="mb-4">
          <label class="font-bold mb-1">{{"Samtec Token" | translate}}:</label><br>
          <input title="{{'Samtec Token' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="samtecToken" (ngModelChange)="samtecToken=$event" type="text">
          <div class="flex flex-row mt-2">
            <button *ngIf="samtecToken && samtecToken != ''" class="button-green" title="{{'GET_QR_SAMTEC' | translate}}" (click)="qrcodeStaticToken('samtectoken')">
              <span class="text-lg">{{ 'GET_QR_SAMTEC' | translate }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-center mb-10">
        <button class="button-red" title="{{'REMOVE' | translate}}" (click)="delete()">
          <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'REMOVE' | translate }}</span>
        </button>
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
