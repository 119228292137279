import { Component, OnInit, ElementRef, ViewChild  } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "src/app/services/util.service";
import { SareqService } from "src/app/services/sareq.service";
import { SubstanceService } from "src/app/services/substance.service";
import { ManufacturerService } from "src/app/services/manufacturer.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { SubstitutionService } from 'src/app/services/substitution.service';
import { UserService } from "src/app/services/user.service";
import { ProductService } from "src/app/services/product.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ModalSareqHistoryComponent } from 'src/app/modals/modal-sareq-history/modal-sareq-history.component';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common'
import * as _ from "lodash";
import { ModalSelectStatusComponent } from 'src/app/modals/modal-select-status/modal-select-status.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: "app-edit-sareq",
  templateUrl: "./edit-sareq.component.html",
  styleUrls: ["./edit-sareq.component.css"],
})
export class EditSareqComponent implements OnInit {
  @ViewChild('chapter1') targetDivChapter1!: ElementRef;
  @ViewChild('chapter2') targetDivChapter2!: ElementRef;
  @ViewChild('chapter3') targetDivChapter3!: ElementRef;
  @ViewChild('chapter4') targetDivChapter4!: ElementRef;
  @ViewChild('chapter5') targetDivChapter5!: ElementRef;
  @ViewChild('chapter6') targetDivChapter6!: ElementRef;
  @ViewChild('chapter7') targetDivChapter7!: ElementRef;
  @ViewChild('chapter8') targetDivChapter8!: ElementRef;
  @ViewChild('chapter9') targetDivChapter9!: ElementRef;
  @ViewChild('chapter11') targetDivChapter11!: ElementRef;
  @ViewChild('chapter13') targetDivChapter13!: ElementRef;
  @ViewChild('chapter14') targetDivChapter14!: ElementRef;
  @ViewChild('chapterMisc') targetDivChapterMisc!: ElementRef;
  @ViewChild('chapterDoc') targetDivChapterDoc!: ElementRef;
  @ViewChild('chapterLabels') targetDivChapterLabel!: ElementRef;

  hphrase = [
    "H200",
    "H201",
    "H202",
    "H203",
    "H204",
    "H205",
    "H206",
    "H207",
    "H208",
    "H220",
    "H221",
    "H222",
    "H223",
    "H224",
    "H225",
    "H226",
    "H227",
    "H228",
    "H229",
    "H230",
    "H231",
    "H232",
    "H240",
    "H241",
    "H242",
    "H250",
    "H251",
    "H252",
    "H260",
    "H261",
    "H270",
    "H271",
    "H272",
    "H280",
    "H281",
    "H290",
    "H300",
    "H301",
    "H302",
    "H303",
    "H304",
    "H310",
    "H311",
    "H312",
    "H314",
    "H315",
    "H317",
    "H318",
    "H319",
    "H330",
    "H331",
    "H332",
    "H334",
    "H335",
    "H336",
    "H340",
    "H341",
    "H350",
    "H350i",
    "H351",
    "H360",
    "H360F",
    "H360D",
    "H360FD",
    "H360Fd",
    "H360Df",
    "H361",
    "H361f",
    "H361d",
    "H361fd",
    "H362",
    "H370",
    "H371",
    "H372",
    "H373",
    "H400",
    "H410",
    "H411",
    "H412",
    "H413",
    "H420",
    "EUH001",
    "EUH014",
    "EUH018",
    "EUH019",
    "EUH029",
    "EUH031",
    "EUH032",
    "EUH044",
    "EUH059",
    "EUH066",
    "EUH070",
    "EUH071",
    "EUH201",
    "EUH201A",
    "EUH202",
    "EUH203",
    "EUH204",
    "EUH205",
    "EUH206",
    "EUH207",
    "EUH208",
    "EUH209",
    "EUH209A",
    "EUH210",
    "EUH211",
    "EUH212",
    "EUH380",
    "EUH381",
    "EUH401",
    "EUH430",
    "EUH431",
    "EUH440",
    "EUH441",
    "EUH450",
    "EUH451"
  ];

  pphrase = [
    "P101",
    "P102",
    "P103",
    "P201",
    "P202",
    "P210",
    "P211",
    "P220",
    "P221",
    "P222",
    "P230",
    "P231",
    "P232",
    "P233",
    "P234",
    "P235",
    "P240",
    "P241",
    "P242",
    "P243",
    "P244",
    "P250",
    "P251",
    "P260",
    "P261",
    "P262",
    "P263",
    "P264",
    "P270",
    "P271",
    "P272",
    "P273",
    "P280",
    "P281",
    "P282",
    "P283",
    "P284",
    "P285",
    "P301",
    "P302",
    "P303",
    "P304",
    "P305",
    "P306",
    "P307",
    "P308",
    "P309",
    "P310",
    "P311",
    "P312",
    "P313",
    "P314",
    "P315",
    "P320",
    "P321",
    "P322",
    "P330",
    "P331",
    "P332",
    "P333",
    "P334",
    "P335",
    "P336",
    "P337",
    "P338",
    "P340",
    "P341",
    "P342",
    "P350",
    "P351",
    "P352",
    "P353",
    "P360",
    "P361",
    "P362",
    "P363",
    "P364",
    "P370",
    "P371",
    "P372",
    "P373",
    "P374",
    "P375",
    "P376",
    "P377",
    "P378",
    "P380",
    "P381",
    "P390",
    "P391",
    "P401",
    "P402",
    "P403",
    "P404",
    "P405",
    "P406",
    "P407",
    "P410",
    "P411",
    "P412",
    "P413",
    "P420",
    "P422",
    "P501",
    "P502",
  ];

  rphrase = [
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
    "R7",
    "R8",
    "R9",
    "R10",
    "R11",
    "R12",
    "R13",
    "R14",
    "R15",
    "R16",
    "R17",
    "R18",
    "R19",
    "R20",
    "R21",
    "R22",
    "R23",
    "R24",
    "R25",
    "R26",
    "R27",
    "R28",
    "R29",
    "R30",
    "R31",
    "R32",
    "R33",
    "R34",
    "R35",
    "R36",
    "R37",
    "R38",
    "R39",
    "R40",
    "R41",
    "R42",
    "R43",
    "R44",
    "R45",
    "R46",
    "R47",
    "R48",
    "R49",
    "R50",
    "R51",
    "R52",
    "R53",
    "R54",
    "R55",
    "R56",
    "R57",
    "R58",
    "R59",
    "R60",
    "R61",
    "R62",
    "R63",
    "R64",
    "R65",
    "R66",
    "R67",
    "R68",
  ];

  sphrase = [
    "S1",
    "S2",
    "S3",
    "S4",
    "S5",
    "S6",
    "S7",
    "S8",
    "S9",
    "S10",
    "S11",
    "S12",
    "S13",
    "S14",
    "S15",
    "S16",
    "S17",
    "S18",
    "S20",
    "S21",
    "S22",
    "S23",
    "S24",
    "S25",
    "S26",
    "S27",
    "S28",
    "S29",
    "S30",
    "S33",
    "S35",
    "S36",
    "S37",
    "S38",
    "S39",
    "S40",
    "S41",
    "S42",
    "S43",
    "S45",
    "S46",
    "S47",
    "S48",
    "S49",
    "S50",
    "S51",
    "S52",
    "S53",
    "S56",
    "S57",
    "S59",
    "S60",
    "S61",
    "S62",
    "S64",
  ];

  clphrase = [
    "Unst.Expl.",
    "Expl.1.1",
    "Expl.1.2",
    "Expl.1.3",
    "Expl.1.4",
    "Expl.1.5",
    "Expl.1.6",
    "Flam.Gas 1A",
    "Flam.Gas 1B",
    "Flam.Gas 2",
    "Chem.Unst.Gas A",
    "Chem.Unst.Gas B",
    "Desen. Expl. 1",
    "Desen. Expl. 2",
    "Desen. Expl. 3",
    "Desen. Expl. 4",
    "Aerosol 1",
    "Aerosol 2",
    "Aerosol 3",
    "Ox.Gas 1",
    "Press.Gas",
    "Flam.Liq.1",
    "Flam.Liq.2",
    "Flam.Liq.3",
    "Flam.Liq.4",
    "Flam.Sol.1",
    "Flam.Sol.2",
    "Self-react.A",
    "Org.Perox.A",
    "Self-react.B",
    "Org.Perox.B",
    "Self-react.CD",
    "Org.Perox.CD",
    "Self-react.EF",
    "Org.Perox.EF",
    "Self-react.G",
    "Org.Perox.G",
    "Pyr.Liq.1",
    "Pyr.Sol.1",
    "Self-heat.1",
    "Self-heat.2",
    "Water-react.1",
    "Water-react.2",
    "Water-react.3",
    "Ox.Liq.1",
    "Ox.Sol.1",
    "Ox.Liq.2",
    "Ox.Sol.2",
    "Ox.Liq.3",
    "Ox.Sol.3",
    "Met.Corr.1",
    "Acute.Tox.1",
    "Acute.Tox.2",
    "Acute.Tox.3",
    "Acute.Tox.4",
    "Skin.Corr.1",
    "Skin.Corr.1A",
    "Skin.Corr.1B",
    "Skin.Corr.1C",
    "Skin.Irrit.2",
    "Eye Dam.1",
    "Eye Irrit.2",
    "Resp.Sens.1",
    "Resp.Sens.1A",
    "Resp.Sens.1B",
    "Skin Sens. 1",
    "Skin Sens. 1A",
    "Skin Sens. 1B",
    "Muta.1A",
    "Muta.1B",
    "Muta.2",
    "Carc.1A",
    "Carc.1B",
    "Carc.2",
    "Repr.1A",
    "Repr.1B",
    "Repr.2",
    "Lact.",
    "STOT SE 1",
    "STOT SE 2",
    "STOT SE 3",
    "STOT RE 1",
    "STOT RE 2",
    "Asp.Tox.1",
    "Aquatic Acute 1",
    "Aquatic Chronic 1",
    "Aquatic Chronic 2",
    "Aquatic Chronic 3",
    "Aquatic Chronic 4",
    "Ozone",
    "ED HH 1",
    "ED HH 2",
    "ED ENV 1",
    "ED ENV 2",
    "PBT",
    "vPvB",
    "PMT",
    "vPvM",
    "Not classified",
  ];

  pictogramCLP = [
    { name: "is_flammable", filename: "clp_flame" },
    { name: "is_toxic", filename: "clp_skull" },
    { name: "is_dangerous_to_the_environment", filename: "clp_pollution" },
    { name: "is_explosive", filename: "clp_explosion" },
    { name: "is_gas_under_pressure", filename: "clp_bottle" },
    { name: "is_oxidising", filename: "clp_roundflame" },
    { name: "is_corrosive", filename: "clp_acid" },
    { name: "is_caution", filename: "clp_exclamation" },
    { name: "is_longer_term_health_hazards", filename: "clp_silhouete" },
  ];
  pictogramKIFS = [
    { name: "kifs_is_harmful", filename: "kifs_korscol" },
    { name: "kifs_is_toxic", filename: "kifs_giftcol" },
    { name: "kifs_is_corrosive", filename: "kifs_fratcol" },
    { name: "kifs_is_dangerous_to_the_environment", filename: "kifs_miljocol" },
    { name: "kifs_is_flammable", filename: "kifs_brandcol" },
    { name: "kifs_is_explosive", filename: "kifs_explcol" },
    { name: "kifs_is_oxidising", filename: "kifs_oxidcol" },
    { name: "kifs_is_warning", filename: "kifs_varncol" },
  ];
  pictogramADR = [
    { name: "adr-1", filename: "adr-1" },
    { name: "adr-1.4", filename: "adr-1.4" },
    { name: "adr-1.5", filename: "adr-1.5" },
    { name: "adr-1.6", filename: "adr-1.6" },
    { name: "adr-brand-svart-2.1", filename: "adr-brand-svart-2.1" },
    { name: "adr-brand-vit-2.1", filename: "adr-brand-vit-2.1" },
    { name: "adr-gas-svart-2.2", filename: "adr-gas-svart-2.2" },
    { name: "adr-gas-vit-2.2", filename: "adr-gas-vit-2.2" },
    { name: "adr-2.3", filename: "adr-2.3" },
    { name: "adr-svart-3", filename: "adr-svart-3" },
    { name: "adr-vit-3", filename: "adr-vit-3" },
    { name: "adr-4.1", filename: "adr-4.1" },
    { name: "adr-4.2", filename: "adr-4.2" },
    { name: "adr-svart-4.3", filename: "adr-svart-4.3" },
    { name: "adr-vit-4.3", filename: "adr-vit-4.3" },
    { name: "adr-5.1", filename: "adr-5.1" },
    { name: "adr-svart-5.2", filename: "adr-svart-5.2" },
    { name: "adr-vit-5.2", filename: "adr-vit-5.2" },
    { name: "adr-6.1", filename: "adr-6.1" },
    { name: "adr-6.2", filename: "adr-6.2" },
    { name: "adr-7A", filename: "adr-7A" },
    { name: "adr-7B", filename: "adr-7B" },
    { name: "adr-7C", filename: "adr-7C" },
    { name: "adr-7D", filename: "adr-7D" },
    { name: "adr-7E", filename: "adr-7E" },
    { name: "adr-8", filename: "adr-8" },
    { name: "adr-9", filename: "adr-9" },
    { name: "miljo", filename: "miljo" },
  ];

  protectiveImages = [
    { name: "image_mask", filename: "mask" },
    { name: "image_boots", filename: "boots" },
    { name: "image_breath", filename: "breath" },
    { name: "image_clothes", filename: "clothes" },
    { name: "image_gloves", filename: "gloves" },
    { name: "image_glasses", filename: "glasses" },
  ];

  transportToBeAdded: any = {
    transport_label_name_1: "",
    transport_label_name_2: "",
    transport_label_name_3: "",
    transport_label_name_4: "",
    transport_label_name_5: "",
    transport_un_nbr: "",
    adr_name: "",
    adr_class: "",
    adr_classification_code: "",
    adr_danger_code: "",
    adr_packing: "",
    adr_environment: 0,
    adr_other_info: "",
    adr_bulk_info: "",
    iata_name: "",
    iata_class: "",
    iata_packing: "",
    iata_environment: 0,
    iata_other_info: "",
    iata_bulk_info: "",
    imdg_name: "",
    imdg_class: "",
    imdg_ems: "",
    imdg_packing: "",
    imdg_environment: 0,
    imdg_other_info: "",
    imdg_bulk_info: "",
    adr_not_classfied: 0,
    adr_tunnel_restr: 0,
    adr_amount_limit: 0,
    adr_special_provision: 0,
  };

  filteredclphrase = this.clphrase;
  filteredsphrase = this.sphrase;
  filteredrphrase = this.rphrase;
  filteredhphrase = this.hphrase;
  filteredpphrase = this.pphrase;

  sareq: any;
  prev_sareq: any;
  duplicate_sareq: any;
  loadingData: boolean = false;
  loadingDataSubstance: boolean = false;
  loadingSareq: boolean = false;
  currentUser: any;
  sareqId: any;
  dateOptions: any;
  displayedManufacturers: any;
  filteredManufacturers: any;
  show_training: boolean;
  show_health_control: boolean;
  show_fitness_for_duty: boolean;
  show_no_special_demands: boolean;
  show_information: boolean;
  status_text_list;
  secretComps: any;
  chem: any;
  secretCompsname: any;
  availableLangs = [];
  secretchemOrg = [];
  tempavailableLangs: any;
  substituteProductsIds: any = [];
  displayedSubstances;
  langCode = "";
  grbFile;
  status_text: any;
  riskfile: any;
  docHistory: any;
  current_sds_file = "";
  current_extended_sds_file = "";
  current_grb_file = "";
  confirmBox:boolean=false;
  comment;
  isDeleted:boolean=false;
  counterForGettingGRB:number=0;
  createGRBBeforeSave:boolean = false;
  createGRBClicked:boolean = false;
  cdocUserList = [];
  productfile: any;
  picturefile: any;
  securityfile: any;
  securityextendedfile: any;
  sessionAddedSubstances = [];
  min = "";
  max = "";
  subToBeAdded;
  sessionAddedMixtures = [];
  eanError: boolean;
  searchH: string = "";
  searchP: string = "";
  searchS: string = "";
  searchR: string = "";
  searchC: string = "";
  showKIFS:boolean=false;
  showAddMixture: boolean;
  showAddSubstance: boolean;
  substanceSearch;
  mixtureSearch;
  itemsPerPageMix: number = 5;
  pageNumberMix = 1;
  itemsPerPageSub: number = 5;
  pageNumberSub = 1;
  replaceCheckboxChecked:boolean = false;
  replaceBy:boolean = false;
  replaceByName:string = "";
  no_item:boolean = false;
  allProducts = [];
  sortorder = 'asc';
  itemsPerPageSareq: number = 5;
  pageNumberSareq = 1;
  tempallProducts = [];
  sareqNewLangsList: any = [];
  sareqNewLangs: any = null;
  sareqSetLangs: any = null;
  oldStatus:number = null;
  prevAssigned: string = "";
  sareqSearch = {
    name:"",
    manufacturer:"",
    articlenumber:"",
  };
  newSareq: boolean = false;
  showOrganisations: boolean = false;

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private utilService: UtilService,
    private substanceService: SubstanceService,
    private sareqService: SareqService,
    private manufacturerService: ManufacturerService,
    private organisationService: OrganisationService,
    private userService: UserService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private uploadService: FileUploadService,
    private authService: AuthService,
    private modalService: NgbModal,
    private substitutionService: SubstitutionService) {

    this.router.onSameUrlNavigation='reload'
  }

  ngAfterViewInit(): void {
    $("#allsubstancesedit").tablesort();
    $("#allmixturesedit").tablesort();
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.previousrouter.params.pipe(take(1)).subscribe((params) => {
      this.sareqId = params["id"];
    });

    this.loadManufacturerList();
    this.dateOptions = {
      startingDay: 1,
    };

    if (this.sareqId) {
      this.newSareq = false;
      this.loadSareq();
      this.loadSubstances();
      this.loadSecretChem();
    }
  }

  scrollToChapter(id: number) {

    if (id == 1)
    {
      this.targetDivChapter1.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 2)
    {
      this.targetDivChapter2.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 3)
    {
      this.targetDivChapter3.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 4)
    {
      this.targetDivChapter4.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 5)
    {
      this.targetDivChapter5.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 6)
    {
      this.targetDivChapter6.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 7)
    {
      this.targetDivChapter7.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 8)
    {
      this.targetDivChapter8.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 9)
    {
      this.targetDivChapter9.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 10)
    {
      this.targetDivChapter11.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 11)
    {
      this.targetDivChapter13.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 12)
    {
      this.targetDivChapter14.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 13)
    {
      this.targetDivChapterMisc.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 14)
    {
      this.targetDivChapterDoc.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    if (id == 15)
    {
      this.targetDivChapterLabel.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }

  setHPhrases(hphrase) {
    var exists = this.sareq.h_phrases.some(phraseObj => phraseObj.phrase === hphrase);
    if (exists == false) {
      this.sareq.h_phrases.push({
        phrase: hphrase,
      });
    }
  }

  removeHPhrases(hphrase) {
    const indexToRemove = this.sareq.h_phrases.findIndex(item => item.phrase === hphrase.phrase);

    if (indexToRemove !== -1) {
      this.sareq.h_phrases.splice(indexToRemove, 1);
    }
  }

  setPPhrases(pphrase) {
    var exists = this.sareq.p_phrases.some(phraseObj => phraseObj.phrase === pphrase);
    if (exists == false) {
      this.sareq.p_phrases.push({
        phrase: pphrase,
      });
    }
  }

  removePPhrases(pphrase) {
    const indexToRemove = this.sareq.p_phrases.findIndex(item => item.phrase === pphrase.phrase);

    if (indexToRemove !== -1) {
      this.sareq.p_phrases.splice(indexToRemove, 1);
    }
  }

  setSPhrases(sphrase) {
    var exists = this.sareq.s_phrases.some(phraseObj => phraseObj.phrase === sphrase);
    if (exists == false) {
      this.sareq.s_phrases.push({
        phrase: sphrase,
      });
    }
  }

  openHistoryModal() {
    const modalRef = this.modalService.open(ModalSareqHistoryComponent);
    modalRef.componentInstance.thisSareq = this.sareq;
  }

  removeSPhrases(sphrase) {
    const indexToRemove = this.sareq.s_phrases.findIndex(item => item.phrase === sphrase.phrase);

    if (indexToRemove !== -1) {
      this.sareq.s_phrases.splice(indexToRemove, 1);
    }
  }

  setRPhrases(rphrase) {
    var exists = this.sareq.r_phrases.some(phraseObj => phraseObj.phrase === rphrase);
    if (exists == false) {
      this.sareq.r_phrases.push({
        phrase: rphrase,
      });
    }
  }

  removeRPhrases(rphrase) {
    const indexToRemove = this.sareq.r_phrases.findIndex(item => item.phrase === rphrase.phrase);

    if (indexToRemove !== -1) {
      this.sareq.r_phrases.splice(indexToRemove, 1);
    }
  }

  setCPhrases(cphrase) {
    var exists = this.sareq.c_phrases.some(phraseObj => phraseObj.phrase === cphrase);
    if (exists == false) {
      this.sareq.c_phrases.push({
        phrase: cphrase,
      });
    }
  }

  removeCPhrases(cpharse) {
    const indexToRemove = this.sareq.c_phrases.findIndex(item => item.phrase === cpharse.phrase);

    if (indexToRemove !== -1) {
      this.sareq.c_phrases.splice(indexToRemove, 1);
    }
  }

  loadManufacturerList() {
    this.manufacturerService.getAll();
    this.manufacturerService
      .getManufacturerDataListener()
      .pipe(take(1))
      .subscribe({
        next: (manufacturer) => {
          if (manufacturer == null) {
          } else {
            this.displayedManufacturers = _.sortBy(manufacturer, [
              function (o) {
                return o.name;
              },
            ]);
            this.filteredManufacturers =  this.displayedManufacturers;
          }
        },
        error: (error) => {},
      });
  }

  onSearchChangeManufacturer(searchValue: string): void {

    this.filteredManufacturers =  this.displayedManufacturers;

    if (searchValue !== "")
    {
        this.filteredManufacturers = this.displayedManufacturers.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  onSearchChangeCphrases(searchValue: string): void {
    this.filteredclphrase =  this.clphrase;

    if (searchValue !== "")
    {
        this.filteredclphrase = this.clphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  onSearchChangeHphrases(searchValue: string): void {
    this.filteredhphrase =  this.hphrase;

    if (searchValue !== "")
    {
        this.filteredhphrase = this.hphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  onSearchChangePphrases(searchValue: string): void {
    this.filteredpphrase =  this.pphrase;

    if (searchValue !== "")
    {
        this.filteredpphrase = this.pphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  onSearchChangeRphrases(searchValue: string): void {
    this.filteredrphrase =  this.rphrase;

    if (searchValue !== "")
    {
        this.filteredrphrase = this.rphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  onSearchChangeSphrases(searchValue: string): void {
    this.filteredsphrase =  this.sphrase;

    if (searchValue !== "")
    {
        this.filteredsphrase = this.sphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  loadSareq() {
    this.sareqService.getSingle({ id: this.sareqId });
    this.sareqService
      .getSareqDataListener()
      .pipe(take(1))
      .subscribe({
        next: (thissareq) => {
          if (thissareq == null) {
            this.loadingData = false;
          }
          else {
            if (thissareq) {
              this.sareq = thissareq[0];
              this.loadingData = false;
              // The checkbox is always unchecked when the value isnt explicatedly set to 1
              this.sareq.hidden=this.sareq.hidden==1?true:false;
              this.sareq.excluded=this.sareq.excluded==1?true:false;
              this.sareq.hygiene_limits=this.sareq.hygiene_limits==1?true:false;
              this.sareq.no_hygiene_limits=this.sareq.no_hygiene_limits==1?true:false;
              this.prevAssigned = this.sareq.assigned;

              if ( this.sareq.high_prio == true ) {
                this.sareq.high_prio = 1;
              } else {
                this.sareq.high_prio = 0;
              }

              if (this.sareq.training) {
                this.show_training = true;
              }

              if (this.sareq.health_control) {
                this.show_health_control = true;
              }

              if (this.sareq.fitness_for_duty) {
                this.show_fitness_for_duty = true;
              }

              if (this.sareq.information) {
                this.show_information = true;
              }

              if ( this.sareq.no_special_demands != null && this.sareq.no_special_demands != 'notset') {
                this.show_no_special_demands = true;
              }

              if (this.sareq.c_phrases)
              {
                let tmpphrases = _.cloneDeep(this.sareq.c_phrases);
                this.sareq.c_phrases = [];
                for (var i = 0; i < tmpphrases.length; i++) {
                  this.sareq.c_phrases.push({
                    phrase: tmpphrases[i].c_phrase,
                  });
                }
              }

              if (this.sareq.h_phrases)
              {
                let tmpphrases = _.cloneDeep(this.sareq.h_phrases);
                this.sareq.h_phrases = [];
                for (var i = 0; i < tmpphrases.length; i++) {
                  this.sareq.h_phrases.push({
                    phrase: tmpphrases[i].h_phrase,
                  });
                }
              }

              if (this.sareq.p_phrases)
              {
                let tmpphrases = _.cloneDeep(this.sareq.p_phrases);
                this.sareq.p_phrases = [];
                for (var i = 0; i < tmpphrases.length; i++) {
                  this.sareq.p_phrases.push({
                    phrase: tmpphrases[i].p_phrase,
                  });
                }
              }

              if (this.sareq.r_phrases)
              {
                let tmpphrases = _.cloneDeep(this.sareq.r_phrases);
                this.sareq.r_phrases = [];
                for (var i = 0; i < tmpphrases.length; i++) {
                  this.sareq.r_phrases.push({
                    phrase: tmpphrases[i].r_phrase,
                  });
                }
              }

              if (this.sareq.s_phrases)
              {
                let tmpphrases = _.cloneDeep(this.sareq.s_phrases);
                this.sareq.s_phrases = [];
                for (var i = 0; i < tmpphrases.length; i++) {
                  this.sareq.s_phrases.push({
                    phrase: tmpphrases[i].s_phrase,
                  });
                }
              }

              if (!this.sareq.transport)
              {
                this.sareq.transport = [];
              }

              if(!this.sareq.transport[0]){
                this.sareq.transport[0]=({
                  'adr_amount_limit':"0",
                  'adr_bulk_info':"",
                  'adr_class': "",
                  'adr_classification_code': "",
                  'adr_danger_code':"",
                  'adr_environment':0,
                  'adr_name':"",
                  'adr_not_classfied':0,
                  'adr_other_info':"0",
                  'adr_packing':"",
                  'adr_special_provision':"",
                  'adr_tunnel_restr':"0",
                  'iata_bulk_info':"",
                  'iata_class':"",
                  'iata_environment':0,
                  'iata_name':"",
                  'iata_other_info':"",
                  'iata_packing':"",
                  'imdg_bulk_info':"",
                  'imdg_class':"",
                  'imdg_ems':"",
                  'imdg_environment':0,
                  'imdg_name':"",
                  'imdg_other_info':"",
                  'imdg_packing':"",
                  'transport_label_name_1':"",
                  'transport_label_name_2':"",
                  'transport_label_name_3':"",
                  'transport_label_name_4':"",
                  'transport_label_name_5':"",
                  'transport_un_nbr':""});
              }

              if (!this.sareq.substances) {
                this.sareq.substances = [];
              }

              if (this.sareq.transport.length === 0) {
                this.sareq.transport.push(this.transportToBeAdded);
              }
            }

          }

          this.getStatus(this.sareq.status);
          this.oldStatus = this.sareq.status;
          this.sareq.assigned = this.currentUser.username;

          this.docHistoryFunction();
          if (this.sareq.replaced_by && this.sareq.replaced_by != "") {
            this.checkboxsetter();
          }

          if(this.currentUser.is_maintenance_user){
            this.status_text_list = [
              this.translate.instant("SAREQ_STATUS_0"),
              this.translate.instant("SAREQ_STATUS_1"),
              this.translate.instant("SAREQ_STATUS_2"),
              this.translate.instant("SAREQ_STATUS_3"),
              this.translate.instant("SAREQ_STATUS_4"),
              this.translate.instant("SAREQ_STATUS_5"),
            ];
          }
          else {
            this.status_text_list = [
              this.translate.instant("SAREQ_STATUS_0"),
              this.translate.instant("SAREQ_STATUS_1"),
              this.translate.instant("SAREQ_STATUS_2"),
              this.translate.instant("SAREQ_STATUS_3"),
              this.translate.instant("SAREQ_STATUS_4"),
              this.translate.instant("SAREQ_STATUS_5"),
            ];
          }

          this.sareqNewLangsList = [
            this.translate.instant("LANGUAGE_0"),
            this.translate.instant("LANGUAGE_1"),
            this.translate.instant("LANGUAGE_2"),
          ];

          this.sareqNewLangs = this.translate.instant(
            this.sareqNewLangsList[this.sareq.lang]
          );

          this.sareqSetLangs = this.translate.instant(
            this.sareqNewLangsList[this.sareq.lang]
          );

          this.sareq.rework_date = this.parseDate(this.sareq.rework_date);

          this.sareq.replaces_date = this.parseDate(this.sareq.replaces_date);
          this.sareq.print_date = this.parseDate(this.sareq.print_date);

          if ( this.secretComps && this.sareq.secret_chemical_organisation && this.sareq.secret_chemical_organisation != "" ) {
            this.sareq.secret_chemical = true;
            for(var i=0;i<this.secretComps.length;i++){
                if(this.secretComps[i].id==this.sareq.secret_chemical_organisation){
                  this.chem=this.sareq.secret_chemical_organisation;
                  this.sareq.secretchemOrg=this.sareq.secret_chemical_organisation;
                  this.secretCompsname=this.secretComps[i].name;
                }
            }
          } else {
            this.secretCompsname = "";
            this.chem = "";
          }
          // Needs a timeout to make sure the data is fetched first
          setTimeout(() => {
            this.sareq.no_inspection = 0;
            this.duplicate_sareq = _.cloneDeep(this.sareq);
            this.prev_sareq = _.cloneDeep(this.sareq);
          },1000);

        },
        error: (error) => {
          this.loadingData = false;
        },
    });

    var localBuffer = [];
    this.productService.getOtherAvailableLangs(this.sareqId);
    this.productService
      .getProductLangDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          for (var i = 0; i < response.length; i++) {
            //Convert char 0 to int 0 (or use +0)
            if (response[i].lang === "0") {
              localBuffer.push(0);
            } else {
              localBuffer.push(response[i].lang);
            }
          }

          for (var i = 0; i < localBuffer.length; i++) {
            this.availableLangs.push(
              this.translate.instant("LANGUAGE_" + localBuffer[i])
            );
            this.secretchemOrg.push(
              this.translate.instant("LANGUAGE_" + localBuffer[i])
            );
          }

          this.tempavailableLangs = [];
          if (
            this.availableLangs.indexOf(this.translate.instant("LANGUAGE_0")) !=
            -1
          ) {
            this.tempavailableLangs.push(this.translate.instant("LANGUAGE_0"));
          }
          if (
            this.availableLangs.indexOf(this.translate.instant("LANGUAGE_1")) !=
            -1
          ) {
            this.tempavailableLangs.push(this.translate.instant("LANGUAGE_1"));
          }
          if (
            this.availableLangs.indexOf(this.translate.instant("LANGUAGE_2")) !=
            -1
          ) {
            this.tempavailableLangs.push(this.translate.instant("LANGUAGE_2"));
          }
        },
        error: (error) => {},
      });
  }

  loadAllSareqList() {
    this.sareqService.getAllForSareqEditAnotherDatalistener();
    this.sareqService.getSareqDataListenerSareqEdit().pipe(take(1)).subscribe({
      next: products => {
        if (products == null) {
        }
        else
        {
          this.allProducts = products;
          this.tempallProducts = _.cloneDeep(this.allProducts);
          if(this.sareq.replaced_by!=null){
            if(this.sareq.replaced_by.trim()==""){
              this.no_item=true;
              this.replaceByName=this.translate.instant("NOTDECIDED");
              this.replaceBy=true;
              this.replaceCheckboxChecked=true;
            }
            else{
              this.replaceBy=true;
              this.replaceCheckboxChecked=true;
              var len=this.allProducts.length;
              for(var count=0;count<len;count++){
                if(this.allProducts[count].id==this.sareq.replaced_by){
                  this.replaceByName=this.allProducts[count].name;
                }
              }
            }
          }
          this.sortProducts('name');
          this.loadingSareq = false;
        }
      },
      error: error => {
        this.loadingSareq = false;
      }
    })
  }

  loadSubstances() {
    this.substanceService.getAll();
    this.substanceService
      .getSubstanceDataListener()
      .pipe(take(1))
      .subscribe({
        next: (substances) => {
          if (substances == null) {
          } else {
            this.displayedSubstances = substances;
          }
        },
        error: (error) => {},
      });
  }

  loadSecretChem() {
    this.organisationService.getSecretChem();
    this.organisationService
      .getOrganisationDataListener()
      .pipe(take(1))
      .subscribe({
        next: (secretChem) => {
          if (secretChem == null) {
          } else {
            this.secretComps = secretChem;
          }
        },
        error: (error) => {},
      });
  }

  cancel() {

    if (this.newSareq ||this.hasSareqChanged())
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.save('');
        } else {
          this.router.navigate(["/controlpanel"]);
          this.location.back();
        }
      })
    }
    else
    {
      this.router.navigate(["/controlpanel"]);
      this.location.back();
    }
  }

  sareqSelectedLang(index) {
    // Reload the page to make sure everything is loaded to the sareqitem
    this.langCode = index;
    let languageSelected = 0;
    switch ( this.tempavailableLangs[this.langCode] )
    {
      case 'Svenska':
      case 'Swedish':
      case 'Ruotsin kieli':
      case 'LANGUAGE_0':
        languageSelected = 0;
        break;
      case 'Engelska':
      case 'English':
      case 'Englanti':
      case 'LANGUAGE_1':
        languageSelected = 1;
        break;
      case 'Finska':
      case 'Finnish':
      case 'Finska':
      case 'LANGUAGE_2':
        languageSelected = 2;
        break;
      case 'Danska':
      case 'Danska':
      case 'Danska':
      case 'LANGUAGE_9':
        languageSelected = 9;
        break;
    }
    this.productService.GetL( { productId: this.sareq.id,
                                lang: languageSelected } );
    this.productService
      .getProductDataListener()
      .pipe(take( 1 ) )
      .subscribe( {
        next: (response) => {
          // No need to reload if its the same id that im on
          // or response.id doesnt exist
          if ( response[0].id && response[0].id != this.sareq.id )
          {
            this.router.navigate( [ "/editsareq", response[0].id ] )
              .then( ( ) => {
                window.location.reload();
                this.loadingData = false;
              } );
          }
          else
          {
            this.loadingData = false;
          }
          this.loadingData = false; // error handling
        }
    } );
  }

  /**
   * Display SDS in new window
   */
  openSdsInNewWindow(product) {
    const id = product.id;
    const filename = product.name + ".pdf";
    const newWindow = window.open("", "_blank");
    this.productService.getSdsAsTrustedResourceUrl(id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: (pdfContent) => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_SDS"),
            this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_SDS"),
          this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  /**
   * Display GRB in new window
   */
  openGrbInNewWindow(product) {
    const filename = product.name + ".pdf";
    const newWindow = window.open("", "_blank");
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: (pdfContent) => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_GRB"),
            this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_GRB"),
          this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  openExtSdsInNewWindow(prod) {
    const filename = prod.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getExtSdsAsTrustedResourceUrl(prod.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  riskfileOpen() {
    window.open(this.grbFile,"_blank");
  }

  getStatus(status) {
    switch (status) {
      case 0:
        this.status_text = this.translate.instant("SAREQ_STATUS_0");
        break;

      case 1:
        this.status_text = this.translate.instant("SAREQ_STATUS_1");
        break;

      case 2:
        this.status_text = this.translate.instant("SAREQ_STATUS_2");
        break;

      case 3:
        this.status_text = this.translate.instant("SAREQ_STATUS_3");
        break;

      case 4:
        this.status_text = this.translate.instant("SAREQ_STATUS_4");
        break;

      case 5:
        this.status_text = this.translate.instant("SAREQ_STATUS_5");
        break;
    }
  }

  pictogramInSareq(pictogram) {
    if (this.sareq) {
      if (this.sareq[pictogram] || this.sareq["clp_" + pictogram]) {
        this.sareq[pictogram] = true;
        return true;
      }
      return false;
    }
  }

  protectiveInSareq(name) {
    //Prevent throwing error at init, cause load is not yet finished. Could parse Sareq vars to other prefedefined this.sareqProt but adds overhead and is ugly.
    if (this.sareq && !this.sareq.no_protection) {
      if (this.sareq[name]) {
        return true;
      }
      return false;
    }
  }

  adrPictogramInSareq(name) {
    if (this.sareq) {
      if (this.sareq.transport) {
        if (this.sareq.transport[0]) {
          var transport_label_name_1 =
            this.sareq.transport[0].transport_label_name_1;
          var transport_label_name_2 =
            this.sareq.transport[0].transport_label_name_2;
          var transport_label_name_3 =
            this.sareq.transport[0].transport_label_name_3;
          var transport_label_name_4 =
            this.sareq.transport[0].transport_label_name_4;
          var transport_label_name_5 =
            this.sareq.transport[0].transport_label_name_5;
        } else {
          var transport_label_name_1 = null;
          var transport_label_name_2 = null;
          var transport_label_name_3 = null;
          var transport_label_name_4 = null;
          var transport_label_name_5 = null;
        }
        if (
          _.isEqual(transport_label_name_1, name) ||
          _.isEqual(transport_label_name_2, name) ||
          _.isEqual(transport_label_name_3, name) ||
          _.isEqual(transport_label_name_4, name) ||
          _.isEqual(transport_label_name_5, name)
        ) {
          return true;
        }
        return false;
      }
    }
  }

  // This is just to get the document to show, in editting mode theres more to do
  createGRB (language) {

    if ( this.checkIfFieldIsTooLong () ) {
      return;
    }

    if ( this.newSareq ) {
      Swal.fire (
        this.translate.instant( "Varning" ),
        this.translate.instant( "Spara först" ),
        "error"
      );
      return;
    }

    this.riskfile = "";
    if ( language == 'swedish' ) {
      this.save( "createGRB" );
    }
    else if ( language == 'english' ) {
      this.save( "createGRBEng" );
    }
    else if ( language == 'finnish') {
      this.save( "createGRBFin" );
    }
    else {
      return;
    }
  }

  setRiskLevel(level) {
    this.sareq.risk_prio = level;
  }

  getGrbAsPdf(id) {
    const newWindow = window.open("", "_blank");
    this.sareqService.getSpecificGrb(id);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: (response) => {
        if (response == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_GRB"),
            this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          const contentType = "application/pdf";
          const file = new Blob([response], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = fileURL;
          } else {
            newWindow.location.href = fileURL;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_GRB"),
          this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  getSdsAsPdf(id) {
    const newWindow = window.open("", "_blank");
    this.sareqService.getSpecificSds(id);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: (response) => {
        if (response == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_SDS"),
            this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          const contentType = "application/pdf";
          const file = new Blob([response], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = fileURL;
          } else {
            newWindow.location.href = fileURL;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_SDS"),
          this.translate.instant("COULD_NOT_GET_SDS_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  getProductSheetAsPdf() {
    const id = this.sareq.id;
    const newWindow = window.open("", "_blank");
    this.productService.getProductSheetAsTrustedResourceUrl(id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: (response) => {
        if (response == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"),
            this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = response;
          } else {
            newWindow.location.href = response;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"),
          this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  getPictureAsPdf() {
    const id = this.sareq.id;
    const newWindow = window.open("", "_blank");
    this.productService.getPictureAsTrustedResourceUrl(id);
    this.productService.getProductPictureDataListener().pipe(take(1)).subscribe({
      next: (response) => {
        if (response == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_PICTURE"),
            this.translate.instant("COULD_NOT_GET_PICTURE_WARNING"),
            "error"
          );
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = response;
          } else {
            newWindow.location.href = response;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_PICTURE"),
          this.translate.instant("COULD_NOT_GET_PICTURE_WARNING"),
          "error"
        );
        if (newWindow) {
          newWindow.close();
        }
      },
    });
  }

  // Duplicates of methods and variables not possible longer
  // (or perhaps it is possible with some extra work but easier solution for now just to change name)
  docHistoryFunction() {
    this.sareqService.getDocumentHistoryForSareqProduct(this.sareq.id);
    this.sareqService
      .getSareqDataListener()
      .pipe(take(1))
      .subscribe({
        next: (docHistory) => {
          if (docHistory == null) {
            Swal.fire(
              this.translate.instant("COULD_NOT_GET_HISTORY"),
              this.translate.instant("COULD_NOT_GET_HISTORY_WARNING"),
              "error"
            );
          } else {
            this.docHistory = docHistory;
            if (this.current_sds_file) {
              this.current_sds_file = this.docHistory.sds[0].sds_file_id;
            } else {
              this.current_sds_file = "";
            }
            if (this.current_grb_file) {
              this.current_grb_file = this.docHistory.grb[0].file_id;
            } else {
              this.current_grb_file = "";
            }
            if (this.current_extended_sds_file) {
              this.current_extended_sds_file = this.docHistory.esdb[0].file_id;
            } else {
              this.current_extended_sds_file = "";
            }
          }
        },
        error: (error) => {
          console.log("Dochistory error: ", error);
          this.utilService.onError(error);
        },
      });
  }

  parseDate(datestring) {
    var dateOut = new Date(datestring);
    dateOut.setDate(dateOut.getDate());
    return dateOut;
  }

  saveasmaintuser(){
    const modalRef = this.modalService.open(ModalSelectStatusComponent);
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((val) => {
      if(val==0){
        this.sareq.status = 5;
        this.save('fastsave');
      }
      if(val==1){
        this.sareq.status = 5;
        this.save('');
      }
      if(val==2){
        this.sareq.status = 0;
        this.save('');
      }
      if(val==3){
        /* The save post with status "Ready" */
        this.sareq.status = 1;
        this.sareq.no_inspection = 1;
        this.save('');
      }
      if(val==4){
        this.sareq.status = 3;
        this.save('');
      }
      return
    });
  }

  getCommentStatus(path){
    Swal.fire({
      title: this.translate.instant("DESCRIPTION"),
      input: 'text',
      html: this.translate.instant("WHY_STATUS_2") + "<br><br><div align='left'>" + this.translate.instant("DESCRIPTION") + ":</div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("CANCEL"),
      confirmButtonText: this.translate.instant("OK"),
      closeOnConfirm: true,
      inputPlaceholder: this.translate.instant("DESCRIPTION")
    }).then((result) => {
      if (result.isConfirmed) {
        this.comment = this.prevAssigned + ': ' + result.value;
        this.save(path);
      } else {
        this.confirmBox=false;
      }
    });
  }

  // Method to check if two objects are different
  hasSareqChanged(): boolean {
    /* We do not want to get difference because of below posts in sareq */
    if (this.prev_sareq.status == this.sareq.status)
    {
      this.sareq.duplicateToHistory = "no";
    }
    else
    {
      this.prev_sareq.status = this.sareq.status;

      if (this.sareq.status == 2)
      {
        this.prev_sareq.status_2_description = this.sareq.status_2_description;
      }
    }

    this.prev_sareq.duplicateToHistory = this.sareq.duplicateToHistory;
    var result = JSON.stringify(this.sareq) !== JSON.stringify(this.prev_sareq);
    return JSON.stringify(this.sareq) !== JSON.stringify(this.prev_sareq);
  }

  save(path) {
    if ( this.sareq.status == 2
          && this.oldStatus != 2
          && path != "createGRB"
          && path != "createGRBEng"
          && path != "createGRBFin"
          && this.confirmBox != true
          && this.isDeleted == false ) {
      this.confirmBox=true;
      this.getCommentStatus( path );
      return;
    }

    if ( this.sareq.status == 2 ) {
      this.confirmBox=false;
      this.sareq.status_2_description = this.comment;
    }

    if ( path == "createGRB" || path == "createGRBEng" || path == "createGRBFin" ) {
      this.sareq.duplicateToHistory = 'no';
    } else {
      this.sareq.duplicateToHistory = 'yes';
    }

    if ( this.sareq.status == 1 ) {
      this.sareq.high_prio = 0;
    }

    if ( this.sareq.status == 1 && this.oldStatus != 1 ) {
      this.sareq.ongoing_revision = '0';
    }


    if ((path == '') && (!this.riskfile || this.riskfile == "") && this.newSareq!=true) {

      if (( this.counterForGettingGRB == 0 ) && (this.hasSareqChanged())) {

        this.counterForGettingGRB++ ;
        this.createGRBBeforeSave = true;
        if ( this.sareq.lang == 1 ) {
          this.createGRB( 'english' );
        }
        else if ( this.sareq.lang == 2 ) {
          this.createGRB( 'finnish' )
        }
        else {
          this.createGRB( 'swedish' );
        }
        return;
      }
    }

    if ( this.sareq.status == 2 ) {
      this.confirmBox=false;
      this.sareq.status_2_description = this.comment;
    }

    if ( this.sareq.status == 1 ) {
      this.sareq.high_prio = 0;
    }

    if ( this.sareq.status != 3) {
      if (this.sareq.name == "") {
        Swal.fire("namn ska vara ifyllt");
        this.loadingData = false;
        return;
      }

      if ( this.currentUser.is_maintenance_user ) {
        var date1 = this.checkDate( this.sareq.replaces_date );
        var date2 = this.checkDate( this.sareq.rework_date );
        if ( date1 > date2 ) {
          Swal.fire("Felaktig inmatning","Ersättningsdatum får inte vara senare än omarbetningsdatum","error");
          this.loadingData = false;
          return;
        }
      }
    }

    if ( path != "createGRB" && path != "createGRBEng" && path != "createGRBFin" ) {
      if ( this.currentUser.is_maintenance_user && this.checkIfFieldIsTooLong() ) {
        this.loadingData = false;
        return;
      }
      this.saveFilesAndData( path );
    }

    if ( path == "createGRB" || path == "createGRBEng" || path == "createGRBFin" ) {
      if ( this.picturefile ) {
        var getPictureFile = new Promise( ( resolve, reject ) => {
          this.uploadService.uploadPicturefile( this.picturefile );
          this.uploadService
            .getPictureFileDataListener()
            .pipe( take( 1 ) )
            .subscribe( {
              next: ( response ) => {
                resolve( response.filename );
              },
              error: ( error ) => {
                this.utilService.onError( error );
              },
            } );
        } );
      }

      Promise.all( [
        getPictureFile
      ] ).then( ( values ) => {
        if ( this.picturefile ) {
          this.sareq.picture_file_id = values[0];
        }
        this.saveData( path );
      } );
    }
  }

  saveFilesAndData(path) {
    if (this.riskfile) {
      var getRiskFile = new Promise((resolve, reject) => {
        this.uploadService.uploadRiskfile(this.riskfile);
        this.uploadService
          .getRiskFileDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              console.log("Dochistory error: ", error);
              this.utilService.onError(error);
            },
          });
      });
    }

    if (this.securityfile) {
      var getSecurityFile = new Promise((resolve, reject) => {
        this.uploadService.uploadSecurityfile(this.securityfile);
        this.uploadService
          .getSecurityFileDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.sareq.cdoc_date=new Date();
              resolve(response.filename);
            },
            error: (error) => {
              this.utilService.onError(error);
            },
          });
      });
    }

    if (this.securityextendedfile) {
      var getSecurityExtendFile = new Promise((resolve, reject) => {
        this.uploadService.uploadSecurityExtfile(this.securityextendedfile);
        this.uploadService
          .getSecurityExtFileDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              this.utilService.onError(error);
            },
          });
      });
    }

    if (this.productfile) {
      var getProductSheet = new Promise((resolve, reject) => {
        this.uploadService.uploadProductfile(this.productfile);
        this.uploadService
          .getProductFileDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              this.utilService.onError(error);
            },
          });
      });
    }

    if (this.picturefile) {
      var getPictureFile = new Promise((resolve, reject) => {
        this.uploadService.uploadPicturefile(this.picturefile);
        this.uploadService
          .getPictureFileDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              this.utilService.onError(error);
            },
          });
      });
    }

    Promise.all([
      getRiskFile,
      getSecurityFile,
      getSecurityExtendFile,
      getProductSheet,
      getPictureFile,
    ]).then((values) => {
      if (this.riskfile) {
        this.sareq.risk_file_id = values[0];
      }
      if (this.securityfile) {
        this.sareq.sds_file_id = values[1];
      }
      if (this.securityextendedfile) {
        this.sareq.esdb_file_id = values[2];
      }

      if (this.productfile) {
        this.sareq.productsheet_file_id = values[3];
      }

      if (this.picturefile) {
        this.sareq.picture_file_id = values[4];
      }
      this.saveData(path);
    });
  }

  saveData(path) {

    if (this.sareq.hidden == true) {
      this.sareq.hidden = 1;
    } else {
      this.sareq.hidden = 0;
    }

    if (this.sareq.excluded == true) {
      this.sareq.excluded = 1;
    } else {
      this.sareq.excluded = 0;
    }

    if (this.sareq.no_hygiene_limits == true) {
      this.sareq.no_hygiene_limits = 1;
      this.sareq.no_hygiene_limits_comments = "";
    } else {
      this.sareq.no_hygiene_limits = 0;
    }

    if (this.sareq.hygiene_limits == true) {
      this.sareq.hygiene_limits = 1;
    } else {
      this.sareq.hygiene_limits = 0;
    }

    if (this.sareq.secret_chemical == true) {
      this.sareq.secret_chemical = 1;
    } else {
      this.sareq.secret_chemical = 0;
    }

    this.sareq.manufacturer = { id: this.sareq.supplier_id };
    this.sareq.rework_date = this.checkDate(this.sareq.rework_date);
    this.sareq.replaces_date = this.checkDate(this.sareq.replaces_date);
    this.sareq.print_date = this.checkDate(this.sareq.print_date);

    if (!this.newSareq) {
      this.loadingData = true;
      this.sareqService.updateSareqWithId(this.sareqId, this.sareq);
      this.sareqService
        .getSareqDataListener()
        .pipe(take(1))
        .subscribe({
          next: () => {
            // this.loadingData = false;
            if (path == "createGRB") {
              this.sareqService.createSpecificGrb(this.sareqId);
              this.sareqService
                .getSareqDataListener()
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response == null) {
                      Swal.fire(
                        this.translate.instant("COULD_NOT_GET_GRB"),
                        this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                        "error"
                      );
                      this.loadingData = false;
                    } else {
                      var file: any, fileURL: string, contentType: string;
                      contentType = "application/pdf";
                      file = new Blob([response], { type: contentType });
                      fileURL = URL.createObjectURL(file);
                      this.riskfile = file;
                      this.grbFile=fileURL;
                      if(this.createGRBBeforeSave==true){
                        this.save('');
                      } else {
                        this.loadingData = false;
                        const newWindow = window.open(fileURL, "_blank");
                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                          // If the new window couldn't be opened, fallback to opening in the same tab
                          window.location.href = fileURL;
                        }
                      }
                    }
                  },
                  error: (error) => {
                    Swal.fire(
                      this.translate.instant("COULD_NOT_GET_GRB"),
                      this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                      "error"
                    );
                    this.loadingData = false;
                  },
                });
            } else if (path == "createGRBEng") {
              this.sareqService.createSpecificGrbEng(this.sareqId);
              this.sareqService
                .getSareqDataListener()
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response == null) {
                      Swal.fire(
                        this.translate.instant("COULD_NOT_GET_GRB"),
                        this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                        "error"
                      );
                      this.loadingData = false;
                    } else {
                      var file: any, fileURL: string, contentType: string;
                      contentType = "application/pdf";
                      file = new Blob([response], { type: contentType });
                      fileURL = URL.createObjectURL(file);
                      this.grbFile=fileURL;
                      this.riskfile = file;
                      this.grbFile=fileURL;
                      if(this.createGRBBeforeSave==true){
                        this.save('');
                      } else {
                        var opened=window.open(fileURL,"_blank");
                        this.loadingData = false;
                        const newWindow = window.open(fileURL, "_blank");
                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                          // If the new window couldn't be opened, fallback to opening in the same tab
                          window.location.href = fileURL;
                        }
                      }
                    }
                  },
                  error: (error) => {
                    Swal.fire(
                      this.translate.instant("COULD_NOT_GET_GRB"),
                      this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                      "error"
                    );
                    this.loadingData = false;
                  },
                });
            } else if (path == "createGRBFin") {
              this.sareqService.createSpecificGrbFin(this.sareqId);
              this.sareqService
                .getSareqDataListener()
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response == null) {
                      Swal.fire(
                        this.translate.instant("COULD_NOT_GET_GRB"),
                        this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                        "error"
                      );
                      this.loadingData = false;
                    } else {
                      var file: any, fileURL: string, contentType: string;
                      contentType = "application/pdf";
                      file = new Blob([response], { type: contentType });
                      fileURL = URL.createObjectURL(file);
                      this.grbFile=fileURL;
                      this.riskfile = file;
                      this.grbFile=fileURL;
                      if(this.createGRBBeforeSave==true){
                        this.save('');
                      } else {
                        this.loadingData = false;
                        const newWindow = window.open(fileURL, "_blank");
                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                          // If the new window couldn't be opened, fallback to opening in the same tab
                          window.location.href = fileURL;
                        }
                      }
                    }
                  },
                  error: (error) => {
                    Swal.fire(
                      this.translate.instant("COULD_NOT_GET_GRB"),
                      this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
                      "error"
                    );
                    this.loadingData = false;
                  },
                });
            } else if (path == "cancel") {
              this.router.navigate(["/controlpanel"]);
              this.location.back();
            } else {
              this.loadingData = false;
              if (path == "fastsave") {
                window.location.reload();
              } else {
                localStorage.setItem('sareqeditlink','sareq');
                this.router.navigate(["/controlpanel"]);
              }
            }
          },
          error: (error) => {
            this.loadingData = false;
            console.log("Update error: ", error);
            this.router.navigate(["/controlpanel"]);
            this.location.back();
            this.utilService.onError(error);
            this.loadingData = false;
          },
        });
      } else {
        this.sareqService.createSareqWithId(this.sareq);
      this.sareqService
        .getSareqDataListener()
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.loadingData = false;
            if (path == "fastsave") {
              window.location.reload();
            } else {
              this.router.navigate(["/controlpanel"]);
              this.location.back();
              // this.router.navigate(["/sareq"]);
            }
          },
          error: (error) => {
            this.loadingData = false;
            console.log("Update error: ", error);
            // this.router.navigate(["/sareq"]);
            this.router.navigate(["/controlpanel"]);
            this.location.back();
            this.utilService.onError(error);
            this.loadingData = false;
          },
        });
      }
  }

  setName(manufacturer) {
    this.sareq.supplier_name = manufacturer.name;
    this.sareq.supplier_id = manufacturer.id;
  }

  addManufacturer() {
    this.router.navigate(["/manufactureritem"], { queryParams: { id: "new" } });
  }

  addPictogramToSareq(pictogram) {
    if (this.pictogramInSareq(pictogram)) {
      this.sareq[pictogram] = 0;
      this.sareq["clp_" + pictogram] = 0;
    } else {
      this.sareq[pictogram] = 1;
      this.sareq["clp_" + pictogram] = 1;
    }
  }

  addOrRemoveProtective(name) {
    if (this.protectiveInSareq(name)) {
      this.sareq[name] = 0;
    } else {
      this.sareq[name] = 1;
    }
  }

  addADRPictogramToSareq(name) {
    if (_.isEqual(this.sareq.transport[0].transport_label_name_1, name)) {
      this.sareq.transport[0].transport_label_name_1 = "";
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_1, "")) {
      this.sareq.transport[0].transport_label_name_1 = name;
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_2, name)) {
      this.sareq.transport[0].transport_label_name_2 = "";
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_2, "")) {
      this.sareq.transport[0].transport_label_name_2 = name;
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_3, name)) {
      this.sareq.transport[0].transport_label_name_3 = "";
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_3, "")) {
      this.sareq.transport[0].transport_label_name_3 = name;
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_4, name)) {
      this.sareq.transport[0].transport_label_name_4 = "";
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_4, "")) {
      this.sareq.transport[0].transport_label_name_4 = name;
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_5, name)) {
      this.sareq.transport[0].transport_label_name_5 = "";
      return;
    }

    if (_.isEqual(this.sareq.transport[0].transport_label_name_5, "")) {
      this.sareq.transport[0].transport_label_name_5 = name;
      return;
    }
  }

  toggleAdrEnvironment() {
    this.sareq.transport[0].adr_environment = this.sareq.transport[0].adr_environment === 0 ? 1 : 0;
  }

  toggleAdrIataEnvironment() {
    this.sareq.transport[0].iata_environment = this.sareq.transport[0].iata_environment === 0 ? 1 : 0;
  }

  toggleAdrImdgEnvironment() {
    this.sareq.transport[0].imdg_environment = this.sareq.transport[0].imdg_environment === 0 ? 1 : 0;
  }

  toggleFootprint(id) {

    if (id == 0)
    {
      this.sareq.footprint_water = this.sareq.footprint_water === 0 ? 1 : 0;
    }

    if (id == 1)
    {
      this.sareq.footprint_air = this.sareq.footprint_air === 0 ? 1 : 0;
    }

    if (id == 2)
    {
      this.sareq.footprint_product = this.sareq.footprint_product === 0 ? 1 : 0;
    }

    if (id == 3)
    {
      this.sareq.footprint_waste = this.sareq.footprint_waste === 0 ? 1 : 0;
    }
  }

  toggleEcoLabels(id) {

    if (id == 0)
    {
      this.sareq.eco_eu = this.sareq.eco_eu === 0 ? 1 : 0;
    }

    if (id == 1)
    {
      this.sareq.eco_svanen = this.sareq.eco_svanen === 0 ? 1 : 0;
    }

    if (id == 2)
    {
      this.sareq.eco_GEC = this.sareq.eco_GEC === 0 ? 1 : 0;
    }
  }

  // Didnt feel like repeating for every checkbox
  showCheckboxClicked(number) {
    switch (number) {
      case 0:
        this.show_information = !this.show_information;
        if (this.show_information == false) {
          this.sareq.information = "";
        }
        break;
      case 1:
        this.show_training = !this.show_training;
        if (this.show_training == false) {
          this.sareq.training = "";
        }
        break;
      case 2:
        this.show_health_control = !this.show_health_control;
        if (this.show_health_control == false) {
          this.sareq.health_control = "";
        }
        break;
      case 3:
        this.show_fitness_for_duty = !this.show_fitness_for_duty;
        if (this.show_fitness_for_duty == false) {
          this.sareq.fitness_for_duty = "";
        }
        break;
      case 4:
        this.show_no_special_demands = !this.show_no_special_demands;
        if (this.show_no_special_demands == false) {
          this.sareq.no_special_demands = "notset";
        } else {
          if ( this.sareq.no_special_demands == null || this.sareq.no_special_demands == "notset" ) {
            this.sareq.no_special_demands = "";
          }
        }
        break;
      case 5:
        this.sareq.hygiene_limits = !this.sareq.hygiene_limits;
        if(this.sareq.hygiene_limits==true&&this.sareq.no_hygiene_limits==true){this.sareq.no_hygiene_limits=false}
        if (this.sareq.hygiene_limits == false) {
          this.sareq.hygiene_limits_comment = "";
        }
        break;
      case 6:
        this.sareq.no_hygiene_limits = !this.sareq.no_hygiene_limits;
        if(this.sareq.no_hygiene_limits==true&&this.sareq.hygiene_limits==true){this.sareq.hygiene_limits=false}
        break;
      default:
        break;
    }
  }

  switchStatus(Status) {
    // adding a case for if its not translated
    switch (Status) {
      case this.translate.instant("SAREQ_STATUS_5"):
        this.sareq.status = 5;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_5":
        this.sareq.status = 5;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
      case this.translate.instant("SAREQ_STATUS_4"):
        this.sareq.status = 4;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_4":
        this.sareq.status = 4;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
      case this.translate.instant("SAREQ_STATUS_2"):
        this.sareq.status = 2;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_2":
        this.sareq.status = 2;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
      case this.translate.instant("SAREQ_STATUS_3"):
        this.sareq.status = 3;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_3":
        this.sareq.status = 3;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
      case this.translate.instant("SAREQ_STATUS_0"):
        this.sareq.status = 0;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_0":
        this.sareq.status = 0;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
      case this.translate.instant("SAREQ_STATUS_1"):
        this.sareq.status = 1;
        this.status_text = this.status_text_list[this.sareq.status];
        break;
      case "SAREQ_STATUS_1":
        this.sareq.status = 1;
        this.status_text = this.translate.instant(
          this.status_text_list[this.sareq.status]
        );
        break;
    }
    //this.showStatus = !this.showStatus;
  }

  switchLanguage(Language, i) {
    let lan = this.translate.instant(Language);
    for (var n = 0; n < this.availableLangs.length; n++) {
      if (this.availableLangs[n] == lan) {
        Swal.fire("Datablad finns redan på detta språk, redigera detta");
        return;
      }
    }

    this.newSareq = true;
    this.sareqNewLangs = lan;
    this.sareq.override = this.sareq.id;
    this.sareq.hideAddLanguage = true;
    this.sareq.lang = i;
    this.sareq.name = "";
    this.sareq.com_use = "";
    this.sareq.com_use_short = "";
    this.sareq.ice_general_information = "";
    this.sareq.ice_inhalation = "";
    this.sareq.mutagenicity = "";
    this.sareq.ice_skin_contact = "";
    this.sareq.cancerogenity = "";
    this.sareq.reproduction_toxicity = "";
    this.sareq.ice_eye_contact = "";
    this.sareq.ice_consumption = "";
    this.sareq.ice_symptom = "";
    this.sareq.prot_breath = "";
    this.sareq.prot_gloves = "";
    this.sareq.prot_glasses = "";
    this.sareq.prot_clothes = "";
    this.sareq.prot_hygiene = "";
    this.sareq.prot_breath_small = "";
    this.sareq.prot_gloves_small = "";
    this.sareq.prot_glasses_small = "";
    this.sareq.prot_clothes_small = "";
    this.sareq.handling_emissions = "";
    this.sareq.handling = "";
    this.sareq.handling_storage = "";
    this.sareq.handling_waste = "";
    this.sareq.handling_fire_measures = "";
    this.sareq.fire_ext_agent = "";
    this.sareq.fire_uns_ext_agent = "";
    this.sareq.combustion_products = "";
    this.sareq.other_dangers = "";
    let newStatus = this.translate.instant("SAREQ_STATUS_4");
    this.switchStatus(newStatus);
  }

  setLanguage(Language, i) {
    let lan = this.translate.instant(Language);
    for (var n = 0; n < this.availableLangs.length; n++) {
      if (this.availableLangs[n] == lan) {
        Swal.fire("Datablad finns redan på detta språk, redigera detta");
        return;
      }
    }
    this.sareqSetLangs = this.translate.instant(
      this.sareqNewLangsList[i]
    );
    this.sareq.lang = i;
  }

  addNewSubstance() {
    this.router.navigate([]).then((result) => {
      window.open("/#/addnewsubstance", "_blank");
    });
  }

  removeMixture(mixture) {
    var idx = this.sareq.mixtures.indexOf(mixture);
    if (idx > -1) {
      this.sareq.mixtures.splice(idx, 1);
    }
  }

  setAddMixture() {
    this.showAddMixture = !this.showAddMixture;
  }

  setAddSubstance() {
    this.showAddSubstance = !this.showAddSubstance;
  }

  addToSubstance() {
    this.showAddSubstance = !this.showAddSubstance;
  }

  removeSubstance(substance, id) {
    var idx;

    for (var i = 0; i < this.displayedSubstances.length; i++) {
      if (this.displayedSubstances[i].id == substance.id)
      {
        this.displayedSubstances[i].isSelected = false;
      }
    }

    if (id == 0) {
      idx = this.sareq.substances.indexOf(substance);
      if (idx > -1) {
        this.sareq.substances.splice(idx, 1);
      }
    }
    else {
      idx = this.sareq.mixtures.indexOf(substance);
      if (idx > -1) {
        this.sareq.mixtures.splice(idx, 1);
      }
    }
  }

  addToMixture() {
    this.showAddMixture = !this.showAddMixture;
  }

  addSubstanceToProduct(substance, id) {
    substance.min = 0;
    this.substanceService.getSingle(substance.id);
    this.substanceService
      .getSubstanceDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response == null) {
          } else {
            response.min = 0;
            response.max = 100;
            if (id == 0) {
              if (this.sareq.substances.length === 0) {
                substance.isSelected = true;
                this.sareq.substances.push(response);
              }
              else
              {
                Swal.fire("Error", "Det går bara att lägga till ett ämne på 3.1!");
              }
            }
            else {
              let duplicate = false;
              for (var i = 0; i < this.sareq.mixtures.length; i++) {
                if (this.sareq.mixtures[i].id == substance.id) {
                  duplicate = true;
                  Swal.fire({
                    title: "" + this.translate.instant("DUPLICATE_MIXTURE") + "",
                    text:   "" + this.translate.instant("DUPLICATE_MIXTURE_MESSAGE") + "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: this.translate.instant("OK"),
                    cancelButtonText: this.translate.instant("ABORT"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if ( duplicate == true) {
                        substance.isSelected = true;
                        this.sareq.mixtures.push(response);
                      }
                    }
                  });
                  return;
                }
              }
              if ( duplicate == false) {
                substance.isSelected = true;
                this.sareq.mixtures.push(response);
              }
            }
          }
        },
        error: (error) => {},
      });
  }

  setChem(index) {
    this.sareq.secretchemOrg = this.secretComps[index].id;
    this.secretCompsname = this.secretComps[index].name;
    this.chem = this.secretComps[index].id;
  }

  fileChange(event, type) {
    if (type == "GRB") {
      this.riskfile = event.target.files[0];
      event.target.value = '';
    }

    if (type == "SDB") {
      this.securityfile = event.target.files[0];
      event.target.value = '';
      this.sareq.not_clp = 0;
    }

    if (type == "SDB_EXT") {
      this.securityextendedfile = event.target.files[0];
      event.target.value = '';
    }

    if (type == "PDS") {
      this.productfile = event.target.files[0];
      event.target.value = '';
    }

    if (type == "PICTURE") {
      this.picturefile = event.target.files[0];
      event.target.value = '';
    }
  }

  deletePost(sareqId) {
    if(this.currentUser.is_maintenance_user){
      if(this.sareq.is_in_organisation==true||(this.sareq.status==1||this.sareq.status==5)){
        Swal.fire({
          title: "Ta bort",
          text: "Du har inte behörighet att ta bort denna post.",
          confirmButtonText: this.translate.instant("OK")
        })
        return;
      }
    }

    Swal.fire({
      title: "Delete Post?",
      text: "This will delete the post.",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("OK"),
      cancelButtonText: this.translate.instant("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.sareq.hidden = 1;
        this.isDeleted = true;
        this.save("");
        this.isDeleted = false;
        this.router.navigate(["/controlpanel"]);
        this.location.back();
      } else if (result.isDenied) {
      }
    });
  }

  addEan(ean) {
    if (typeof ean == "undefined" || ean == "") {
      this.eanError = true;
      return;
    } else {
      this.eanError = false;
    }
    if (this.sareq.eancodes.indexOf(ean) == -1) {
      this.sareq.eancodes.push(ean);
    }
  }

  removeEan(ean) {
    this.sareq.eancodes.splice(this.sareq.eancodes.indexOf(ean), 1);
  }

  checkDate(thisdate) {
    if (thisdate == "") {
      return new Date().toLocaleDateString("sv");
    } else {
      return new Date(thisdate).toLocaleDateString("sv");
    }
  }

  changetransport(item) {
    if (item == 0) {
      this.sareq.transport[0].adr_not_classfied = 1;
    } else {
      this.sareq.transport[0].adr_not_classfied = 0;
    }
  }

  filterSearchItems(type){
    this.allProducts=this.tempallProducts;
    if(type=='name'){
      if(this.sareqSearch.name==''){
        this.sareqSearch.name=null;
      }
      if(this.sareqSearch.name!=null){
        this.allProducts=this.allProducts.filter(o => o.name?.toLowerCase().indexOf(this.sareqSearch.name.toLowerCase())>-1);
      }
    }

    if(type=='manufacturer'){
      if(this.sareqSearch.manufacturer==''){
        this.sareqSearch.manufacturer=null;
      }
      if(this.sareqSearch.manufacturer!=null){
        this.allProducts=this.allProducts.filter(o => o.supplier_name?.toLowerCase().indexOf(this.sareqSearch.manufacturer.toLowerCase())>-1);
      }
    }

    if(type=='supplier_article_number'){
      if(this.sareqSearch.articlenumber==''){
        this.sareqSearch.articlenumber=null;
      }
      if(this.sareqSearch.articlenumber!=null){
        this.allProducts=this.allProducts.filter(o => o.supplier_article_number?.toLowerCase().indexOf(this.sareqSearch.articlenumber.toLowerCase())>-1);
      }
    }
  }

  sortProducts(type){
    if(this.sortorder=='asc'){
      this.sortorder='desc';
      this.allProducts=_.orderBy(this.allProducts,[item => item[type]?.toLowerCase()], ['asc']);
    } else if(this.sortorder=='desc'){
      this.sortorder='asc';
      this.allProducts=_.orderBy(this.allProducts,[item => item[type]?.toLowerCase()], ['desc']);
    }
  }

  addProductToReplaceList ( product ) {
    if ( product == '' ) {
      if ( this.no_item == false ) {
        this.replaceByName=this.translate.instant("NOTDECIDED");
        this.sareq.replaced_by='';
        this.replaceBy=true;
      }
    } else {
      if ( this.no_item != true ) {
        this.replaceByName=product.name;
        this.sareq.replaced_by=product.id;
        this.replaceBy=true;
      } else {
        this.no_item=false;
        this.replaceByName=product.name;
        this.sareq.replaced_by=product.id;
        this.replaceBy=true;
      }
    }
  }

  checkboxsetter() {
    this.replaceCheckboxChecked = !this.replaceCheckboxChecked;
    if ( this.replaceCheckboxChecked==true ) {
      this.replaceBy=false;
      this.replaceByName="";
      this.no_item=false;
      this.loadingSareq = true;
      if ( !this.allProducts || this.allProducts.length == 0 ) {
        this.loadAllSareqList();
      } else {
        this.loadingSareq = false;
      }
    } else {
      this.replaceByName="";
      this.sareq.replaced_by='';
    }
  }

  checkIfFieldIsTooLong() {
    if ( this.sareq.status == 2 || this.sareq.status == 3 || this.sareq.status == 4 || this.sareq.status == 5 ) {
      return false;
    }

    this.sareq.supplier_name
    let arrayItems = [
      {'name':'name','translatename':'NAME','length':''},
      {'name':'supplier','translatename':'MANUFACTURER','length':''},
      {'name':'alternative_name','translatename':'ALTERNATIVE_NAME','length':300},
      {'name':'articlenumber','translatename':'ARTICLE_NUMBER','length':2000},
      {'name':'com_use_short','translatename':'COMMON_USE_SHORT','length':50},
      {'name':'ice_general_information','translatename':"Allmän information",'length':300},
      {'name':'ice_inhalation','translatename':'AT_INHALATION','length':300},
      {'name':'ice_skin_contact','translatename':'AT_SKIN_CONTACT','length':400},
      {'name':'ice_eye_contact','translatename':'AT_EYE_CONTACT','length':400},
      {'name':'ice_consumption','translatename':'AT_CONSUMPTION','length':400},
      {'name':'ice_symptom','translatename':'Symptom','length':200},
      {'name':'fire_ext_agent','translatename':'EXTINGUISHING_AGENT','length':200},
      {'name':'fire_uns_ext_agent','translatename':'UNSUITABLE_EXTINGUISHING_AGENT','length':100},
      {'name':'combustion_products','translatename':'COMBUSTION_PRODUCTS','length':100},
      {'name':'handling_fire_measures','translatename':'FIRE_MEASURES','length':600},
      {'name':'handling_emissions','translatename':'UNINTENTIONAL_EMISSIONS','length':1300},
      {'name':'handling','translatename':'HANDLING','length':400},
      {'name':'handling_storage','translatename':'STORAGE','length':300},
      {'name':'prot_breath_small','translatename':'RESPIRATORY_SHORT','length':100},
      {'name':'prot_gloves_small','translatename':'HAND_PROTECTION_SHORT','length':100},
      {'name':'prot_glasses_small','translatename':'EYE_PROTECTION_SHORT','length':100},
      {'name':'prot_clothes_small','translatename':'PROTECTIVE_CLOTHING_SHORT','length':100},
      {'name':'observation','translatename':'OBSERVATION','length':100}
    ]

    var toLongText="";
    var requiredText="";
    var allergicText="";
    var totalErrorText="";

    if ( this.checkDates ( ) == false ) {
      return true;
    }

    if ( this.checkEmptyFields () == false ) {
      return true;
    }

    for (var i = 0; i < this.sareq.mixtures.length; i++)
    {
      if (this.sareq.mixtures[i].min > this.sareq.mixtures[i].max)
      {
        Swal.fire(
          this.translate.instant("Blandningar min värde är större än max värdet!"),
          totalErrorText,
          "Error"
        );
        return true;
      }
    }

    for (var i = 0; i < this.sareq.substances.length; i++)
    {
      if (this.sareq.substances[i].min > this.sareq.substances[i].max)
      {
        Swal.fire(
          this.translate.instant("Ämnen min värde är större än max värdet!"),
          totalErrorText,
          "Error"
        );
        return true;
      }
    }

    for(var n=0;n<arrayItems.length;n++){
      if(n==0){
        if(this.sareq[arrayItems[n].name] == "") {
          requiredText += this.translate.instant(arrayItems[n].translatename)+"<br/>";
        }
      }else if(n==1) {
        if(!this.sareq.supplier_name){
          requiredText += this.translate.instant(arrayItems[n].translatename)+"<br/>";
        }
      }else{
        if(this.sareq[arrayItems[n].name]&&this.sareq[arrayItems[n].name].length > arrayItems[n].length) {
          toLongText += this.translate.instant(arrayItems[n].translatename)+"<br/>";
        }
      }
    }

    if(this.sareq.allergic === null && this.currentUser.is_maintenance_user){
      allergicText += this.translate.instant("ALLERGIC_REQUIRED")+"<br/>";
    }

    if(requiredText != ""){
      totalErrorText=this.translate.instant("TEXT_IN_THESE_FIELDS_REQUIRED")+"<br/>"+requiredText+"<hr/>";
    }

    if(toLongText != ""){
      totalErrorText=totalErrorText+this.translate.instant("TEXT_IN_THESE_FIELDS_TOO_LONG")+"<br/>"+toLongText+"<hr/>";
    }

    if(allergicText != ""){
      totalErrorText=totalErrorText+allergicText+"<br/>"+toLongText+"<hr/>";
    }

    if(totalErrorText!=""){
      Swal.fire(
        this.translate.instant("ERROR_IN_INPUT"),
        totalErrorText,
        "error"
      );
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.authService.logout();
  }

  allowedToDelete(){
    if(this.sareq.id&&!this.sareq.is_in_organisation){
      this.organisationService.idExistsInProducts(this.sareq.id);
      this.organisationService
        .getOrganisationDataListener()
        .pipe(take(1))
        .subscribe({
          next: (exists) => {
            if (exists == false) {
              this.sareq.is_in_organisation=false;
            } else {
              this.sareq.is_in_organisation=true;
            }
          },
          error: (error) => {},
        });
    }
  }

  allergiccheckboxclicked(bool,type){
    // Can probably be refactored but this works
    // bool is what checkbox is clicked
    // bool==true allergic, bool==false non_allergic
    // type is if Im on tabview (tab) or listview (list)

    if(type=='list'){
      if(bool==true){
        this.sareq.allergic = 1;
      }
      if(bool==false) {
        this.sareq.allergic = 0;
      }
    }
  }

  getCdocUsers(){
    this.userService.getCdocUsers();
    this.userService
      .getCurrentUserDataListener()
      .pipe(take(1))
      .subscribe({
        next: (users) => {
          if (users == null) {

          } else {
            for(var n=0;n<users.length;n++){
              this.cdocUserList.push(users[n].username);
            }
          }
        },
        error: (error) => {},
      });
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  onInputChange(event: any) {
    const inputValue = event.target.value;

    // Replace commas with periods to ensure a consistent format
    const sanitizedValue = inputValue.replace(',', '.');

    // Validate that the input value is a valid number
    const numericValue = parseFloat(sanitizedValue);

    if (!isNaN(numericValue)) {
      // Update the model with the numeric value
      this.sareq.ph_value = numericValue;
    } else {
      // Handle invalid input here if needed
    }
  }

  checkDates ( ) {
    var dateArray = [
      { 'name' : 'rework_date', 'translatename' : 'REVISION_DATE', 'length' : '' },
      { 'name' : 'replaces_date', 'translatename' : 'REPLACEMENT_DATE', 'length' : '' }
    ]

    let currentDate = new Date();
    let dateTenYearsAgo = new Date();
    dateTenYearsAgo.setFullYear(currentDate.getFullYear() - 10);

    for ( var n=0; n<dateArray.length; n++ ) {
      let inputDate = new Date( this.checkDate((this['sareq'][dateArray[n].name] ) ) );
      if ( inputDate > currentDate ) {
        Swal.fire(
          this.translate.instant("ERROR_IN_INPUT"),
          this.translate.instant("DATE_CANNOT_BE_IN_FUTURE")+" "+this.translate.instant(dateArray[n].translatename),
          "error"
        );
        return false;
      }
      if ( inputDate < dateTenYearsAgo ) {
        Swal.fire(
          this.translate.instant("ERROR_IN_INPUT"),
          this.translate.instant("DATE_CANNOT_BE_MORE_THAN_TEN_YEARS_OLD")+" "+this.translate.instant(dateArray[n].translatename),
          "error"
        );
        return false;
      }
    }
    return true;
  }

  checkEmptyFields () {
    var protArray = [
      {'name' : 'prot_breath', 'translatename' : 'RESPIRATORY', 'length' : '' },
      {'name' : 'prot_breath_small', 'translatename' : 'RESPIRATORY_SHORT', 'length' : '' },
      {'name' : 'prot_gloves', 'translatename' : 'HAND_PROTECTION', 'length' : '' },
      {'name' : 'prot_gloves_small', 'translatename' : 'HAND_PROTECTION_SHORT', 'length' : '' },
      {'name' : 'prot_glasses', 'translatename' : 'EYE_PROTECTION', 'length' :'' },
      {'name' : 'prot_glasses_small', 'translatename' : 'EYE_PROTECTION_SHORT', 'length' : '' },
      {'name' : 'prot_clothes', 'translatename' : 'PROTECTIVE_CLOTHING', 'length' : '' },
      {'name' : 'prot_clothes_small', 'translatename' : 'PROTECTIVE_CLOTHING_SHORT', 'length' : '' }
    ]

    for ( var n=0; n < protArray.length; n++ ) {
      if(this.sareq[protArray[n].name] == "") {
        Swal.fire(
          this.translate.instant("ERROR_IN_INPUT"),
          this.translate.instant("TEXT_IN_THESE_FIELDS_REQUIRED")+" "+this.translate.instant(protArray[n].translatename),
          "error"
        );
        return false;
      }
    }
    return true;
  }

  select(organisationId) {
    this.organisationService.getOrganisation(organisationId);
    this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
      next: organisation => {
          localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));
          localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
          localStorage.setItem('selectedDepartment', JSON.stringify(organisation));
          localStorage.removeItem("allDepartments");
          this.router.navigate( [ "/productlist" ] ).then( () => {
            window.location.reload();
          } );
      },
      error: error => {

      }
    })
  }

  reviseSdb ( product ) {
    if ( this.deepEqual ( product, this.duplicate_sareq ) ) {
      this.sareqService.updateSareqWithIdDateFromEditSareq(product.id);
    } else {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.sareq.cdoc_date = new Date();
          this.sareq.assigned  = "super";
          this.save("");
        } else {
          this.sareqService.updateSareqWithIdDateFromEditSareq( product.id );
        }
      })
    }
  }

  // Deep comparison function
  deepEqual ( obj1: any, obj2: any ): boolean {
    if ( obj1 === obj2 ) {
      return true;
    }
    if ( typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null ) {
      return false;
    }
    const keys1 = Object.keys ( obj1 );
    const keys2 = Object.keys ( obj2 );

    if ( keys1.length !== keys2.length ) {
      return false;
    }

    for ( const key of keys1 ) {
      if ( !keys2.includes(key) || !this.deepEqual ( obj1[key], obj2[key] ) ) {
        return false;
      }
    }
    return true;
  }

  createQR(product){
    this.sareqService.createQRForId(product.id)
    this.sareqService.getSareqQRDataListener().pipe(take(1)).subscribe({
    next: products => {
      this.loadingData = false;
      if (products == null) {
        Swal.fire(this.translate.instant("QR_CODE_CREATED"));
      }
      else
      {
        Swal.fire(this.translate.instant("QR_CODE_CREATED"));
        this.loadingData = false;
      }
    },
    error: error => {
      Swal.fire(
        this.translate.instant("ERROR_QR_CODE"),
        this.translate.instant("ERROR_QR_CODE"),
        "error"
      );
      this.loadingData = false;
    }
  })
  }

  updateSubstanceList(){
    this.loadingDataSubstance = true;
    this.loadSubstances();
    setTimeout(() => {
      this.loadingDataSubstance = false;
    }, 3000);

  }
}
