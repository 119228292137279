import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { TranslateService } from "@ngx-translate/core";
import { UserService } from 'src/app/services/user.service';
import { ModalAddInventoryComponent } from 'src/app/modals/modal-add-inventory/modal-add-inventory.component';
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit{
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  loadingData: boolean = true;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  inventoryList: any = [];
  prevInventory: any = {};
  inventoryListDuplicate: any = [];
  showSubdepartments: boolean = true;
  listOfDepartments: any = [];
  listAlldepartments: any = [];
  listOfDepartmentsDuplicate: any = [];
  inventorySearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  order = ["asc", "asc","asc", "asc", "asc","asc","asc", "asc",];

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private userService: UserService,
    private sareqService: SareqService,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.inventoryList = [];
    localStorage.removeItem('inventory');

    this.organisation = JSON.parse( localStorage.getItem( "rootOrganisation" ) );
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );

    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );

    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null) 
    {
      this.logout();
    }

    let data = localStorage.getItem('currentUser', );
    if (data != null && data != undefined)
    {
      this.currentUser = JSON.parse(data);
    }

    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartments")
    );

    if (this.showSubdepartments == null) 
    {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.listAlldepartments = null;

    this.getAllDepartments();
    this.listOfDepartments = this.utilService.getAllSubdepartments(this.selectedOrganisation,false);
    this.listOfDepartmentsDuplicate=_.cloneDeep(this.listOfDepartments);

    if (this.showSubdepartments == true) {
      this.getAllInventories();
    }
    else
    {
      this.getAllInventoriesDepartment(this.selectedDepartment);
    }

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.ngOnInit();
    })
  }

  logout() {
    this.authService.logout();
  }

  getAllDepartments() {
    if (this.listAlldepartments == null ||
      (this.organisation &&
        this.organisation.id !=
          JSON.parse(localStorage.getItem("rootOrganisation")).id)
    ) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService
        .getOrganisationAllDepartmentNamesDataListener()
        .pipe(take(1))
        .subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  extractDepartmentIds(departmentArray: any[]): string[] {
    let departmentIds: string[] = [];
  
    if (!departmentArray || !Array.isArray(departmentArray)) return departmentIds;
  
    departmentArray.forEach(department => {
      if (department.id) {
        departmentIds.push(department.id); // ✅ Add department ID
      }
      if (department.orgs && Array.isArray(department.orgs)) {
        departmentIds = departmentIds.concat(this.extractDepartmentIds(department.orgs)); // ✅ Recursively extract from sub-orgs
      }
    });
  
    return departmentIds;
  }

  startInventory() {

    const modalRef = this.modalService.open(ModalAddInventoryComponent);
    modalRef.componentInstance.listOfDepartments = this.listOfDepartments;
    modalRef.componentInstance.listAlldepartments = this.listAlldepartments;
    modalRef.componentInstance.inventory = null;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((Inventory) => {
      if (Inventory == null) {
        return;
      }

      // ✅ Extract all department IDs from `Inventory.departmentArray` (recursive)
      const newInventoryDepartmentIds = this.extractDepartmentIds(Inventory.departmentArray);

      // ✅ Extract department IDs from `inventoryList` where status is "ONGOING" or "NEW"
      const existingDepartmentIds = new Set(
        this.inventoryList
          .filter(inventory => inventory.status === this.translate.instant('ONGOING') || inventory.status === this.translate.instant('NEW'))
          .flatMap(inventory => this.extractDepartmentIds(inventory.departmentArray))
      );

      // ✅ Check if any department in `newInventoryDepartmentIds` exists in `existingDepartmentIds`
      const hasConflict = newInventoryDepartmentIds.some(departmentId =>
        existingDepartmentIds.has(departmentId)
      );

      // 🚨 Show an error message if a conflict exists
      if (hasConflict) {
        Swal.fire(
          this.translate.instant("INVENTORY"),
          this.translate.instant("INVENTORY_ALREADY_EXISTS"),
          "error"
        );
        return; // 🚨 Stop execution
      }

      this.loadingData = true;
      this.inventoryService.createInventory(this.organisation.id, Inventory);
      this.inventoryService.getInventoryDataListener().pipe(take(1)).subscribe({
        next: inventory => {
          window.location.reload();
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    })
  }

  getAllInventoriesDepartment(department) {
    this.inventoryList = [];
    this.inventoryService.getAllInventoriesDepartment(this.organisation.id, department.id);
    this.inventoryService.getInventoriesDataListener().pipe(take(1)).subscribe({
      next: inventories => {
        if (!inventories || inventories.length === 0) {
          this.loadingData = false;
          return;
        }
        this.inventoryList = inventories;
  
        // Create a lookup map for departments for efficient matching
        const departmentMap = this.listAlldepartments?.reduce((map, department) => {
          map[department.id] = department;
          return map;
        }, {});
  
        // Process inventory list
        this.inventoryList.forEach(inventory => {
          const startDate = new Date(inventory.start_date);
          const endDate = new Date(inventory.end_date);
          if (startDate > new Date()) {
            inventory.status = this.translate.instant("NEW");
          } else if (endDate < new Date()) {
            inventory.status = this.translate.instant("FINISHED");
          } else {
            inventory.status = this.translate.instant("ONGOING");
          }

          if (inventory.approved == 1) {
            inventory.status = this.translate.instant("FINISHED_AND_APPROVED");
          }

          var departmentsArray = inventory.departments?.split(","); // Split departments string into array
          inventory.departmentArray = departmentsArray
            .map(departmentId => departmentMap[departmentId] || null) // Use lookup map for matching
            .filter(Boolean); // Remove nulls

          inventory.departmentArray = this.addProductCounts(inventory, inventory.departmentArray);
        });
  
        this.loadingData = false;
      },
      error: error => {
        console.error("Error fetching inventories:", error);
        this.loadingData = false;
      }
    });
  }

  getAllInventories() {
    this.inventoryService.getInventories(this.organisation.id);
    this.inventoryService.getInventoriesDataListener().pipe(take(1)).subscribe({
      next: inventories => {
        if (!inventories || inventories.length === 0) {
          this.loadingData = false;
          return;
        }
        this.inventoryList = inventories;
  
        // Create a lookup map for departments for efficient matching
        const departmentMap = this.listAlldepartments?.reduce((map, department) => {
          map[department.id] = department;
          return map;
        }, {});
  
        // Process inventory list
        this.inventoryList.forEach(inventory => {
          const startDate = new Date(inventory.start_date);
          const endDate = new Date(inventory.end_date);
          if (startDate > new Date()) {
            inventory.status = this.translate.instant("NEW");
          } else if (endDate < new Date()) {
            inventory.status = this.translate.instant("FINISHED");
          } else {
            inventory.status = this.translate.instant("ONGOING");
          }

          var departmentsArray = inventory.departments?.split(","); // Split departments string into array
          inventory.departmentArray = departmentsArray
            .map(departmentId => departmentMap[departmentId] || null) // Use lookup map for matching
            .filter(Boolean); // Remove nulls

          inventory.departmentArray = this.addProductCounts(inventory, inventory.departmentArray);

          const allApproved = inventory.departmentArray.every(departmentArray => departmentArray.productCountApproved > 0);
          if (allApproved == true) {
            inventory.status = this.translate.instant("FINISHED");
          }

          if (inventory.approved == 1) {
            inventory.status = this.translate.instant("FINISHED_AND_APPROVED");
          }
        });
  
        this.loadingData = false;
      },
      error: error => {
        console.error("Error fetching inventories:", error);
        this.loadingData = false;
      }
    });
  }

  // Function to add product count to departmentsArray with productCountInvented
  addProductCounts(inventory: any, departmentsArray: any): any[] {
    return departmentsArray.map(department => {
      // Count total products for the department
      const productCount = inventory.products.filter(
        product => product.organisation_id === department.id
      ).length;

      // Count products where status == 1 for the department
      const productCountInvented = inventory.products.filter(
        product => product.organisation_id === department.id && product.status === 1
      ).length;

      const productNoSareqCount = inventory.productsNoSareq.filter(
        product => product.organisation_id === department.id
      ).length;

      // Count products where status == 1 for the department
      const productCountApproved = inventory.products.filter(
        product => product.organisation_id === department.id && product.approved === 1
      ).length;

      // Return the updated department object
      return {
        ...department,
        productCount, // Add the count of all products
        productCountInvented, // Add the count of products with status == 1
        productNoSareqCount, // Add the count of products not in Sareq with status == 0
        productCountApproved // Add the count of products with approved == 1
      };
    });
  }

  getTruncatedDepartments(department1: string, department2: string): string {
    if (!department1 || !department2) {
      return '...';
    }

    const maxChars = 16;

    // If department1 fits fully, show it entirely with department2 truncated if needed
    if (department1.length < maxChars) {
      const remainingChars = maxChars - department1.length - 2; // Reserve space for ", "
      const truncatedDept2 = department2.slice(0, remainingChars);
      return `${department1}, ${truncatedDept2}${department2.length > remainingChars ? '...' : '...'}`;
    }

    // If department1 is too long, truncate both
    const truncatedDept1 = department1.slice(0, maxChars - 5); // Reserve space for ", ..."
    return `${truncatedDept1}..., ${department2.slice(0, 5)}...`;
  }

  departFullName(department){
    if (department!=undefined)
    {
      return this.utilService.departFullName(department, this.listAlldepartments);
    }
  }

  checkDepartments(departmentsArray: any[]): boolean {
    return departmentsArray.every(department => 
      department.productCountApproved === department.productCount
    );
  }

  approveInventory(inventory) {

    let result = this.checkDepartments(inventory.departmentArray);
    if (result == false) {
      Swal.fire(this.translate.instant("APPROVE"), this.translate.instant("INVENTORY_IS_NOT_COMPLETE"),"error");
    }
    else
    {
      Swal.fire({
        title: this.translate.instant("APPROVE"),
        html: this.translate.instant("CONFIRM_UPDATE_PRODUCT_REGISTER") + "<br><br><div align='left'></div>",
        showCancelButton: true,
        cancelButtonText: this.translate.instant("NO"),
        confirmButtonText: this.translate.instant("YES"),
        closeOnConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingData = true;
          this.inventoryService.updateOrganisationProducts(this.organisation.id, inventory.id, this.currentUser.id);
          this.inventoryService.getInventoryDataListener().pipe(take(1)).subscribe({
            next: products => {
              window.location.reload();
            },
            error: error => {
              console.log("Error: ", error);
              this.loadingData  = false;
            }
          })
        }
      })  
    }
  }

  getTruncatedDepartment(department: string): string {
    const maxChars = 16;

    if (!department) {
      return '';
    }

    // Truncate and add ellipsis if necessary
    return department.length > maxChars ? department.slice(0, maxChars) + '...' : department;
  }

  toggleDropdown(event: Event, inventory: any) {
    event.stopPropagation();
    inventory.dropdownOpen = !inventory.dropdownOpen;
  }

  goToInventory(event: Event, department, inventory){
    event.stopPropagation();

    localStorage.setItem('previousSelectedDepartment', JSON.stringify({
      department: this.selectedDepartment,
      page: 'inventorylist',
      timestamp: new Date().getTime()
    }));

    localStorage.setItem('inventory', JSON.stringify(inventory));

    if (department.id != this.selectedOrganisation.id) {
      this.organisationService.changeDepartmentById(department.id);
                    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                      this.utilService.departmentChangeClicked(this.router.url);
                      localStorage.setItem('departmentchanged','set');
                      localStorage.setItem('inventoryDepartment', JSON.stringify(department));
                      this.router.navigate(['/inventoryitem', inventory.id]).then(() => { window.location.reload(); });
                    })
    } else {
      localStorage.setItem('inventoryDepartment', JSON.stringify(department));
      this.router.navigate(['/inventoryitem', inventory.id]);
    }
  }

  onTdClick(event: Event) {
    event.stopPropagation();
    // Prevent the click event from bubbling up to the <tr>
    // If I click on the td but not the button in this area I want nothing to happen
    // as the button click event will handle the click.
  }

  filterSearchItems() {
    this.inventoryList = this.inventoryListDuplicate; 
    if ( this.inventorySearch.all == '' ) {
      this.inventorySearch.all=null;
    }
    if ( this.inventorySearch.all != null ) {
      this.inventoryList = this.inventoryList.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.inventorySearch.all.toLowerCase())>-1);
    }
  }

  onToggleShowSubDepartments(){
    this.showSubdepartments = !this.showSubdepartments;
    localStorage.setItem(
      "showSubdepartments",
      JSON.stringify(this.showSubdepartments)
    );
    this.ngOnInit();
  }

  sortInventories ( property, sortIndex ) {
    if ( this.order[ sortIndex ]== 'asc' ) {
      this.order[ sortIndex ] = 'desc';
      this.inventoryList = _.orderBy( this.inventoryList, [ property ], ['asc']);
    } else if ( this.order[ sortIndex ] == 'desc' ) {
      this.order[ sortIndex ] = 'asc';
      this.inventoryList = _.orderBy( this.inventoryList, [ property ], ['desc'] );
    }
  }

  async gotoInventoryModal(inventory: any): Promise<void> {
    this.prevInventory = _.cloneDeep(inventory);
    const modalRef = this.modalService.open(ModalAddInventoryComponent);
    modalRef.componentInstance.listOfDepartments = this.listOfDepartments;
    modalRef.componentInstance.listAlldepartments = this.listAlldepartments;
    modalRef.componentInstance.inventory = inventory;
    const Inventory = await modalRef.componentInstance.passEntry.pipe(take(1)).toPromise();

    if (Inventory == null) {
        return;
    }

    // Find removed departments
    const prevDepartments = this.prevInventory.departmentArray || [];
    const newDepartments = Inventory.departmentArray || [];

    const removedDepartments = prevDepartments.filter(
        dept => !newDepartments.some(newDept => newDept.id === dept.id)
    );

    if (removedDepartments.length > 0) {

        // Wait for the update to complete before continuing
        this.inventoryService.removeInventoryProductsDepartment(this.organisation.id, Inventory, removedDepartments);

        // Optionally fetch updated data
        await this.inventoryService.getInventoryDataListener().pipe(take(1)).toPromise();
    }

    // ✅ Wait for the inventory update to finish before continuing
    this.inventoryService.updateInventory(this.organisation.id, Inventory.id, Inventory);

    // ✅ Ensure latest inventory data is fetched before updating UI
    await this.inventoryService.getInventoryDataListener().pipe(take(1)).toPromise();

    this.ngOnInit();
  }

  removeInventory(inventory) {
    Swal.fire({
    
      title: this.translate.instant("REMOVE") + "?",
      html: this.translate.instant("CONFIRM_REMOVE_INVENTORY") + "<br><br><div align='left'></div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.inventoryService.deleteInventory(this.organisation.id, inventory.id);
        this.inventoryService.getInventoryDataListener().pipe(take(1)).subscribe({
          next: inventory => {
            window.location.reload();
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    })  
  }
}
