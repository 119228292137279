<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>
<div *ngIf="loadingData==false" class="flex flex-col px-4 max-w-full py-2 mb-2">
    <div class="flex flex-row">
        <p class="hidden md:block text-xl font-bold mt-6 px-4">{{"CHEMICAL_RISK_ASSESSMENTS" | translate}}</p>
        <button type="button" *ngIf="lrbDBFilter" class="button-filter-yellow mt-4" (click)="removeFilter()">
            <span class="text-lg">{{"FILTERED" | translate}}: {{lrbDBFilterName}}</span>
        </button>
    </div>
</div>
<div *ngIf="!loadingData" class="px-4 py-2 mb-40 md:mb-20">
    <div class="overflow-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="assessmenttable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="assessmentSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header" (click)="sortHeader('updated_date')">{{"DATE" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('product_name')">{{"NAME" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('supplier_name')">{{"SUPPLIER" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('department')">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('allergic')">{{"ALLERGIC" | translate}}</th>
                    <th class="table-row-header">{{"SDB/GRB" | translate}}</th>
                    <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                    <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('LRB_status')">{{"STATUS" | translate}}</th>
                    <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                    <th *ngIf="!showSubdepartments" class="table-row-header" (click)="sortHeader('assessment_risk')">{{"LOCAL_RISK_LEVEL" | translate}}</th>
                    <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let chemical of products
                    | filter : assessmentSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };">
                    <td (click)="editChemical(chemical)" class="table-row-column" *ngIf="chemical.updated_date">{{chemical.updated_date | date:'yyyy-MM-dd'}}</td>
                    <td (click)="editChemical(chemical)" class="table-row-column" *ngIf="!chemical.updated_date">-</td>
                    <td (click)="editChemical(chemical)" class="table-row-column">{{chemical.product_name}}</td>
                    <td (click)="editChemical(chemical)" class="table-row-column">{{chemical.supplier_name}}</td>
                    <td *ngIf="showSubdepartments&&chemical.departments.length!=1&&!currentUser.is_guest" class="table-row-column-department">
                        <div class="relative inline-block text-left p-1 w-auto border border-gray-300" appDropdown>
                            <button type="button" (click)="toggleDropdown($event, chemical)" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <span class="nav-home-text text-sm">{{"DEPARTMENTS" | translate}}&nbsp;({{chemical.departments.length}})</span>
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div [class.hidden]="!chemical.dropdownOpen" id="dropdownMenu" class="hidden dropdown-menu w-auto overflow-y-auto max-h-[200px] z-40" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
                                <!-- Header Section -->
                                <div class="px-3 py-2 text-gray-700 font-bold border-b border-gray-300">
                                    {{ "DEPARTMENTS" | translate }}
                                </div>
                                <div class="py-1">
                                    <div *ngFor="let department of chemical.departments" data-value="unit.id" class="dropdown-menu-item truncate"  (click)="gotoChemicalDepartment(chemical,department)" tabindex="1">
                                        <div class="flex flex-row items-center w-auto text-center" *ngIf="department.LRB_risk">
                                            <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>&nbsp;-&nbsp;
                                            <span class="text-sm">{{'RISK' | translate}}&nbsp;{{department.LRB_risk}}(LRB)</span>
                                        </div>
                                        <div class="flex flex-row w-auto items-center w-auto text-center" *ngIf="!department.LRB_risk">
                                            <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                  <td *ngIf="showSubdepartments&&chemical.departments.length==1&&!currentUser.is_guest" class="table-row-column">{{chemical.departments[0].name}}</td>
                  <td *ngIf="(!showSubdepartments&&!currentUser.is_guest)" class="table-row-column">{{selectedDepartment.name}}</td>
                    <td (click)="editChemical(chemical)" class="table-row-column" *ngIf="!chemical.allergic||chemical.allergic==0">{{"NO" | translate}}</td>
                    <td (click)="editChemical(chemical)" class="table-row-column" *ngIf="chemical.allergic==1">{{"YES" | translate}}</td>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <img class="-mr-4" *ngIf="subscribesToSds && ( chemical.esdb_available==0 || ( chemical.esdb_file_id!='' && chemical.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(chemical)" />
                            <img class="-mr-4" *ngIf="subscribesToSds && ( chemical.esdb_available==1 && ( chemical.esdb_file_id=='' || chemical.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(chemical)" />
                            <img class="-mr-4" *ngIf="subscribesToSds && ( chemical.esdb_available==1 && ( chemical.esdb_file_id!='' && chemical.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(chemical)" />
                            <img class="-mr-4" *ngIf="subscribesToGrb" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(chemical)" />
                            <img class="-mr-4" *ngIf="chemical.riskfile && chemical.LRB_status==3 && showSubdepartments==false" tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}"  area-hidden="true" id="LRBIcon" src="/images/icons/LRB.svg" (click)="openLrbInNewWindow(chemical)" />
                            <img *ngIf="chemical.riskfile && chemical.LRB_status!=3 && showSubdepartments==false" tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT_NEEDS_UPDATE' | translate}}"  area-hidden="true" id="LRBIcon" src="/images/icons/LRB_Warning.svg" (click)="openLrbInNewWindow(chemical)" />
                        </div>
                    </td>
                    <td class="table-row-column" (click)="editChemical(chemical)">
                        <div class="flex flex-row">
                            <span *ngFor="let pictogram of chemical.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                <img title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                            </span>
                        </div>
                    </td>
                    <td class="table-row-column" (click)="editChemical(chemical)" *ngIf="showSubdepartments || !showSubdepartments && ( !(!chemical.chemical_id || chemical.chemical_id==null || chemical.chemical_id!='') )">
                        <div class="flex flex-row">
                            <span *ngFor="let protective of chemical.protectiveArray" tooltips tooltip-smart="true" title={{protective.description}}>
                                <img title={{protective.description}} src="/images/protective/{{protective.filename}}-tiny.png" />
                            </span>
                        </div>
                    </td>
                    <td class="table-row-column" (click)="editChemical(chemical)" *ngIf="!showSubdepartments && ( (!chemical.chemical_id || chemical.chemical_id==null || chemical.chemical_id!=''))">
                        <div class="flex flex-row" *ngIf="chemical.protectiveArrayLRB">
                            <span *ngFor="let protective of chemical.protectiveArrayLRB" tooltips tooltip-smart="true" title={{protective.description}}>
                                <img title={{protective.description}} src="/images/protective/{{protective.filename}}-tiny.png" />
                            </span>
                        </div>
                        <div class="flex flex-row" *ngIf="!chemical.protectiveArrayLRB">
                          <span *ngFor="let protective of chemical.protectiveArray" tooltips tooltip-smart="true" title={{protective.description}}>
                              <img title={{protective.description}} src="/images/protective/{{protective.filename}}-tiny.png" />
                          </span>
                      </div>
                    </td>
                    <td class="table-row-column" *ngIf="chemical.LRB_status==null" (click)="editChemical(chemical)"></td>
                    <td class="table-row-column" *ngIf="chemical.LRB_status==0" (click)="editChemical(chemical)">{{"ONGOING" | translate}}</td>
                    <td class="table-row-column" *ngIf="chemical.LRB_status==1" (click)="editChemical(chemical)">{{"FOR_REVIEW" | translate}}</td>
                    <td class="table-row-column" *ngIf="chemical.LRB_status==2" (click)="editChemical(chemical)">{{"FOR_VERIFICATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="chemical.LRB_status==3" (click)="editChemical(chemical)">{{"APPROVED2" | translate}}</td>
                    <td class="table-row-column">
                        <div class="flex flex-col">
                            <div *ngFor="let file of chemical.assessmentchemicalfiles; let i=index" tooltips tooltip-smart="true" title={{file.file_name}}>
                                <div (click)="openFileInNewWindow(file)" class="flex flex-row cursor-pointer whitespace-nowrap">
                                    <img class="w-4 h-4" title={{file.file_name}} src="/images/icons/pdf-svgrepo-com.svg" />
                                    <div>&nbsp;{{ file?.file_name?.length > 10 ? (file?.file_name | slice:0:10) + '...' : file?.file_name }}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="table-row-column" *ngIf="!showSubdepartments && chemical.assessment_risk>0" (click)="editChemical(chemical)">{{chemical.assessment_risk}}</td>
                    <td class="table-row-column" *ngIf="!showSubdepartments && chemical.assessment_risk==0" (click)="editChemical(chemical)"></td>
                    <td class="table-row-column" *ngIf="!showSubdepartments && chemical.assessment_risk==null" (click)="editChemical(chemical)"></td>
                    <td *ngIf="currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)" (click)="copyChemical(chemical)">
                        <div class="flex flex-col">
                            <img class="w-8 h-8" title="{{'COPY' | translate}}" src="/images/icons/copy-svgrepo-com.svg" area-hidden="true" />
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="4">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"CHEMICALS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>



