<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>
  
<div *ngIf="loadingData==false" class="flex flex-col px-4 max-w-full py-2">
    <div class="flex justify-between">
      <button (click)="startInventory()" *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
        currentUser.is_useradmin || currentUser.is_super_user)" class="button-cdocblue mt-4">
        <span class="text-white text-lg">{{"START_INVENTORY" | translate}}</span>
      </button>
    </div>
</div>

<div *ngIf="(!loadingData && ((!inventoryListDuplicate || inventoryListDuplicate.length == 0)))" class="flex flex-col bg-gray-200 border border-gray-400 px-7 m-4 py-2">
    <div class="text-lg font-bold">
      {{"INVENTORY" | translate}}
    </div>
    <div class="mt-4">
      {{"NO_INVENTORY_MESSAGE_1" | translate}}<br/>
      {{"NO_INVENTORY_MESSAGE_2" | translate}}
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="!loadingData" class="px-4 py-2">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="inventorySearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-1 py-2 px-4">
                                <div class="static inline-block text-left" id="selectStatus" appDropdown>
                                    <button type="button" class="button-gray">
                                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                        3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                        4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                        14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                        4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                    </svg>
                                    <span>{{'STATUS' | translate}}: {{ filterStatus }}</span>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu-static left-0 z-50" #dropdownMenuInside>
                                        <div class="py-1">
                                            <div data-value="1" class="dropdown-menu-item" (click)="toggleStatus(0)" tabindex="1">{{'ALL' | translate}}</div>
                                            <div data-value="2" class="dropdown-menu-item" (click)="toggleStatus(1)" tabindex="2">{{'ONGOING' | translate}}</div>
                                            <div data-value="3" class="dropdown-menu-item" (click)="toggleStatus(2)" tabindex="3">{{'NEW' | translate}}</div>
                                            <div data-value="4" class="dropdown-menu-item" (click)="toggleStatus(3)" tabindex="4">{{'FINISHED' | translate}}</div>
                                            <div data-value="5" class="dropdown-menu-item" (click)="toggleStatus(4)" tabindex="5">{{'FINISHED_AND_APPROVED' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                <th class="table-row-header" (click)="sortInventories('heading',0)">{{"NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortInventories('heading',6)">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortInventories('start_date',1)">{{"START_DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortInventories('end_date',2)">{{"END_DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortInventories('statuText',5)">{{"STATUS" | translate}}</th>
                <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let inventory of inventoryList | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                <tr>
                    <td (click)="selectInventory(inventory)" class="table-row-column" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">{{inventory.name}}</td>
                    <td (click)="selectInventory(inventory)" class="table-row-column" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">{{inventory.issuer_name}}</td>
                    <td (click)="selectInventory(inventory)" class="table-row-column" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">{{inventory.start_date | date:'yyyy-MM-dd'}}</td>
                    <td (click)="selectInventory(inventory)" class="table-row-column" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">{{inventory.end_date | date:'yyyy-MM-dd'}}</td>  
                    <td (click)="selectInventory(inventory)" class="table-row-column" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">{{inventory.statusText}}</td> 
                    <td class="table-row-column" *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                        currentUser.is_useradmin || currentUser.is_super_user)" [ngStyle]="{'background-color': inventory.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                        <div class="flex flex-row">
                            <a (click)="removeInventory(inventory)">
                                <svg class="h-5 w-5 cursor-pointer mt-2 ml-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                    21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                    5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                    3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <a (click)="gotoInventoryModal(inventory)">
                                <svg class="w-7 h-7 mt-1 mr-2 ml-2" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#1C274C"/>
                                    <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#1C274C"/>
                                    <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#1C274C"/>
                                </svg>
                            </a>
                        </div>
                    </td>
                </tr>
            </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"INVENTORY" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
    
<div class="overflow-auto" *ngIf="selectedInventory&&showSubdepartments">
    <div *ngIf="!loadingData" class="px-4 py-2 mb-20">
        <div class="flex flex-row justify-between">
            <h2 class="text-lg font-bold mb-2 mt-2">{{ selectedInventory.name }}</h2>
            <div *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                currentUser.is_useradmin || currentUser.is_super_user)">
                <div *ngIf="selectedInventory.approved==0" class="flex flex-row">
                    <button class="button-green mr-2" title="{{'APPROVE' | translate}}" (click)="approveInventory(selectedInventory)">
                        <span class="text-white whitespace-nowrap">{{"APPROVE" | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
        <table class="table-auto w-full bg-white mb-4 border border-gray-400">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="departmentSearch" class="prompt" (input)="filterByDepartment()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header">{{"DEPARTMENTS" | translate}}</th>
                    <th class="table-row-header">{{"STATUS" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let department of filteredDepartmentArray | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                    <tr (click)="goToInventory(department, selectedInventory)">
                        <td class="table-row-column">
                            {{department.name}} <span class="text-sm italic">({{departFullName(department.id)}})</span>
                        </td>
                        <td class="table-row-column">
                            <div *ngIf="department.productCountInvented==department.productCount+department.productNoSareqCount&&department.productCountApproved==0" class="flex flex-row items-center w-auto text-center">
                                <span class="text-green-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}}</span>
                            </div>
                            <div *ngIf="department.productCountApproved>0" class="flex flex-row items-center w-auto text-center">
                                <span class="text-green-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}} - {{"DONE" | translate}}</span>
                            </div>
                            <div *ngIf="department.productCountInvented!=department.productCount+department.productNoSareqCount&&department.productCountApproved==0" class="flex flex-row items-center w-auto text-center">
                                <span class="text-red-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}}</span>
                            </div>
                            
                            <div *ngIf="department.productCountDone > 0" class="flex flex-row items-center w-auto text-center">
                                <span class="text-green-500"> {{"INVENTORY_MARKED_DONE" | translate}}</span>
                            </div>
                            <div *ngIf="department.productCountDone == 0" class="flex flex-row items-center w-auto text-center">
                                <span class="text-red-500"> {{"INVENTORY_MARKED_NOT_DONE" | translate}}</span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="1">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"DEPARTMENTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPageDep}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPageDep=5;pageNumberDep=1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPageDep=10;pageNumberDep=1" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPageDep=25;pageNumberDep=1" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPageDep=100;pageNumberDep=1" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <pagination-controls (pageBoundsCorrection)="pageNumberDep = 1" (pageChange)="pageNumberDep = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div class="overflow-auto" *ngIf="selectedInventory&&!showSubdepartments">
    <div *ngIf="!loadingData" class="px-4 py-2 mb-20">
        <h2 class="text-lg font-bold mb-4">{{ selectedInventory.name }}</h2>
        <table class="table-auto w-full bg-white border border-gray-400">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header">{{"STATUS" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr (click)="goToInventory(selectedDepartment, selectedInventory)">
                    <td class="table-row-column">
                        <span class="mr-1">{{selectedInventory.departmentArray[0].name}}</span> <span class="text-sm italic">({{departFullName(selectedInventory.departmentArray[0].id)}})</span>
                    </td>
                    <td class="table-row-column">
                        <span class="text-green-500" *ngIf="selectedInventory.departmentArray[0].productCountInvented==selectedInventory.departmentArray[0].productCount+selectedInventory.departmentArray[0].productNoSareqCount">
                            {{selectedInventory.departmentArray[0].productCountInvented}}/{{selectedInventory.departmentArray[0].productCount+selectedInventory.departmentArray[0].productNoSareqCount}}
                        </span>
                        <span class="text-green-500" *ngIf="selectedInventory.departmentArray[0].productCountApproved>0">
                            - {{"DONE" | translate}}
                        </span>
                        <span class="text-red-500" *ngIf="selectedInventory.departmentArray[0].productCountInvented!=selectedInventory.departmentArray[0].productCount+selectedInventory.departmentArray[0].productNoSareqCount">
                            {{selectedInventory.departmentArray[0].productCountInvented}}/{{selectedInventory.departmentArray[0].productCount+selectedInventory.departmentArray[0].productNoSareqCount}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
  
