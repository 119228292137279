<div *ngIf="loadingData" class="flex items-center justify-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div *ngIf="loadingData == false" class="mb-40 md:mb-20">
  <div class="flex flex-col min-h-screen border border-gray-300">
    <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="cancel()">
      <svg class="cursor-pointer h-7 w-7" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
      </svg>
    </button>
    <div *ngIf="!loadingData&&sareq" class="flex flex-col w-full px-4 py-2 mb-2 md:flex-row bg-cdocbanner">
      <div class="w-2/4 mt-2">
        <div class="flex flex-col">
          <div class="flex flex-row" *ngIf="sareq&&sareq.name">
            <svg *ngIf="sareq?.existsinorg" class="w-6 h-6 mr-1 cursor-pointer" width="800px" height="800px" viewBox="0 0 24 24" fill="#21BA45" xmlns="http://www.w3.org/2000/svg" (click)="showOrganisations=!showOrganisations">
              <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="#21BA45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="text-xl font-bold text-white">{{sareq.name}}</span>
          </div>
          <span class="text-white" *ngIf="sareq&&sareq.sareq_number">{{"SAREQ_NR"| translate}}{{" : "}}{{sareq?.sareq_number}}</span>
            <div *ngIf="showOrganisations" class="absolute z-50 p-2 mt-6 bg-white border border-gray-300">
              <ul>
                <li *ngFor="let orgName of sareq.existsinorgNames" class="cursor-pointer hover:bg-gray-200">
                  <span (click)="select(orgName.id)">{{orgName.name}}</span>
                </li>
              </ul>
            </div>
        </div>
      </div>
      <div class="w-1/4 mt-2">
        <span class="mr-1 text-white">{{"LANGUAGE" | translate}}:</span>
        <span class="cursor-pointer" *ngFor="let langs of tempavailableLangs;let i = index">
          <a class="mr-1 underline text-brightblue" (click)="sareqSelectedLang(i)" *ngIf="tempavailableLangs[i]==tempavailableLangs[sareq.lang]">{{langs}} |</a>
          <a class="text-white" (click)="sareqSelectedLang(i)" *ngIf="tempavailableLangs[i]!=tempavailableLangs[sareq.lang]">{{langs}} |</a>
        </span>
      </div>
      <div class="flex justify-end w-1/6 mt-2">
        <div class="flex flex-row">
          <button *ngIf="currentUser.is_super_user" class="button-gray h-8" title="{{'Revidera SDB'}}" (click)="reviseSdb(sareq)">
            <span class="text-black whitespace-nowrap">{{"Revidera SDB"}}</span>
          </button>
          <button *ngIf="currentUser.is_super_user" class="button-gray h-8" title="{{'QR_CODE_MESSAGE' | translate}}" (click)="createQR(sareq)">
            <span class="text-black whitespace-nowrap">{{"QR"}}</span>
          </button>
        </div>
      </div>
      <div class="flex justify-end w-1/4 mt-2">
        <div class="flex flex-row">
          <a *ngIf="currentUser.is_super_user" class="cursor-pointer" area-hidden="true"
            title="{{'HISTORY' | translate}}" (click)="$event.stopPropagation(); openHistoryModal()">
            <img class="w-6 h-10" title="{{'HISTORY' | translate}}" src="/images/icons/history-svgrepo-com.svg" />
          </a>
          <a *ngIf="sareq?.old_sds_file_id&&sareq?.old_sds_file_id!=''&&sareq?.old_sds_file_id!=null && ( sareq.esdb_available==0 || ( sareq.esdb_file_id!='' && sareq.esdb_file_id!=null ))" class="-mr-3 cursor-pointer" area-hidden="true"
            title="{{'SAFETY_DATA_SHEET' | translate}}" (click)="$event.stopPropagation(); openSdsInNewWindow(sareq)">
            <img title="{{'SAFETY_DATA_SHEET' | translate}}" src="/images/icons/SDB_white.svg" id="SDBIcon" />
          </a>
          <a *ngIf="sareq?.old_sds_file_id&&sareq?.old_sds_file_id!=''&&sareq?.old_sds_file_id!=null && ( sareq.esdb_available==1 && ( sareq.esdb_file_id=='' || sareq.esdb_file_id==null ))" class="-mr-3 cursor-pointer" area-hidden="true"
            title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" (click)="$event.stopPropagation(); openSdsInNewWindow(sareq)">
            <img title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" src="/images/icons/EXT_SDB_white.svg" id="EXTSDBICon" />
          </a>
          <a *ngIf="sareq?.old_sds_file_id&&sareq?.old_sds_file_id!=''&&sareq?.old_sds_file_id!=null && ( sareq.esdb_available==1 && ( sareq.esdb_file_id!='' && sareq.esdb_file_id!=null ))" class="-mr-3 cursor-pointer" area-hidden="true"
            title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" (click)="$event.stopPropagation(); openExtSdsInNewWindow(sareq)">
            <img title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" src="/images/icons/EXT_SDB_white.svg" id="EXTSDBICon" />
          </a>
          <a *ngIf=" sareq?.risk_file_id&&sareq?.risk_file_id!=''&&sareq?.risk_file_id!=null&&sareq?.risk_prio||(sareq?.risks&&sareq?.risks?.length>0)" tooltips tooltip-smart="true"
            class="cursor-pointer" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}"
            area-hidden="true" (click)="$event.stopPropagation(); openGrbInNewWindow(sareq)">
            <img title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" src="/images/icons/GRB_white.svg" id="GRBIcon" />
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap mx-4 bg-white">
      <button type="button" class="button button-gray" (click)="scrollToChapter(1)">Avsnitt 1</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(2)">Avsnitt 2</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(3)">Avsnitt 3</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(4)">Avsnitt 4</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(5)">Avsnitt 5</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(6)">Avsnitt 6</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(7)">Avsnitt 7</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(8)">Avsnitt 8</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(9)">Avsnitt 9</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(10)">Avsnitt 11</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(11)">Avsnitt 13</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(12)">Avsnitt 14</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(13)">Övrigt</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(14)">Dokument</button>
      <button type="button" class="button button-gray" (click)="scrollToChapter(15)">Märkning/kommentarer</button>
    </div>

    <div class="flex flex-col h-screen md:flex-row">
      <div *ngIf="!loadingData&&sareq" class="flex flex-col w-full p-4 mb-4 bg-white rounded shadow-lg md:flex-grow md:overflow-y-auto md:m-4 md:p-7 md:w-3/4">
        <div class="flex flex-col w-full mt-2">
          <div #chapter1 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">1. {{"NAME" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">1.1 {{"PRODUCT_DESIGNATION" | translate}}</label>
          </div>
          <div class="mb-4">
              <label class="mb-1 font-bold">{{"NAME" | translate}}:</label><br>
              <input title="{{'NAME' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.name" (ngModelChange)="sareq.name = $event" [ngModelOptions]="{standalone: true}">
              <label *ngIf="(sareq.name==''||sareq.name==null)" class="text-red-500">
                {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
              </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"ALTERNATIVE_NAME" | translate}}:</label><br>
            <input title="{{'ALTERNATIVE_NAME' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.alternative_name" (ngModelChange)="sareq.alternative_name = $event" [ngModelOptions]="{standalone: true}" maxlength="101">
            <label *ngIf="sareq?.alternative_name?.length > 100" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{100}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"UFI" | translate}}:</label><br>
            <input title="{{'UFI' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.ufi" (ngModelChange)="sareq.ufi = $event" [ngModelOptions]="{standalone: true}" maxlength="33">
            <label *ngIf="sareq?.ufi?.length > 32" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{32}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"ARTICLE_NUMBER" | translate}}:</label><br>
            <textarea title="{{'ARTICLE_NUMBER' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.articlenumber" (ngModelChange)="sareq.articlenumber = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="2001"></textarea>
            <label *ngIf="sareq?.articlenumber?.length > 2000" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{2000}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row items-center ">
              <div class="flex items-center mt-2 mr-10 space-x-2">
                <input title="{{'SECRET_CHEMICAL' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.secret_chemical" [checked]="sareq.secret_chemical==1"
                  (change)="sareq.secret_chemical = !sareq.secret_chemical;
                            sareq.secret_chemical!=true?secretCompsname='':secretCompsname;
                            sareq.secret_chemical!=true?sareq.secret_chemical_organisation='':sareq.secret_chemical_organisation;
                            sareq.secret_chemical!=true?sareq.secretchemOrg='':sareq.secretchemOrg">
                <span class="mr-1 text-sm">{{"SECRET_CHEMICAL" | translate}}</span>
              </div>
              <div *ngIf="sareq.secret_chemical==1" class="relative z-20 flex items-center justify-between inline-block w-full text-left" appDropdown>
                <button type="button" class="items-start justify-between mt-2 border border-gray-300 button-white">
                    <span class="text-sm">{{secretCompsname}}</span>
                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let component of secretComps; let $index = index" data-value="component.name" class="dropdown-menu-item" (click)="setChem($index)" tabindex="1">{{ component.name }}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 mb-4">
            <label class="mb-1 font-bold">1.2 {{"COMMON_USE" | translate}}</label>
          </div>
          <div class="mb-4">
              <label class="mb-1 font-bold">{{"COMMON_USE" | translate}}:</label><br>
              <textarea title="{{'COMMON_USE' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.com_use" (ngModelChange)="sareq.com_use = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
              <label *ngIf="sareq?.com_use?.length > 400" class="text-red-500">
                {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
              </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"COMMON_USE_SHORT" | translate}}:</label><br>
            <input title="{{'COMMON_USE_SHORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.com_use_short" (ngModelChange)="sareq.com_use_short = $event" [ngModelOptions]="{standalone: true}" maxlength="51">
            <label *ngIf="sareq?.com_use_short?.length > 50" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{50}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mt-4 mb-2">
            <label class="mb-1 font-bold">1.3 {{"MANUFACTURER" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="relative z-30 flex items-center justify-between inline-block w-full text-left" appDropdown>
              <button type="button" class="items-start justify-between w-full border border-gray-300 button-white">
                  <span>{{sareq.supplier_name}}</span>
                  <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </button>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10 " #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangeManufacturer($event.target.value)" [(ngModel)]="sareqSearch.manufacturer"
                  class="w-full py-2 pl-2 border rounded border-cdocblue focus:outline-none focus:border-blue-500" placeholder="" #dropdownSearch/>
                <div>
                  <div *ngFor="let manufacturer of filteredManufacturers" data-value="manufacturer" class="dropdown-menu-item focus:outline-none focus:ring-2 focus:ring-cdocblue" (click)="setName(manufacturer)" tabindex="1">{{ manufacturer.name }}</div>
                </div>
              </div>
            </div>
            <label *ngIf="!sareq.supplier_name||sareq.supplier_name==null" class="text-red-500">
              {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
            </label>
          </div>
          <div #chapter2 class="p-4 mt-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">2. {{"CLASSIFICATION" | translate}}</label>
          </div>
          <div #chapter22 class="mb-4">
            <label class="mb-1 font-bold">2.2 {{"LABELS_AND_INDICATIONS" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"CLASSIFICATION" | translate}}:</label><br>
            <div class="relative z-20 inline-block w-full text-left" appDropdown>
              <div class="flex items-center justify-center p-2 px-2 py-1 m-1 font-medium bg-white bg-teal-100 border border-gray-300">
                <div class="flex flex-wrap">
                  <div *ngFor="let c of sareq.c_phrases">
                    <div class="flex flex-row p-1 mt-1 mr-1 bg-gray-200 border border-blue-500 rounded">
                      <div class="flex-initial max-w-full text-xs font-normal leading-none">{{c.phrase}}</div>
                      <div class="flex flex-row-reverse flex-auto">
                        <div>
                            <svg (click)="removeCPhrases(c)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-teal-400">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu right-1 max-h-64" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangeCphrases($event.target.value)"
                  class="w-full px-2 py-2 border rounded border-cdocblue" placeholder="" #dropdownSearch/>
                <div class="py-1">
                    <div *ngFor="let c of filteredclphrase" data-value="selectCPhrases" class="dropdown-menu-item multi-items" (click)="setCPhrases(c)" (keydown.enter)="setCPhrases(c)" tabindex="1">{{ c }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"LABELS2" | translate}} CLP:</label><br>
            <div class="flex flex-row">
              <span *ngFor="let pictogram of pictogramCLP" tooltips tooltip-smart="true" title={{pictogram.description}}>
                <img title="{{pictogram.filename}}" class="w-20 h-20 mr-1 cursor-pointer" [class.selected-border]="pictogramInSareq(pictogram.name)" src="/images/pictograms/{{pictogram.filename}}-small.png" (click)="addPictogramToSareq(pictogram.name)" />
              </span>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"PROTECTIVE_GEAR_SYMBOLS" | translate}}:</label><br>
            <div class="flex flex-row">
              <span *ngFor="let protective of protectiveImages" tooltips tooltip-smart="true" title={{protective.description}}>
                <img title="{{protective.filename}}" class="w-20 h-20 mr-1 cursor-pointer" [class.selected-border]="protectiveInSareq(protective.name)" src="/images/protective/{{protective.filename}}-small.png" (click)="addOrRemoveProtective(protective.name)" />
              </span>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"H_PHRASES" | translate}}:</label><br>
            <div class="relative z-30 inline-block w-full text-left" appDropdown>
              <div class="flex items-center justify-center p-2 px-2 py-1 m-1 font-medium bg-white bg-teal-100 border border-gray-300">
                <div class="flex flex-wrap">
                  <div *ngFor="let h of sareq.h_phrases">
                    <div class="flex flex-row p-1 mt-1 mr-1 bg-gray-200 border border-blue-500 rounded">
                      <div class="flex-initial max-w-full text-xs font-normal leading-none">{{h.phrase}}</div>
                      <div class="flex flex-row-reverse flex-auto">
                        <div>
                            <svg (click)="removeHPhrases(h)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-teal-400">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu right-1 max-h-64" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangeHphrases($event.target.value)"
                  class="w-full px-2 py-2 border rounded border-cdocblue" placeholder="" #dropdownSearch/>
                <div class="py-1">
                    <div *ngFor="let h of filteredhphrase | FilterPipe : searchH" class="item" data-value="selectHPhrases" class="dropdown-menu-item multi-items" (click)="setHPhrases(h)" (keydown.enter)="setHPhrases(h)" tabindex="1">{{ h }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"P_PHRASES" | translate}}:</label><br>
            <div class="relative z-20 inline-block w-full text-left" appDropdown>
              <div class="flex items-center justify-center p-2 px-2 py-1 m-1 font-medium bg-white bg-teal-100 border border-gray-300">
                <div class="flex flex-wrap">
                  <div *ngFor="let p of sareq.p_phrases">
                    <div class="flex flex-row p-1 mt-1 mr-1 bg-gray-200 border border-blue-500 rounded">
                      <div class="flex-initial max-w-full text-xs font-normal leading-none">{{p.phrase}}</div>
                      <div class="flex flex-row-reverse flex-auto">
                        <div>
                            <svg (click)="removePPhrases(p)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-teal-400">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu right-1 max-h-64" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangePphrases($event.target.value)"
                  class="w-full px-2 py-2 border rounded border-cdocblue" placeholder="" #dropdownSearch/>
                <div class="py-1">
                    <div *ngFor="let p of filteredpphrase | FilterPipe : searchP" class="item" data-value="selectPPhrases" class="dropdown-menu-item multi-items" (click)="setPPhrases(p)" (keydown.enter)="setPPhrases(p)" tabindex="1">{{ p }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <button class="button-cdocblue" (click)="showKIFS=!showKIFS">visa KIFS</button>
          </div>
          <div *ngIf="showKIFS" class="mb-4">
            <label class="mb-2 font-bold">{{"LABELS2" | translate}} KIFS:</label><br>
            <div class="flex flex-row">
              <span *ngFor="let pictogram of pictogramKIFS" tooltips tooltip-smart="true" title={{pictogram.description}}>
                <img title="{{pictogram.filename}}" class="w-20 h-20 mr-1 cursor-pointer" [class.selected-border]="pictogramInSareq(pictogram.name)" src="/images/pictograms/{{pictogram.filename}}-small.png" (click)="addPictogramToSareq(pictogram.name)" />
              </span>
            </div>
          </div>
          <div *ngIf="showKIFS" class="mb-4">
            <label class="mb-1 font-bold">{{"R_PHRASES" | translate}}:</label><br>
            <div class="relative z-30 inline-block w-full text-left" appDropdown>
              <div class="flex items-center justify-center p-2 px-2 py-1 m-1 font-medium bg-white bg-teal-100 border border-gray-300">
                <div class="flex flex-wrap">
                  <div *ngFor="let r of sareq.r_phrases">
                    <div class="flex flex-row p-1 mt-1 mr-1 bg-gray-200 border border-blue-500 rounded">
                      <div class="flex-initial max-w-full text-xs font-normal leading-none">{{r.phrase}}</div>
                      <div class="flex flex-row-reverse flex-auto">
                        <div>
                            <svg (click)="removeRPhrases(r)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-teal-400">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu right-1 max-h-64" #dropdownMenuInside>
                  <div class="py-1">
                      <div *ngFor="let r of rphrase | FilterPipe : searchR" class="item" data-value="selectRPhrases" class="dropdown-menu-item multi-items" (click)="setRPhrases(r)" (keydown.enter)="setRPhrases(r)" tabindex="1">{{ r }}</div>
                  </div>
              </div>
            </div>
          </div>
          <div *ngIf="showKIFS" class="mb-4">
            <label class="mb-1 font-bold">{{"S_PHRASES" | translate}}:</label><br>
            <div class="relative z-20 inline-block w-full text-left" appDropdown>
              <div class="flex items-center justify-center p-2 px-2 py-1 m-1 font-medium bg-white bg-teal-100 border border-gray-300">
                <div class="flex flex-wrap">
                  <div *ngFor="let s of sareq.s_phrases">
                    <div class="flex flex-row p-1 mt-1 mr-1 bg-gray-200 border border-blue-500 rounded">
                      <div class="flex-initial max-w-full text-xs font-normal leading-none">{{s.phrase}}</div>
                      <div class="flex flex-row-reverse flex-auto">
                        <div>
                            <svg (click)="removeSPhrases(s)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-teal-400">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu right-1 max-h-64" #dropdownMenuInside>
                  <div class="py-1">
                      <div *ngFor="let s of sphrase | FilterPipe : searchS" class="item" data-value="selectSPhrases" class="dropdown-menu-item multi-items" (click)="setSPhrases(s)" (keydown.enter)="setSPhrases(s)" tabindex="1">{{ s }}</div>
                  </div>
              </div>
            </div>
          </div>
          <div #chapter23 class="mb-4">
            <label class="mb-1 font-bold">2.3 {{"OTHER_HAZARDS" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"OTHER_HAZARDS" | translate}}:</label><br>
            <textarea title="{{'OTHER_HAZARDS' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.other_dangers" (ngModelChange)="sareq.other_dangers = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="151"></textarea>
            <label *ngIf="sareq?.other_dangers?.length > 150" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{150}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter3 class="flex justify-between p-4 mb-4 bg-gray-400">
            <label class="mt-2 mb-1 font-bold">3. {{"SUBSTANCES" | translate}}</label>
            <div class="flex space-x-2">
              <button class="button button-cdocblue">
                <a (click)="addNewSubstance()">{{"ADD_NEW_SUBSTANCE" | translate}}</a>
              </button>
              <button class="button button-cdocblue">
                <a (click)="updateSubstanceList()">{{"UPDATE_SUBSTANCE_LIST" | translate}}</a>
              </button>
              <div *ngIf="loadingDataSubstance">
                <div class="loader"></div>
              </div>
            </div>
          </div>
          <div *ngIf="sareq?.substances?.length===0" #chapter32 class="flex justify-between mb-4">
            <label class="mt-2 mb-1 font-bold">3.2 {{"INCLUDED_MIXTURES" | translate}}</label>
            <button class="button button-green" (click)="setAddMixture()">Lägg till blandning</button>
          </div>
          <div *ngIf="showAddMixture" class="mb-4">
            <table class="w-full mb-4 bg-white border border-gray-400 table-auto" id="mixturesTable">
              <thead>
                <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-row items-center">
                        <div class="mt-4">
                            <input st-search="" type="search" [(ngModel)]="mixtureSearch" class="prompt"
                                class="flex items-center w-full px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                        </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th class="table-row-header">{{"NAME" | translate}}</th>
                  <th class="table-row-header">CAS </th>
                  <th class="table-row-header">EG </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let subs of displayedSubstances
                        | filter : mixtureSearch
                        | paginate: { 'itemsPerPage': itemsPerPageMix, 'currentPage': pageNumberMix, id:'editSareqOne'};"
                    (click)="addSubstanceToProduct(subs, 1)">
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.name}}</td>
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.cas}}</td>
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.eg}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="p-6" colspan="1">
                      <div class="relative inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SUBSTANCES" | translate}} / {{"PAGE" | translate}}: {{itemsPerPageMix}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPageMix=5;pageNumberMix=1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPageMix=10;pageNumberMix=1" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPageMix=25;pageNumberMix=1" class="dropdown-menu-item" tabindex="3">25</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="2">
                      <pagination-controls id="editSareqOne" (pageBoundsCorrection)="pageNumberMix = 1" (pageChange)="pageNumberMix = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
                <tr>
              </tfoot>
            </table>
          </div>
          <div *ngIf="sareq?.mixtures?.length > 0" class="mb-4">
            <table class="w-full mb-4 bg-white border border-gray-400 table-auto">
              <thead>
                <tr>
                  <th class="table-row-header">{{"NAME" | translate}}</th>
                  <th class="table-row-header">CAS </th>
                  <th class="table-row-header">EG </th>
                  <th class="table-row-header">{{"H_PHRASES" | translate}}</th>
                  <th class="table-row-header">{{"CONCENTRATION_MIN" | translate}} %</th>
                  <th class="table-row-header">{{"CONCENTRATION_MAX" | translate}} %</th>
                  <th class="px-6 py-3 border-b border-gray-400"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mixture of sareq.mixtures">
                  <td class="table-row-column">{{mixture.name}}</td>
                  <td class="table-row-column">{{mixture.cas}}</td>
                  <td class="table-row-column">{{mixture.eg}}</td>
                  <td class="table-row-column">
                    <span *ngFor="let hphrases of mixture.hphrases">
                      <ul>{{hphrases}}</ul>
                    </span>
                  </td>
                  <td class="table-row-column">
                    <input placeholder="min %" [(ngModel)]="mixture.min" class="w-20 p-1 border border-gray-300"
                      [ngModelOptions]="{standalone: true}" type="number" min="0" max="100.0" step="0.00001" />
                  </td>
                  <td class="table-row-column">
                    <input placeholder="max %" [(ngModel)]="mixture.max" class="w-20 p-1 border border-gray-300"
                      [ngModelOptions]="{standalone: true}" type="number" min="0" max="100.0" step="0.00001" />
                  </td>
                  <td class="p-2" (click)="removeSubstance(mixture, 1)">
                    <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="sareq?.mixtures?.length===0" #chapter31 class="flex justify-between mb-4">
            <label class="mt-2 mb-1 font-bold">3.1 {{"INCLUDED_SUBSTANCE" | translate}}</label>
            <button class="button button-green" (click)="setAddSubstance()">Lägg till ämne</button>
          </div>
          <div *ngIf="showAddSubstance" class="mb-4">
            <table class="w-full mb-4 bg-white border border-gray-400 table-auto" id="substancesTable">
              <thead>
                <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-row items-center">
                        <div class="mt-4">
                            <input st-search="" type="search" [(ngModel)]="substanceSearch" class="prompt"
                                class="flex items-center w-full px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                        </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th class="table-row-header">{{"NAME" | translate}}</th>
                  <th class="table-row-header">CAS </th>
                  <th class="table-row-header">EG </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let subs of displayedSubstances
                        | filter : substanceSearch
                        | paginate: { 'itemsPerPage': itemsPerPageSub, 'currentPage': pageNumberSub, id:'editSareqZero'};"
                    (click)="addSubstanceToProduct(subs, 0)">
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.name}}</td>
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.cas}}</td>
                  <td [ngStyle]="subs.isSelected && {'background-color': 'rgb(217, 232, 202)'}" class="table-row-column">{{subs.eg}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="p-6" colspan="1">
                      <div class="relative inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SUBSTANCES" | translate}} / {{"PAGE" | translate}}: {{itemsPerPageSub}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPageSub=5;pageNumberSub=1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPageSub=10;pageNumberSub=1" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPageSub=25;pageNumberSub=1" class="dropdown-menu-item" tabindex="3">25</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="2">
                      <pagination-controls id="editSareqZero" (pageBoundsCorrection)="pageNumberSub = 1" (pageChange)="pageNumberSub = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
                <tr>
              </tfoot>
            </table>
          </div>
          <div *ngIf="sareq?.substances?.length > 0" class="mb-4">
            <table class="w-full mb-4 bg-white border border-gray-400 table-auto">
              <thead>
                <tr>
                  <th class="table-row-header">{{"NAME" | translate}}</th>
                  <th class="table-row-header">CAS </th>
                  <th class="table-row-header">EG </th>
                  <th class="table-row-header">{{"H_PHRASES" | translate}}</th>
                  <th class="table-row-header">{{"CONCENTRATION_MIN" | translate}} %</th>
                  <th class="table-row-header">{{"CONCENTRATION_MAX" | translate}} %</th>
                  <th class="px-6 py-3 border-b border-gray-400"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let substance of sareq.substances">
                  <td class="table-row-column">{{substance.name}}</td>
                  <td class="table-row-column">{{substance.cas}}</td>
                  <td class="table-row-column">{{substance.eg}}</td>
                  <td class="table-row-column">
                    <span *ngFor="let hphrases of substance.hphrases">
                      <ul>{{hphrases}}</ul>
                    </span>
                  </td>
                  <td class="table-row-column">
                    <input placeholder="min %" [(ngModel)]="substance.min" class="w-20 p-1 border border-gray-300"
                      [ngModelOptions]="{standalone: true}" type="number" min="0" max="100.0" step="0.00001" />
                  </td>
                  <td class="table-row-column">
                    <input placeholder="max %" [(ngModel)]="substance.max" class="w-20 p-1 border border-gray-300"
                      [ngModelOptions]="{standalone: true}" type="number" min="0" max="100.0" step="0.00001" />
                  </td>
                  <td class="p-2" (click)="removeSubstance(substance, 0)">
                    <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div #chapter4 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">4. {{"FIRST_AID" | translate}}</label>
          </div>
          <div #chapter41 class="mb-4">
            <label class="mb-1 font-bold">4.1 {{"FIRST_AID" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">Allmän information:</label><br>
            <textarea title="Allmän information" class="w-full p-2 border border-gray-300" rows="3" [(ngModel)]="sareq.ice_general_information" (ngModelChange)="sareq.ice_general_information = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.ice_general_information?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"AT_INHALATION" | translate}}:</label><br>
            <textarea title="{{'AT_INHALATION' | translate}}" class="w-full p-2 border border-gray-300" rows="3" [(ngModel)]="sareq.ice_inhalation" (ngModelChange)="sareq.ice_inhalation = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.ice_inhalation?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"AT_SKIN_CONTACT" | translate}}:</label><br>
            <textarea title="{{'AT_SKIN_CONTACT' | translate}}" class="w-full p-2 border border-gray-300" rows="3" [(ngModel)]="sareq.ice_skin_contact" (ngModelChange)="sareq.ice_skin_contact = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.ice_skin_contact?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"AT_EYE_CONTACT" | translate}}:</label><br>
            <textarea title="{{'AT_EYE_CONTACT' | translate}}" class="w-full p-2 border border-gray-300" rows="3" [(ngModel)]="sareq.ice_eye_contact" (ngModelChange)="sareq.ice_eye_contact = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.ice_eye_contact?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"AT_CONSUMPTION" | translate}}:</label><br>
            <textarea title="{{'AT_CONSUMPTION' | translate}}" class="w-full p-2 border border-gray-300" rows="3" [(ngModel)]="sareq.ice_consumption" (ngModelChange)="sareq.ice_consumption = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.ice_consumption?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"Symptom"}}:</label><br>
            <textarea title="Symptom" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.ice_symptom" (ngModelChange)="sareq.ice_symptom = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="201"></textarea>
            <label *ngIf="sareq?.ice_symptom?.length > 200" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{200}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter5 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">5. {{"FIRE_MEASURES" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"EXTINGUISHING_AGENT" | translate}}:</label><br>
            <textarea title="{{'EXTINGUISHING_AGENT' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.fire_ext_agent" (ngModelChange)="sareq.fire_ext_agent = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="151"></textarea>
            <label *ngIf="sareq?.fire_ext_agent?.length > 150" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{150}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"UNSUITABLE_EXTINGUISHING_AGENT" | translate}}:</label><br>
            <textarea title="{{'UNSUITABLE_EXTINGUISHING_AGENT' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.fire_uns_ext_agent" (ngModelChange)="sareq.fire_uns_ext_agent = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="101"></textarea>
            <label *ngIf="sareq?.fire_uns_ext_agent?.length > 100" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{100}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"COMBUSTION_PRODUCTS" | translate}}:</label><br>
            <textarea title="{{'COMBUSTION_PRODUCTS' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.combustion_products" (ngModelChange)="sareq.combustion_products = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="101"></textarea>
            <label *ngIf="sareq?.combustion_products?.length > 100" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{100}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"FIRE_MEASURES" | translate}}:</label><br>
            <textarea title="{{'FIRE_MEASURES' | translate}}" class="w-full p-2 border border-gray-300" rows="6" [(ngModel)]="sareq.handling_fire_measures" (ngModelChange)="sareq.handling_fire_measures = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="601"></textarea>
            <label *ngIf="sareq?.handling_fire_measures?.length > 600" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{600}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter6 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">6. {{"UNINTENTIONAL_EMISSIONS" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"UNINTENTIONAL_EMISSIONS" | translate}}:</label><br>
            <textarea title="{{'UNINTENTIONAL_EMISSIONS' | translate}}" class="w-full p-2 border border-gray-300" rows="13" [(ngModel)]="sareq.handling_emissions" (ngModelChange)="sareq.handling_emissions = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="1301"></textarea>
            <label *ngIf="sareq?.handling_emissions?.length > 1300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{1300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter7 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">7. {{"HANDLING" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"HANDLING" | translate}}:</label><br>
            <textarea title="{{'HANDLING' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.handling" (ngModelChange)="sareq.handling = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.handling?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"STORAGE" | translate}}:</label><br>
            <textarea title="{{'STORAGE' | translate}}" class="w-full p-2 border border-gray-300" rows="4" [(ngModel)]="sareq.handling_storage" (ngModelChange)="sareq.handling_storage = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.handling_storage?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter8 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">8. {{"PROTECTION" | translate}}</label>
          </div>
          <div #chapter82 class="mb-4">
            <label class="mb-1 font-bold">8.2 {{"PROTECTIVE_GEAR" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"RESPIRATORY" | translate}}:</label><br>
            <textarea title="{{'RESPIRATORY' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.prot_breath" (ngModelChange)="sareq.prot_breath = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.prot_breath?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"RESPIRATORY_SHORT" | translate}}:</label><br>
            <input title="{{'RESPIRATORY_SHORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.prot_breath_small" (ngModelChange)="sareq.prot_breath_small = $event" [ngModelOptions]="{standalone: true}" maxlength="91">
            <label *ngIf="(sareq.prot_breath_small==''||sareq.prot_breath_small==null)" class="text-red-500">
              {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
            </label>
            <label *ngIf="sareq?.prot_breath_small?.length > 90" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{90}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"HAND_PROTECTION" | translate}}:</label><br>
            <textarea title="{{'RESPIRATORY' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.prot_gloves" (ngModelChange)="sareq.prot_gloves = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.prot_gloves?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"HAND_PROTECTION_SHORT" | translate}}:</label><br>
            <input title="{{'HAND_PROTECTION_SHORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.prot_gloves_small" (ngModelChange)="sareq.prot_gloves_small = $event" [ngModelOptions]="{standalone: true}" maxlength="91">
            <label *ngIf="(sareq.prot_gloves_small==''||sareq.prot_gloves_small==null)" class="text-red-500">
              {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
            </label>
            <label *ngIf="sareq?.prot_gloves_small?.length > 90" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{90}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"EYE_PROTECTION" | translate}}:</label><br>
            <textarea title="{{'EYE_PROTECTION' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.prot_glasses" (ngModelChange)="sareq.prot_glasses = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.prot_glasses?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"EYE_PROTECTION_SHORT" | translate}}:</label><br>
            <input title="{{'EYE_PROTECTION_SHORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.prot_glasses_small" (ngModelChange)="sareq.prot_glasses_small = $event" [ngModelOptions]="{standalone: true}" maxlength="91">
            <label *ngIf="(sareq.prot_glasses_small==''||sareq.prot_glasses_small==null)" class="text-red-500">
              {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
            </label>
            <label *ngIf="sareq?.prot_glasses_small?.length > 90" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{90}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"PROTECTIVE_CLOTHING" | translate}}:</label><br>
            <textarea title="{{'PROTECTIVE_CLOTHING' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.prot_clothes" (ngModelChange)="sareq.prot_clothes = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.prot_clothes?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"PROTECTIVE_CLOTHING_SHORT" | translate}}:</label><br>
            <input title="{{'PROTECTIVE_CLOTHING_SHORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.prot_clothes_small" (ngModelChange)="sareq.prot_clothes_small = $event" [ngModelOptions]="{standalone: true}" maxlength="91">
            <label *ngIf="(sareq.prot_clothes_small==''||sareq.prot_clothes_small==null)" class="text-red-500">
              {{"TEXT_IN_THESE_FIELDS_REQUIRED" | translate}}
            </label>
            <label *ngIf="sareq?.prot_clothes_small?.length > 90" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{90}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"PROTECTIVE_AND_HYGIENE_MEASURES" | translate}}:</label><br>
            <textarea title="{{'PROTECTIVE_AND_HYGIENE_MEASURES' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.prot_hygiene" (ngModelChange)="sareq.prot_hygiene = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="401"></textarea>
            <label *ngIf="sareq?.prot_hygiene?.length > 400" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{400}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter9 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">9. pH-Värde och dosering</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"PH_VALUE" | translate}}:</label><br>
            <input title="{{'PH_VALUE' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.ph_value" (ngModelChange)="sareq.ph_value = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"DOSERING" | translate}}:</label><br>
            <textarea title="{{'DOSERING' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.dosering" (ngModelChange)="sareq.dosering = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="301"></textarea>
            <label *ngIf="sareq?.dosering?.length > 300" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{300}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter11 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">11. {{"TOXILOGICAL_INFORMATION" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"MUTAGENIC" | translate}}:</label><br>
            <textarea title="{{'MUTAGENIC' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.mutagenicity" (ngModelChange)="sareq.mutagenicity = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="300"></textarea>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"CARCINOGENIC" | translate}}:</label><br>
            <textarea title="{{'CARCINOGENIC' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.cancerogenity" (ngModelChange)="sareq.cancerogenity = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="300"></textarea>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"REPRODUCTIVE_TOXICITY" | translate}}:</label><br>
            <textarea title="{{'REPRODUCTIVE_TOXICITY' | translate}}" class="w-full p-2 border border-gray-300" rows="2" [(ngModel)]="sareq.reproduction_toxicity" (ngModelChange)="sareq.reproduction_toxicity = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="300"></textarea>
          </div>
          <div #chapter13 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">13. {{"WASTE_HANDLING" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"WASTE_HANDLING" | translate}}:</label><br>
            <textarea title="{{'WASTE_HANDLING' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.handling_waste" (ngModelChange)="sareq.handling_waste = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="501"></textarea>
            <label *ngIf="sareq?.handling_waste?.length > 500" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{500}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div #chapter14 class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">14. {{"TRANSPORT_INFORMATION" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex items-center mt-4 mb-4 space-x-2">
              <input title="{{'ADR_NOT_CLASSFIED' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.transport[0].adr_not_classfied" (change)="changetransport(sareq.transport[0].adr_not_classfied)">
              <span class="mr-1 text-sm">{{"ADR_NOT_CLASSFIED" | translate}}</span>
            </div>
          </div>
          <div [hidden]="(sareq.transport[0]&&sareq.transport[0].adr_not_classfied==1)">
            <div class="mb-4">
              <label class="mb-2 font-bold">{{"LABELS_AND_INDICATIONS" | translate}} CLP:</label><br>
              <div class="flex flex-wrap mt-2">
                <span *ngFor="let pictogram of pictogramADR" tooltips tooltip-smart="true" title={{pictogram.description}}>
                  <img title="{{pictogram.filename}}" class="w-20 h-20 mr-1 cursor-pointer" [class.selected-border]="adrPictogramInSareq(pictogram.name)" src="/images/transport/{{pictogram.filename}}-small.png" (click)="addADRPictogramToSareq(pictogram.name)" />
                </span>
              </div>
            </div>
            <div class="mb-4">
              <label class="mb-1 text-lg font-bold">{{"TRANSPORT_INFORMATION" | translate}} (ADR/ADN/RID)</label>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.1 {{"UN_NUMBER" | translate}} ( {{"ONLY_NUMBERS" | translate}} ) </label>
              <input title="{{'UN_NUMBER' | translate}}" class="w-full p-2 border border-gray-300" type="number" [(ngModel)]="sareq.transport[0].transport_un_nbr" (ngModelChange)="sareq.transport[0].transport_un_nbr = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.2 {{"PROPER_SHIPPING_NAME" | translate}}</label>
              <input title="{{'PROPER_SHIPPING_NAME' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_name" (ngModelChange)="sareq.transport[0].adr_name = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.3 {{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}</label>
              <input title="{{'HAZARD_CLASS_FOR_TRANSPORT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_class" (ngModelChange)="sareq.transport[0].adr_class = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">{{"CLASSIFICATION_CODE" | translate}}</label>
              <input title="{{'CLASSIFICATION_CODE' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_classification_code" (ngModelChange)="sareq.transport[0].adr_classification_code = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">{{"DANGER_CODE" | translate}}</label>
              <input title="{{'DANGER_CODE' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_danger_code" (ngModelChange)="sareq.transport[0].adr_danger_code = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">{{"TUNNEL_RESTRICTION_CODE" | translate}}</label>
              <input title="{{'TUNNEL_RESTRICTION_CODE' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_tunnel_restr" (ngModelChange)="sareq.transport[0].adr_tunnel_restr = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">{{"LIMITED_AMOUNTS" | translate}}</label>
              <input title="{{'LIMITED_AMOUNTS' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_amount_limit" (ngModelChange)="sareq.transport[0].adr_amount_limit = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">{{"ADR_SPECIAL_PROVISIONS" | translate}}</label>
              <input title="{{'ADR_SPECIAL_PROVISIONS' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.transport[0].adr_special_provision" (ngModelChange)="sareq.transport[0].adr_special_provision = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.4 {{"PACKING_GROUP" | translate}}</label>
              <textarea title="{{'REPRODUCTIVE_TOXICITY' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.adr_packing" (ngModelChange)="sareq.adr_packing = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'ENVIRONMENT_HAZARDS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.transport[0].adr_environment" (click)="toggleAdrEnvironment()">
                <span class="mr-1 text-sm">14.5 {{"ENVIRONMENT_HAZARDS" | translate}}</span>
              </div>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.6 {{"SPECIAL_PRECAUTIONS" | translate}}</label>
              <textarea title="{{'SPECIAL_PRECAUTIONS' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.adr_other_info" (ngModelChange)="sareq.adr_other_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.7 Bulk information</label>
              <textarea title="Bulk information" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.adr_bulk_info" (ngModelChange)="sareq.adr_bulk_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 text-lg font-bold">{{"TRANSPORT_INFORMATION" | translate}} (IATA)</label>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.2 {{"PROPER_SHIPPING_NAME" | translate}}</label>
              <textarea title="{{'PROPER_SHIPPING_NAME' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.iata_name" (ngModelChange)="sareq.iata_name = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.3 {{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}</label>
              <textarea title="{{'HAZARD_CLASS_FOR_TRANSPORT' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.iata_class" (ngModelChange)="sareq.iata_class = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.4 {{"PACKING_GROUP" | translate}}</label>
              <textarea title="{{'PACKING_GROUP' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.iata_packing" (ngModelChange)="sareq.iata_packing = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'ENVIRONMENT_HAZARDS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.transport[0].iata_environment" (click)="toggleAdrIataEnvironment()">
                <span class="mr-1 text-sm">14.5 {{"ENVIRONMENT_HAZARDS" | translate}}</span>
              </div>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.6 {{"SPECIAL_PRECAUTIONS" | translate}}</label>
              <textarea title="{{'SPECIAL_PRECAUTIONS' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.iata_other_info" (ngModelChange)="sareq.iata_other_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.7 Bulk information</label>
              <textarea title="Bulk information" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.iata_bulk_info" (ngModelChange)="sareq.iata_bulk_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 text-lg font-bold">{{"TRANSPORT_INFORMATION" | translate}} (IMDG/IMO)</label>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.2 {{"PROPER_SHIPPING_NAME" | translate}}</label>
              <textarea title="{{'PROPER_SHIPPING_NAME' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.imdg_name" (ngModelChange)="sareq.imdg_name = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.3 {{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}</label>
              <textarea title="{{'HAZARD_CLASS_FOR_TRANSPORT' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.imdg_class" (ngModelChange)="sareq.imdg_class = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">EMS</label>
              <textarea title="EMS" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.imdg_ems" (ngModelChange)="sareq.imdg_ems = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.4 {{"PACKING_GROUP" | translate}}</label>
              <textarea title="{{'PACKING_GROUP' | translate}}" class="w-full p-2 border border-gray-300" rows="1" [(ngModel)]="sareq.imdg_packing" (ngModelChange)="sareq.imdg_packing = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="100"></textarea>
            </div>
            <div class="mb-4">
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'ENVIRONMENT_HAZARDS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.transport[0].imdg_environment" (click)="toggleAdrImdgEnvironment()">
                <span class="mr-1 text-sm">14.5 {{"ENVIRONMENT_HAZARDS" | translate}}</span>
              </div>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.6 {{"SPECIAL_PRECAUTIONS" | translate}}</label>
              <textarea title="{{'SPECIAL_PRECAUTIONS' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.imdg_other_info" (ngModelChange)="sareq.imdg_other_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
              <label class="mb-1 font-bold">14.7 Bulk information</label>
              <textarea title="Bulk information" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.imdg_bulk_info" (ngModelChange)="sareq.imdg_bulk_info = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"FOOTPRINT" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                <input title="{{'WATER' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.footprint_water" (click)="toggleFootprint(0)">
                <span class="text-sm">{{"WATER" | translate}}</span>
              </div>
              <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                <input title="{{'AIR' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.footprint_air" (click)="toggleFootprint(1)">
                <span class="text-sm">{{"AIR" | translate}}</span>
              </div>
              <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                <input title="{{'PRODUCT' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.footprint_product" (click)="toggleFootprint(2)">
                <span class="text-sm">{{"PRODUCT" | translate}}</span>
              </div>
              <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                <input title="{{'WASTE' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.footprint_waste" (click)="toggleFootprint(3)">
                <span class="text-sm">{{"WASTE" | translate}}</span>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"CLASSIFICATION" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row justify-between m-2">
              <div class="flex items-center px-4 mt-4 mb-4 space-x-2">
                <input title="{{'ACID' | translate}}" type="radio" [checked]="sareq.classification == 1" (click)="sareq.classification = 1">
                <span class="text-sm">{{"ACID" | translate}}</span>
              </div>
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'NEUTRAL' | translate}}" type="radio" [checked]="sareq.classification == 2" (click)="sareq.classification = 2">
                <span class="text-sm">{{"NEUTRAL" | translate}}</span>
              </div>
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'ALCALINE' | translate}}" type="radio" [checked]="sareq.classification == 3" (click)="sareq.classification = 3">
                <span class="text-sm">{{"ALCALINE" | translate}}</span>
              </div>
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'SOLVENT' | translate}}" type="radio" [checked]="sareq.classification == 4" (click)="sareq.classification = 4">
                <span class="text-sm">{{"SOLVENT" | translate}}</span>
              </div>
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'MISC' | translate}}" type="radio" [checked]="sareq.classification == 4" (click)="sareq.classification = 5">
                <span class="text-sm">{{"MISC" | translate}}</span>
              </div>
              <div class="flex items-center mt-4 mb-4 space-x-2">
                <input title="{{'UNKNOWN2' | translate}}" type="radio" [checked]="sareq.classification == 4" (click)="sareq.classification = 0">
                <span class="text-sm">{{"UNKNOWN2" | translate}}</span>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"ECOLABELS" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex items-center w-1/3 mt-4 mb-4 space-x-2">
                <input title="{{'ECOLABELS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.eco_eu" (click)="toggleEcoLabels(0)">
                <span class="text-sm">{{"ECOLABELS" | translate}}</span>
              </div>
              <div class="flex items-center w-1/3 mt-4 mb-4 space-x-2">
                <input title="Svanen" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.eco_svanen" (click)="toggleEcoLabels(1)">
                <span class="text-sm">Svanen</span>
              </div>
              <div class="flex items-center w-1/3 mt-4 mb-4 space-x-2">
                <input title="{{'GOOD_ENVIROMENTAL_CHOICE' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.eco_GEC" (click)="toggleEcoLabels(2)">
                <span class="text-sm">{{"GOOD_ENVIROMENTAL_CHOICE" | translate}}</span>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"SPECIAL_REQUIREMENTS" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex flex-col">
                <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                  <input title="{{'INFORMATION_DEMAND' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="show_information" (click)="showCheckboxClicked(0)">
                  <span class="text-sm">{{"INFORMATION_DEMAND" | translate}}</span>
                </div>
                <textarea [hidden]="!show_information" title="{{'INFORMATION_DEMAND' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.information" (ngModelChange)="sareq.information = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                  <input title="{{'TRAINING' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="show_training" (click)="showCheckboxClicked(1)">
                  <span class="text-sm">{{"TRAINING" | translate}}</span>
                </div>
                <textarea [hidden]="!show_training" title="{{'TRAINING' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.training" (ngModelChange)="sareq.training = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                  <input title="{{'HEALTH_CONTROL' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="show_health_control" (click)="showCheckboxClicked(2)">
                  <span class="text-sm">{{"HEALTH_CONTROL" | translate}}</span>
                </div>
                <textarea [hidden]="!show_health_control" title="{{'HEALTH_CONTROL' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.health_control" (ngModelChange)="sareq.health_control = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                  <input title="{{'FITNESS_FOR_DUTY' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="show_fitness_for_duty" (click)="showCheckboxClicked(3)">
                  <span class="text-sm">{{"FITNESS_FOR_DUTY" | translate}}</span>
                </div>
                <textarea [hidden]="!show_fitness_for_duty" title="{{'FITNESS_FOR_DUTY' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.fitness_for_duty" (ngModelChange)="sareq.fitness_for_duty = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center w-1/4 mt-4 mb-4 space-x-2">
                  <input title="{{'NO_SPECIAL_DEMANDS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="show_no_special_demands" (click)="showCheckboxClicked(4)">
                  <span class="text-sm whitespace-nowrap">{{"NO_SPECIAL_DEMANDS" | translate}}</span>
                </div>
                <textarea [hidden]="!show_no_special_demands" title="{{'NO_SPECIAL_DEMANDS' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.no_special_demands" (ngModelChange)="sareq.no_special_demands = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
            </div>
          </div>
          <div #chapterMisc class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"HYGIENE_LIMITS" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex flex-col w-1/2">
                <div class="flex items-center mt-4 mb-4 space-x-2">
                  <input title="{{'HYGIENE_LIMITS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.hygiene_limits" (click)="showCheckboxClicked(5)">
                  <span class="text-sm">{{"HYGIENE_LIMITS" | translate}}</span>
                </div>
                <textarea [hidden]="!sareq.hygiene_limits==true" title="{{'HYGIENE_LIMITS' | translate}}" class="w-full p-2 border border-gray-300" rows="5" [(ngModel)]="sareq.hygiene_limits_comment" (ngModelChange)="sareq.hygiene_limits_comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
              </div>
              <div class="flex items-center w-1/2 mt-4 mb-4 space-x-2">
                <input title="{{'NO_HYGIENE_LIMITS' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.no_hygiene_limits==true" (click)="showCheckboxClicked(6)">
                <span class="text-sm">{{"NO_HYGIENE_LIMITS" | translate}}</span>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"ALLERGIC" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex items-center w-1/2 mt-4 mb-4 space-x-2">
                <input title="{{'ALLERGIC' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.allergic" (change)="allergiccheckboxclicked(true,'list')" name="allergic_checkboxList">
                <span class="text-sm">{{"ALLERGIC" | translate}}</span>
              </div>
              <div class="flex items-center w-1/2 mt-4 mb-4 space-x-2">
                <input title="{{'NON_ALLERGIC' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.allergic==0" (change)="allergiccheckboxclicked(false,'list')" name="non_allergic_checkboxList">
                <span class="text-sm">{{"NON_ALLERGIC" | translate}}</span>
              </div>
            </div>
          </div>
          <div #chapterDoc class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"DOCUMENT" | translate}}</label>
          </div>
          <div #chapterDocSDB class="mt-2 mb-4">
            <label class="mb-1 text-lg font-bold">{{"REPORT_SDS" | translate}}</label>
          </div>
          <div class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="mt-2 mr-1 py-2 border border-gray-300 button-white" type="file" (change)="fileChange($event,'SDB')" placeholder="Upload file" id="upload-file" accept=".pdf" />
            <div *ngIf="securityfile" class="flex flex-row items-center mt-4">
              <img class="w-6 h-6 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{'REPORT_SDS' | translate}}"/>
              {{securityfile.name != '' ? securityfile.name: 'Säkerhetsdatablad'}}
              <a class="ml-2" (click)="securityfile = null">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"DOCUMENT_HISTORY" | translate}}</label>
            <table class="w-full mt-2 mb-4 bg-white border border-gray-400 table-auto" id="sdsdates" *ngIf="docHistory">
              <thead>
                <tr>
                  <th class="table-row-header">ID</th>
                  <th class="table-row-header">{{"DATE" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of docHistory.sds" (click)="getSdsAsPdf(doc.sds_file_id)">
                  <td class="table-row-column">{{doc.id}}</td>
                  <td class="table-row-column">{{doc.timestamp | date:"dd/MM/yyyy"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-4">
            <div class="flex items-center space-x-2">
              <input title="Utökat säkerhetsdatablad" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [ngModel]="sareq.esdb_available" (change)="sareq.esdb_available=sareq.esdb_available!=1?1:0">
              <span class="mr-1 text-sm">Utökat säkerhetsdatablad</span>
            </div>
          </div>
          <div [hidden]="sareq.esdb_available!=1" class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="mt-2 mr-1 py-2 border border-gray-300 button-white" type="file" (change)="fileChange($event,'SDB_EXT')" placeholder="Upload file" accept=".pdf" />
            <div *ngIf="securityextendedfile" class="flex flex-row items-center mt-4">
              <img class="w-6 h-6 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{'REPORT_SDS' | translate}}"/>
              {{securityextendedfile.name!=''?securityextendedfile.name:'Säkerhetsdatablad'}}
              <a class="ml-2" (click)="securityextendedfile = null">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
            </div>
          </div>
          <div [hidden]="sareq.esdb_available!=1" class="mb-4">
            <label class="mb-2 font-bold">{{"DOCUMENT_HISTORY" | translate}}</label>
            <table class="w-full mt-2 mb-4 bg-white border border-gray-400 table-auto" id="sdsdates" *ngIf="docHistory">
              <thead>
                <tr>
                  <th class="table-row-header">ID</th>
                  <th class="table-row-header">{{"DATE" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of docHistory.esdb" (click)="getSdsAsPdf(doc.esdb_file_id)">
                  <td class="table-row-column">{{doc.id}}</td>
                  <td class="table-row-column">{{doc.timestamp | date:"dd/MM/yyyy"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div #chapterDocGRB class="mt-2 mb-4">
            <label class="mb-1 text-lg font-bold">{{"GENERAL_RISK_ASSESSMENT" | translate}}</label>
          </div>
          <div class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="mt-2 mr-1 py-2 border border-gray-300 button-white" type="file" (change)="fileChange($event,'GRB')" placeholder="Upload file" id="upload-file" accept=".pdf" />
            <div *ngIf="riskfile" class="flex flex-row items-center mt-4">
              <img class="w-6 h-6 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}"/>
              {{riskfile.name!=''?riskfile.name:'{{"GENERAL_RISK_ASSESSMENT" | translate}}'}}
              <a class="ml-2" (click)="riskfile = null">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"RISK_LEVEL" | translate}}:</label>
            <div class="flex flex-row">
              <div class="relative z-20 flex items-center justify-between inline-block w-full text-left" appDropdown>
                <button type="button" class="items-start justify-between w-full mr-2 border border-gray-300 py-3 button-white">
                  <span class="text-sm">{{sareq?.risk_prio}}</span>
                  <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let i of [1, 2, 3, 4, 5]" data-value="i" class="dropdown-menu-item" (click)="setRiskLevel(i)" tabindex="1">{{i}}</div>
                    </div>
                </div>
              </div>
              <span class="w-1/2" *ngIf="sareq.lang != 1 && sareq.lang != 2">
                <button type = "button" class = "button button-green" (click) = "createGRB( 'swedish' )">
                  {{ "Skapa svenskt GRB" }}
                </button>
              </span>
              <span class="w-1/2" *ngIf="sareq.lang == 1">
                <button type = "button" class = "button button-cdocblue" (click) = "createGRB('english')">
                  {{ "Skapa engelskt GRB" }}
                </button>
              </span>
              <span class="w-1/2" *ngIf="sareq.lang == 2">
                <button type = "button" class = "button button-cdocblue" (click) = "createGRB('finnish')">
                  {{ "CREATE_GRB_FIN" | translate}}
                </button>
              </span>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"DOCUMENT_HISTORY" | translate}}</label>
            <table class="w-full mt-2 mb-4 bg-white border border-gray-400 table-auto" id="sdsdates" *ngIf="docHistory">
              <thead>
                <tr>
                  <th class="table-row-header">ID</th>
                  <th class="table-row-header">{{"DATE" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of docHistory.grb" (click)="getGrbAsPdf(doc.file_id)">
                  <td class="table-row-column">{{doc.file_id}}</td>
                  <td class="table-row-column">{{doc.last_changed | date:"dd/MM/yyyy"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div #chapterDocProductSheet class="mt-2 mb-4">
            <label class="mb-1 text-lg font-bold">{{"PRODUCT_SHEET" | translate}}</label>
          </div>
          <div class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="mt-2 mr-1 py-2 border border-gray-300 button-white" type="file" (change)="fileChange($event,'PDS')" placeholder="Upload file" id="upload-file" accept=".pdf" />
            <div *ngIf="productfile" class="flex flex-row items-center mt-4">
              <img class="w-6 h-6 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{'PRODUCT_SHEET' | translate}}"/>
              {{productfile.name!=''?productfile.name:'Produktblad'}}
              <a class="ml-2" (click)="productfile = null">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"PRODUCT_SHEET" | translate}}</label>
            <table class="w-full mt-2 mb-4 bg-white border border-gray-400 table-auto" id="productdatasheetsdates">
              <thead>
                <tr>
                  <th class="table-row-header">ID</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="sareq.productsheet_file_id" (click)="getProductSheetAsPdf()">
                  <td class="table-row-column">{{sareq.productsheet_file_id}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div #chapterDocPicture class="mt-2 mb-4">
            <label class="mb-1 text-lg font-bold">{{"PICTURE" | translate}}</label>
          </div>
          <div class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="mt-2 mr-1 py-2 border border-gray-300 button-white" type="file" (change)="fileChange($event,'PICTURE')" placeholder="Upload file" id="upload-file" accept=".png, .jpg, .jpeg" />
            <div *ngIf="picturefile" class="flex flex-row items-center mt-4">
              <img class="w-6 h-6 mr-1" src="/images/icons/image-combiner-svgrepo-com.svg" area-hidden="true" title="{{'PRODUCT_SHEET' | translate}}"/>
              {{picturefile.name!=''?picturefile.name:'Bilder'}}
              <a class="ml-2" (click)="picturefile = null">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
            </div>
          </div>
          <div class="mb-4">
            <label class="mb-2 font-bold">{{"PICTURE" | translate}}</label>
            <table class="w-full mt-2 mb-4 bg-white border border-gray-400 table-auto">
              <thead>
                <tr>
                  <th class="table-row-header">ID</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="sareq.picture_file_id" (click)="getPictureAsPdf()">
                  <td class="table-row-column">{{sareq.picture_file_id}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div #chapterLabels class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"SAREQ_LABEL_HEADING" | translate}}</label>
          </div>
          <div class="mb-4">
            <div class="flex items-center space-x-2">
              <input title="{{'NO_CLP' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.not_clp==1" (change)="sareq.not_clp = !sareq.not_clp;">
              <span class="text-sm">{{"NO_CLP" | translate}}</span>
            </div>
          </div>
          <div *ngIf="currentUser.is_super_user" class="mb-4">
            <div class="flex items-center space-x-2">
              <input title="{{'REPLACE' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="replaceCheckboxChecked==true" (click)="checkboxsetter()">
              <span class="text-sm">{{"REPLACE" | translate}}<span class="text-red-700"> - {{this.replaceByName}}</span></span>
            </div>
          </div>
          <div *ngIf="replaceCheckboxChecked && (loadingSareq==false)" class="mb-4">
            <app-sareq-search (dataOutput)="addProductToReplaceList($event)"></app-sareq-search>
          </div>
          <div class="p-4 mb-4 bg-gray-400">
            <label class="mb-1 font-bold">{{"SAREQ_COMMENT" | translate}}</label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"OBSERVATION" | translate}}:</label>
            <input title="{{'OBSERVATION' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.observation" (ngModelChange)="sareq.observation = $event" [ngModelOptions]="{standalone: true}" maxlength="100">
            <label *ngIf="sareq?.observation?.length>100" class="text-red-500">
              {{"FIELD_ERROR_MAX_LENGTH_1" | translate}}{{' '}}{{100}}{{' '}}{{"FIELD_ERROR_MAX_LENGTH_2" | translate}}
            </label>
          </div>
          <div class="mb-4">
            <label class="mb-1 font-bold">{{"REVCOMMENT" | translate}}:</label>
            <input title="{{'REVCOMMENT' | translate}}" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.rev_comment" (ngModelChange)="sareq.rev_comment = $event" [ngModelOptions]="{standalone: true}" maxlength="100">
          </div>
          <div class="p-4 mb-4 bg-gray-400" [hidden]="!currentUser.is_super_user">
            <label class="mb-1 font-bold">{{"OTHER" | translate}}</label>
          </div>
          <div *ngIf="currentUser.is_super_user" class="mb-4">
            <div class="flex items-center mb-4 space-x-2">
              <input title="{{'HIDDEN' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.hidden==true" (change)="sareq.hidden = !sareq.hidden;">
              <span class="text-sm">{{"HIDDEN" | translate}}</span>
            </div>
            <div [hidden]="!currentUser.is_super_user" class="flex flex-col items-start space-x-2">
              <div class="flex items-center space-x-2">
                <input title="{{'HIGH_PRIO' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.high_prio==true" (change)="sareq.high_prio = !sareq.high_prio;" name="high_prio">
                <span class="text-sm">{{"HIGH_PRIO" | translate}}</span>
              </div>
              <span class="mt-2 text-sm text-blue-700">{{"HIGH_PRIO_CHOICE_MESSAGE" | translate}}</span>
            </div>
            <div class="mb-4" [hidden]="!currentUser.is_super_user">
              <div class="flex flex-col w-full">
                <div class="flex items-center mt-4 mb-4 space-x-2">
                  <input title="{{'EXCLUDED' | translate}}" type="checkbox" class="w-5 h-5 ml-4 form-checkbox text-cdocblue" [checked]="sareq.excluded==true" (change)="sareq.excluded = !sareq.excluded;" name="excluded">
                  <span class="text-sm">{{"EXCLUDED" | translate}}</span>
                </div>
                <textarea title="{{'EXCLUDED_COMMENT' | translate}}" placeholder="{{'EXCLUDED_COMMENT' | translate}}" rows="2" class="w-full p-2 border border-gray-300" [(ngModel)]="sareq.excluded_comment" (ngModelChange)="sareq.excluded_comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="255"></textarea>
              </div>
            </div>
            <div class="mt-4 mb-4" [hidden]="!currentUser.is_super_user">
              <label class="mb-1 font-bold">{{"CHANGE_LANGUAGE" | translate}}:</label><br>
              <div class="relative z-20 flex items-center justify-between inline-block w-full text-left" appDropdown>
                <button type="button" class="items-start justify-between w-full border border-gray-300 button-white py-2">
                    <span>{{sareqSetLangs}}</span>
                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let language of sareqNewLangsList; let i = index" data-value="language" class="dropdown-menu-item" (click)="setLanguage(language, i)" tabindex="1">{{language | translate}}</div>
                    </div>
                </div>
              </div>
              <span class="mt-2 text-sm text-blue-700">{{"CHANGE_LANGUAGE_MESSAGE" | translate}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!currentUser.is_maintenance_user" class="flex flex-row justify-center mt-4 mb-20">
          <button class="mr-1 button-red" title="{{'REMOVE' | translate}}" (click)="deletePost(sareq.id)">
            <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="w-5 h-7">
            <span class="text-lg">{{ 'REMOVE' | translate }}</span>
          </button>
          <button class="mr-1 button-green" title="{{'SAVE_AND_MOVETOPRODUCTLIST' | translate}}" (click)="save('')">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
          <button class="button-cdocblue" title="{{'SAVE_AND_CONTINUE' | translate}}" (click)="save('fastsave')">
            <span class="text-lg font-bold">{{ 'SAVE_AND_CONTINUE' | translate }}</span>
          </button>
        </div>
        <div *ngIf="currentUser.is_maintenance_user" class="flex flex-row justify-center mt-4 mb-20">
          <button class="mr-1 button-green" title="{{'SAVE' | translate}}" (click)="saveasmaintuser()">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
        </div>
      </div>

      <div *ngIf="!loadingData&&sareq" class="flex flex-col w-full p-4 mb-20 bg-white rounded shadow-lg md:m-4 md:p-7 md:w-1/4">
        <div class="mb-4">
          <label class="mb-1 font-bold">{{"RESPONSIBLE" | translate}}:</label>
          <input title="{{'RESPONSIBLE' | translate}}" class="w-full p-2 border border-gray-300" type="text" value="{{sareq.assigned}}">
        </div>
        <div class="mb-4">
          <label class="mb-1 font-bold">{{"STATUS" | translate}}:</label><br>
          <div class="relative z-30 flex items-center justify-between inline-block w-full text-left" appDropdown>
            <button type="button" class="items-start justify-between w-full border border-gray-300 button-white py-2">
                <span>{{status_text}}</span>
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10" #dropdownMenuInside>
                <div class="py-1">
                  <div *ngFor="let text of status_text_list" data-value="status_text" class="dropdown-menu-item" (click)="switchStatus(text)" tabindex="1">{{ text }}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="mb-1 font-bold">{{"ADD_LANGUAGE" | translate}}:</label><br>
          <div class="relative z-20 flex items-center justify-between inline-block w-full text-left" appDropdown>
            <button type="button" class="items-start justify-between w-full border border-gray-300 button-white py-2">
                <span>{{sareqNewLangs}}</span>
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div id="dropdownMenu" class="hidden overflow-y-auto dropdown-menu max-h-64 right-1 top-10" #dropdownMenuInside>
                <div class="py-1">
                  <div *ngFor="let language of sareqNewLangsList; let i = index" data-value="language" class="dropdown-menu-item" (click)="switchLanguage(language, i)" tabindex="1">{{language | translate}}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="mb-1 font-bold">{{"REVISION_DATE" | translate}}:</label><br>
          <input title="{{'REVISION_DATE' | translate}}" class="w-full p-2 border border-gray-300" type="date" [ngModel] ="sareq.rework_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?sareq.rework_date = $event:null" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
          <label class="mb-1 font-bold">{{"REPLACEMENT_DATE" | translate}}:</label><br>
          <input title="{{'REPLACEMENT_DATE' | translate}}" class="w-full p-2 border border-gray-300" type="date" [ngModel]="sareq.replaces_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?sareq.replaces_date = $event:null" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
          <label class="mb-1 font-bold">Version:</label><br>
          <input title="Version" class="w-full p-2 border border-gray-300" type="text" [(ngModel)]="sareq.version" (ngModelChange)="sareq.version = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="flex flex-row justify-center mt-4">
          <button *ngIf="!currentUser.is_maintenance_user" class="mr-1 button-red" title="{{'REMOVE' | translate}}" (click)="deletePost(sareq.id)">
            <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="w-5 h-7">
            <span class="text-lg">{{ 'REMOVE' | translate }}</span>
          </button>
          <button *ngIf="currentUser.is_maintenance_user" class="mr-1 button-green" title="{{'SAVE' | translate}}" (click)="saveasmaintuser()">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
          <button *ngIf="!currentUser.is_maintenance_user" class="mr-1 button-green" title="{{'SAVE' | translate}}" (click)="save('')">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
        </div>
        <div *ngIf="!currentUser.is_maintenance_user" class="flex flex-col justify-center mt-4 mb-20">
          <button class="button-cdocblue" title="{{'SAVE_AND_CONTINUE' | translate}}" (click)="save('fastsave')">
            <span class="font-bold">{{ 'SAVE_AND_CONTINUE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

