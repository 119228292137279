<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col px-4 max-w-full py-2">
  <div class="flex justify-between">
    <button (click)="newProductApplication()" class="button-cdocblue mt-4">
      <span class="text-white text-lg">{{"START_PRODUCT_APPLICATION" | translate}}</span>
    </button>
    <button (click)="historyProductApplication()" class="button-white mt-4">
        <span class="hidden md:block nav-home-text text-lg">{{"HISTORY" | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="(!loadingData && (!productApplications || productApplications.length == 0))" class="flex flex-col bg-gray-200 border border-gray-400 px-7 m-4 py-2">
  <div class="text-lg font-bold">
    {{"NO_PRODUCTAPPLICATION_HEADER" | translate}}
  </div>
  <div class="mt-4">
    {{"NO_PRODUCTAPPLICATION_MESSAGE_1" | translate}}<br/>
    {{"NO_PRODUCTAPPLICATION_MESSAGE_2" | translate}}
  </div>
</div>

<div class="overflow-auto">
  <div *ngIf="( !loadingData && productApplications ) " class="px-4 py-2 mb-20">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400">
          <thead class="bg-white">
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                      <div class="flex flex-col md:flex-row md:items-center">
                          <div class="mt-4">
                              <input st-search="" type="search" [(ngModel)]="productapplicationSearch.all" class="prompt" (input)="filterSearchItems()"
                                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                          </div>
                          <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                            <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                            <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}:</span>
                          </div>
                      </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortProductsApplications('name',0)">{{"PRODUCT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('dep_name',1)">{{"DEPARTMENT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('created_date',2)">{{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('updated_date',3)">{{"LAST_UPDATED" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('supplier_name',5)">{{"SUPPLIER" | translate}}</th>
                <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('username',7)">{{"ORIGINATOR" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('status',8)">{{"STATUS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('approval_date',4)">{{"APPROVAL_DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('departments',9)">{{"DEPARTMENTS_TO_EXIST_ON" | translate}}</th>
              </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let productApplication of getFilteredProductApplications() | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
              <tr>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.name}}</td>
                <td *ngIf="showSubdepartments && productApplication.departmentsAll && productApplication.departmentsAll.length > 0&&(!currentUser.is_guest)" class="table-row-column-department">
                  <div class="relative inline-block text-left p-1 w-auto border border-gray-300" appDropdown>
                      <button type="button" (click)="toggleDropdown($event, productApplication)" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-xs flex flex-col">
                              {{"DEPARTMENTS" | translate}}&nbsp;({{productApplication.departmentsAll?.length}})
                              <span *ngIf="productApplication.departmentsAll?.length > 1" class="text-[8px] italic">
                                  {{
                                      getTruncatedDepartments(
                                        productApplication.departmentsAll[0]?.department,
                                        productApplication.departmentsAll[1]?.department
                                      )
                                  }}
                              </span>
                              <span *ngIf="productApplication.departmentsAll?.length == 1" class="text-[8px] italic">
                                  {{ getTruncatedDepartment(productApplication.departmentsAll[0].department) }}
                              </span>
                          </span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </button>
                      <div [class.hidden]="!productApplication.dropdownOpen" id="dropdownMenu" class="hidden dropdown-menu w-auto overflow-y-auto max-h-[200px] z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
                          <div class="py-1">
                              <div *ngFor="let department of productApplication.departmentsAll" data-value="unit.id" class="dropdown-menu-item truncate"  (click)="gotoProductApplicationItemFromDepartment(productApplication,department)" tabindex="1">
                                  <div class="flex flex-row w-auto items-center w-auto text-center">
                                      <span class="text-sm font-bold mr-1">{{department.department}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>&nbsp;
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" *ngIf="!showSubdepartments" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{selectedDepartment.name}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.created_date | date:'yyyy-MM-dd'}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.updated_date | date:'yyyy-MM-dd'}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.supplier_name}}</td>
                <td class="table-row-column" *ngIf="productApplication.sareq_id!=null" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">
                  <div class="flex flex-row">
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==0 || ( productApplication.esdb_file_id!='' && productApplication.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(productApplication)" />
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==1 && ( productApplication.esdb_file_id=='' || productApplication.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(productApplication)" />
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==1 && ( productApplication.esdb_file_id!='' && productApplication.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(productApplication)" />
                    <img *ngIf="productApplication.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(productApplication)" />
                  </div>
                </td>
                <td class="table-row-column" *ngIf="productApplication.sareq_id==null&&(productApplication.fileid==null||productApplication.fileid=='')" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">-</td>
                <td class="table-row-column" *ngIf="productApplication.sareq_id==null&&!(productApplication.fileid==null||productApplication.fileid=='')"
                    [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">
                    <img class="-mr-3"
                          tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}"
                          id="SDBIcon" src="/images/icons/SDB.svg"
                          area-hidden="true" (click)="openFileInNewWindow(productApplication)" />
                </td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }"
                    tooltips tooltip-smart="true"
                    title="{{productApplication.first_name+
                    ' '+productApplication.last_name+'\n'+
                    productApplication.email+'\n'+
                    productApplication.phone}}">
                    {{productApplication.username}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{getStatus(productApplication.status)}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.approval_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column-department">
                  <div class="relative inline-block text-left p-1 w-auto border border-gray-300" appDropdown>
                    <button type="button"
                            (click)="toggleDropdownDisplayedDeps($event, productApplication)"
                            class="flex justify-between items-center text-black-900 space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span class="nav-home-text text-xs flex flex-col" title="{{'DEPARTMENTS_TO_EXIST_ON_MESSAGE' | translate}}">
                        {{"DEPARTMENTS" | translate}}&nbsp;({{departmentsDisplayedAsArray(productApplication.departmentsNames)?.length}})
                        <span *ngIf="departmentsDisplayedAsArray(productApplication.departmentsNames)?.length > 1"
                          class="text-[8px] italic"
                          title="{{departmentsDisplayedAsArray(productApplication.departmentsNames).join(', ')}}">
                          {{
                            getTruncatedDepartments(
                              departmentsDisplayedAsArray(productApplication.departmentsNames)[0],
                              departmentsDisplayedAsArray(productApplication.departmentsNames)[1]
                            )
                          }}
                        </span>
                        <span *ngIf="departmentsDisplayedAsArray(productApplication.departmentsNames)?.length == 1"
                              class="text-[8px] italic">
                              {{ getTruncatedDepartment(departmentsDisplayedAsArray(productApplication.departmentsNames)[0]) }}
                        </span>
                      </span>
                      <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>

                    <div [class.hidden]="!productApplication.dropdownOpenDisplayedDeps"
                          id="dropdownMenu"
                          class="hidden dropdown-menu w-auto overflow-y-auto max-h-[200px] z-20"
                          style="-webkit-overflow-scrolling: touch;"
                          #dropdownMenuInside>
                      <div class="py-1">
                        <div *ngFor="let department of departmentsDisplayedAsArray(productApplication.departmentsNames)"
                              class="dropdown-menu-item-display"
                              tabindex="1">
                          <div class="flex flex-row items-center text-center">
                            <span class="text-sm font-bold mr-1">{{department}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="2">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
  </div>
</div>

