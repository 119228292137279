<table class="table-auto w-full bg-white border border-gray-400 mt-4" st-table="products" st-safe-src="products" id="productaddtable">
    <thead class="bg-white">
        <tr>
            <th class="table-row-header-no-border no-sort" colspan="12">
                <span class="font-lg font-bold italic mt-4 px-2 py-1">{{'PRODUCT_SEARCH_TEXT_1' | translate}}</span>
            </th>
        </tr>
        <tr>
            <th class="table-row-header-no-border no-sort" colspan="12">
                <div class="flex flex-col md:flex-row">
                    <input st-search="" type="search" [(ngModel)]="productSearch.name" id="productAddSearchname" (input)="filterSearchItems()" (keydown.enter)="searchProducts('','')"
                        class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_PRODUCT_NAME' | translate}} ..." />

                    <input st-search="" type="search" [(ngModel)]="productSearch.com_use" id="productAddSearchComUse" (input)="filterSearchItems()" (keydown.enter)="searchProducts('','')"
                        class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_COM_USE' | translate}} ..." />

                    <input st-search="" type="search" [(ngModel)]="productSearch.supplier_name" id="productAddSearchsuppliername" (input)="filterSearchItems()" (keydown.enter)="searchProducts('','')"
                        class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />

                    <input st-search="" type="search" [(ngModel)]="productSearch.supplier_article_number" id="productAddSearchsupplierarticlenumber" (input)="changeArticleSearch()" (keydown.enter)="searchProducts('','')"
                        class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400" placeholder="{{'SEARCH_ARTICLE' | translate}} ..." />
                </div>
            </th>
        </tr>
        <tr>
            <th class="table-row-header-no-border no-sort" colspan="12">
                <div class="flex flex-row items-center mr-4">
                    <button id="sareqSearch110button" class="button-green mr-1" title="{{'SEARCH_PRODUCT' | translate}}" (click)="searchProducts('','')">
                      <span class="text-lg">{{ 'SEARCH_PRODUCT' | translate }}</span>
                    </button>
                </div>
            </th>
        </tr>
        <tr>
            <th class="table-row-header-no-border no-sort" colspan="6">
                <div class="flex flex-row justify-between mb-2">
                    <div class="relative inline-block text-left" appDropdown>
                        <button type="button" class="button-gray">
                            <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu right-2" #dropdownMenuInside>
                            <div class="py-1">
                                <div *ngFor="let unit of [10,100,1000]" class="dropdown-menu-item" (click)="setItems(unit);searchProducts('','')" data-value="unit" tabindex="1">{{ unit }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex item-center ml-10">
                        <button id="button-showall" class="button-cdocblue" title="{{ 'SHOW_ALL' | translate }} {{ 'PRODUCTS' | translate }}" (click)="getSareqProducts()">
                            <span class="text-white text-lg">{{ 'SHOW_ALL' | translate }} {{ 'PRODUCTS' | translate }}</span>
                        </button>
                    </div>
                </div>
            </th>
        </tr>
        <tr>
            <th class="table-row-header-no-border no-sort" colspan="12">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
                    <div class="loader"></div>
                </div>
                <div class="flex flex-row">
                    <div *ngIf="sareqProducts&&sareqProducts!=null&&sareqProducts?.length!=0&&searchAll==false">
                        <span *ngIf="searchPageNumber0!=1&&numberOfItems1!=0">
                            <button class="p-2 border border-gray-300 mr-2 bg-cdoccontrablue" (click)="searchProducts('Prev','');">{{"PREVIOUS" | translate}}{{' '+numberOfItems1+' '}}</button>
                        </span>
                        {{"SHOWING_PRODUCT" | translate}}{{" "}}{{numberOfItems1*(searchPageNumber0-1)+1}}{{" "}}{{"TO" | translate}}{{" "}}{{getAmount('1')}}{{" "}}{{"OF" | translate}}{{" "}}{{totalAmount+' '}}
                        <span>
                            <button class="p-2 border border-gray-300 ml-2 bg-cdoccontrablue" *ngIf="numberOfItems1!=0&&((searchPageNumber0)*numberOfItems1<totalAmount)" (click)="searchProducts('Next','');">{{"NEXT" | translate}}{{' '+numberOfItems1}}</button>
                        </span>
                    </div>
                </div>
            </th>
        </tr>
        <tr *ngIf="sareqProducts&&sareqProducts!=null&&sareqProducts?.length!=0">
            <th (click)="sortItem('sareq_number',3)" st-sort="sareq_number" class="table-row-header sort-header">{{"SAREQ_NR" | translate}}</th>
            <th (click)="sortItem('name',0)" st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
            <th st-sort="language" class="table-row-header sort-header">{{"LANGUAGE" | translate}}</th>
            <th (click)="sortItem('com_use',6)" st-sort="com_use" class="table-row-header sort-header">{{"AREA_OF_USE" | translate}}</th>
            <th (click)="sortItem('alternative_name',4)" st-sort="alternative_name" class="table-row-header sort-header">{{"ALTERNATIVE_NAME" | translate}}</th>
            <th (click)="sortItem('supplier_name',1)" st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
            <th (click)="sortItem('risk_prio',5)" st-sort="risk" class="table-row-header sort-header">{{"RISK" | translate}}</th>
            <th (click)="sortItem('supplier_article_number',2)" st-sort="supplier_article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
            <th st-sort="sds_file_id" class="table-row-header sort-header">{{"SDS/GRB"}}</th>
            <th *ngIf="currentUser.is_super_user" st-sort="override" class="table-row-header sort-header">{{"OVERRIDE"}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of products
        | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber }; trackBy:trackById" id="addProductRow1">
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected==true ? 'rgb(217, 232, 202)' : 'rgb(255, 255, 255)'}">
                {{product.sareq_number}}
            </td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <span class="flex flex-row">
                    <span class="whitespace-pre-line overflow-hidden">{{product.name}}</span>
                </span>
            </td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
              <img *ngIf="product.lang==1" class="w-6 h-6 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
              <img *ngIf="product.lang==2" class="w-6 h-6 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">
            </td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
              <span class="whitespace-pre-line overflow-hidden">{{product.com_use}}</span>
          </td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <span class="whitespace-pre-line overflow-hidden">{{product.alternative_name}}</span>
            </td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <span class="whitespace-pre-line overflow-hidden">{{product.supplier_name}}</span></td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <span class="whitespace-pre-line overflow-hidden">{{product.risk_prio}}</span></td>
            <td (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <span class="whitespace-pre-line overflow-hidden">{{product.supplier_article_number}}</span></td>
            <td class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
                <div class="flex flex-row">
                    <img class="-mr-3" *ngIf="( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                    <img class="-mr-3" *ngIf="( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                    <img class="-mr-3" *ngIf="( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                    <img *ngIf="product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
            </td>
            <td *ngIf="currentUser.is_super_user" (click)="addProduct(product)" class="table-row-column" [ngStyle]="{'background-color': product.isSelected ? 'rgb(217, 232, 202)' : 'white'}">
              <ng-container *ngIf="getOverrideValues(product.id) as overrideValues">
                <ng-container *ngFor="let override of overrideValues">
                  <div class="flex items-center">
                    <b class="mr-2">Äger</b>
                    <img *ngIf="override.lang==0" class="w-6 h-6 mr-2" src="/images/icons/flag-se-svgrepo-com.svg" alt="Flag">
                    <img *ngIf="override.lang==1" class="w-6 h-6 mr-2" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                    <img *ngIf="override.lang==2" class="w-6 h-6 mr-2" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">
                    <span class="whitespace-pre-line overflow-hidden">{{override.name}}</span>
                  </div>
                </ng-container>
              </ng-container>
            </td>
        </tr>
    </tbody>
</table>
