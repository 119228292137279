import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { AuthService } from '../auth/auth.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.css']
})
export class AssessmentsComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  assessment: any;
  assessments: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  assessmentfiles: any = [];
  arbDBFilter: any;
  showSubdepartments: boolean = true;
  pen:boolean=false;
  loadingStates: { [key: number]: boolean } = {};
  arbDBFilterName: string = "";

  assessmentSearch = {
    all:""
  };

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private materialListService: MaterialListService,
    private userService: UserService,
    private assessmentService: AssessmentService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'asc';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'name';
    }

    this.arbDBFilter = JSON.parse(localStorage.getItem("arbDBFilter"));
    if (this.arbDBFilter == 1)
    {
      this.arbDBFilterName = this.translate.instant('ONGOING');
    }
    if (this.arbDBFilter == 2)
    {
      this.arbDBFilterName = this.translate.instant('DONE');
    }
    localStorage.removeItem('arbDBFilter');

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.assessment = {
      id: "",
      heading: "",
      status: 0,
      risk: 0,
      substitution: 0,
      phasing_out: 0,
      risk_minimzation: 0,
      created_date: new Date(),
      comments: "",
      products: []
    };

    this.getAllDepartments();
    this.getActivityAssessments();

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.assessments = null;
      this.ngOnInit();
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  removeFilter() {
    localStorage.removeItem('arbDBFilter');
    this.ngOnInit();
  }

  onToggleShowSubDepartments() {
    this.showSubdepartments = !this.showSubdepartments;
    localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
    this.getActivityAssessments();
  }

  getActivityAssessments() {
    this.assessments=[];
    if (!this.showSubdepartments) {

      this.assessmentService.getAll();
      this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
        next: assessments => {
          if (assessments == null) {}
          else {
            if(!(this.currentUser.is_admin || this.currentUser.is_local_admin || this.currentUser.is_local_assignee  || this.currentUser.is_useradmin || this.currentUser.is_super_user)){
              this.assessments = _.filter(assessments, function(o)
                {
                  return o.status==3;
                }
              );
            }else{
              this.assessments = assessments;
            }

            if (this.sortDirection === 'desc') {
              this.assessments = _.orderBy(this.assessments, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.assessments = _.orderBy(this.assessments, ['type', this.sortValue]);
            }
          }
          this.getItemsPerPage();
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    } else {

      this.assessmentService.AllWithSubDepartments();
      this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
        next: assessments => {
          if ((assessments == null) || (assessments.length == 0)) {}
          else {
            //this.assessments = assessments;
            if(!(this.currentUser.is_admin || this.currentUser.is_local_admin || this.currentUser.is_local_assignee || this.currentUser.is_useradmin || this.currentUser.is_super_user)){
              this.assessments = _.filter(assessments, function(o)
              {
                return o.status==3;
              }
            );
            } else {
              this.assessments = assessments;

              if (this.arbDBFilter != null)
              {
                if (this.arbDBFilter === '1')
                {
                  this.assessments = this.assessments.filter(assessment =>
                    [0, 1, 2].includes(assessment.status)
                  );
                }

                if (this.arbDBFilter === '2')
                {
                  this.assessments = this.assessments.filter(assessment =>
                    [3].includes(assessment.status)
                  );
                }

                if (this.arbDBFilter === '3')
                {
                  // Get the current date and subtract one year
                  const oneYearAgo = new Date();
                  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                  this.assessments = this.assessments.filter(assessment =>
                    new Date(assessment.created_date) < oneYearAgo
                  );
                }
              }
            }

            if (this.sortDirection === 'desc') {
              this.assessments = _.orderBy(this.assessments, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.assessments = _.orderBy(this.assessments, ['type', this.sortValue]);
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  openArbInNewWindow(assessment, i) {

    this.loadingStates[i] = true;
    this.assessmentService.getArbAsTrustedResourceUrl(assessment);
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          this.loadingStates[i] = false;
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${assessment.heading}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
          this.loadingStates[i] = false;
        }
      },
      error: error => {
        this.loadingStates[i] = false;
        console.log("Error: ", error);
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    this.ngOnInit();
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  addAssessment() {
    localStorage.removeItem('assessment_activity');
    this.router.navigate(['/assessmentadd']);
  }

  assessmentSelected(assessment)
  {
    if (localStorage.getItem('previousSelectedDepartment')) {
      localStorage.removeItem('previousSelectedDepartment');
    }
    localStorage.setItem ( 'previousSelectedDepartment', JSON.stringify ( {
      department: this.selectedDepartment,
      page: 'assessment',
      timestamp: new Date().getTime()
    } ) );
    this.pen = true;
    if (this.currentUser && ((this.currentUser.is_local_assignee || this.currentUser.is_local_admin
                || this.currentUser.is_admin || this.currentUser.is_useradmin) || this.currentUser.is_super_user))
    {
      localStorage.removeItem('assessment_activity');
      if (assessment.organisation_id !== this.selectedDepartment.id) {
        this.organisationService.changeDepartmentById(assessment.organisation_id);
        this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
          this.utilService.departmentChangeClicked(this.router.url);
          localStorage.setItem('departmentchanged','set');
          this.router.navigate(['/assessmentedit', assessment.id]);
        })
      } else {
        this.router.navigate(['/assessmentedit', assessment.id]);
      }
    }
  }

  logout() {
    this.authService.logout();
  }
}
