import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SubstanceService } from 'src/app/services/substance.service';
import { AuthService } from 'src/app/auth/auth.service';
import * as XLSX from 'xlsx';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-substances',
  templateUrl: './substances.component.html',
  styleUrls: ['./substances.component.css']
})
export class SubstancesComponent implements OnInit {
  showAllCompanies:boolean;
  showExtendedsubstanceSearchList:boolean;
  filterSearch:any;
  allSubstances:any=[];
  tempallSubstances:any=[];
  itemsPerPage:number=10;
  pageNumber:number=1;
  searchedKeyword: string;
  moreSearchOptions:boolean;
  itemid: string;
  substanceSearch = {
          all: "",
          name: "",
          cas: "",
          eg: "",
          sareq_id: "",
          restrictionList:""
      };
  sortArray={name:'asc',cas:'asc',eg:'asc',sareq_id:'asc'};
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;

  constructor(
    private utilService:UtilService,
    private translate: TranslateService,
    private router:Router,
    private previousrouter: ActivatedRoute,
    private substanceService: SubstanceService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.itemid = this.previousrouter.snapshot.queryParamMap.get("id");
    this.showAllCompanies=false;
    this.moreSearchOptions=false;
    this.loadSubstanceList();
    this.showExtendedsubstanceSearchList=false;
    this.filterSearch = {
        all: "",
    }
    this.subscribesToSIN = this.getSubscription('subscription_sin');
    this.subscribesToSLL = this.getSubscription('subscription_sll');
    this.subscribesToBASTA = this.getSubscription('subscription_basta');
  }

  addSubstance() {
    this.router.navigate(["/editsubstance", 'new']);
  }

  substanceSelected(substance) {
    this.router.navigate(["/editsubstance", substance.id]);
  }

  loadSubstanceList() {
    this.substanceService.getAll();
    this.substanceService.getSubstanceDataListener().pipe(take(1)).subscribe({
      next: substances => {
        if (substances == null) {

        }
        else
        {
          this.allSubstances=substances;
            for ( var productLength = 0; productLength < this.allSubstances.length; productLength++ ) {
              let restrictionListText = "";
              if (this.allSubstances[productLength].list_amv_a > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_AMV_A" )+" ";
              }
              if (this.allSubstances[productLength].list_amv_b > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_AMV_B" )+" ";
              }
              if (this.allSubstances[productLength].list_reach_14 > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_14" )+" ";
              }
              if (this.allSubstances[productLength].list_reach_17 > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_17" )+" ";
              }
              if (this.allSubstances[productLength].list_reach_candidate > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_CANDIDATE" )+" ";
              }
              if (this.subscribesToBASTA && this.allSubstances[productLength].list_BASTA > 0){
                restrictionListText += this.translate.instant("RESTRICTION_LIST_BASTA" )+" ";
              }
              if (this.allSubstances[productLength].list_chem_prio_p > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_CHEM_PRIO_P" )+" ";
              }
              if (this.allSubstances[productLength].list_chem_prio_u > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_CHEM_PRIO_U" )+" ";
              }
              if (this.allSubstances[productLength].list_water_directive > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_WATER_DIRECTIVE" )+" ";
              }
              if (this.subscribesToSIN && this.allSubstances[productLength].list_sin > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_SIN" )+" ";
              }
              if (this.subscribesToSLL && this.allSubstances[productLength].list_sll > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_SLL" )+" ";
              }
              if (this.allSubstances[productLength].list_chem_limit > 0){
                restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_CANDIDATE" )+" ";
              }
              this.allSubstances[productLength]['restrictionListText'] = restrictionListText;
              continue;
          }
          this.tempallSubstances=_.cloneDeep(this.allSubstances);
        }
      },
      error: error => {

      }
    })
  }

  gotoControlPanel(){
    this.router.navigate(['/controlpanel']);
  }

  showMoreSearchOptions(moreSearchOptions:boolean){
    this.moreSearchOptions=!moreSearchOptions;
  }

  sortSubstances(type){
    if(this.sortArray[type]=='asc'){
      var direction='asc';
      this.sortArray[type]='desc';
    }else{
      var direction='desc';
      this.sortArray[type]='asc';
    }
    this.allSubstances=_.orderBy(this.allSubstances,[item => item[type]?.toLowerCase()],[direction]);
  }

  filterSearchItems() {
    this.allSubstances = this.tempallSubstances;

    // Set empty search fields to null
    this.substanceSearch.name = this.substanceSearch.name || null;
    this.substanceSearch.cas = this.substanceSearch.cas || null;
    this.substanceSearch.eg = this.substanceSearch.eg || null;
    this.substanceSearch.sareq_id = this.substanceSearch.sareq_id || null;
    this.substanceSearch.all = this.substanceSearch.all || null;

    if (this.substanceSearch.all!=null) {
      this.allSubstances = this.allSubstances.filter(o => JSON.stringify(o).toLowerCase().includes(this.substanceSearch.all.toLowerCase()));
    }
    if (this.substanceSearch.name!=null) {
      this.allSubstances = this.allSubstances.filter(o => o.name?.toLowerCase().includes(this.substanceSearch.name.toLowerCase()));
    }
    if (this.substanceSearch.eg!=null) {
      this.allSubstances = this.allSubstances.filter(o => o.eg?.toLowerCase().includes(this.substanceSearch.eg.toLowerCase()));
    }
    if (this.substanceSearch.cas!=null) {
      this.allSubstances = this.allSubstances.filter(o => o.cas?.toLowerCase().includes(this.substanceSearch.cas.toLowerCase()));
    }
    if (this.substanceSearch.sareq_id!=null) {
      this.allSubstances = this.allSubstances.filter(o => o.substance_number?.toString().toLowerCase().includes(this.substanceSearch.sareq_id.toLowerCase()));
    }
  }

  filterByType(type:string){
    this.allSubstances=this.tempallSubstances;
    if(this.substanceSearch.restrictionList==''){
      this.substanceSearch.restrictionList=null;
    }

    if (this.substanceSearch.restrictionList !== null && this.substanceSearch.restrictionList !== undefined) {
      this.allSubstances = this.allSubstances.filter(o =>
          o.restrictionListText !== null && o.restrictionListText !== undefined &&
          o.restrictionListText.toString().toLowerCase().indexOf(this.substanceSearch.restrictionList.toString().toLowerCase()) > -1
      );
    }
  }

  logout() {
    this.authService.logout();
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  exportToExcelClicked() {
    var dataToExportToExcel = [];

    for ( var n = 0, max = this.allSubstances.length; n < max; n++) {
        dataToExportToExcel.push( {
          'Nummer': this.allSubstances[n].substance_number,
          'Namn': this.allSubstances[n].name,
          'cas': this.allSubstances[n].cas,
          'Eg': this.allSubstances[n].eg,
          'Restriktionslista': this.allSubstances[n].restrictionListText
        } );
      }

    const worksheet = XLSX.utils.json_to_sheet( dataToExportToExcel );
        worksheet[ '!cols' ] = [
          { wch: 10 },
          { wch: 50 },
          { wch: 50 },
          { wch: 50 },
          { wch: 150 } ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'exported-data.xlsx');
    return;
  }
}
