<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="overflow-auto mb-20">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <div class="flex flex-row mt-2">
            <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
              <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
                <path fill="#000000"
                      d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
              </svg>
            </button>
            <div class="flex flex-row justify-center items-center w-full">
              <span class="text-xl font-bold px-8 py-2 text-black">{{'SUBSTITUTION' | translate}}</span>
            </div>
        </div>
        <div *ngIf="!loadingData&&substitution" class="flex flex-col md:flex-row px-6 w-full py-2 mb-2 bg-cdocbanner">
            <div class="w-full md:w-2/3 mt-2 mb-2">
                <div class="flex flex-col">
                    <p class="text-gray-200"><a><strong>{{substitution?.supplier_name}}</strong></a></p>
                    <span class="text-white text-2xl font-bold mr-4">{{substitution?.sareq_name}}</span>
                    <span class="text-white text-sm font-bold italic">{{getNameStatus(substitution?.status)}}</span>
                    <div class="flex flex-row mt-2">
                        <div *ngIf="product?.pictograms?.length > 0" class="flex flex-row">
                            <span *ngFor="let pictogram of product?.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                <img class="w-8 h-8" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div *ngIf="product?.phrases">
                            <ul *ngIf="product?.phrases" class="list-disc list-inside">
                            <li class="text-white" *ngFor="let phrase of product?.phrases.H">
                                <small class="text-white">{{phrase}}{{" - "}}{{"PHRASE_" + phrase | translate}}{{" "}}</small>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between w-full md:w-1/3 mt-2">
                <div>
                    <div class="flex flex-row">
                        <a *ngIf="subscribesToSds" (click)="openSdsInNewWindow(substitution)"><img class="w-8 h-8 cursor-pointer" title="icon" id="SDBIcon" src="/images/icons/SDB_white.svg" area-hidden="true" /></a>
                        <span class="text-white text-lg">{{"SAFETY_DATA_SHEET" | translate}}</span>
                    </div>
                    <div *ngIf="subscribesToGrb && substitution.risk_id" class="flex flex-row">
                        <a (click)="openGrbInNewWindow(substitution)"><img class="w-8 h-8 cursor-pointer" title="icon" id="GRBIcon" src="/images/icons/GRB_white.svg" area-hidden="true" /></a>
                        <span class="text-white text-lg">{{"GENERAL_RISK_ASSESSMENT" | translate}}</span>
                    </div>
                </div>
                <div>
                    <button *ngIf="substitution" (click)="createPDF()" [disabled]="loadingButton" class="px-4" [class.cursor-not-allowed]="loadingButton">
                        <ng-container *ngIf="!loadingButton; else loading">
                            <img class="w-8 h-8 cursor-pointer" title="{{'PRINT' | translate}} {{'SUBSTITUTION' | translate}}" src="images/icons/print-svgrepo-com.svg" area-hidden="true" />
                        </ng-container>
                        <ng-template #loading>
                            <svg class="w-8 h-8 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>

        <!-- NEW DATA -->
        <div *ngIf="copyThisTubstitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7">
            <div class="overflow-x-auto">
                <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4" id="worksteptable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <span class="text-lg font-bold">{{"PRODUCT_IS_ON_FOLLOWING_DEPARTMENTS" | translate}}:</span>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header"></th>
                            <th class="table-row-header"></th>
                            <th class="table-row-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let substitutionDepsItem of substitutionDeps; let i = index">
                            <td class="table-row-column">{{substitutionDepsItem.name}}</td>
                            <td class="table-row-column"><input title="checkbox" type="checkbox" [checked]="substitutionDepsItem.active==true" (click)="activateordeactivateitem(i)" [disabled]="substitutionDepsItem.organisation_id==substitution.department_id"></td>
                            <td class="table-row-column"><label *ngIf="substitutionDepsItem.organisation_id==substitution.department_id">{{'CURRENT_DEPARTMENT' | translate}}</label></td>
                        </tr>
                        <tr>
                        <td class="table-row-column">{{'ALL_DEPARTMENTS' | translate}}</td>
                        <td class="table-row-column"><input title="checkbox" type="checkbox" (checked)="substitutionDepsItemAll==true" (click)="activateordeactivateitem(-1)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="depActive" class="flex flex-row justify-center mb-4 mt-2">
                <button class="button-cdocblue" title="{{'COPY' | translate}}" (click)="addSubstitution()">
                    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                  <span class="text-white text-lg">{{ 'COPY' | translate }}</span>
                </button>
            </div>
        </div>
        <!-- END NEW DATA -->

        <!-- Form Container -->
        <div class="overflow-auto p-4">
            <div *ngIf="!loadingData&&substitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                  <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"DATE" | translate}}:</label><br>
                        <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="substitution.created_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?substitution.created_date = $event:null" [ngModelOptions]="{standalone: true}" [disabled]="true">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"REGISTRAR" | translate}}:</label><br>
                        <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text" placeholder="{{issuer?.first_name}} {{issuer?.last_name}}" [required]="true" [disabled]="true">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}:</label><br>
                        <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substitution.responsible" (ngModelChange)="substitution.responsible = $event" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"STATUS" | translate}}:</label><br>
                        <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                            <button type="button" class="button-white w-full justify-between border border-gray-300">
                                <span *ngIf="substitution.status == 0" >{{'NEW' | translate}}</span>
                                <span *ngIf="substitution.status == 1">{{'ONGOING' | translate}}</span>
                                <span *ngIf="substitution.status == 2">{{'FOR_REVIEW' | translate}}</span>
                                <span *ngIf="substitution.status == 3">{{'SAREQ_WAITING_FOR_PRODUCT' | translate}}</span>
                                <span *ngIf="substitution.status == 4">{{'APPROVED2' | translate}}</span>
                                <span *ngIf="substitution.status == 5">{{'FINISHED_AND_SUBSTITUTED' | translate}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <div data-value="0" class="dropdown-menu-item" (click)="substitution.status=0" tabindex="1">{{'NEW' | translate}}</div>
                                    <div data-value="0" class="dropdown-menu-item" (click)="substitution.status=1" tabindex="2">{{'ONGOING' | translate}}</div>
                                    <div data-value="0" class="dropdown-menu-item" (click)="substitution.status=2" tabindex="3">{{'FOR_REVIEW' | translate}}</div>
                                    <div data-value="0" class="dropdown-menu-item" (click)="substitution.status=3" tabindex="4">{{'SAREQ_WAITING_FOR_PRODUCT' | translate}}</div>
                                    <div data-value="3" class="dropdown-menu-item" (click)="substitution.status=4" tabindex="5">{{'APPROVED2' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div *ngIf="!loadingData&&substitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7">
                <div class="flex flex-col w-full mt-4">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}:</label>
                        <span *ngIf="substitution.comment?.length > 200" class="text-red-500">{{" (max 200 tecken) "}}</span>
                        <input title="{{'DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substitution.comment" (ngModelChange)="substitution.comment = $event" [ngModelOptions]="{standalone: true}" maxlength="201">
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}:</label><br>
                        <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" accept=".pdf" (change)="incomingfile($event)"/>
                        <div *ngFor="let file of substitution.substitutionfiles; let i = index" >
                            <a class="cursor-pointer" (click)="openDocumentInNewWindow(file.file_id)">
                                <div class="flex flex-row items-center mt-4">
                                    <img class="w-6 h-6 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{file.File_name}}"/>
                                    <span class="text-black">{{file.File_name}}</span>
                                    <svg class="h-4 w-4 ml-4 cursor-pointer"  (click)="fileRemove(i)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-4">{{"ATTACHE_PICTURES" | translate}}:</label><br>
                        <input title="{{'ATTACHE_PICTURES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1 mb-2" type="file" id="upload-file" accept=".png, .jpg, .jpeg" (change)="incomingimage($event)" />
                        <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
                            <div class="flex flex-row">
                                <a>
                                    <img title="{{'ATTACHE_PICTURES' | translate}}" [src]=image style="max-height: 200px; max-width: 600px;"/>
                                </a>
                                <a (click)="pictureRemove(i)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overflow-auto p-4">
            <div *ngIf="!loadingData&&substitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7">
                <div class="flex flex-row justify-between items-center">
                    <div class="flex mt-4">
                        <img *ngIf="substitution?.worksteps&&substitution?.worksteps?.length>0" class="w-7 h-7 mr-2 cursor-pointer" src="/images/icons/edit-svgrepo-com.svg"
                            area-hidden="true" (click)="editWorksteps(substitution)" title="{{'EDIT' | translate}}"/>
                        <p class="text-2xl">{{"CHEMICAL_CANDIDATES" | translate}}</p>
                    </div>
                    <button (click)="addWorkstep()" class="button-cdocblue mt-4">
                        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        <span class="text-white text-lg">{{"ADD" | translate}}</span>
                    </button>
                </div>
                <div class="overflow-x-auto">
                    <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"COMMENT" | translate}}</th>
                                <th class="table-row-header">SDS/GRB</th>
                                <th class="table-row-header">{{"RISK" | translate}}</th>
                                <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                                <th class="table-row-header">{{"APPROVED2" | translate}}</th>
                                <th class="table-row-header"></th>
                                <th class="table-row-header"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let workstep of substitution?.worksteps; let i = index">
                                <td class="table-row-column" (click)="workstepSelected(substitution, i)">{{workstep.product?.name}}</td>
                                <td class="table-row-column" (click)="workstepSelected(substitution, i)">{{workstep.product?.supplier_name}}</td>
                                <td class="table-row-column" (click)="workstepSelected(substitution, i)">{{workstep.comment}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <a class="-mr-3" (click)="openSdsInNewWindow(workstep.product)">
                                            <img *ngIf="subscribesToSds && workstep.product" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" />
                                        </a>
                                        <a (click)="openGrbInNewWindow(workstep.product)">
                                            <img *ngIf="subscribesToGrb && workstep.product?.risk_id" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" />
                                        </a>
                                    </div>
                                </td>
                                <td class="table-row-column" (click)="workstepSelected(substitution, i)">{{workstep.product?.risk_prio}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <span *ngFor="let pictogram of workstep.product?.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                            <img class="w-8 h-8" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==1">
                                    <img class="w-4 h-4" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" title="{{'APPROVED' | translate}}"/>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==0">
                                    <img class="w-4 h-4" src="/images/icons/gui-ban-svgrepo-com.svg" area-hidden="true" title="{{'NOT_APPROVED' | translate}}"/>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==null">
                                    <img class="w-4 h-4" src="/images/icons/question-round-svgrepo-com.svg" area-hidden="true" title="{{'ONGOING' | translate}}"/>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row items-center space-x-2 px-4">
                                        <span class="text-sm  mr-1">{{approvestatus(workstep.approved)}}:</span>
                                        <input title="{{'APPROVE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="workstep.approved==1" (click)="approveWorkstep(workstep)">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="substitution?.worksteps?.length>0" class="flex flex-col bg-cdocbanner border border-gray-300 p-2 text-sm">
                    <p class="text-white font-bold">Info:</p>
                    <p class="text-white">{{'SUBSTITUTION_WORKSTEP_TEXT_1' | translate }}</p>
                </div>
            </div>

            <div *ngIf="!loadingData&&substitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7 mt-4 mb-4">
                <div class="flex flex-col">
                    <label class="font-bold">{{"ESTIMATION" | translate }}</label>
                    <span *ngIf="substitution.estimation?.length > 1024" class="text-red-500">{{" (max 1024 tecken) "}}</span>
                    <textarea title="{{'ESTIMATION' | translate }}" rows="5" class="w-full border border-gray-300 p-2" [ngModel]="substitution.estimation" (ngModelChange)="substitution.estimation=$event" (ngModelChange)="substitution.estimation = $event" [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="1025"></textarea>
                </div>
            </div>

            <div *ngIf="!loadingData&&substitution" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7 mt-4 mb-4">
                <span class="text-xl font-bold">{{"ACTION_PRODUCT" | translate}}</span>
                <div class="flex flex-col md:flex-row">
                    <div class="flex items-center space-x-2 ml-4 mt-4 md:m-4">
                        <input title="{{'KEEP' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_keep==1" [ngModel]="checkbox_keep" (ngModelChange)="checkbox_keep = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxesKeep($event)">
                        <span class="text-sm mr-1">{{"KEEP" | translate}}</span>
                    </div>
                    <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                        <input title="{{'OUTPHASE_ALT1' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_outphase_alt1==1" [ngModel]="checkbox_outphase_alt1" (ngModelChange)="checkbox_outphase_alt1 = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxesAlt1($event)">
                        <span class="text-sm mr-1">{{"OUTPHASE_ALT1" | translate}}</span>
                    </div>
                    <div class="flex items-center space-x-2 ml-4 mt-2md:m-4">
                        <input title="{{'OUTPHASE_ALT2' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_outphase_alt2==1" [ngModel]="checkbox_outphase_alt2" (ngModelChange)="checkbox_outphase_alt2 = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxesAlt2($event)">
                        <span class="text-sm mr-1">{{"OUTPHASE_ALT2" | translate}}</span>
                    </div>
                    <div class="flex items-center space-x-2 ml-4 mt-2 mb-4 md:m-4">
                        <input title="{{'OUTPHASE_ALT3' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_outphase_alt3==1" [ngModel]="checkbox_outphase_alt3" (ngModelChange)="checkbox_outphase_alt3 = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxesAlt3($event)">
                        <span class="text-sm mr-1">{{"OUTPHASE_ALT3" | translate}}</span>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="font-bold">{{"COMMENTS" | translate }}</label>
                    <span *ngIf="substitution.checkbox_comment_1?.length > 200" class="text-red-500">{{" (max 200 tecken) "}}</span>
                    <textarea title="{{'COMMENTS' | translate }}" rows="2" class="w-full border border-gray-300 p-2" [ngModel]="substitution.checkbox_comment_1" (ngModelChange)="substitution.checkbox_comment_1=$event" (ngModelChange)="substitution.checkbox_comment_1 = $event" [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="201"></textarea>
                </div>

                <div class="mt-10" *ngIf="substitution.checkbox_keep!=1">
                    <span class="text-xl font-bold">{{"ACTION_FOR_REMAINGING_PRODUCT" | translate}}</span>
                    <div class="flex flex-row">
                        <div class="flex items-center space-x-2 m-4">
                            <input title="{{'END_CONSUMED' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_endusage==1" [ngModel]="checkbox_endusage" (ngModelChange)="checkbox_endusage = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxUsage($event)">
                            <span class="text-sm mr-1">{{"END_CONSUMED" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 m-4">
                            <input title="{{'DEPONI' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_deponi==1" [ngModel]="checkbox_deponi" (ngModelChange)="checkbox_deponi = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxDeponi($event)">
                            <span class="text-sm mr-1">{{"DEPONI" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 m-4">
                            <input title="{{'OTHER_ACTION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="substitution.checkbox_other==1" [ngModel]="checkbox_other" (ngModelChange)="checkbox_other = $event" [ngModelOptions]="{standalone: true}" (change)="onToggleCheckboxOther($event)">
                            <span class="text-sm mr-1">{{"OTHER_ACTION" | translate}}</span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="font-bold">{{"COMMENTS" | translate }}</label>
                        <span *ngIf="substitution.checkbox_comment_2?.length > 200" class="text-red-500">{{" (max 200 tecken) "}}</span>
                        <textarea title="{{'COMMENTS' | translate }}" rows="2" class="w-full border border-gray-300 p-2" [(ngModel)]="substitution.checkbox_comment_2" (ngModelChange)="substitution.checkbox_comment_2 = $event" [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="201"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-center mt-4 mb-20">
            <button class="button-red mr-2" title="{{'REMOVE' | translate}}" (click)="remove()">
              <img title="{{'REMOVE' | translate}}" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
              <span class="text-lg">{{ 'REMOVE' | translate }}</span>
            </button>
            <button (click)="createPDFAndSubmit()" [disabled]="loadingButton" class="button-green" [class.cursor-not-allowed]="loadingButton==true">
                <ng-container *ngIf="!loadingButton; else loading">
                    <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                    <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
                </ng-container>
                <ng-template #loading>
                    <svg class="w-6 h-6 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
                </ng-template>
                </button>
            <button *ngIf="substitution&&(substitution.status==4||substitution.status==5)" class="button-cdocblue mr-2" title="{{'DOSUBSTITUTION' | translate}}" (click)="doSubstitution()">
                <span class="text-white text-lg">{{ 'DOSUBSTITUTION' | translate }}</span>
            </button>
        </div>
    </div>
</div>

<!-- Hidden PDF content -->
<div #pdfContent class="pdf-content">
    <app-pdf-substitution [substitution]="substitution" [product]="product" [pictures]="pictures"></app-pdf-substitution>
    <div class="flex justify-center items-center mt-4">
        <div class="loader"></div>
    </div>
</div>
