import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription, interval, Observable } from 'rxjs';
import { InventoryService } from 'src/app/services/inventory.service';
import { DropdownDirective } from 'src/app/shared/directives/dropdown.directive';
import { take, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { isEqual } from 'lodash';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {

    searchList: any;
    searchText: any;
    currentUser: any=null;
    isSettings: boolean = false;
    selectedOrganisation;
    selectedDepartment: any;
    toggle: boolean=true;
    listAlldepartments: any;
    allDepartments: any;
    organisation: any;
    selectedDepartmentName: any;
    departmentSearch: any;
    loginDepartment: any;
    loginTreeList: any;
    isLoading: boolean = false;
    inventoryOngoing: boolean = false;
    departmentChangedSubscription: Subscription;
    private pollingSubscription: Subscription;

    constructor(private router:Router, private authService:AuthService, private utilService:UtilService,private translate: TranslateService,
                private organisationService: OrganisationService, private formBuilder:FormBuilder, private inventoryService:InventoryService) {}

    ngOnInit() {
        this.searchList = [];
        this.selectedDepartmentName = "";
        this.isLoading = true;

        var data = localStorage.getItem('currentUser', );
        if (data == null || data == undefined)
        {
            this.logout();
        }
        else
        {
            this.currentUser = JSON.parse(data);
        }

        this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
        this.selectedOrganisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
        if (localStorage.getItem('selectedDepartment', ) == null) {
          this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
          this.selectedDepartment.level = 1;
        }
        else {
          this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
          this.selectedDepartment.level = 2;
          if (this.selectedDepartment == null) {
            this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
            this.selectedDepartment.level = 1;
          }
        }

        if ((this.currentUser != null) && (this.currentUser.is_local_user == 1 || this.currentUser.is_local_admin == 1 || this.currentUser.is_local_assignee == 1)) {

          this.currentUser.LocalUserDepartments = [];

          if (this.currentUser.department.trim() == "" && this.currentUser.is_local_user_department.trim() == "") {
            this.currentUser.LocalUserDepartments.push(this.currentUser.root_organisation_id.trim());
          } else {
            var departmentArrayTemp = this.currentUser.is_local_user_department.split(',');
            for (let value of departmentArrayTemp) {
              if (value != ""){
                this.currentUser.LocalUserDepartments.push(value.trim());
              }
            }
          }
        } else if (this.currentUser != null) {
          this.currentUser.isLocalUser="";
          this.currentUser.LocalUserDepartments=[];
        }

        this.getAllDepartments();

        this.setOrgTree(this.organisation);

        this.allDepartments = [];
        this.allDepartments.push({org: this.organisation, name: '\u2022 ' + this.organisation.name, level: 1});

        if (localStorage.getItem('allDepartments', ) != null ) {
          this.allDepartments = JSON.parse(localStorage.getItem('allDepartments', ));
          this.isLoading = false;
        } else {
          this.iterateOrganisation(this.organisation.orgs, 1);
          localStorage.setItem('allDepartments', JSON.stringify(this.allDepartments));
          this.isLoading = false;
        }

        this.getInventoryStatus();

        // Set Login Status
        this.departmentChangedSubscription = this.organisationService.departmentChanged.subscribe((msg: any) => {
          this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
          this.selectedOrganisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
          if (localStorage.getItem('selectedDepartment', ) == null) {
            this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
          }
          else {
            this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
            if (this.selectedDepartment == null) {
              this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
            }
          }
          this.ngOnInit();
          this.departmentChangedSubscription.unsubscribe();
        })
      setTimeout(() => {
        localStorage.removeItem("set");
      },2000);
      this.startPollingDepartments();
    }

    ngOnDestroy(): void {
      if (this.pollingSubscription) {
        this.pollingSubscription.unsubscribe();
      }
      if (this.departmentChangedSubscription) {
        this.departmentChangedSubscription.unsubscribe();
      }
    }

    startPollingDepartments() {
      if (this.pollingSubscription) {
        this.pollingSubscription.unsubscribe();
      }
      this.pollingSubscription = interval(300000).pipe( // 5 minutes
        switchMap(() => {
          this.organisationService.getOrganisation(this.organisation.id);
          return this.organisationService.getOrganisationOrgDataListener().pipe(take(1));
        })
      ).subscribe({
        next: organisation => {
          // I need to compare the two trees and update the tree if there are differences
          // I do this on a temp object to not overload localstorage
          this.setOrgTree(organisation);
          let tempAllDepartments = [];
          tempAllDepartments.push({org: organisation, name: '\u2022 ' + organisation.name, level: 1});
          tempAllDepartments = this.iterateOrganisationTemp(organisation.orgs, 1, tempAllDepartments);
          if ( organisation && this.organisation && !isEqual(this.allDepartments, tempAllDepartments)) {
            localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));
            localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
            this.organisation = organisation;
            this.allDepartments = tempAllDepartments;
            localStorage.setItem('allDepartments', JSON.stringify(this.allDepartments));
          }
        },
        error: error => {
          console.error('Error fetching organisation:', error);
        }
      });
    }

    setOrgTree(organisation)
    {
      if (organisation) {
        for (var i = 0; i < organisation.orgs.length; i++) {
          organisation.orgs[i].disabled = true;

          if (this.currentUser.is_local_user == 1 || this.currentUser.is_local_admin == 1 || this.currentUser.is_local_assignee == 1 || this.currentUser.is_guest == 1) {
            for (let j = 0; j < this.currentUser.LocalUserDepartments.length; j++) {
              if (organisation.orgs[i].id == this.currentUser.LocalUserDepartments[j]) {
                organisation.orgs[i].disabled = false;
              }
            }
          } else {
            organisation.orgs[i].disabled = false;
          }

          if (organisation.orgs[i].orgs.length > 0) {
            this.setOrgTree(organisation.orgs[i]);
          }
        }
      }
    }

    getInventoryStatus() {
      this.inventoryOngoing = false;
      this.inventoryService.getOngoingInventories(this.selectedDepartment.id);
      this.inventoryService.getInventoryOngoingDataListener().pipe(take(1)).subscribe({
        next: inventories => {
          if (inventories != null && inventories.length > 0)
          {
            this.inventoryOngoing = true;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }

    onSearchChange(searchValue: string): void {
        this.allDepartments = [];
        this.allDepartments.push({org: this.organisation, name: this.organisation.name, level: 1});
        this.iterateOrganisation(this.organisation.orgs, 1);

        if (searchValue !== "")
        {
            var currentDepartments = [];
            currentDepartments = this.allDepartments.filter(item => item.org.name.toLowerCase().includes(searchValue.toLowerCase()));

            if (currentDepartments.length > 0) {
              this.allDepartments = [];

              currentDepartments.forEach(currentDepartment => {
                this.allDepartments.push({org: currentDepartment.org, name: currentDepartment.name, level: 1});
                this.iterateOrganisation(currentDepartment.org.orgs, 1);
              });
            }

          // Remove duplicates ( in name and id not level )
          this.allDepartments = this.allDepartments.reduce((acc, department) => {
            const existing = acc.find(d => d.name === department.name && d.org.id === department.org.id);
            if (!existing || department.level > existing.level) {
              return acc.filter(d => !(d.name === department.name && d.org.id === department.org.id)).concat(department);
            }
            return acc;
          }, []);

        }
    }

    addMessage(department) {
        localStorage.setItem('departmentchanged','set');
        if (this.currentUser.is_local_user == 1 || this.currentUser.is_local_admin == 1 || this.currentUser.is_local_assignee == 1 || this.currentUser.is_guest == 1) {
          for (let j = 0; j < this.currentUser.LocalUserDepartments.length; j++) {
            if (department.id == this.currentUser.LocalUserDepartments[j]) {
              this.selectedDepartment = department;
              localStorage.setItem('selectedDepartment', JSON.stringify(this.selectedDepartment));
              this.organisationService.departmentChanged.next(this.selectedDepartment);
            }
          }
        } else {
          this.selectedDepartment = department;
          localStorage.setItem('selectedDepartment', JSON.stringify(this.selectedDepartment));
          this.organisationService.departmentChanged.next(this.selectedDepartment);
        }
    }

    removeSubscriptions(orgs) {
      Object.keys(orgs)
        .filter(key => key.startsWith('subscription'))
        .forEach(key => delete orgs[key]);
    }

    removeSubscriptionsTemp(orgs) {
      Object.keys(orgs)
        .filter(key => key.startsWith('subscription'))
        .forEach(key => delete orgs[key]);
    }

    iterateOrganisation(orgs: any[], depth: number) {

      orgs.forEach(org => {
        if (org.disabled == false)
        {
          this.removeSubscriptions(org);
          this.allDepartments.push({org: org, name: '\u2022 ' + org.name, level: depth+1});
        }

        if (org.orgs.length > 0) {
          this.iterateOrganisation(org.orgs, depth + 1);
        }
      });
    }

    iterateOrganisationTemp(orgs: any[], depth: number, deps) {
      orgs.forEach(org => {
        if (org.disabled == false)
        {
          this.removeSubscriptions(org);
          deps.push({org: org, name: '\u2022 ' + org.name, level: depth+1});
        }

        if (org.orgs.length > 0) {
          this.iterateOrganisationTemp(org.orgs, depth + 1, deps);
        }
      });
      return deps;
    }

    departFullName(department){
      let deps = this.utilService.departFullNameHeader(department, this.listAlldepartments);
        return deps;
    }

    getAllDepartments() {
      if (this.organisation) {
        this.organisationService.getAllDepartmentNames(this.organisation);
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: departments => {
            if (departments == null) {}
            else {
              this.listAlldepartments = departments;
            }
          },
          error: error => {
            console.log("Error: ", error);
            return (null);
          }
        })
      }
      else
      {
        return (null);
      }
    }

    navigateHome(){
        if(this.currentUser && this.currentUser.is_guest==1){
          return;
        }

        this.router.navigate(['/dashboard']);
    }

    logout() {
        this.authService.logout();
    }

    gotoCustomerCard() {
      this.router.navigate(["/organisation", this.organisation.id]).then(() => {
          window.location.reload();
      });
    }

    gotoDepartment(dep){
      if (this.currentUser.is_guest == 1) {return;}
      let org;
      this.allDepartments.forEach(department => {
        if (department.org.id == dep.id) {
          org = department.org;
        }
      });
      this.addMessage(org)
    }

    getTreeDifferences(orgs1: any[], orgs2: any[]): string {
      const diff = [];
      const maxLength = Math.max(orgs1.length, orgs2.length);
      for (let i = 0; i < maxLength; i++) {
        if (!isEqual(orgs1[i], orgs2[i])) {
          diff.push(`Difference at index ${i}: ${JSON.stringify(orgs1[i])} !== ${JSON.stringify(orgs2[i])}`);
        }
      }
      return diff.join('\n');
    }
}
