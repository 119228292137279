
<div *ngIf="isLoading==false" class="flex flex-row">
    <div class="w-1/2 text-lg">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <span class="font-extrabold mb-1">{{organisation.name}}</span>
                <span class="font-bold" *ngIf="organisation.name != selectedDepartment.name"> &nbsp;/ {{selectedDepartment.name}}</span>
                <a *ngIf="currentUser.is_super_user" id="tourStep40" class="text-blue-700 cursor-pointer" (click)="gotoCustomerCard()">
                    &nbsp;{{"(" }}<b><u>{{selectedOrganisation?.type_of_subscription}}</u></b>{{")"}}
                </a>
            </div>
            <div class="flex flex-row flex-wrap items-start">
                <span *ngFor="let deps of departFullName(selectedDepartment); let isFirst = first; let isLast = last">
                  <span (click)="gotoDepartment(deps)"
                        *ngIf="deps.id != selectedDepartment.id"
                        class="text-gray-400 text-xs hover:text-gray-700 cursor-pointer font-bold italic text-shadow-sm inline-block"
                        [ngClass]="{'whitespace-pre-line': isFirst}">{{deps.name}}</span>
                  <span *ngIf="deps.id == selectedDepartment.id"
                        class="text-black-700 text-xs font-bold italic text-shadow-sm inline-block"
                        [ngClass]="{'whitespace-pre-line': isFirst}">{{deps.name}}</span>
                  <span *ngIf="!isLast" class="text-black-700 text-xs font-bold italic text-shadow-sm inline-block">
                    &nbsp;>&nbsp;
                  </span>
                </span>
            </div>
            <label *ngIf="inventoryOngoing==true" class="bg-red-500 rounded w-48 px-2 py-1 font-bold text-white text-sm text-center mt-2">{{ 'ONGOING_INVENTORY' | translate }}</label>
        </div>
    </div>
    <div class="w-1/2" *ngIf="!currentUser.is_guest">
        <div class="relative inline-block text-left w-full" appDropdown>
            <button type="button" [class.active]="isSettings" class="text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span class="nav-home-text text-lg">{{"CHOOSE_DEPARTMENT" | translate}}</span>
                <svg class="nav-home-carot" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div class="hidden dropdown-menu right-1 overflow-y-auto max-h-[600px] md:left-1 z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
                <div class="py-1">
                    <input st-search="" type="search" fullTextSearch="false" id="headerDepartmentSearch" (input)="onSearchChange($event.target.value)" [(ngModel)]="departmentSearch"
                        class="w-full px-4 py-2 mt-2 mb-2 border border-cdocblue rounded" placeholder="{{'SEARCH_DEPARTMENT' | translate}}" #dropdownSearch/>
                    <div *ngFor="let department of allDepartments"
                        [ngClass]="{'level-2': department.level === 2, 'level-3': department.level === 3, 'level-4': department.level === 4,
                                    'level-5': department.level === 5, 'level-6': department.level === 6, 'level-7': department.level === 7,
                                    'level-8': department.level === 8, 'level-9': department.level === 9, 'level-10': department.level === 10}"
                        class="dropdown-menu-item" (click)="addMessage(department.org)" tabindex="1">{{department.name}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

