<div class="flex min-h-screen">
    
    <div class="hidden md:block w-3/4">
        <img title="icon" class="min-h-screen w-screen" src="images/mountains-4777410_1920.jpg">
    </div>

    <div class="w-full md:w-1/4 bg-black flex items-center justify-center min-h-screen">
        <!-- Form Container -->
        <div class="max-w-md w-full bg-black p-8" [hidden]=forgotPassword>
            <img title="icon" class="mx-auto w-auto h-auto" src="images/cdoc_logga.png">

            <!-- Input Field 1 (Text Input) -->
            <div class="mb-4">
            <label for="username" class="block text-white text-sm font-medium mb-2 mt-10">{{ 'USERNAME' | translate }}</label>
            <input type="text" id="username" name="username" [(ngModel)]="username" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Input Field 2 (Password Input) -->
            <div class="mb-6">
            <label for="password" class="block text-white text-sm font-medium mb-2">{{ 'PASSWORD' | translate }}</label>
            <input type="password" id="password" name="password" [(ngModel)]="password" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Submit Button -->
            <button class="bg-buttongreen hover:bg-buttongreen-hover text-white font-bold py-2 px-4 rounded" type="submit" (click)="login()">
                {{ 'LOGIN' | translate }}
            </button>

            <div class="text-white pt-6 text-xs">
                <label><a class="underline hover:text-gray cursor-pointer" (click)="forgotPassword=!forgotPassword" id="forgotpassword">{{"FORGOT_PASSWORD" | translate}}</a></label>
            </div>

            <div class="text-white pt-4 text-xs" [hidden]=forgotPassword>
                <label>{{"NEW_HERE" | translate}}? <a class="underline hover:text-gray cursor-pointer" (click)="goToNewRegister()" id="register">{{"CREATE_NEW_ACCOUNT" | translate}}.</a></label>
            </div>

            <!-- Input Field 1 (Text Input) -->
            <div class="mb-4">
                <label for="email" class="block text-white text-sm font-medium mb-2 mt-10">{{ 'EMAIL' | translate }}</label>
                <input type="text" id="email" name="email" [(ngModel)]="email" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Submit Button -->
            <button class="bg-buttongreen hover:bg-buttongreen-hover text-white font-bold py-2 px-4 rounded" type="submit" (click)="loginSSO()">
                {{ 'LOGIN_SSO' | translate }}
            </button>

            <div class="fixed bottom-4 flex justify-center items-center text-white text-center text-xs py-">
                <label><small>&copy; {{today | date :  "yyyy" }}{{' '}}{{"COMPANYNAME" | translate}}</small></label>
            </div>
        </div>

        <!-- FORGOT PASSWORD FORM -->
        <div class="bg-white p-8 rounded" [hidden]=!forgotPassword>

            <h4 class="font-bold">{{"FORGOT_PASSWORD" | translate}}</h4>
            <p class="mb-5">{{"CONTACT_" | translate}} 
                <span class="underline text-blue-500">{{"SUPPORTMAIL" | translate}}</span>
            </p>
            <hr/>

            <p class="pt-5">{{"SEND_MESSAGE_TO_SUPPORT" | translate}}</p>

            <div>
                <label for="username" class="block text-black text-sm font-medium mb-2 mt-2">{{ 'USERNAME' | translate }}</label>
                <input title="{{ 'USERNAME' | translate }}" type="text" id="username2" placeholder="" [(ngModel)]="username2" (ngModelChange)="username2= $event" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Submit Button -->
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-2 mb-6 rounded" type="submit" (click)="mailsent()">
                {{ 'SEND' | translate }}
            </button>
            <br/>
            <div class="text-sm mt-2 mb-2"  *ngIf="mailsentClicked">
                <div>
                    {{'MESSAGE_SENT_FORGOT_PASSWORD_1' | translate }}
                </div><br/>
                    {{'MESSAGE_SENT_FORGOT_PASSWORD_2' | translate }}
                </div>
            <hr/>
            <!-- Submit Button -->
            <button class="text-black mt-6 hover:text-gray cursor-pointer" type="submit" (click)="goBack()">
                {{ 'BACK' | translate }}
            </button>
        </div>
    </div>
</div>


