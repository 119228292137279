import { Component, OnInit, OnDestroy} from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { AuthService } from '../auth/auth.service';
import { ProductService } from "src/app/services/product.service";
import { CoordinatorService } from "src/app/services/coordinator.service";
import { SubstitutionService } from "src/app/services/substitution.service";
import { HeaderComponent } from "src/app/header/header.component";
import { AssessmentChemicalService } from "src/app/services/assessment-chemical.service";
import { AssessmentService } from "src/app/services/assessment.service";
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DropdownDirective } from 'src/app/shared/directives/dropdown.directive';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent {

  subscribesToCatalogue: boolean;
  subscribesToProducts: boolean;
  subscribesToExposures: boolean;
  subscribesToReports: boolean;
  subscribesToProjects: boolean;
  subscribesToLawLawpoint: boolean;
  subscribesToSamtec: boolean;
  subscribesToLawAptor: boolean;
  subscribesToEducation: boolean;
  isHome: boolean = false;
  subscribesToCoordinator: any;
  subscribesToSubstitution: any;
  subscribesToRiskAssessment: any;
  cataloguesCount: any;
  selectedOrganisation: any;
  selectedDepartment: any;
  organisation: any;
  productsCount: any;
  productsLang: any;
  products: any;
  coordinatorCount: any;
  substitutionCount:any;
  lrbCount: any;
  arbCount: any;
  currentUser: any=null;
  currentDepartment: any=null;
  departmentChangedSubscription: Subscription;

  constructor(private router: Router, private utilService: UtilService, private organisationService: OrganisationService,
    private catalogueService: CatalogueService, private productService: ProductService, private assessmentService: AssessmentService,
    private coordinatorService: CoordinatorService, private substitutionService: SubstitutionService,
    private assessmentChemicalService: AssessmentChemicalService, private authService: AuthService, private translate: TranslateService) {}

  ngOnInit() {

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
        this.logout();
    }
    else
    {
        this.currentUser = JSON.parse(data);
    }

    this.subscribesToCatalogue = this.utilService.subscribesTo('subscription_catalogue');
    this.subscribesToProducts = this.utilService.subscribesTo('subscription_products');
    this.subscribesToCoordinator = this.utilService.subscribesTo('subscription_coordinator');
    this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
    this.subscribesToRiskAssessment = this.utilService.subscribesTo('subscription_risk_assessment');
    this.subscribesToExposures = this.utilService.subscribesTo('subscription_exposures');
    this.subscribesToReports = this.utilService.subscribesTo('subscription_reports');
    this.subscribesToProjects = this.utilService.subscribesTo('subscription_projects');
    this.subscribesToLawLawpoint = this.utilService.subscribesTo('subscription_lawpoint');
    this.subscribesToLawAptor = this.utilService.subscribesTo('subscription_law_aptor');
    this.subscribesToSamtec = this.utilService.subscribesTo('subscription_samtec');
    this.subscribesToEducation = this.utilService.subscribesTo('subscription_education');

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    if (this.selectedDepartment == null)
    {
      this.selectedDepartment = this.organisation;
    }
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    this.cataloguesCount = 0;
    this.productsCount = 0;
    this.coordinatorCount = 0;
    this.substitutionCount = 0;
    this.lrbCount = 0;
    this.arbCount = 0;
    this.productsLang = {
      "en":0,
      "sv":0,
      "fi":0
      // more languages can be added here
    };

    this.loadProducts(this.selectedDepartment);
    this.loadCatalogue();
    this.getAllCoordinatorSubjects();
    this.getAllSubstitutionSubjects();
    this.getAllAssessmentSubjects();
    this.getAllActivityAssessments();

    // Set Login Status
    this.departmentChangedSubscription = this.organisationService.departmentChanged.subscribe((msg: any) => {
      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.currentDepartment = this.selectedDepartment;
      this.departmentChangedSubscription.unsubscribe();
      this.ngOnInit();
    })
  }

  logout() {
    this.authService.logout();
  }

  goToHelp() {
    this.router.navigate(['/help']);
  }

  goToSettings() {
      this.router.navigate(['/userprofile']);
  }

  showContactForm() {
      this.router.navigate(['/contactform']);
  }

  getAllActivityAssessments() {

    this.assessmentService.AllWithSubDepartments();
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info != null) {
          for (var n = 0; n < info.length; n++) {
            this.arbCount += 1;
          }
        }
      }
    })
  }

  getAllAssessmentSubjects() {
    this.lrbCount = 0;
    this.assessmentChemicalService.getProductsWithSuborganisations();
    this.assessmentChemicalService.getAssessmentProductsDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info != null) {
          for (var n = 0; n < info.length; n++) {
            if ((info[n].chemical_id != null) && (info[n].created_date != null))
            {
              this.lrbCount += 1;
            }
          }
        }
      }
    })
  }

  getAllSubstitutionSubjects() {

    this.substitutionCount = 0;

    if (this.selectedDepartment) {
      this.substitutionService.getAll();
      this.substitutionService.getSubstitutionAllDataListener().pipe(take(1)).subscribe({
        next: substitutionSubjects => {
          if (substitutionSubjects != null)
          {
            this.substitutionCount = substitutionSubjects.length;
          }
        }
      })
    }
  }

  getAllCoordinatorSubjects() {

    this.coordinatorCount = 0;

    if (this.selectedDepartment) {
      this.coordinatorService.getAllSubs();
      this.coordinatorService.getAllCoordinatorDataListener().pipe(take(1)).subscribe({
        next: coordinatorSubjects => {
          if (coordinatorSubjects != null)
          {
            for (var i = 0; i < coordinatorSubjects.length; i += 1) {

              if (coordinatorSubjects[i].status === 0) {
                this.coordinatorCount++;
              }
              else if (coordinatorSubjects[i].status > 0 && coordinatorSubjects[i].status < 4) {
                this.coordinatorCount++;
              } else if (coordinatorSubjects[i].status === 4) {
                this.coordinatorCount++;
              }
            }
          }
        }
      })
    }
  }

  getProducts() {
    if (this.selectedDepartment)
    {
      return new Promise<any>(resolve => {
        if (this.utilService.subscribesTo("subscription_stamped_SDS")) {

          this.productService.AllWithSuborganisationsMoreInformationStampedTop(this.selectedDepartment.id)
        } else {

          this.productService.AllWithSuborganisationsMoreInformationTop(this.selectedDepartment.id);
        }

        this.productService.getProductAllSubTopListener().pipe(take(1)).subscribe({
          next: products => {
            resolve(products);
          },
          error: error => {
            console.log("Error: ", error);
            this.utilService.onError(error);
          }
        });
      })
    }
  };

  loadProducts(department) {

    this.productsCount = 0;
    if (this.selectedDepartment==department) {
      this.getProducts().then(products => {
          if (products != null) {
            this.productsCount = 0;
            this.products = products;
            this.products.forEach(product => {
              if (product?.override == null) {
                if ( product?.lang == 0 ) {
                  this.productsLang["sv"]++;
                } else if ( product?.lang == 1 ) {
                  this.productsLang["en"]++;
                } else if ( product?.lang == 2 ) {
                  this.productsLang["fi"]++;
                }
                  this.productsCount++;
              }
            });
          }
      })
    }
  }

  loadCatalogue() {

    if (this.selectedDepartment) {
      this.catalogueService.AllWithSuborganisations(this.selectedDepartment.id);
      this.catalogueService.getCatalogueDataListener().pipe(take(1)).subscribe({
        next: catalogues => {
          if (catalogues != null)
          {
            this.cataloguesCount = catalogues.length;
          }
        },
        error: error => {
          console.log("Unable to fetch catalogues: ", error);
        }
      })
    }
  }

  goToCatalogue() {
    this.organisationService.setCatalogueList.next("catalogue");
  }

  goToProducts() {
    this.organisationService.setProductList.next("product");
  }

  goToCoordinator() {
    this.organisationService.setCoordinatorList.next("coordinator");
  }

  goToSubstitution() {
    this.organisationService.setSubstitutionList.next("substitution");
  }

  gotoLocalRiskAssessments() {
    this.organisationService.setRiskAssessmentList.next("assessment");
  }

  gotoActivityRiskAssessments() {
    this.organisationService.setRiskAssessmentActivityList.next("catalogue");
  }

  gotoSubstitution() {
    this.router.navigate(['/substitution']);
  }

  gotoCatalogue(){
    this.router.navigate(['/catalogue']);
  }

  gotoProducts() {
    this.router.navigate(['/productlist']);
  }

  goToExposures() {
    this.router.navigate(['/exposures']);
  }

  goToHistory() {
    this.router.navigate(['/history']);
  }

  goToAssessment() {
    this.router.navigate(['/assessments']);
  }

  goToAssessmentChemical() {
    this.router.navigate(['/assessmentschemical']);
  }

  gotoReports() {
    this.router.navigate(['/reports']);
  }

  gotoMaterialList() {
    this.router.navigate(['/materiallist']);
  }

  gotoLaw() {
    this.router.navigate(['/law']);
  }
  gotoEducation() {
    var link="https://portal.cloocast.com"
    window.open(link,'_blank');
  }

  gotoSamtec() {
    if(!this.currentUser.samtectoken){
      window.open('https://portal.samtec.se/','_blank');
      return;
    }
    var link=this.translate.instant("SAMTEC_LINK_PROD")+this.currentUser.samtectoken;
    window.open(link,'_blank');
  }

  gotoControlPanel() {
    this.router.navigate(['/controlpanel']);
  }

  navigateHome(){
    if(this.currentUser && this.currentUser.is_guest==1){
      return;
    }

    this.router.navigate(['/dashboard']);
  }
}
