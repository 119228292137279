import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-add-assessment',
  templateUrl: './add-assessment.component.html',
  styleUrls: ['./add-assessment.component.css']
})
export class AddAssessmentComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  project: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  created_date: any = 0;
  assessment: any;
  substitution: boolean = false;
  phasing_out: boolean = false;
  risk_minimzation: boolean = false;
  products: any = [];
  assessmentfile: any;
  filenames: any = [];
  assessmentimage: any;
  picturenames: any = [];
  subscribesToSds: any;
  subscribesToGrb: any;
  assessment_activity:any;
  riskfile: any = "";
  loadingData: boolean = false;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, private assessmentService: AssessmentService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');
    this.loadingData = true;

    this.assessment = JSON.parse(localStorage.getItem('assessment_activity', ));
    if (!this.assessment && this.selectedDepartment)
    {
      this.assessment = {
        created_date: new Date(),
        updated_date: new Date(),
        issuer: this.currentUser,
        participants: "",
        responsible: "",
        approved_by: "",
        heading: "",
        description: "",
        status: 0,
        risk: 0,
        substitution: 0,
        phasing_out: 0,
        risk_minimzation: 0,
        general_risks: "",
        ventilation: "",
        storage: "",
        hand_protection: "",
        eye_protection: "",
        respiratory: "",
        body_protection: "",
        incident_info: "",
        comments: "",
        department: this.selectedDepartment.name,
        worksteps: [],
        workstep_index: 0,
        assessmentimages: [],
        assessmentfiles: [],
        noactionneeded: 0,
        riskfile: "",
        editActivity: false
      };
    }

    this.getAllDepartments();

    //Get all products
    if (this.assessment != null) {
      if (this.assessment.worksteps) {
        for (var i = 0; i < this.assessment.worksteps.length; i++) {
          for (var j = 0; j < this.assessment.worksteps[i].products.length; j++) {
            this.products.push(this.assessment.worksteps[i].products[j]);
          }
        }
      }
    }

    this.assessment_activity = localStorage.getItem('assessment_activity');
    if(this.assessment_activity&&this.assessment_activity!=null){
      this.submit();
    }
    this.loadingData=false;
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  goToAssessmentList() {
    this.router.navigate(['/assessments']);
  }

  incomingfile(event) {
    this.assessmentfile = event.target.files[0];

    if (this.assessmentfile != null) {

      this.uploadService.upload(this.assessmentfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result != null)
          {
            if (!this.assessment.assessmentfiles)
            {
              this.assessment.assessmentfiles = [];
            }
            var file_id = {"file_id": result.filename, "file_name": this.assessmentfile.name};
            this.assessment.assessmentfiles.push(file_id);
            this.filenames.push(this.assessmentfile.name);
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  incomingimage(event) {
    this.assessmentimage = event.target.files[0];;

    if (this.assessmentimage != null) {

      this.uploadService.upload(this.assessmentimage);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result != null)
          {
            if (!this.assessment.assessmentimages)
            {
              this.assessment.assessmentimages = [];
            }
            var file_id = {"file_id": result.filename};
            this.assessment.assessmentimages.push(file_id);
            this.picturenames.push(this.assessmentimage.name);
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  workstepSelected(workstep, index) {
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
    this.router.navigate(['/workstep', index+1]);
  }

  addWorkstep() {
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
    this.router.navigate(['/workstep', 0]);
  }

  workstepRemoved(index) {
    this.assessment.worksteps.splice(index, 1);
    this.picturenames.splice(index, 1);
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
  }

  openSdsInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  onToggleShowSubstitution() {
    if (this.substitution == true)
    {
      this.assessment.substitution = 1;
    }
    else
    {
      this.assessment.substitution = 0;
    }
  }

  onToggleShowOutphasing() {
    if (this.phasing_out == true)
    {
      this.assessment.phasing_out = 1;
    }
    else
    {
      this.assessment.phasing_out = 0;
    }
  }

  onToggleShowRiskMinimzation() {
    if (this.risk_minimzation == true)
    {
      this.assessment.risk_minimzation = 1;
    }
    else
    {
      this.assessment.risk_minimzation = 0;
    }
  }

  submit() {
      this.loadingData=true;
      this.assessmentService.createAssessment(this.assessment);
      this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else if(this.assessment_activity&&this.assessment_activity!=null){
          localStorage.removeItem('assessment_activity');
          this.router.navigate(['/assessmentedit', pdfContent.id]);
        }
        else {
          this.assessment.id=pdfContent.id;
          this.assessmentService.createARB(pdfContent.id);
          this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
            next: file => {
              if (file == null) {
                Swal.fire("Error", "generiskt fel");
              }
              else {{
                  this.riskfile = file;
                  this.submitAfterPdfUpdate();
                }
              }
            },
            error: error => {
              console.log("Error: ", error);
              Swal.fire("Error", "generiskt fel");
              this.loadingData=false;
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  submitAfterPdfUpdate() {
    if (!this.assessment.issuer) {
      this.assessment.issuer = this.currentUser;
    }

    var promises = [];

    if(this.riskfile != "") {
      var getRiskFile = new Promise((resolve, reject) => {
        this.utilService.uploadFile(this.riskfile);
        this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
          next: response => {
            resolve(response);
          },
          error: error => {
            console.log("Error: ", error);
            this.utilService.onError(error);
          }
        })
      });
    }

    Promise.all([getRiskFile]).then(values => {

      if (!this.assessment.riskfile)
      {
        this.assessment.riskfile = "";
      }

      if (values[0] != undefined)
      {
        var file_id = {"file_id": values};
        this.assessment.riskfile = file_id.file_id[0];
        this.assessment.riskfile = this.assessment.riskfile.filename;
      }
      this.assessmentService.updateAssessment(this.assessment.id, this.assessment);
      this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {
            this.loadingData=false;
            Swal.fire("Error", "generiskt fel");
          }
          else if(this.assessment_activity&&this.assessment_activity!=null){
            localStorage.removeItem('assessment_activity');
            window.location.reload();
            this.loadingData=false;
          }
          else {
            this.loadingData=false;
            this.router.navigate(['/assessments']);
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "generiskt fel");
        }
      })
    });
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  validation() {
    this.assessment.status = 1;
    this.submit();
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }
}
