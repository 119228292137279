import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FileUploadService } from 'src/app/services/file-upload.service';
declare var $: any;

@Component({
  selector: "app-productapplication-item",
  templateUrl: "./productapplication-item.component.html",
  styleUrls: ["./productapplication-item.component.css"],
})

export class ProductapplicationItemComponent implements OnInit {
  itemsPerPage: number = null;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  products: any = [];
  productApplication: any = [];
  productsOverride: any = [];
  showSubdepartments: boolean = true;
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  order = ["asc", "asc","asc"];
  productApplicationStarted: boolean = false;
  productapplicationSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  sareqProducts: any=[];
  numberOfItems0  = 100;
  numberOfItems1  = 100;
  searchPageNumber0=1;
  totalAmount;
  orderBy = "";
  productSearch: boolean = false;
  productInSareq: boolean = true;
  productNotSareq: boolean = false;
  allDepartments: boolean = false;
  product: any = {
    name:null,
    supplier_name:null,
    id:null
  };
  listOfDepartments: any = [];
  listOfDepartmentsDuplicate: any = [];
  showProductApplications;
  rowindex: number = null;
  productApplicationId: number = null;
  productApplicationDepartments: any = [];
  productApplicationDepartmentsObject: any = [];
  approvalStatus: string = '';
  productApplicationOrganisation;
  units: any;
  departmentSearch: any = null;
  selectedFiles?: FileList;
  selectedFile: any;
  currentFile?: File;
  currentFiles: any;
  sendToSupport: boolean = false;

  ngOnDestroy(): void {}

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private productApplicationService: ProductApplicationService,
    private sareqService: SareqService,
    private router: Router,
    private previousrouter: ActivatedRoute,
    private uploadService: FileUploadService,
  ) {}

  ngOnInit(): void {
    this.previousrouter.params.pipe(take(1)).subscribe((params) => {
      this.productApplicationId = params["id"];
    });

    if(!this.productApplicationId||this.productApplicationId==null||this.productApplicationId==undefined){
      this.router.navigate( [ '/productapplicationlist' ] );
    }

    this.loadingData = true;
    if ( localStorage.getItem("productApplicationOrganisation") ) {
      this.productApplicationOrganisation = JSON.parse(localStorage.getItem("productApplicationOrganisation"));
    }

    localStorage.removeItem( "productApplicationOrganisation" );

    this.organisation = JSON.parse(
      localStorage
      .getItem( 'rootOrganisation', )
    );

    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );

    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );

    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.units = this.staticDataService.getUnitDefinitions();
    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartments")
    );

    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.itemsPerPage = 10;

    this.getAllDepartments();

    if ( this.productApplicationOrganisation ) {
      this.listOfDepartments = this.utilService.getAllSubdepartments(this.productApplicationOrganisation,false);
    } else {
      this.listOfDepartments = this.utilService.getAllSubdepartments(this.selectedOrganisation,false);
    }
    this.listOfDepartmentsDuplicate=_.cloneDeep(this.listOfDepartments);

    setTimeout( () =>{
      this.getProductApplication(this.productApplicationId);
      this.loadingData = false;
    },500)

    this.organisationService.departmentChanged
      .pipe( take( 1 ) )
      .subscribe( ( msg: any ) => {
        this.utilService.departmentChangeClicked(this.router.url);
        this.loadingData = true;
        this.router.navigate(['/productapplicationlist']).then(() => {
          window.location.reload();
        })
    });
  }


  logout() {
    this.authService.logout();
  }


  getAllDepartments() {
    if (
      this.listAlldepartments == null ||
      ( this.productApplicationOrganisation
        ||this.organisation && this.organisation.id != JSON.parse(localStorage.getItem("rootOrganisation")).id)
    ) {
      if ( this.productApplicationOrganisation ) {
        this.organisationService.getAllDepartmentNames(this.productApplicationOrganisation);
      } else {
        this.organisationService.getAllDepartmentNames(this.organisation);
      }


      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService
        .getOrganisationAllDepartmentNamesDataListener()
        .pipe(take(1))
        .subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;

            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  goToProductApplication() {
    this.router.navigate( [ "/productapplicationlist" ] );
  }

  sortItem(type, id) {
    if (this.order[id] == "asc") {
      this.order[id] = "desc";
      this.productsOverride = _.orderBy(this.productsOverride, [type], ["asc"]);
    } else if (this.order[id] == "desc") {
      this.order[id] = "asc";
      this.productsOverride = _.orderBy(
        this.productsOverride,
        [type],
        ["desc"]
      );
    }
  }

  changeArticleSearch(){
    for (var i = 0; i < this.productapplicationSearch.supplier_article_number.length; i++)
    {
      this.productapplicationSearch.supplier_article_number = this.productapplicationSearch.supplier_article_number.replace(' ', '');
    }

    this.filterSearchItems();
  }

  filterSearchItems(){
    this.products=this.sareqProducts;
    if(this.productapplicationSearch.all==''){
      this.productapplicationSearch.all=null;
    }
    if(this.productapplicationSearch.name==''){
      this.productapplicationSearch.name=null;
    }
    if(this.productapplicationSearch.supplier_name==''){
      this.productapplicationSearch.supplier_name=null;
    }
    if(this.productapplicationSearch.supplier_article_number==''){
      this.productapplicationSearch.supplier_article_number=null;
    }
    if(this.productapplicationSearch.all!=null){
      this.products=this.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productapplicationSearch.all.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.name!=null){
      this.products=this.products.filter(o => o.name?.toLowerCase().indexOf(this.productapplicationSearch.name.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.supplier_name!=null){
      this.products=this.products.filter(o => o.supplier_name?.toLowerCase().indexOf(this.productapplicationSearch.supplier_name.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.supplier_article_number!=null){
      this.products=this.products.filter(o => o.supplier_article_number?.toString().toLowerCase().indexOf(this.productapplicationSearch.supplier_article_number.toLowerCase())>-1);
    }

  }

  onKeyTabbEvent(id): void {
    if(id=='button-searchproduct'){
      setTimeout(()=>{let element=document.getElementById('productapplicationSearchname');element.focus();},100);
    }
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  addProduct(product) {
    for ( var n=0; n<this.products.length; n++ ) {
      if ( this.products[n].isSelected == true
            && this.products[n].id != product.id ) {
        delete this.products[n].isSelected;
      }
    }

    if ( product.isSelected == true ) {
      delete product.isSelected;
      this.productInSareq = true;
    } else {
      product.isSelected=true;
      this.productSearch = false;
      this.productInSareq = true;
      this.productApplication.sareq_id = product.id;
      this.productApplication.name = product.name;
      this.productApplication.supplier_id = product.supplier_id;
      this.productApplication.supplier_name = product.supplier_name;
    }
  }

  openSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    });
  }

  openGrbInNewWindow(product) {
    const filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    });
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  selectThisDepartment(department){
    if ( department=="all" ) {
      this.listOfDepartments = this.listOfDepartmentsDuplicate;
      if ( this.allDepartments == true ) {
        this.allDepartments = false;
      } else {
        this.allDepartments = true;
      }
      for ( var n=0; n<this.listOfDepartments.length; n++ ) {
        this.listOfDepartments[n].selected = this.allDepartments;
      }
      return;
    }

    if ( department.selected == true ) {
      department.selected = false;
    } else {
      department.selected = true;
      }
  }

  getProductApplication(id) {
    this.productApplicationService.getProductApplication( id );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.productApplication = responce;
          if (this.productApplication.departments) {
            var depsArray = this.productApplication.departments.replace(/\s/g, '').split(',');
            this.listOfDepartments.forEach(element => {
              if ( depsArray.includes(element.id) ) {
                element.selected = true;
              }
            });
          }

          if ( !this.productApplication.sareq_id || this.productApplication.sareq_id == null ) {
            this.productInSareq = false;
          } else {
            this.productInSareq = true;
          }

          this.productApplicationDepartments = this.productApplication.departments.split(',').map(str => str.trim());
          for (var n = 0; n < this.productApplicationDepartments.length; n++) {
            for (var o = 0; o < this.listAlldepartments.length; o++) {
              if ( this.listAlldepartments[o].id == this.productApplicationDepartments[n] ) {
                this.productApplicationDepartmentsObject.push(this.listAlldepartments[o]);
              }
            }
          }

          switch (this.productApplication.status) {
            case 1:
              this.approvalStatus = 'new';
              break;
            case 2:
              this.approvalStatus = 'approved';
              break;
            case 3:
              this.approvalStatus = 'rejected';
              break;
            default:
              this.approvalStatus = 'new';
              break;
          }

        // Get file
          this.currentFiles = [];
          if ( this.productApplication.fileid ) {
            let info = {
              fileid: this.productApplication.fileid,
              filename: this.productApplication.filename ? this.productApplication.filename.substring(0, this.productApplication.filename.indexOf(".pdf")):"SDB"
            }
            this.currentFiles.push(info);
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  submit() {
    this.loadingData = true;
    if ( this.productApplication.name === ""||this.productApplication.name === null||this.productApplication.name === undefined ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("PRODUCT_NAME_IS_REQUIRED"), "", "error");
      return;
    }
    if ( this.productApplication.supplier_name === ""||this.productApplication.supplier_name === null||this.productApplication.supplier_name === undefined ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("MANUFACTURER_IS_REQUIRED"), "", "error");
      return;
    }
    if ( this.productInSareq != false ) {
      if ( !this.productApplication.sareq_id ) {
        this.loadingData = false;
        Swal.fire(this.translate.instant("CHOOSE_PRODUCT"), "", "error");
        return;
      }
    }
    var departments = [];
    for ( var n=0; n<this.listOfDepartments.length; n++ ) {
      if ( this.listOfDepartments[n].selected == true ) {
        departments.push(this.listOfDepartments[n].id);
      }
    }
    if ( departments.length == 0 ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("NO_DEPARTMENTS"), "", "error");
      return;
    }
    this.productApplication.departments = departments.join(",");
    if ( this.approvalStatus != 'new'
          && this.approvalStatus != 'approved'
          && this.approvalStatus != 'rejected' ) {
      Swal.fire("Error", this.translate.instant("SELECT_APPROVAL_STATUS"));
      return;
    }
    switch ( this.approvalStatus ) {
      case 'new':
        this.productApplication.status = 1;
        break;
      case 'approved':
        this.productApplication.status = 2;
        break;
      case 'rejected':
        this.productApplication.status = 3;
        break;
    }
    this.productApplication.user_id = this.currentUser.id;

    if ( this.productApplication.product_application_mail_support && this.productApplication.product_application_mail_support == true ) {
      this.productApplication.product_application_mail_support = 1;
    } else {
      this.productApplication.product_application_mail_support = 0;
    }

    this.productApplicationService.updateProductApplication( this.productApplication );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          this.loadingData = false;
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.loadingData = false;
          this.router.navigate( [ "/productapplicationlist" ] );
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  deletePost(productapplicationId) {
    if (!productapplicationId || productapplicationId == null) {
      return;
    }
    Swal.fire({
      title: "Delete Post?",
      text: "This will delete the post.",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("OK"),
      cancelButtonText: this.translate.instant("CANCEL"),
    }).then( ( result ) => {
      if (result.isConfirmed) {
        this.productApplicationService.deleteProductApplication(productapplicationId);
        this.productApplicationService
          .getProductApplicationDataListener()
          .pipe( take( 1 ) )
          .subscribe( {
          next: () => {
            this.router.navigate( [ "/productapplicationlist" ] );
          },
          error: (error) => {
            Swal.fire(
              this.translate.instant("It was not possible to delete productapplication"),
              this.translate.instant("It was not possible to delete productapplication", "error")
            );
          },
        } );
      } else if ( result.isDenied || result.isDismissed ) {
        return;
      }
    });
  }

  addProducts() {
    this.productApplicationService.addProductProductApplication(this.productApplication);
    this.productApplicationService.addProductsApplicationDataListener().pipe( take( 1 ) ).subscribe( {
      next: () => {
        this.router.navigate( [ "/productapplicationlist" ] );
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("It was not possible to add product"),
          this.translate.instant("It was not possible to add product", "error")
        );
      },
    });
  }

  setItems(unit) {
    this.numberOfItems0 = unit;
    if(unit == "alla"){
      this.numberOfItems1 = 0;
    } else {
      this.numberOfItems1 = unit;
    }
  }

  searchProducts( tabb, orderby ){
    if ( orderby == '' && tabb == '' )
    {
      this.orderBy = '';
    }
    else if(orderby=='' && tabb=='Prev'||tabb=='Next') {

    } else {
      this.orderBy = orderby;
    }

    this.loadingData = true;
    if(tabb==''||tabb=='Prev'||tabb=='Next'){
      if(tabb=='Prev'){this.searchPageNumber0--;}
      if(tabb=='Next'){this.searchPageNumber0++;}
      if(tabb==''){this.searchPageNumber0=1;}
      if(!this.numberOfItems0){
        this.numberOfItems0=100;
      }
      var info = {
        'name': encodeURIComponent(this.productapplicationSearch.name==null?'':this.productapplicationSearch.name),
        'manufacturer': encodeURIComponent(this.productapplicationSearch.supplier_name==null?'':this.productapplicationSearch.supplier_name),
        'articlenumber': this.productapplicationSearch.supplier_article_number==null?'':this.productapplicationSearch.supplier_article_number,
        'orderBy': this.orderBy,
        'limit': this.numberOfItems0,
        'page': this.searchPageNumber0,
        'status': 'allproducts'
      };
    }
    this.sareqProducts=[];

    this.sareqService.getAllAndFilter(info);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: sareqProducts => {
        if(!sareqProducts||sareqProducts==null){
          this.loadingData = false;
      } else {
        if(!sareqProducts[sareqProducts.length-1].id){
          this.totalAmount = sareqProducts[sareqProducts.length-1];
          sareqProducts.splice(sareqProducts.length-1,1);
        }
        for(var i = 0; i < sareqProducts.length; i++) {
          if(sareqProducts[i].hidden != 1 && sareqProducts[i].override == null) {
            if(sareqProducts[i].secret_chemical != 1||
                this.selectedOrganisation.id==sareqProducts[i].secret_chemical_organisation) {
                this.sareqProducts.push(sareqProducts[i]);
            }
          }
          // this.sareqProducts.push(sareqProducts[i]);
        }
        this.products = this.sareqProducts;
        this.itemsPerPage = this.numberOfItems0;
        this.loadingData = false;
          setTimeout( () => {
            let element=document.getElementById('productapplicationSearchname');
              element.focus();
          },100)
        }
      },
      error: error => {
        Swal.fire("Error excel", "Error", "error");
        this.loadingData = false;
      }
    })
  }

  getAmount(item){
    if(item=='1') {
      var amount = this.numberOfItems0*this.searchPageNumber0;
      if(amount<this.totalAmount) {
        return amount;
      } else {
        return this.totalAmount;
      }
    }
  }

  changeProductNotSareq( ) {

    if ( this.productInSareq==true ) {
      this.productInSareq=false;
      this.productApplication.notinsareq = true;
      this.productApplication.sareq_id = null;
      this.productApplication.supplier_id = null;
    } else {
      this.productInSareq=true;
      delete this.productApplication.notinsareq;
    }
  }

  changeSendToSupport() {
    if ( this.sendToSupport==true ) {
      this.sendToSupport=false;
    } else {
      this.sendToSupport=true;
    }
  }

  setUnit(product, value) {
    for ( var i = 0; i < this.units.length; i++ ) {
      if (value == this.units[i].id) {
        product.unit = this.units[i].id;
        break;
      }
    }
  }

  departmentSearchItems() {
    this.listOfDepartments = this.listOfDepartmentsDuplicate;

    if ( this.departmentSearch == '' || this.departmentSearch == null ) {
      this.departmentSearch=null;
    }

    if( this.departmentSearch != null ){
      this.listOfDepartments = this.listOfDepartments.filter(o => o.name?.toLowerCase().indexOf( this.departmentSearch.toLowerCase() ) >-1 );
    }
  }

  changeProductApplicationMailSupport(event) {
    if ( event == true ) {
      this.productApplication.product_application_mail_support = true;
    } else if ( event == false ) {
      this.productApplication.product_application_mail_support = false;
    }
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    this.selectedFile = this.selectedFiles[0];
  }

  uploadSds() {
    if ( this.selectedFile ) {
      this.uploadSelectedFile();
    } else {
      Swal.fire(this.translate.instant("FILE_MISSING"), this.translate.instant("NO_UPLOAD"), "error");
    }
  }

  uploadSelectedFile() {
    if ( this.sendToSupport == true ) {
      if ( this.productApplication.name == null || this.productApplication.supplier_name == null ||
        this.productApplication.name == "" || this.productApplication.supplier_name == ""
      ) {
        Swal.fire(this.translate.instant("NO_NAME_OR_SUPPLIER"), this.translate.instant("NO_NAME_OR_SUPPLIER"), "error");
        return;
      }
    }
    this.loadingData = true;
    if (this.selectedFile) {
        this.uploadService.upload(this.selectedFile);
        this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
          next: response => {
            if (response.filename)
            {
              this.selectedFile.id = response.filename;
              var uploadedFile = {
                file_id: response.filename,
                filename: this.selectedFile.name,
                id: this.productApplication.id,
                sendToSupport: this.sendToSupport,
                productname: this.productApplication.name||"",
                supplier: this.productApplication.supplier_name||""
              }

              this.productApplicationService.uploadSds(uploadedFile);
              this.productApplicationService.getSDSDataListener().pipe(take(1)).subscribe({
                next: pdfContent => {
                  let info = {
                    fileid: uploadedFile.file_id,
                    filename: uploadedFile.filename ? uploadedFile.filename.substring(0, this.productApplication.filename.indexOf(".pdf")):"SDB"
                  }
                  this.currentFiles.push(info);
                  this.loadingData = false;
                },
                error: error => {
                  console.log("Error: ", error);
                  this.selectedFile = null;
                  this.loadingData = false;
                }
              })
            }
          },
          error: error => {
            console.log("Error: ", error);
            this.selectedFile = null;
            this.loadingData = false;
          }
      })
    }
  }

  openDocumentInNewWindow(id) {
    // Open a blank window immediately in response to the user action
    const newWindow = window.open('', '_blank');

    if (!newWindow) {
      Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
      return;
    }

    this.productApplicationService.getsds(id);
    this.productApplicationService.getSDSDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
          newWindow.close();
        } else {
          newWindow.location.href = pdfContent;
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
        newWindow.close();
      }
    });
  }

  removeDocument(id){
    this.productApplicationService.deleteFileWithId(id);
    this.productApplicationService.getSDSDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        this.currentFiles = [];
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }
}
