<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
  <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200">
  <div class="flex flex-row mt-2">
    <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
        <path fill="#000000"
              d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
      </svg>
    </button>
    <div class="flex flex-row justify-center items-center w-full">
      <span class="text-xl font-bold px-8 py-2 text-black">{{'EXPOSURE_LIST' | translate}}</span>
    </div>
  </div>

  <div *ngIf="loadingData==false" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
      <div class="w-2/3 mt-2 mb-2">
        <div class="flex flex-col px-4">
          <p class="text-gray-200"><a><strong>{{"NAME" | translate}}: {{exposure.name}}</strong></a></p>
          <span class="text-white text-sm mr-4">{{"PERSONAL_CODE_NUMBER" | translate}}: {{exposure.id_number}}</span>
        </div>
      </div>
  </div>

  <div *ngIf="loadingData==false" class="flex flex-col w-full p-4 mb-10">
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
          <div class="flex-1">
            <label class="font-bold mb-1">{{"NAME" | translate}}</label><br>
            <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="exposure.name" (ngModelChange)="exposure.name = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-2">
            <label class="font-bold mb-1">{{"PERSONAL_CODE_NUMBER" | translate}}:</label><br>
            <input title="{{'PERSONAL_CODE_NUMBER' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="exposure.id_number" (ngModelChange)="exposure.id_number = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"CHEMICAL" | translate}}</label><br>
            <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
              <button type="button" class="button-white w-full justify-between border border-gray-300">
                  <span>{{exposure?.chemical?.name}}</span>
                  <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </button>

              <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchProduct($event.target.value)" [(ngModel)]="exposureSearch.name"
                  class="w-full py-2 pl-2 border rounded border-cdocblue focus:outline-none focus:border-blue-500" placeholder="" #dropdownSearch/>
                  <div class="py-1">
                    <div *ngFor="let product of filteredProducts" data-value="product" class="dropdown-menu-item" (click)="setProduct(product)" tabindex="1">{{ product.name }}</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
          <div class="flex-2">
            <label class="font-bold mb-1">{{"TIME_PER_MOMENT" | translate}}</label><br>
            <input title="{{'TIME_PER_MOMENT' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="exposure.exp_per_moment" (ngModelChange)="exposure.exp_per_moment = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-2">
            <label class="font-bold mb-1">{{"INTERVAL" | translate}}</label><br>
            <input title="{{'INTERVAL' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="exposure.exp_times" (ngModelChange)="exposure.exp_times = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"START_DATE" | translate}}</label><br>
            <input title="{{'START_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="exposure.start | date:'yyyy-MM-dd'" (ngModelChange)="$event?exposure.start = $event:null" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1">
            <label class="font-bold mb-1">{{"END_DATE" | translate}}</label>
            <div class="flex flex-row">
              <div class="w-2/3">
                <input title="{{'END_DATE' | translate}}" [disabled]="exposure.end_not_defined==true" class="w-full border border-gray-300 p-2" type="date" [ngModel]="exposure.end | date:'yyyy-MM-dd'" (ngModelChange)="$event ? exposure.end = $event : null" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="flex items-center space-x-2 ml-4 w-1/3">
                <input title="{{'END_DATE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [ngModel]="exposure.end_not_defined" (ngModelChange)="exposure.end_not_defined = $event" [ngModelOptions]="{standalone: true}">
                <label class="text-sm mr-1">{{"END_NOT_DEFINED" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Form Container -->
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <!-- Conditional Message -->
        <div *ngIf="check() && hPhraseMessages && hPhraseMessages.length > 0" class="mb-4 p-4 border border-red-600 bg-red-100 text-red-600 font-bold rounded flex items-start">
          <svg class="w-6 h-6 mr-2 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01M12 5.5a7.5 7.5 0 11-7.5 7.5 7.5 7.5 0 017.5-7.5z"></path>
          </svg>
          <div>
            <p class="mb-2">{{ "EXPOSURE_MESSAGE" | translate }}</p>
            <ul class="list-disc list-inside">
              <li *ngFor="let hPhraseMessage of hPhraseMessages" class="mr-2">{{ hPhraseMessage.message }}</li>
            </ul>
          </div>
        </div>


        <div class="mb-4">
          <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
          <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="exposure.comments" (ngModelChange)="exposure.comments = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
        </div>

        <div class="mb-4">
          <label class="font-bold mb-1">{{"OTHER_RISK_SOURCE" | translate}}:</label><br>
          <textarea title="{{'OTHER_RISK_SOURCE' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="exposure.risk_source" (ngModelChange)="exposure.risk_source = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
        </div>

        <div class="mb-4">
          <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}</label><br>
          <div *ngFor="let file of exposure.file_id?.split(',')" >
              <div *ngIf="exposure.file_id != ''" class="flex flex-row items-center">
                <a (click)="openFileInNewWindow(file)" class="cursor-pointer flex items-center">
                    <img class="w-6 h-10 mr-2" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{file.File_name}}"/>
                    <span class="text-black mr-2">{{exposure.file_name}}</span>
                </a>
                <a (click)="fileRemove()">
                  <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                      21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                      5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                      3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                      stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </div>
          </div>
          <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" accept=".pdf" (change)="incomingfile($event)"/>
        </div>
      </div>
      <div class="flex flex-row justify-center mb-20 mt-4">
        <button class="button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
          <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'REMOVE' | translate }}</span>
        </button>
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>


