import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { SupportService } from 'src/app/services/support.service';
import { AuthService } from 'src/app/auth/auth.service';
import { PdfService } from 'src/app/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { take,concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
import { ModalSelectSubstitutionProductComponent } from 'src/app/modals/modal-select-substitution-product/modal-select-substitution-product.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-edit-substitution',
  templateUrl: './edit-substitution.component.html',
  styleUrls: ['./edit-substitution.component.css']
})
export class EditSubstitutionComponent implements OnInit {
  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  loadingButton: boolean = false;
  organisation: any = null;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  substitution: any = null;
  prev_substitution: any = null;
  subscribesToSds: any;
  subscribesToGrb: any;
  substitutionId: any = null;
  alt_exists: boolean = false;
  alt_not_exists: boolean = false;
  checkbox_endusage: boolean = false;
  checkbox_deponi: boolean = false;
  checkbox_other: boolean = false;
  checkbox_keep: boolean = false;
  checkbox_outphase_alt1: boolean = false;
  checkbox_outphase_alt2: boolean = false;
  checkbox_outphase_alt3: boolean = false;
  tempWorksteps: any = [];
  pictures: any = [];
  issuer: any = null;
  picture: any = null;
  pictures2: any = [];
  picturenames: any = [];
  product: any = null;
  pictureHeight = 200;
  pictureWidth = 260;
  substitutionfile: any;
  substitutionimage: any;
  filenames: any = [];
  substitutionimages: any = [];
  copyThisTubstitution:boolean=false;
  substitutionDeps;
  substitutionDepsItemAll:boolean=false;
  depActive:boolean=false;
  innerObservable;
  departFullNameString;
  checkbox_outphase_alt2_activated: boolean = false;
  markAsDone: boolean = false;

  substitutionSearch = {
    all:""
  };

  ngAfterViewInit(): void {
    $('#substitutiontable').tablesort();
  }

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private substitutionService: SubstitutionService,
    private supportService: SupportService,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private uploadService: FileUploadService,
    private pdfService: PdfService) { }

  ngOnInit(): void {
    this.issuer={
      'first_name':'',
      'last_name':''
    }
    this.loadingData = true;

    this.route.params.pipe(take(1)).subscribe(params => {
      this.substitutionId = params['id'];
    });

    if(localStorage.getItem('copysubstitution', )=='set') {
      this.copyThisTubstitution=true;
    } else {
      this.copyThisTubstitution=false;
    }

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.getAllDepartments();
    this.getSubstitution();

    // if(this.copyThisTubstitution==true) {
      this.getDepartmentsOfProduct();
    // }

    this.checkbox_outphase_alt2_activated_fn();
    if ( this.substitution && this.substitution.checkbox_keep == 1 ||
        this.substitution.checkbox_outphase_alt1 == 1 ||
        this.substitution.checkbox_outphase_alt2 == 1 ||
        this.substitution.checkbox_outphase_alt3 == 1 ) {
        this.markAsDone = true;
    }

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      localStorage.setItem('departmentchanged','set');
      this.ngOnInit();
    })
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.substitution) != JSON.stringify(this.prev_substitution);
  }

  cancel() {
    Swal.fire({
      title: this.translate.instant('SAVE') + "?",
      text: this.translate.instant('SAVE_POST'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('NO'),
      confirmButtonText: this.translate.instant('YES')
    }).then((result) => {
      if (result.isConfirmed) {
        this.submit();
        this.router.navigate(['/substitution']);
      } else {
        if (this.utilService.previousSelectedDepartmentIsValid('substitution') == true) {
          const previousSelectedDepartment = localStorage.getItem('previousSelectedDepartment');
          if (previousSelectedDepartment) {
            const { department, timestamp, page } = JSON.parse(previousSelectedDepartment);
            this.organisationService.changeDepartmentById(department.id);
            localStorage.setItem('selectedDepartment', JSON.stringify(department));
            setTimeout(() => {
              this.router.navigate(['/substitution']);
            }, 1000);
          } else {
            localStorage.removeItem('previousSelectedDepartment');
            this.router.navigate(['/substitution']);
          }
        } else {
          localStorage.removeItem('previousSelectedDepartment');
          this.router.navigate(['/substitution']);
        }
      }
    })
  }

  getSubstitution() {

    this.substitution = JSON.parse(localStorage.getItem('substitution', ));
    if (this.substitution != null) {

      if (!this.substitution.created_date)
      {
        this.substitution.created_date = new Date();
      }
      else
      {
        this.substitution.created_date = Date.parse(this.substitution.created_date);
      }

      this.substitution.updated_date = new Date();
      if (this.substitution.alt_exists==1)
      {
        this.alt_exists = true;
        this.alt_not_exists = false;
      }
      else
      {
        this.alt_not_exists = true;
        this.alt_exists = false;
      }
      this.checkbox_endusage = this.substitution.checkbox_endusage==1?true:false;
      this.checkbox_deponi = this.substitution.checkbox_deponi==1?true:false;
      this.checkbox_other = this.substitution.checkbox_other==1?true:false;
      this.checkbox_keep = this.substitution.checkbox_keep==1?true:false;
      this.checkbox_outphase_alt1 = this.substitution.checkbox_outphase_alt1==1?true:false;
      this.checkbox_outphase_alt2 = this.substitution.checkbox_outphase_alt2==1?true:false;
      this.checkbox_outphase_alt3 = this.substitution.checkbox_outphase_alt3==1?true:false;
      this.substitution.products = [];
      this.loadTableData();
    }
  }

  loadTableData() {

    this.userService.getSingleUser(this.substitution.issuer);
    this.userService.getCurrentSingleUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        this.issuer = user;
      },
      error: error => {
        this.issuer = null;
        this.loadingData = false;
      }
    })
    if (this.substitution && this.substitution.substitutionimages)
    {
      let unique = false;
      if (localStorage.getItem("departmentchanged") != "set") {
        for (var i = 0; i < this.substitution.substitutionimages.length; i++) {
          this.innerObservable = of(
            this.substitutionService.getFileAsTrustedResourceUrl(
              this.substitution.substitutionimages[i].file_id,
              this.substitution.substitutionimages[i].File_name
            )
          );
          if (unique == false) {
            this.openPicture(
              this.substitution.substitutionimages[i].file_id,
              this.substitution.substitutionimages[i].File_name
            );
            unique = true;
          }
        }
        localStorage.removeItem("departmentchanged2");
      }
    }

    this.substitutionService.getSingleProductAny(this.substitution.product_id);
    this.substitutionService.getSubstitutionProductDataListener().pipe(take(1)).subscribe({
      next: product => {

        if (product == null) {
          this.loadingData = false;
        }
        else {

          this.product = product;
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })

    this.substitution.checkbox = this.substitution.checkbox_endusage==1?true:false;

    var counter = 0;
    if (this.substitution && this.substitution.worksteps)
    {
      // Filter out worksteps without a product_id
      this.substitution.worksteps = this.substitution.worksteps.filter(workstep => workstep.product_id);

      for (var j = 0; j < this.substitution.worksteps.length; j++)
      {
        if (this.substitution.worksteps[j].product_id)
        {
          this.substitutionService.getSingleWorkstepProduct(this.substitution.worksteps[j].product_id);
        }
      }
      this.prev_substitution = _.cloneDeep(this.substitution);
    }

    this.reveiveProductMsg(counter);

    this.loadingData = false;
  }

  reveiveProductMsg(counter) {
    this.substitutionService.getSubstitutionWorkstepProductDataListener().pipe(take(1)).subscribe({
      next: (product2) => {

        if (this.substitution && this.substitution.worksteps) {
          if(product2&&product2.product_id!=this.substitution.product_id){
            if (counter < this.substitution.worksteps.length) {
              this.tempWorksteps[counter] = product2;
              counter = counter + 1
              this.reveiveProductMsg(counter);
          }
        }else{
            //counter = counter + 1
            this.reveiveProductMsg(counter);
          }
      }


      },
      error: error => {
        console.log("Error: ", error);
      }
    });

    // As it is asynchronous I cant be sure of what order the database calls arrive
    if(this.tempWorksteps&&this.substitution.worksteps){
      for(var n = 0;n<this.tempWorksteps.length;n++){
        for(var m = 0;m<this.substitution.worksteps.length;m++){
          if(this.substitution.worksteps[m].product_id==this.tempWorksteps[n].product_id){
            this.substitution.worksteps[m].product=this.tempWorksteps[n];
            this.substitution.worksteps[m].product.name = this.tempWorksteps[n].product_name;
          }
        }
      }
    }
  }

  incomingfile(event) {
    this.substitutionfile = event.target.files[0];

    if (this.substitutionfile != null) {

      this.uploadService.upload(this.substitutionfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.substitutionfile = null;
          }
          else {
            if (!this.substitution.substitutionfiles)
            {
              this.substitution.substitutionfiles = [];
            }
            var file_id = {"file_id": result.filename, "File_name": this.substitutionfile.name,'Comment_id':'-1'};
            this.substitution.substitutionfiles.push(file_id);
            this.filenames.push(this.substitutionfile.name);
          }
        },
        error: error => {
          this.substitutionfile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  incomingimage(event) {

    this.substitutionimage = event.target.files[0];

    if (this.substitutionimage != null) {

      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        this.pictures.push(e.target.result);
      };
      fileReader.readAsDataURL(this.substitutionimage);

      this.uploadService.upload(this.substitutionimage);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.substitutionimage = null;
          }
          else {
            if (!this.substitutionimage.substitutionimages || this.substitutionimage.substitutionimages.length === 0)
            {
              this.substitutionimage.substitutionimages = [];
            }
            var file_id = {"file_id": result.filename};
            this.substitutionimage.substitutionimages.push(file_id);
            this.substitutionimages.push(file_id);
            this.picturenames.push(this.substitutionimage.name);
          }
        },
        error: error => {
          this.substitutionimage = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  getDepartmentsOfProduct(){
    if (this.selectedDepartment&&this.substitution) {
      this.productService.getDepartmentsOfProduct(this.selectedDepartment.id, this.substitution.product_id);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: productdepartments => {
          if (productdepartments == null) {

          }
          else
          {
          setTimeout(() => {
            let itemArray=['FRAGGLAR'];
            productdepartments.forEach((element) => {
              if (element.organisation_id) {
                element.department = this.departFullName(
                  element.organisation_id
                ).text;
                element.parent = this.departFullName(
                  element.organisation_id
                ).parent;
                element.active=false;
              }
            });
            for(var n=0;n<productdepartments.length;n++){

              if(_.countBy(productdepartments[n].department)['/']>1){
                for(var m=0;m<productdepartments.length;m++){
                  if(productdepartments[n].parent==productdepartments[m].organisation_id&&itemArray.indexOf(productdepartments[n].organisation_id)<0){
                    itemArray.push(productdepartments[n].organisation_id);
                    let temp=productdepartments[n];
                    productdepartments.splice(n, 1);
                    productdepartments.splice(m+1, 0, temp);
                  }

                }
              }

            }

            for(var n=0;n<productdepartments.length;n++){
              if(productdepartments[n].parent=='123'){
                let temp=productdepartments[n];
                    productdepartments.splice(n, 1);
                    productdepartments.splice(0, 0, temp);
              }
            }


          }, 100);
            this.substitutionDeps=productdepartments;
            for ( var n=0;n<this.substitutionDeps.length;n++){
              let depObject = this.utilService.getDepartmentObjectFromId(this.substitutionDeps[n].organisation_id, this.selectedOrganisation);
              this.substitutionDeps[n].fullName=this.utilService.departFullName(depObject,this.listAlldepartments);
            }
          }
        },
        error: error => {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
      })
    }
  }

  submit() {
    this.loadingData=true;
    if (this.substitution.status == 0)
    {
      this.substitution.status = 1;
    }

    if (this.selectedDepartment){
      this.substitution.department = this.selectedDepartment.id;
      this.substitution.mainOrganisation = this.selectedDepartment.main_organisation;
    } else {
      this.substitution.department = this.issuer.mainOrganisation;
      this.substitution.mainOrganisation = this.issuer.mainOrganisation;
    }

    if (this.substitution.editActivity == false)
    {
      this.substitutionService.createSubstitution(this.substitution);
      this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
        next: substitution => {

          if (substitution == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData=false;
          }
          else {
            this.loadingData=false;
          }
        },
        error: error => {
          this.loadingData=false;
          Swal.fire("Error", "generiskt fel");
        }
      })
    }
    else
    {
      this.substitutionService.updateSubstitution(this.substitutionId, this.substitution);
      this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
        next: substitution => {

          if (substitution == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData=false;
          }
          else {
            this.loadingData=false;
          }
        },
        error: error => {
          Swal.fire("Error", "generiskt fel");
          this.loadingData=false;
        }
      })
    }
  }

  verification() {
    this.loadingData=true;
    this.substitution.status = 2;

    if (this.selectedDepartment){
      this.substitution.department = this.selectedDepartment.id;
      this.substitution.mainOrganisation = this.selectedDepartment.main_organisation;
    } else {
      this.substitution.department = this.issuer.mainOrganisation;
      this.substitution.mainOrganisation = this.issuer.mainOrganisation;
    }
    this.loadingData=false;
  }

  approveWorkstep(workstep) {
    workstep.approved==1?workstep.approved=0:workstep.approved=1;
    if(workstep.approved==1 && this.substitution.worksteps){
      for (var i = 0; i < this.substitution.worksteps.length; i++) {
        if (this.substitution.worksteps[i]!=workstep) {
          this.substitution.worksteps[i].approved = 0;
        }
      }
    }
  }

  approve() {
    this.loadingData=true;
    this.substitution.status = 4;

    if (this.selectedDepartment){
      this.substitution.department = this.selectedDepartment.id;
      this.substitution.mainOrganisation = this.selectedDepartment.main_organisation;
    } else {
      this.substitution.department = this.issuer.mainOrganisation;
      this.substitution.mainOrganisation = this.issuer.mainOrganisation;
    }

    if (this.substitution.editActivity == true)
    {
      this.substitutionService.updateSubstitution(this.substitutionId, this.substitution);
      this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
        next: substitution => {

          if (substitution == null) {
            this.loadingData=false;
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.loadingData=false;
            this.router.navigate(['/substitution']);
          }
        },
        error: error => {
          this.loadingData=false;
          Swal.fire("Error", "generiskt fel");
        }
      })
    }
  }

  remove() {
    Swal.fire({

      title: this.translate.instant("REMOVE") + "?",
      input: 'text',
      html: this.translate.instant("CONFIRM_REMOVE_SUBSTITUTION") + "<br><br><div align='left'></div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true,
      inputPlaceholder: this.translate.instant("COMMENT")
    }).then((result) => {
      if (result.isConfirmed) {
        let department = "";
        this.substitution.department_id&&this.substitution.department_id!=""?
          department=this.substitution.department_id:
          department=this.substitution.subdepartmentid;
        this.loadingData=true;
        this.substitutionService.deleteDepartmentSubstitution(this.substitutionId,department);
        this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
          next: substitution => {

            if (substitution == null) {
              Swal.fire("Error", "generiskt fel");
              this.loadingData=false;
            }
            else {
              this.loadingData=false;
              this.router.navigate(['/substitution']);
            }
          },
          error: error => {
            this.loadingData=false;
            Swal.fire("Error", "generiskt fel");
          }
        })
      }
    });
  }

  addWorkstep() {
    localStorage.setItem('substitution', JSON.stringify(this.substitution));
    this.router.navigate(['/substitutionworkstep', 0]);
  }

  workstepSelected(workstep, index) {
    localStorage.setItem('substitution', JSON.stringify(this.substitution));
    this.router.navigate(['/substitutionworkstep', index+1]);
  }

  editWorksteps(workstep) {
    localStorage.setItem('substitution', JSON.stringify(this.substitution));
    this.router.navigate(['/substitutionworkstep','all']);
  }

  fileRemove(index) {
    this.substitution.substitutionfiles.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  pictureRemove(index) {
    // this.substitution.substitutionimages is the list when item first is loaded
    // this.substitutionimages is the items added
    if ( this.substitution.substitutionimages.length <= index) {
      let index2 = index - this.substitution.substitutionimages.length;
      this.substitutionimages.splice(index2, 1);
      this.pictures.splice(index, 1);
    } else {
      this.substitution.substitutionimages.splice(index, 1);
      this.pictures.splice(index, 1);
    }


  }

  onToggleAltExists(event) {
    this.substitution.alt_exists = 0;
    if (event.target.checked == true)
    {
      this.substitution.alt_exists = 1;
      this.alt_not_exists = false;
    }
  }

  onToggleAltNotExists(event) {
    this.substitution.alt_exists = 0;
    if (event.target.checked == true)
    {
      this.alt_exists = false;
    }
  }

  onToggleCheckboxesKeep(event) {
    this.substitution.checkbox_keep = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_keep = 1;
      this.substitution.checkbox_outphase_alt1 = 0;
      this.substitution.checkbox_outphase_alt2 = 0;
      this.substitution.checkbox_outphase_alt3 = 0;

      this.checkbox_outphase_alt1 = false;
      this.checkbox_outphase_alt2 = false;
      this.checkbox_outphase_alt3 = false;
      this.markAsDone = true;
    }
  }

  onToggleCheckboxesAlt1(event) {
    this.substitution.checkbox_outphase_alt1 = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_keep = 0;
      this.substitution.checkbox_outphase_alt1 = 1;
      this.substitution.checkbox_outphase_alt2 = 0;
      this.substitution.checkbox_outphase_alt3 = 0;

      this.checkbox_keep = false;
      this.checkbox_outphase_alt2 = false;
      this.checkbox_outphase_alt3 = false;
      this.markAsDone = true;
    }
  }

  onToggleCheckboxesAlt2(event) {
    this.substitution.checkbox_outphase_alt2 = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_keep = 0;
      this.substitution.checkbox_outphase_alt1 = 0;
      this.substitution.checkbox_outphase_alt2 = 1;
      this.substitution.checkbox_outphase_alt3 = 0;

      this.checkbox_keep = false;
      this.checkbox_outphase_alt1 = false;
      this.checkbox_outphase_alt3 = false;
      this.markAsDone = true;
    }
  }

  onToggleCheckboxesAlt3(event) {
    this.substitution.checkbox_outphase_alt3 = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_keep = 0;
      this.substitution.checkbox_outphase_alt1 = 0;
      this.substitution.checkbox_outphase_alt2 = 0;
      this.substitution.checkbox_outphase_alt3 = 1;

      this.checkbox_keep = false;
      this.checkbox_outphase_alt1 = false;
      this.checkbox_outphase_alt2 = false;
      this.markAsDone = true;
    }
  }

  onToggleCheckboxUsage(event) {
    this.substitution.checkbox_endusage = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_endusage = 1;
      this.substitution.checkbox_deponi = 0;
      this.substitution.checkbox_other = 0;

      this.checkbox_deponi = false;
      this.checkbox_other = false;
    }
  }

  onToggleCheckboxDeponi(event) {
    this.substitution.checkbox_deponi = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_endusage = 0;
      this.substitution.checkbox_deponi = 1;
      this.substitution.checkbox_other = 0;

      this.checkbox_endusage = false;
      this.checkbox_other = false;
    }
  }

  onToggleCheckboxOther(event) {
    this.substitution.checkbox_other = 0;
    if (event.target.checked == true)
    {
      this.substitution.checkbox_endusage = 0;
      this.substitution.checkbox_deponi = 0;
      this.substitution.checkbox_other = 1;

      this.checkbox_endusage = false;
      this.checkbox_deponi = false;
    }
  }

  openPicture(file_id,file_name) {
    this.picture = null;
    this.substitutionService.getSubstitutionDataFileListener().pipe(concatMap( val => {
      this.picture = val;
      this.pictures.push(this.picture);
      this.pictures2.push(this.picture);
      return this.innerObservable
    })).subscribe({
      next: pdfContent => {

      },
      error: error => {
        console.log("Error: ", error);
        this.picture = null;
      }
    });
  }

  editSubstitution() {
    this.router.navigate(['/substitutionedit', this.substitutionId]);
  }

  printSubstitution() {
    window.print();
  }

  goToSubstitutionList() {
    localStorage.removeItem('substitution');
    this.router.navigate(['/substitution']);
  }

  openDocumentInNewWindow(fileId) {
    this.substitutionService.getcDocFileWithId(fileId);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          console.log("Error: Could not get document");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  getNameStatus(status) {
    switch (status) {
      case 0:
          return this.translate.instant("NEW");
          break;
      case 1:
          return this.translate.instant("ONGOING");
          break;
      case 2:
          return this.translate.instant("FOR_REVIEW");
          break;
      case 3:
          return this.translate.instant("SAREQ_WAITING_FOR_PRODUCT");
          break;
      case 4:
          return this.translate.instant("FINISHED");
          break;
      case 5:
          return this.translate.instant("FINISHED_AND_SUBSTITUTED");
          break;
      default:
          break;
    }
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  openSdsInNewWindow(product) {
    let item = product.product_name ? product.product_name : product.sareq_name;
    const filename = product.product_name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    });
  }

  openGrbInNewWindow(product) {
    const filename = product.name + '.pdf';
    let item = product.product_name ? product.product_name : product.sareq_name;

    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    });
  }

  departFullName(department){
    if(this.listAlldepartments&&!_.isString(department)){
      var dep = this.utilService.departFullNameAndParent(department, this.listAlldepartments);
      if(dep.text){
        return dep;
      } else {
        var dep2 = ({text: '', parent: ''});
        return dep2;
      }
    }
    var dep2 = ({text: '', parent: ''});
        return dep2;
  }

  substitutionSelected(substitution) {
    this.router.navigate(['/substitutionitem', substitution.subid]);
  }

  logout() {
    this.authService.logout();
  }

  activateordeactivateitem(index){
    var found=false;
    if(index==-1) {
      this.substitutionDepsItemAll=!this.substitutionDepsItemAll;
      for(var n=0;n<this.substitutionDeps.length;n++) {
        if(this.substitutionDeps[n].organisation_id!=this.substitution.department_id) {
          this.substitutionDeps[n].active=this.substitutionDepsItemAll;
        }
      }
    }
    else {
      if(this.substitutionDeps[index].active==true) {
        this.substitutionDeps[index].active=false;
      } else {
        this.substitutionDeps[index].active=true;
      }
    }

    for(var n=0;n<this.substitutionDeps.length;n++) {
      if(this.substitutionDeps[n].active==true) {
        found=true;
        break;
      }
    }
    if(found==true){
      this.depActive=true;
    }else{
      this.depActive=false;
    }
  }

  addSubstitution(){
    this.loadingData=true;
    var substitutionDepartmentsAndId;
    var substitutionDepartments=[];
    for(var n=0;n<this.substitutionDeps.length;n++){
      if(this.substitutionDeps[n].active){
        if(substitutionDepartments==null){
          substitutionDepartments = this.substitutionDeps[n].organisation_id;
        }else{
          substitutionDepartments.push(this.substitutionDeps[n].organisation_id);
        }
      }
    }
    substitutionDepartmentsAndId={
      'departments':substitutionDepartments,
      'substitutionid':this.substitutionId
    }
    this.substitutionService.copySubstitution(substitutionDepartmentsAndId);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: response => {
        this.loadingData=false;
        this.router.navigate(['/substitution']);
      },
      error: error => {
        this.loadingData=false;
      }
    })

  }

  doSubstitution(){
    let foundondepartment=false;
    for(let n=0;n<this.substitutionDeps.length;n++){
      if(this.substitution.department_id==this.substitutionDeps[n].organisation_id){
        this.substitutionDeps[n].active=true;
        foundondepartment = true;
      }else{
        this.substitutionDeps[n].active=false;
      }
    }
    if(foundondepartment == false){
      this.loadingData = false;
      Swal.fire(
        this.translate.instant("WARNING"),
        this.translate.instant("ERROR_PRODUCT_NOT_IN_DEPARTMENT"),
        "error"
      );
    return;
    }
    this.loadingData = true;
    const modalRef = this.modalService.open(ModalSelectSubstitutionProductComponent);
    for(let n=0;n<this.substitutionDeps.length;n++){
      if(this.substitution.department_id==this.substitutionDeps[n].organisation_id){
        this.substitutionDeps[n].active=true;
      }else{
        this.substitutionDeps[n].active=false;
      }
    }
    modalRef.componentInstance.departments = this.substitution.worksteps;
    modalRef.componentInstance.variousDepartments = this.substitutionDeps;
    var products = [];
    if(this.substitution.worksteps){
      for(var n=0;n<this.substitution.worksteps.length;n++)
      {
        if(products.length==0)
        {
          products = [{'name':this.substitution.worksteps[n].product?.name,'id':this.substitution.worksteps[n].product_id,'approved':this.substitution.worksteps[n].approved}];
        }
        else
        {
          if (this.substitution.worksteps[n].product_id != '')
          {
            products.push({'name':this.substitution.worksteps[n].product.name,'id':this.substitution.worksteps[n].product_id});
          }
        }
      }
    }
    modalRef.componentInstance.substitutionProducts = products;
    modalRef.componentInstance.productName = this.substitution.sareq_name;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((info) => {
      if (info != null) {
        this.substitutionService.substituteProduct(this.substitution.product_id,info.product,info.departments);
        this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
          next: response => {
            if (response == null) {
              this.loadingData=false;
              Swal.fire(
                this.translate.instant("WARNING"),
                this.translate.instant("UNKNOWN_ERROR"),
                "error"
              );
              this.loadingData = false;
              return;
            }
            if (response.reply=="newproductexists") {
              this.loadingData=false;
              Swal.fire(
                this.translate.instant("WARNING"),
                this.translate.instant("NEWPRODUCTALREADYONDEPARTMENT"),
                "error"
              );
              this.loadingData = false;
              return;
            } else if(response.reply=="oldproductdoesnt"){
              this.loadingData=false;
              Swal.fire(
                this.translate.instant("WARNING"),
                this.translate.instant("OLDPRODUCTEXISTS"),
                "error"
              );
              return;
            }else if(response.reply=="ok"){
              // Updatestatusforsubstitution
              this.substitution.status = 5;
              this.substitutionService.updateSubstitutionStatus(this.substitution.product_id, info.departments);
              this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
                next: substitution => {
                  if (substitution == null) {
                    this.loadingData=true;
                    Swal.fire("Error", "generiskt fel");
                  }
                  else {
                    this.loadingData=true;
                    this.router.navigate(['/substitution']);
                  }
                },
                error: error => {
                  this.loadingData=true;
                  Swal.fire("Error", "generiskt fel");
                }
              })

            }else{
              this.loadingData=false;
              Swal.fire(
                this.translate.instant("WARNING"),
                this.translate.instant("UNKNOWN_ERROR"),
                "error"
              );
              return;
            }
          },
          error: error => {
            this.loadingData=false;
          }
        })
      }
    })
    this.loadingData=false;
  }

  createPDF() {
    // Make the hidden content visible
    this.loadingButton=true;
    const content = this.pdfContent.nativeElement;
    content.style.visibility = 'visible';

    // Generate the PDF
    this.pdfService.generatePDF(content).then((file) => {
      // Hide the content again after PDF generation
      content.style.visibility = 'hidden';
      this.uploadService.upload(file);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result==null)
          {
            this.substitution.file_id = null;
            this.loadingButton=false;
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.substitution.file_id = result.filename;
            this.submit();
            this.loadingButton=false;

            var fileURL = URL.createObjectURL(file);
            var opened=window.open(fileURL,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          this.substitution.file_id = null;
          this.loadingButton=false;
          Swal.fire("Error", "generiskt fel");
        }
      })

    }).catch((error) => {
      content.style.visibility = 'hidden';
      this.loadingButton=false;
      Swal.fire("Error", "generiskt fel");
    });
  }

  approvestatus(approve){
    return this.translate.instant('APPROVED2');
  }

  createPDFAndSubmit(){
    if (this.substitution.substitutionimages == undefined)
    {
      this.substitution.substitutionimages = [];
    }

    if ( this.substitutionimages && this.substitutionimages.length > 0) {
      this.substitution.substitutionimages.push(...this.substitutionimages);
    } else {

    }
    // Make the hidden content visible
    this.loadingButton=true;
    const content = this.pdfContent.nativeElement;
    content.style.visibility = 'visible';

    // Generate the PDF
    this.pdfService.generatePDF(content).then((file) => {
      // Hide the content again after PDF generation
      content.style.visibility = 'hidden';
      this.uploadService.upload(file);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result==null)
          {
            this.substitution.file_id = null;
            this.loadingButton=false;
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.substitution.file_id = result.filename;
            this.submit();
            this.router.navigate(['/substitution']);
          }
        },
        error: error => {
          this.substitution.file_id = null;
          this.loadingButton=false;
          Swal.fire("Error", "generiskt fel");
        }
      })

    }).catch((error) => {
      content.style.visibility = 'hidden';
      this.loadingButton=false;
      Swal.fire("Error", "generiskt fel");
    });
  }

  openExtSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    let item = product.product_name ? product.product_name : product.sareq_name;

    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    });
  }

  checkbox_outphase_alt2_activated_fn() {
    this.checkbox_outphase_alt2_activated = false;
    if ( this.substitution && this.substitution.worksteps ) {
      for ( let k = 0; k <  this.substitution.worksteps.length; k++ ) {
          if (this.substitution.worksteps[k].approved == 1 && this.substitution.worksteps[k].product?.product_id) {
            this.checkbox_outphase_alt2_activated = true;
         }
      }
    }
  }
}
