<div [hidden]="isDashboardRoot">
  <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="tabDashboard()">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>
</div>
<div class="flex flex-col md:flex-row w-full">
  <div class="w-full px-7">
    <ul class="flex w-full mb-2">
      <li class="mr-1">
          <a (click)="tabDashboard()" [class.active]="isDashboardRoot" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DASHBOARD" | translate}}</a>
      </li>
      <li class="mr-1">
        <a (click)="tabDashboardAnalysis()" [class.active]="isDashboardAnalysis" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DASHBOARD_ANALYSIS" | translate}}</a>
      </li>
      <li class="mr-1">
        <a *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)" (click)="tabDashboardAdmin()" [class.active]="isDashboardAdmin" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DASHBOARD_ADMIN" | translate}}</a>
      </li>
      <li class="mr-1" *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)">
        <a (click)="tabDashboardHistoryStatistics()" [class.active]="isDashboardHistoryStatistics" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DASHBOARD_HISTORY_STATISTICS" | translate}}</a>
      </li>
      <li class="mr-1" *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)">
        <a (click)="tabDashboardYearlyReport()" [class.active]="isDashboardYearlyReport" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DASHBOARD_YEARLY_REPORT" | translate}}</a>
      </li>
    </ul>
    <div *ngIf="isDashboardRoot" class="mb-40 md:mb-20">
      <div *ngIf="currentNews&&currentNews.length > 0" class="flex flex-col md:flex-row">
        <div class="w-full md:w-2/4 rounded overflow-hidden shadow-lg text-black bg-gradient-to-r from-gradientdark to-gradientlight mb-4">
          <div class="flex flex-row justify-between mr-2">
            <div class="px-6 py-4">
              <div *ngIf="currentNews&&currentNews[0].news_header&&!showAllNews" class="text-black font-bold text-xl mb-2">{{currentNews[0].news_header}}</div>
              <p *ngIf="currentNews&&currentNews[0].news_body&&!showAllNews" class="text-gray-700 text-base">
                {{currentNews[0].news_body}}
              </p>
            </div>
            <div class="hidden md:block py-4">
              <button class="bg-white hover:bg-blue-700 text-black font-bold py-2 px-4 w-auto rounded-full truncate text-center">
                <span [hidden]="showAllNews" (click)="showAllNews=true">{{"MORE_NEWS" | translate}}</span>
                <span [hidden]="!showAllNews" (click)="showAllNews=false">{{"HIDE_NEWS" | translate}}</span>
              </button>
            </div>
          </div>
          <div *ngIf="showAllNews" class="flex flex-col p-4 w-full">
            <div *ngFor='let currentNewsItems of currentNews;let i=index'>
              <div class="mb-2">
                <div>
                  <span class="font-bold">{{currentNews[i].news_header}}</span><br>
                  <i class="text-xs cursive">{{currentNews[i].news_date| date: "yyyy-MM-dd"}}</i>
                </div>
                <div>
                  <p>
                    {{currentNews[i].news_body}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-6 py-4 md:ml-4 h-auto w-full md:w-2/4 rounded bg-white overflow-hidden shadow-lg mb-4">
          <p class="font-bold underline">{{"PRODUCTS" | translate}}</p>
          <p>{{"DASHBOARD_MAX_AMOUNT_1" | translate}} <strong class="text-black font-bold">{{nbrProductMaxAmount}}</strong></p>
          <p>{{"DASHBOARD_MAX_AMOUNT_2" | translate}} <strong class="text-black font-bold">{{nbrProductAmountLargerMax}}</strong></p>
          <p class="flex items-center cursor-pointer hover:text-gray" *ngIf="newSDS&&newSDS?.length>0" id="sds-amount-1" (click)="showSDS=!showSDS">{{"SDB_UPDATE" | translate}}
            <sup title="{{'SDB_UPDATED_WITHIN_A_MONTH' | translate}}">
              <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 17V16.9929M12 14.8571C12 11.6429 15 12.3571 15 9.85714C15 8.27919 13.6568 7 12 7C10.6567 7 9.51961 7.84083 9.13733 9"
                stroke="#3498db" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </sup>
            <strong class="text-blue-500 cursor-pointer">: {{newSDS?.length}}</strong>
          </p>
          <ng-container *ngIf='showSDS'>
            <table class="mt-4">
              <ng-container >
                <tr class="hover:bg-gray-200 cursor-pointer" *ngFor='let newSDSItem of newSDS;let i=index'>
                  <td class="px-1 ">&nbsp;&nbsp;</td>
                  <td class="px-1" id="{{'sdsliitem'+i}}" (click)="gotoProduct(newSDSItem.name)">{{newSDSItem.name}}{{' '}}</td>
                  <td class="px-1">{{newSDSItem.date}}</td>
                </tr>
              </ng-container>
            </table>
          </ng-container>

          <div *ngIf="subscribesToRiskAssessment&&(loadingData==false)" id="goto-risk-assessment">
            <p class="font-bold underline mt-6">{{"LOCAL_RISK_ASSESSMENT" | translate}} (LRB)</p>
            <p>{{"STARTED" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoLocalRiskAssessments('1')">{{productsOwnRBOngoing}}</strong>,
              {{"DONE" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoLocalRiskAssessments('2')">{{productsOwnRBDone}}</strong>
            </p>
            <p *ngIf="itemstoold&&itemstoold?.length > 0" (click)="gotoLocalRiskAssessments('3')">{{"OWN_RISK_ASSESSMENTS_DATE" | translate}}: <strong class="text-blue-500 underline cursor-pointer">{{itemstoold?.length}}</strong></p>
          </div>

          <div *ngIf="subscribesToRiskAssessment&&(loadingData==false)" id="goto-activity-risk-assessment">
            <p class="font-bold underline mt-6">{{"ACTIVITY_RISK_ASSESSMENT" | translate}} (ARB)</p>
            <p>{{"STARTED" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoActivityRiskAssessments('1')">{{productsOwnARBOngoing}}</strong>,
              {{"DONE" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoActivityRiskAssessments('2')">{{productsOwnARBDone}}</strong>
            </p>
            <p *ngIf="itemstooldARB&&itemstooldARB?.length > 0">{{"ACTIVITY_RISK_ASSESSMENTS_DATE" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoActivityRiskAssessments('3')">{{itemstooldARB?.length}}</strong></p>
          </div>

          <div *ngIf="subscribesToSubstitution&&(loadingData==false)" id="goto-substitution">
            <p class="font-bold underline mt-6">{{"SUBSTITUTION" | translate}}</p>
            <p>{{"STARTED" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoSubstitution('1')">{{substitutionCountNew+substitutionCountOngoing}}</strong>,
              {{"DONE" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoSubstitution('2')">{{substitutionCountDone}}</strong>
            </p>
            <p>{{"DONE_AND_SUBSTITUTED" | translate}}: <strong class="text-blue-500 underline cursor-pointer" (click)="gotoSubstitution('3')">{{substitutionCountDoneAndSubstituted}}</strong></p>
          </div>
        </div>
      </div>
      <div *ngIf="(currentCompanyFiles?.length > 0) || (currentDepartmentFiles?.length > 0)" class="flex flex-col md:flex-row">
        <div *ngIf="currentCompanyFiles && currentCompanyFiles?.length > 0"  class="flex flex-col bg-white w-full md:w-1/2 rounded overflow-hidden shadow-lg mb-4 p-7">
          <span class="text-2xl font-bold">{{"COMPANY_INFO" | translate}}</span>
          <div class="mt-4" *ngIf="isCompanyDoc">
            <div *ngFor="let file of currentCompanyFiles; let i = index">
              <a class="flex flex-row items-center cursor-pointer text-black" (click)="openDocumentInNewWindow(file.id)" id="company-file">
                <img class="w-6 h-6 mr-1 cursor-pointer" title="icon" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                {{file.filename}}
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="currentDepartmentFiles && currentDepartmentFiles?.length > 0"  class="flex flex-col bg-white w-full md:w-1/2  md:ml-4 rounded overflow-hidden shadow-lg mb-4 p-7">
          <span class="text-2xl font-bold">{{"DEPARTMENT_INFORMATION" | translate}}</span>
          <span class="mt-2 break-all" *ngIf="selectedDepartment&&selectedDepartment.misc != null">{{selectedDepartment.misc}}</span>
          <div class="mt-4">
            <div *ngIf="currentDepartmentFiles?.length > 0">
              <span *ngFor="let file of currentDepartmentFiles; let i = index">
                <a class="flex flex-row cursor-pointer text-black" (click)="openDocumentInNewWindowDepartment(file.id)">
                  <img class="w-6 h-6 mr-1 cursor-pointer" title="icon" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                  {{file.filename}}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isDashboardAnalysis&&subscribesToFreemium==false" class="mb-40 md:mb-20">
      <div *ngIf="loadingData == true" class="flex justify-center items-center mt-4 mb-4">
        <div class="loader"></div>
      </div>
      <div *ngIf="loadingData == false">
        <div *ngIf="(productsCount > 0)"  class="flex flex-col md:flex-row">
          <div class="flex flex-col items-center bg-white w-full rounded overflow-hidden shadow-lg mb-4 p-7" [ngClass]="{'md:w-1/2': groupInfo && groupInfo.length > 0}">
            <span class="text-lg font-bold">{{"RISK_LEVEL" | translate}}</span>
            <div class="flex flex-col items-center mt-4" *ngIf="isRiskDiag">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="riskPrioDiagramChartConfig"
                id = "highchart-riskpriodiagram"
              ></highcharts-chart>
            </div>
          </div>
          <div *ngIf="groupInfo&&groupInfo?.length>0" class="flex flex-col items-center bg-white w-full md:w-1/2 rounded overflow-hidden shadow-lg mb-4 p-7 md:ml-4">
            <span class="text-lg font-bold">{{"PRODUCT_GROUP" | translate}}</span>
            <div class="flex flex-col items-center mt-4">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="productgroupDiagramChartConfig"
                id = "highchart-productgroupdiagram"
              ></highcharts-chart>
            </div>
          </div>
        </div>
        <div *ngIf="subscribesToFreemium==false && productsCount > 0 && this.restriktionList.length > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white w-full p-7 rounded overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"PRODUCTS_RESTRICTIONLIST" | translate}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="restrictionDiagramChartConfig"
              class="w-full"
              id = "highchart-restrictiondiagram"
            ></highcharts-chart>
          </div>
        </div>
        <div *ngIf="productsCount > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white w-full md:w-1/2 p-7 rounded overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"SUPPLIERS" | translate}}{{" ( "}}{{"TOP" | translate}}{{" "}}{{(productsByIdLength-1)}}{{" ) "}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="supplierDiagramChartConfig"
              id = "highchart-supplierdiagram"
            ></highcharts-chart>
          </div>
          <div class="flex flex-col items-center bg-white w-full md:w-1/2 p-7 ml-4 rounded overflow-hidden shadow-lg mt-6 md:mt-0">
            <table class="table-auto bg-white mb-4">
              <thead class="bg-gray-200">
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"SUPPLIER" | translate}}</th>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"NUMBER_OF" | translate}}{{' '}}{{"PRODUCTS" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let supplier of productsById;let i=index">
                  <tr *ngIf="showAllSuppliersInTable||!showAllSuppliersInTable&&i<showSupplierAmountInTable" (click)="tableClickSupplier(supplier)">
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{supplier.name}}</td>
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{supplier.count}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <button class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="showAllSuppliersInTable==true"(click)="showAllSuppliersInTable=!showAllSuppliersInTable">{{"SHOW_ALL" | translate}}{{' '}}{{productsById?.length}}</button>
            <button class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="!showAllSuppliersInTable==true"(click)="showAllSuppliersInTable=!showAllSuppliersInTable">{{"SHOW_10" | translate}}</button>
          </div>
        </div>
        <div *ngIf="styrenproducts&&subscribesToStyren && styrenproducts?.length > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white w-full md:w-1/2 p-7 ml-4 rounded overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"SUPPLIERS_STYREN" | translate}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="styrenDiagramChartConfig"
              id = "highchart-styrendiagram"
            ></highcharts-chart>
          </div>
          <div class="flex flex-col items-center bg-white w-full md:w-1/2 p-7 ml-4 rounded overflow-hidden shadow-lg mt-6 md:mt-0">
            <table *ngIf="showStyrenSupplier==true" class="table-auto mb-4">
              <thead class="bg-gray-200">
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"SUPPLIER" | translate}}</th>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"NUMBER_OF" | translate}}{{' '}}{{"PRODUCTS" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let styren of styrensupplierarray;let i=index">
                  <tr *ngIf="showAllStyrenInTable||!showAllStyrenInTable&&i<showStyrenAmountInTable" (click)="tableClickSupplier(styren)">
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{styren.supplier_name}}</td>
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{styren.count}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <table *ngIf="showStyrenSupplier==false" class="table-auto mb-4">
              <thead class="bg-gray-200">
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"NAME" | translate}}</th>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"DEPARTMENT" | translate}}{{' '}}{{"PRODUCTS" | translate}}</th>
                  <th class="px-6 py-3 border-b border-gray-200 text-gray-600 font-bold uppercase text-left">{{"ANNUAL_CONSUMPTION" | translate}}{{' '}}{{"PRODUCTS" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let styren of styrenproducts;let i=index">
                  <tr *ngIf="showAllStyrenProductInTable||!showAllStyrenProductInTable&&i<showStyrenProductAmountInTable" (click)="gotoStyrenProduct(styren.sareq_name)">
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{styren.sareq_name}}</td>
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{styren.org_name}}</td>
                    <td class="px-6 py-1 border-b border-gray text-sm text-black">{{styren.annual}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <button *ngIf="showStyrenSupplier&&styrensupplierarray&&styrensupplierarray?.length>10" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="showAllStyrenInTable==true"(click)="showAllStyrenInTable=!showAllStyrenInTable">{{"SHOW_ALL" | translate}}{{' '}}{{styrensupplierarray?.length}}</button>
            <button *ngIf="showStyrenSupplier&&styrensupplierarray&&styrensupplierarray?.length>10" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="!showAllStyrenInTable==true"(click)="showAllStyrenInTable=!showAllStyrenInTable">{{"SHOW_10" | translate}}</button>
            <button *ngIf="!showStyrenSupplier&&styrenproducts&&styrenproducts?.length>10" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="showAllStyrenProductInTable==true"(click)="showAllStyrenProductInTable=!showAllStyrenProductInTable">{{"SHOW_ALL" | translate}}{{' '}}{{styrenproducts?.length}}</button>
            <button *ngIf="!showStyrenSupplier&&styrenproducts&&styrenproducts?.length>10" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" [hidden]="!showAllStyrenProductInTable==true"(click)="showAllStyrenProductInTable=!showAllStyrenProductInTable">{{"SHOW_10" | translate}}</button>
            <button *ngIf="showStyrenSupplier" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" (click)="showStyrenSupplier=false">{{"SHOW_PRODUCTS" | translate}}{{' '}}</button>
            <button *ngIf="!showStyrenSupplier" class="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full m-4" (click)="showStyrenSupplier=true">{{"SHOW_SUPPLIERS" | translate}}{{' '}}</button>
          </div>´
        </div>
        <div *ngIf="subscribesToFreemium==false && CMRADiagramIsFetched && productsCount > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white p-7 rounded w-full overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"CMRA_CATEGORY" | translate}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="CMRADiagramChartConfig"
              class="w-full"
              id = "highchart-cmradiagram"
            ></highcharts-chart>
          </div>
        </div>
        <div *ngIf="subscribesToFreemium==false && subscribesToSpecialDemands && productsCount > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white p-7 rounded w-full overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"SPECIAL_REQUIREMENTS" | translate}} {{"ALLERGY" | translate}} (AFS 2023:10 §§4-12)</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="specialDiagramChartConfig"
              [(update)]="updateFlag"
              class="w-full"
              id = "highchart-specialdiagram"
            ></highcharts-chart>
          </div>
        </div>
        <div *ngIf="productsCount > 0" class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col items-center bg-white p-7 rounded w-full overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"PICTOGRAM" | translate}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="pictogramChartConfig"
              [(update)]="updateFlagClp"
              class="w-full"
              id = "highchart-pictogramdiagram"
            ></highcharts-chart>
          </div>
        </div>
        <div *ngIf="subscribesToReportEnvironment && productsCount > 0 && ecoCounter > 0" class="flex flex-col md:flex-row mb-10">
          <div class="flex flex-col items-center bg-white p-7 rounded w-full overflow-hidden shadow-lg">
            <p class="font-bold text-lg">{{"ECOLABELS" | translate}}</p>
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="environmentDiagramChartConfig"
              class="w-full"
              id = "highchart-environmentdiagram"
            ></highcharts-chart>
          </div>
        </div>
        <div *ngIf="subscribesToReportEnvironment && organisation.id=='1c132210-f5d3-11e7-81c3-af0bc021377f' && !loadingData" class="flex flex-col md:flex-row mb-20">
          <div class="flex flex-col bg-white p-7 rounded w-full overflow-hidden shadow-lg">
            <div class="flex flex-row mb-10 px-7">
              <div class="relative inline-block text-left z-10 mr-10" appDropdown>
                <button type="button" class="text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span class="nav-home-text text-lg">{{historyYear}} ({{'YEAR' | translate}})</span>
                  <svg class="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                  <div class="py-5">
                    <a (click)="updateHistoryYear(2021)" class="dropdown-menu-item" tabindex="1">2021</a>
                    <a (click)="updateHistoryYear(2022)" class="dropdown-menu-item" tabindex="2">2022</a>
                    <a (click)="updateHistoryYear(2023)" class="dropdown-menu-item" tabindex="3">2023</a>
                    <a (click)="updateHistoryYear(2024)" class="dropdown-menu-item" tabindex="4">2024</a>
                    <a (click)="updateHistoryYear(2025)" class="dropdown-menu-item" tabindex="5">2025</a>
                  </div>
                </div>
              </div>
              <div class="relative inline-block text-left z-10" appDropdown>
                <button type="button" class="text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span class="nav-home-text text-lg">{{groupItem}} ({{'PRODUCT_GROUP' | translate}})</span>
                  <svg class="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                  <div class="py-5">
                    <a (click)="productGroupSelected('all')" class="dropdown-menu-item">{{"ALL" | translate}}</a>
                    <span *ngFor="let groupItem of groupInfo">
                      <a (click)="productGroupSelected(groupItem)" class="dropdown-menu-item" tabindex="1">{{ groupItem.name }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center">
              <p class="font-bold text-lg">{{"ECOLABELS" | translate}}</p>
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="historyChart"
                [(update)]="updateFlagClp"
                class="w-full"
                id = "highchart-historydiagram"
              ></highcharts-chart>
              <p class="font-bold text-lg">{{"ECOLABELS" | translate}} (Procent)</p>
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="historyChartProcent"
                [(update)]="updateFlagClp"
                class="w-full"
                id = "highchart-historychartdiagram"
              ></highcharts-chart>
            </div>
            <div class="hidden md:block">
              <div class="flex flex-row">
                <div class="w-3/5">
                  <span class="font-bold text-sm">{{"ECOLABELS" | translate}}:</span>
                  <div class="flex flex-row">
                    <img title="Svanen" class="h-6 w-6 h-full max-w-full" src="/images/loggos/SE_Svanen_A_POS_RGB.png" />
                    <img title="Ecolabel" class="h-6 w-6 h-full max-w-full" src="/images/loggos/ecolabel-logo.png" />
                  </div>
                </div>
                <div class="flex items-center space-x-2 w-1/5 mt-4">
                  <input title="Svanen" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel] ="includedEcoLabels.eco_svanen" (ngModelChange)="includedEcoLabels.eco_svanen=$event" (change)="onToggleEcoLabelSettings()">
                  <label>Svanen (bild)</label>
                </div>
                <div class="flex items-center space-x-2 w-1/5 mt-4">
                  <input title="Ecolabel" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel] ="includedEcoLabels.eco_eu" (ngModelChange)="includedEcoLabels.eco_eu=$event" (change)="onToggleEcoLabelSettings()">
                  <label>EU-Blomman</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isDashboardAdmin" class="mb-40 md:mb-20">
      <app-dashboard-admin></app-dashboard-admin>
    </div>
    <div *ngIf="isDashboardHistoryStatistics" class="mb-40 md:mb-20">
      <app-dashboard-history-statistics></app-dashboard-history-statistics>
    </div>
    <div *ngIf="isDashboardYearlyReport" class="mb-40 md:mb-20">
      <app-dashboard-yearly-report></app-dashboard-yearly-report>
    </div>
  </div>
</div>

