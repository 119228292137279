<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex justify-between">
      <div class="flex mt-4">
        <p *ngIf="currentUser && ((currentUser.is_local_assignee
          || currentUser.is_local_admin
          || currentUser.is_admin
          || currentUser.is_useradmin)
          || currentUser.is_super_user)" class="flex items-center space-x-2 mr-2">
            <img class="h-8 w-8 mr-2 cursor-pointer" tooltips tooltip-smart="true" title="{{'EDIT_ALL_CHEMICAL' | translate}}" (click)="goToEditAllProducts()" src="/images/icons/edit-svgrepo-com.svg"/>
            </p>
            <p class="flex flex-row items-center text-xs md:text-base space-x-2" *ngIf="products">
                {{"REGISTERED_CHEMICALS" | translate}}: {{products.length}}
                <a class="flex flex-row items-center" *ngIf="productsOverride">({{productsOverride?.length}})
                <button type="button" *ngIf="searchFilter[0]" class="button-filter-yellow ml-4" (click)="clearDashboardSearch()" id="buttonProductList1" >
                    <span class="text-lg">{{"FILTERED" | translate}}: {{searchFilter.Name}}</span>
                </button>
                </a>
            </p>
            <p class="flex flex-row items-center hidden md:block space-x-2" *ngIf="!products">
                {{"REGISTERED_CHEMICALS" | translate}}: 0
                <a class="flex flex-row items-center" *ngIf="productsOverride">({{productsOverride?.length}})
                <button type="button" *ngIf="searchFilter[0]" class="button-filter-yellow ml-4" (click)="clearDashboardSearch()" id="buttonProductList2" >
                    <span class="text-lg">{{"FILTERED" | translate}}: {{searchFilter.Name}}</span>
                </button>
                </a>
            </p>
            <p class="flex flex-row items-center hidden md:block space-x-2" *ngIf="!products">{{"REGISTERED_CHEMICALS" | translate}}: 0
            <a class="flex flex-row items-center" *ngIf="productsOverride">({{productsOverride?.length}})
                <button type="button" *ngIf="searchFilter[0]" class="button-filter-yellow ml-4" (click)="clearDashboardSearch()" >
                    <span class="text-lg">{{"FILTERED" | translate}}: {{searchFilter.Name}}</span>
                </button>
            </a>
            </p>
        </div>
    <div>

    </div>
    <button *ngIf="currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin || currentUser.is_admin ||
                currentUser.is_useradmin) || currentUser.is_super_user)"
                (click)="goToAddProduct()" class="button-cdocblue mt-4" (click)="goToAddProduct()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{"ADD_CHEMICAL" | translate}}</span>
    </button>
</div>

<div *ngIf="loadingData==false" class="mt-2 px-4 py-2 mb-20">
  <div *ngIf="!currentUser.is_printer" class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="products" st-safe-src="products" id="producttable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header" colspan="12">
                        <div class="flex justify-between">
                            <div class="flex flex-col md:flex-row flex-items-center w-full">
                                <div class="mb-4 mt-4">
                                    <input st-search="" type="search" [(ngModel)]="productSearch.all" id="searchProductList1" (input)="filterSearchItems()"
                                        class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                </div>
                                <div class="flex items-center space-x-2 mt-4 mb-4 md:m-4" id="checkboxProductList2" *ngIf="(!currentUser.is_guest)">
                                    <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" id="checkboxProductList2checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                    <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                                    <sup *ngIf="subscribesToRiskAssessment"><img title="{{'SHOW_SUBDEPARTMENTS_INFO' | translate}}" class="w-4 h-4 cursor-pointer" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" /></sup>
                                </div>
                            </div>
                            <div class="flex flex-row justify-end py-4 mr-4">
                                <a *ngIf="subscribesToQRLogin && !showSubdepartments && !currentUser.is_guest" (click)="orderLabelsForQR('ADummyProduct')" title="{{'PRINT_LABELS' | translate}}" class="dropdown-menu-help-item ml-1">
                                    <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 16.75H16C15.8011 16.75 15.6103 16.671 15.4697 16.5303C15.329 16.3897 15.25 16.1989 15.25 16C15.25 15.8011 15.329 15.6103 15.4697 15.4697C15.6103 15.329 15.8011 15.25 16 15.25H18C18.3315 15.25 18.6495 15.1183 18.8839 14.8839C19.1183 14.6495 19.25 14.3315 19.25 14V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H6C5.66848 8.75 5.35054 8.8817 5.11612 9.11612C4.8817 9.35054 4.75 9.66848 4.75 10V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H8C8.19891 15.25 8.38968 15.329 8.53033 15.4697C8.67098 15.6103 8.75 15.8011 8.75 16C8.75 16.1989 8.67098 16.3897 8.53033 16.5303C8.38968 16.671 8.19891 16.75 8 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V10C3.25 9.27065 3.53973 8.57118 4.05546 8.05546C4.57118 7.53973 5.27065 7.25 6 7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V14C20.75 14.7293 20.4603 15.4288 19.9445 15.9445C19.4288 16.4603 18.7293 16.75 18 16.75Z" fill="#000000"/>
                                        <path d="M16 8.75C15.8019 8.74741 15.6126 8.66756 15.4725 8.52747C15.3324 8.38737 15.2526 8.19811 15.25 8V4.75H8.75V8C8.75 8.19891 8.67098 8.38968 8.53033 8.53033C8.38968 8.67098 8.19891 8.75 8 8.75C7.80109 8.75 7.61032 8.67098 7.46967 8.53033C7.32902 8.38968 7.25 8.19891 7.25 8V4.5C7.25 4.16848 7.3817 3.85054 7.61612 3.61612C7.85054 3.3817 8.16848 3.25 8.5 3.25H15.5C15.8315 3.25 16.1495 3.3817 16.3839 3.61612C16.6183 3.85054 16.75 4.16848 16.75 4.5V8C16.7474 8.19811 16.6676 8.38737 16.5275 8.52747C16.3874 8.66756 16.1981 8.74741 16 8.75Z" fill="#000000"/>
                                        <path d="M15.5 20.75H8.5C8.16848 20.75 7.85054 20.6183 7.61612 20.3839C7.3817 20.1495 7.25 19.8315 7.25 19.5V12.5C7.25 12.1685 7.3817 11.8505 7.61612 11.6161C7.85054 11.3817 8.16848 11.25 8.5 11.25H15.5C15.8315 11.25 16.1495 11.3817 16.3839 11.6161C16.6183 11.8505 16.75 12.1685 16.75 12.5V19.5C16.75 19.8315 16.6183 20.1495 16.3839 20.3839C16.1495 20.6183 15.8315 20.75 15.5 20.75ZM8.75 19.25H15.25V12.75H8.75V19.25Z" fill="#000000"/>
                                    </svg>
                                </a>
                                <div  class="relative inline-block text-left dropdown-menu-help-item ml-1" appDropdown>
                                    <button *ngIf="subscribesToLabels && !showSubdepartments && !currentUser.is_guest" title="{{'MENU' | translate}}" id="dropdownBtn" type="button" class="text-black flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                        <svg class="w-7 h-7 mr-2" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#000000"/>
                                            <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#000000"/>
                                            <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#000000"/>
                                        </svg>
                                    </button>
                                        <div id="dropdownMenu" class="hidden dropdown-menu right-10" #dropdownMenuInside>
                                            <div class="py-2">
                                                <a *ngIf="subscribesToLabels && !showSubdepartments && !currentUser.is_guest" (click)="createLabelsDepartmentWord()" class="dropdown-menu-item" tabindex="1">
                                                    {{"CREATE_QR_DEPARTMENT_WORD" | translate}}
                                                </a>
                                                <a *ngIf="subscribesToLabels && !showSubdepartments && !currentUser.is_guest" (click)="createLabelsDepartmentPDF()" class="dropdown-menu-item" tabindex="1">
                                                    {{"CREATE_QR_DEPARTMENT_PDF" | translate}}
                                                </a>
                                            </div>
                                        </div>
                                  </div>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-items-center w-full">
                            <div *ngIf="!currentUser.is_guest" class="flex items-center space-x-2 m-4" id="checkboxProductList1">
                                <input title="{{'SHOW_MORE_SEARCH' | translate}}" id="checkboxProductList1checkbox" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showExtendedProductSearchList" (click)="onToggleShowExtendedProductSearchList()">
                                <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH" | translate}}</span>
                            </div>
                            <div *ngIf="(!currentUser.is_guest)&&subscribesToFreemium==false&&productlist_columns_locked==0" id="checkboxProductList3" class="flex items-center space-x-2 m-4">
                                <input title="{{'CHOOSE_COLUMNS' | translate}}"  type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" id="checkboxProductList3checkbox" name="example" [ngModel]="showSelectColumns" (click)="onToggleShowSelectColumns()">
                                <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                            </div>
                            <div *ngIf="(!currentUser.is_guest)&&subscribesToFreemium==false" id="checkboxProductList5" class="flex items-center space-x-2 m-4">
                              <input title="{{'SHOW_ONLY_EXCLUDED' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" id="checkboxProductList5checkbox" name="example" [ngModel]="excluded" (click)="onToggleShowExcluded()">
                              <span class="text-sm mr-1">{{"SHOW_ONLY_EXCLUDED" | translate}}</span>
                            </div>
                        </div>
                        <div class="flex flex-wrap" *ngIf="showSelectColumns">
                            <button type="button" *ngIf="!extendedProductListSettings[0]" id="columnbutton0" class="button-gray" (click)="onToggleShowExtendedProductListSettings(0, true)" >
                                {{"PICTOGRAM" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[1]" id="columnbutton1" class="button-gray" (click)="onToggleShowExtendedProductListSettings(1, true)" >
                                {{"PROTECTIVE_GEAR" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[2]" id="columnbutton2" class="button-gray" (click)="onToggleShowExtendedProductListSettings(2, true)" >
                                {{"AMOUNT_ON_DEPARTMENT" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[3]" id="columnbutton3" class="button-gray" (click)="onToggleShowExtendedProductListSettings(3, true)" >
                                {{"ANNUAL_CONSUMPTION" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[5]" id="columnbutton4" class="button-gray" (click)="onToggleShowExtendedProductListSettings(5, true)" >
                                {{"DESCRIPTION" | translate}}
                            </button>
                            <button type="button" *ngIf="subscribesToClassification&&!extendedProductListSettings[6]" id="columnbutton5" class="button-gray" (click)="onToggleShowExtendedProductListSettings(6, true)" >
                                {{"PH_CLASSIFICATION" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[7]" id="columnbutton6" class="button-gray" (click)="onToggleShowExtendedProductListSettings(7, true)" >
                                {{"RISK_LEVEL" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[38]" id="columnbutton6" class="button-gray" (click)="onToggleShowExtendedProductListSettings(38, true)" >
                              {{"LOCAL_RISK_LEVEL" | translate}}
                          </button>
                            <button type="button" *ngIf="!extendedProductListSettings[8]" id="columnbutton7" class="button-gray" (click)="onToggleShowExtendedProductListSettings(8, true)" >
                                {{"ARTICLE_NUMBER" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[9]" id="columnbutton8" class="button-gray" (click)="onToggleShowExtendedProductListSettings(9, true)" >
                                {{"PRODUCT_GROUP" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[12] && subscribesToSpecialDemands" id="columnbutton9" class="button-gray" (click)="onToggleShowExtendedProductListSettings(12, true)" >
                                {{"SPECIAL_REQUIREMENTS" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[13] && subscribesToReportEnvironment" id="columnbutton10" class="button-gray" (click)="onToggleShowExtendedProductListSettings(13, true)" >
                                {{"ECOLABELS" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[19]" id="columnbutton11" class="button-gray" (click)="onToggleShowExtendedProductListSettings(19, true)" >
                                {{"OWN_ARTICLE" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[20] && subscribesToTransport" id="columnbutton12" class="button-gray" (click)="onToggleShowExtendedProductListSettings(20, true)" >
                                {{"DANGEROUS_GOODS" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[21]" id="columnbutton13" class="button-gray" (click)="onToggleShowExtendedProductListSettings(21, true)" >
                                {{"DEPARTMENT_INFO" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[22]" id="columnbutton14" class="button-gray" (click)="onToggleShowExtendedProductListSettings(22, true)" >
                                {{"SAFETY_EXT_DATA_SHEET" | translate}}
                            </button>
                            <button *ngIf="subscription_revise_date&&!extendedProductListSettings[24]" id="columnbutton15" class="button-gray" (click)="onToggleShowExtendedProductListSettings(24, true)" >
                                {{"REVISEDATE" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[28]" id="columnbutton16" class="button-gray" (click)="onToggleShowExtendedProductListSettings(28, true)" >
                                {{"OBSERVATION" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[15]" id="columnbutton17" class="button-gray" (click)="onToggleShowExtendedProductListSettings(15, true)" >
                                {{"H_PHRASES" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[16]" id="columnbutton18" class="button-gray" (click)="onToggleShowExtendedProductListSettings(16, true)" >
                                {{"P_PHRASES" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[25]" id="columnbutton19" class="button-gray" (click)="onToggleShowExtendedProductListSettings(25, true)" >
                                CAS
                            </button>
                            <button type="button" *ngIf="subscription_subscription_stamped_SDS==1 && !extendedProductListSettings[27]" id="columnbutton21" class="button-gray" (click)="onToggleShowExtendedProductListSettings(27, true)" >
                                {{"ST_DATE" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[29]" id="columnbutton22" class="button-gray" (click)="onToggleShowExtendedProductListSettings(29, true)" >
                                {{"RESTRICTION_LIST" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[30]" id="columnbutton23" class="button-gray" (click)="onToggleShowExtendedProductListSettings(30, true)" >
                                {{"LAST_CHANGED" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[31]" id="columnbutton24" class="button-gray" (click)="onToggleShowExtendedProductListSettings(31, true)" >
                                {{"SUBSTITUTION" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[32]" id="columnbutton25" class="button-gray" (click)="onToggleShowExtendedProductListSettings(32, true)" >
                                {{"C_PHRASES" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[33]" id="columnbutton26" class="button-gray" (click)="onToggleShowExtendedProductListSettings(33, true)" >
                                {{"ALTERNATIVE_NAME" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[34]" id="columnbutton27" class="button-gray" (click)="onToggleShowExtendedProductListSettings(34, true)" >
                                {{"HGV" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[35]" id="columnbutton28" class="button-gray" (click)="onToggleShowExtendedProductListSettings(35, true)" >
                                {{"CMRA_CATEGORY" | translate}}
                            </button>
                            <button type="button" *ngIf="subscribesToRiskAssessment && !extendedProductListSettings[36]" id="columnbutton29" class="button-gray" (click)="onToggleShowExtendedProductListSettings(36, true)" >
                                {{"FOOD_APPROVED" | translate}}
                            </button>
                            <button type="button" *ngIf="!extendedProductListSettings[37]" id="columnbutton37" class="button-gray" (click)="onToggleShowExtendedProductListSettings(37, true)" >
                                {{"OTHER_DANGERS" | translate}}
                            </button>
                        </div>
                        <div class="flex flex-row">
                            <div class="flex items-center space-x-2 m-4" *ngIf="showSelectColumns">
                                <label>{{"ITEMS_PER_PAGE" | translate}}{{' : '}}
                                <input id="itemsPerPageSetting" class="border border-gray-400 w-15" [disabled]="productlist_columns_locked==1" [(ngModel)]="items_per_page_setting" (ngModelChange)="items_per_page_setting = $event" [ngModelOptions]="{standalone: true}" type="number">
                                </label>
                            </div>
                            <div *ngIf="(!currentUser.is_guest) && showSelectColumns == true && productlist_columns_locked != 1" class="flex items-center space-x-2 m-4">
                                <button title="{{'SAVE_SETTINGS_MESSAGE_1' | translate }} {{'SAVE_SETTINGS_MESSAGE_2' | translate }}" class="button-green" id="button-save-settings" (click)="saveSettings()">{{"SAVE_SETTINGS" | translate }}</button>
                            </div>
                        </div>
                        <div class="flex mt-4" *ngIf="showExtendedProductSearchList">
                            <div class="static inline-block text-left" id="selectRisk" appDropdown>
                                <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"GENERAL_RISK_LEVEL" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let risk of searchRisk" (click)="filterSearch(risk,2)" class="dropdown-menu-item" id="{{'productListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                                </div>
                            </div>
                            <div class="static inline-block text-left z-10 w-50" id="selectPictogram" appDropdown>
                                <button type="button" class="button-gray w-40 mr-2">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"PICTOGRAM" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let pictogram of searchPictogram" (click)="filterSearch(pictogram,1)" data-value="pictogram"
                                        class="dropdown-menu-item" id="{{'productListPictogram'+pictogram}}" tabindex="1">{{ pictogram }} </div>
                                </div>
                            </div>
                            <div class="static inline-block text-left z-10 w-50" id="selectPictogram" appDropdown>
                                <button type="button" class="button-gray w-40 mr-2">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"OTHER_DANGERS" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let warning of searchWarning" (click)="filterSearchWarning(warning)" data-value="warning"
                                        class="dropdown-menu-item" id="{{'productListPictogram'+warning}}" tabindex="1">{{ warning }} </div>
                                </div>
                            </div>
                            <div *ngIf="subscribesToReportEnvironment" class="static inline-block text-left z-10 w-40" id="selectEcoLabel" appDropdown>
                                <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"ECOLABELS" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let ecolabel of searchEcoLabels" (click)="filterSearch(ecolabel,3)" data-value="ecolabel"
                                        class="dropdown-menu-item" id="{{'productListPictogram'+ecolabel}}" tabindex="1">{{ ecolabel }} </div>
                                </div>
                            </div>
                            <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                                <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"SDS" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let SDS of searchSDS" (click)="filterSearch(SDS,5)" data-value="SDS"
                                        class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS}}" tabindex="1">{{SDS | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="subscribesToRiskAssessment" class="static inline-block text-left z-10" id="selectFoodApproved" appDropdown>
                                <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"FOOD_APPROVED" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                                    <div class="py-5" *ngFor="let foodApproved of searchFoodApproved" (click)="filterSearch(foodApproved,7)" data-value="foodApproved"
                                        class="dropdown-menu-item" id="{{'productListfoodapproved'+foodApproved}}" tabindex="1">{{foodApproved | translate }}</div>
                                </div>
                            </div>
                          <div class="mr-2 mt-1">
                            <button type="button" id="buttonProductList3" class="button-yellow" (click)="clearDashboardSearch()">{{"CLEAR" | translate}}</button>
                          </div>
                        </div>
                        <div class="flex mt-4" *ngIf="showExtendedProductSearchList">
                            <div class="inline-block mt-1">
                                <button type="button" id="buttonProductList4" class="button-red" (click)="clearDashboardSearchFields()">{{"CLEAR_ALL_SEARCHFIELDS" | translate}}</button>
                            </div>
                        </div>
                        <div class="flex flex-wrap mt-4" *ngIf="showExtendedProductSearchList">
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList2" (input)="filterSearchItems()" [(ngModel)]="productSearch.name"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList3" (input)="filterSearchItems()" [(ngModel)]="productSearch.supplier_name"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input *ngIf="showSubdepartments!=true" st-search="" type="search" fullTextSearch="false" id="searchProductList4" (input)="filterSearchItems()" [(ngModel)]="productSearch.group_name"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_PRODUCT_GROUP' | translate}} ..." />
                                <input *ngIf="showSubdepartments==true"st-search="" type="search" fullTextSearch="false" id="searchProductList4" (input)="filterSearchItems()" [(ngModel)]="productSearch.group_name_list"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_PRODUCT_GROUP' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList5" (input)="changeArticleSearch()" [(ngModel)]="productSearch.articlenumber"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ARTICLE' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="productSearch.h_phrases"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList7" (input)="changePprases()" [(ngModel)]="productSearch.p_phrases"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_P_PHRASE' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" (input)="filterSearchItems()" [(ngModel)]="productSearch.cas"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList9" (input)="filterSearchItems()" [(ngModel)]="productSearch.c_phrases"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CLASSIFICATION' | translate}} ..." />
                            </div>
                            <div class="mr-2 mt-2">
                                <input st-search="" type="search" fullTextSearch="false" id="searchProductList10" (input)="filterSearchItems()" [(ngModel)]="productSearch.alternative_name"  class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALTERNATIVE_NAME' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th (click)="sortProducts('customerarticle',0)" class="table-row-header"
                        *ngIf="extendedProductListSettings[19]&&(!currentUser.is_guest)"
                        (mouseenter)="showMinus0 = true" (mouseleave)="showMinus0 = false">
                        {{"OWN_ARTICLE" | translate}}
                        <sup><img id="showMinusId0" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(19, false)" *ngIf="showMinus0" class="w-4 h-4"></sup>
                    </th>
                    <th class="table-row-header" (click)="sortProducts('name',1)">{{"NAME" | translate}}</th>
                    <th *ngIf="extendedProductListSettings[33]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus25 = true" (mouseleave)="showMinus25 = false" (click)="sortProducts('alternative_name',25)">
                        {{"ALTERNATIVE_NAME" | translate}}
                        <sup><img id="showMinus25" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(33, false)" *ngIf="showMinus25" class="w-4 h-4"></sup>
                    </th>
                    <th class="table-row-header" (click)="sortProducts('supplier_name',2)">{{"MANUFACTURER" | translate}}</th>
                    <th *ngIf="!currentUser.is_guest||currentUser.is_guest&&showSubdepartments != true" class="table-row-header" (click)="sortProducts('department',3)">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                    <th *ngIf="extendedProductListSettings[0]" class="table-row-header"
                        (mouseenter)="showMinus1 = true" (mouseleave)="showMinus1 = false">
                        {{"PICTOGRAM" | translate}}
                        <sup><img id="showMinusId1" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(0, false)" *ngIf="showMinus1" class="w-4 h-4"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[1]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false">
                        {{"PROTECTIVE_GEAR" | translate}}
                        <sup><img id="showMinusId2" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(1, false)" *ngIf="showMinus2" class="w-4 h-4"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[37]" class="table-row-header"
                        (mouseenter)="showMinus37 = true" (mouseleave)="showMinus37 = false">
                        {{"OTHER_DANGERS" | translate}}
                        <sup><img id="showMinusId37" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(37, false)" *ngIf="showMinus37" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[2]&&!currentUser.is_guest" class="table-row-header"
                        (click)="sortProducts('amount',4)"
                        (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">
                        <span class=" flex items-center">{{"AMOUNT_ON_DEPARTMENT" | translate}}&nbsp;&nbsp;
                          <sup *ngIf="showSubdepartments == true">&nbsp;&nbsp;<img title="{{'AMOUNT_ON_DEPARTMENT_INFO' | translate}}" class="w-4 h-4 cursor-pointer" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" /></sup>
                        </span>
                        <sup><img id="showMinusId3" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(2, false)" *ngIf="showMinus3" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[3]&&(!currentUser.is_guest)" class="table-row-header"
                        (click)="sortProducts('annual_consumption',5)"
                        (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">
                        <span class=" flex items-center">{{"ANNUAL_CONSUMPTION" | translate}}
                          <sup *ngIf="showSubdepartments == true">&nbsp;&nbsp;<img title="{{'ANNUAL_CONSUMPTION_INFO' | translate}}" class="w-4 h-4 cursor-pointer" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" /></sup>
                        </span>
                        <sup><img id="showMinusId4" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(3, false)" *ngIf="showMinus4" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[5]" class="table-row-header"
                        (click)="sortProducts('com_use',6)"
                        (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">
                        {{"DESCRIPTION" | translate}}
                        <sup><img id="showMinusId5" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(5, false)" *ngIf="showMinus5" class="nav-home"></sup>
                    </th>
                    <th *ngIf="subscribesToClassification && extendedProductListSettings[6] &&(!currentUser.is_guest)" class="table-row-header"
                        (click)="sortProducts('classification.name',7)"
                        (mouseenter)="showMinus6 = true" (mouseleave)="showMinus6 = false">
                        {{"PH_CLASSIFICATION" | translate}}
                        <sup><img id="showMinusId6" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(6, false)" *ngIf="showMinus6" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('risk_prio',8)" class="table-row-header"
                        *ngIf="extendedProductListSettings[7]"
                        (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false">
                        {{"GENERAL_RISK_LEVEL" | translate}}
                        <sup><img id="showMinusId7" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(7, false)" *ngIf="showMinus7" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('assessment_risk',30)" class="table-row-header"
                        *ngIf="extendedProductListSettings[38]"
                        (mouseenter)="showMinus38 = true" (mouseleave)="showMinus38 = false">
                        {{"LOCAL_RISK_LEVEL" | translate}}
                        <sup><img id="showMinusId38" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(38, false)" *ngIf="showMinus38" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('articlenumber',9)" class="table-row-header"
                        *ngIf="extendedProductListSettings[8]&&(!currentUser.is_guest)"
                        (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false">
                        {{"ARTICLE_NUMBER" | translate}}
                        <sup><img id="showMinusId8" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(8, false)" *ngIf="showMinus8" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('group_name',10)" class="table-row-header"
                        *ngIf="showSubdepartments!=true&&extendedProductListSettings[9]&&(!currentUser.is_guest)"
                        (mouseenter)="showMinus9 = true" (mouseleave)="showMinus9 = false">
                        {{"PRODUCT_GROUP" | translate}}
                        <sup><img id="showMinusId9" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(9, false)" *ngIf="showMinus9" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('group_name_list',10)" class="table-row-header"
                        *ngIf="showSubdepartments==true&&extendedProductListSettings[9]&&(!currentUser.is_guest)"
                        (mouseenter)="showMinus9 = true" (mouseleave)="showMinus9 = false">
                        {{"PRODUCT_GROUP" | translate}}
                        <sup><img id="showMinusId9" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(9, false)" *ngIf="showMinus9" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[20] && subscribesToTransport &&(!currentUser.is_guest)" class="table-row-header"
                        (click)="sortProducts('transport_un_nbr',11)"
                        (mouseenter)="showMinus10 = true" (mouseleave)="showMinus10 = false">
                        {{"DANGEROUS_GOODS" | translate}}
                        <sup><img id="showMinusId10" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(20, false)" *ngIf="showMinus10" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[12] && subscribesToSpecialDemands &&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus11 = true" (mouseleave)="showMinus11 = false" (click)="sortProducts('',20)">
                        {{"SPECIAL_REQUIREMENTS" | translate}}
                        <sup><img id="showMinusId11" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(12, false)" *ngIf="showMinus11" class="nav-home"></sup>
                    </th>
                    <th (click)="sortProducts('eco',12)" class="table-row-header"
                        *ngIf="extendedProductListSettings[13] && subscribesToReportEnvironment &&(!currentUser.is_guest)"
                        (mouseenter)="showMinus12 = true" (mouseleave)="showMinus12 = false">
                        {{"ECOLABELS" | translate}}
                        <sup><img id="showMinusId12" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(13, false)" *ngIf="showMinus12" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[15]&&(!currentUser.is_guest)" (click)="sortProducts('h_phrases',21)" class="table-row-header"
                        (mouseenter)="showMinus13 = true" (mouseleave)="showMinus13 = false">
                        {{"H_PHRASES" | translate}}
                        <sup><img id="showMinusId13" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(15, false)" *ngIf="showMinus13" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[16]&&(!currentUser.is_guest)" (click)="sortProducts('p_phrases',22)" class="table-row-header"
                        (mouseenter)="showMinus14 = true" (mouseleave)="showMinus14 = false">
                        {{"P_PHRASES" | translate}}
                        <sup><img id="showMinusId14" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(16, false)" *ngIf="showMinus14" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[32]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus15 = true" (mouseleave)="showMinus15 = false" (click)="sortProducts('c_phrases',23)">
                        {{"C_PHRASES" | translate}}
                        <sup><img id="showMinusId15" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(32, false)" *ngIf="showMinus15" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[25]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus16 = true" (mouseleave)="showMinus16 = false">
                        {{"CAS" | translate}}
                        <sup><img id="showMinusId16" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(25, false)" *ngIf="showMinus16" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[21]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus17 = true" (mouseleave)="showMinus17 = false" (click)="sortProducts('department_info',24)">
                        {{"DEPARTMENT_INFO" | translate}}
                        <sup><img id="showMinusId17" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(21, false)" *ngIf="showMinus17" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[22]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus18 = true" (mouseleave)="showMinus18 = false" (click)="sortProducts('esdb_available',29)">
                        {{"SAFETY_EXT_DATA_SHEET" | translate}}
                        <sup><img id="showMinusId18" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(22, false)" *ngIf="showMinus18" class="nav-home"></sup>
                    </th>
                    <th *ngIf="subscription_revise_date && extendedProductListSettings[24]&&(!currentUser.is_guest)" class="table-row-header"
                        (click)="sortProducts('cdoc_date',13)"
                        (mouseenter)="showMinus19 = true" (mouseleave)="showMinus19 = false">
                        {{"REVISEDATESHORT" | translate}}
                        <sup><img id="showMinusId19" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(24, false)" *ngIf="showMinus19" class="nav-home"></sup>
                    </th>
                    <th *ngIf="subscription_subscription_stamped_SDS && extendedProductListSettings[27]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus21 = true"
                        (mouseleave)="showMinus21 = false"
                        (click)="sortProducts('stamp_date',15)">
                        {{"ST_DATE" | translate}}
                        <sup><img id="showMinusId21" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(27, false)" *ngIf="showMinus21" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[28]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus22 = true"
                        (mouseleave)="showMinus22 = false"
                        (click)="sortProducts('observation',16)">
                        {{"OBSERVATION" | translate}}
                        <sup><img id="showMinusId22" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(28, false)" *ngIf="showMinus22" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[29]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus23 = true"
                        (mouseleave)="showMinus23 = false"
                        (click)="sortProducts('restriktionList',17)">
                        {{"RESTRICTION_LIST" | translate}}
                        <sup><img id="showMinusId23" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(29, false)" *ngIf="showMinus23" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[30]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus24 = true"
                        (mouseleave)="showMinus24 = false"
                        (click)="sortProducts('last_changed',18)">
                        {{"LAST_CHANGED" | translate}}
                        <sup><img id="showMinusId24" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(30, false)" *ngIf="showMinus24" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[31]&&(!currentUser.is_guest)&&loadSubstitutionFinished" class="table-row-header"
                        (mouseenter)="showMinus26 = true"
                        (mouseleave)="showMinus26 = false"
                        (click)="sortProducts('substitutionstatus',19)">
                        {{"SUBSTITUTION" | translate}}
                        <sup><img id="showMinusId26" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(31, false)" *ngIf="showMinus26" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[34]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus27 = true" (mouseleave)="showMinus27 = false" (click)="sortProducts('hygiene_limits',26)">
                        {{"HGV" | translate}}
                        <sup><img id="showMinusId27" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(34, false)" *ngIf="showMinus27" class="nav-home"></sup>
                    </th>
                    <th *ngIf="extendedProductListSettings[35]&&(!currentUser.is_guest)" class="table-row-header"
                        (mouseenter)="showMinus28 = true" (mouseleave)="showMinus28 = false" (click)="sortProducts('CMRA_Category',25)">
                        {{"CMRA_CATEGORY" | translate}}
                        <sup><img id="showMinusId28" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(35, false)" *ngIf="showMinus28" class="nav-home"></sup>
                    </th>
                    <th *ngIf="subscribesToRiskAssessment&&extendedProductListSettings[36]&&(!currentUser.is_guest)" (click)="sortProducts('LRB_food_approved',28)"
                        (mouseenter)="showMinus29 = true" (mouseleave)="showMinus29 = false" class="table-row-header">
                        {{"FOOD_APPROVED" | translate}}
                        <sup><img id="showMinusId29" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedProductListSettings(36, false)" *ngIf="showMinus29" class="nav-home"></sup>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr [ngClass]="{'hover:bg-gray-200': !product.dropdownOpen}" *ngFor="let product of products | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" (click)="productSelected(product, false)" (contextmenu)="onRightClick($event, product)">
                    <td class="table-row-column" *ngIf="extendedProductListSettings[19]&&(!currentUser.is_guest)">{{product.customerarticle}}</td>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <div class="flex flex-row w-12">
                                <i *ngIf="product?.status == 4">
                                    <img class="w-6 h-6" tooltips tooltip-smart="true" title="{{'NEW_MESSAGE' | translate}}" area-hidden="true" src="/images/icons/new-star-solid-svgrepo-com.svg" />
                                </i>
                                <i *ngIf="product?.status != 4 && product?.status != 1">
                                    <img class="w-6 h-6" tooltips tooltip-smart="true" title="{{'UPDATE_MESSAGE' | translate}}" area-hidden="true" src="/images/icons/construction-danger-exclamantion-svgrepo-com.svg" />
                                </i>
                                <i *ngIf="product?.allergicOrSpecialDemandsFlag == true" tooltips tooltip-smart="true" title="{{'NO_INFO_ALLERGY_SPECIALDEMANDS' | translate}}">
                                    <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5V15M12 19H12.01" stroke="#e74c3c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </i>
                            </div>
                            <div class="flex flex-col w-5/6">
                                <strong>{{product.name}}</strong>
                                <span *ngIf="product.excluded==1" class="text-red-500">{{"EXCLUDED" | translate}}&nbsp;{{product.excluded_comment}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="table-row-column" *ngIf="extendedProductListSettings[33]&&(!currentUser.is_guest)">{{product.alternative_name}}</td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td (click)="onTdClick($event)" *ngIf="showSubdepartments && product.departmentsAll && product.departmentsAll.length > 0&&(!currentUser.is_guest)" class="table-row-column-department">
                        <div class="relative inline-block text-left p-1 w-auto border border-gray-300" appDropdown>
                            <button type="button" (click)="toggleDropdown($event, product)" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <span class="nav-home-text text-xs flex flex-col">
                                    {{"DEPARTMENTS" | translate}}&nbsp;({{getUniqueDepartments(product.departmentsAll)?.length}})
                                    <span *ngIf="getUniqueDepartments(product.departmentsAll)?.length > 1" class="text-[8px] italic">
                                        {{
                                            getTruncatedDepartments(
                                              getUniqueDepartments(product.departmentsAll)[0]?.department,
                                              getUniqueDepartments(product.departmentsAll)[1]?.department
                                            )
                                        }}
                                    </span>
                                    <span *ngIf="getUniqueDepartments(product.departmentsAll)?.length == 1" class="text-[8px] italic">
                                        {{ getTruncatedDepartment(getUniqueDepartments(product.departmentsAll)[0].department) }}
                                    </span>
                                </span>
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div [class.hidden]="!product.dropdownOpen" id="dropdownMenu" class="hidden dropdown-menu w-auto overflow-y-auto max-h-[200px] z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
                                <div class="py-1">
                                    <div *ngFor="let department of getUniqueDepartments(product.departmentsAll)" data-value="unit.id" class="dropdown-menu-item truncate"  (click)="goToProduct($event, department, product)" tabindex="1">
                                        <div class="flex flex-row items-center w-auto text-center" *ngIf="department.LRB_riskfile">
                                            <span class="text-sm font-bold mr-1">{{department.department}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>&nbsp;-&nbsp;
                                            {{'RISK' | translate}}&nbsp;{{department.LRB_risk}}(LRB)
                                            <img class="w-8 h-8 cursor-pointer" tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}"
                                                area-hidden="true" (click)="$event.stopPropagation(); openLrbInNewWindowForDepartmentInfo(product,department)"
                                                id="LRBIcon" src="/images/icons/LRB.svg" />
                                        </div>
                                        <div class="flex flex-row w-auto items-center w-auto text-center" *ngIf="!department.LRB_riskfile">
                                            <span class="text-sm font-bold mr-1">{{department.department}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!showSubdepartments" class="table-row-column text-xs">{{selectedDepartment.name}}</td>
                    <td class="table-row-column" *ngIf="extendedProductListSettings[17]">
                        <div class="flex flex-row">
                            <img class="-mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))&&subscribesToSds&&comparedates(product)!=false&&product.status===1" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openSdsInNewWindow(product)" id="SDBIcon" src="/images/icons/SDB.svg" />
                            <img class="-mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))&&subscribesToSds&&comparedates(product)!=false&&product.status===1" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openSdsInNewWindow(product)" id="SDBIcon" src="/images/icons/EXT_SDB.svg" />
                            <img class="-mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' &&subscribesToSds&&comparedates(product)==false&&product.status===1"
                                    tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}{{' *'}}{{'SAREQ_STATUS_4' | translate}}"
                                    (click)="$event.stopPropagation(); openSdsInNewWindow(product)" id="SDBWarningIcon" src="/images/icons/SDB_Star.svg" />
                            <img class="-mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id && product.esdb_file_id!='' ) ) &&subscribesToSds&&comparedates(product)!=false&&product.status===1" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openExtSdsInNewWindow(product)" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" />
                            <img class="-mr-3" *ngIf="product.status!==1&&(!currentUser.is_guest)" id="SDBWarningIcon" src="/images/icons/SDB_Warning.svg" (click)="$event.stopPropagation(); openSdsInNewWindow(product)" tooltips tooltip-smart="true" title="{{'PRODUCT_IS_UPDATED' | translate}}" />
                            <img class="-mr-3" *ngIf="showSubdepartments==false && subscription_risk_assessment==1 && product.assessment_riskfile != '' && product.assessment_riskfile != null && !updatedGrbFile(product) && assessmentIsInOrg(product)" tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openLrbInNewWindow(product)" id="LRBIcon" src="/images/icons/LRB.svg" />
                            <img class="-mr-3" *ngIf="showSubdepartments==false && subscription_risk_assessment==1 && product.assessment_riskfile != '' && product.assessment_riskfile != null && updatedGrbFile(product) && assessmentIsInOrg(product)" tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT_NEEDS_UPDATE' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openLrbInNewWindow(product)" id="LRBIcon" src="/images/icons/LRB_Warning.svg" />
                            <img class="-mr-3" *ngIf="product.status!==1 && product.risk_id && ( showSubdepartments==true || (!(showSubdepartments==false && subscription_risk_assessment==1 && product.assessment_riskfile != '' && product.assessment_riskfile != null && assessmentIsInOrg(product) ) && subscribesToGrb && product.risk_prio))" tooltips tooltip-smart="true" title="{{'PRODUCT_IS_UPDATED' | translate}}" id="GRBWarningIcon" area-hidden="true" (click)="$event.stopPropagation(); openGrbInNewWindow(product)" src="/images/icons/GRB_Warning.svg" />
                            <img class="-mr-3" *ngIf="product.status===1 && product.risk_id && (showSubdepartments==true || (!(showSubdepartments==false && subscription_risk_assessment==1 && product.assessment_riskfile != '' && product.assessment_riskfile != null && assessmentIsInOrg(product) ) && subscribesToGrb && product.risk_prio))" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" area-hidden="true" (click)="$event.stopPropagation(); openGrbInNewWindow(product)" src="/images/icons/GRB.svg" />
                            <img class="-mr-3" *ngIf="subscribesToProductsheet && product.productsheet_file_id&&(!currentUser.is_guest)" tooltips tooltip-smart="true" title="{{'PRODUCT_SHEET' | translate}}" area-hidden="true" (click)="$event.stopPropagation(); openProductSheetInNewWindow(product)" id="PDBIcon" src="/images/icons/PDB.svg" />
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[0]" class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngFor="let pictogram of product.pictograms" tooltips tooltip-smart="true" tooltip-class="cdoc-tooltip" title={{pictogram.description}}>
                                <img title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                            </a>
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[1] && ( showSubdepartments == true || !subscribesToRiskAssessment || ( (!currentUser.is_guest) && !(!product.chemical_id || product.chemical_id==null || product.chemical_id!='' || product.LRB_status == 3 )))"
                        class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngFor="let protective of product.protectiveArray" tooltips tooltip-smart="true" tooltip-class="cdoc-tooltip" title={{protective.description}}>
                                <img title="title={{protective.description}}" src="/images/protective/{{protective.filename}}-tiny.png" />
                            </a>
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[1] && ( showSubdepartments == false && subscribesToRiskAssessment && (!currentUser.is_guest) && (product.protectiveArrayLRB?.length))" class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngFor="let protective of product.protectiveArrayLRB" tooltips tooltip-smart="true" tooltip-class="cdoc-tooltip" title={{protective.description}}>
                                <img title="title={{protective.description}}" src="/images/protective/{{protective.filename}}-tiny.png" />
                            </a>
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[1] && ( showSubdepartments == false && subscribesToRiskAssessment && (!currentUser.is_guest) && (!product.protectiveArrayLRB?.length))" class="table-row-column">
                      <div class="flex flex-row">
                          <a *ngFor="let protective of product.protectiveArray" tooltips tooltip-smart="true" tooltip-class="cdoc-tooltip" title={{protective.description}}>
                              <img title="title={{protective.description}}" src="/images/protective/{{protective.filename}}-tiny.png" />
                          </a>
                      </div>
                  </td>
                    <td *ngIf="extendedProductListSettings[37]" class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngFor="let warning of product.warnings" tooltips tooltip-smart="true" tooltip-class="cdoc-tooltip" title={{warning}}>
                                <p class="bg-orange text-white font-bold text-xxs px-2 rounded-full">{{warning}}</p>
                            </a>
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[2]&&(!currentUser.is_guest)" class="table-row-column">
                      <span *ngIf = "showSubdepartments == true">
                        <p> <span *ngIf="product.amount_volume">{{showSwedishDecimalComma(formatNumbers(product.amount_volume))}}{{' '}}{{'l'}}<br/></span>
                            <span *ngIf="product.amount_weight">{{showSwedishDecimalComma(formatNumbers(product.amount_weight))}}{{' '}}{{'kg'}}<br/></span>
                            <span *ngIf="product.amount_other">{{showSwedishDecimalComma(formatNumbers(product.amount_other))}}</span>
                        </p>
                      </span>
                      <span *ngIf = "showSubdepartments != true">
                        <p *ngIf="product.amount">{{showSwedishDecimalComma(formatNumbers(product.amount))}}{{' '}}{{formatUnit(product.unit)}}</p>
                      </span>
                    </td>
                    <td *ngIf="extendedProductListSettings[3]&&(!currentUser.is_guest)" class="table-row-column">
                      <span *ngIf = "showSubdepartments == true">
                        <p> <span *ngIf="product.annual_consumption_volume">{{showSwedishDecimalComma(formatNumbers(product.annual_consumption_volume))}}{{' '}}{{'l'}}<br/></span>
                            <span *ngIf="product.annual_consumption_weight">{{showSwedishDecimalComma(formatNumbers(product.annual_consumption_weight))}}{{' '}}{{'kg'}}<br/></span>
                            <span *ngIf="product.annual_consumption_other">{{showSwedishDecimalComma(formatNumbers(product.annual_consumption_other))}}</span>
                        </p>
                      </span>
                      <span *ngIf = "showSubdepartments != true">
                        <p *ngIf="product.annual_consumption">{{showSwedishDecimalComma(formatNumbers(product.annual_consumption))}}{{' '}}{{formatUnit(product.unit)}}</p>
                      </span>
                    </td>
                    <td *ngIf="extendedProductListSettings[5]" class="table-row-column">
                        {{product.com_use}}
                    </td>
                    <td *ngIf="subscribesToClassification && extendedProductListSettings[6]&&(!currentUser.is_guest)" class="table-row-column">
                        {{product.classification.translation | translate}}
                    </td>
                    <td *ngIf="extendedProductListSettings[7]" class="table-row-column">
                        {{product.risk_prio}}
                    </td>
                    <td *ngIf="extendedProductListSettings[38]" class="table-row-column">
                      {{showSubdepartments==false && subscribesToRiskAssessment && product.chemical_id && product.assessment_risk && product.LRB_status == 3 ? product.assessment_risk:'-'}}
                  </td>
                    <td *ngIf="extendedProductListSettings[8]&&(!currentUser.is_guest)" class="table-row-column">
                        {{product.articlenumber}}
                    </td>
                    <td *ngIf="extendedProductListSettings[9]&&(!currentUser.is_guest)" class="table-row-column">
                        <span *ngIf="showSubdepartments==true">{{getUniqueGroupNameList(product)}}</span>
                        <span *ngIf="showSubdepartments==false">{{product.group_name}}</span>
                    </td>
                    <td *ngIf="extendedProductListSettings[20] && !product.adr_not_classfied && !product.transport_un_nbr && subscribesToTransport&&(!currentUser.is_guest)" class="table-row-column">
                    </td>
                    <td *ngIf="extendedProductListSettings[20] && !product.adr_not_classfied && product.transport_un_nbr && subscribesToTransport && (!currentUser.is_guest)" class="table-row-column">
                        {{"UN"}}{{product.transport_un_nbr}}
                    </td>
                    <td *ngIf="extendedProductListSettings[20] && ((!product.transport_un_nbr && product.adr_not_classfied===1)|| (product.transport_un_nbr && product.adr_not_classfied===1))  && subscribesToTransport&&(!currentUser.is_guest)" class="table-row-column">
                        {{"NO" | translate}}
                    </td>
                    <td *ngIf="extendedProductListSettings[12] && subscribesToSpecialDemands&&(!currentUser.is_guest)" class="table-row-column">
                        <p *ngIf="product.training">{{"TRAINING" | translate}}</p>
                        <p *ngIf="product.health_control">{{"HEALTH_CONTROL" | translate}}</p>
                        <p *ngIf="product.fitness_for_duty">{{"FITNESS_FOR_DUTY" | translate}}</p>
                        <p *ngIf="product.information">{{"INFORMATION_DEMAND" | translate}}</p>
                        <p *ngIf="product.no_special_demands">{{"NO_SPECIAL_DEMANDS" | translate}}</p>
                        <i *ngIf="product?.allergicOrSpecialDemandsFlag == true"
                            class="fa fa-exclamation" tooltips tooltip-smart="true"
                            title="{{'NO_INFO_ALLERGY_SPECIALDEMANDS' | translate}}">&nbsp;
                        </i>
                    </td>
                    <td *ngIf="extendedProductListSettings[13] && subscribesToReportEnvironment&&(!currentUser.is_guest)" class="table-row-column">
                        <div class="flex flex-row">
                            <img title="icon" class="w-8 h-8" *ngIf="product.eco_eu" src="/images/loggos/ecolabel-logo.png" />
                            <img title="icon" class="w-8 h-8" *ngIf="product.eco_svanen" src="/images/loggos/SE_Svanen_A_POS_RGB.png" />
                            <img title="icon" class="w-8 h-8" *ngIf="product.eco_GEC" src="/images/loggos/gec.jpg" />
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[15]&&(!currentUser.is_guest)" class="table-row-column">
                            <span *ngFor="let hphrases of product.h_phrases">
                            {{hphrases}}
                        </span>
                    </td>
                    <td *ngIf="extendedProductListSettings[16]&&(!currentUser.is_guest)" class="table-row-column">
                        <span *ngFor="let pphrases of product.p_phrases">
                            {{pphrases}}</span>
                    </td>
                    <td *ngIf="extendedProductListSettings[32]&&(!currentUser.is_guest)" class="table-row-column">
                        <span *ngFor="let cphrases of product.c_phrases">
                        {{cphrases}}
                        </span>
                    </td>
                    <td *ngIf="extendedProductListSettings[25]&&(!currentUser.is_guest)" class="table-row-column">
                        <span *ngFor="let cas of product.cas">
                            {{cas}}</span>
                    </td>
                    <td *ngIf="extendedProductListSettings[21]&&(!currentUser.is_guest)" class="table-row-column">
                      <span *ngIf="showSubdepartments!=true">
                        {{product.department_info}}
                      </span>
                      <span *ngIf="showSubdepartments==true">
                        <ul>
                          <li *ngFor="let department of product.parsed_department_info_array"><b>{{department.name}}{{' : '}}</b>{{department.info}}</li>
                        </ul>
                      </span>
                    </td>
                    <td *ngIf="extendedProductListSettings[22] && product.esdb_available===1 &&(!currentUser.is_guest)" class="table-row-column">
                        {{"YES" | translate}}
                    </td>
                    <td *ngIf="extendedProductListSettings[22] && product.esdb_available===0 &&(!currentUser.is_guest)" class="table-row-column">
                        {{"NO" | translate}}
                    </td>
                    <td *ngIf="subscription_revise_date&&extendedProductListSettings[24]&&(!currentUser.is_guest)" class="table-row-column">{{product.cdoc_date| date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="subscription_subscription_stamped_SDS==1 && extendedProductListSettings[27]&&(!currentUser.is_guest)" class="table-row-column">
                        {{product.stamp_date  | date:'yyyy-MM-dd'}}
                    </td>
                    <td *ngIf="extendedProductListSettings[28]&&(!currentUser.is_guest)" class="table-row-column">
                        {{product.observation}}
                    </td>
                    <td *ngIf="extendedProductListSettings[29]&&(!currentUser.is_guest)" class="table-row-column">
                        <ng-template #tipContent>
                            <div class="bg-black text-white p-2 z-20 w-80 rounded border border-gray-300">
                                <span class="text-lg font-bold">{{product.name}}</span>
                                <p>{{"CONTAINS_SUBSTANCE_WITH_FOLLOWING_RESTRICTIONLISTS" | translate}}:</p>
                                <ul class="list-disc list-inside mt-2">
                                    <li *ngIf="product.list_amv_a=='1'">{{"RESTRICTION_LIST_AMV_A" | translate}}</li>
                                    <li *ngIf="product.list_amv_b=='1'">{{"RESTRICTION_LIST_AMV_B" | translate}}</li>
                                    <li *ngIf="product.list_chem_prio_p=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_P" | translate}}</li>
                                    <li *ngIf="product.list_chem_prio_u=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_U" | translate}}</li>
                                    <li *ngIf="product.list_reach_14=='1'">{{"RESTRICTION_LIST_REACH_14" | translate}}</li>
                                    <li *ngIf="product.list_reach_17=='1'">{{"RESTRICTION_LIST_REACH_17" | translate}}</li>
                                    <li *ngIf="product.list_reach_candidate=='1'">{{"RESTRICTION_LIST_REACH_CANDIDATE" | translate}}</li>
                                    <li *ngIf="subscribesToSIN&&product.list_sin=='1'">{{"RESTRICTION_LIST_SIN" | translate}}</li>
                                    <li *ngIf="subscribesToSLL&&product.list_sll=='1'">{{"RESTRICTION_LIST_SLL" | translate}}</li>
                                    <li *ngIf="product.list_water_directive=='1'">{{"RESTRICTION_LIST_WATER_DIRECTIVE" | translate}}</li>
                                    <li *ngIf="subscribesToBASTA&&product.list_BASTA=='1'">{{"RESTRICTION_LIST_BASTA" | translate}}</li>
                                </ul>
                            </div>
                        </ng-template>
                        <div class="flex items-center">
                            <i *ngIf="(product.list_amv_a=='1'||product.list_amv_b=='1'||product.list_reach_candidate=='1'||product.list_reach_14=='1'||product.list_reach_17=='1')"
                                [ngbTooltip]="tipContent" triggers="hover focus" #t1="ngbTooltip">
                                <svg class="w-8 h-8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#FF0000" fill-rule="evenodd" d="M8 1a2.143 2.143 0 00-1.827 1.024l-5.88 9.768a2.125 2.125 0 00.762 2.915c.322.188.687.289
                                    1.06.293h11.77a2.143 2.143 0 001.834-1.074 2.126 2.126 0 00-.006-2.124L9.829 2.028A2.149 2.149 0 008 1zM7 11a1 1 0 011-1h.007a1 1 0 110
                                    2H8a1 1 0 01-1-1zm1.75-5.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z" clip-rule="evenodd"/>
                                </svg>
                            </i>
                            <i *ngIf="!(product.list_amv_a=='1'||product.list_amv_b=='1'||product.list_reach_candidate=='1'||product.list_reach_14=='1'||product.list_reach_17=='1')&&
                                (product.list_water_directive=='1'||product.list_chem_prio_p=='1'||product.list_chem_prio_u=='1')"
                                [ngbTooltip]="tipContent" triggers="hover focus" #t1="ngbTooltip">
                                <svg class="w-8 h-8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#DAA520" fill-rule="evenodd" d="M8 1a2.143 2.143 0 00-1.827 1.024l-5.88 9.768a2.125 2.125 0 00.762 2.915c.322.188.687.289
                                    1.06.293h11.77a2.143 2.143 0 001.834-1.074 2.126 2.126 0 00-.006-2.124L9.829 2.028A2.149 2.149 0 008 1zM7 11a1 1 0 011-1h.007a1 1 0 110
                                    2H8a1 1 0 01-1-1zm1.75-5.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z" clip-rule="evenodd"/>
                                </svg>
                            </i>
                            <i *ngIf="!(product.list_amv_a=='1'||product.list_amv_b=='1'||product.list_reach_candidate=='1'||product.list_reach_14=='1'||product.list_reach_17=='1')&&
                                !(product.list_water_directive=='1'||product.list_chem_prio_p=='1'||product.list_chem_prio_u=='1')&&(subscribesToSIN&&product.list_sin=='1'||
                                subscribesToSLL&&product.list_sll=='1'||subscribesToBASTA&&product.list_BASTA=='1')" [ngbTooltip]="tipContent" triggers="hover focus" #t1="ngbTooltip">
                                <svg class="w-8 h-8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#000000" fill-rule="evenodd" d="M8 1a2.143 2.143 0 00-1.827 1.024l-5.88 9.768a2.125 2.125 0 00.762 2.915c.322.188.687.289
                                    1.06.293h11.77a2.143 2.143 0 001.834-1.074 2.126 2.126 0 00-.006-2.124L9.829 2.028A2.149 2.149 0 008 1zM7 11a1 1 0 011-1h.007a1 1 0 110
                                    2H8a1 1 0 01-1-1zm1.75-5.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z" clip-rule="evenodd"/>
                                </svg>
                            </i>
                        </div>
                    </td>
                    <td *ngIf="extendedProductListSettings[30]&&(!currentUser.is_guest)" class="table-row-column">{{product.last_changed | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="extendedProductListSettings[31]&&(!currentUser.is_guest)&&loadSubstitutionFinished" class="table-row-column">{{product.substitutionstatus}}</td>
                    <td *ngIf="extendedProductListSettings[34]&&(!currentUser.is_guest)" class="table-row-column">{{product.hygiene_limits==1?'Ja':'Nej'}}</td>
                    <td *ngIf="extendedProductListSettings[35]&&(!currentUser.is_guest)" class="table-row-column">
                        <span *ngFor="let CMRA_Category_Item of product.CMRA_Category let lastitem = last" tooltips tooltip-smart="true" title="{{getCmraTitleTranslation(CMRA_Category_Item.title)}}">
                        {{CMRA_Category_Item.name}}<span *ngIf="product?.CMRA_Category?.length>1&&!lastitem">{{","}}</span>
                        </span>
                    </td>
                    <td class="table-row-column" *ngIf="showSubdepartments==false && subscribesToRiskAssessment && extendedProductListSettings[36] && (!currentUser.is_guest) && product.LRB_status === 3 && product.LRB_food_approved===1">{{'YES' | translate}}</td>
                    <td class="table-row-column" *ngIf="showSubdepartments==false && subscribesToRiskAssessment && extendedProductListSettings[36] && (!currentUser.is_guest) && product.LRB_status === 3 && product.LRB_food_approved===0">{{'NO' | translate}}</td>
                    <td class="table-row-column" *ngIf="showSubdepartments==false && subscribesToRiskAssessment && extendedProductListSettings[36] && (!currentUser.is_guest) && product.LRB_status === 3 && product.LRB_food_approved===2">{{'NOT_ASSESSED' | translate}}</td>
                    <td class="table-row-column" *ngIf="showSubdepartments==false && subscribesToRiskAssessment && extendedProductListSettings[36] && (!currentUser.is_guest) && product.LRB_status === 3 && !(product.LRB_food_approved===0 || product.LRB_food_approved===1 || product.LRB_food_approved===2)">{{'-'}}</td>
                    <td class="table-row-column" *ngIf="showSubdepartments==false && subscribesToRiskAssessment && extendedProductListSettings[36] && (!currentUser.is_guest) && product.LRB_status !== 3"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6 static" colspan="2">
                        <div class="inline-block text-left" appDropdown>
                            <button class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static right-0 overflow-y-auto" #dropdownMenuInside>
                                <div class="py-1">
                                <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<span *ngIf="showSubdepartments!=true&&(currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee ||
                  currentUser.is_admin || currentUser.is_useradmin || currentUser.is_super_user))">
  <div *ngIf="contextMenuVisible" [ngStyle]="{'top.px': contextMenuPosition.y, 'left.px': contextMenuPosition.x}" class="context-menu card" (click)="$event.stopPropagation()">
    <div class="card-header flex justify-between items-center">
      <span class="product-name">{{selectedProduct.name}}</span>
      <div class="flex justify-end">
        <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="closeContextMenu()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
      </div>
    </div>
    <ul class="card-body">
        <li class="dropdown-menu-item" (click)="productSelected(selectedProduct, true)" tabindex="2">
            {{"OPEN_NEW_TAB" | translate}}
        </li>
        <li class="dropdown-menu-item" (click)="editProduct(selectedProduct)" tabindex="2">
            {{"EDIT" | translate}}
        </li>
        <li *ngIf="subscribesToRiskAssessment" (click)="goToLRB(selectedProduct)" class="dropdown-menu-item" tabindex="1">
            {{"DO_LRB" | translate}}
        </li>
        <li *ngIf="subscribesToSubstitution && currentUser && (currentUser.is_local_admin ||
                    currentUser.is_local_assignee || currentUser.is_admin || currentUser.is_useradmin ||
                    currentUser.is_super_user)" (click)="sendToSubstitution(selectedProduct)" class="dropdown-menu-item" tabindex="3">
            {{"SEND_TO_SUBSTITUTION" | translate}}
        </li>
        <li *ngIf="subscribesToStamping && currentUser && (currentUser.is_local_admin ||
                    currentUser.is_local_assignee || currentUser.is_admin ||
                    currentUser.is_useradmin || currentUser.is_super_user)" (click)="uploadStampedFile(selectedProduct)" class="dropdown-menu-item" tabindex="4">
            {{"ADDSTAMPING" | translate}}
        </li>
        <li *ngIf="subscribesToLabels" (click)="orderLabels(selectedProduct)" class="dropdown-menu-item" tabindex="5">
            {{"WRITE_LABELS_AND_QR_CODES" | translate}}
        </li>
    </ul>
  </div>
</span>
<span *ngIf="showSubdepartments==true&&(currentUser && (currentUser.is_local_admin ||
              currentUser.is_local_assignee || currentUser.is_admin || currentUser.is_useradmin || currentUser.is_super_user))">
  <div *ngIf="contextMenuVisible" [ngStyle]="{'top.px': contextMenuPosition.y, 'left.px': contextMenuPosition.x}" class="context-menu card" (click)="$event.stopPropagation()">
    <div class="card-header flex justify-between items-center">
      <span class="product-name">{{selectedProduct.name}}</span>
      <div class="flex justify-end">
        <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="closeContextMenu()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
      </div>
  </div>
    <div class="card-body p-3">
        <ul class="card-body">
            <li class="dropdown-menu-item" (click)="productSelected(selectedProduct, true)" tabindex="2">
                {{"OPEN_NEW_TAB" | translate}}
            </li>
            <li *ngIf="subscribesToLabels" (click)="orderLabels(selectedProduct)" class="dropdown-menu-item" tabindex="1">
              {{"WRITE_LABELS_AND_QR_CODES" | translate}}
            </li>
          </ul>
    </div>
  </div>
</span>
