import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-edit-department',
  templateUrl: './modal-edit-department.component.html',
  styleUrls: ['./modal-edit-department.component.css']
})
export class ModalEditDepartmentComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() depName;
  @Input() depInfo;
  @Input() depAdressVisit;
  @Input() depId;

  departmentName: any;
  departmentInfo: any;
  departmentAdressVisit: any;
  selectedFile: any;
  organisation: any;
  departmentFiles: [];

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private authService: AuthService,
             private organisationService: OrganisationService, private uploadService: FileUploadService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.departmentName = this.depName;
    this.departmentInfo = this.depInfo;
    this.departmentAdressVisit = this.depAdressVisit;
    this.loadFiles();
  }

  ngOnDestroy(): void {
  }

  save() {
    let object = {
      'name':'',
      'info':'',
      'adress':''
    };

      object.name=this.departmentName;
      if(object.name==''){
        return;
      }

      object.info=this.departmentInfo;

      object.adress=this.departmentAdressVisit;

    this.passEntry.emit(object);

    this.activeModal.close(ModalEditDepartmentComponent);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      let file: File = fileList[0];
      this.selectedFile = file;

      this.uploadService.upload(this.selectedFile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: response => {
          if (response.filename)
          {
            this.selectedFile.id = response.filename;
            var uploadedFile = {
              file_id: response.filename,
              original_filename: this.selectedFile.name,
              filename: this.selectedFile.name,
              file_extension: "pdf",
            }

            this.organisationService.addFile(this.depId, uploadedFile);
            this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
              next: files => {
                this.loadFiles();
              },
              error: error => {
                console.log("Error: ", error);
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  openDocumentInNewWindow(fileId) {
    this.organisationService.getFileWithId(this.depId, fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          console.log("Error: Could not get the document.");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  removeDocument(fileId){
    this.organisationService.deleteFileWithId(this.depId, fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        this.loadFiles();
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  loadFiles(){
    this.departmentFiles = [];
    if (this.depId) {
      this.organisationService.getAllFiles(this.depId);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: documents => {
          if (documents == null) {}
          else {
            this.departmentFiles = documents;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  logout() {
    this.authService.logout();
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalEditDepartmentComponent);
  }

}
