import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.css']
})
export class AddMaterialComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  project: any;
  prev_project: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  showSubdepartments: boolean = false;
  organisationProducts: any;

  projectSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };

  ngAfterViewInit(): void {
    $('#materialtable').tablesort();
  }

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    if (this.currentUser) {
      this.project = {
        id: "",
        name: "",
        number: "",
        issuer: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        project_date: new Date(),
        comments: "",
        products: []
      };
    }

    this.prev_project = _.cloneDeep(this.project);

    var projectCopy = JSON.parse(localStorage.getItem('projectCopy', ));
    if (projectCopy) {
      this.project = projectCopy;
      localStorage.removeItem("projectCopy");
    }

    this.loadOrganisationProducts();
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.project) != JSON.stringify(this.prev_project);
  }

  cancel() {
    if (this.hasPostChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.save();
        } else {
          this.router.navigate(['/materiallist']);
        }
      })
    }
    else
    {
      this.router.navigate(['/materiallist']);
    }
  }

  logout() {
    this.authService.logout();
  }

  goToMaterialList() {
    this.router.navigate(['/materiallist']);
  }

  save() {

    this.project.project_date = this.utilService.adjustDateForTimezoneOffset(this.project.project_date);
    this.materialListService.createProject(this.project);
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: project => {
        if (project == null) {}
        else {
          this.router.navigate(['/materiallist']);
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  incProductRows(product) {

    var productToAdd = cloneDeep(product);
    productToAdd.editColor = true;
    productToAdd.editSurface = true;
    productToAdd.disabled = false;
    productToAdd.new = true;
    if (typeof(this.project.products)=='undefined') {
      this.project.products=[];
      productToAdd.rownumber = 0;
      this.project.products.push(productToAdd);
      product.isSelected=true;
    } else {
      productToAdd.rownumber = this.project.products.length;
      this.project.products.push(productToAdd);
      product.isSelected=true;
    }
    if(typeof(product.counter)!='undefined'){
        product.counter++;
    }
  }

  decProductRows(product) {
    var i;
    for(let n=0;n<this.organisationProducts.length;n++){
      if(this.organisationProducts[n].sareq_id==product.sareq_id){
        delete this.organisationProducts[n].isSelected;
      }
    }



    if (typeof(product.counter)!='undefined'){
        product.counter--;
    }
    //New items are pushed to the end of the array. We remove the last index of the product.
    for (i = this.project.products.length-1; i >= 0; i--) {
        if (product.sareq_id === this.project.products[i].sareq_id) {
          this.project.products.splice(i, 1);
          delete product.isSelected;
          break;
        }
    }

    if(product.counter <= 0)
    {
        delete product.isSelected;
        delete product.counter;
    }

    for ( let i = 0; i < this.project.products.length; i++ ) {
      if( this.project.products[i].rownumber != i ) {
        this.project.products[i].rownumber = i;
      }
    }
  }

  onToggleShowSubDepartments(){
    this.showSubdepartments = !this.showSubdepartments;
    if (this.showSubdepartments){
      this.productService.AllWithSuborganisations();
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {}
          else {
            this.organisationProducts = products;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    } else{
        this.loadOrganisationProducts();
    }
  }

  loadOrganisationProducts() {

    this.productService.getAllProducts();
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: products => {
        if (products == null) {}
        else {
          this.organisationProducts = products;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  moveRowUp(index: number): void {
    if (index > 0) {
      var temp = this.project.products[index];
      this.project.products[index] = this.project.products[index - 1];
      this.project.products[index - 1] = temp;

      // Update row numbers
      this.project.products[index].rownumber = index;
      this.project.products[index - 1].rownumber = index-1;
    }
  }

  moveRowDown(index: number): void {
    if (index < this.project.products.length - 1) {
      var temp = this.project.products[index];
      this.project.products[index] = this.project.products[index + 1];
      this.project.products[index + 1] = temp;

      // Update row numbers
      this.project.products[index].rownumber = index;
      this.project.products[index+1].rownumber = index+1;
    }
  }
}
