import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from 'src/app/services/user.service';
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-productapplication-list",
  templateUrl: "./productapplication-list.component.html",
  styleUrls: ["./productapplication-list.component.css"],
})

export class ProductapplicationListComponent implements OnInit {
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  products: any = [];
  productApplications: any = [];
  productApplicationsDuplicate: any = [];
  productsOverride: any = [];
  showSubdepartments: boolean = true;
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  order = ["asc", "asc","asc", "asc", "asc","asc","asc", "asc","asc","asc"];
  productApplicationStarted: boolean = false;
  productapplicationSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  sareqProducts: any=[];
  numberOfItems0  = 100;
  numberOfItems1  = 100;
  searchPageNumber0=1;
  totalAmount;
  orderBy = "";
  productSearch: boolean = false;
  productInSareq: boolean = true;
  productNotSareq: boolean = false;
  allDepartments: boolean = false;
  product: any = {
    name:null,
    supplier_name:null,
    id:null
  };
  listOfDepartments: any = [];
  showProductApplications;
  rowindex: number = null;
  departmentsIds: any = [];
  departmentsDisplayed: any = "";
  dropdownOpen = false;
  dropdownOpenDisplayedDeps = false;

  ngOnDestroy(): void {}

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private userService: UserService,
    private productApplicationService: ProductApplicationService,
    private sareqService: SareqService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse( localStorage.getItem( "rootOrganisation" ) );
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );
    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartmentsProductapplications")
    );
    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.getAllDepartments();
    this.getItemsPerPage();

    setTimeout( () =>{
      this.getAllProductApplications('');
      this.loadingData = false;
    },500)

    this.organisationService.departmentChanged
      .pipe( take( 1 ) )
      .subscribe( ( msg: any ) => {
        this.utilService.departmentChangeClicked(this.router.url);
        this.loadingData = true;
        this.ngOnInit();
    } );
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              this.itemsPerPage = 10;
              return;
            }
          } else {
            this.itemsPerPage = 10;
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  openSdsInNewWindow(product) {
    const filename = 'SDS.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.sareq_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    });
  }

  openFileInNewWindow(product) {
    // Open a blank window immediately in response to the user action
        const newWindow = window.open('', '_blank');

        if (!newWindow) {
          Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
          return;
        }

        this.productApplicationService.getsds(product.id);
        this.productApplicationService.getSDSDataListener().pipe(take(1)).subscribe({
          next: pdfContent => {
            if (pdfContent == null) {
              Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
              newWindow.close();
            } else {
              newWindow.location.href = pdfContent;
            }
          },
          error: error => {
            Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
            newWindow.close();
          }
        });
  }

  openGrbInNewWindow(product) {
    const filename = 'GRB.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.sareq_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    });
  }

  openExtSdsInNewWindow(product) {
    const filename = 'Product' + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(product.sareq_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    });
  }

  getAllProductApplications( path ){
    path = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    var subDepartmentsIds = [];
    if ( this.showSubdepartments == true) {
      subDepartmentsIds = this.utilService.getAllSubdepartments( JSON.parse( localStorage.getItem( "selectedDepartment" ) ), true );
    } else {
      subDepartmentsIds = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    }
    this.productApplicationService.getAllProductApplications( path, subDepartmentsIds );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.productApplications = responce;
          this.productApplicationsDuplicate = _.cloneDeep( this.productApplications );
          this.getProductApplicationDepartmentNames(subDepartmentsIds);
          this.collectDepartments()
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  getStatus( status ) {
    switch ( status ) {
      case 1:
        return this.translate.instant("SAREQ_STATUS_4");
      case 2:
        return this.translate.instant("APPROVED2");
      case 3:
        return this.translate.instant("NOT_APPROVE");
      default:
        return this.translate.instant("SAREQ_STATUS_4");
    }
  }

  goToProductApplication() {
    this.router.navigate( [ '/productapplication' ] );
  }

  gotoProductApplicationItem(item) {
    if (item.department_id !== this.selectedDepartment.id) {
      this.organisationService.changeDepartmentById(item.department_id);
      this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
        this.utilService.departmentChangeClicked(this.router.url);
        localStorage.setItem('departmentchanged','set');
        this.router.navigate( [ '/productapplicationitem', item.id ] );
      })
    }
    else
    {
      this.router.navigate( [ '/productapplicationitem', item.id ] );
    }

  }

  newProductApplication() {
      this.router.navigate( [ '/productapplicationadd' ] );
  }

  historyProductApplication() {
    this.router.navigate( [ '/productapplicationhistory' ] );
  }

  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  getProductApplicationDepartmentNames(subDepartments:any) {
    if (this.listAlldepartments) {
      for(var k = 0; k < this.productApplications.length; k++) {
        this.departmentsIds = [];
        this.productApplications[k].departmentsNames = "";
        this.productApplications[k].departmentsDisplayed = "";
        this.departmentsIds = this.productApplications[k].departments.split(',');
        for(var j = 0; j < this.departmentsIds.length; j++) {
          for(var i = 0; i < this.listAlldepartments.length; i++) {
            if (this.departmentsIds[j] == this.listAlldepartments[i].id)
            {
                this.productApplications[k].departmentsNames += this.listAlldepartments[i].name;
                this.productApplications[k].departmentsNames += ", ";
            }
          }
        }
        this.productApplications[k].departmentsNames = this.productApplications[k].departmentsNames.slice(0, -2);
        this.productApplications[k].departmentsDisplayed = this.truncateText(this.productApplications[k].departmentsNames, 15);

      }
    }
  }

  sortProductsApplications ( property, sortIndex ) {
    if ( this.order[ sortIndex ]== 'asc' ) {
      this.order[ sortIndex ] = 'desc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['asc']);
    } else if ( this.order[ sortIndex ] == 'desc' ) {
      this.order[ sortIndex ] = 'asc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['desc'] );
    }
  }

  filterSearchItems() {
    this.productApplications = this.productApplicationsDuplicate;
    if ( this.productapplicationSearch.all == '' ) {
      this.productapplicationSearch.all=null;
    }
    if ( this.productapplicationSearch.all != null ) {
      this.productApplications = this.productApplications.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productapplicationSearch.all.toLowerCase())>-1);
    }
  }

  onToggleShowSubDepartments(){
    this.showSubdepartments = !this.showSubdepartments;
    localStorage.setItem( "showSubdepartmentsProductapplications",  JSON.stringify(this.showSubdepartments))
    this.getAllProductApplications('');
  }

  departFullName( department ) {
    return this.utilService.departFullName( department, this.listAlldepartments );
  }

  getAllDepartments() {
    if (this.listAlldepartments==null||this.organisation&&this.organisation.id!=JSON.parse(localStorage.getItem('rootOrganisation', )).id) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      } )
    }
    else {
      return (null);
    }
  }

  collectDepartments(){
    // Collect all departments for each product application
    // and set visibility based on selected department

    var departmentsAll = [];

    // Iterate through productApplications to group departments by sareqId
    if (Array.isArray(this.productApplications)) {
      this.productApplications.forEach ( application => {
        departmentsAll = [];
        if ( application.sareq_id == null ) {
          var tempDeps = this.productApplications.filter( app => app.name === application.name );
        } else {
          var tempDeps = this.productApplications.filter( app => app.sareq_id === application.sareq_id );
        }

        tempDeps.forEach ( tempDep => {
          departmentsAll.push({
            'id':tempDep.department_id,
            'department':tempDep.dep_name
          });
        });

        // Duplicates should not be shown
        // isOnDepartment: the product is on the selected department, show that but not any other
        // isNotFirstOccurrence: the product is not on the selected department, show first occurence only
        var isOnDepartment = departmentsAll.some ( tempDep => tempDep.id === this.selectedDepartment.id );
        var isNotFirstOccurrence = this.productApplications.findIndex(app => app.sareq_id === application.sareq_id) < this.productApplications.indexOf(application);
        if ( ( ( isOnDepartment ) && ( application.department_id !== this.selectedDepartment.id ) ) ||
            ( ( ! isOnDepartment ) && ( application.sareq_id!=null && isNotFirstOccurrence ) ) ) {
          var visible = false;
        } else {
          var visible = true;
        }
        application.departmentsAll = departmentsAll;
        application.visible = visible;
      });
    }
  }

  getFilteredProductApplications(): any[] {
    if (this.showSubdepartments === true) {
      return this.productApplications.filter(productApplication => productApplication.visible === true);
    } else {
      return this.productApplications;
    }
  }

  toggleDropdown(event: Event, productApplication: any) {
    event.stopPropagation();
    productApplication.dropdownOpen = !productApplication.dropdownOpen;
  }

  toggleDropdownDisplayedDeps(event: Event, productApplication: any) {
    event.stopPropagation();
    productApplication.dropdownOpenDisplayedDeps = !productApplication.dropdownOpenDisplayedDeps;
  }

  getTruncatedDepartments(department1: string, department2: string): string {
    if (!department1 || !department2) {
      return '...';
    }

    const maxChars = 16;

    if (department1.length < maxChars) {
      const remainingChars = maxChars - department1.length - 2; // Reserve space for ", "
      const truncatedDept2 = department2.slice(0, remainingChars);
      return `${department1}, ${truncatedDept2}${department2.length > remainingChars ? '...' : '...'}`;
    }

    const truncatedDept1 = department1.slice(0, maxChars - 5); // Reserve space for ", ..."
    return `${truncatedDept1}..., ${department2.slice(0, 5)}...`;
  }

  getTruncatedDepartment(department: string): string {
    const maxChars = 16;

    if (!department) {
      return '';
    }

    // Truncate and add ellipsis if necessary
    return department.length > maxChars ? department.slice(0, maxChars) + '...' : department;
  }

  gotoProductApplicationItemFromDepartment(productApplication,department) {
    // This is if the dropdown for departments is selected
    var thisId = "";
    if ( this.selectedDepartment.id !== department.id ) {
      // Get the id for the application I want to open ( not necesseary the displayed one )
      thisId = this.productApplications.find (
        (item) => item.sareq_id === productApplication.sareq_id && item.department_id === department.id
      )?.id || "";
      if (thisId === "") {
        return; // An error occurred, do not navigate
      }
      this.organisationService.changeDepartmentById ( department.id );
      this.organisationService.departmentChanged.pipe ( take ( 1 ) ).subscribe ( ( msg: any ) => {
        this.utilService.departmentChangeClicked ( this.router.url );
        localStorage.setItem ( 'departmentchanged','set' );
        this.router.navigate( [ '/productapplicationitem', thisId ] );
      })
    }
    else
    {
      thisId = productApplication.id;
      this.router.navigate( [ '/productapplicationitem', thisId ] );
    }
  }

  departmentsDisplayedAsArray(departmentsDisplayed){
    return departmentsDisplayed.split(',');
  }
}
