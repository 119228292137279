import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from "lodash";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-company-articles',
  templateUrl: './company-articles.component.html',
  styleUrls: ['./company-articles.component.css']
})
export class CompanyArticlesComponent implements OnInit {

  itemsPerPage:number=10;
  pageNumber:number=1;
  currentUser: any;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean;
  subscribesToFreemium: boolean;
  currentLanguage: any;
  organisation: any;
  customerArticles: any;
  products: any;
  enableEditArticles: boolean;
  showArticleAddExcell: boolean;
  showTableSave: boolean;
  file:File;
  tmpArticles: any[];
  counter: number=0;
  userArticleList: any[];
  newArticleNumbers: any[];
  arrayBuffer: any;
  articleSearch = {
    all: "",
    supplier_name: "",
    name: "",
    customerarticle: ""
  };
  loadingData:boolean=false;
  sortItems:any;
  object:any=[];

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private productService: ProductService) { }

  ngOnInit(): void {
    this.sortItems=[
      {'name':'supplier_name','order':'asc'},
      {'name':'name','order':'asc'},
      {'name':'customerarticle','order':'asc'}
    ];
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.currentLanguage = localStorage.getItem('usedLanguage');
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.customerArticles = [];
    this.products = [];
    this.newArticleNumbers = [];
    this.showTableSave = false;
    this.showArticleAddExcell = false;
    this.enableEditArticles = false;
    this.counter = 0;

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");
    this.getCustomerProducts();
  }

  logout() {
    this.authService.logout();
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  saveTable() {
    this.loadingData=true;
    /* Get current article list */
    this.organisationService.getProductArticleNumbers(this.organisation.id);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: article => {
        if (article == null) {}
        else {
          for(var j = 0; j < this.userArticleList.length; j++) {
            var buffer = { "name" : this.userArticleList[j].Product,
                            "sareqid" : "",
                            "articlenumber" : this.userArticleList[j].Article,
                            "newItem": 1};

            for (var i = 0; i < this.products.length; i++) {
              var thisProductName = this.products[i].name.replace(/ /g,'');
              var bufferName = buffer.name.replace(/ /g,'');
              if(_.isEqual(thisProductName, bufferName)) {
                  buffer.sareqid = this.products[i].sareq_id;
              }
            }

            this.newArticleNumbers.push(buffer);
          }

          this.tmpArticles = article;

          /* Check if newly added article already exists */
          for(var y = 0; y < this.newArticleNumbers.length; y++)
          {
            this.organisationService.saveProductArticleNumbers(this.organisation.id, this.newArticleNumbers[y]);
            this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
              next: articles => {
                this.counter = this.counter + 1;
                if (this.counter >= this.newArticleNumbers.length)
                {
                  this.ngOnInit();
                }
              },
              error: error => {
                console.log("Error: ", error);
              }
            })
          }
        }
        this.loadingData=false;
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData=false;
      }
    })
  }

  createArticleTemplate() {
    var data = [{Product: '', Article: ''}];

    var worksheet = XLSX.utils.json_to_sheet(data);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "articles");
    XLSX.writeFile(workbook, "import_article_template.xlsx");
  }

  loadFromFile() {
    this.showTableSave = true;

    if(this.showArticleAddExcell)
    {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.userArticleList = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      }
      fileReader.readAsArrayBuffer(this.file);
    }
  }

  addArticleFromExcel() {

    this.showArticleAddExcell = !this.showArticleAddExcell;
  }

  incomingfile(event) {
    this.file= event.target.files[0];
  }

  thisIncomingFile(event,article) {
    article.uploadDocument = event.target.files[0];
  }

    uploadThisFile(article, index: number): Promise<void> {
      return new Promise((resolve, reject) => {
        if (!article.uploadDocument || !article.uploadDocument.name) {
          Swal.fire('No valid file selected for upload.');
          return reject('No valid file selected.');
        }

        this.uploadService.upload(article.uploadDocument);
        this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
          next: (response) => {
            if (response.filename) {
              this.object[index] = {
                index: index,
                orgid: this.organisation.id,
                sareqid: article.sareq_id,
                customerarticle_file_id: response.filename,
                customerarticle_file_name: article.uploadDocument.name,
                customerarticle_file_type: article.uploadDocument.name.split('.').pop()?.toLowerCase() || "",
              };

              this.organisationService.uploadProductArticleNumberFile(this.object[index]);
              this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
                next: (pdfContent) => {
                  if (pdfContent && pdfContent.file_id === response.filename) {
                    article.customerarticle_file_id = response.filename;
                    article.customerarticle_file_name = article.uploadDocument.name;
                    article.customerarticle_file_type = article.uploadDocument.name.split('.').pop()?.toLowerCase() || "";
                    resolve(); // Resolve the promise when the file is successfully processed
                  } else {
                    console.error("Mismatched response for index:", index);
                    reject("Mismatched response.");
                  }
                },
                error: (error) => {
                  console.error("Error in organisation service response:", error);
                  reject(error);
                },
              });
            } else {
              reject("Invalid response from upload service.");
            }
          },
          error: (error) => {
            console.error("Error in file upload:", error);
            reject(error);
          },
        });
      });
    }

    async processFilesSequentially() {
      this.object = [];

      for (let index = 0; index < this.customerArticles.length; index++) {
        const article = this.customerArticles[index];
        if (article.uploadDocument) {
          try {
            await this.uploadThisFile(article, index); // Wait for each file to finish processing
          } catch (error) {
            console.error(`Error processing file at index ${index}:`, error);
          }
        }
      }
    }

  editArticle() {
    this.enableEditArticles = !this.enableEditArticles;
  }

  getCustomerProducts() {
    if (this.organisation) {
      this.productService.getProductsWithLessInformation(this.organisation.id);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {}
          else {
            this.products = products;
            this.customerArticles = cloneDeep(products);
          }
          this.getItemsPerPage();
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  save() {
    this.object=[];
    this.loadingData=true;
    this.processFilesSequentially().then(() => {
      /* Check if newly added article already exists */
      for(var y = 0; y < this.customerArticles.length; y++) {
        if (this.products[y].customerarticle !== this.customerArticles[y].customerarticle) {
          var buffer = { "name" : this.customerArticles[y].name,
                          "sareqid" : this.customerArticles[y].sareq_id,
                          "articlenumber" : this.customerArticles[y].customerarticle,
                          "newItem": 1,
                          "user": this.currentUser.id};

          if ((this.customerArticles[y].customerarticle === null) || (this.customerArticles[y].customerarticle === "")) {
            this.organisationService.removeProductArticleNumber(this.organisation.id, buffer);
            this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
              next: articles => {
              },
              error: error => {
                console.log("Error: ", error);
                this.loadingData = false;
              }
            })
          }
          else {
            this.organisationService.saveProductArticleNumbers(this.organisation.id, buffer);
            this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
              next: articles => {
              },
              error: error => {
                console.log("Error: ", error);
                this.loadingData = false;
              }
            })
          }
        }
      }
    setTimeout(() => {
      this.loadingData=false;
      window.location.reload();
      }, 2000);
    }).catch((error) => {
      console.error("Error processing files:", error);
    });
  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }

  sortItem(id){
    if(id==2){
      if(this.sortItems[id].order=='asc'){
        this.sortItems[id].order='desc';
        this.customerArticles=_.orderBy(this.customerArticles, [( o ) => { if(o[this.sortItems[id].name]!=null){return o[this.sortItems[id].name]}else{return 'zzz999'} }], ['asc']);
      } else if(this.sortItems[id].order=='desc'){
        this.sortItems[id].order='asc';
        this.customerArticles=_.orderBy(this.customerArticles, [( o ) => { if(o[this.sortItems[id].name]!=null){return o[this.sortItems[id].name]}else{return '-aaa999'} }], ['desc']);
      }
    } else {
      if(this.sortItems[id].order=='asc'){
        this.sortItems[id].order='desc';
        this.customerArticles.sort((a, b) => (a[this.sortItems[id].name]?.toLowerCase() > b[this.sortItems[id].name]?.toLowerCase()) ? 1 : -1);
      } else if(this.sortItems[id].order=='desc'){
        this.sortItems[id].order='asc';
        this.customerArticles.sort((a, b) => (a[this.sortItems[id].name]?.toLowerCase() > b[this.sortItems[id].name]?.toLowerCase()) ? -1 : 1);
      }
    }
  }

  openDocumentInNewWindow(article) {
    // Open a blank window immediately in response to the user action
    const newWindow = window.open('', '_blank');

    if (!newWindow) {
      Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
      return;
    }

    this.organisationService.getArticleNumberFile(article.customerarticle_file_id, article.customerarticle_file_type);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
            newWindow.close();
          } else {
            // Handle based on file type
            if (article.customerarticle_file_type === 'pdf') {
              // Open PDF in the new window
              newWindow.location.href = pdfContent;
            } else if (['xlsx', 'xls', 'doc', 'docx'].includes(article.customerarticle_file_type)) {
              // For other file types, trigger a download
              const anchor = document.createElement('a');
              anchor.href = pdfContent;
              anchor.download = article.customerarticle_file_name || 'download.' + article.customerarticle_file_type;
              anchor.click();
              newWindow.close();
            } else {
              // Handle unknown file types
              Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("UNSUPPORTED_FILE_TYPE"), "error");
              newWindow.close();
            }
          }
        },
      error: error => {
        Swal.fire(this.translate.instant("CANT_OPEN_DOCUMENT"), this.translate.instant("CANT_OPEN_DOCUMENT"), "error");
        newWindow.close();
      }
    });
  }

  removeDocument(article){
    let object=({
      'orgId': this.organisation.id,
      'sareqId': article.sareq_id,
      'customerarticle_file_id': article.customerarticle_file_id
    });
    this.organisationService.deleteArticleNumberFile(object);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        article.customerarticle_file_id = "";
        article.customerarticle_file_name = "";
        article.customerarticle_file_type = "";
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }
}
