import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'src/app/auth/auth.service';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FilterByPipe } from 'ngx-pipes';
import { OrderByPipe} from 'ngx-pipes';
declare var $: any;

@Component({
  selector: 'app-organisation-edit',
  templateUrl: './organisation-edit.component.html',
  styleUrls: ['./organisation-edit.component.css']
})

export class OrganisationEditComponent implements OnInit {
    activeTab: any = 0;
    allUsersOnOrganisation: any = [];
    dateOptions: any;
    isSuperUser: boolean;
    currentUser: any;
    organisation: any = [];
    selectedOrganisation: any;
    organisationId: any;
    isNew: boolean;
    picturefile: any;
    show: boolean = false;
    sponsorList: any = [];
    sponsorName1;
    subscriptions: any;
    displayedSubscriptions: any;
    selectAllSubscriptions: boolean = false;
    errorMessage;
    subscriptionName;
    itemsPerPage: number = 10;
    pageNumber: number = 1;
    thisdate = new Date();
    products: any = "test";
    userSearch = {
      all: "",
      username: "",
      email: "",
      role: "",
    };
    allDepartmentNames: any;
    loadingData: boolean = true;
    products2: any;
    currentLanguage: any;
    reducedLengthLimit: any;
    showReducedInfo: boolean;
    sareqIds: any[];
    amountSwedishProduct: any;
    amountForeignProduct: any;
    amountRevised: any;
    amountUpdated: any;
    tempdate: any;
    date: any;
    kamItem = "Kent Sahlgren";
    customerCalleritem = "JOPE";
    typeOfSubscriptionItem = "Bas";
    kamArray = ['Kent Sahlgren','Carl-Uno Olsson'];
    customerCallerArray = ['JOPE','CUO','JOIS','NA'];
    lawenforcementArray = [];
    lawenforcementItem = "";
    imageLogo: any;
    economy:any = [];
    delivery:any = [];
    subscriptionMoreInfo:any = [];
    organisationMoreInfo:any = [];
    typeOfSubscriptionArray = ['Freemium','Bas','Premium','Professional'];
    Samtec:boolean=false;
    Cloocast:boolean=false;
    Distributor:boolean=false;
    Transport:boolean=false;
    Project:boolean=false;
    CMR:boolean=false;
    SIN:boolean=false;
    BASTA:boolean=false;
    Svanen:boolean=false;
    Exposures:boolean=false;
    Exponering:boolean=false;
    Secret:boolean=false;
    riskAssessmentModule:boolean=false;
    Substitution:boolean=false;
    productApplication:boolean = false;
    listPop:boolean = false;
    listRohs:boolean = false;
    orgIdInUrl: any="";

    Freemium =  ["subscription_freemium",
                  "subscription_catalogue",
                  "subscription_products",
                  "subscription_report_simple",
                  "subscription_reports",
                  "subscription_sds"];

    Bas =       ["subscription_product_analysis",
                  "subscription_labels",
                  "subscription_grb",
                  "subscription_app",
                  "subscription_products",
                  "subscription_qrcode",
                  "subscription_report_fire",
                  "subscription_report_simple",
                  "subscription_report_grb",
                  "subscription_report_statutory",
                  "subscription_report_notes",
                  "subscription_report_sds",
                  "subscription_reports",
                  "subscription_sds"];

    Premium =   [ "subscription_product_analysis",
                  "subscription_labels",
                  "subscription_grb",
                  "subscription_coordinator",
                  "subscription_app",
                  "subscription_products",
                  "subscription_qrcode",
                  "subscription_report_fire",
                  "subscription_report_cmr",
                  "subscription_report_simple",
                  "subscription_report_grb",
                  "subscription_report_statutory",
                  "subscription_report_notes",
                  "subscription_report_sds",
                  "subscription_report_classification",
                  "subscription_reports",
                  "subscription_revise_date",
                  "subscription_risk_assessment",
                  "subscription_substitution",
                  "subscription_sds"];

    Professional = [  "subscription_product_analysis",
                      "subscription_labels",
                      "subscription_grb",
                      "subscription_coordinator",
                      "subscription_app",
                      "subscription_products",
                      "subscription_qrcode",
                      "subscription_report_fire",
                      "subscription_report_cmr",
                      "subscription_report_simple",
                      "subscription_report_grb",
                      "subscription_report_statutory",
                      "subscription_report_notes",
                      "subscription_report_sds",
                      "subscription_report_classification",
                      "subscription_reports",
                      "subscription_revise_date",
                      "subscription_risk_assessment",
                      "subscription_special_demands",
                      "subscription_substitution",
                      "subscription_sds"];


    constructor(
      private router: Router,
      private previousrouter: ActivatedRoute,
      private organisationService: OrganisationService,
      private productService: ProductService,
      private userService: UserService,
      private sponsorService: SponsorService,
      private staticDataService: StaticDataService,
      private utilService: UtilService,
      private translate: TranslateService,
      private authService: AuthService,
      private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
      // Change department
      this.lawenforcementArray =["Lagbevakning - Lagpunkten","Lagbevakning - Aptor","Ingen lagbevakning"];
      this.tempdate = new Date();
      this.tempdate.setDate(this.tempdate.getDate() - 1);
      this.tempdate.setHours(0);
      this.tempdate.setMinutes(0);
      this.tempdate.setSeconds(0);
      this.amountSwedishProduct = 0;
      this.amountForeignProduct = 0;
      this.amountRevised = 0;
      this.amountUpdated = 0;
      this.date = new Date();
      this.previousrouter.params.pipe(take(1)).subscribe((params) => {
        let changeOrg = JSON.parse(localStorage.getItem('changeOrg'));
        if (changeOrg != true)
        {
          params["id"] == "new" ? (this.isNew = true) : (this.isNew = false);
          if (this.isNew == false) {
            this.organisationId = params["id"];
          }
        }
        else
        {
          this.selectedOrganisation = JSON.parse(
            localStorage.getItem("rootOrganisation")
          );
          this.organisationId = this.selectedOrganisation.id;
          this.isNew = false;
          localStorage.removeItem('changeOrg');
        }
      });
      this.allUsersOnOrganisation = [];
      this.activeTab = JSON.parse(
        localStorage.getItem("controlPanelOrganisationActiveTab")
      );
      if (
        this.activeTab != 0 &&
        this.activeTab != 1 &&
        this.activeTab != 2 &&
        this.activeTab != 3 ||
        this.isNew     == true
      ) {
        this.activeTab = 0;
      }
      this.dateOptions = {
        startingDay: 1,
      };
      if (!_.isNumber(this.activeTab)) {
        this.activeTab = 0;
      }

      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

      if (this.currentUser) {
        this.isSuperUser = this.currentUser.is_super_user;
        this.selectedOrganisation = JSON.parse(
          localStorage.getItem("rootOrganisation")
        );
      }

      if (this.selectedOrganisation == null) {
        this.logout();
      }

      if (this.activeTab == 3) {
        this.setProducts();
      }

      if (this.isNew == false) {
        this.organisationService.getSingleOrganisation(this.organisationId);
        this.organisationService
          .getOrganisationDataListener()
          .pipe(take(1))
          .subscribe({
            next: (organisation) => {
              this.organisation = organisation;
              this.subscriptions = this.getOrganisationSubscriptions(
                this.organisation
              );
              if (this.organisation&&this.subscriptions) {
                this.organisation.subscriptions = _.cloneDeep(this.subscriptions);
              }
              this.loadSponsors();
              this.organisation.sponsor_name = this.organisation.sponsname
                ? this.organisation.sponsname
                : "";


                if(this.organisation.organisationMoreInfo){
                  this.organisationMoreInfo = this.organisation.organisationMoreInfo[0];
                  this.kamItem = this.organisationMoreInfo.kam;
                } else {
                  this.organisationMoreInfo = {
                    gan : false,
                    sms : false,
                    kam : "",
                    koncern : "",
                    reseller : "",
                    numberOfEmployees:"",
                  };
                }

                if(this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint")?.active==true){
                  this.lawenforcementItem = "Lagbevakning - Lagpunkten";
                }
                else if(this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor")?.active==true){
                  this.lawenforcementItem = "Lagbevakning - Aptor";
                } else {
                  this.lawenforcementItem = "Ingen lagbevakning";
                }

                this.Samtec=this.subscriptions.find(({ identifier }) => identifier === "subscription_samtec")?.active;
                this.Cloocast=this.subscriptions.find(({ identifier }) => identifier === "subscription_education")?.active;
                this.Distributor=this.subscriptions.find(({ identifier }) => identifier === "subscription_distribution")?.active;
                this.Transport=this.subscriptions.find(({ identifier }) => identifier === "subscription_transport")?.active;
                this.Project=this.subscriptions.find(({ identifier }) => identifier === "subscription_projects")?.active;
                this.CMR=this.subscriptions.find(({ identifier }) => identifier === "subscription_report_cmr")?.active;
                this.SIN=this.subscriptions.find(({ identifier }) => identifier === "subscription_sin")?.active;
                this.BASTA=this.subscriptions.find(({ identifier }) => identifier === "subscription_basta")?.active;
                this.Svanen=this.subscriptions.find(({ identifier }) => identifier === "subscription_report_enviroment")?.active;
                this.Exposures=this.subscriptions.find(({ identifier }) => identifier === "subscription_exposures")?.active;
                this.Secret=this.subscriptions.find(({ identifier }) => identifier === "subscription_secret_chemical")?.active;
                this.riskAssessmentModule=this.subscriptions.find(({ identifier }) => identifier === "subscription_risk_assessment")?.active;
                this.Substitution=this.subscriptions.find(({ identifier }) => identifier === "subscription_substitution")?.active;
                this.productApplication = this.subscriptions.find(({ identifier }) => identifier === "subscription_product_application")?.active;
                this.listPop = this.subscriptions.find(({ identifier }) => identifier === "subscription_list_pop")?.active;
                this.listRohs = this.subscriptions.find(({ identifier }) => identifier === "subscription_list_rohs")?.active;

                if(this.organisation.subscriptionMoreInfo){
                  this.subscriptionMoreInfo = this.organisation.subscriptionMoreInfo[0];
                  this.typeOfSubscriptionItem = this.subscriptionMoreInfo.typeOfSubscription;
                } else {
                  this.subscriptionMoreInfo = {
                    typeOfSubscription:"",
                    numberOfChemicals:null,
                    HGV:false,
                    newChemicaladministrator:false,
                    API:false,
                    SundaHus:false,
                    revision:"",
                    revisionForeignLanguage:"",
                    trygghetspaket:false,
                    sevesoRiskassessment:false,
                    pictures:false,
                    history:false,
                    MFaktorRevision:false,
                    EHSConsult:null,
                    governmentRep:false,
                    cdocSupport:false,
                    yearlyEnvironmentReport:false,
                    siteInspector:false
                  }
                }

                if(this.organisation.economy){
                  this.economy = this.organisation.economy[0];
                } else {
                  this.economy = {
                    orderNumber:null,
                    numberFortnox:null,
                    replacenumberFortnox:null,
                    paymentDate:"",
                    deliveryDate:"",
                    terminationDate:"",
                    subscriptionEnd:"",
                    contractPeriod:null,
                    annualLicense:null,
                    historicLicense:null,
                    entryCostLawpoint:null,
                    entryCostSamtec:null,
                    entryCostCloocast:null
                  };
                }

                if(this.organisation.delivery){
                  this.delivery = this.organisation.delivery[0];
                  this.customerCalleritem = this.delivery.customerCaller;
                } else {
                  this.delivery = {
                    contractDate:"",
                    workshop:"",
                    customerCaller:"",
                    welcomeCall:"",
                    welcomeCallPerformed:"",
                    chemicalListArrivedPlanned:"",
                    organisationListArrived:"",
                    chemicalListArrived:"",
                    accountCreatePlanned:"",
                    accountCreatedDone:"",
                    instructionFilmSent:"",
                    specialinformation:"",
                    lawpoint:"",
                    samtec:"",
                    aptor:"",
                    lawpointMail:"",
                    setupPlannedDate:"",
                    setupDoneDate:"",
                    deviations:null,
                    comments:null,
                    revisionStarted:"",
                    revisionDone:"",
                  };
                }

              this.userService.getAllUsers(this.organisationId);
              this.userService
                .getCurrentAllUsersListener()
                .pipe(take(1))
                .subscribe({
                  next: (allUsers) => {
                    this.allUsersOnOrganisation = allUsers;
                    if(this.allUsersOnOrganisation!=null)
                    {
                      for (var i = 0; i < this.allUsersOnOrganisation.length; i++) {
                        if (this.allUsersOnOrganisation[i].is_admin) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("ADMIN");
                        } else if (this.allUsersOnOrganisation[i].is_useradmin) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("USERADMIN");
                        } else if (this.allUsersOnOrganisation[i].is_local_assignee) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_ASSIGNEE");
                        } else if (this.allUsersOnOrganisation[i].is_local_admin) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_ADMIN");
                        } else if (this.allUsersOnOrganisation[i].is_maintenance_user) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("MAINTENANCE_USER");
                        } else if (this.allUsersOnOrganisation[i].is_readonly_user) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("READONLY_USER");
                        } else if (this.allUsersOnOrganisation[i].is_local_user) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_USER");
                        } else if (this.allUsersOnOrganisation[i].is_guest) {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("GUEST");
                        } else {
                            this.allUsersOnOrganisation[i].role = this.translate.instant("USER");
                        }
                      }
                    }
                    this.getDepartmentNames();
                    this.getLogoAsPdf();
                    this.loadingData = false;
                  },
                });
            },
          });
      } else {
        this.subscriptions = this.SetDefaultOrganisationSubscriptions();
        this.displayedSubscriptions = this.subscriptions;
        this.organisation = {
          name: null,
          org_nr: null,
          address_1: null,
          address_2: null,
          address_visit: null,
          zip: null,
          city: null,
          country: null,
          www: null,
          email: null,
          phone: null,
          contact_person: null,
          resp_consultant: null,
          misc: null,
          subscriptions: [],
        };
        this.loadingData = false;
        // This seemed to work best
        if (this.organisation&&this.subscriptions) {
          this.organisation.subscriptions = _.cloneDeep(this.subscriptions);
        }
      }
    }

    ngAfterViewInit(): void {
      $("#usertable").tablesort();
    }

    logout() {
      this.authService.logout();
    }

    gotoControlPanel() {
      this.router.navigate(["/organisation"]);
    }

    setCustomerCaller(customerCaller) {
      this.delivery.customerCaller = customerCaller;
    }

    setKam(kam) {
      this.organisationMoreInfo.kam = kam;
    }

    setActiveTab(tabnumber) {
      this.activeTab = tabnumber;
      localStorage.setItem(
        "controlPanelOrganisationActiveTab",
        JSON.stringify(tabnumber)
      );
      if (this.activeTab == 3) {
        this.setProducts();
      }
    }

    getLogoAsPdf() {
      this.productService.getLogoAsTrustedResourceUrl(
        this.organisation.logo_file_id
      );
      this.productService
        .getLogoListener()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response != null) {
              this.imageLogo = response;
            }
          },
          error: (error) => {
          },
        });
    }

    fileChange(event) {
      this.organisation.logo_file = event.target.files[0];
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          this.imageLogo = reader.result;
        };

        reader.readAsDataURL(file);
      }
    }

    setName(sponsor) {
      this.organisation.sponsor_name = sponsor.name;
      this.organisation.sponsor_id = sponsor.id;
    }

    loadSponsors() {
      this.sponsorService.getAll();
      this.sponsorService
        .getSponsorDataListener()
        .pipe(take(1))
        .subscribe({
          next: (sponsors) => {
            if (sponsors == null) {
            } else {
              this.sponsorList = sponsors;
              this.sponsorList.unshift({ name: "Välj ingen", id: null });
            }
          },
          error: (error) => {
            this.utilService.onError(error);
          },
        });
    }

    saveOrganisation() {
      this.route.url.subscribe(urlSegments => {
        const fullPath = urlSegments.map(segment => segment.path).join('/');
        this.orgIdInUrl = fullPath.split('/').pop();
      });
      this.organisationMoreInfo.gan=this.saveCheckboxRightValue(this.organisationMoreInfo.gan);
      this.organisationMoreInfo.sms=this.saveCheckboxRightValue(this.organisationMoreInfo.sms);
      this.subscriptionMoreInfo.HGV=this.saveCheckboxRightValue(this.subscriptionMoreInfo.HGV);
      this.subscriptionMoreInfo.newChemicaladministrator=this.saveCheckboxRightValue(this.subscriptionMoreInfo.newChemicaladministrator);
      this.subscriptionMoreInfo.API=this.saveCheckboxRightValue(this.subscriptionMoreInfo.API);
      this.subscriptionMoreInfo.SundaHus=this.saveCheckboxRightValue(this.subscriptionMoreInfo.SundaHus);
      this.subscriptionMoreInfo.trygghetspaket=this.saveCheckboxRightValue(this.subscriptionMoreInfo.trygghetspaket);
      this.subscriptionMoreInfo.sevesoRiskassessment=this.saveCheckboxRightValue(this.subscriptionMoreInfo.sevesoRiskassessment);
      this.subscriptionMoreInfo.pictures=this.saveCheckboxRightValue(this.subscriptionMoreInfo.pictures);
      this.subscriptionMoreInfo.history=this.saveCheckboxRightValue(this.subscriptionMoreInfo.history);
      this.subscriptionMoreInfo.MFaktorRevision=this.saveCheckboxRightValue(this.subscriptionMoreInfo.MFaktorRevision);
      this.subscriptionMoreInfo.governmentRep=this.saveCheckboxRightValue(this.subscriptionMoreInfo.governmentRep);
      this.subscriptionMoreInfo.cdocSupport=this.saveCheckboxRightValue(this.subscriptionMoreInfo.cdocSupport);
      this.subscriptionMoreInfo.yearlyEnvironmentReport=this.saveCheckboxRightValue(this.subscriptionMoreInfo.yearlyEnvironmentReport);
      this.subscriptionMoreInfo.siteInspector=this.saveCheckboxRightValue(this.subscriptionMoreInfo.siteInspector);

      this.organisation.organisationMoreInfo=this.organisationMoreInfo;
      this.organisation.subscriptionMoreInfo=this.subscriptionMoreInfo;
      this.organisation.economy=this.economy;
      this.organisation.delivery=this.delivery;


      // The second case shouldnt be able to happen
      var tempsubscription = [];
      this.organisation.subscriptions = _.cloneDeep(this.subscriptions);
      for (var i = 0; i < this.organisation.subscriptions.length; i += 1) {
        if (this.organisation.subscriptions[i].active == true) {
          tempsubscription.push(this.organisation.subscriptions[i]);
        }
      }
      this.organisation.subscriptions = tempsubscription;
      if (
        typeof this.organisation.logo_file != "undefined" &&
        this.organisation.logo_file != null
      ) {

        var uploadLogoFile = new Promise((resolve, reject) => {
          this.utilService.uploadFile(this.organisation.logo_file);
          this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              this.utilService.onError(error);
            },
          });
        });
        Promise.all([uploadLogoFile]).then((result) => {
          if (result[0] !== undefined) {
            this.organisation.logo_file_id = result[0];
            this.organisation.logo_file_extension =
              this.organisation.logo_file.name.substr(
                this.organisation.logo_file.name.lastIndexOf(".") + 1
              );
            this.organisation.logo_file = null;
          }
          if (this.isNew == true) {
            this.organisationService.createSingle(this.organisation);
            this.organisationService
              .getOrganisationDataListener()
              .pipe(take(1))
              .subscribe({
                next: () => {
                  Swal.fire({
                    title: this.translate.instant("ORGANISATION_CREATED"),
                    timer: 2000,
                  });
                  localStorage.removeItem("oldDepartment");
                  this.router.navigate(["controlpanel"]);
                },
                error: (error) => {
                  this.utilService.onError(error);
                },
              });
          } else {
            this.organisationService.updateSingle(
              this.organisation.id,
              this.organisation
            );
            this.organisationService
              .getOrganisationDataListener()
              .pipe(take(1))
              .subscribe({
                next: () => {
                  Swal.fire({
                    title: this.translate.instant("ORGANISATION_SAVED"),
                    timer: 2000,
                  });
                  localStorage.removeItem("oldDepartment");
                  if ( this.selectedOrganisation.id != this.orgIdInUrl ) {
                    this.router.navigate ( [ "/organisation", this.orgIdInUrl ] )
                      .then( ( ) => {
                        window.location.reload();
                      }
                    );
                  } else {
                    window.location.reload();
                  }
                },
                error: (error) => {
                  this.utilService.onError(error);
                },
              });
          }
        });
      } else {
        // No new logo file
        if (this.isNew == true) {
          this.organisationService.createSingle(this.organisation);
          this.organisationService
            .getOrganisationDataListener()
            .pipe(take(1))
            .subscribe({
              next: () => {
                Swal.fire({
                  title: this.translate.instant("ORGANISATION_CREATED"),
                  timer: 2000,
                });
                localStorage.removeItem("oldDepartment");
                this.router.navigate(["controlpanel"]);
              },
              error: (error) => {
                this.utilService.onError(error);
              },
            });
        } else {
          this.organisationService.updateSingle(
            this.organisation.id,
            this.organisation
          );
          this.organisationService
            .getOrganisationDataListener()
            .pipe(take(1))
            .subscribe({
              next: () => {
                Swal.fire({
                  title: this.translate.instant("ORGANISATION_SAVED"),
                  timer: 2000,
                });
                localStorage.removeItem("oldDepartment");
                if ( this.selectedOrganisation.id != this.orgIdInUrl ) {
                  this.router.navigate ( [ "/organisation", this.orgIdInUrl ] )
                    .then( ( ) => {
                      window.location.reload();
                    }
                  );
                } else {
                  window.location.reload();
                }
              },
              error: (error) => {
                this.utilService.onError(error);
              },
            });
        }
      }
    }

    removeOrganisation() {
      var id = this.organisationId;
      if (id != "") {
        Swal.fire({
          title: this.translate.instant("INACTIVATE_ORGANISATION"),
          text:
            this.translate.instant("INACTIVATE_ORGANISATION_WARNING") +
            " " +
            this.organisation.name +
            "?",
          showCancelButton: true,
          confirmButtonText: this.translate.instant("YES"),
          cancelButtonText: this.translate.instant("CANCEL"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.organisationService.removeSingle(id);
            this.organisationService
              .getOrganisationDataListener()
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.router.navigate(["/organisation"]);
                },
                error: (error) => {
                  this.utilService.onError(error);
                },
              });
          } else if (result.isDenied) {
          }
        });
      }
    }

    getOrganisationSubscriptions(organisation) {
      var i,
        j,
        subscriptions = this.staticDataService.getAvailableSubscriptions();

      for (i = 0; i < subscriptions.length; i += 1) {
        subscriptions[i].active = false;
      }

      for (i = 0; i < organisation.subscriptions.length; i += 1) {
        for (j = 0; j < subscriptions.length; j += 1) {
          if (
            subscriptions[j].identifier ===
            organisation.subscriptions[i].identifier
          ) {
            subscriptions[j].active = true;
          }
        }
      }

      return subscriptions;
    }

    setSubscription(index, bool) {
      if (bool == true || bool == false) {
        this.subscriptions[index].active = bool;
      } else {
        this.subscriptions[index].active = !this.subscriptions[index].active;
      }
      var shouldAddSubscription = true,
        i;

      if (this.subscriptions[index].active) {
        for (i = 0; i < this.organisation.subscriptions.length; i += 1) {
          if (
            this.organisation.subscriptions[i].identifier ===
            this.subscriptions[index].identifier
          ) {
            shouldAddSubscription = false;
            break;
          }
        }

        if (shouldAddSubscription) {
          this.organisation.subscriptions.push(this.subscriptions[index]);
        }
      } else {
        for (i = 0; i < this.organisation.subscriptions.length; i += 1) {
          if (
            this.organisation.subscriptions[i].identifier ===
            this.subscriptions[index].identifier
          ) {
            this.organisation.subscriptions.splice(i, 1);
            break;
          }
        }
      }
        if(this.subscriptions[index].identifier=="subscription_samtec"){
          this.Samtec=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_education"){
          this.Cloocast=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_distribution"){
          this.Distributor=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_transport"){
          this.Transport=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_projects"){
          this.Project=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_report_cmr"){
          this.CMR=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_sin"){
          this.SIN=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_basta"){
          this.BASTA=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_report_enviroment"){
          this.Svanen=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_exposures"){
          this.Exposures=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_secret_chemical"){
          this.Secret=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_risk_assessment"){
          this.riskAssessmentModule=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_substitution"){
          this.Substitution=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_product_application"){
          this.productApplication=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_list_pop"){
          this.listPop=this.subscriptions[index].active;
        }
        if(this.subscriptions[index].identifier=="subscription_list_rohs"){
          this.listRohs=this.subscriptions[index].active;
        }
    }

    toggleSelectAllSubscriptions() {
      this.selectAllSubscriptions = !this.selectAllSubscriptions;
      for (var i = 0; i < this.subscriptions.length; i += 1) {
        this.setSubscription(i, this.selectAllSubscriptions);
      }
      this.organisation.subscriptions = _.cloneDeep(this.subscriptions);
    }


    editUser(userId) {
      this.router.navigate(["/organisationedituser", userId, this.organisationId]);
    }

    AddNewUser() {
      localStorage.removeItem("oldDepartment");
      this.router.navigate(["/organisationedituser", "new", this.organisationId]);
    }

    getDepartmentNames() {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: (departments) => {
            if (departments != null) {
              this.allDepartmentNames = departments;
              if(this.allUsersOnOrganisation!=null){
                for (var i = 0; i < this.allUsersOnOrganisation.length; i++) {
                  this.allUsersOnOrganisation[i].departmentName = "";
                  for (var j = 0; j < this.allDepartmentNames.length; j++) {
                    if (
                      this.allUsersOnOrganisation[i].department ==
                      this.allDepartmentNames[j].id
                    ) {
                      this.allUsersOnOrganisation[i].departmentName =
                        this.allDepartmentNames[j].name;
                    }
                  }
                  if((this.allUsersOnOrganisation[i].is_local_user||this.allUsersOnOrganisation[i].is_local_admin)
                    &&this.allUsersOnOrganisation[i].is_local_user_department!=null
                    &&this.allUsersOnOrganisation[i].is_local_user_department!=""){
                      var thisDepartmentsArray = this.allUsersOnOrganisation[i].is_local_user_department.split(',');
                      var thisDepartments="";
                      for(var l = 0; l < thisDepartmentsArray.length; l++) {
                        thisDepartmentsArray[l] = thisDepartmentsArray[l].trim();
                        for (var k = 0; k < this.allDepartmentNames.length; k++)
                        {
                          if (this.allDepartmentNames[k].id == thisDepartmentsArray[l])
                          {
                            if (thisDepartments == "") {
                              thisDepartments += this.allDepartmentNames[k].name;
                            } else {
                              thisDepartments += ', ' + this.allDepartmentNames[k].name;
                            }
                          }
                        }
                      }
                      if(thisDepartments!=""){
                        this.allUsersOnOrganisation[i].local_user_departments=thisDepartments;
                      } else {
                        this.allUsersOnOrganisation[i].local_user_departments="";
                      }
                    }
                  }
                }
            } else {
              Swal.fire({
                title: "Organisation users!",
                text: "No departments found!",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: this.translate.instant("OK"),
              });
            }
          },
          error: (error) => {
            Swal.fire({
              title: "Organisation users!",
              text: "No departments found!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            });
          },
        });
    }

    setProducts() {
      this.loadingData = true;
      localStorage.setItem("selectedDepartment2", this.organisationId);
      var query;

      this.productService.AllWithSuborganisationsMoreInformationControlPanel(
        this.organisationId
      );
      this.productService
        .getProductDataListener()
        .pipe(take(1))
        .subscribe({
          next: (products) => {
            if (products != null) {
              this.products2 = products;
              this.currentLanguage = localStorage.getItem(
                this.currentUser.language
              );
              switch (this.currentLanguage) {
                case "sv":
                  this.currentLanguage = 0;
                  break;

                case "en":
                  this.currentLanguage = 1;
                  break;

                case "fi":
                  this.currentLanguage = 2;
                  break;

                default:
                  this.currentLanguage = 0;
                  break;
              }
              var ids = [];
              for (var i = 0; i < this.products2.length; i++) {
                ids.push({
                  id: this.products2[i].sareq_id,
                  lang: this.currentLanguage,
                });
              }

              for (var i = 0; i < this.products2.length; i++) {
                if (this.products2[i].override != null) {
                  this.products2.splice(i, 1);
                }
              }
              if (this.currentLanguage != 0) {
                this.productService.checkForOtherLanguagesControlPanel(ids);
                this.productService
                  .getProductDataListener()
                  .pipe(take(1))
                  .subscribe({
                    next: (otherLangs) => {
                      for (var i = 0; i < this.products2.length; i++) {
                        for (var j = 0; j < otherLangs.length; j++) {
                          if (
                            this.products2[i].sareq_id == otherLangs[j].override
                          ) {
                            this.products2[i].name = otherLangs[j].name;
                            this.products2[i].sds_file_id =
                              otherLangs[j].sds_file_id;
                            this.products2[i].overridden = true;
                            this.products2[i].origProdId = this.products2[i].id;
                            this.products2[i].id = otherLangs[j].id;
                          }
                        }
                      }
                    },
                  });
              } else {
                for (var i = 0; i < this.products2.length; i++) {
                  if (this.products2[i].override != undefined) {
                    this.products2.splice(i, 1);
                  }
                }
              }
              if (this.products2.length > this.reducedLengthLimit) {
                this.showReducedInfo = true;
                this.products = this.products2.slice(0, 10);
              } else {
                this.showReducedInfo = false;
              }
              this.products2.sort(this.utilService.sortByProperty("name"));
              this.products = [];
              this.sareqIds = [];
            }
            setTimeout(() => {
              this.products = this.products2;
              this.productService.getDatasheetInfo(this.organisationId);
              this.productService
                .getProductDataListener()
                .pipe(take(1))
                .subscribe({
                  next: (info) => {
                    this.amountSwedishProduct = 0;
                    this.amountForeignProduct = 0;
                    this.amountRevised = 0;
                    this.amountUpdated = 0;
                    for (var i = 0; i < this.products.length; i++) {
                      if (this.products[i].lang == "0") {
                        this.amountSwedishProduct++;
                      } else {
                        this.amountForeignProduct++;
                      }
                      if (
                        new Date(
                          this.products[i].cdoc_date.substr(0, 10)
                        ).valueOf() >= this.tempdate.valueOf()
                      ) {
                        this.amountRevised++;
                      }
                      if (
                        new Date(
                          this.products[i].sds_date.substr(0, 10)
                        ).valueOf() >= this.tempdate.valueOf()
                      ) {
                        this.amountUpdated++;
                      }
                    }
                    this.organisation.datasheet = [];
                    if (info && typeof info != "undefined" && info.length > 0) {
                      var SDBSwedish: any = info[0].SDBSwedish;
                      var SDBForeign = info[0].SDBForeign;
                    } else {
                      var SDBSwedish: any = 0;
                      var SDBForeign: any = 0;
                    }
                    this.organisation.datasheet = {
                      sds_swedish_agreement: SDBSwedish,
                      sds_swedish: this.amountSwedishProduct,
                      sds_foreign_agreement: SDBForeign,
                      sds_foreign: this.amountForeignProduct,
                      revisedchemical: this.amountRevised,
                      revised: this.amountUpdated,
                      updatedDate:this.date
                    };
                  },
                });
                this.loadingData = false;
            },1);
          },
          error: (error) => {this.loadingData = false;},
        });
    }

    updateDate() {
      if (typeof this.date == "undefined") {
        Swal.fire("Error", "Invalid date");
        return;
      } else {
        this.amountRevised = 0;
        this.amountUpdated = 0;
        var tempDate: any = new Date(this.date);
        tempDate.getDate() - 1;
        tempDate.setDate(tempDate.getDate() - 1);
        tempDate.setHours(0);
        tempDate.setMinutes(0);
        tempDate.setSeconds(0);
        this.date = tempDate;

        for (var i = 0; i < this.products.length; i++) {
          if (
            new Date(this.products[i].cdoc_date.substr(0, 10)).valueOf() >=
            this.date.valueOf()
          ) {
            this.amountRevised++;
          }
          if (
            new Date(this.products[i].sds_date.substr(0, 10)).valueOf() >=
            this.date.valueOf()
          ) {
            this.amountUpdated++;
          }
        }
        this.organisation.datasheet.revisedchemical = this.amountRevised;
        this.organisation.datasheet.revised = this.amountUpdated;
      }
    }

    SetDefaultOrganisationSubscriptions() {
      var i,
        subscriptions = this.staticDataService.getAvailableSubscriptions();

      for (i = 0; i < subscriptions.length; i += 1) {
        if (
          subscriptions[i].identifier === "LARS" // just to not get error
        ) {
          subscriptions[i].active = true;
        } else {
          subscriptions[i].active = false;
        }
      }
      return subscriptions;
    }

    checkboxclicked(type){
      if(type=='sms'||type=='gan'){
        this.organisationMoreInfo[type]!=true?
        this.organisationMoreInfo[type] = true:
        this.organisationMoreInfo[type] = false;
      } else {
        this.subscriptionMoreInfo[type]!=true?
        this.subscriptionMoreInfo[type] = true:
        this.subscriptionMoreInfo[type] = false;
      }
    }

    oldcheckboxclicked(type){
      if(type=="subscription_samtec"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Samtec=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_education"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Cloocast=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_distribution"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Distributor=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_transport"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Transport=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_projects"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Project=this.subscriptions.find(({ identifier }) => identifier === type).active;
      }
      if(type=="subscription_report_cmr"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.CMR=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_sin"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.SIN=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_basta"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.BASTA=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_report_enviroment"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Svanen=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_exposures"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Exposures=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_secret_chemical"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Secret=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_risk_assessment"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.riskAssessmentModule=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_substitution"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.Substitution=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_product_application"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.productApplication=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_list_pop"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.listPop=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }
      if(type=="subscription_list_rohs"){
        this.subscriptions.find(({ identifier }) => identifier === type).active=!this.subscriptions.find(({ identifier }) => identifier === type)?.active;
        this.listRohs=this.subscriptions.find(({ identifier }) => identifier === type)?.active;
      }


  }

    dateAddDays(date,days){
      let tempdate = _.cloneDeep(date);
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if(dateRegex.test(tempdate)){
        tempdate=new Date(tempdate);
        if(days!="1 month"){
          tempdate.setDate(tempdate.getDate() + days);
        } else {
          var newMonth = tempdate.getMonth();
          var newYear = tempdate.getFullYear();
          if(newMonth == 3 || newMonth == 5 || newMonth == 8 || newMonth == 10){ days=30; }
            else if(newMonth == 1 && newYear % 4 ==0) { days=29;}
            else if(newMonth == 1 && newYear % 4 !=0) { days=28;}
            else { days=31; }
          tempdate.setDate(tempdate.getDate() + days);
        }
      } else {
        if(days!="1 month"){
          tempdate.setDate(tempdate.getDate() + days);
        } else {
          var newMonth = tempdate.getMonth();
          var newYear = tempdate.getFullYear();
          if(newMonth == 3 || newMonth == 5 || newMonth == 8 || newMonth == 10){ days=30; }
          else if(newMonth == 1 && newYear % 4 ==0) { days=29; }
          else if(newMonth == 1 && newYear % 4 !=0) { days=28; }
          else { days=31; }
          tempdate.setDate(tempdate.getDate() + days);
        }
      }
      var yyyy = tempdate.getFullYear();
      var mm = String(tempdate.getMonth() + 1).padStart(2, '0');
      var dd = String(tempdate.getDate()).padStart(2, '0');
      var formattedDate = `${yyyy}-${mm}-${dd}`;

      return formattedDate;
    }

    // The frontend uses true or false
    showCheckboxRightValue(data){
      if(data == 1){
        return true;
      } else {
        return false;
      }
    }

    // The database uses 1 or 0
    saveCheckboxRightValue(data){
      if(data == true){
        return 1;
      }
      if(data == false){
        return 0;
      }
    }

    toggleSelect(subscription){
      if(subscription.identifier=="subscription_samtec"){
        this.Samtec=subscription.active
      }
      if(subscription.identifier=="subscription_education"){
        this.Cloocast=subscription.active
      }
      if(subscription.identifier=="subscription_distribution"){
        this.Distributor=subscription.active
      }
      if(subscription.identifier=="subscription_transport"){
        this.Transport=subscription.active
      }
      if(subscription.identifier=="subscription_projects"){
        this.Project=subscription.active
      }
      if(subscription.identifier=="subscription_report_cmr"){
        this.CMR=subscription.active
      }
      if(subscription.identifier=="subscription_sin"){
        this.SIN=subscription.active
      }
      if(subscription.identifier=="subscription_basta"){
        this.BASTA=subscription.active
      }
      if(subscription.identifier=="subscription_report_enviroment"){
        this.Svanen=subscription.active
      }
      if(subscription.identifier=="subscription_exposures"){
        this.Exposures=subscription.active
      }
      if(subscription.identifier=="subscription_secret_chemical"){
        this.Secret=subscription.active
      }
      if(subscription.identifier=="subscription_risk_assessment"){
        this.riskAssessmentModule=subscription.active
      }
      if(subscription.identifier=="subscription_substitution"){
        this.Substitution=subscription.active
      }
      if(subscription.identifier=="subscription_product_application"){
        this.productApplication=subscription.active
      }
      if(subscription.identifier=="subscription_list_pop"){
        this.listPop=subscription.active
      }
      if(subscription.identifier=="subscription_list_rohs"){
        this.listRohs=subscription.active
      }
      if(subscription.active==false){
        if(subscription.identifier=="subscription_law_aptor"){
          if(this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint").active==false){
            this.lawenforcementItem = "Ingen lagbevakning";
          }
        }
        if(subscription.identifier=="subscription_lawpoint"){
          if(this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor").active==false){
            this.lawenforcementItem = "Ingen lagbevakning";
          }
        }
      } else {
        if(subscription.identifier=="subscription_law_aptor"){
          this.lawenforcementItem = "Lagbevakning - Aptor";
          this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint").active=false;

        }
        if(subscription.identifier=="subscription_lawpoint"){
          this.lawenforcementItem = "Lagbevakning - Lagpunkten";
          this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor").active=false;
        }
      }
    }

    itemChange(value){
      setTimeout(()=> {
        if(value=="Lagbevakning - Aptor"){
          this.lawenforcementItem = "Lagbevakning - Aptor";
          this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor").active=true;
          this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint").active=false;
        }
        if(value=="Lagbevakning - Lagpunkten"){
          this.lawenforcementItem = "Lagbevakning - Lagpunkten";
          this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor").active=false;
          this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint").active=true;
        }
        if(value=="Ingen lagbevakning"){
          this.lawenforcementItem = "Ingen lagbevakning";
          this.subscriptions.find(({ identifier }) => identifier === "subscription_law_aptor").active=false;
          this.subscriptions.find(({ identifier }) => identifier === "subscription_lawpoint").active=false;
        }
      }, 10);
    }

    selectPlan(typeofsubscription){
      var subscription;
      this.subscriptionMoreInfo.typeOfSubscription = typeofsubscription;
      if(typeofsubscription == "Freemium"){
        for (var i = 0; i < this.subscriptions.length; i += 1) {
          this.setSubscription(i, false);
        }
        this.lawenforcementItem = "Ingen lagbevakning";
        for (var n=0;n<this.Freemium.length;n++){
          if(this.subscriptions.find(({ identifier }) => identifier === this.Professional[n]).active!=true){
            subscription={"identifier":this.Freemium[n],"active":true}
            this.toggleSelect(subscription);
            this.oldcheckboxclicked(subscription.identifier);
            this.subscriptions.find(({ identifier }) => identifier === this.Freemium[n]).active=true;
          }
        }
      }
      if(typeofsubscription == "Bas"){
        for (var i = 0; i < this.subscriptions.length; i += 1) {
          this.setSubscription(i, false);
        }
        this.lawenforcementItem = "Ingen lagbevakning";
        for (var n=0;n<this.Bas.length;n++){
          if(this.subscriptions.find(({ identifier }) => identifier === this.Bas[n]).active!=true){
            subscription={"identifier":this.Bas[n],"active":true}
            this.toggleSelect(subscription);
            this.oldcheckboxclicked(subscription.identifier);
            this.subscriptions.find(({ identifier }) => identifier === this.Bas[n]).active=true;
          }
        }
      }
      if(typeofsubscription == "Premium"){
        for (var i = 0; i < this.subscriptions.length; i += 1) {
          this.setSubscription(i, false);
        }
        this.lawenforcementItem = "Ingen lagbevakning";
        for (var n=0;n<this.Premium.length;n++){
          if(this.subscriptions.find(({ identifier }) => identifier === this.Premium[n]).active!=true){
            subscription={"identifier":this.Premium[n],"active":true}
            this.toggleSelect(subscription);
            this.oldcheckboxclicked(subscription.identifier);
            this.subscriptions.find(({ identifier }) => identifier === this.Premium[n]).active=true;
          }
        }
      }
      if(typeofsubscription == "Professional"){
        for (var i = 0; i < this.subscriptions.length; i += 1) {
          this.setSubscription(i, false);
        }
        this.lawenforcementItem = "Ingen lagbevakning";
        for (var n=0;n<this.Professional.length;n++){
          if(this.subscriptions.find(({ identifier }) => identifier === this.Professional[n]).active!=true){
            subscription={"identifier":this.Professional[n],"active":true}
            this.toggleSelect(subscription);
            this.oldcheckboxclicked(subscription.identifier);
            this.subscriptions.find(({ identifier }) => identifier === this.Professional[n]).active=true;
          }
        }
      }
    }
}

