import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { ExposuresService } from 'src/app/services/exposures.service';
import { SareqService } from 'src/app/services/sareq.service';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FileUploadService } from 'src/app/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-add-exposure',
  templateUrl: './add-exposure.component.html',
  styleUrls: ['./add-exposure.component.css']
})
export class AddExposureComponent implements OnInit {


  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  exposure: any;
  prev_exposure: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  created_date: any = 0;
  exposureId: any;
  products: any = [];
  loadingData: boolean = false;
  sareqSelected: boolean = false;
  allProductSelected: boolean = false;
  message="";
  exposureForm: any;
  chemical_name: string = "";
  filteredProducts: any = [];
  hPhraseMessages = [];
  sareqIds: any;
  exposureSearch = {
    name:""
  };

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private organisationService: OrganisationService,
    private route: ActivatedRoute, private exposuresService: ExposuresService,private sareqService: SareqService,
    private uploadService: FileUploadService) { }

    ngOnInit(): void {

      this.route.params.pipe(take(1)).subscribe(params => {
        this.exposureId = params['id'];
      });

      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
      if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
        this.logout();
      }

      this.exposureForm = this.formBuilder.group({
        name: [''],
        id_number: [''],
        selectedproduct: [''],
        exp_per_moment: [''],
        exp_times: [''],
        start: [''],
        end: [''],
        end_not_defined: [''],
        comments: [''],
        risk_source: ['']
      });

      var data = localStorage.getItem('currentUser', );
      if (data == null || data == undefined)
      {
        this.logout();
      }
      else
      {
        this.currentUser = JSON.parse(data);
      }

      this.loadingData = true;

      this.exposure = ({
        name: null,
        id_number: null,
        exp_per_moment: null,
        exp_times: null,
        start: new Date(),
        end: new Date(),
        end_not_defined: false,
        chemical: null
      });

      this.prev_exposure = _.cloneDeep(this.exposure);

      this.getAllDepartments();
      this.getProducts();
    }

    // Method to check if two objects are different
    hasPostChanged(): boolean {
      return JSON.stringify(this.exposure) != JSON.stringify(this.prev_exposure);
    }

    cancel() {
      if (this.hasPostChanged() == true)
      {
        Swal.fire({
          title: this.translate.instant('SAVE') + "?",
          text: this.translate.instant('SAVE_POST'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.translate.instant('NO'),
          confirmButtonText: this.translate.instant('YES')
        }).then((result) => {
          if (result.isConfirmed) {
            this.submit();
          } else {
            this.router.navigate(['/exposures']);
          }
        })
      }
      else
      {
        this.router.navigate(['/exposures']);
      }
    }

    getProducts() {
      this.sareqIds = [];
      if (this.selectedDepartment != null) {

        this.productService.AllMoreInformation(this.selectedDepartment.id);
        this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
          next: products => {
            if (products.length == 0) {

              this.loadingData = false;
            } else {
              this.products = _.orderBy(products, ['type', 'name']);
              for(var i = 0; i < this.products.length; i++) {
                this.sareqIds.push(this.products[i].sareq_id);
              }
              this.filteredProducts =  this.products;
              setTimeout( () => {
                this.getHHPhrases();
              },500)
              this.loadingData = false;
            }
          },
          error: error => {
            this.loadingData = false;
            console.log("Error: ", error);
          }
        });
      }
    }

    onSearchProduct(searchValue: string): void {

      this.filteredProducts =  this.products;

      if (searchValue !== "")
      {
          this.filteredProducts = this.products.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      }
    }

    submit() {
      this.exposure.end_not_defined = this.exposure.end_not_defined == false ?0:1;
      this.exposuresService.createExposure(this.exposure);
      this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
        next: exposure => {
          if (exposure == null) {
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.router.navigate(['/exposures']);
          }
        },
        error: error => {
          this.loadingData = false;
          Swal.fire("Error", "generiskt fel");
        }
      })
    }

    getSubscription(subscription) {
      return (this.utilService.subscribesTo(subscription));
    }

    goToExposureList() {
      this.router.navigate(['/exposures']);
    }

    getAllDepartments() {
      if (this.organisation) {
        this.organisationService.getAllDepartmentNames(this.organisation);
        //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: departments => {
            if (departments == null) {}
            else {
              this.listAlldepartments = departments;
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    }

    setProduct(product) {
      this.hPhraseMessages = [];
      this.exposureSearch.name = "";
      if ( product.h_phrases.includes('H340') ) {
        this.hPhraseMessages.push({"id":"H340","message":'H340'+' '+ this.translate.instant('PHRASE_H340')});
      }
      if (product.h_phrases.includes('H350') ) {
        this.hPhraseMessages.push({"id":"H350","message":'H350'+' '+ this.translate.instant('PHRASE_H350')});
      }
      if (product.h_phrases.includes('H360') ) {
        this.hPhraseMessages.push({"id":"H360","message":'H360'+' '+ this.translate.instant('PHRASE_H360')});
      }
      this.exposure.chemical = product.id;
      this.chemical_name = product.name;
    }

    departFullName(department){
      return this.utilService.departFullName(department, this.listAlldepartments);
    }

    logout() {
      this.authService.logout();
    }

    getProductsFromSareq(){
      if(this.sareqSelected == true){return;}
      this.loadingData = true;
      this.sareqService.getAll();
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
          next: products => {
            if (products.length == 0) {

              this.loadingData = false;
            } else {
              this.products = _.orderBy(products, ['type', 'name']);
              this.loadingData   = false;
              this.sareqSelected = true;
              this.allProductSelected = false;
            }
          },
          error: error => {
            this.loadingData = false;
            console.log("Error: ", error);
          }
        });
    }

    getProductFromAllDeps(){
      if(this.allProductSelected == true){return;}
      this.loadingData = true;
      this.productService.AllWithSuborganisationsMoreInformation(this.selectedOrganisation.id);
      this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
        next: products => {
          if (products.length == 0) {
            this.loadingData = false;
          } else {
            this.products = _.orderBy(products, ['type', 'name']);
            this.loadingData   = false;
            this.allProductSelected = true;
            this.sareqSelected = false;
            }
          },
          error: error => {
            this.loadingData = false;
            console.log("Error: ", error);
          }
      });
    }

    incomingfile(event){
      var file = event.target.files[0];
      if (event.target.files[0] != null) {
        var file = event.target.files[0];
        this.uploadService.upload(event.target.files[0]);
        this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
          next: result => {
            if (result.success == false)
            {

            }
            else {
              this.exposure.file_id = result.filename;
              this.exposure.file_name = file.name;
            }
          },
          error: error => {
          console.log("Error: ", error);
          }
        })
      }
    }

    fileRemove(){
      this.exposure.file_name = "";
      this.exposure.file_id = "";
    }

    containsHazardPhrases(): boolean {
      console.log(this.exposure);
      const hazardPhrases = ['H340', 'H350', 'H360'];
      return hazardPhrases.some(phrase => this.exposure.chemical.h_phrases.includes(phrase));
    }

    getHHPhrases() {
      this.productService.getHPhrases(this.selectedOrganisation.id, this.sareqIds);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: hPhrases => {
          if (hPhrases == null) {}
          else {
            for(var i = 0; i < hPhrases.length; i++){
              for(var j = 0; j < this.products.length; j++) {
                if(hPhrases[i].sareq_id == this.products[j].sareq_id) {
                  if(this.products[j].h_phrases == undefined) {
                    this.products[j]['h_phrases'] = [];
                  }
                  this.products[j]['h_phrases'].push(hPhrases[i].hphrases);
                }
              }
            }
            for(var j = 0; j < this.products.length; j++) {
              let temphphrases=[];
              let tempeuhphrases=[];
              if(this.products[j]['h_phrases']&&this.products[j]['h_phrases'].length>1){
                for(var k = 0; k < this.products[j]['h_phrases'].length; k++) {
                  if(this.products[j]['h_phrases'][k].toLowerCase().indexOf('euh')>-1){
                    tempeuhphrases.push(this.products[j]['h_phrases'][k]);
                  } else {
                    temphphrases.push(this.products[j]['h_phrases'][k]);
                  }
                }
                this.products[j]['h_phrases'] = _.uniq(temphphrases.sort().concat(tempeuhphrases.sort()));
              }
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }
