import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../../auth/auth.service';
import { SareqService } from 'src/app/services/sareq.service';
import { SupportService } from 'src/app/services/support.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { ModalAddGroupComponent } from 'src/app/modals/modal-add-group/modal-add-group.component';
import { ModalAddExcelComponent } from 'src/app/modals/modal-add-excel/modal-add-excel.component';
import { OrganisationService } from 'src/app/services/organisation.service';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UnsavedChangesService } from 'src/app/services/unsaved-changes.service';
import * as _ from "lodash";
declare var $: any;


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit, OnDestroy {

  itemsPerPage:number=10;
  pageNumber:number=1;
  loadingData: boolean=true;
  loadingFileData: boolean=false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  selectedProducts: any;
  inventoryfile: any;
  sareqProductsToAdd: any;
  sareqProducts: any=[];
  testproduct: boolean=false;
  products: any=[];
  subscribesToFreemium: any;
  subscribesToSpecialDemands: any;
  currentProducts: any;
  units: any;
  groups: any;
  newGroupName: any;
  inventoryfileName: any;
  companyArticles:any = [];
  productAddSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  order=['asc','asc','asc','asc'];
  searchAll:boolean=false;
  loadingButton: boolean=false;
  searchPageNumber0=1;
  numberOfItems0  = 100;
  numberOfItems1  = 100;
  totalAmount;
  orderBy = "";
  productNeedsToBeChecked = [];
  listOfDepartments: any = [];
  listOfDepartmentsDuplicate: any = [];
  listAlldepartments: any;
  departmentSearch: any = null;
  allDepartments: boolean = false;
  filteredlistOfDepartments: any = [];
  searchDepartment: string = "";
  departments: any = [];
  allDepartmentsArray: any = [];
  allDepartmentsText: string = "";
  backButtonClicked: boolean = false;

  constructor( private utilService: UtilService,
              private sareqService: SareqService,
              private translate: TranslateService,
              private authService: AuthService,
              private productService: ProductService,
              private uploadService: FileUploadService,
              private userService: UserService,
              private router: Router,
              private staticDataService: StaticDataService,
              private modalService: NgbModal,
              private organisationService: OrganisationService,
              private supportService: SupportService,
              private unsavedChangesService: UnsavedChangesService ) { }

  ngOnInit(): void {
    this.selectedProducts = [];
    this.inventoryfile = null;
    this.currentProducts = [];
    this.groups = [];
    this.units = this.staticDataService.getUnitDefinitions();
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
    {
      this.logout();
    }
    this.subscribesToFreemium = this.utilService.subscribesTo('subscription_freemium');
    this.subscribesToSpecialDemands = this.utilService.subscribesTo('subscription_special_demands');
    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }
    this.getAllDepartments();
    this.listOfDepartments = this.utilService.getAllSubdepartments(this.selectedOrganisation,false);
    this.filteredlistOfDepartments =  this.listOfDepartments;
    this.allDepartmentsArray = JSON.parse(localStorage.getItem('allDepartments', ));
    this.mapLevelsToFilteredDepartments();
    this.listOfDepartmentsDuplicate=_.cloneDeep(this.listOfDepartments);
    this.loadAllProducts();
    this.loadingData = true;

    setTimeout( () => {
      let element=document.getElementById ( 'productAddSearchname' );
         element.focus();
         this.allDepartmentsText = this.translate.instant("ALL_DEPARTMENTS");
         this.filteredlistOfDepartments.unshift({'name':this.allDepartmentsText,'id':0});
    }, 100 )

    this.organisationService.departmentChanged.pipe( take( 1 ) ).subscribe( ( msg: any ) => {
      this.utilService.departmentChangeClicked( this.router.url );
      this.router.navigate( ['/productlist'] );
    })
    this.loadingData = false;
  }

  ngOnDestroy(): void {
    this.unsavedChangesService.setUnsavedChanges(false);
  }

  hasUnsavedChanges(): boolean {
    return true;
  }

  markAsDirty() {
    this.unsavedChangesService.setUnsavedChanges(true);
  }

  logout() {
    this.authService.logout();
  }

  getOwnArticles() {
    this.organisationService.getProductArticleNumbers( this.organisation.id );
    this.organisationService.getOrganisationDataListener().pipe( take( 1 ) ).subscribe( {
      next: article => {
        if (article == null) {}
        else {
          this.companyArticles = article;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  goToProducts() {
    if ( this.selectedProducts.length == 0 ) {
      this.router.navigate(['/productlist']).then(() => {
        window.location.reload();
      });
      return;
    }
    this.backButtonClicked=true;
    Swal.fire({
      title: this.translate.instant('SAVE') + "?",
      text: this.translate.instant('SAVE_POST'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('NO'),
      confirmButtonText: this.translate.instant('YES')
    }).then((result) => {
      if (result.isConfirmed) {
        this.save();
        this.router.navigate(['/productlist']).then(() => {
          window.location.reload();
        });
      } else {
        this.backButtonClicked = false;
        this.router.navigate(['/productlist']).then(() => {
          window.location.reload();
        });
      }
    })
  }

  setUnit(product, value) {
    for ( var i = 0; i < this.units.length; i++ ) {
      if ( value == this.units[i].id ) {
        product.unit = this.units[i];
        break;
      }
    }
  }

  setGroupName( product, value ) {
    for ( var i = 0; i < this.groups.length; i++ ) {
      if (value == this.groups[i].name) {
        product.group = this.groups[i];
        product.group_name = product.group.name;
        break;
      }
    }
  }

  getGroups() {
    if (this.selectedDepartment) {
      this.productService.getGroups(this.selectedDepartment.id);
      this.productService.getProductGroupDataListener().pipe(take(1)).subscribe({
        next: groups => {
          this.groups = groups;
          this.groups = _.sortBy(this.groups, 'name');
          // this.groups.push({'name':'  '});
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              this.itemsPerPage = 10;
              return;
            }
          } else {
            this.itemsPerPage = 10;
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  addGroup() {
    const modalRef = this.modalService.open(ModalAddGroupComponent);
    modalRef.componentInstance.newGroupName = this.newGroupName;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((receivedGroupName) => {

      this.productService.createNewGroup(this.organisation.id, receivedGroupName);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: group => {
          if (group != null) {
            this.getGroups();
            // window.location.reload();
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    })
  }

  addProduct(product) {
    var i, productExists = false, addToList = true;
    product.department = [];
    product.department.push(this.selectedDepartment);
    product.filteredlistOfDepartments = [];
    product.filteredlistOfDepartments = this.filteredlistOfDepartments;

    if (this.companyArticles) {
      for (var k = 0; k < this.companyArticles.length; k++) {
        if (product.id == this.companyArticles[k].sareqid)
        {
          product.own_article_number = [];
          product.own_article_number = this.companyArticles[k].articlenumber;
        }
      }
    }

    product.new_own_article_number = "";
    product.new_own_article_number = product.own_article_number;
    if (this.selectedProducts) {
      for ( i = 0; i < this.selectedProducts.length; i += 1 ) {
        if ( product.id === this.selectedProducts[i].id ) {
          if ('amount' in product) {
            delete product.amount;
          }
          if ('annual_consumption' in product) {
            delete product.annual_consumption;
          }
          if ('maximum_amount' in product) {
            delete product.maximum_amount;
          }
          if ('unit' in product) {
            delete product.unit;
          }
          if ('group' in product) {
            delete product.group;
          }
          if ('isSelected' in product) {
            delete product.isSelected;
          }
          if ('new_own_article_number' in product) {
            delete product.new_own_article_number;
          }
          this.selectedProducts.splice(i, 1);
          addToList = false;
          if ( this.subscribesToSpecialDemands ) {
            this.removeproductNeedsToBeChecked(product);
          }
          break;
        }
      }
    }

    if (addToList) {
      // Check if product is already added to organisation
      for (i = 0; i < this.currentProducts.length; i += 1) {
          if (this.currentProducts[i].id === product.id) {
              productExists = true;
              delete product.isSelected;
              if ( this.subscribesToSpecialDemands ) {
                this.removeproductNeedsToBeChecked(product);
              }
              break;
          }
      }
      if (productExists) {
          Swal.fire(this.translate.instant("PRODUCT_ALREADY_EXISTS"),
                      "\"" + product.name + "\" " + this.translate.instant("POST_ALREADY_EXISTS_ON_DEPARTMENT"),
                      "error");
          delete product.isSelected;
      } else {
        product.isSelected=true;
        this.selectedProducts.push(product);
        this.markAsDirty();
        if ( this.subscribesToSpecialDemands ) {
          this.checkIfAllergyOrSpecialDemand(product);
        }
      }
    }
  }

  removeProduct(product) {
    if ( this.subscribesToSpecialDemands ) {
      this.removeproductNeedsToBeChecked(product);
    }
    var i;

    delete product.isSelected;
    for (i = 0; i < this.selectedProducts.length; i += 1) {
      if (product.id === this.selectedProducts[i].id) {
        delete product.amount;
        delete product.annual_consumption;
        delete product.maximum_amount;
        delete product.unit;
        delete product.group;
        this.selectedProducts.splice(i, 1);
        break;
      }
    }
  }

  getCurrentProducts() {
    if (this.selectedDepartment) {
      this.productService.AllMoreInformation(this.selectedDepartment.id);
      this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
        next: currentProducts => {
          this.currentProducts = currentProducts;
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  saveProductToList(product) {
    this.productService.addSareqProductToDepartment(this.selectedDepartment.id, product);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: currentProducts => {

      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  saveProductToListAndDepartment(product, department) {
    if ( department.id != this.selectedDepartment.id ) {
      let productCopy = { ...product };
      if ('isSelected' in productCopy) {
        delete productCopy.isSelected;
      }
      this.productService.addSareqProductToDepartment(department.id, productCopy);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: currentProducts => {},
        error: error => {
          console.log("Error: ", error);
        }
      });
    } else {
      this.productService.addSareqProductToDepartment(department.id, product);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: currentProducts => {},
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  // Renamed this as addProducts is a very confusing name
  save() {
    if ( this.isValidUnits ( this.selectedProducts ) != true ) {
      Swal.fire({
        title: this.translate.instant('UNIT_MISSING'),
        text:  this.translate.instant('UNIT_MISSING_TEXT'),
        icon: "error",
        confirmButtonText: this.translate.instant("OK"),
      });
      return;
    }
    let value = this.getSessionStorageItemWithExpiration('addProductOrganisation');
    let currentOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation')).id;
    if ( value ) {
      if ( value != currentOrganisation ) {
        Swal.fire ( this.translate.instant("WRONG_DEPARTMENT_HEADER"),
                    this.translate.instant("WRONG_DEPARTMENT_MESSAGE"),
                    "error" );
                  return;
      }
    }
    this.loadingButton=true;
    if ( this.subscribesToSpecialDemands &&
      this.productNeedsToBeChecked &&
      this.productNeedsToBeChecked.length > 0 ) {
        let needToBeCheckedText = "";
        let mailHeader =  "Sareqid\t"+
                          "Namn\t"+
                          "Tillverkare\n";
        let mailMessage = "";
        for ( var n=0, max = this.productNeedsToBeChecked.length; n < max; n++) {
          if ( n != max - 1) {
            needToBeCheckedText += this.productNeedsToBeChecked[n].name + "<br/>";
            mailMessage += this.productNeedsToBeChecked[n].id +
            "\t"+
            this.productNeedsToBeChecked[n].name +
            "\t"+
            this.productNeedsToBeChecked[n].supplier+"\n";
          } else {
            needToBeCheckedText += this.productNeedsToBeChecked[n].name;
            mailMessage += this.productNeedsToBeChecked[n].id +
            "\t"+
            this.productNeedsToBeChecked[n].name +
            "\t"+
            this.productNeedsToBeChecked[n].supplier+"\n";
          }
        }
        var object=({
          email: "this.currentUser.email",
          cc: "",
          subject: this.translate.instant( "PRODUCT_ALLERGY_HEADER"),
          body:
              "Företag: " + this.organisation.name + " (" + this.organisation.id + ")\n" +
              "--------------------------------------------\n" +
              "Meddelande: " + this.translate.instant("PRODUCT_ALLERGY_TEXT") +
              "\n\n"+
              mailHeader +
              mailMessage +
              "\n\n" + + "\n" +
              "--------------------------------------------\n" +
              "Avsändare: Namn: " + this.currentUser.username + "\n" +
              "            Email: " + this.currentUser.email + "\n" +
              "            Tel: " + this.currentUser.phone,

        });

        this.supportService.mail(object);
        this.supportService.getSupportDataListener().pipe(take(1)).subscribe({
          next: pdfContent => {

          },
          error: error => {
            console.log("Error: ", error);
            this.loadingButton=false;
          }
        });


        Swal.fire( this.translate.instant( "PRODUCT_ALLERGY_HEADER" ),
                    this.translate.instant("PRODUCT_ALLERGY_TEXT") +
                    "<br/><br/>" +
                    needToBeCheckedText +
                    "<br/><br/>" +
                    this.translate.instant("PRODUCT_ALLERGY_SUPPORTTEXT"),
                    "error" );
    }

    var time:number=0;
    if (this.subscribesToFreemium === true)
    {
      if (this.selectedProducts && this.currentProducts && (this.selectedProducts.length + this.currentProducts.length) > 20) {
        for (var i = 0; i < 20 - this.currentProducts.length; i += 1) {
          time+=300;
          this.saveProductToList(this.selectedProducts[i]);
        }
        Swal.fire(this.translate.instant("ADD_PRODUCTS"), this.translate.instant("MAXIMUM_LIMIT_FREMIUM"), "error");
      }
      else
      {
        if (this.selectedProducts) {
          for (var i = 0; i < this.selectedProducts.length; i += 1) {
            time+=300;
            this.saveProductToList(this.selectedProducts[i]);
          }
        }
      }
    }
    else
    {
      if (this.selectedProducts) {
        for (var i = 0; i < this.selectedProducts.length; i += 1) {
          for ( var n=0; n<this.selectedProducts[i].department.length; n++ ) {
              time+=300;
              this.saveProductToListAndDepartment(this.selectedProducts[i], this.selectedProducts[i].department[n]);
            }
          }
        }
      }

    setTimeout(() => {
      this.loadingButton=false;
      if (this.backButtonClicked != true ) {
        this.router.navigate(['/productlist']);
      }
    }, time);
  }

  downloadInventoryTemplate(){
    this.productService.fetchInventoryTemplate(this.selectedDepartment.id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: excelContent => {
        var opened=window.open(excelContent,"_blank");
        if(!opened){
          window.location.href=excelContent;
        }
      },
      error: error => {
        Swal.fire("Error excel", "Error", "error");
      }
    })
  }

  incomingfile(event) {
    this.inventoryfile = event.target.files[0];
    this.inventoryfileName = event.target.files[0].name;
  }

  loadFromFile() {
    this.loadingData = true;
    this.loadingFileData = true;
    if (this.inventoryfile) {
        // Upload file to server and save its id
        this.uploadService.upload(this.inventoryfile);
        this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
          next: response => {
            if (response.filename)
            {
              this.inventoryfile.id = response.filename;
              this.productService.postInventoryList(this.selectedDepartment.id, this.inventoryfile.id);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: info => {
                  if (info != null) {
                    this.sareqProductsToAdd = info;
                    if(info.length > 0){
                      this.showProductsToAdd();
                      this.loadingFileData = false;
                      this.loadingData = false;
                    }
                    else
                    {
                      this.loadingData = false;
                      this.loadingFileData = false;
                      Swal.fire("Ok", "Success");
                    }
                  }
                  this.inventoryfile = null;
                },
                error: error => {
                  console.log("Error: ", error);
                  this.inventoryfile = null;
                }
              })
            }
          },
          error: error => {
            console.log("Error: ", error);
            this.inventoryfile = null;
          }
      })
    }
  }

  showProductsToAdd() {
    const modalRef = this.modalService.open(ModalAddExcelComponent);
    modalRef.componentInstance.sareqProductsToAdd = this.sareqProductsToAdd;
  }

  loadAllProducts(){
    this.searchAll = true;
    this.loadingData = true;
    this.getItemsPerPage();
    this.getCurrentProducts();
    this.getGroups();
    this.getOwnArticles();
  }

  checkIfAllergyOrSpecialDemand(product) {
    if (
      (product.h_phrases.includes('H317') || product.h_phrases.includes('H334')) &&
      product.information === null &&
      product.training === null &&
      product.health_control === null &&
      product.fitness_for_duty === null &&
      product.no_special_demands === null &&
      (product.not_clp === null || product.not_clp == '0')
    ) {
        this.productNeedsToBeChecked.push( {
          'id'  : product.id,
          'name': product.name,
          'supplier': product.supplier_name
        } );
    }
  }

  removeproductNeedsToBeChecked(product) {
    this.productNeedsToBeChecked.forEach((element, index) => {
        if (element.id === product.id) {
            // Remove the item at the current index
            this.productNeedsToBeChecked.splice(index, 1);
        }
    });
  }

  getAllDepartments() {
    if (
      this.listAlldepartments == null ||
      (this.organisation &&
        this.organisation.id !=
          JSON.parse(localStorage.getItem("rootOrganisation")).id)
    ) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService
        .getOrganisationAllDepartmentNamesDataListener()
        .pipe(take(1))
        .subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  removeDepartment(department,product) {
    const indexToRemove = product.department.findIndex(item => item.id === department.id);
    if (indexToRemove !== -1 ) {
      product.filteredlistOfDepartments.push(department);
      product.department.splice(indexToRemove, 1);
    }
  }

  warningShow(){
    Swal.fire(this.translate.instant("DEP_LIST_NOT_EMPTY"),
      this.translate.instant("DEP_LIST_NOT_EMPTY_TEXT"),
      "error");
  }


    onSearchChangeDepartment(searchValue: string, departments, product): void {
      // Recursive function to collect all related departments (including subdepartments/orgs)
      const collectRelatedDepartments = (departmentId: number, collectedIds: Set<number>) => {
        // Add the current department ID to the set
        collectedIds.add(departmentId);

        // Find the department with the given ID
        const department = this.listOfDepartments.find(item => item.id === departmentId);

        // If the department has orgs, recursively collect their IDs
        if (department && department.orgs && department.orgs.length > 0) {
          department.orgs.forEach(orgId => {
            if (!collectedIds.has(orgId)) {
              collectRelatedDepartments(orgId.id, collectedIds);
            }
          });
        }
      };

      // Filter departments based on the search value
      const includedIds = new Set<number>();
      this.listOfDepartments.forEach(item => {
        if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
          collectRelatedDepartments(item.id, includedIds);
        }
      });

      // First add all departments before filtering
      product.filteredlistOfDepartments = [
        ...new Set([
          this.filteredlistOfDepartments[0], // Add the first item
          ...this.listOfDepartments.filter(item => includedIds.has(item.id))
        ])
      ];
    }

  setDepartment(department,product) {
    if ( department.id == 0 ) {
      this.selectAllDepartments(product);
      return;
    }
    var exists = product.department.some(departmentObj => departmentObj.id === department.id);
    if (exists == false) {
      product.department.push(department);
    }
  }

  setDepartmentFromAll(department,product) {
    var exists = product.department.some(departmentObj => departmentObj.id === department.id);
    if (exists == false && department.id != 0) {
      product.department.push(department);
    }
    product.filteredlistOfDepartments = this.listOfDepartments.filter(item =>
      !product.department.some(department => department.id === item.id)
    );
  }

  selectAllDepartments( product ) {
    this.listOfDepartments.forEach(department => {
      this.setDepartmentFromAll(department, product);
    });
  }

  getSessionStorageItemWithExpiration(key: string) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the current time with the expiry time
    if (now.getTime() > item.expiry) {
      // Item has expired, remove it from storage
      sessionStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  isValidUnits( products ){
    var isValid = true;
    products.forEach( ( Prod ) => {
      const { amount, annual_consumption, maximum_amount, unit } = Prod;
      if ( amount || annual_consumption || maximum_amount ) {
        if ( !unit || unit === '' || unit === null || (typeof unit === 'string' && unit.trim() === '')) {
          Prod.mark = true;
          isValid = false;
        }
      }
    });
    return isValid;
  }

  isUnit(product){
    if ( !product.unit || product.unit === '' || product.unit === null || (typeof product.unit === 'string' && product.unit.trim() === '')) {
      return false;
    }
    return true;
  }

  mapLevelsToFilteredDepartments() {
    this.filteredlistOfDepartments.forEach(filteredDepartment => {
      const matchingDepartment = this.allDepartmentsArray.find(department => department.org.id == filteredDepartment.id);
      if (matchingDepartment) {
        filteredDepartment.level = matchingDepartment.level;  // Map the level
        filteredDepartment.orgs = matchingDepartment.org.orgs;    // Map the subdepartments
      }
    });
  }
}




