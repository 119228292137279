import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.css']
})
export class ModalUploadFileComponent implements OnInit {

  file: any = null;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() no_upload: boolean = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private authService: AuthService, private uploadService: FileUploadService,) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  incomingfile(event) {
    this.file = event.target.files[0]; 
  }

  loadFromFile() {

    if (this.no_upload == true) {
      this.passEntry.emit((this.file) ? this.file : null);
      this.activeModal.close(ModalUploadFileComponent);
      return;
    }

    if (this.file) {
      // Upload file to server and save its id
      this.uploadService.upload(this.file);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: file_id => {
          this.passEntry.emit(file_id);
          this.activeModal.close(ModalUploadFileComponent);
        },
        error: error => {
          console.log("Error: ", error);
          this.activeModal.close(ModalUploadFileComponent);
        }
      })
    }
  }

  remove() {
    this.activeModal.close(ModalUploadFileComponent);
  }

  logout() {
    this.authService.logout();
  }
}
