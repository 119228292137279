<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3">
      <div class="text-gray-900 font-bold">{{"REPORT_STATUTORY" | translate}} ({{"HISTORY" | translate}})</div>
      <!-- Modal Body -->
      <div class="mt-10 p-2 text-center">
        <div *ngIf="loadingData==true" class="lex justify-center items-center mt-4 border border-gray-200">
          <div class="loader"></div>
        </div>
        <div *ngIf="loadingData==false" class="overflow-auto border border-gray-200  mb-20 ">
          <table datatable [dtOptions]="dtOptions" id="table">
            <thead class="bg-white">
              <tr>
                <th class="sort-header">{{"DATE" | translate}}</th>
                <th class="sort-header">{{"ACTION" | translate}}</th>
                <th class="sort-header">{{"COMMENT" | translate}}</th>
                <th class="sort-header">{{"PERFORMED_BY" | translate}}</th>
                <th class="sort-header">{{"SUPPLIER" | translate}}</th>
                <th class="sort-header">{{"PRODUCT" | translate}}</th>
                <th class="sort-header">{{"COMMON_USE" | translate}}</th>
                <th class="sort-header">{{"DEPARTMENT_INFO" | translate}}</th>
                <th class="sort-header">{{"DEPARTMENT" | translate}}</th>
                <th class="sort-header">{{"H_PHRASES" | translate}}</th>
                <th class="sort-header">{{"PICTOGRAM" | translate}}</th>
                <th class="sort-header">{{'HYGIENE_LIMITS' | translate}}</th>
                <th class="sort-header">{{"SUBSTANCES" | translate}}</th>
                <th class="sort-header">{{"AMOUNT" | translate}}</th>
                <th class="sort-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                <th class="sort-header">{{"EMISSIONS" | translate}}</th>
                <th class="sort-header">{{"CLASSIFICATION" | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of reportInformation.products">
                <td class="align-top">{{product.last_changed | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td class="align-top">
                  <span *ngIf="product.deleted&&product.deleted=='yes'">{{"DELETED" | translate}}</span>
                  <span *ngIf="product.added&&product.added=='yes'">{{"ADDED" | translate}}</span>
                  <span *ngIf="product.added!='yes'&&product.deleted!='yes'">{{"CHANGED" | translate}}</span>
                </td>
                <td class="align-top">
                  <span *ngIf="product.deleted&&product.deleted=='yes'&&product.deletedcomment">
                    <em>{{product.deletedcomment}}</em>
                  </span>
                </td>
                <td class="align-top">
                  <span *ngIf="product.username!=''&&product.username!='null'&&product.username!=null">{{product.username}}</span>
                  <span *ngIf="product.username==''||product.username=='null'||product.username==null">-</span>
                </td>
                <td class="align-top">{{product.supplier_name}}</td>
                <td class="align-top">{{product.name}}</td>
                <td class="align-top">{{product.com_use}}</td>
                <td class="align-top">{{product.department_info}}</td>
                <td class="align-top">{{departFullName(product.departmentid)}}</td>
                <td class="align-top">
                  <div *ngIf="product.h_phrases">
                    <ul *ngIf="product.h_phrases" class="list-disc list-inside">
                      <li *ngFor="let phrase of removeItemsWithSameName(product.h_phrases, 'hphrase')">
                        <small>{{phrase}}{{" - "}}{{"PHRASE_" + phrase | translate}}{{" "}}</small>
                      </li>
                    </ul>
                  </div>
                </td>
                <td class="align-top">
                  <div class="flex flex-row">
                    <span *ngFor="let pictogram of product.pictograms">
                      <img title="pictogram" class="w-6 h-6" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                    </span>
                  </div>
                </td>
                <td class="align-top">{{getHGV(product)}}</td>
                <td class="align-top">
                  <div class="flex flex-col" *ngFor="let substance of product.substances; last as isLast">
                    <span class="font-bold">{{substance.name}}</span>
                    <div class="flex flex-row">
                      <span class="font-bold">{{"CAS" | translate}}:</span>
                      <span class="text-sm">&nbsp;{{substance.cas}}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="font-bold">{{"RISK_PHRASES" | translate}}:</span>
                      <div *ngIf="substance.hphrases">
                        <span *ngFor="let phrase of substance.hphrases; last as isLastHPhrase"><small>{{isLastHPhrase ? phrase : phrase + ", "}}</small></span>
                      </div>
                      <div *ngIf="substance.rphrases">
                        <span *ngFor="let phrase of substance.rphrases; last as isLastRPhrase"><small>{{isLastRPhrase ? phrase : phrase + ", "}}</small></span>
                      </div>
                    </div>
                    <div class="flex flex-row">
                      <span class="font-bold">{{"CONCENTRATION" | translate}}: </span>
                      <span class="text-sm">&nbsp;{{substance.min}} - {{substance.max}} %</span>
                    </div>
                    <div class="flex flex-row">
                      <span class="font-bold">{{'HYGIENE_LIMITS' | translate}}: </span>
                      <span class="text-sm">{{getHGV(substance)}}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="font-bold">{{"RESTRICTION_LISTS" | translate}}:</span>
                      <small *ngIf="substance.list_reach_14">{{"Reach XIV"}}</small>
                      <small *ngIf="substance.list_reach_17">{{"Reach XVII"}}</small>
                      <small *ngIf="substance.list_reach_candidate">{{"Reach Kand."}}</small>
                      <small *ngIf="substance.list_chem_prio_u">{{"PRIO U"}}</small>
                      <small *ngIf="substance.list_chem_prio_p">{{"PRIO P"}}</small>
                      <small *ngIf="substance.list_water_directive">{{"Vattendir."}}</small>
                      <small *ngIf="substance.list_amv_a">{{"AMV A"}}</small>
                      <small *ngIf="substance.list_amv_b">{{"AMV B"}}</small>
                      <small *ngIf="substance.list_sin&&subscribesToSIN">{{"SIN"}}</small>
                      <small *ngIf="substance.list_BASTA&&subscribesToBASTA">{{"BASTA / ED"}}</small>
                    </div>
                    <div class="flex flex-col" *ngIf="subscribesToPop||subscribesToRohs">
                      <span class="font-bold" *ngIf="subscribesToPop&&!subscribesToRohs">{{"LIST_POP" | translate}}:</span>
                      <span class="font-bold" *ngIf="!subscribesToPop&&subscribesToRohs">{{"LIST_ROHS" | translate}}:</span>
                      <span class="font-bold" *ngIf="subscribesToPop&&subscribesToRohs">{{"HEADER_POP_ROHS" | translate}}:</span>
                      <small *ngIf="substance.list_pop&&subscribesToPop">{{"LIST_POP_SHORT" | translate}}</small>
                      <small *ngIf="substance.list_rohs&&subscribesToRohs">{{"LIST_ROHS_SHORT" | translate}}</small>
                    </div>
                  </div>
                </td>
                <td class="align-top">{{product.amount}} {{product.unit}}</td>
                <td class="align-top">{{product.annual}} {{product.unit}}</td>
                <td class="align-top">
                  <span *ngIf="product.fp_water || product.fp_air || product.fp_product || product.fp_waste">
                    <ul class="list-disc list-inside">
                      <li><span *ngIf="product.fp_water">{{"WATER" | translate}}</span></li>
                      <li><span *ngIf="product.fp_air">{{"AIR" | translate}}</span></li>
                      <li><span *ngIf="product.fp_product">{{"PRODUCT" | translate}}</span></li>
                      <li><span *ngIf="product.fp_waste">{{"WASTE" | translate}}</span></li>
                    </ul>
                  </span>
                </td>
                <td class="align-top">
                  <span *ngFor="let c_phrases of removeItemsWithSameName(product.c_phrases, 'statutory')">{{c_phrases}}{{' '}}<br/></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
