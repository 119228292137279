import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-report-allergenic',
  templateUrl: './report-allergenic.component.html',
  styleUrls: ['./report-allergenic.component.css']
})
export class ReportAllergenicComponent implements OnInit {

  dtOptions: any = {};

  @Input() reportInfo;

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  reportInformation: any = [];
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  currentLanguage: any;
  subscribesToClassification: any;
  subscribesToTransport: any;
  subscribesToSpecialDemands: any;
  listAlldepartments: any;
  loadingData: boolean = false;
  category: any = 'all';
  categoryName: any = "";
  reportSearch = {
    all:"",
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private utilService: UtilService,
              private authService: AuthService,
              private reportService: ReportService,
              private productService: ProductService,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder) { }

  ngOnInit(): void {

    this.reportInformation = {
      start_date: new Date("2015 1 January"),
      date: new Date(),
      reportType: "",
      name: "",
      id: "",
      includeSubDepartments: false,
      registerProducts: false,
      product: false
    };

    this.categoryName = this.translate.instant("ALL");
    this.reportInformation = this. reportInfo;
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToClassification = this.getSubscription('subscription_classification');
    this.subscribesToTransport = this.getSubscription('subscription_transport');
    this.subscribesToSpecialDemands = this.getSubscription('subscription_special_demands');

    this.currentLanguage = localStorage.getItem('usedLanguage');
    switch(this.currentLanguage) {
      case 'sv':
        this.currentLanguage = 0;
      break;

      case 'en':
        this.currentLanguage = 1;
      break;

      case 'fi':
        this.currentLanguage = 2;
      break;

      default:
        this.currentLanguage = 0;
      break;
    }

    if (this.reportInformation) {
      var result="";
      if (typeof this.reportInformation.date === 'object' && this.reportInformation.date !== null && 'toLocaleDateString' in this.reportInformation.date) {
        result = this.reportInformation.date.toLocaleDateString('sv');
      }else{
        result = this.reportInformation.date;
        this.reportInformation.date=new Date(this.reportInformation.date);
      }
      if(this.currentLanguage=='sv'||this.currentLanguage===0){
        this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        language:{"sEmptyTable": "Ingen data hittades",
        "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
        "sInfoEmpty": "Visar 0 till 0 av totalt 0",
        "sInfoFiltered": "(filtrerat från totalt _MAX_)",
        "sLoadingRecords": "Laddar...",
        "sSearch": "Filtrera:",
        "sLengthMenu": "Visa _MENU_ rader",
        "sZeroRecords": "Inga poster hittades",
        "oPaginate": {
            "sFirst": "Första",
            "sLast": "Sista",
            "sNext": "Nästa",
            "sPrevious": "Föregående"}
        },
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          }
        ]
      };
    }else{
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          }
        ]
      };
    }
  }

    this.getAllDepartments();
    this.getReport();
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  getReport() {

    this.loadingData = true;

    if (this.reportInfo) {

      var reportDate = new Date(this.reportInfo.date);
      reportDate = this.utilService.adjustDateForTimezoneOffset(reportDate);

      var info = {
        reportType: this.reportInfo.reportType.url,
        start_date: reportDate,
        date: reportDate,
        sub: this.reportInfo.includeSubDepartments,
        actual: this.reportInfo.Actual
      }

      this.reportService.getProducts(info);
      this.reportService.getReportDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {
            this.loadingData = false;
          }
          else {
            this.reportInformation.products = products;

            var ids = [];
            for (var i = 0; i < this.reportInformation.products.length; i++) {
              if ( this.subscribesToSpecialDemands )
              {
                this.isAllergicOrSpecialDemands(this.reportInformation.products[i]);
              }
                ids.push({id: this.reportInformation.products[i].sareq_id, lang: this.currentLanguage});
            }

            for (var i = 0; i < this.reportInformation.products.length; i++ ) {
                if( this.reportInformation.products[i].override != null) {
                  this.reportInformation.products.splice(i, 1);
                }
            }

            this.loadingData = false;

            if (this.currentLanguage != 0) {
              this.productService.checkForOtherLanguages(this.organisation.id, ids);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: otherLangs => {
                  if (otherLangs == null) {}
                  else {
                    for (var i = 0; i < this.reportInformation.products.length; i++){
                      for (var j = 0; j < otherLangs.length; j++) {
                        if (this.reportInformation.products[i].sareq_id == otherLangs[j].override) {

                            this.reportInformation.products[i].name = otherLangs[j].name;
                            this.reportInformation.products[i].sds_file_id = otherLangs[j].sds_file_id;
                            this.reportInformation.products[i].overridden = true;
                            this.reportInformation.products[i].origProdId = this.reportInformation.products[i].id;
                            this.reportInformation.products[i].id = otherLangs[j].id;
                        }
                      }
                    }
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                  this.loadingData = false;
                }
              })
            } else {
              for ( var i = 0; i < this.reportInformation.products.length; i++ )
              {
                if (this.reportInformation.products[i].override != undefined )
                {
                  this.reportInformation.products.splice( i, 1 );
                }
                else
                {

                }
              }
            }
            this.reportInformation.products.all = this.reportInformation.products;
            this.reportInformation.products.allergic = this.reportInformation.products.filter(product => product['allergic'] !== null && product['allergic'] !== undefined && product['allergic'] == 1);
            this.reportInformation.products.information = this.reportInformation.products.filter(product => product['information'] !== null && product['information'] !== undefined && product['information'] !== '');
            this.reportInformation.products.training = this.reportInformation.products.filter(product => product['training'] !== null && product['training'] !== undefined && product['training'] !== '');
            this.reportInformation.products.health_control = this.reportInformation.products.filter(product => product['health_control'] !== null && product['health_control'] !== undefined && product['health_control'] !== '');
            this.reportInformation.products.fitness_for_duty = this.reportInformation.products.filter(product => product['fitness_for_duty'] !== null && product['fitness_for_duty'] !== undefined && product['fitness_for_duty'] !== '');
            this.reportInformation.products.no_special_demands = this.reportInformation.products.filter(product => product['no_special_demands'] !== null && product['no_special_demands'] !== undefined && product['no_special_demands'] !== '');
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire({
            title: this.translate.instant("NO_PRODUCTS"),
            text: this.translate.instant("NO_PRODUCTS_WARNING"),
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK"),
            closeOnConfirm: true
          });
          this.loadingData = false;
        }
      })
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
  }

  remove() {
    this.activeModal.close(ReportAllergenicComponent);
  }

  isAllergicOrSpecialDemands(element) {
    if ( element.information === null &&
          element.training === null &&
          element.health_control === null &&
          element.fitness_for_duty === null &&
          element.no_special_demands === null &&
          element.allergic === null ) {
          element.hasBeenChecked = false;
    }
    else
    {
      element.hasBeenChecked = true;
    }
  }

  setCategory ( category ) {
    if ( category == this.category ){return;}

    if ( category == 'information' ) {
      this.category = 'information';
      this.categoryName = this.translate.instant("INFORMATION_DEMAND");
    }
    else if ( category == 'training' ) {
      this.category = 'training';
      this.categoryName = this.translate.instant("TRAINING");
    }
    else if ( category == 'health_control' ) {
      this.category = 'health_control';
      this.categoryName = this.translate.instant("HEALTH_CONTROL");
    }
    else if ( category == 'fitness_for_duty' ) {
      this.category = 'fitness_for_duty';
      this.categoryName = this.translate.instant("FITNESS_FOR_DUTY");
    }
    else if ( category == 'no_special_demands' ) {
      this.category = 'no_special_demands';
      this.categoryName = this.translate.instant("NO_SPECIAL_DEMANDS");
    }
    else if ( category == 'all' ) {
      this.category = 'all';
      this.categoryName = this.translate.instant("ALL");
    }
    else if ( category == 'allergic' ) {
      this.category = 'allergic';
      this.categoryName = this.translate.instant("ALLERGIC");
    }
    else {
      this.category = 'error';
      this.categoryName = this.translate.instant("ERROR");
    }
  }
}

