<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>
  
<div *ngIf="loadingData==false" class="flex flex-col px-4 max-w-full py-2">
    <div class="flex justify-between">
      <button (click)="startInventory()" *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
        currentUser.is_useradmin || currentUser.is_super_user)" class="button-cdocblue mt-4">
        <span class="text-white text-lg">{{"START_INVENTORY" | translate}}</span>
      </button>
    </div>
</div>

<div *ngIf="(!loadingData && ((!inventoryList || inventoryList.length == 0)))" class="flex flex-col bg-gray-200 border border-gray-400 px-7 m-4 py-2">
    <div class="text-lg font-bold">
      {{"INVENTORY" | translate}}
    </div>
    <div class="mt-4">
      {{"NO_INVENTORY_MESSAGE_1" | translate}}<br/>
      {{"NO_INVENTORY_MESSAGE_2" | translate}}
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="!loadingData" class="px-4 py-2 mb-20">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="inventorySearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                              <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                              <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th class="table-row-header" (click)="sortInventories('heading',0)">{{"NAME" | translate}}</th>
                  <th class="table-row-header" (click)="sortInventories('heading',6)">{{"RESPONSIBLE" | translate}}</th>
                  <th class="table-row-header" (click)="sortInventories('department_id',3)">{{"DEPARTMENTS" | translate}}</th>
                  <th class="table-row-header" (click)="sortInventories('start_date',1)">{{"START_DATE" | translate}}</th>
                  <th class="table-row-header" (click)="sortInventories('end_date',2)">{{"END_DATE" | translate}}</th>
                  <th class="table-row-header" (click)="sortInventories('status',5)">{{"STATUS" | translate}}</th>
                  <th class="table-row-header"></th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let inventory of inventoryList | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                <tr>
                    <td (click)="gotoInventoryModal(inventory)" class="table-row-column">{{inventory.name}}</td>
                    <td (click)="gotoInventoryModal(inventory)" class="table-row-column">{{inventory.issuer_name}}</td>
                    <td (click)="onTdClick($event)" [ngStyle]="{'background-color':inventory.status=='1' ? '#DAF7A6' : '' }" *ngIf="showSubdepartments && inventory.departmentArray.length > 0" class="table-row-column-department">
                        <div class="relative inline-block text-left p-1 w-auto border border-gray-300" appDropdown>
                            <button type="button" (click)="toggleDropdown($event, inventory)" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <span class="nav-home-text text-xs flex flex-col">
                                    {{"DEPARTMENTS" | translate}}&nbsp;({{inventory.departmentArray?.length}})
                                    <span *ngIf="inventory.departmentArray?.length > 1" class="text-[8px] italic">
                                        {{
                                            getTruncatedDepartments(
                                            inventory.departmentArray[0]?.name,
                                            inventory.departmentArray[1]?.name
                                            )
                                        }}
                                    </span>
                                    <span *ngIf="inventory.departmentArray?.length == 1" class="text-[8px] italic">
                                        {{ getTruncatedDepartment(inventory.departmentArray[0].name) }}
                                    </span>
                                </span>
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div [class.hidden]="!inventory.dropdownOpen" id="dropdownMenu" class="hidden dropdown-menu w-auto overflow-y-auto max-h-[200px] z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
                                <div class="py-1">
                                    <div *ngFor="let department of inventory.departmentArray" data-value="unit.id" class="dropdown-menu-item truncate"  (click)="goToInventory($event, department, inventory)" tabindex="1">
                                        <div *ngIf="department.productCountInvented==department.productCount+department.productNoSareqCount&&department.productCountApproved==0" class="flex flex-row items-center w-auto text-center">
                                            <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}}) 
                                                - <span class="text-green-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}}</span>
                                            </span>
                                        </div>
                                        <div *ngIf="department.productCountApproved>0" class="flex flex-row items-center w-auto text-center">
                                            <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}}) 
                                                - <span class="text-green-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}} - {{"DONE" | translate}}</span>
                                            </span>
                                        </div>
                                        <div *ngIf="department.productCountInvented!=department.productCount+department.productNoSareqCount&&department.productCountApproved==0" class="flex flex-row items-center w-auto text-center">
                                            <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}}) 
                                                - <span class="text-red-500">{{department.productCountInvented}}/{{department.productCount+department.productNoSareqCount}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td (click)="onTdClick($event)" *ngIf="!showSubdepartments" class="table-row-column-department">
                        <div class="relative inline-block text-left py-1 px-2 w-auto border border-gray-300">
                            <button type="button" (click)="goToInventory($event, selectedDepartment, inventory)" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <span class="nav-home-text text-xs flex flex-col">
                                    {{"DEPARTMENTS" | translate}}&nbsp;(1)
                                    <span class="text-[8px] italic">
                                        {{inventory.departmentArray[0].name}} - 
                                            <span class="text-green-500" *ngIf="inventory.departmentArray[0].productCountInvented==inventory.departmentArray[0].productCount+inventory.departmentArray[0].productNoSareqCount">
                                                {{inventory.departmentArray[0].productCountInvented}}/{{inventory.departmentArray[0].productCount+inventory.departmentArray[0].productNoSareqCount}}
                                            </span>
                                            <span class="text-green-500" *ngIf="inventory.departmentArray[0].productCountApproved>0">
                                                - {{"DONE" | translate}}
                                            </span>
                                            <span class="text-red-500" *ngIf="inventory.departmentArray[0].productCountInvented!=inventory.departmentArray[0].productCount+inventory.departmentArray[0].productNoSareqCount">
                                                {{inventory.departmentArray[0].productCountInvented}}/{{inventory.departmentArray[0].productCount+inventory.departmentArray[0].productNoSareqCount}}
                                            </span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </td>
                    <td (click)="gotoInventoryModal(inventory)" class="table-row-column">{{inventory.start_date | date:'yyyy-MM-dd'}}</td>
                    <td (click)="gotoInventoryModal(inventory)" class="table-row-column">{{inventory.end_date | date:'yyyy-MM-dd'}}</td>  
                    <td (click)="gotoInventoryModal(inventory)" class="table-row-column">{{inventory.status}}</td> 
                    <td class="table-row-column" *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                        currentUser.is_useradmin || currentUser.is_super_user)">
                        <div *ngIf="inventory.approved==0" class="flex flex-row">
                            <button class="button-green-little" title="{{'APPROVE' | translate}}" (click)="approveInventory(inventory)">
                                <span class="text-white whitespace-nowrap">{{"APPROVE" | translate}}</span>
                             </button>
                        </div>
                    </td>
                    <td class="table-row-column" *ngIf="currentUser && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                        currentUser.is_useradmin || currentUser.is_super_user)">
                        <a (click)="removeInventory(inventory)">
                            <svg class="h-4 w-4 cursor-pointer mt-2 ml-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
  </div>
  
