<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                <div class="loader"></div>
            </div>
            <div class="text-gray-900 font-bold text-lg">{{thisSareq.name}}</div>
            <!-- Modal Body -->
            <div *ngIf="loadingData==false" class="mt-2">
                <table class="table-auto bg-white mb-4 w-full">
                    <thead>
                        <tr>
                            <th class="table-row-header">{{"DATE" | translate}}</th>
                            <th class="table-row-header">{{"RESPONSIBLE" | translate}}</th>
                            <th class="table-row-header">{{"STATUS" | translate}}</th>
                            <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of sareqItems | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };">
                            <td class="table-row-column">{{items.created_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{items.assigned}}</td>
                            <td class="table-row-column">{{getStatus(items.status)}}</td>
                            <td class="table-row-column">{{items.risk_prio}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="p-6" colspan="2">
                                <div class="static inline-block text-left" appDropdown>
                                    <button type="button" class="button-gray">
                                        <span class="text-sm">{{"HISTORY" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                        <div class="py-1">
                                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="4">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
