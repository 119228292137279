import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SareqService } from 'src/app/services/sareq.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: "app-transportinfo",
  templateUrl: "./transportinfo.component.html",
  styleUrls: ["./transportinfo.component.css"],
})
export class TransportinfoComponent implements OnInit {
  loadingData: boolean;
  currentUser: any;
  displayedProducts: any = [];
  otherLangs: any = [];
  uploadTransportInfoDocument: boolean;
  itemsPerPage: number = 5;
  pageNumber:number=1;
  transport_un_nbr2=null;
  sortDirection="asc";

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private sareqService: SareqService,
    private organisationService: OrganisationService,
    private productService: ProductService,
    private translate: TranslateService,
    private utilService: UtilService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loadSareqProducts();
  }


  gotoControlPanel() {
    this.router.navigate(["/controlpanel"]);
  }

  loadSareqProducts() {
    this.displayedProducts = [];
    this.otherLangs = [];
    this.sareqService.getAllWithTransport();
    this.sareqService
      .getSareqDataListener()
      .pipe(take(1))
      .subscribe({
        next: (products) => {
          if (products == null) {
          } else {
            for (var n = 0; n < products.length; n++) {
              if (products[n].transport_un_nbr != "") {
                this.displayedProducts.push(products[n]);
              }
            }
            this.loadingData = false;
          }
        },
        error: (error) => {},
      });
  }

  openTransportInfoInNewWindow(product) {
    const filename = product.name + ".pdf";
    this.productService.getTransportInfoAsTrustedUrl(product.transport_un_file_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: (pdfContent) => {
        if (pdfContent == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_TRANSPORTINFO"),
            this.translate.instant("COULD_NOT_GET_TRANSPORTINFO_WARNING"),
            "error"
          );
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_TRANSPORTINFO"),
          this.translate.instant("COULD_NOT_GET_TRANSPORTINFO_WARNING"),
          "error"
        );
      },
    });
  }

  fileChange(event, product) {
    // 10 megaBytes
    if (event.target.files[0].size > 10 * 1024 * 1024) {
      Swal.fire(
        this.translate.instant("Filen är för stor"),
        this.translate.instant("Filen är för stor"),
        "error"
      );
      event.target.files = null;
      return;
    }
    product.uploadTransportInfoDocument = event.target.files[0];
    event.target.files = null;
  }

  uploadTransportInfoFile(product) {
    if (product.uploadTransportInfoDocument) {
      var getUploadTransportInfoDocument = new Promise((resolve, reject) => {
        this.utilService.uploadFile(product.uploadTransportInfoDocument);
        this.utilService
          .getUtilDataListener()
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              resolve(response.filename);
            },
            error: (error) => {
              console.log("Dochistory error: ", error);
              this.utilService.onError(error);
            },
          });
      });
    }

    Promise.all([getUploadTransportInfoDocument]).then((values) => {
      if (product.uploadTransportInfoDocument) {
        var object = {
          transport_id: product.transport_un_nbr,
          file_id: values[0],
        };
      }

      this.organisationService.setTransportInfo(object);
      this.organisationService
        .getOrganisationDataListener()
        .pipe(take(1))
        .subscribe({
          next: (object) => {
            Swal.fire({
              title: "",
              text: "Dokument uppladdat",
              icon: "info",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                product.uploadTransportInfoDocument = null;
                window.location.reload();
              }
            });
          },
          error: (error) => {
            Swal.fire("Fel med att uppdatera lista", "generiskt fel");
            product.uploadTransportInfoDocument = null;
          },
        });
    });
  }

  removeTransportInfoFile(product) {
    Swal.fire({
      title: this.translate.instant("REMOVE") + "?",
      text: this.translate.instant("REMOVE_TRANSPORTINFO_WARNING") + "?",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        var object=({
          'transport_id':product.transport_un_nbr,
          'file_id':product.transport_un_file_id
      });
        this.organisationService.removeTransportInfo(object);
        this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
          next: () => {
            setTimeout(function(){window.location.reload()},2000);
          },
          error: error => {
            Swal.fire(this.translate.instant("Didnt work"), this.translate.instant("Didnt work", "error"))
          }
        })
      } else if (result.isDenied) {
      }
    });

  }

  sortItem(type){
    if(this.sortDirection=='desc'){
      this.sortDirection='asc';
      this.displayedProducts.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? -1 : 1);
    }
    else if(this.sortDirection=='asc'){
      this.sortDirection='desc';
      this.displayedProducts.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? 1 : -1);
    }
  }

  logout() {
    this.authService.logout();
  }

  sortTable(header: string) {
    this.displayedProducts.sort((a, b) => {
      if (a[header] < b[header]) {
        return -1;
      } else if (a[header] > b[header]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
