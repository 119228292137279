import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-productapplication-all",
  templateUrl: "./productapplication-all.component.html",
  styleUrls: ["./productapplication-all.component.css"],
})

export class ProductapplicationAllComponent implements OnInit {
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  products: any = [];
  productApplications: any = [];
  productApplicationsDuplicate: any = [];
  productsOverride: any = [];
  showSubdepartments: boolean = true;
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  order = ["asc", "asc","asc", "asc", "asc","asc","asc", "asc","asc",];
  productApplicationStarted: boolean = false;
  productapplicationSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  sareqProducts: any=[];
  numberOfItems0  = 100;
  numberOfItems1  = 100;
  searchPageNumber0=1;
  totalAmount;
  orderBy = "";
  productSearch: boolean = false;
  productInSareq: boolean = true;
  productNotSareq: boolean = false;
  allDepartments: boolean = false;
  product: any = {
    name:null,
    supplier_name:null,
    id:null
  };
  listOfDepartments: any = [];
  showProductApplications;
  rowindex: number = null;
  selectAll: boolean = false;

  ngOnDestroy(): void {}

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private productApplicationService: ProductApplicationService,
    private sareqService: SareqService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );
    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartments")
    );
    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }
      this.getAllProductApplications('all');


    this.organisationService.departmentChanged
    .pipe( take( 1 ) )
    .subscribe( ( msg: any ) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.router.navigate(['/controlpanel']).then(() => {
        window.location.reload();
      })
    } );
    this.loadingData = false;
  }


  logout() {
    this.authService.logout();
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  openSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }

        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
}

openGrbInNewWindow(product) {
  var filename = product.name + '.pdf';
  this.productService.getGrbAsTrustedResourceUrl(product.id);
  this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
    next: pdfContent => {
      if (pdfContent == null) {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
      else
      {
        var opened=window.open(pdfContent,"_blank");
        if (opened) {
          const htmlContent = `
            <html>
              <head>
                <title>${product.name}</title>
              </head>
              <body>
                <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
              </body>
            </html>
          `;
          opened.document.write(htmlContent);
          opened.document.close();
        } else {
          window.location.href = pdfContent;
        }

      }
    },
    error: error => {
      Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
    }
  })
}

  getAllProductApplications( path ){
    this.productApplicationService.getAllProductApplications( path, '' );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.productApplications = responce;
          this.productApplicationsDuplicate = _.cloneDeep( this.productApplications );
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  getStatus( status ) {
    switch ( status ) {
      case 1:
        return this.translate.instant("SAREQ_STATUS_4");
      case 2:
        return this.translate.instant("APPROVED2");
      case 3:
        return this.translate.instant("NOT_APPROVE");
      default:
        return this.translate.instant("SAREQ_STATUS_4");
    }
  }

  sortProductsApplications ( property, sortIndex ) {
    if ( this.order[ sortIndex ]== 'asc' ) {
      this.order[ sortIndex ] = 'desc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['asc']);
    } else if ( this.order[ sortIndex ] == 'desc' ) {
      this.order[ sortIndex ] = 'asc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['desc'] );
    }
  }

  filterSearchItems() {
    this.productApplications = this.productApplicationsDuplicate;
    if ( this.productapplicationSearch.all == '' ) {
      this.productapplicationSearch.all=null;
    }
    if ( this.productapplicationSearch.all != null ) {
      this.productApplications = this.productApplications.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productapplicationSearch.all.toLowerCase())>-1);
    }
  }

  gotoControlPanel() {
    this.router.navigate( [ '/controlpanel' ] );
  }
}
