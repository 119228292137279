
<div #pdfContent>
    <div *ngIf="assessment" id="content" class="content p-4">
        <div class="page">
            <p class="text-4xl font-extrabold px-4 text-gray-900 text-center mb-6">
                {{ "ACTIVITY_RISK_ASSESSMENT" | translate }} (ARB)
            </p>
            <div class="flex flex-row w-full mb-4">
                <!-- Header Section -->
                <div class="flex flex-col md:flex-row justify-center items-center bg-cdocbanner pb-6 w-5/6">
                    <div class="flex-shrink-0" *ngIf="imageLogo">
                        <img class="pl-4 pt-4 mr-2" title="{{'ATTACHE_PICTURES' | translate}}" [src]="imageLogo" height="200" width="200" />
                    </div>
                    <div class="flex-grow text-center md:text-left">
                        <p class="text-3xl font-bold px-4 text-white break-all">{{ assessment?.heading }}</p>
                    </div>
                </div>

                <!-- Risk Indicator -->
                <div class="w-1/6">
                    <span class="bg-risk-1-color w-full h-full  text-white text-2xl font-bold flex items-center justify-center"
                        *ngIf="assessment.risk==0"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}"></span>
                    <span class="bg-risk-1-color w-full h-full  text-white text-2xl font-bold flex items-center justify-center"
                        *ngIf="assessment.risk==1"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_1_DESCRIPTION' | translate}}">
                        {{assessment.risk}}  {{'RISK_1' | translate }}</span>
                    <span class="bg-risk-2-color w-full h-full  w-20 h-20 text-white text-2xl font-bold flex items-center justify-center mx-1 pb-6 "
                        *ngIf="assessment.risk==2"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_2_DESCRIPTION' | translate}}">
                        {{assessment.risk}}  {{'RISK_2' | translate }}</span>
                    <span class="bg-yellow w-full h-full  w-20 h-20 text-white text-2xl font-bold flex items-center justify-center mx-1 pb-6 "
                        *ngIf="assessment.risk==3"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_3_DESCRIPTION' | translate}}">
                        {{assessment.risk}}  {{'RISK_3' | translate }}</span>
                    <span class="bg-risk-4-color w-full h-full flex items-center justify-center text-white text-2xl font-bold pb-6 "
                        *ngIf="assessment.risk==4"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_4_DESCRIPTION' | translate}}">
                        {{assessment.risk}} {{'RISK_4' | translate }}</span>
                    <span class="bg-risk-5-color w-full h-full w-20 h-20 text-white text-2xl font-bold flex items-center justify-center mx-1 pb-6 "
                        *ngIf="assessment.risk==5"
                        tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_5_DESCRIPTION' | translate}}">
                        {{assessment.risk}}  {{'RISK_5' | translate }}</span>
                </div>
            </div>

            <!-- Image Gallery -->
            <div class="p-4">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
                        <div class="flex flex-row">
                            <a>
                                <img title="{{ 'ATTACHE_PICTURES' | translate }}" [src]="image" [height]="pictureHeight" [width]="pictureWidth" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div>
                    <p class="text-xl">{{ 'DATE' | translate }}: {{ assessment?.arb_date | date: 'yyyy-MM-dd' }}</p>
                    <p class="text-xl">{{ 'DESCRIPTION' | translate }}: {{ assessment?.description }}</p>
                    <br/>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "RESPONSIBLE" | translate }}
                    </h2>
                    <p class="text-xl">
                        {{ assessment?.responsible }}
                    </p>

                </div>
            </div>

            <!-- Participants Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "PARTICIPANTS" | translate }}
                    </h2>
                    <p class="text-xl break-all whitespace-pre-line">
                        {{ assessment?.participants }}
                    </p>
                </div>
            </div>

            <!-- Risk Description Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "RISK_DESCRIPTION" | translate }}
                    </h2>
                    <p class="text-xl break-all whitespace-pre-line">
                        {{ assessment?.general_risks }}
                    </p>
                </div>
            </div>

            <!-- Risk Limitation Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "RISK_LIMITATION" | translate }}
                    </h2>
                    <ng-container *ngFor="let section of [
                    { title: 'VENTILATION', content: assessment?.ventilation },
                    { title: 'STORAGE2', content: assessment?.storage },
                    { title: 'HAND_PROTECTION', content: assessment?.hand_protection },
                    { title: 'EYE_PROTECTION', content: assessment?.eye_protection },
                    { title: 'RESPIRATORY', content: assessment?.respiratory },
                    { title: 'PROTECTIVE_CLOTHING', content: assessment?.body_protection },
                    { title: 'MISC', content: assessment?.miscellanious },
                    { title: 'EMERGENCY_PREPAREDNESS', content: assessment?.incident_info }
                    ]">
                        <p class="font-bold text-xl">
                            {{ section.title | translate }}
                        </p>
                        <p class="text-xl break-all whitespace-pre-line mb-2">
                            {{ section.content }}
                        </p>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="page-break"></div>
    </div>

    <!-- Risk Description Section -->
    <div *ngFor="let workstep of tempworksteps; let i = index">
        <div id="content" class="content p-4">
            <p class="text-4xl font-extrabold px-4 text-gray-900 text-center mb-6">
                {{ "ACTIVITY_RISK_ASSESSMENT" | translate }} (ARB)
            </p>
            <div class="page p-4">
                <!-- Header Section -->
                <div class="flex flex-col md:flex-row justify-center items-center bg-cdocbanner pb-6 w-full">
                    <div class="flex-shrink-0" *ngIf="imageLogo">
                        <img class="pl-4 pt-4" title="{{'ATTACHE_PICTURES' | translate}}" [src]="imageLogo" height="150" width="150" />
                      </div>
                      <div class="flex-grow text-center md:text-left p-4">
                        <p class="text-3xl font-semibold text-white break-all mb-2">
                          {{workstep.item}}. {{ "WORK_STEP" | translate }} {{workstep.isNotFirst!=false?'( forts )':''}}
                        </p>
                        <p class="text-2xl font-medium text-white break-all">
                          {{ workstep?.heading }}
                        </p>
                    </div>
                </div>

                <div *ngIf="workstep.isNotFirst!=true">
                  <div class="mb-4">
                      <h3 class="text-2xl font-bold uppercase border-b-2 pb-2 mb-2">
                          {{ "PRESENCE" | translate }}
                      </h3>
                      <p class="text-xl break-all whitespace-pre-line">{{ workstep?.place }}</p>
                  </div>

                  <div class="mb-4">
                      <h3 class="text-2xl font-bold uppercase border-b-2 pb-2 mb-2">
                          {{ "DESCRIPTION" | translate }}
                      </h3>
                      <p class="text-xl break-all whitespace-pre-line">{{ workstep?.risk_description }}</p>
                  </div>

                  <div class="flex flex-row mb-4">
                      <div class="w-1/2">
                          <h3 class="text-2xl font-bold uppercase border-b-2 pb-2 mb-2">
                              {{ "CONSEQUENCE" | translate }}
                          </h3>
                          <p class="text-xl">{{ workstep?.consequence }}</p>
                      </div>
                      <div class="w-1/2">
                          <h3 class="text-2xl font-bold uppercase border-b-2 pb-2 mb-2">
                              {{ "PROPABILITY" | translate }}
                          </h3>
                          <p class="text-xl">{{ workstep?.propability }}</p>
                      </div>
                  </div>

                  <div class="mb-4">
                      <h3 class="text-2xl font-bold uppercase border-b-2 pb-2 mb-2">
                          {{ "PICTOGRAM" | translate }}
                      </h3>
                      <div class="flex flex-wrap gap-2">
                          <span *ngFor="let protective of workstep.protectiveArray" tooltips tooltip-smart="true" [title]="protective.description">
                              <img title="protective.description" class="w-20 h-20" [src]="'/images/protective/' + protective.filename + '-small.png'" [alt]="protective.description" />
                          </span>
                      </div>
                  </div>
                </div>

                <div *ngIf="workstep.products?.length > 0" class="mt-10 mb-4">
                    <h3 class="text-2xl font-bold uppercase">{{ "CHEMICALS" | translate }}:</h3>
                </div>
                <div *ngFor="let product of workstep.products" class="mb-4 border border-black">
                    <div class="m-1">
                        <h2 class="text-2xl font-bold mb-2">{{ product?.product_name }}</h2>
                        <p class="text-xl font-semibold mb-1">{{ product?.supplier_name }}</p>
                        <p class="text-xl font-bold mb-1">{{ "RISK_LEVEL" | translate }}: {{ product?.risk_prio }}</p>

                        <p class="text-xl mb-1">
                            {{ "ALLERGIC" | translate }}:
                            <span *ngIf="product?.allergic === 1" class="font-bold">{{ "YES" | translate }}</span>
                            <span *ngIf="product?.allergic !== 1" class="font-bold">{{ "NO" | translate }}</span>
                        </p>

                        <p class="text-xl mb-1">
                            {{ "HGV" | translate }}:
                            <span *ngIf="product?.hygiene_limits === 1" class="font-bold">{{ "YES" | translate }}</span>
                            <span *ngIf="product?.hygiene_limits !== 1 && product?.no_hygiene_limits === 1" class="font-bold">{{ "NO" | translate }}</span>
                            <span *ngIf="product?.hygiene_limits !== 1 && product?.no_hygiene_limits !== 1" class="font-bold">{{ "-" | translate }}</span>
                        </p>

                        <div class="flex flex-wrap gap-2 mt-2">
                            <span *ngFor="let pictogram of product.pictograms" tooltips tooltip-smart="true" [title]="pictogram.description">
                                <img title="pictogram.description" class="w-20 h-20" [src]="'/images/pictograms/' + pictogram.filename + '-small.png'" [alt]="pictogram.description" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-break"></div>
        </div>
    </div>
</div>


