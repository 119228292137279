import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-productapplication-add",
  templateUrl: "./productapplication-history.component.html",
  styleUrls: ["./productapplication-history.component.css"],
})

export class ProductapplicationHistoryComponent implements OnInit {
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  productApplications: any = [];
  productApplicationsDuplicate: any = [];
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  productApplicationStarted: boolean = false;
  rowindex: number = null;
  departmentsIds: any = [];
  departmentsNames: any = "";
  departmentsDisplayed: any = "";
  productapplicationSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  order:Array<String>= ['asc', 'asc', 'asc', 'asc', 'asc',
                        'asc', 'asc', 'asc', 'asc', 'asc',
                        'asc', 'asc', 'asc', 'asc', 'asc',
                        'asc', 'asc'];

  ngOnDestroy(): void {}

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private productApplicationService: ProductApplicationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );
    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.getAllDepartments();

    setTimeout( () =>{
      this.getAllProductApplications('');
      this.loadingData = false;
    },500)

    this.organisationService.departmentChanged
    .pipe( take( 1 ) )
    .subscribe( ( msg: any ) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.loadingData = true;
      this.router.navigate(['/productapplicationlist']).then(() => {
        window.location.reload();
      })
    } );
  }

  logout() {
    this.authService.logout();
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  getAllDepartments() {
    if (this.listAlldepartments==null||this.organisation&&this.organisation.id!=JSON.parse(localStorage.getItem('rootOrganisation', )).id) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      } )
    }
    else {
      return (null);
    }
  }

  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  getProductApplicationDepartmentNames() {
    this.departmentsIds = [];
    this.departmentsNames = "";
    if (this.listAlldepartments) {
      this.departmentsIds = this.productApplications[0].departments.split(',');
      for(var j = 0; j < this.departmentsIds.length; j++) {
        for(var i = 0; i < this.listAlldepartments.length; i++) {
          if (this.departmentsIds[j] == this.listAlldepartments[i].id)
          {
            this.departmentsNames += this.listAlldepartments[i].name;
            this.departmentsNames += ", "
            this.departmentsDisplayed = this.truncateText(this.departmentsNames, 15);
          }
        }
      }
      this.departmentsNames = this.departmentsNames.slice(0, -1);
    }
  }

  openSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
}

openGrbInNewWindow(product) {
  var filename = product.name + '.pdf';
  this.productService.getGrbAsTrustedResourceUrl(product.id);
  this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
    next: pdfContent => {
      if (pdfContent == null) {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
      else
      {
        var opened=window.open(pdfContent,"_blank");
        if (opened) {
          const htmlContent = `
            <html>
              <head>
                <title>${product.name}</title>
              </head>
              <body>
                <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
              </body>
            </html>
          `;
          opened.document.write(htmlContent);
          opened.document.close();
        } else {
          window.location.href = pdfContent;
        }
      }
    },
    error: error => {
      Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
    }
  })
}

  getAllProductApplications( path ){
    if ( path != 'all') {
      path = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
    }
    this.productApplicationService.getAllHistoryProductApplications( path );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.productApplications = responce;
          this.productApplicationsDuplicate = _.cloneDeep( this.productApplications );
          this.getProductApplicationDepartmentNames()
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  getStatus( status ) {
    switch ( status ) {
      case 1:
        return this.translate.instant("SAREQ_STATUS_4");
      case 2:
        return this.translate.instant("APPROVED2");
      case 3:
        return this.translate.instant("NOT_APPROVE");
      case 10:
        return this.translate.instant("DELETED");
      default:
        return this.translate.instant("SAREQ_STATUS_4");
    }
  }

  goToProductApplication() {
    this.router.navigate( [ "/productapplicationlist" ] );
  }

  gotoProductApplicationItem(item) {
    this.router.navigate( [ '/productapplicationitem', item.id ] );
  }

  newProductApplication() {
      this.router.navigate( [ '/productapplicationadd' ] );
  }

  listProductApplication() {
    this.router.navigate( [ '/productapplicationlist' ] );
  }

  sortProductsApplications ( property, sortIndex ) {
    if ( this.order[ sortIndex ]== 'asc' ) {
      this.order[ sortIndex ] = 'desc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['asc']);
    } else if ( this.order[ sortIndex ] == 'desc' ) {
      this.order[ sortIndex ] = 'asc';
      this.productApplications = _.orderBy( this.productApplications, [ property ], ['desc'] );
    }
  }

  filterSearchItems() {
    this.productApplications = this.productApplicationsDuplicate;
    if ( this.productapplicationSearch.all == '' ) {
      this.productapplicationSearch.all=null;
    }
    if ( this.productapplicationSearch.all != null ) {
      this.productApplications = this.productApplications.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productapplicationSearch.all.toLowerCase())>-1);
    }
  }
}
