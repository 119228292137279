<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
              <div class="loader"></div>
            </div>
            <div *ngIf="!loadingData&&inventoryEdit" class="text-gray-900 font-bold">
              {{ "EDIT_INVENTORY" | translate }}
            </div>
            <div *ngIf="!loadingData&&!inventoryEdit" class="text-gray-900 font-bold">
              {{ "ADD_INVENTORY" | translate }}
            </div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4 text-left">
                        <label class="font-bold mb-1">{{"NAME" | translate}}</label><br>
                        <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="inventory.name" (ngModelChange)="inventory.name=$event" type="text">
                    </div>
                    <div class="mb-4 text-left flex flex-row">
                        <div class="w-1/2">
                            <label class="font-bold mb-1">{{"START_DATE" | translate}}</label><br>
                            <input title="{{'START_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="inventory.start_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?inventory.start_date = $event:null" [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="w-1/2">
                            <label class="font-bold mb-1">{{"END_DATE" | translate}}</label><br>
                            <input title="{{'END_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="inventory.end_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?inventory.end_date = $event:null" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="flex flex-col mb-4">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" id="searchNav" (input)="departmentSearchItems()" [(ngModel)]="departmentSearch" class="prompt" 
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_DEPARTMENT' | translate}} ..." />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mb-4">
                        <div class="overflow-x-auto">
                          <table class="table-auto w-full bg-white mb-4 border border-gray-400 p-2">
                            <thead class="bg-white">
                                <tr>
                                  <th class="table-row-header w-10"></th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr (click)="selectThisDepartment('all')">
                                <td class="table-row-column">
                                  <div *ngIf="allDepartments">
                                    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 0h48v48H0z" fill="none"/>
                                      <g id="Shopicon">
                                        <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095 
                                          11.905,36.095 11.905,11.905 	"/>
                                        <polygon fill="#00ff00" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                                      </g>
                                    </svg>
                                  </div>
                                  <div *ngIf="!allDepartments">
                                    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                                    </svg>
                                  </div>
                                </td>
                                <td class="table-row-column">{{'ALL_DEPARTMENTS' | translate}}</td>
                              </tr>
                              <ng-container *ngFor="let department of listOfDepartments">
                                <tr (click)="selectThisDepartment(department)">
                                  <td class="table-row-column">
                                    <div *ngIf="department.selected">
                                      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h48v48H0z" fill="none"/>
                                        <g id="Shopicon">
                                          <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095 
                                            11.905,36.095 11.905,11.905 	"/>
                                          <polygon fill="#00ff00" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                                        </g>
                                      </svg>
                                    </div>
                                    <div *ngIf="!department.selected">
                                      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                                      </svg>
                                    </div>
                                  </td>
                                  <td class="table-row-column">{{department.name}}<br/>{{"( "}}{{departFullName(department)}}{{" )"}}</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="flex flex-col mb-2">
                        <label>{{"COMMENTS" | translate}}</label>
                        <textarea placeholder="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2 text-sm" rows = "4" maxlength="400"
                                  [(ngModel)]="inventory.comments" (ngModelChange)="inventory.comments = $event"
                                  [ngModelOptions]="{standalone: true}"></textarea>
                      </div>
                </div>
            </div>
            <!-- Modal Footer -->
            <div class="flex justify-center items-center mt-4">
                <button type="button" 
                    class="button-green" 
                    (click)="save()">
                    {{"SAVE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
