<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
  <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200 mb-20">
  <div class="flex flex-row mt-2">
    <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="goToProductApplication()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
        <path fill="#000000"
              d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
      </svg>
    </button>
    <div class="flex flex-row justify-center items-center w-full">
      <span class="text-xl font-bold px-8 py-2 text-black">{{'PRODUCT_APPLICATION' | translate}}</span>
    </div>
  </div>

  <div *ngIf="loadingData==false" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
    <div class="w-2/3 mt-2 mb-2">
      <div class="flex flex-col px-4">
        <p class="text-gray-200"><a><strong>{{productApplication.name}}</strong></a></p>
        <span class="text-white text-sm mr-4">{{productApplication.supplier_name}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="loadingData==false" class="flex flex-col w-full p-4 mb-10">
    <!-- Form Container -->
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
      <div class="flex flex-col w-full mt-2">
        <label class="font-bold">{{"STATUS" | translate}}</label>
        <div class="mb-1">
          <div class="flex items-center space-x-2 mt-4 py-1 px-4 mr-2">
            <input title="{{'SAREQ_STATUS_4' | translate}}" type="radio" value="new" [(ngModel)]="approvalStatus" [checked]="approvalStatus == 'new'">
            <label class="text-sm">{{"SAREQ_STATUS_4" | translate}}</label>
          </div>
        </div>
        <div class="mb-1">
          <div class="flex items-center space-x-2 py-1 px-4 mr-2">
            <input title="{{'APPROVE_APPLICATION' | translate}}" type="radio" value="approved" [(ngModel)]="approvalStatus" [checked]="approvalStatus == 'approved'">
            <label class="text-sm">{{"APPROVE_APPLICATION" | translate}}</label>
          </div>
        </div>
        <div class="mb-4">
          <div class="flex items-center space-x-2 py-1 px-4 mr-2">
            <input title="{{'NOT_APPROVE_APPLICATION' | translate}}" type="radio" value="rejected" [(ngModel)]="approvalStatus" [checked]="approvalStatus == 'rejected'">
            <label class="text-sm">{{"NOT_APPROVE_APPLICATION" | translate}}</label>
          </div>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"COMMENTS_APPROVAL" | translate}}</label><br>
          <textarea title="{{'COMMENTS_APPROVAL' | translate}}" class="w-full border border-gray-300 p-2" placeholder="" rows = "2"
                    [(ngModel)]="productApplication.approval_comment"
                    (ngModelChange)="productApplication.approval_comment = $event"  [ngModelOptions]="{standalone: true}">{{productApplication?.approval_comment}}</textarea>
        </div>
      </div>
      <div class="flex flex-row justify-center mb-2 mt-2">
        <button class="button-red" title="{{'REMOVE' | translate}}" (click)="deletePost(productApplication.id)">
          <img title="{{ 'REMOVE' | translate }}" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'REMOVE' | translate }}</span>
        </button>
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
          <img title="{{ 'SAVE' | translate }}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
        <button *ngIf="productApplication.status!=1&&productApplication.status!=3&&productApplication.sareq_id!=null" class="button-cdocblue" title="{{'SAVE' | translate}}" (click)="addProducts()">
          <span class="text-white text-lg">{{ 'ADD_PRODUCT_TO_ORG' | translate }}</span>
        </button>
      </div>
    </div>

    <!-- Form Container -->
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mt-2 mb-2 p-7">
      <div class="mb-4" (click)="productSearch=!productSearch">
        <button *ngIf="!productSearch" class="button-green" title="{{'SEARCH_PRODUCT' | translate}}">{{'SEARCH_PRODUCT' | translate}}</button>
        <button *ngIf="productSearch" class="button-green" title="{{'HIDE_PRODUCTTABLE' | translate}}">{{'HIDE_PRODUCTTABLE' | translate}}</button>
      </div>
      <hr/>
      <div *ngIf="productSearch">
        <app-sareq-search (dataOutput)="addProduct($event)"></app-sareq-search>
      </div>
      <div class="flex flex-col w-full mt-4">
        <div class="flex flex-col md:flex-row mb-4">
          <div class="flex flex-col w-full md:w-1/3 mr-4">
            <label class="font-bold">{{"PRODUCT_NAME" | translate}}</label>
            <input title="{{'PRODUCT_NAME' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'PRODUCT_NAME' | translate}}"
              [(ngModel)]="productApplication.name" (ngModelChange)="productApplication.name = $event"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex flex-col w-full md:w-1/3 mr-4">
            <label class="font-bold">{{"SUPPLIER" | translate}}</label>
            <input title="{{'SUPPLIER' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'SUPPLIER' | translate}}"
              [(ngModel)]="productApplication.supplier_name" (ngModelChange)="productApplication.supplier_name = $event"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex flex-col w-full md:w-1/3">
            <div class="flex flex-col">
              <span class="font-bold mb-2 mr-1">{{"PRODUCT_NOT_SAREQ" | translate}}</span>
              <input title="{{'PRODUCT_NOT_SAREQ' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="productInSareq==false" (click)="changeProductNotSareq()">
            </div>
          </div>
        </div>
        <div *ngIf="productInSareq==false" class="mt-4 mb-4">
          <div *ngIf="currentFiles?.length > 0" class="eight wide column">
            <span class="font-bold">{{"ATTACHED_SDS" | translate}}</span>
            <span *ngFor="let file of currentFiles">
                <div class="flex flex-row items-center px-7 py-1">
                    <a (click)="openDocumentInNewWindow(productApplication.id)">
                        <img class="w-6 h-6 cursor-pointer" title="Substitution" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                    </a>
                    <p>{{file.filename}}</p>
                    <a *ngIf="currentUser.is_super_user" class="ml-4">
                        <i aria-hidden="true" (click)="removeDocument(file.fileid)">
                            <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </i>
                    </a>
                </div>
            </span>
        </div>
          <div class="flex flex-col w-full" *ngIf="!currentFiles || ( currentFiles && currentFiles.length < 1 )">
            <span class="font-bold">{{"ATTACHE_OWN_SDS" | translate}}</span>
            <div class="flex flex-col md:flex-row mb-4">
              <div class="flex flex-col w-full md:w-1/3 mr-4">
                <input title="{{'ATTACHE_FILES' | translate}}" class="button-white border border-gray-300 mr-1" type="file" id="upload-file" accept="application/pdf" (change)="selectFile($event)" />
              </div>
              <div class="flex flex-col w-full md:w-1/3 mr-4">
                <div class="flex flex-col">
                  <span class="font-bold mb-2 mr-1">{{"SEND_SDS_TO_SUPPORT" | translate}}</span>
                  <input title="{{'SEND_SDS_TO_SUPPORT' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="sendToSupport==true" (click)="changeSendToSupport()">
                </div>
              </div>
              <div class="flex flex-col w-full md:w-1/3">
                <button class="button-green w-40" title="{{'UPLOAD' | translate}}" (click)="uploadSds()">
                  <img title="icon" src="images/icons/upload-svgrepo-com.svg" class="nav-home">
                  <span class="text-lg">{{ 'UPLOAD' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label class="font-bold">{{"USAGE" | translate}}</label>
          <input title="{{'USAGE' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'USAGE' | translate}}" maxlength="50"
            [(ngModel)]="productApplication.usage" (ngModelChange)="productApplication.usage = $event"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="flex flex-row mb-4">
          <div class="flex flex-col w-1/2 mr-4">
            <label class="font-bold">{{"ANNUAL_CONSUMPTION" | translate}}</label>
            <input title="{{'ANNUAL_CONSUMPTION' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'ANNUAL_CONSUMPTION' | translate}}" maxlength="50"
              [(ngModel)]="productApplication.amount" (ngModelChange)="productApplication.amount = $event"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex flex-col w-1/2">
            <label class="font-bold">{{"UNIT" | translate}}</label>
            <div class="flex flex-row justify-between items-center w-full relative inline-block text-left" appDropdown>
              <button type="button" class="button-white border border-gray-300 justify-between w-full">
                  <span class="text-sm">{{productApplication.unit}}</span>
                  <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </button>
              <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                  <div class="py-1">
                    <div *ngFor="let unit of units" class="item" data-value="unit.id" class="dropdown-menu-item" (click)="setUnit(productApplication, unit.id)" tabindex="1">{{ unit.id }}</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label>{{"EXIST_DEPARTMENTS" | translate}}</label>
          <div class="flex flex-row items-center">
            <div class="mt-4">
                <input st-search="" type="search" id="searchNav" (input)="departmentSearchItems()" [(ngModel)]="departmentSearch" class="prompt"
                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_DEPARTMENT' | translate}} ..." />
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <div class="overflow-x-auto">
            <table class="table-auto w-full bg-white mb-4 border border-gray-400">
              <thead class="bg-white">
                  <tr>
                    <th class="table-row-header w-10"></th>
                    <th class="table-row-header">{{"DEPARTMENT" | translate}} </th>
                  </tr>
              </thead>
              <tbody>
                <tr (click)="selectThisDepartment('all')">
                  <td class="table-row-column">
                    <div *ngIf="allDepartments">
                      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h48v48H0z" fill="none"/>
                        <g id="Shopicon">
                          <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095
                            11.905,36.095 11.905,11.905 	"/>
                          <polygon fill="#00ff00" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                        </g>
                      </svg>
                    </div>
                    <div *ngIf="!allDepartments">
                      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                      </svg>
                    </div>
                  </td>
                  <td class="table-row-column">{{'ALL_DEPARTMENTS' | translate}}</td>
                </tr>
                <ng-container *ngFor="let department of listOfDepartments">
                  <tr (click)="selectThisDepartment(department)">
                    <td class="table-row-column">
                      <div *ngIf="department.selected">
                        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 0h48v48H0z" fill="none"/>
                          <g id="Shopicon">
                            <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095
                              11.905,36.095 11.905,11.905 	"/>
                            <polygon fill="#00ff00" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                          </g>
                        </svg>
                      </div>
                      <div *ngIf="!department.selected">
                        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                        </svg>
                      </div>
                    </td>
                    <td class="table-row-column">{{department.name}}<br/>{{"( "}}{{departFullName(department)}}{{" )"}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label>{{"COMMENTS" | translate}}</label>
          <textarea placeholder="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2 text-sm" rows = "4" maxlength="400"
                    [(ngModel)]="productApplication.comments" (ngModelChange)="productApplication.comments = $event"
                    [ngModelOptions]="{standalone: true}"></textarea>
        </div>
        <div class="flex flex-col justify-start items-start mb-10">
          <div class="flex items-center space-x-2 mt-4 py-2 px-4">
            <input title="{{'EMAIL_PRODUCT_APPLICATION_SUPPORT' | translate}}" class="form-checkbox h-6 w-6 text-cdocblue" type="checkbox" (checked)="productApplication.product_application_mail_support == true"
              [(ngModel)]="productApplication.product_application_mail_support"
              (ngModelChange)=" changeProductApplicationMailSupport( $event )"
              [ngModelOptions]="{standalone: true}">
            <span class="text-sm mr-1 mt-4 mb-4">{{"EMAIL_PRODUCT_APPLICATION_SUPPORT" | translate}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center mb-4 mt-4">
      <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
      </button>
    </div>
  </div>
</div>

