<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="overflow-auto mb-20">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <div class="flex flex-row mt-2">
            <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="goToAssessmentList()">
              <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
                <path fill="#000000"
                      d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
              </svg>
            </button>
            <div class="flex flex-row justify-center items-center w-full">
              <span class="text-xl font-bold px-8 py-2 text-black">{{'ACTIVITY_RISK_ASSESSMENT' | translate}}</span>
            </div>
        </div>

        <div *ngIf="assessment.heading!=''" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2 mt-2">
            <p class="text-xl font-bold px-4 text-white break-all w-1/3">{{assessment?.heading}}</p>
        </div>
        <div *ngIf="assessment.heading==''" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2 mt-2">
            <p class="text-xl font-bold px-4 text-white break-all w-1/3">{{"HEADING" | translate}}</p>
        </div>

        <div *ngIf="loadingData==false&&assessment" class="flex flex-col w-full py-2 px-4">
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                  <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"DATE" | translate}}</label><br>
                        <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="assessment.arb_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?assessment.arb_date = $event:null" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"REGISTRAR" | translate}}</label><br>
                        <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text" placeholder="{{assessment.issuer.first_name}} {{assessment.issuer.last_name}}" [required]="true" [disabled]="true">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}</label><br>
                        <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.responsible" (ngModelChange)="assessment.responsible=$event" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="flex-1">
                        <label class="font-bold mb-1">{{"STATUS" | translate}}</label>
                        <div class="flex justify-between items-center w-full relative inline-block text-left z-30" appDropdown>
                            <button type="button" class="button-white justify-between w-full items-start border border-gray-300 mr-2">
                            <span *ngIf="assessment.status==0">{{'ONGOING' | translate}}</span>
                            <span *ngIf="assessment.status==1">{{'FOR_VERIFICATION' | translate}}</span>
                            <span *ngIf="assessment.status==2">{{'FOR_VERIFICATION' | translate}}</span>
                            <span *ngIf="assessment.status==3">{{'APPROVED2' | translate}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <div data-value="0" class="dropdown-menu-item" (click)="assessment.status=0" tabindex="1">{{'ONGOING' | translate}}</div>
                                    <div data-value="2" class="dropdown-menu-item" (click)="assessment.status=2" tabindex="2">{{'FOR_VERIFICATION' | translate}}</div>
                                    <div data-value="3" class="dropdown-menu-item" (click)="assessment.status=3" tabindex="3">{{'APPROVED2' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HEADING" | translate}}</label><br>
                        <input title="{{'HEADING' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.heading" (ngModelChange)="assessment.heading=$event" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"PARTICIPANTS" | translate}}</label><br>
                        <input title="{{'PARTICIPANTS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.participants" (ngModelChange)="assessment.participants=$event" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}</label><br>
                        <textarea title="{{'DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.description" (ngModelChange)="assessment.description=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}</label><br>
                        <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" accept="application/pdf" (change)="incomingfile($event)" />
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"ATTACHE_PICTURES" | translate}}</label><br>
                        <input title="{{'ATTACHE_PICTURES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" accept=".png, .jpg, .jpeg" (change)="incomingimage($event)" />
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="loadingData==false&&assessment" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RISK_DESCRIPTION" | translate}}</label><br>
                        <textarea title="{{'RISK_DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.general_risks" (ngModelChange)="assessment.general_risks=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </div>

                <div class="flex justify-between">
                    <p class="text-xl font-bold mt-6 px-4">{{"WORK_STEP" | translate}}</p>
                    <button *ngIf="currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin
                            || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)"
                            (click)="addWorkstep()" class="button-cdocblue mt-4">
                        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        <span class="hidden md:block text-white font-bold">{{"ADD" | translate}} {{"WORK_STEP" | translate}}</span>
                    </button>
                </div>

                <div class="overflow-x-auto">
                    <table class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"HEADING" | translate}}</th>
                                <th class="table-row-header">{{"DESCRIPTION" | translate}}</th>
                                <th class="table-row-header">{{"PRESENCE" | translate}}</th>
                                <th class="table-row-header">{{"CONSEQUENCE" | translate}}</th>
                                <th class="table-row-header">{{"PROPABILITY" | translate}}</th>
                                <th class="table-row-header">{{"COMMENTS2" | translate}}</th>
                                <th class="table-row-header"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let workstep of assessment.worksteps; let i = index" (click)="workstepSelected(workstep, i)">
                                <td class="table-row-column">{{workstep.heading}}</td>
                                <td class="table-row-column">{{workstep.place}}</td>
                                <td class="table-row-column">{{workstep.risk_description}}</td>
                                <td class="table-row-column">{{workstep.consequence}}</td>
                                <td class="table-row-column">{{workstep.propability}}</td>
                                <td class="table-row-column">{{workstep.comments}}</td>
                                <td class="table-row-column" (click)="workstepRemoved(i)">
                                    <svg class="w-10 h-6 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927
                                        21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125
                                        15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208
                                        3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="flex justify-between">
                    <p class="text-xl font-bold mt-6 px-4">{{"CHEMICALS" | translate}}</p>
                </div>

                <div class="overflow-x-auto">
                    <table class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                                <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                                <th class="table-row-header">{{"HGV" | translate}}</th>
                                <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of products; let i = index">
                                <td class="table-row-column">{{product.product_name}}</td>
                                <td class="table-row-column">{{product.supplier_name}}</td>
                                <td class="table-row-column">{{product.risk_prio}}</td>
                                <td class="table-row-column" *ngIf="product.allergic==0">{{"NO" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.allergic==1">{{"YES" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits==1">{{"YES" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits!=1&&product.no_hygiene_limits==1">{{"NO" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits!=1&&product.no_hygiene_limits!=1">{{"-" | translate}}</td>
                                <td class="table-row-column">
                                <img class="-mr-3" *ngIf="subscribesToSds" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                <img *ngIf="subscribesToGrb && product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="loadingData==false&&assessment" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <span class="text-lg font-bold mb-4">{{"RISK_LIMITATION" | translate}}</span>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"VENTILATION" | translate}}</label><br>
                        <textarea title="{{'VENTILATION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.ventilation" (ngModelChange)="assessment.ventilation=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"STORAGE2" | translate}}</label><br>
                        <textarea title="{{'STORAGE2' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.storage" (ngModelChange)="assessment.storage=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HAND_PROTECTION" | translate}}</label><br>
                        <textarea title="{{'HAND_PROTECTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.hand_protection" (ngModelChange)="assessment.hand_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"EYE_PROTECTION" | translate}}</label><br>
                        <textarea title="{{'EYE_PROTECTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.eye_protection" (ngModelChange)="assessment.eye_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RESPIRATORY" | translate}}</label><br>
                        <textarea title="{{'RESPIRATORY' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.respiratory" (ngModelChange)="assessment.respiratory=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"PROTECTIVE_CLOTHING" | translate}}</label><br>
                        <textarea title="{{'PROTECTIVE_CLOTHING' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.body_protection" (ngModelChange)="assessment.body_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"MISC" | translate}}</label><br>
                        <textarea title="{{'MISC' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.miscellanious" (ngModelChange)="assessment.miscellanious=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"EMERGENCY_PREPAREDNESS" | translate}}</label><br>
                        <textarea title="{{'EMERGENCY_PREPAREDNESS' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.incident_info" (ngModelChange)="assessment.incident_info=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="loadingData==false&&assessment" class="flex flex-col w-full py-2 px-4">
            <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RISK" | translate}}:</label><br>
                        <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                            <button type="button" class="button-white w-full justify-between border border-gray-300">
                                <span class="text-sm">{{assessment?.risk}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                                <div *ngFor="let i of [1, 2, 3, 4, 5]" data-value="i" class="dropdown-menu-item" (click)="assessment.risk=i" tabindex="i">{{i}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <span class="text-lg font-bold mb-2">{{"ACTIONS" | translate}}</span>
                    <div class="flex flex-row mb-4">
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'SUBSTITUTION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="substitution" [(ngModel)]="substitution" (ngModelChange)="substitution=$event" (change)="onToggleShowSubstitution" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"SUBSTITUTION" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'OUT_PHASING' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="phasing_out" [(ngModel)]="phasing_out" (ngModelChange)="phasing_out=$event" (change)="onToggleShowOutphasing" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"OUT_PHASING" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'RISK_MINIMISATION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="risk_minimzation" [(ngModel)]="risk_minimzation" (ngModelChange)="risk_minimzation=$event" (change)="onToggleShowRiskMinimzation" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"RISK_MINIMISATION" | translate}}</span>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
                        <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.comments" (ngModelChange)="assessment.comments=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-center mb-20 mt-4">
            <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
              <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
              <span class="text-lg">{{ 'SAVE' | translate }}</span>
            </button>
        </div>
    </div>
</div>

