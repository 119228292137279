<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
    <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200 mb-20">
    <div class="flex flex-row mt-2">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
            <path fill="#000000"
                  d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
          </svg>
        </button>
        <div class="flex flex-row justify-center items-center w-full">
          <span class="text-xl font-bold px-8 py-2 text-black">{{'COORDINATOR' | translate}}</span>
        </div>
    </div>

    <div *ngIf="loadingData==false" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
        <div class="w-2/3 mt-2 mb-2">
          <div class="flex flex-col px-4">
            <p class="text-gray-200"><a><strong>{{coordinatorSubject.name}}</strong></a></p>
          </div>
        </div>
    </div>

    <div *ngIf="loadingData==false" class="flex flex-col w-full p-4 mb-10">
        <!-- Form Container -->
        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
              <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"PRIORITY" | translate}}</label><br>
                    <input title="{{'PRIORITY' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="coordinatorSubject.prio" (ngModelChange)="coordinatorSubject.prio = $event" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"STATUS" | translate}}</label><br>
                    <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                        <button type="button" class="button-white w-full justify-between border border-gray-300">
                            <span>{{statusName}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                            <div class="py-1">
                                <div *ngFor="let status of availableCoordinatorSubjectStatus" data-value="status" class="dropdown-menu-item" (click)="setStatus(status)" tabindex="1">{{ status.name }}</div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"DUE_DATE" | translate}}</label><br>
                    <input title="{{'DUE_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="coordinatorSubject.due | date:'yyyy-MM-dd'" (ngModelChange)="$event?coordinatorSubject.due = $event:null" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"CONDUCTED" | translate}}</label><br>
                    <input title="{{'CONDUCTED' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="coordinatorSubject.done | date:'yyyy-MM-dd'" (ngModelChange)="$event?coordinatorSubject.done = $event:null" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}</label><br>
                    <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="coordinatorSubject.responsible" (ngModelChange)="coordinatorSubject.responsible = $event" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
        </div>
        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"HEADING" | translate}}</label><br>
                    <input title="{{'ISSUE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="coordinatorSubject.name" (ngModelChange)="coordinatorSubject.name = $event" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="mb-4">
                    <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}</label><br>
                    <textarea class="w-full border border-gray-300 p-2" rows="5" placeholder="{{coordinatorSubject.body}}" [(ngModel)]="coordinatorSubject.body" (ngModelChange)="coordinatorSubject.body = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                </div>

                <div class="mb-4">
                    <label class="font-bold mb-1">{{"COMMENT" | translate}}</label><br>
                    <textarea class="w-full border border-gray-300 p-2" rows="5" placeholder="{{coordinatorSubject.comment}}" [(ngModel)]="coordinatorSubject.comment" (ngModelChange)="coordinatorSubject.comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                </div>

                <div class="mb-4">
                    <label class="font-bold mb-1">{{"DOCUMENT" | translate}}</label><br>
                    <div *ngFor="let file of coordinatorSubject?.Metadata">
                        <div *ngIf="file!=null">
                            <a (click)="openFileInNewWindow(file)"
                                *ngIf="(file?.new=='new' && file?.image != 'yes' ) || (file?.new!='new' && !file['mime-type']||file['mime-type']=='application/pdf')">
                                <span class="flex items-center text-black">
                                <img [ngClass]="{'cursor-pointer': file.new != 'new'}" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{file.filename}}"/>
                                <span [ngClass]="{'cursor-pointer': file.new != 'new'}">{{file.filename}}</span>
                                <a class="ml-2" (click)="removeFile(file)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                                </span>
                            </a>
                            <a (click)="openImageFileInNewWindow(file)"
                                *ngIf="( file.new=='new' && file.image == 'yes' ) || (file.new!='new' &&  file['mime-type'] && file['mime-type'].startsWith('image/'))">
                                <span class="flex items-center text-black">
                                    <img [ngClass]="{'cursor-pointer': file.new != 'new'}" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/image-combiner-svgrepo-com.svg" area-hidden="true" title="{{file.filename}}"/>
                                    <span [ngClass]="{'cursor-pointer': file.new != 'new'}">{{file.filename}}</span>
                                    <a class="ml-2" (click)="removeFile(file)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    </a>
                                </span>
                            </a>
                        </div>
                    </div>
                    <input title="{{'DOCUMENT' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" accept=".pdf, image/*" (change)="incomingfile($event)"/>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-end mb-4 mt-4">
            <button class="button-cdocblue" title="{{'ADD_CHEMICAL' | translate}}" (click)="addChemical=!addChemical">
                <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                    <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <span class="text-white text-lg">{{ 'ADD_CHEMICAL' | translate }}</span>
            </button>
        </div>
        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-4">
            <div class="overflow-x-auto w-full mt-2">
                <table *ngIf="addChemical" class="table-auto w-full bg-white mb-4 border border-gray-400" id="productaddtable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <div class="flex flex-row">
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.all" class="prompt" [ngModelOptions]="{standalone: true}"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header" colspan="12">
                                <div class="flex flex-row">
                                    <div class="mt-2 mr-2 mb-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.name" class="prompt" [ngModelOptions]="{standalone: true}"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                                    </div>
                                    <div class="mt-2 mb-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.supplier_name" class="prompt" [ngModelOptions]="{standalone: true}"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header sort-header" st-sort="name">{{"NAME" | translate}}</th>
                            <th class="table-row-header sort-header" st-sort="supplier_name">{{"MANUFACTURER" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products
                            | filter : productAddSearch.all
                            | filterBy  : ['name'] : productAddSearch.name
                            | filterBy  : ['supplier_name'] : productAddSearch.supplier_name
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                            (click)="addProduct(product)" [ngStyle]="product.isSelected && {'background-color': 'rgb(217, 232, 202)'}">
                            <td class="table-row-column"><strong>{{product.name}}</strong></td>
                            <td class="table-row-column">{{product.supplier_name}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="p-6" colspan="1">
                                <div class="relative inline-block text-left" appDropdown>
                                    <button type="button" class="button-gray">
                                        <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                        <div class="py-1">
                                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item">5</a>
                                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item">10</a>
                                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item">25</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="4">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <table class="table-auto w-full bg-white mb-4 border border-gray-400 mb-4" id="worksteptable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                            <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                            <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                            <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                            <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
                            <th class="table-row-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of coordinatorSubject.products; let i = index">
                            <td class="table-row-column">{{product.name}}</td>
                            <td class="table-row-column">{{product.supplier_name}}</td>
                            <td class="table-row-column">{{product.risk_prio}}</td>
                            <td class="table-row-column">
                                <div class="flex flex-row">
                                    <img *ngIf="subscribesToSds" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                </div>
                            </td>
                            <td class="table-row-column">
                                <div class="flex flex-row">
                                    <span *ngFor="let pictogram of product.pictograms">
                                        <img title="pictogram" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                    </span>
                                </div>
                            </td>
                            <td class="table-row-column">
                                <div class="flex flex-row">
                                    <span *ngFor="let protective of product.protectiveArray">
                                        <img title="protective" src="/images/protective/{{protective.filename}}-tiny.png" />
                                    </span>
                                </div>
                            </td>
                            <td class="table-row-column" (click)="removeProduct(product)">
                                <a >
                                    <svg class="h-6 w-6 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex flex-row justify-center mb-4 mt-4">
                    <button class="button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
                        <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
                        <span class="text-lg">{{ 'REMOVE' | translate }}</span>
                    </button>
                    <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
                        <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                        <span class="text-lg">{{ 'SAVE' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>




