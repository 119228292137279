<div class="container mr-auto px-4 mt-2">
  <ul class="flex flex-col md:flex-row mb-2">
    <li class="mr-1">
        <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
    </li>
    <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
      <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
    </li>
  </ul>
</div>

<div class="max-w-screen m-4 mb-40 md:mb-20" *ngIf="isArticles">
  <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
  </div>
  <div *ngIf="loadingData==false" class="px-6 py-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
      <div class="flex flex-col">
          <div class="flex flex-row justify-between items-center mt-4">
            <button class="button-cdocblue mb-2" title="{{'SHOW_EXCEL_FORM' | translate}}" (click)="addArticleFromExcel()">
              <span class="text-lg text-white">{{ 'SHOW_EXCEL_FORM' | translate }}</span>
            </button>
            <div class="flex flex-row">
              <div class="mr-4 cursor-pointer" (click)="editArticle()">
                <img title="{{'EDIT' | translate}}" src="images/icons/edit-svgrepo-com.svg" class="h-10 w-10">
              </div>
              <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
                <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                <span class="text-lg">{{ 'SAVE' | translate }}</span>
              </button>
            </div>
          </div>
          <div *ngIf="showArticleAddExcell" class="flex flex-col mt-4">
              <div class="w-full md:w-1/3 py-2">
                  <button class="button-gray" title="{{'DOWNLOAD_USER_TEMPLATE' | translate}}" (click)="createArticleTemplate()">
                      <svg class="w-6 h-6" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="text-lg text-black">{{ 'DOWNLOAD_USER_TEMPLATE' | translate }}</span>
                  </button>
                  <div class="flex flex-row mt-1">
                      <input title="{{'LOAD_FROM_FILE' | translate}}" class="button-white border border-gray-300 mr-1 mt-4" type="file" id="upload-file" (change)="incomingfile($event)" />
                      <button class="px-2 mt-4" title="{{'LOAD_FROM_FILE' | translate}}" (click)="loadFromFile()">
                          <svg class="w-10 h-10" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <span *ngIf="showArticleAddExcell" class="font-xl font-bold">{{"ARTICLES_TO_BE_ADDED" | translate}}</span>
      <div class="overflow-x-auto">
        <table *ngIf="showArticleAddExcell" class="table-auto w-full bg-white mb-4 border border-gray-400 mt-2" st-table="userArticleList" st-safe-src="userArticleList" id="articletable">
            <thead class="bg-white">
                <tr>
                  <th class="table-row-header">{{"PRODUCT" | translate}}</th>
                  <th class="table-row-header">{{"OWN_ARTICLE" | translate}}</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let article of userArticleList">
                <td class="table-row-column">{{article.Product}}</td>
                <td class="table-row-column">{{article.Article}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <div *ngIf="showArticleAddExcell" class="flex flex-row justify-center mb-4 mt-4">
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="saveTable()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4" st-table="customerArticles" st-safe-src="customerArticles">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header" colspan="12">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="articleSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th st-sort="supplier_name" (click)="sortItem(0)" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                  <th st-sort="name" (click)="sortItem(1)" class="table-row-header sort-header">{{"PRODUCT" | translate}}</th>
                  <th class="table-row-header" (click)="sortItem(2)">{{"OWN_ARTICLE" | translate}}</th>
                  <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                  <th class="table-row-header" *ngIf="enableEditArticles"></th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let article of customerArticles
                    | filter : articleSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };">
                <td class="table-row-column">{{article.supplier_name}}</td>
                <td class="table-row-column">{{article.name}}</td>
                <td class="table-row-column">
                  <input title="{{'EDIT' | translate}}" class="border border-gray-300 p-1" *ngIf="enableEditArticles" [(ngModel)]="article.customerarticle">
                  <span *ngIf="!enableEditArticles">{{article.customerarticle}}</span>
                </td>
                <td class="table-row-column">
                  <div class="flex flex-row">
                      <img (click)="openDocumentInNewWindow(article)" *ngIf="article.customerarticle_file_id" class="w-8 h-8 cursor-pointer" title="{{article.customerarticle_file_name}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                  </div>
                </td>
                <td class="table-row-column" *ngIf="enableEditArticles">
                  <div class="flex flex-row" *ngIf="!article.customerarticle_file_id">
                      <input #fileInput class="border border-gray-200 p-2 w-60" title="file" type="file" id="upload-file" accept=".doc,.docx,.xls,.xlsx,.pdf" (change)="thisIncomingFile($event,article)"/>
                  </div>
                  <div *ngIf="article.customerarticle_file_id" class="flex flex-row hover:bg-gray-200" >
                    <i aria-hidden="true" (click)="removeDocument(article)">
                        <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                            21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                            5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                            3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                            stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </i>
                      <label class="cursor-pointer">{{"REMOVE" | translate}}</label>
                  </div>
              </td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="1">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class=" text-sm">{{"OWN_ARTICLE" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static left-40" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=15;pageNumber=1" class="dropdown-menu-item" tabindex="3">15</a>
                                </div>
                                </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
      </div>
  </div>
</div>

