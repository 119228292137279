import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { HelpComponent } from 'src/app/settings/help/help.component';
import { ContactformComponent } from 'src/app/settings/contactform/contactform.component';
import { UserProfileComponent } from 'src/app/settings/user-profile/user-profile.component';
import { CompanyUsersComponent } from 'src/app/settings/company-users/company-users.component';
import { EditUserComponent } from 'src/app/settings/company-users/edit-user/edit-user.component';
import { ControlpanelComponent } from 'src/app/controlpanel/controlpanel.component';
import { ManufacturerComponent } from 'src/app/controlpanel/manufacturer/manufacturer.component';
import { ManufactureritemComponent } from 'src/app/controlpanel/manufacturer/manufacturer-item/manufacturer-item.component';
import { SubstancesComponent } from 'src/app/controlpanel/substances/substances.component';
import { EditSubstanceComponent } from 'src/app/controlpanel/substances/edit-substance/edit-substance.component';
import { SareqComponent } from 'src/app/controlpanel/sareq/sareq.component';
import { DepartmentsComponent } from 'src/app/settings/departments/departments.component';
import { ProductGroupComponent } from 'src/app/settings/product-group/product-group.component';
import { CompanyInfoComponent } from 'src/app/settings/company-info/company-info.component';
import { CompanyMiscComponent } from 'src/app/settings/company-misc/company-misc.component';
import { EditSareqComponent } from 'src/app/controlpanel/sareq/edit-sareq/edit-sareq.component';
import { CompanyArticlesComponent } from 'src/app/settings/company-articles/company-articles.component';
import { StampedSdsComponent } from 'src/app/settings/stamped-sds/stamped-sds.component';
import { AddSareqComponent } from 'src/app/controlpanel/sareq/add-sareq/add-sareq.component';
import { RestrictionComponent } from 'src/app/controlpanel/restriction/restriction.component';
import { ProductsComponent } from 'src/app/products/products.component';
import { OrganisationComponent } from 'src/app/controlpanel/organisation/organisation.component';
import { AddProductComponent } from 'src/app/products/add-product/add-product.component';
import { ProductItemComponent } from 'src/app/products/product-item/product-item.component';
import { OrganisationEditUserComponent} from 'src/app/controlpanel/organisation/organisation-edit-user/organisation-edit-user.component'
import { MateriallistComponent } from 'src/app/materiallist/materiallist.component';
import { AddMaterialComponent } from 'src/app/materiallist/add-material/add-material.component';
import { EditMaterialComponent } from 'src/app/materiallist/edit-material/edit-material.component';
import { TransportinfoComponent } from 'src/app/controlpanel/transportinfo/transportinfo.component';
import { ReportsComponent } from 'src/app/reports/reports.component';
import { ReportSimplifiedComponent } from 'src/app/reports/report-simplified/report-simplified.component';
import { AssessmentsComponent } from 'src/app/assessments/assessments.component';
import { AddAssessmentComponent } from 'src/app/assessments/add-assessment/add-assessment.component';
import { EditAssessmentComponent } from 'src/app/assessments/edit-assessment/edit-assessment.component';
import { WorkstepComponent } from 'src/app/assessments/workstep/workstep.component';
import { AssessmentsChemicalComponent } from 'src/app/assessments-chemical/assessments-chemical.component';
import { AssessmentChemicalEditComponent } from 'src/app/assessments-chemical/assessment-chemical-edit/assessment-chemical-edit.component';
import { NewfreemiumComponent } from 'src/app/controlpanel/newfreemium/newfreemium.component';
import { NewsComponent } from 'src/app/controlpanel/news/news.component';
import { CoordinatorComponent } from 'src/app/coordinator/coordinator.component';
import { ExposuresComponent } from 'src/app/exposures/exposures.component';
import { EditExposureComponent } from 'src/app/exposures/edit-exposure/edit-exposure.component';
import { AddExposureComponent } from 'src/app/exposures/add-exposure/add-exposure.component';
import { HistoryComponent } from 'src/app/history/history.component';
import { SubstitutionComponent } from 'src/app/substitution/substitution.component';
import { AddCoordinatorComponent } from 'src/app/coordinator/add-coordinator/add-coordinator.component';
import { EditCoordinatorComponent } from 'src/app/coordinator/edit-coordinator/edit-coordinator.component';
import { EditSubstitutionComponent } from 'src/app/substitution/edit-substitution/edit-substitution.component';
import { WorkstepSubstitutionComponent } from 'src/app/substitution/workstep-substitution/workstep-substitution.component';
import { ControlpanelcoordinatorComponent } from 'src/app/controlpanel/controlpanelcoordinator/controlpanelcoordinator.component';
import { KebnekaiseComponent } from 'src/app/controlpanel/kebnekaise/kebnekaise.component';
import { LawComponent } from 'src/app/law/law.component';
import { CatalogueComponent } from 'src/app/catalogue/catalogue.component';
import { CatalogueEditComponent } from 'src/app/catalogue/catalogue-edit/catalogue-edit.component';
import { CustomeractionsComponent } from 'src/app/controlpanel/customeractions/customeractions.component';
import { RegisterComponent } from 'src/app/auth/register/register.component';
import { ProductsEditComponent } from 'src/app/products/products-edit-all/products-edit-all.component';
import { ProductapplicationAddComponent } from 'src/app/productapplication/productapplication-add/productapplication-add.component';
import { ProductapplicationListComponent } from 'src/app/productapplication/productapplication-list.component';
import { ProductapplicationItemComponent } from 'src/app/productapplication/productapplication-item/productapplication-item.component';
import { ProductapplicationHistoryComponent } from 'src/app/productapplication/productapplication-history/productapplication-history.component';
import { ProductapplicationAllComponent } from 'src/app/productapplication/productapplication-all/productapplication-all.component';
import { DashboardAdminComponent } from 'src/app/dashboard/dashboard-admin/dashboard-admin.component';
import { DashboardHistoryStatisticsComponent } from 'src/app/dashboard/dashboard-history-statistics/dashboard-history-statistics.component';
import { DashboardYearlyReportComponent } from 'src/app/dashboard/dashboard-yearly-report/dashboard-yearly-report.component';
import { ProductItemRiskComponent } from './products/product-item-risk/product-item-risk.component';
import { OrganisationEditComponent } from './controlpanel/organisation/organisation-edit/organisation-edit.component';
import { AssessmentPdfComponent } from './assessments/assessment-pdf/assessment-pdf.component';
import { SubstitutionProductsComponent } from './controlpanel/substitution-products/substitution-products.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryItemComponent } from './inventory/inventory-item/inventory-item.component';

const routes: Routes = [
  { path: 'login', component:LoginComponent },
  { path: 'register', component:RegisterComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboardadmin', component: DashboardAdminComponent, canActivate: [AuthGuard] },
  { path: 'help', component:HelpComponent, canActivate: [AuthGuard] },
  { path: 'userprofile', component:UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'contactform', component:ContactformComponent, canActivate: [AuthGuard] },
  { path: 'companyusers', component:CompanyUsersComponent, canActivate: [AuthGuard] },
  { path: 'editdepartments', component:DepartmentsComponent, canActivate: [AuthGuard] },
  { path: 'productgroup', component:ProductGroupComponent, canActivate: [AuthGuard] },
  { path: 'companyinfo', component:CompanyInfoComponent, canActivate: [AuthGuard] },
  { path: 'companyarticles', component:CompanyArticlesComponent, canActivate: [AuthGuard] },
  { path: 'companymisc', component:CompanyMiscComponent, canActivate: [AuthGuard] },
  { path: 'stampedsds', component:StampedSdsComponent, canActivate: [AuthGuard] },
  { path: 'edituser/:id', component:EditUserComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel', component:ControlpanelComponent, canActivate: [AuthGuard] },
  { path: 'manufacturer', component:ManufacturerComponent, canActivate: [AuthGuard] },
  { path: 'manufactureritem', component:ManufactureritemComponent, canActivate: [AuthGuard] },
  { path: 'substances', component:SubstancesComponent, canActivate: [AuthGuard] },
  { path: 'editsubstance/:id', component:EditSubstanceComponent, canActivate: [AuthGuard] },
  { path: 'addnewsubstance', component:EditSubstanceComponent, canActivate: [AuthGuard] },
  { path: 'sareq', component:SareqComponent, canActivate: [AuthGuard] },
  { path: 'editsareq/:id', component:EditSareqComponent, canActivate: [AuthGuard] },
  { path: 'addnewsareq', component:AddSareqComponent, canActivate: [AuthGuard] },
  { path: 'restriction', component:RestrictionComponent, canActivate: [AuthGuard] },
  { path: 'productlist', component:ProductsComponent, canActivate: [AuthGuard] },
  { path: 'organisation', component:OrganisationComponent, canActivate: [AuthGuard] },
  { path: 'organisation/:id', component:OrganisationEditComponent, canActivate: [AuthGuard] },
  { path: 'organisationedituser/:id/:orgid', component:OrganisationEditUserComponent, canActivate: [AuthGuard] },
  { path: 'productadd', component:AddProductComponent, canActivate: [AuthGuard] },
  { path: 'producteditall', component:ProductsEditComponent, canActivate: [AuthGuard] },
  { path: 'productapplicationadd', component:ProductapplicationAddComponent, canActivate: [AuthGuard] },
  { path: 'productapplicationlist', component:ProductapplicationListComponent, canActivate: [AuthGuard] },
  { path: 'productapplicationhistory', component:ProductapplicationHistoryComponent, canActivate: [AuthGuard] },
  { path: 'productapplicationall', component:ProductapplicationAllComponent, canActivate: [AuthGuard] },
  { path: 'productapplicationitem/:id', component:ProductapplicationItemComponent, canActivate: [AuthGuard] },
  { path: 'product/:id', component:ProductItemComponent, canActivate: [AuthGuard] },
  { path: 'productrisk/:id', component:ProductItemRiskComponent, canActivate: [AuthGuard] },
  { path: 'materiallist', component:MateriallistComponent, canActivate: [AuthGuard] },
  { path: 'materialadd', component:AddMaterialComponent, canActivate: [AuthGuard] },
  { path: 'materialedit/:id', component:EditMaterialComponent, canActivate: [AuthGuard] },
  { path: 'reports', component:ReportsComponent, canActivate: [AuthGuard] },
  { path: 'reportssimplified', component:ReportSimplifiedComponent, canActivate: [AuthGuard] },
  { path: 'transportinfo', component:TransportinfoComponent, canActivate: [AuthGuard] },
  { path: 'assessments', component:AssessmentsComponent, canActivate: [AuthGuard] },
  { path: 'assessmentadd', component:AddAssessmentComponent, canActivate: [AuthGuard] },
  { path: 'assessmentpdf/:id', component:AssessmentPdfComponent, canActivate: [AuthGuard] },
  { path: 'assessmentedit/:id', component:EditAssessmentComponent, canActivate: [AuthGuard] },
  { path: 'assessmentschemical', component:AssessmentsChemicalComponent, canActivate: [AuthGuard] },
  { path: 'assessmentchemicaledit/:id', component:AssessmentChemicalEditComponent, canActivate: [AuthGuard] },
  { path: 'workstep/:id', component:WorkstepComponent, canActivate: [AuthGuard] },
  { path: 'new_freemium', component:NewfreemiumComponent, canActivate: [AuthGuard] },
  { path: 'news', component:NewsComponent, canActivate: [AuthGuard] },
  { path: 'coordinator', component:CoordinatorComponent, canActivate: [AuthGuard] },
  { path: 'coordinatoradd', component:AddCoordinatorComponent, canActivate: [AuthGuard] },
  { path: 'coordinatoredit/:id', component:EditCoordinatorComponent, canActivate: [AuthGuard] },
  { path: 'exposures', component:ExposuresComponent, canActivate: [AuthGuard] },
  { path: 'exposureadd', component:AddExposureComponent, canActivate: [AuthGuard] },
  { path: 'exposureedit/:id', component:EditExposureComponent, canActivate: [AuthGuard] },
  { path: 'substitution', component:SubstitutionComponent, canActivate: [AuthGuard] },
  { path: 'substitutionedit/:id', component:EditSubstitutionComponent, canActivate: [AuthGuard] },
  { path: 'substitutionworkstep/:id', component:WorkstepSubstitutionComponent, canActivate: [AuthGuard] },
  { path: 'history', component:HistoryComponent, canActivate: [AuthGuard] },
  { path: 'controlpanelcoordinator', component:ControlpanelcoordinatorComponent, canActivate: [AuthGuard] },
  { path: 'kebnekaise', component:KebnekaiseComponent, canActivate: [AuthGuard] },
  { path: 'law', component:LawComponent, canActivate: [AuthGuard] },
  { path: 'catalogue', component:CatalogueComponent, canActivate: [AuthGuard] },
  { path: 'catalogueedit/:id', component:CatalogueEditComponent, canActivate: [AuthGuard] },
  { path: 'customeractions', component:CustomeractionsComponent, canActivate: [AuthGuard] },
  { path: 'substitutionproducts', component:SubstitutionProductsComponent, canActivate: [AuthGuard] },
  { path: 'inventorylist', component:InventoryComponent, canActivate: [AuthGuard] },
  { path: 'inventoryitem/:id', component:InventoryItemComponent, canActivate: [AuthGuard] },
  { path: '', component: ProductsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers:  [AuthGuard]
})
export class AppRoutingModule { }
