import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClipboardService } from 'ngx-clipboard';
import { FilterPipe } from "src/app/shared/pipes/filterpipe.pipe";
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-organisation-edit-user',
  templateUrl: './organisation-edit-user.component.html',
  styleUrls: ['./organisation-edit-user.component.css']
})
export class OrganisationEditUserComponent implements OnInit {

  currentUser: any;
  user = null;
  organisation: any;
  availableRoles: any;
  selectedDepartment: any;
  departmentNames: any;
  cdocToken: any;
  samtecToken: any;
  subscribesToSamtec: any;
  subscribesToQRCode: any;
  selectedOrganisation: any;
  editUserId: any;
  newUser: boolean;
  searchD: string = "";
  userRole: any;
  displayPassword: any;
  showFakePass: boolean;
  existingDepartments: any = [];
  isError:boolean=false;
  isValid:boolean=true;
  userlocalDepartments: any = [];
  oldUsername:string="";
  organisationId: any;
  subscriptions: any;
  currentDepartmentName: any;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
              private userService:UserService, private translate: TranslateService, private formBuilder:FormBuilder,
              private clipboardApi: ClipboardService, private productService: ProductService, private staticDataService: StaticDataService,
              private route: ActivatedRoute, private organisationService: OrganisationService,) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.editUserId = params['id'];
      this.organisationId = params['orgid'];
    });

    this.displayPassword = "********";
    this.showFakePass = false;

    this.cdocToken = null;

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.departmentNames = [];

    this.getOrganisation();

    setTimeout(() => {
      if(this.currentUser.is_super_user){
        this.availableRoles = [this.translate.instant("ADMIN"),
        this.translate.instant("USERADMIN"),
        this.translate.instant("LOCAL_ASSIGNEE"),
        this.translate.instant("LOCAL_ADMIN"),
        this.translate.instant("MAINTENANCE_USER"),
        this.translate.instant("USER"),
        this.translate.instant("LOCAL_USER"),
        this.translate.instant("READONLY_USER"),
        this.translate.instant("GUEST"),];
      }
      else {
        this.availableRoles = [this.translate.instant("ADMIN"),
        this.translate.instant("USERADMIN"),
        this.translate.instant("LOCAL_ASSIGNEE"),
        this.translate.instant("LOCAL_ADMIN"),
        this.translate.instant("USER"),
        this.translate.instant("LOCAL_USER"),
        this.translate.instant("GUEST"),];
      }
    }, 200);
  }

  getOrganisation() {
    this.organisationService.getOrganisation(this.organisationId);
    this.organisationService
      .getOrganisationOrgDataListener()
      .pipe(take(1))
      .subscribe({
        next: (organisation) => {
          this.organisation = organisation;

          if(this.organisation.subscription_samtec == 1){
            this.subscribesToSamtec = true;
          }

          if(this.organisation.subscription_qrcode == 1){
            this.subscribesToQRCode = true;
          }

          if (this.organisation && this.organisation.orgs) {
            this.departmentNames.push({'name':this.organisation.name,'id':this.organisation.id});
            this.updateDepartmentNames(this.organisation);
          }

          this.newUser = false;
          if (this.editUserId == "new")
          {
            this.newUser = true;
            this.user = {
              id: "",
              email: "",
              first_name: "",
              hashed_password: "",
              is_admin: 0,
              is_super_user: 0,
              is_useradmin: 0,
              is_local_assignee: 0,
              is_local_admin: 0,
              is_maintenance_user: 0,
              is_readonly_user: 0,
              is_local_user: 0,
              is_guest: 0,
              is_local_user_department: "",
              last_name: "",
              phone: "",
              root_organisation_id: this.organisation.id,
              salt: "",
              static_token: "",
              samtec_token:"",
              samtectoken:"",
              department: "",
              password: "",
              username: "",
            };
          }
          else
          {
            this.showFakePass = true;
            this.userService.getSingleUser(this.editUserId);
            this.userService.getCurrentSingleUserDataListener().pipe(take(1)).subscribe({
              next: userData => {
                this.user = userData;
                this.getLocalDepartmentNames();
                this.getRoles();
                this.cdocToken = "http://portal.cdoc.se/login?access_token=" + this.user.static_token;
                this.samtecToken = this.user.samtectoken;
                this.oldUsername = this.user.username;
              },
              error: error => {}
            })
          }
        }
      })
  }

  updateDepartmentNames(organisation) {
    for (let i = 0; i < organisation.orgs.length; i++)
    {
      this.departmentNames.push({'name':organisation.orgs[i].name,'id':organisation.orgs[i].id});
      this.existingDepartments.push(organisation.orgs[i]);
      if (organisation.orgs[i].orgs.length > 0) {
        this.updateDepartmentNames(organisation.orgs[i]);
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  setLanguage(language) {
    switch(language)
    {
      case "sv" :
        this.user.language=0;
        break;
      case "en":
        this.user.language=1;
        break;
      case "fi":
        this.user.language=2;
        break;
      default:
        this.user.language=0;
        break;
    }
  }

  toggleDisplayFakePass() {
    this.showFakePass = !this.showFakePass;
  }

  getLocalDepartmentNames() {

    this.userlocalDepartments = [];

    if (this.user.is_local_user_department != "")
    {
      var thisDepartmentsArray = this.user.is_local_user_department.split(',').map(department => department.trim());
      for(var i = 0; i < thisDepartmentsArray.length; i++) {
        for (var k = 0; k < this.existingDepartments.length; k++)
        {
          if (this.existingDepartments[k].id == thisDepartmentsArray[i])
          {
            this.userlocalDepartments.push({id:this.existingDepartments[k].id, name:this.existingDepartments[k].name});
            break;
          }
        }
      }

      if (this.userlocalDepartments.length > 0)
      {
        this.user.department = this.userlocalDepartments[0].id;
      }
    }
    else
    {
      if (this.user.department == this.organisation.id)
      {
        this.currentDepartmentName = this.organisation.name;
        return;
      }

      for (var k = 0; k < this.existingDepartments.length; k++)
      {
        if (this.user.department == this.existingDepartments[k].id)
        {
          this.currentDepartmentName = this.existingDepartments[k].name;
        }
      }
    }
  }

  save() {
    this.isError=false;
    if(this.user.username==""||this.user.password==""){
      Swal.fire({
        title: this.translate.instant("USERNAME"),
        text: this.translate.instant("USERNAME_PASSWORD_REQUIRED_FIELDS"),
        icon: "error",
        showCancelButton: false,
        confirmButtonText: this.translate.instant("OK")
      });
      this.isError=true;
      return;
    }

    if(this.isValid==false){
      Swal.fire({
        title: this.translate.instant("USERNAME"),
        text: this.translate.instant("USERNAME_CONTAINS_INVALID_CHARACTER"),
        icon: "error",
        showCancelButton: false,
        confirmButtonText: this.translate.instant("OK")
      });
      this.isError=true;
      return;
    }

    // moved to a function saveData
    if (this.newUser||this.oldUsername!=this.user.username) {
    this.userService.CheckIfUserExists(this.user.username);
      this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
        next: response => {
          if (response == false)
          {
            this.saveData();
          }
          else {
            Swal.fire({
              title: this.translate.instant("USERNAME"),
              text: this.translate.instant("USERNAME_EXISTS"),
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
            });
          }
        },
        error: error => {
          Swal.fire({
            title: this.translate.instant("USERS"),
              text: this.translate.instant("CREATING_USER_ERROR"),
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
          });
        }
      })
    } else {
      this.saveData();
    }
  }

  getRoles() {
    this.userRole = this.translate.instant("USER");

    if (this.user.is_admin == 1) {
      this.userRole = this.translate.instant("ADMIN");
    }

    if (this.user.is_useradmin == 1) {
      this.userRole = this.translate.instant("USERADMIN");
    }

    if (this.user.is_local_assignee == 1) {
      this.userRole = this.translate.instant("LOCAL_ASSIGNEE");
    }

    if (this.user.is_local_admin == 1) {
      this.userRole = this.translate.instant("LOCAL_ADMIN");
    }

    if (this.user.is_maintenance_user == 1) {
      this.userRole = this.translate.instant("MAINTENANCE_USER");
    }

    if (this.user.is_local_user == 1) {
      this.userRole = this.translate.instant("LOCAL_USER");
    }

    if (this.user.is_readonly_user == 1) {
      this.userRole = this.translate.instant("READONLY_USER");
    }

    if (this.user.is_guest == 1) {
      this.userRole = this.translate.instant("GUEST");
    }
  }


  setRole(role) {

    this.user.is_admin = 0;
    this.user.is_useradmin = 0;
    this.user.is_local_assignee = 0;
    this.user.is_local_admin = 0;
    this.user.is_maintenance_user = 0;
    this.user.is_local_user = 0;
    this.user.is_readonly_user = 0;
    this.user.is_guest = 0;

    if (role == this.translate.instant("ADMIN"))
    {
      this.user.is_admin = 1;
      this.userRole = this.translate.instant("ADMIN");
    }
    else if (role == this.translate.instant("USERADMIN"))
    {
      this.user.is_useradmin = 1;
      this.userRole = this.translate.instant("USERADMIN");
    }
    else if (role == this.translate.instant("LOCAL_ASSIGNEE"))
    {
      this.user.is_local_assignee = 1;
      this.userRole = this.translate.instant("LOCAL_ASSIGNEE");
    }
    else if (role == this.translate.instant("LOCAL_ADMIN"))
    {
      this.user.is_local_admin = 1;
      this.userRole = this.translate.instant("LOCAL_ADMIN");
    }
    else if (role == this.translate.instant("LOCAL_USER"))
    {
      this.user.is_local_user = 1;
      this.userRole = this.translate.instant("LOCAL_USER");
    }
    else if (role == this.translate.instant("MAINTENANCE_USER"))
    {
      this.user.is_maintenance_user = 1;
      this.userRole = this.translate.instant("MAINTENANCE_USER");
    }
    else if (role == this.translate.instant("READONLY_USER"))
    {
      this.user.is_readonly_user = 1;
      this.userRole = this.translate.instant("READONLY_USER");
    }
    else if (role == this.translate.instant("GUEST"))
    {
      this.user.is_guest = 1;
      this.userRole = this.translate.instant("GUEST");
    }
    else
    {
      this.userRole = this.translate.instant("USER");
    }
  }

  setDepartment(department) {
    this.userlocalDepartments.push(department);
  }

  setFirstDepartment(department) {
    this.user.department = department.id;
    this.currentDepartmentName = department.name;
  }

  cancel() {
    this.router.navigate(["/organisation", this.organisation.id]);
  }

  copyToClip() {
    this.clipboardApi.copyFromContent(this.cdocToken)
  }

  removeDepartment(department) {
    const indexToRemove = this.userlocalDepartments.findIndex(item => item.id === department.id);

    if (indexToRemove !== -1) {
      this.userlocalDepartments.splice(indexToRemove, 1);
    }
  }

  createToken() {
    this.userService.createStaticToken();
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response != null)
        {
          this.user.static_token = response.token;
          this.cdocToken = "http://portal.cdoc.se/login?access_token=" + response.token;
        }
        else {
          Swal.fire({
            title: "Token!",
            text: "No toke was created!",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK")
          });
        }
      },
      error: error => {
        Swal.fire({
          title: "Token!",
          text: "No toke was created!",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: this.translate.instant("OK")
        });
      }
    })
  }

  deleteToken() {
    this.user.static_token = null;
    this.cdocToken = null;
  }

  qrcodeStaticToken(type) {

    if (type == 'statictoken') {
        var size="static_token";
        var token = this.user.static_token;
    } else if (type == 'samtectoken') {
        var size="samtectoken";
        var token = this.user.samtectoken;
    } else {
        // Something must be wrong if this occurs here
        return;
    }

    // If token is empty or token doesnt exist theres something wrong
    if(!token||token==""){
      return;
    }

    // Looks weird but I need a function with 4 variables to fit in with this
    // function in other places and the first 2 can't be empty (tested)
    this.productService.fetchLabels("a", "a", size, token);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response != null)
        {
          // window.open(response);
          var opened=window.open(response,"_blank");
          if(!opened){
            window.location.href=response;
          }
        }
        else {
          Swal.fire({
            title: "QRCode!",
            text: "QRCode failed to be created!",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK")
          });
        }
      },
      error: error => {
        Swal.fire({
          title: "QRCode!",
          text: "QRCode failed to be created!",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: this.translate.instant("OK")
        });
      }
    })
  }

  delete() {

    Swal.fire({
      title: "Ta bort användare?",
      text: "Är du säker på att du vill ta bort användaren?",
      type: "warning",
      icon: "error",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: this.translate.instant("OK"),
      cancelButtonText: this.translate.instant("CANCEL"),
      closeOnConfirm: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.userService.deleteUserWithId(this.editUserId);
        this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
          next: response => {
            this.router.navigate(["/organisation", this.organisation.id]);
          },
          error: error => {}
        })
      }
    });
  }

  validate(name){
    if(!name.match(/^[a-zA-Z0-9-_@.]*$/gm)){
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  saveData(){
    this.user.is_local_user_department = "";
    for (var i = 0; i < this.userlocalDepartments.length; i++)
    {
      for (var k = 0; k < this.existingDepartments.length; k++)
      {
        if (this.userlocalDepartments[i].id == this.existingDepartments[k].id)
        {
          if (this.user.is_local_user_department == "")
          {
            this.user.is_local_user_department += this.existingDepartments[k].id;
          }
          else
          {
            this.user.is_local_user_department += ', ' + this.existingDepartments[k].id;
          }
        }
      }
    }

    if (this.currentUser.is_local_admin == 1) {

      this.user.is_admin = 0;
      this.user.is_useradmin = 0;
      this.user.is_local_assignee = 0;
      this.user.is_local_admin = 0;
      this.user.is_maintenance_user = 0;
      this.user.is_readonly_user = 0;
      this.user.is_guest = 0;
      this.user.is_local_user = 1;
    }

    this.user.samtectoken=this.samtecToken;

    if (this.newUser) {
      this.userService.CreateUser(this.user);
      this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
        next: response => {
          if (response != null)
          {
            this.router.navigate(["/organisation", this.organisation.id]);
          }
          else {
            Swal.fire({
              title: this.translate.instant("USERS"),
              text: this.translate.instant("CREATING_USER_ERROR"),
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
            });
          }
        },
        error: error => {
          Swal.fire({
            title: this.translate.instant("USERS"),
              text: this.translate.instant("CREATING_USER_ERROR"),
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
          });
        }
      })
    }
    else
    {
      this.userService.updateUser(this.user.id, this.user);
      this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
        next: response => {
          if (response != null)
          {
            this.router.navigate(["/organisation", this.organisation.id]);
          }
          else {
            Swal.fire({
              title: this.translate.instant("USERS"),
              text: this.translate.instant("CREATING_USER_ERROR"),
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
            });
          }
        },
        error: error => {
          Swal.fire({
            title: this.translate.instant("USERS"),
              text: "Error when creating user!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
          });
        }
      })
    }
  }
}
