<div class="flex flex-col bg-white w-full rounded shadow-lg mb-20 p-7">
  <div class="container">
    <ul class="flex flex-col md:flex-row">
      <li class="mr-1">
        <a (click)="setActiveTab(0)" [class.active]="activeTab==0" class="bg-white inline-block px-4 py-2 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"REMOVE_IMPORTED_PRODUCTS" | translate}}</a>
      </li>
      <li class="mr-1">
        <a (click)="setActiveTab(2);createRevision('show')" [class.active]="activeTab==2" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"REVISION_LIST" | translate}}</a>
      </li>
      <li class="mr-1">
        <a (click)="setActiveTab(3);createHistory()" [class.active]="activeTab==3" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"REVISION_HISTORY" | translate}}</a>
      </li>
    </ul>
    <hr>
  </div>

  <div *ngIf="activeTab==0" class="mt-1 px-4 py-2 mb-4 mt-4">
    <div class="flex flex-col w-full">
      <div class="mb-4">
        <label class="font-bold mb-1">Ta bort produkter:</label>
        <div class="flex flex-row">
          <div class="flex justify-between items-center w-1/2 relative inline-block text-left z-20" appDropdown>
            <button title="{{'CHOOSEWHICHYEARINFO' | translate}}" type="button" class="button-white justify-between w-full items-start border border-gray-300 mr-2">
              <span class="text-sm">{{lastyear}}</span>
              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                <div class="py-1">
                  <div *ngFor="let years of yearArray;let i = index;" data-value="years" class="dropdown-menu-item" (click)="year=years;lastyear=years" tabindex="{{i}}">{{years}}</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-4">
      <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
        <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
        <span class="text-lg">{{ 'SAVE' | translate }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="activeTab==2" class="mt-1 px-4 py-2 mb-4 mt-4">
    <div class="flex flex-col mb-4">
      <span *ngIf="revisionList" class="text-2xl font-bold text-black">Revisionslista</span>
      <span *ngIf="revisionList" class="text-2xl font-bold text-black">{{" "}}{{"UNIQUE_ITEMS" | translate}}: <span class="text-green-700">{{" "}}{{revisionList?.length}}</span></span>
      <hr/>
    </div>
    <div class="flex flex-row mb-4">
      <button (click)="selectForRevision()" class="button button-cdocblue mr-1">{{"MARK_FOR_REVISION" | translate}}</button>
      <button (click)="unselectForRevision()" class="button button-cdocblue mr-1">{{"UNMARK_FOR_REVISION" | translate}}</button>
      <button (click)="createRevision('addtoexcel')" class="button button-cdocblue mr-1">{{"EXPORT_TO_EXCEL" | translate}}</button>
      <button (click)="reset()" *ngIf="path!=''&&path!='show'&&path!='addtoexcel'" class="button button-red ml-auto">{{"RESET_REVISION" | translate}}</button>
    </div>
    <hr/>
    <button [hidden]="path==''||path=='show'||path=='addtoexcel'" (click)="createRevision('')" class="button button-green mb-4 mt-4">
      <img class="w-6 h-6 mr-1" id="SDBIcon" src="/images/icons/excel-svgrepo-com.svg" area-hidden="true" title="{{'DO_REVISION' | translate}}"/>
      {{"DO_REVISION" | translate}}
    </button>
    <table class="table-auto bg-white mb-4 w-full" id="organisationinformationtable">
      <thead>
        <tr>
          <td class="table-row-header">{{"CDOC_DATE" | translate}}</td>
          <td class="table-row-header">{{"NAME" | translate}}</td>
          <td class="table-row-header">{{"MARKED_FOR_REVISION" | translate}}</td>
        </tr>
      </thead>
      <tbody style="height: 200px;">
        <tr *ngFor='let product of revisionList'>
          <td class="table-row-column">{{product.cDoc_Revisionsdatum | date:'yyyy-MM-dd'}}</td>
          <td class="table-row-column">{{product.Produkt}}</td>
          <td class="table-row-column">
            <img [hidden]="product.ongoing_revision==0" class="w-8 h-8" title="{{'USERADMIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="activeTab==3" class="mt-1 px-4 py-2 mb-4 mt-4">
    <table class="table-auto bg-white mb-4 w-full" id="revisionhistorytable">
      <thead>
        <tr>
          <td class="table-row-header">{{"REVISION_START_DATE" | translate}}</td>
          <td class="table-row-header">{{"REVISION_BY" | translate }}</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let history of revisionHistory'>
          <td class="table-row-column">{{history.date | date:'yyyy-MM-dd'}}</td>
          <td class="table-row-column">{{history.assigned}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

