import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { SareqService } from 'src/app/services/sareq.service';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FileUploadService } from 'src/app/services/file-upload.service';
import * as _ from "lodash";
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-sareq',
  templateUrl: './sareq.component.html',
  styleUrls: ['./sareq.component.css']
})
export class SareqComponent implements OnInit, AfterViewInit  {
  @ViewChild('searchSareqInput') searchSareqInput!: ElementRef;

  tabName: any;
  loadingData: boolean = false;
  currentUser: any;
  sortDirection: any = null;
  sortValue: any = null;
  extendedSareqListSettings: any;
  showSelectColumns:boolean;
  showAdvanced:boolean = false;
  sareq:any;

  sareqSearch = {
    all: "",
    name: "",
    manufacturer: "",
    responsible: "",
    alternative_name: "",
    articlenumber:"",
    h_phrases: "",
    cas:"",
    risk: "",
    esdb: ""
  };

  isSearch: boolean = true;
  isAll: boolean = false;
  isInspection: boolean = false;
  isDashboard: boolean = false;
  isOngoing: boolean = false;
  isAvailable: boolean = false;
  isNew: boolean = false;
  isReturned: boolean = false;
  isHistory: boolean = false;
  sareqSortBy: string = "";
  numberOfItems = 100;
  searchPageNumber:number = 1;
  pageNumber:number = 1;
  itemsPerPage:number = 10;
  displayedProducts:any = [];
  displayedProductsFiltered: any = [];
  tmpDisplayedProductsFiltered: any = [];
  displayedApproved:any;
  displayedNotApprovedInspection:any;
  displayedNotApprovedUpdated:any;
  displayedOngoing:any;
  displayedNew:any;
  displayedReturn:any;
  overrideProducts = [];
  showMinus2: boolean = false;
  showMinus3: boolean = false;
  showMinus4: boolean = false;
  showMinus5: boolean = false;
  showMinus6: boolean = false;
  showMinus7: boolean = false;
  showMinus8: boolean = false;
  totalAmount: number = 0;
  searchRisk = [1,2,3,4,5,"Alla"];
  searchSDS=[];
  riskfile: any;
  grbFile: any;

  constructor(
    private utilService:UtilService,
    private translate: TranslateService,
    private router:Router,
    private previousrouter: ActivatedRoute,
    private productService: ProductService,
    private sareqService: SareqService,
    private authService: AuthService,
    private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.tabName = localStorage.getItem("controlPanelSareqActiveTab");
    if (this.tabName == null || this.tabName == undefined)
    {
      this.tabName = 'isSearch';
    }
    this.extendedSareqListSettings = JSON.parse(localStorage.getItem("extendedSareqListSettings"));
    this.showSelectColumns = false;

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    var tempextendedSareqListSettings=[];
    if(this.extendedSareqListSettings == null)
    {
        this.extendedSareqListSettings = [
        true,     // 0 name
        true,     // 1 manufacturer
        true,     // 2 SDS/GRB
        true,     // 3 VERSION
        true,     // 4 REVISION_DATE
        false,    // 5 alternative_name
        true,     // 6 DATE
        true,     // 7 STATUS
        true,     // 8 RESPONSIBLE
        true,     // 9 REVISE (NOW REMOVED BUT KEEPING IT FOR CLARITY)
        true,     // 10 MANUFACTURER_ARTICLE_NUMBER
        false,    // 11 SHOW_REPLACED
        false,    // 12 HIDDEN
        true,     // 13 REVISE COMMENT
        false,    // 14 UFI
        false,    // 15 OVERRIDE
        true     // 16 LAST_UPDATE
      ];
    }
    else
    {
      for(var i = 0;i<this.extendedSareqListSettings.length;i++)
      {
        tempextendedSareqListSettings.push(this.extendedSareqListSettings[i])
      }
      this.extendedSareqListSettings=tempextendedSareqListSettings;
    }

    //this.getAmountOfSareqProductsByStatus();
    this.showTab(this.tabName);
    this.searchSDS =[{"id":"1","name":"SDS_1"},{"id":"2","name":"SDS_ALL"}];
    this.loadingData = false;
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  setFocus() {
    // This checks if the element is rendered and sets the focus
    if (this.searchSareqInput && this.searchSareqInput.nativeElement) {
      this.searchSareqInput.nativeElement.focus();
    }
  }

  showTab(tabName: string) {
    const validFlags  = ['isDashboard', 'isSearch', 'isAll', 'isInspection', 'isOngoing', 'isAvailable', 'isNew', 'isReturned'];
    validFlags .forEach(flag => this[flag] = false);

    if (validFlags.includes(tabName)) {
      this[tabName] = true;
    }

    localStorage.setItem("controlPanelSareqActiveTab", tabName);

    this.sareqSearch.all                ="";
    this.sareqSearch.alternative_name   ="";
    this.sareqSearch.articlenumber      ="";
    this.sareqSearch.manufacturer       ="";
    this.sareqSearch.name               ="";
    this.sareqSearch.responsible        ="";
    this.sareqSearch.h_phrases       ="";
    this.sareqSearch.cas               ="";
    this.sareqSearch.risk        ="";
    this.sareqSortBy                    = "";
    this.displayedProductsFiltered      = [];
    this.numberOfItems                  =-1;

    switch(tabName){

      case 'isDashboard':
        break;

      case 'isSearch':
        break;

      case 'isAll':
        this.loadSareqList('all')
        break;

      case 'isInspection':
        this.loadSareqList('notapprovedinspection')
        break;

      case 'isOngoing':
        this.loadSareqList('notapprovedongoing');
        break;

      case 'isAvailable':
        this.loadSareqList('ongoing');
        break;

      case 'isNew':
        this.loadSareqList('new');
        break;

      case 'isReturned':
        this.loadSareqList('return');
        break;
    }
  }

  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    this.loadingData = true;
    this.ngOnInit();
  }

  addSareq() {
    this.router.navigate(["/addnewsareq"]);
  }

  sareqSelected(sareq) {
    if (((sareq.hidden==1) && !this.currentUser.is_super_user) || this.currentUser.is_readonly_user) {
      Swal.fire({
        title: "Redigera gömd post",
        text: "Du har inte behörighet att ta redigera denna post.",
        confirmButtonText: this.translate.instant("OK")
      })
      return;
    }
    this.router.navigate(["/editsareq", sareq.id]);
  }

  sareqReplacedSelected(sareq) {
    this.router.navigate(["/sareqitem", sareq.replaced_by]);
  }

  loadOverrideProducts(){
    this.sareqService.getOverrideProducts();
    this.sareqService.getSareqDataListenerOverride().pipe(take(1)).subscribe({
      next: override => {
        this.overrideProducts = override.rows;
        for(var n = 0;n<this.displayedProductsFiltered.length;n++){
          if(this.displayedProductsFiltered[n].override!=null){
            for(var nn = 0;n<this.overrideProducts.length;nn++){
              if(this.displayedProductsFiltered[n].override==this.overrideProducts[nn].override){
                this.displayedProductsFiltered[n].overridename=this.overrideProducts[nn].override_name;
                break;
              }
            }
          }
        }
      },
      error: error => {
      }
    })
  }

  // Takes a searchword and filters out the items where its used
  // type is the name of the searchfield, in case its needed in the future
  // (for example search only when name is longer than 3 keys)
  // i.e if(type=='name'&&list==0){if(this.sareqSearch.name.length<4){return}}
  // list is the list to work on.
  filterByType(type: string){

    this.displayedProductsFiltered = this.tmpDisplayedProductsFiltered;
    if(this.sareqSearch.all!=null && this.sareqSearch.all!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.sareqSearch.all.toLowerCase())>-1);
    }
    if(this.sareqSearch.name!=null && this.sareqSearch.name!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.name?.toLowerCase().indexOf(this.sareqSearch.name.toLowerCase())>-1);
    }
    if(this.sareqSearch.manufacturer!=null && this.sareqSearch.manufacturer!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.supplier_name?.toLowerCase().indexOf(this.sareqSearch.manufacturer.toLowerCase())>-1);
    }
    if(this.sareqSearch.responsible!=null && this.sareqSearch.responsible!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.assigned?.toLowerCase().indexOf(this.sareqSearch.responsible.toLowerCase())>-1);
    }
    if(this.sareqSearch.alternative_name!=null && this.sareqSearch.alternative_name!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.alternative_name?.toLowerCase().indexOf(this.sareqSearch.alternative_name.toLowerCase())>-1);
    }
    if(this.sareqSearch.articlenumber!=null && this.sareqSearch.articlenumber!=""){
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.supplier_article_number?.toLowerCase().indexOf(this.sareqSearch.articlenumber.toLowerCase())>-1);
    }
    if ( this.sareqSearch.h_phrases!=null && this.sareqSearch.h_phrases!="" ) {
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.h_phrases?.toString().toLowerCase().indexOf(this.sareqSearch.h_phrases.toLowerCase())>-1);
    }
    if ( this.sareqSearch.cas!=null && this.sareqSearch.cas!="") {
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.cas?.toString().toLowerCase().indexOf(this.sareqSearch.cas.toLowerCase())>-1);
    }
    if ( type=='risk') {
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.risk_prio?.toString().toLowerCase().indexOf(this.sareqSearch.risk.toLowerCase())>-1);
    }

    if ( this.sareqSearch.esdb!=null && this.sareqSearch.esdb!="") {
      this.displayedProductsFiltered=this.displayedProductsFiltered.filter(o => o.esdb_available?.toString().toLowerCase().indexOf(this.sareqSearch.esdb.toLowerCase())>-1);
    }

  }

  loadSareqList(status: string) {
    let advanced = 'No';
    if ( this.showAdvanced && this.showAdvanced == true ) {
      advanced = 'Yes';
    } else {
      advanced = 'No';
    }
    if ( localStorage.getItem( "controlPanelSareqActiveTab" ) == 'isSearch' ){
      advanced = 'No';
    }
    var info = {
      'name': encodeURIComponent(this.sareqSearch.name),
      'manufacturer': encodeURIComponent(this.sareqSearch.manufacturer),
      'articlenumber': encodeURIComponent(this.sareqSearch.articlenumber),
      'orderBy': '',
      'limit': this.numberOfItems,
      'page': this.searchPageNumber,
      'status': status,
      'showAdvanced': advanced
    };

    this.loadingData = true;
    this.displayedProducts = [];
    this.displayedProductsFiltered = [];

    this.sareqService.getAllAndFilter(info);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.loadingData = false;
        if (products == null) {
          this.loadingData = false;
        }
        else
        {
          for(var n = 0;n<products.length;n++){
            if(!products[n].id){
              this.totalAmount = products[n];
              products.splice(n,1);
            }
          }

          if (status == 'new' || status == 'ongoing') {
            products = _.sortBy(products,["cdoc_date"], ['asc']);
          }
          products = this.sortByHighPrio(products);
          this.displayedProducts = products;
          this.displayedProductsFiltered = _.cloneDeep(products);
          this.tmpDisplayedProductsFiltered = _.cloneDeep(products);

          if ( this.sortDirection === 'desc' ) {
            this.displayedProducts = _.orderBy(
              this.displayedProducts,
              [
                (item) =>
                  typeof item["type"] === "string"
                    ? item["type"].toLowerCase()
                    : item["type"],
                (item) =>
                  typeof item["high_prio"] === "string"
                    ? item["high_prio"].toLowerCase()
                    : item["high_prio"],
                (item) =>
                  typeof item[this.sortValue] === "string"
                    ? item[this.sortValue].toLowerCase()
                    : item[this.sortValue],
              ],
              ["asc", "desc", "asc"]
            );
          }
          if (this.sortDirection === 'asc') {
            this.displayedProducts = _.orderBy(
              this.displayedProducts,
              [
                (item) =>
                  typeof item["type"] === "string"
                    ? item["type"].toLowerCase()
                    : item["type"],
                (item) =>
                  typeof item["high_prio"] === "string"
                    ? item["high_prio"].toLowerCase()
                    : item["high_prio"],
                (item) =>
                  typeof item[this.sortValue] === "string"
                    ? item[this.sortValue].toLowerCase()
                    : item[this.sortValue],
              ],
              ["desc", "desc", "desc"]
            );
          }

          this.loadSareqApproved();

          if(this.extendedSareqListSettings[15] == true){
            this.loadOverrideProducts();
          }

          this.displayedNotApprovedInspection = this.sortByHighPrio(this.displayedNotApprovedInspection);
          this.displayedNotApprovedUpdated = this.sortByHighPrio(this.displayedNotApprovedUpdated);
          this.displayedOngoing = this.sortByHighPrio(this.displayedOngoing);
          this.displayedNew = this.sortByHighPrio(this.displayedNew);
          this.displayedReturn=this.sortByHighPrio(this.displayedReturn);

          this.loadingData = false;
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }

  sortByHighPrio(productList) {
    return _.orderBy(productList, ['high_prio'], ['desc']);
  }

  loadSareqApproved(){
    this.displayedApproved = [];
    this.displayedNotApprovedInspection = [];
    this.displayedNotApprovedUpdated = [];
    this.displayedOngoing = [];
    this.displayedNew = [];
    this.displayedReturn = [];

    var twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 3);

    for(var i = 0; i < this.displayedProducts.length; i++){
      if(!this.displayedProducts[i].status){
      }
      else if(this.displayedProducts[i].status !== 1){
        if(this.displayedProducts[i].status !== 0)
        {
          if(this.displayedProducts[i].status === 5)
          {
            this.displayedNotApprovedUpdated.push(this.displayedProducts[i]);
          }
          if(this.displayedProducts[i].status === 3)
          {
            var tempDate = new Date(this.displayedProducts[i].sds_date);
            if ((tempDate >= twoDaysAgo) && (this.currentUser.is_super_user || this.currentUser.is_priority)) {
              this.displayedOngoing.push(this.displayedProducts[i]);
            }
            else if (tempDate < twoDaysAgo)
            {
              this.displayedOngoing.push(this.displayedProducts[i]);
            }
          }
          if(this.displayedProducts[i].status === 4)
          {
            this.displayedNew.push(this.displayedProducts[i]);
          }
          if(this.displayedProducts[i].status === 2)
          {
            this.displayedReturn.push(this.displayedProducts[i]);
          }
        }
      }
      else{
        this.displayedApproved.push(this.displayedProducts[i]);
      }
    }

    for(var i = 0; i < this.displayedProducts.length; i++){
      if(this.displayedProducts[i].status === 0)
      {
          this.displayedNotApprovedInspection.push(this.displayedProducts[i]);
      }
    }

    this.displayedOngoing = _.sortBy(this.displayedOngoing,['high_prio',"cdoc_date"], ['desc', 'asc']);
    this.displayedNew = _.sortBy(this.displayedNew,['high_prio',"cdoc_date"], ['desc', 'asc']);
    this.displayedReturn = _.sortBy(this.displayedReturn,['high_prio',"cdoc_date"], ['desc', 'asc']);
    this.displayedNotApprovedInspection=_.sortBy(this.displayedNotApprovedInspection,['high_prio',"cdoc_date"], ['desc', 'asc']);
}

onToggleShowExtendedSareqListSettings(index, value) {
  this.extendedSareqListSettings[index] = value;
  localStorage.setItem("extendedSareqListSettings", JSON.stringify(this.extendedSareqListSettings));
  if(index==15){
    this.loadOverrideProducts();
  }
}

sortItem(type: string) {

  // Retrieve the sorting direction from local storage or default to 'desc'
  let order = localStorage.getItem('sortSareqDirection') || 'desc';

  // Toggle the sorting direction if the same column is clicked
  if (localStorage.getItem('sortSareqValue') === type) {
    order = order === 'asc' ? 'desc' : 'asc';
  }

  // Sort the products based on the selected column and sorting direction
  this.displayedProductsFiltered = _.orderBy(this.displayedProductsFiltered,
    [(item) => typeof item[type] === "string" ? item[type].toLowerCase() : item[type]],
    [order]
  );

  // Save the sorting column and direction in local storage
  localStorage.setItem('sortSareqValue', type);
  localStorage.setItem('sortSareqDirection', order);
}

gotoControlPanel(){
  this.router.navigate(['/controlpanel']);
}

showSelectColumnsClicked(showSelectColumns:boolean){
  this.showSelectColumns=!showSelectColumns;
}

showAdvancedClicked(showAdvanced:boolean){

  if ( this.showAdvanced == true ) {
    this.showAdvanced = false;
  } else if ( this.showAdvanced == false ) {
    this.showAdvanced = true;
    switch(localStorage.getItem("controlPanelSareqActiveTab")){
      case 'isSearch':
        if ( this.sareqSearch.name == "" && this.sareqSearch.manufacturer == "" && this.sareqSearch.articlenumber == "" ) {

        } else {
          this.loadSareqList('all');
        }
        break;
      case 'isAll':
        this.loadSareqList('all')
        break;
      case 'isInspection':
        this.loadSareqList('notapprovedinspection')
        break;
      case 'isOngoing':
        this.loadSareqList('notapprovedongoing');
        break;
      case 'isAvailable':
        this.loadSareqList('ongoing');
        break;
      case 'isNew':
        this.loadSareqList('new');
        break;
      case 'isReturned':
        this.loadSareqList('return');
        break;
    }
  }
}

reviseSdb(product) {
  this.sareq = product;
  if ( this.sareq.lang == 2 ) {
    this.sareqService.createSpecificGrbFin(this.sareq.id);
  } else if ( this.sareq.lang == 1 ) {
    this.sareqService.createSpecificGrbEng(this.sareq.id);
  } else {
    this.sareqService.createSpecificGrb(this.sareq.id);
  }
  this.sareqService
    .getSareqDataListener()
    .pipe(take(1))
    .subscribe({
      next: (response) => {
        if (response == null) {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_GRB"),
            this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
            "error"
          );
          this.loadingData = false;
        } else {
          var file: any, fileURL: string, contentType: string;
          contentType = "application/pdf";
          file = new Blob([response], { type: contentType });
          // fileURL = URL.createObjectURL(file);
          this.riskfile = file;
          // this.grbFile = fileURL;

          if (this.riskfile) {
            var getRiskFile = new Promise((resolve, reject) => {
              this.uploadService.uploadRiskfile(this.riskfile);
              this.uploadService
                .getRiskFileDataListener()
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    resolve(response.filename);
                  },
                  error: (error) => {
                    console.log("Dochistory error: ", error);
                    this.utilService.onError(error);
                    reject(error);
                  },
                });
            });

            getRiskFile.then((filename) => {
              this.sareq.risk_file_id = filename;
              this.sareqService.updateSareqWithIdDate(this.sareq);
            }).catch((error) => {
              console.error("Error processing risk file:", error);
            });
          }
        }
      },
      error: (error) => {
        Swal.fire(
          this.translate.instant("COULD_NOT_GET_GRB"),
          this.translate.instant("COULD_NOT_GET_GRB_WARNING"),
          "error"
        );
        this.loadingData = false;
      },
    });
}

approveProduct(product)
{
  this.sareq = product;
  this.sareqService.approveSareq(this.sareq.id)
  this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
    next: products => {
      this.loadingData = false;
      if (products == null) {
      }
      else
      {
        window.location.reload();
      }
    },
    error: error => {
      this.loadingData = false;
    }
  })
}

/**
 * Display SDS in new window
 */
openSdsInNewWindow(product) {
  const filename = product.name + '.pdf';
  const newWindow = window.open("", "_blank");
  this.productService.getSdsAsTrustedResourceUrl(product.id);
  this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
    next: pdfContent => {
      if (pdfContent == null) {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        if (newWindow) {
          newWindow.close();
        }
      } else {
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // If the new window couldn't be opened, fallback to opening in the same tab
          window.location.href = pdfContent;
        } else {
          newWindow.location.href = pdfContent;
        }
      }
    },
    error: error => {
      Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      if (newWindow) {
        newWindow.close();
      }
    }
  });
}

  /**
    * Display GRB in new window
  */
  openGrbInNewWindow(product) {
    const filename = product.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  getValueHidden(value){
    let sareqHiddenValueAsText = "";
    if(value==1){
      sareqHiddenValueAsText = this.translate.instant("YES");
      return sareqHiddenValueAsText;
    } else {
      sareqHiddenValueAsText = this.translate.instant("NO");
      return sareqHiddenValueAsText;
    }

  }

  exportToExcelClicked() {
    this.loadingData = true;
    const workbook = XLSX.utils.book_new();
    var dataToExportToExcel = [];

    for ( var n = 0, max = this.displayedProductsFiltered.length; n < max; n++) {
      if (typeof this.displayedProductsFiltered[n].rework_date === 'string') {
        var reworkDateSubstring = this.displayedProductsFiltered[n].rework_date.substring(0, 10);
      } else {
        var reworkDateSubstring = null;
      }

      if (typeof this.displayedProductsFiltered[n].cdoc_date === 'string') {
        var cdocDateSubstring = this.displayedProductsFiltered[n].cdoc_date.substring(0, 10);
      } else {
        var cdocDateSubstring = null;
      }
      if ( this.showAdvanced == true ) {
        dataToExportToExcel.push( {
          'Namn': this.displayedProductsFiltered[n].name,
          'Alternativa namn': this.displayedProductsFiltered[n].alternative_name,
          'Tillverkare': this.displayedProductsFiltered[n].supplier_name,
          'SDS Version': this.displayedProductsFiltered[n].version,
          'SDS Omarbetningsdatum': reworkDateSubstring,
          'cDoc datum': cdocDateSubstring,
          'Status': this.displayedProductsFiltered[n].status,
          'Ansvarig': this.displayedProductsFiltered[n].assigned,
          'Artikelnummer': this.displayedProductsFiltered[n].supplier_article_number,
          'Revisionskommentar': this.displayedProductsFiltered[n].revision_comment,
          'Gömd': this.displayedProductsFiltered[n].hidden,
          'UFI-kod': this.displayedProductsFiltered[n].ufi,
          'Override': this.displayedProductsFiltered[n].overridename,
          'H-fraser': this.displayedProductsFiltered[n].h_phrases.map(phrase => phrase.replace(/\n/g, ' ')).join(', '),
          'CAS': this.displayedProductsFiltered[n].cas.map(cas => cas.replace(/\n/g, ' ')).join(', '),
          'risk_prio': this.displayedProductsFiltered[n].risk_prio,
          'esdb': this.displayedProductsFiltered[n].esdb_available
        } );
      } else {
        dataToExportToExcel.push( {
          'Namn': this.displayedProductsFiltered[n].name,
          'Alternativa namn': this.displayedProductsFiltered[n].alternative_name,
          'Tillverkare': this.displayedProductsFiltered[n].supplier_name,
          'SDS Version': this.displayedProductsFiltered[n].version,
          'SDS Omarbetningsdatum': reworkDateSubstring,
          'cDoc datum': cdocDateSubstring,
          'Status': this.displayedProductsFiltered[n].status,
          'Ansvarig': this.displayedProductsFiltered[n].assigned,
          'Artikelnummer': this.displayedProductsFiltered[n].supplier_article_number,
          'Revisionskommentar': this.displayedProductsFiltered[n].revision_comment,
          'Gömd': this.displayedProductsFiltered[n].hidden,
          'UFI-kod': this.displayedProductsFiltered[n].ufi,
          'Override': this.displayedProductsFiltered[n].overridename
        } );
      }
    }



    const worksheet = XLSX.utils.json_to_sheet( dataToExportToExcel );
      if ( this.showAdvanced == true ) {
        worksheet[ '!cols' ] = [
          { wch: 50 },
          { wch: 20 },
          { wch: 50 },
          { wch: 12 },
          { wch: 22 },
          { wch: 12 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 5 },
          { wch: 10 },
          { wch: 10 },
          { wch: 50 },
          { wch: 50 },
          { wch: 5 },
          { wch: 5 },
         ];
      } else {

        worksheet[ '!cols' ] = [
          { wch: 50 },
          { wch: 20 },
          { wch: 50 },
          { wch: 12 },
          { wch: 22 },
          { wch: 12 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 5 },
          { wch: 10 },
          { wch: 10 } ];
      }
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'exported-data.xlsx');
    this.loadingData = false;
    return;
  }

  openExtSdsInNewWindow(prod) {
    const filename = prod.name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getExtSdsAsTrustedResourceUrl(prod.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  changeHprases() {
    if (this.sareqSearch.h_phrases.length > 2 && this.sareqSearch.h_phrases.toLowerCase().indexOf('euh') > -1) {
        this.sareqSearch.h_phrases = this.sareqSearch.h_phrases.toLowerCase().replace('euh', '');
        this.sareqSearch.h_phrases = 'EUH' + this.sareqSearch.h_phrases;
    }
    else if (this.sareqSearch.h_phrases.length > 2) {
      this.sareqSearch.h_phrases = this.sareqSearch.h_phrases.replace('H', '');
      this.sareqSearch.h_phrases = this.sareqSearch.h_phrases.replace('h', '');
      this.sareqSearch.h_phrases = 'H' + this.sareqSearch.h_phrases;
    }
    this.filterByType('h_phrases');
  }

  searchByRisk(risk: any) {
    this.sareqSearch.risk = risk.toString();
    if ( risk=="Alla") {
      this.sareqSearch.risk = "";
    }
    this.filterByType('risk');
  }

  searchByESDB(esdb) {
    if (esdb == "1"){
      this.sareqSearch.esdb = "1";
    } else if (esdb == "2"){
      this.sareqSearch.esdb = "";
    }
    this.filterByType('esdb');
  }

  checkIfItemsInSearchAndSearch() {
    if ( this.sareqSearch.name == "" && this.sareqSearch.manufacturer == "" && this.sareqSearch.articlenumber == "" ) {
      Swal.fire({
        title: this.translate.instant("NO_SEARCH_CRITERIA"),
        text: this.translate.instant("NO_SEARCH_CRITERIA_WARNING"),
        confirmButtonText: this.translate.instant("OK")
      })
      return;
    }
    this.loadSareqList('all');
  }
}
