<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-2 border border-gray-200">
    <div class="flex justify-between">
        <div class="flex mt-4">
            <div class="flex flex-row items-center hidden md:block space-x-2">
                <p class="font-bold text-lg">{{"COORDINATOR" | translate}}: {{coordinatorSubjectList?.length}}</p>
                <button type="button" *ngIf="this.all!=true" class="button-filter-yellow" (click)="toggleStatus(-1)">
                    <span class="text-lg">{{"FILTERED" | translate}}: {{statusName}}</span>
                </button>
            </div>
        </div>
        <button (click)="addCoordinator()" class="button-cdocblue mt-4">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{"ADD" | translate}}</span>
        </button>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="loadingData==false" class="mt-4 px-4 py-2 mb-20">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="exposuretable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="coordinatorSearch.all" id="searchProductList1" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex mt-6 md:ml-4 mr-20">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-2" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-4">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="static inline-block text-left" id="selectRisk" appDropdown>
                                <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"STATUS" | translate}}</span>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                    <div class="py-1">
                                        <div data-value="3" class="dropdown-menu-item" (click)="toggleStatus(-1)" tabindex="1">{{'SHOW_ALL' | translate}}</div>
                                        <div data-value="0" class="dropdown-menu-item" (click)="toggleStatus(0)" tabindex="2">{{'SHOW_NOT_STARTED' | translate}}</div>
                                        <div data-value="2" class="dropdown-menu-item" (click)="toggleStatus(1)" tabindex="3">{{'SHOW_ONGOING' | translate}}</div>
                                        <div data-value="3" class="dropdown-menu-item" (click)="toggleStatus(2)" tabindex="4">{{'SHOW_DONE' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr *ngIf="coordinatorSubjectList.length > 0">
                    <th class="table-row-header" (click)="sortItem('name',0)">{{"HEADING" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('department',1)">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('prio',2)">{{"PRIORITY" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('status',3)">{{"STATUS" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('due',4)">{{"DUE_DATE" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('responsible',5)" >{{"RESPONSIBLE" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover:bg-gray-200" *ngFor="let subject of coordinatorSubjectList
                    | filter : coordinatorSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" (click)="coordinatorSelected(subject)">
                    <td class="table-row-column">{{subject.name}}</td>
                    <td class="table-row-column">{{subject.department}}</td>   
                    <td class="table-row-column">{{subject.prio}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==0">{{availableCoordinatorSubjectStatus[0].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==1">{{availableCoordinatorSubjectStatus[1].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==2">{{availableCoordinatorSubjectStatus[2].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==3">{{availableCoordinatorSubjectStatus[3].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==4">{{availableCoordinatorSubjectStatus[4].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.due===''">{{''}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.due!==''">{{subject.due | date:'yyyy-MM-dd'}}</td>
                    <td class="table-row-column">{{subject.responsible}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr *ngIf="coordinatorSubjectList.length > 0">
                    <td class="p-6" colspan="2">
                        <div class="relative inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>


