<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3 text-center">
        <div class="text-gray-900 font-bold">{{"REPORT_ALLERGENIC" | translate}}</div>
        <!-- Modal Body -->
        <div class="flex flex-col center-items mt-10 p-2">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                <div class="loader"></div>
            </div>

            <div *ngIf="loadingData==false" class="overflow-auto border mb-20 border-gray-200">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
  </div>
</div>

