import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-report-simplified',
  templateUrl: './report-simplified.component.html',
  styleUrls: ['./report-simplified.component.css']
})
export class ReportSimplifiedComponent implements OnInit {

  dtOptions: any = {};

  @Input() reportInfo;

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  reportInformation: any = [];
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  currentLanguage: any;
  subscribesToClassification: any;
  subscribesToTransport: any;
  listAlldepartments: any;
  loadingData: boolean = false;

  reportSearch = {
    all:"",
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private utilService: UtilService,
              private authService: AuthService,
              private reportService: ReportService,
              private productService: ProductService,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder) { }

  ngOnInit(): void {

    this.reportInformation = {
      start_date: new Date("2015 1 January"),
      date: new Date(),
      reportType: "",
      name: "",
      id: "",
      includeSubDepartments: false,
      registerProducts: false,
      product: false
    };

    this.reportInformation = this.reportInfo;
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToClassification = this.getSubscription('subscription_classification');
    this.subscribesToTransport = this.getSubscription('subscription_transport');

    this.currentLanguage = localStorage.getItem('usedLanguage');
    switch(this.currentLanguage) {
      case 'sv':
        this.currentLanguage = 0;
      break;

      case 'en':
        this.currentLanguage = 1;
      break;

      case 'fi':
        this.currentLanguage = 2;
      break;

      default:
        this.currentLanguage = 0;
      break;
    }

    if (this.reportInformation) {
      var result="";
      if (typeof this.reportInformation.date === 'object' && this.reportInformation.date !== null && 'toLocaleDateString' in this.reportInformation.date) {
        result = this.reportInformation.date.toLocaleDateString('sv');
      }else{
        result = this.reportInformation.date;
        this.reportInformation.date=new Date(this.reportInformation.date);
      }
      if(this.currentLanguage=='sv'||this.currentLanguage===0){
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        language:{"sEmptyTable": "Ingen data hittades",
        "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
        "sInfoEmpty": "Visar 0 till 0 av totalt 0",
        "sInfoFiltered": "(filtrerat från totalt _MAX_)",
        "sLoadingRecords": "Laddar...",
        "sSearch": "Filtrera:",
        "sLengthMenu": "Visa _MENU_ rader",
        "sZeroRecords": "Inga poster hittades",
        "oPaginate": {
            "sFirst": "Första",
            "sLast": "Sista",
            "sNext": "Nästa",
            "sPrevious": "Föregående"}
        },
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          },
          {
            text: this.translate.instant('EXCEL_WITH_PICTOGRAMS'),
            action: () => {
              this.exportToExcel(); // Call your custom exportToExcel function
            },
          },
        ]
      };
    }else{
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          },
          {
            text: this.translate.instant('EXCEL_WITH_PICTOGRAMS'),
            action: () => {
              this.exportToExcel(); // Call your custom exportToExcel function
            },
          },
        ]
      };
    }
      if(this.subscribesToClassification&&this.subscribesToTransport){
        this.dtOptions.columnDefs=[{targets:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],visible:true},{targets:'_all', visible: false}];
      }else if(!this.subscribesToTransport&&!this.subscribesToClassification){
        this.dtOptions.columnDefs=[{targets:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14],visible:true},{targets:'_all', visible: false}];
      }else if(!this.subscribesToTransport){
        this.dtOptions.columnDefs=[{targets:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],visible:true},{targets:'_all', visible: false}];
      }else if(!this.subscribesToClassification){
        this.dtOptions.columnDefs=[{targets:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],visible:true},{targets:'_all', visible: false}];
      }
    }

    this.getAllDepartments();
    this.getReport();
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  exportToExcel() {
    this.loadingData = true;
    const products = this.reportInformation.products;
    if (!products || products.length === 0) {
      console.error('No products available for export.');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report Export');

    const headers = [
      this.translate.instant('SUPPLIER'),
      this.translate.instant('NAME'),
      this.translate.instant('DEPARTMENT'),
      this.translate.instant('PICTOGRAM'),
      this.translate.instant('AMOUNT_ON_DEPARTMENT'),
      this.translate.instant('ANNUAL_CONSUMPTION'),
      this.translate.instant('UNIT'),
      this.translate.instant('ARTICLE_NUMBER'),
      this.translate.instant('COMMON_USE'),
      this.translate.instant('OBSERVATION'),
      this.translate.instant('DEPARTMENT_INFO'),
      this.translate.instant('HYGIENE_LIMITS'),
      this.subscribesToClassification ? this.translate.instant('CLASSIFICATION') : null,
      this.translate.instant('OWN_ARTICLE'),
      this.translate.instant('PRODUCT_GROUP'),
      this.translate.instant('DATE'),
      this.subscribesToTransport ? this.translate.instant('DANGEROUS_GOODS') : null,
      this.translate.instant('SAFETY_EXT_DATA_SHEET'),
      this.translate.instant('RISK_LEVEL'),
    ].filter((header) => header !== null);

    // Add headers to worksheet
    worksheet.addRow(headers);

    // Set the width of the "Pictogram" column
    const pictogramColumnIndex = headers.findIndex(
      (header) => header.toLowerCase() === this.translate.instant('PICTOGRAM').toLowerCase()
    ) + 1; // ExcelJS columns are 1-based index

    // Set dynamic column width based on the number of images
    const baseImageWidth = 20; // Width per image in Excel units
    const imagesPerRow = 4; // Max images per row in the cell
    const columnWidth = baseImageWidth * imagesPerRow + 2; // Add padding
    worksheet.getColumn(pictogramColumnIndex).width = columnWidth;

    const imagePromises: Promise<void>[] = [];

    products.forEach((product, rowIndex) => {
      if (this.subscribesToClassification &&  this.subscribesToTransport) {
      var rowData: any[] = [
        product.supplier_name,
        product.name,
        this.departFullName(product.departmentid),
        '', // Placeholder for images
        product.amount,
        product.annual,
        product.unit,
        product.supplier_article_number,
        product.com_use,
        product.observation,
        product.department_info,
        product.hygiene_limits == 1 ? 'Ja' : 'Nej',
        this.subscribesToClassification
          ? this.removeItemsWithSameName(product.c_phrases).join(', ')
          : '',
        product.customerarticle,
        product.group_name,
        product.last_changed,
        !product.adr_not_classfied && !product.transport_un_nbr && this.subscribesToTransport
          ? ''
          : product.transport_un_nbr && !product.adr_not_classfied && this.subscribesToTransport
          ? `UN ${product.transport_un_nbr}`
          : 'No',
        product.esdb_available === 1 ? 'Yes' : 'No',
        product.risk_prio,
      ];
      } else if (!this.subscribesToClassification &&  this.subscribesToTransport) {
        var rowData: any[] = [
          product.supplier_name,
          product.name,
          this.departFullName(product.departmentid),
          '', // Placeholder for images
          product.amount,
          product.annual,
          product.unit,
          product.supplier_article_number,
          product.com_use,
          product.observation,
          product.department_info,
          product.hygiene_limits == 1 ? 'Ja' : 'Nej',
          product.customerarticle,
          product.group_name,
          product.last_changed,
          !product.adr_not_classfied && !product.transport_un_nbr && this.subscribesToTransport
            ? ''
            : product.transport_un_nbr && !product.adr_not_classfied && this.subscribesToTransport
            ? `UN ${product.transport_un_nbr}`
            : 'No',
          product.esdb_available === 1 ? 'Yes' : 'No',
          product.risk_prio,
        ];
      } else if (this.subscribesToClassification &&  !this.subscribesToTransport) {
        var rowData: any[] = [
          product.supplier_name,
          product.name,
          this.departFullName(product.departmentid),
          '', // Placeholder for images
          product.amount,
          product.annual,
          product.unit,
          product.supplier_article_number,
          product.com_use,
          product.observation,
          product.department_info,
          product.hygiene_limits == 1 ? 'Ja' : 'Nej',
          this.subscribesToClassification
            ? this.removeItemsWithSameName(product.c_phrases).join(', ')
            : '',
          product.customerarticle,
          product.group_name,
          product.last_changed,
          product.esdb_available === 1 ? 'Yes' : 'No',
          product.risk_prio,
        ];
    } else if (!this.subscribesToTransport && !this.subscribesToClassification) {
      var rowData: any[] = [
        product.supplier_name,
        product.name,
        this.departFullName(product.departmentid),
        '', // Placeholder for images
        product.amount,
        product.annual,
        product.unit,
        product.supplier_article_number,
        product.com_use,
        product.observation,
        product.department_info,
        product.hygiene_limits == 1 ? 'Ja' : 'Nej',
        product.customerarticle,
        product.group_name,
        product.last_changed,
        product.esdb_available === 1 ? 'Yes' : 'No',
        product.risk_prio,
      ];
    }


      worksheet.addRow(rowData);

      // Handle images in the pictograms column
      if (product.pictograms && product.pictograms.length > 0) {
        const baseRowHeight = 20; // Base row height for a single image
        const imagesPerRow = 4; // Max images per row in the cell
        const totalRowsNeeded = Math.ceil(product.pictograms.length / imagesPerRow);
        const requiredRowHeight = baseRowHeight * totalRowsNeeded;

        // Dynamically calculate the vertical offset multiplier based on row height
        const verticalOffsetMultiplier = requiredRowHeight / (totalRowsNeeded * baseRowHeight);

        // Set the row height dynamically
        worksheet.getRow(rowIndex + 2).height = requiredRowHeight;

        product.pictograms.forEach((pictogram, imageIndex) => {
          const imgSrc = `/images/pictograms/${pictogram.filename}-tiny.png`; // Construct the image path
          const fetchImage = fetch(imgSrc)
            .then((response) => response.blob())
            .then((blob) => {
              const reader = new FileReader();
              return new Promise<string>((resolve) => {
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(blob);
              });
            })
            .then((base64Image) => {
              const imageId = workbook.addImage({
                base64: base64Image,
                extension: 'png',
              });

              // Calculate offsets for images within the cell
              const colIndex = pictogramColumnIndex - 1; // Zero-based index
              const rowPosition = rowIndex + 2; // Adjust for header row

              const horizontalPosition = imageIndex % imagesPerRow; // Horizontal index within the row
              var verticalPosition = Math.floor(imageIndex / imagesPerRow); // Wrap to next line after max images per row

              worksheet.addImage(imageId, {
                tl: {
                  col: colIndex + 0.1 + horizontalPosition * 0.2, // Adjust fractional column offset
                  row: (rowPosition - 1) + 0.1 + verticalPosition * 0.3, // Adjust fractional row offset
                },
                ext: {
                  width: 20, // Image width in pixels
                  height: 20, // Image height in pixels
                },
              });
            });

          imagePromises.push(fetchImage);
        });
      }
    });

    Promise.all(imagePromises).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'SimplifiedReport.xlsx');
        this.loadingData = false;
      });
    });
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  getReport() {

    this.loadingData = true;

    if (this.reportInfo) {

      var reportDate = new Date(this.reportInfo.date);
      reportDate = this.utilService.adjustDateForTimezoneOffset(reportDate);

      var info = {
        reportType: this.reportInfo.reportType.url,
        start_date: reportDate,
        date: reportDate,
        sub: this.reportInfo.includeSubDepartments,
        actual: this.reportInfo.Actual
      }

      this.reportService.getProducts(info);
      this.reportService.getReportDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {
            this.loadingData = false;
          }
          else {
            this.reportInformation.products = products;

            var ids = [];
            for (var i = 0; i < this.reportInformation.products.length; i++) {
                ids.push({id: this.reportInformation.products[i].sareq_id, lang: this.currentLanguage});
            }

            for (var i = 0; i < this.reportInformation.products.length; i++ ) {
                if( this.reportInformation.products[i].override != null) {
                  this.reportInformation.products.splice(i, 1);
                }
            }

            this.loadingData = false;

            if (this.currentLanguage != 0) {
              this.productService.checkForOtherLanguages(this.organisation.id, ids);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: otherLangs => {
                  if (otherLangs == null) {}
                  else {
                    for (var i = 0; i < this.reportInformation.products.length; i++){
                      for (var j = 0; j < otherLangs.length; j++) {
                        if (this.reportInformation.products[i].sareq_id == otherLangs[j].override) {

                            this.reportInformation.products[i].name = otherLangs[j].name;
                            this.reportInformation.products[i].sds_file_id = otherLangs[j].sds_file_id;
                            this.reportInformation.products[i].overridden = true;
                            this.reportInformation.products[i].origProdId = this.reportInformation.products[i].id;
                            this.reportInformation.products[i].id = otherLangs[j].id;
                        }
                      }
                    }
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                  this.loadingData = false;
                }
              })
            } else {
              for ( var i = 0; i < this.reportInformation.products.length; i++ ) {
                if (this.reportInformation.products[i].override != undefined ) {
                  this.reportInformation.products.splice( i, 1 );
                }
              }
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire({
            title: this.translate.instant("NO_PRODUCTS"),
            text: this.translate.instant("NO_PRODUCTS_WARNING"),
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK"),
            closeOnConfirm: true
          });
          this.loadingData = false;
        }
      })
    }
  }

  removeItemsWithSameName(thisArray) {

    if (thisArray.length == 0) {
      return [];
    }

    var output = [];
    var isinarray = [];

    _.forEach(thisArray, function(item) {
      if(isinarray.indexOf(item) < 0){
        output.push(item);
        isinarray.push(item);
      }
    });
    return output;
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
  }

  remove() {
    this.activeModal.close(ReportSimplifiedComponent);
  }
}
