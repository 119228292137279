<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
    <div class="loader"></div>
</div>

<div class="overflow-auto">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <div class="flex flex-row justify-between">
            <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="goToProductItem()" id="itemProductButton18">
                <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
                <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
                </svg>
            </button>
            <div class="flex flex-row justify-end py-4 mr-4">
                <a (click)="editHI()" title="{{'EDIT' | translate}}" class="dropdown-menu-help-item">
                  <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#000000"/>
                </svg>
                </a>
            </div>
        </div>

        <div *ngIf="!loadingData && product" class="flex px-4 w-full py-2 mb-2 bg-cdocbanner">
            <div class="w-5/6 mt-2">
                <div class="flex flex-col">
                    <p class="text-gray-200"><a (click)="showManufacturerInformation(product)" id="itemProductButton1"><strong>{{product.supplier_name}}</strong></a></p>
                    <span class="text-white text-2xl font-bold">{{product?.product_name}}</span>
                    <span *ngIf="product?.alternative_name" class="text-white font-bold">{{"ALTERNATIVE_NAME2" | translate}}: {{product.alternative_name}}</span>
                    <label *ngIf="product?.replaced_by" class="bg-yellow w-40 p-2 text-white">{{'Ersättare finns'}}</label>
                </div>
            </div>
            <div *ngIf="product&&product.LRB_risk" class="hidden md:block w-1/6">
                <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.LRB_risk&&product.LRB_risk==1"
                    tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_1_DESCRIPTION' | translate}}">
                    {{product.LRB_risk}}</span>
                <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.LRB_risk&&product.LRB_risk==2"
                    tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_2_DESCRIPTION' | translate}}">
                    {{product.LRB_risk}}</span>
                <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product.LRB_risk&&product.LRB_risk==3"
                    tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_3_DESCRIPTION' | translate}}">
                    {{product.LRB_risk}}</span>
                <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.LRB_risk&&product.LRB_risk==4"
                    tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_4_DESCRIPTION' | translate}}">
                    {{product.LRB_risk}}</span>
                <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.LRB_risk&&product.LRB_risk==5"
                    tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_5_DESCRIPTION' | translate}}">
                    {{product.LRB_risk}}</span>
            </div>
            <div *ngIf="product&&!product.LRB_risk" class="hidden md:block w-1/6">
                <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.risk_prio&&product.risk_prio==1"
                    tooltips tooltip-smart="true" title="{{'RISK_1_DESCRIPTION' | translate}}">
                    {{product.risk_prio}}</span>
                <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.risk_prio&&product.risk_prio==2"
                    tooltips tooltip-smart="true" title="{{'RISK_2_DESCRIPTION' | translate}}">
                    {{product.risk_prio}}</span>
                <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.risk_prio&&product.risk_prio==3"
                    tooltips tooltip-smart="true" title="{{'RISK_3_DESCRIPTION' | translate}}">
                    {{product.risk_prio}}</span>
                <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.risk_prio&&product.risk_prio==4"
                    tooltips tooltip-smart="true" title="{{'RISK_4_DESCRIPTION' | translate}}">
                    {{product.risk_prio}}</span>
                <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full border border-gray-200 flex items-center justify-center"
                    *ngIf="product&&product.risk_prio&&product.risk_prio==5"
                    tooltips tooltip-smart="true" title="{{'RISK_5_DESCRIPTION' | translate}}">
                    {{product.risk_prio}}</span>
            </div>
        </div>

        <div *ngIf="!loadingData && product" class="flex flex-col md:flex-row bg-white mb-40">
            <div class="flex flex-col w-full md:w-1/2 mr-2 mt-10 p-4">
                <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="hiListChartConfig"
                    class="h-30 w-30"
                ></highcharts-chart>
                <div class="p-4">
                    <span class="flex flex-row items-center text-lg font-bold">{{"SPECIAL_REQUIREMENTS" | translate}}
                        <sup><img class="w-4 h-4" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" title="Utbildning, tjänstbarhetsintyg eller hälsoundersökning"/></sup>
                    </span>
                    <p *ngIf="product.training">{{"TRAINING" | translate}}: {{product.training}}</p>
                    <p *ngIf="product.health_control">{{"HEALTH_CONTROL" | translate}}: {{product.health_control}}</p>
                    <p *ngIf="product.fitness_for_duty">{{"FITNESS_FOR_DUTY" | translate}}: {{product.fitness_for_duty}}</p>
                    <p *ngIf="product.information">{{"INFORMATION_DEMAND" | translate}}: {{product.information}}</p>
                    <p *ngIf="product.no_special_demands">{{"NO_SPECIAL_DEMANDS" | translate}}: {{product.no_special_demands}}</p>
                    <p *ngIf="!product.training && !product.health_control && !product.fitness_for_duty && !product.information && !product.no_special_demands">-{{"UNKNOWN2" | translate}}</p>
                </div>
                <div class="p-4">
                    <span class="text-lg font-bold">{{"PROTECTIVE_GEAR" | translate}} </span>
                    <span class="flex flex-row font-bold mt-4">{{"RESPIRATORY" | translate}} </span>
                    <p class="font-normal">{{product.protection.breath}}</p>
                    <span class="flex flex-row font-bold mt-4">{{"HAND_PROTECTION" | translate}} </span>
                    <p class="font-normal">{{product.protection.gloves}}</p>
                    <span class="flex flex-row font-bold mt-4">{{"EYE_PROTECTION" | translate}} </span>
                    <p class="font-normal">{{product.protection.glasses}}</p>
                    <span class="flex flex-row font-bold mt-4">{{"PROTECTIVE_CLOTHING" | translate}} </span>
                    <p class="font-normal">{{product.protection.clothes}}</p>
                    <span class="flex flex-row font-bold mt-4">{{"PROTECTIVE_AND_HYGIENE_MEASURES" | translate}} </span>
                    <p class="font-normal">{{product.protection.hygiene}}</p>
                </div>
            </div>
            <div *ngIf="!editHealthIndex" class="flex flex-col w-full md:w-1/2 mr-2 mt-10 p-4">
                <div class="flex flex-row">
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'DATE' | translate}}:</span>
                        <span>{{ product.analysis_date ? (product.analysis_date | date:'yyyy-MM-dd') : '-' }}</span>
                    </div>
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'STATUS' | translate}}:</span>
                        <span>{{hi.status.name}}</span>
                    </div>
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'RESPONSIBLE' | translate}}:</span>
                        <span>{{product.analysis_responsible}}</span>
                    </div>
                </div>
                <div class="flex flex-row mt-4">
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'REPORT_SDS' | translate}}:</span>
                        <span>{{product.rework_date | date:'yyyy-MM-dd'}}</span>
                    </div>
                    <div *ngIf="documentHistory && (documentHistory.grb.length > 0)" class="flex flex-col w-1/3">
                        <span class="font-bold">{{'RISK_ASSESSMENT' | translate}}:</span>
                        <span>{{documentHistory.grb[0].last_changed | date:'yyyy-MM-dd'}}</span>
                    </div>
                </div>
                <div class="flex flex-col mt-4">
                    <span class="text-lg font-bold">{{'RISKS' | translate}}</span>
                    <p class="text-sm"><strong>{{'RISK_ASSESSMENT' | translate}}:</strong> &nbsp;&nbsp;{{product.risk_prio}}</p>
                    <p class="text-sm"><strong>{{'USAGE' | translate}}:</strong></p>
                    <p class="text-sm">{{hi.analysis[8].name}}</p>
                </div>
                <div class="flex flex-col mt-4">
                    <span class="text-lg font-bold">{{'PROCESS_RISKS' | translate}}</span>
                    <p class="text-sm"><strong>{{'PRODUCT_CHOICE' | translate}}:</strong>
                    <p class="text-sm">{{hi.analysis[0].name}}</p>
                    <p class="text-sm"><strong>{{'STORAGE' | translate}}:</strong></p>
                    <p class="text-sm">{{hi.analysis[1].name}}</p>
                    <p class="text-sm"><strong>{{'USAGE' | translate}}:</strong></p>
                    <p class="text-sm">{{hi.analysis[2].name}}</p>
                    <p class="text-sm"><strong>{{'WASTE_HANDLING' | translate}}:</strong></p>
                    <p class="text-sm">{{hi.analysis[3].name}}</p>
                    <p class="text-sm"><strong>{{'COMMENTS' | translate}}:</strong></p>
                    <p class="text-sm">{{hi.analysis.analysis_comment}}</p>
                </div>
            </div>
            <div *ngIf="editHealthIndex" class="flex flex-col w-full md:w-1/2 mr-2 mt-10 p-4">
                <div class="flex flex-row">
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'DATE' | translate}}:</span>
                        <input class="mt-2 border border-gray-300 w-2/3 p-1 text-sm" type="date" [ngModel]="product.analysis_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?product.analysis_date = $event:null"  [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'STATUS' | translate}}:</span>
                        <div class="relative inline-block text-left z-10 mt-2 border border-gray-300 p-1 w-2/3 z-20" appDropdown>
                            <button type="button" class="flex justify-between items-center  text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                              <span class="nav-home-text text-sm">{{hi.status.name}}</span>
                              <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu w-40" #dropdownMenuInside>
                                <div class="py5" *ngFor="let option of hiStatusOptionsList" data-value="option" class="dropdown-menu-item"
                                    (click)="setAnalysisStatus(option)" id="itemProductButton30" tabindex="1">{{ option.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'RESPONSIBLE' | translate}}:</span>
                        <input title="{{'RESPONSIBLE' | translate}}" class="mt-2 border border-gray-300 w-2/3 p-1 text-sm" type="text" [(ngModel)]="product.analysis_responsible" (ngModelChange)="product.analysis_responsible = $event" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="flex flex-row mt-4">
                    <div class="flex flex-col w-1/3">
                        <span class="font-bold">{{'REPORT_SDS' | translate}}:</span>
                        <span>{{product.rework_date | date:'yyyy-MM-dd'}}</span>
                    </div>
                    <div *ngIf="documentHistory && (documentHistory.grb.length > 0)" class="flex flex-col w-1/3">
                        <span class="font-bold">{{'RISK_ASSESSMENT' | translate}}:</span>
                        <span>{{documentHistory.grb[0].last_changed | date:'yyyy-MM-dd'}}</span>
                    </div>
                </div>
                <div class="flex flex-col mt-4">
                    <span class="text-lg font-bold mt-2">{{'RISKS' | translate}}</span>
                    <p class="flex flex-row text-sm"><strong>{{'RISK_ASSESSMENT' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Riskbedömning" (click)="showAnalysis0=!showAnalysis0"/></sup>
                        &nbsp;&nbsp;{{product.risk_prio}}
                    </p>
                    <span class="text-sm" *ngIf="showAnalysis0==true">
                        <em>
                            {{"ASSESSMENT_INFO_1" | translate}} <br/> {{"ASSESSMENT_INFO_1_2" | translate}} <br/><br/> {{"ASSESSMENT_INFO_1_3" | translate}} <br/><br/>
                        </em>
                    </span>
                    <p class="flex flex-row text-sm mt-2"><strong>{{'USAGE_AREA' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Användningsområde" (click)="showAnalysis1=!showAnalysis1"/></sup>
                    </p>
                    <div class="relative inline-block text-left z-10 mt-2 border border-gray-300 p-1 w-2/3 z-40" appDropdown>
                        <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-sm">{{hi.analysis[8].name}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu w-100" #dropdownMenuInside>
                            <div class="py5" *ngFor="let option of hiOptionsList2" data-value="option" class="dropdown-menu-item"
                                (click)="setAnalysisUsage(option)" id="itemProductButton31" tabindex="1">{{ option.name }}
                            </div>
                        </div>
                    </div>
                    <span class="text-sm mt-2" *ngIf="showAnalysis1==true">
                        <em>
                            <p>{{"ASSESSMENT_INFO_2" | translate}}</p>
                            <p class="mt-2">{{"ASSESSMENT_INFO_2_1" | translate}}</p>
                            <p class="mt-2">{{"ASSESSMENT_INFO_2_2" | translate}}</p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_2_3" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_2_4" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_2_5" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_2_6" | translate}}</li>
                            </ul>
                        </em>
                    </span>
                </div>
                <div class="flex flex-col mt-4">
                    <span class="text-lg font-bold mt-2">{{'PROCESS_RISKS' | translate}}</span>
                    <strong>{{"ANSWER_ALT" | translate}}:</strong>
                    <p class="text-sm"><em>{{"ASSESSMENT_INFO_3" | translate}}<br>{{"ASSESSMENT_INFO_3_1" | translate}}<br>{{"ASSESSMENT_INFO_3_2" | translate}}</em></p>
                    <p class="flex flex-row text-sm mt-4"><strong>{{'PRODUCT_CHOICE' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Produktval" (click)="showAnalysis2=!showAnalysis2"/></sup>
                    </p>
                    <div class="relative inline-block text-left z-20 mt-2 border border-gray-300 p-1 w-2/3 z-50" appDropdown>
                        <button type="button" class="flex justify-between text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-sm">{{hi.analysis[0].name}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu w-100" #dropdownMenuInside>
                            <div *ngFor="let option of hiOptionsList3" data-value="option" class="dropdown-menu-item"
                                (click)="setAnalysisChoice(option)" id="itemProductButton32" tabindex="1">{{ option.name }}
                            </div>
                        </div>
                    </div>
                    <span class="text-sm mt-2" *ngIf="showAnalysis2==true">
                        <em>
                            <p>{{"ASSESSMENT_INFO_4" | translate}}</p>
                            <p>{{"ASSESSMENT_INFO_4_1" | translate}}</p>
                            <p>{{"ASSESSMENT_INFO_4_2" | translate}}</p>
                            <ul class="list-disc list-inside mt-2">
                                <li>{{"ASSESSMENT_INFO_4_3" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_4_4" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_4_5" | translate}}</li>
                            </ul>
                        </em>
                    </span>
                    <p class="flex flex-row text-sm mt-4"><strong>{{'STORAGE' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Lagring" (click)="showAnalysis3=!showAnalysis3"/></sup>
                    </p>
                    <div class="relative inline-block text-left z-20 mt-2 border border-gray-300 p-1 w-2/3 z-40" appDropdown>
                        <button type="button" class="flex justify-between text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="text-sm">{{hi.analysis[1].name}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu w-100" #dropdownMenuInside>
                            <div class="dropdown-menu-item" *ngFor="let option of hiOptionsList1" data-value="option"
                                (click)="setAnalysisStorage(option)" id="itemProductButton33" tabindex="1">{{ option.name }}
                            </div>
                        </div>
                    </div>
                    <span class="text-sm mt-2" *ngIf="showAnalysis3==true">
                        <em>
                            <p>{{"ASSESSMENT_INFO_5" | translate}}</p>
                            <p class="mt-1">{{"ASSESSMENT_INFO_5_1" | translate}}: </p>
                            <div class="flex items-center space-x-2 mt-2" >
                                <span class="text-sm mr-1" for="showIndoor">{{"ASSESSMENT_INFO_5_2" | translate}}:</span>
                                <input title="{{'ASSESSMENT_INFO_5_2' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="showAnalysis3_1" name="example" [(ngModel)]="showAnalysis3_1" (ngModelChange)="showAnalysis3_1 = $event" [ngModelOptions]="{standalone: true}" (change)="toggleCheckboxIndoor($event)" id="showIndoor">
                            </div>
                            <div class="flex items-center space-x-2 mt-2" >
                                <span class="text-sm mr-1" for="showOutdoor">{{"ASSESSMENT_INFO_5_3" | translate}}:</span>
                                <input title="{{'ASSESSMENT_INFO_5_3' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="showAnalysis3_2" name="example" [(ngModel)]="showAnalysis3_2" (ngModelChange)="showAnalysis3_2 = $event" [ngModelOptions]="{standalone: true}" (change)="toggleCheckboxOutdoor($event)" id="showOutdoor">
                            </div>
                            <div *ngIf="showAnalysis3_1">
                                <p class="text-lg font-bold mt-2" *ngIf="showAnalysis3_1">{{"ASSESSMENT_INFO_5_2" | translate}}</p>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_4" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_5" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_6" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_7" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_8" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_9" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_10" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_11" | translate}}</li>
                                </ul>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_12" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_13" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_14" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_15" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_16" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_17" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_18" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_19" | translate}}</li>
                                </ul>
                                <p>{{"ASSESSMENT_INFO_5_20" | translate}}</p>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_21" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_22" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_23" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_24" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_25" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_26" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_27" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_28" | translate}}</li>
                                </ul>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_29" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_30" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_31" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_32" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_33" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_34" | translate}}</li>
                                </ul>
                            </div>
                            <div *ngIf="showAnalysis3_2">
                                <p class="text-lg font-bold mt-2" *ngIf="showAnalysis3_2">{{"ASSESSMENT_INFO_5_3" | translate}}</p>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_35" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_36" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_37" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_38" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_39" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_40" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_41" | translate}}</li>
                                </ul>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_42" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_43" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_44" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_45" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_46" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_47" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_48" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_49" | translate}}</li>
                                </ul>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_50" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_51" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_52" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_53" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_54" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_55" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_56" | translate}}</li>
                                </ul>
                                <p class="mt-2"><strong>{{"ASSESSMENT_INFO_5_57" | translate}}</strong></p>
                                <ul class="list-disc list-inside mt-2 px-4">
                                  <li>{{"ASSESSMENT_INFO_5_58" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_59" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_60" | translate}}</li>
                                  <li>{{"ASSESSMENT_INFO_5_61" | translate}}</li>
                                </ul>
                            </div>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_62" | translate}}</span>
                                <span class="font-bold mt-2">{{"UNINTENTIONAL_EMISSIONS" | translate}}</span>
                                <p>{{product.handling_emissions}}</p>
                                <span class="font-bold mt-2">{{"HANDLING_AND_STORAGE" | translate}}</span>
                                <p>{{product.handling_storage}}</p>
                                <span class="font-bold mt-2">{{"FIRE_MEASURES" | translate}}</span>
                                <p>{{product.handling_fire_measures}}</p>
                            </div>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_64" | translate}}</span>
                                <div class="flex flew-row">
                                    <div *ngFor="let protective of product.protectiveArray">
                                        <img title="protective" src="/images/protective/{{protective.filename}}-tiny.png" />
                                    </div>
                                    <div *ngFor="let pictogram of product.pictograms">
                                        <img title="pictogram" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                        <span *ngIf="pictogram.name=='clp_is_flammable'">{{"ASSESSMENT_INFO_5_62" | translate}}<br></span>
                                    </div>
                                </div>
                            </div>
                        </em>
                    </span>
                    <p class="flex flex-row text-sm mt-4"><strong>{{'USAGE' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Lagring" (click)="showAnalysis4=!showAnalysis4"/></sup>
                    </p>
                    <div class="relative inline-block text-left z-20 mt-2 border border-gray-300 p-1 w-2/3 z-30" appDropdown>
                        <button type="button" class="flex justify-between text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-sm">{{hi.analysis[2].name}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu w-100" #dropdownMenuInside>
                            <div class="py5" *ngFor="let option of hiOptionsList4" data-value="option" class="dropdown-menu-item"
                                (click)="setAnalysisUsageProduct(option)" id="itemProductButton34" tabindex="1">{{ option.name }}
                            </div>
                        </div>
                    </div>
                    <span class="text-sm mt-2" *ngIf="showAnalysis4==true">
                        <em>
                            <span class="font-bold">{{"ASSESSMENT_INFO_6" | translate}}</span>
                            <p class="mt-2"><strong>{{"ASSESSMENT_INFO_6_1" | translate}}</strong></p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_6_2" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_2" | translate}}</li>
                            </ul>
                            <p class="mt-2"><strong>{{"ASSESSMENT_INFO_6_4" | translate}}</strong></p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_6_5" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_6" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_7" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_8" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_9" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_10" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_11" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_12" | translate}}</li>
                            </ul>
                            <p class="mt-2"><strong>{{"ASSESSMENT_INFO_6_13" | translate}}</strong></p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_6_14" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_15" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_16" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_17" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_18" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_19" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_20" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_21" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_22" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_23" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_24" | translate}}</li>
                            </ul>
                            <p class="mt-2"><strong>{{"ASSESSMENT_INFO_6_25" | translate}}</strong></p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_6_26" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_27" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_6_28" | translate}}</li>
                            </ul>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_62" | translate}}</span>
                                <span class="font-bold mt-2">{{"UNINTENTIONAL_EMISSIONS" | translate}}</span>
                                <p>{{product.handling_emissions}}</p>
                                <span class="font-bold mt-2">{{"HANDLING_AND_STORAGE" | translate}}</span>
                                <p>{{product.handling_storage}}</p>
                                <span class="font-bold mt-2">{{"FIRE_MEASURES" | translate}}</span>
                                <p>{{product.handling_fire_measures}}</p>
                            </div>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_64" | translate}}</span>
                                <div class="flex flew-row">
                                    <div *ngFor="let protective of product.protectiveArray">
                                        <img title="protective" src="/images/protective/{{protective.filename}}-tiny.png" />
                                    </div>
                                    <div *ngFor="let pictogram of product.pictograms">
                                        <img title="pictogram" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                        <span *ngIf="pictogram.name=='clp_is_flammable'">{{"ASSESSMENT_INFO_5_62" | translate}}<br></span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="product.training || product.information || product.health_control || product.fitness_for_duty || product.no_special_demands" class="flex flex-col text-sm mt-4">
                                <p><strong>{{"ASSESSMENT_INFO_6_25" | translate}}</strong></p>
                                <p class="mt-2" *ngIf="product.training">{{"TRAINING" | translate}}: {{product.training}}</p>
                                <p class="mt-2" *ngIf="product.health_control">{{"HEALTH_CONTROL" | translate}}: {{product.health_control}}</p>
                                <p class="mt-2" *ngIf="product.fitness_for_duty">{{"FITNESS_FOR_DUTY" | translate}}: {{product.fitness_for_duty}}</p>
                                <p class="mt-2" *ngIf="product.information">{{"INFORMATION_DEMAND" | translate}}: {{product.information}}</p>
                                <p class="mt-2" *ngIf="product.no_special_demands">{{"NO_SPECIAL_DEMANDS" | translate}}: {{product.no_special_demands}}</p>
                            </div>
                        </em>
                    </span>
                    <p class="flex flex-row text-sm mt-4"><strong>{{'WASTE' | translate}}:</strong>
                        <sup><img class="w-4 h-4 pointer-cursor" src="/images/icons/question-svgrepo-com.svg" area-hidden="true" title="Avfall" (click)="showAnalysis5=!showAnalysis5"/></sup>
                    </p>
                    <div class="relative inline-block text-left z-20 mt-2 border border-gray-300 p-1 w-2/3 z-20" appDropdown>
                        <button type="button" class="flex justify-between text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-sm">{{hi.analysis[3].name}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu w-100" #dropdownMenuInside>
                            <div class="py5" *ngFor="let option of hiOptionsList5" data-value="option" class="dropdown-menu-item"
                                (click)="setAnalysisWaste(option)" id="itemProductButton35" tabindex="1">{{ option.name }}
                            </div>
                        </div>
                    </div>
                    <span class="text-sm mt-2" *ngIf="showAnalysis5==true">
                        <em>
                            <span class="font-bold">{{"ASSESSMENT_INFO_7" | translate}}</span>
                            <p class="mt-2"><strong>{{"ASSESSMENT_INFO_7_1" | translate}}</strong></p>
                            <ul class="list-disc list-inside mt-2 px-4">
                                <li>{{"ASSESSMENT_INFO_7_2" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_7_3" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_7_4" | translate}}</li>
                                <li>{{"ASSESSMENT_INFO_7_5" | translate}}</li>
                            </ul>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_62" | translate}}</span>
                                <span class="font-bold mt-2">{{"UNINTENTIONAL_EMISSIONS" | translate}}</span>
                                <p>{{product.handling_emissions}}</p>
                                <span class="font-bold mt-2">{{"HANDLING_AND_STORAGE" | translate}}</span>
                                <p>{{product.handling_storage}}</p>
                            </div>
                            <div class="flex flex-col text-sm mt-4">
                                <span class="font-bold">{{"ASSESSMENT_INFO_5_64" | translate}}</span>
                                <div class="flex flew-row">
                                    <div *ngFor="let protective of product.protectiveArray">
                                        <img title="protective" src="/images/protective/{{protective.filename}}-tiny.png" />
                                    </div>
                                    <div *ngFor="let pictogram of product.pictograms">
                                        <img title="pictogram" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                        <span *ngIf="pictogram.name=='clp_is_flammable'">{{"ASSESSMENT_INFO_5_62" | translate}}<br></span>
                                    </div>
                                </div>
                            </div>
                        </em>
                    </span>
                    <p class="flex flex-row text-sm mt-4"><strong>{{'COMMENTS' | translate}}:</strong></p>
                    <textarea title="{{'COMMENTS' | translate}}" class="border border-gray-300 p-2 w-2/3 mt-2" rows="2" [(ngModel)]="product.analysis_comment" (ngModelChange)="product.analysis_comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                </div>
                <div class="flex flex-row justify-left mb-20 mt-4">
                    <button *ngIf="editHealthIndex" id="itemProductButton36" class="button-green" title="{{'SAVE' | translate}}" (click)="updateHI()">
                        <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                        <span class="text-lg">{{ 'SAVE' | translate }}</span>
                      </button>
                </div>
            </div>
        </div>
    </div>
</div>
