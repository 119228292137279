import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FileUploadService } from './file-upload.service'; // Import your FileUploadService
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(private uploadService: FileUploadService) { }

  generatePDF(content: HTMLElement): Promise<any> {
    return new Promise((resolve, reject) => {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true
      });

      const options = {
        scale: 1,
        useCORS: true, // Allow cross-origin images
        logging: true, // Enable logging for debugging
        allowTaint: true // Allow tainted cross-origin images
      };

      const margins = {
        top: 15,
        bottom: 15,
        left: 15,
        right: 15
      };

      const elements = content.querySelectorAll('.content');
      let promiseChain = Promise.resolve();

      const footerText = "Chemical Documentation cDoc AB / Phone: 035-10 81 89 / E-mail: support@cdoc.se";

      elements.forEach((element, index) => {
        promiseChain = promiseChain
          .then(() => html2canvas(element as HTMLElement, options))
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            console.log(`Image Data URL: ${imgData.substring(0, 50)}...`);

            if (!imgData.startsWith('data:image/png')) {
              throw new Error('Generated image data is not a PNG');
            }

            const imgProps = doc.getImageProperties(imgData);
            const pdfWidth = doc.internal.pageSize.getWidth() - margins.left - margins.right;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            console.log(`Adding image of width ${pdfWidth} and height ${pdfHeight}`);

            if (index > 0) {
              doc.addPage();
            }

            doc.addImage(imgData, 'PNG', margins.left, margins.top, pdfWidth, pdfHeight);

            // Add footer text at the bottom of each page
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(8); // Set the font size for the footer text
            doc.text(footerText, margins.left, pageHeight - margins.bottom + 5);
          });
      });

      promiseChain
        .then(() => {

          // Convert the PDF to a Blob
          const pdfBlob = doc.output('blob');

          // Create a Blob URL
          resolve(pdfBlob);
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
          reject(error);
        });
    });
  }
}
