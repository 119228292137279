import { Component } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../../auth/auth.service';
import { ManufacturerService } from "src/app/services/manufacturer.service";
import { AssessmentChemicalService } from 'src/app/services/assessment-chemical.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalPrintCargodeclarationComponent } from 'src/app/modals/modal-print-cargodeclaration/modal-print-cargodeclaration.component';
import { ModalEditProductComponent } from 'src/app/modals/modal-edit-product/modal-edit-product.component';
import { ModalUploadFileComponent } from 'src/app/modals/modal-upload-file/modal-upload-file.component';
import { ModalOrderLabelsComponent } from 'src/app/modals/modal-order-labels/modal-order-labels.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SareqService } from "src/app/services/sareq.service";
import { SupportService } from 'src/app/services/support.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticDataService } from "src/app/services/static-data.service";
import { FormBuilder, Validators } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-product-item-risk',
  templateUrl: './product-item-risk.component.html',
  styleUrls: ['./product-item-risk.component.css']
})
export class ProductItemRiskComponent {

  Highcharts: typeof Highcharts = Highcharts;

  hiListChartConfig: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        width: 500,
        type: 'area'
    },
    xAxis: {
      gridLineWidth: 1,
      title: {
          text: localStorage.getItem('usedLanguage')!='en'?"Process":"Process"
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      min: 0,
      max: 5
    },
    yAxis: {
        title: {
            text: localStorage.getItem('usedLanguage')!='en'?"Produkt":"Product"
        },
        min: 0,
        max: 5
    },
    title: {
        text: localStorage.getItem('usedLanguage')!='en'?"Efterlevnadskontroll":"Risk analysis"
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
          label: {
              connectorAllowed: false
          },
          pointStart: 0
      },
      scatter: {
        marker: {
            symbol: 'circle',
            radius: 7,
            states: {
                hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                }
            }
        }
      }
    },
    series: [
    {
        name: 'Röd',
        type: 'area',
        data: [[0, 5],[5, 5],[5, 2],[0, 5]],
        enableMouseTracking: false,
        showInLegend: false,
        color: "#FF0000",
    },
    {
        name: 'Gul',
        type: 'area',
        data: [[0, 3], [0, 5], [5, 2],[5, 0],[4, 0],[0, 3]],
        enableMouseTracking: false,
        showInLegend: false,
        color: "#FFFF00",
    },
    {
        name: 'Grön',
        type: 'area',
        data: [[0, 3], [0, 3], [4, 0]],
        enableMouseTracking: false,
        showInLegend: false,
        color: "#80FF00",
    },
    {
        name: 'Grön',
        type: 'scatter',
        data: [[0,0]],
        enableMouseTracking: false,
        showInLegend: true,
        color: "#000",
    }],
  }

  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  productId: any = null;
  product_id_backup: any = null;
  currentLanguage: any;
  product: any;
  subscribesToReportEnvironment: any;
  subscribesToSpecialDemands: any;
  subscribesToSubstitution: any;
  subscribesToStamping: any;
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToFootprint: any;
  subscribesToTransport: any;
  subscribesToPHClassification: any;
  subscribesToGrb: any;
  footPrintText: any = "";
  showFootprint: any = "";
  picture: any = null;
  thisHeight: any;
  availableLangs: any;
  manufacturer: any;
  subscribesToSds: any;
  subscribesToProductsheet: any;
  subscribesToLabels: any;
  isFirstHelp: boolean = true;
  isProtective: boolean = false;
  isphrases: boolean = false;
  isPhClassification: boolean = false;
  isMixtures: boolean = false;
  isRiskActivity: boolean = false;
  isTransport: boolean = false;
  isSpecialDemand: boolean = false;
  isPhValueAndDosage: boolean = false;
  isHandling: boolean = false;
  isMisc: boolean = false;
  departmentList: any;
  documentHistory: any = null;
  showStampedDocument: boolean = false;
  subscribesToProductAnalysis: boolean = false;
  riskfile: any;
  editHealthIndex: boolean = false;
  hi: any = {};
  hiStatusOptionsList: any;
  hiOptionsList1: any;
  hiOptionsList2: any;
  hiOptionsList3: any;
  hiOptionsList4: any;
  hiOptionsList5: any;
  currentFiles: any;
  substitutionThisDepartment:boolean=true;
  showAnalysis0:boolean=false;
  showAnalysis1:boolean=false;
  showAnalysis2:boolean=false;
  showAnalysis3:boolean=false;
  showAnalysis3_1:boolean=false;
  showAnalysis3_2:boolean=false;
  showAnalysis4:boolean=false;
  showAnalysis5:boolean=false;
  activityBasedDataSheets;


  ngAfterViewInit(): void {
  }

  constructor(private utilService: UtilService,
              private organisationService: OrganisationService,
              private translate: TranslateService,
              private authService: AuthService,
              private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute,
              private manufacturerService: ManufacturerService,
              private modalService: NgbModal,
              private sareqService: SareqService,
              private supportService: SupportService,
              private staticDataService: StaticDataService,
              private substitutionService: SubstitutionService,
              private assessmentService: AssessmentService,
              private assessmentChemicalService: AssessmentChemicalService,
              private formBuilder: FormBuilder) { }

ngOnDestroy(): void{
  if(this.router.url.indexOf('product')!=-1||this.router.url.indexOf('productlist')!=-1)
  {

  }
  else
  {
    localStorage.removeItem('searchFilterEco',);
    localStorage.removeItem('searchFilterRisk',);
    localStorage.removeItem('searchFilterPictogram',);
    localStorage.removeItem('searchFilter',);
  }
}

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.productId = params['id'];
      this.picture = null;
      this.loadingData = true;
      this.product = null;
      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
      if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
      {
        this.logout();
      }


      var data = localStorage.getItem('currentUser', );
      if (data == null || data == undefined)
      {
        this.logout();
      }
      else
      {
        this.currentUser = JSON.parse(data);
      }

      this.subscribesToReportEnvironment = this.utilService.subscribesTo('subscription_report_enviroment');
      this.subscribesToSpecialDemands = this.utilService.subscribesTo('subscription_special_demands');
      this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
      this.subscribesToStamping = this.utilService.subscribesTo('subscription_stamped_SDS');
      this.subscribesToSIN = this.utilService.subscribesTo('subscription_sin');
      this.subscribesToSLL = this.utilService.subscribesTo('subscription_sll');
      this.subscribesToBASTA = this.utilService.subscribesTo('subscription_basta');
      this.subscribesToFootprint = this.utilService.subscribesTo('subscription_footprint');
      this.subscribesToTransport = this.utilService.subscribesTo('subscription_transport');
      this.subscribesToPHClassification = this.utilService.subscribesTo('subscription_classification');
      this.subscribesToGrb = this.utilService.subscribesTo('subscription_grb');
      this.subscribesToSds = this.utilService.subscribesTo('subscription_sds');
      this.subscribesToProductsheet = this.utilService.subscribesTo('subscription_productsheet');
      this.subscribesToLabels = this.utilService.subscribesTo('subscription_labels');
      this.subscribesToProductAnalysis = this.utilService.subscribesTo('subscription_product_analysis');

      this.getProduct();
      this.getProductDepartments();
    });
  }

  logout() {
    this.authService.logout();
  }

  goToProductItem() {
    this.router.navigate(['/product', this.productId]);
  }

  openGrbInNewWindow(product) {
    const filename = product.product_name + '_risk.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  openLrbInNewWindow(product) {
    const filename = product.name + '_risk.pdf';
    const newWindow = window.open("", "_blank");
    this.assessmentChemicalService.getLrbAsTrustedResourceUrl(this.selectedDepartment.id, product, 'productitem');
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  openSdsInNewWindow(product) {
    const filename = product.product_name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  openExtSdsInNewWindow(product) {
    const filename = product.product_name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  openStampedSdsInNewWindow(product) {
    const filename = product.product_name + '.pdf';
    const newWindow = window.open("", "_blank");
    this.productService.getStampedSdsAsTrustedResourceUrl(product.sds_stamp_id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
          if (newWindow) {
            newWindow.close();
          }
        } else {
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          } else {
            newWindow.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    });
  }

  showManufacturerInformation(product) {
    let object = { type: "get", id: this.product.supplier_id };
    this.manufacturerService.getSingle(object);
    this.manufacturerService.getManufacturerDataListener().pipe(take(1))
      .subscribe({next: (manufacturer) => {
          if (manufacturer == null) {
          } else {
            if (manufacturer) {
              Swal.fire({
                title: manufacturer[0].name,
                type: "info",
                allowOutsideClick: "true" ,
                html:
                "<div class='ui grid' style='overflow-y: scroll;overflow-x: hidden'>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 1:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_1 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 2:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_2 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("ZIP") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                          manufacturer[0].zip +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("CITY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].city +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("COUNTRY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].country +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("WEB_PAGE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].www +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("EMAIL") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].email +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("PHONE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].phone +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("FAX") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].fax +
                        "</div>" +
                    "</div>" +
                  "</div>"
              });
            }
          }
        },
        error: (error) => {},
    });
  }

  getProductDepartments() {
    if (this.productId) {
      this.productService.getDepartmentsWhereProductAppearsByProductId(this.productId, true);
      this.productService.getProductDepartmentDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.departmentList = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  formatNumbers(string){
    // The database doesnt accept swedish decimal comma on numbers
    if(!string){ return; }
    else if(string.toString().indexOf(",")==-1) {
      string = Math.round(Number(string)*1000)/1000;
      return string;
    } else {
      string = Math.round(Number(string.replace(',','.'))*1000)/1000;
      return string;
    }
  }

  getProduct() {

    if (this.selectedDepartment) {
      this.productService.getSingleProduct(this.selectedDepartment.id, this.productId);
      this.productService.getProductSingleDataListener().pipe(take(1)).subscribe({
        next: product => {
          if (product != null) {
            this.product = product;
            if ( this.product?.LRB_id && this.product?.LRB_id!='' && this.product?.LRB_id!=null && this.product?.LRB_status == 3 ) {
              this.getLRBInfo ( this.product );
            }
            if ( product.c_phrases && product.c_phrases.length > 1)
            {
              // Sorting alphabetically and only unique items
              this.sortCphrases( product );
            }
            if ( this.subscribesToSpecialDemands )
            {
              this.getCMRA_Category( product );
            }
            this.product_id_backup = this.product.id;
            this.product.o_footprint_water = this.product.o_footprint_water==1?true:false;
            this.product.o_footprint_air = this.product.o_footprint_air==1?true:false;
            this.product.o_footprint_product = this.product.o_footprint_product==1?true:false;
            this.product.o_footprint_waste = this.product.o_footprint_waste==1?true:false;
            this.product.maximum_amount=this.formatNumbers(this.product.maximum_amount);
            this.product.annual_consumption=this.formatNumbers(this.product.annual_consumption);
            this.product.amount=this.formatNumbers(this.product.amount);
            this.currentLanguage = localStorage.getItem('usedLanguage');

            switch (this.currentLanguage) {
              case 'sv':
                this.currentLanguage = 0;
              break;

              case 'en':
                this.currentLanguage = 1;
              break;

              case 'fi':
                this.currentLanguage = 2;
              break;

              default:
                this.currentLanguage = 0;
              break;
            }

            this.checkAnalysisValue();

            this.hiStatusOptionsList = this.staticDataService.getStatusOptionsList();
            this.hiOptionsList1 = this.staticDataService.getOptionsStoring();
            this.hiOptionsList2 = this.staticDataService.getOptionsAreaOfUse();
            this.hiOptionsList3 = this.staticDataService.getOptionsProductChoice();
            this.hiOptionsList4 = this.staticDataService.getOptionsUsage();
            this.hiOptionsList5 = this.staticDataService.getOptionsWaste();

            if(this.product.analysis_status == undefined)
            {
              this.hi.status = this.hiStatusOptionsList[0];
            }
            else
            {
              this.hi.status = this.hiStatusOptionsList[parseInt(this.product.analysis_status, 10)];
            }

            this.getLanguages(this.product);

            if(this.product.stamp_dep&&this.product.stamp_org&&(this.product.stamp_dep==this.selectedDepartment.id||this.product.stamp_org==this.selectedOrganisation.id)){
                this.showStampedDocument=true;
            } else {
                this.showStampedDocument=false;
            }
            this.loadingData = false;

          } else {

            this.loadingData = false;
            this.router.navigate(['/productlist']);
          }
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      });
    }
  }

  checkAnalysisValue() {

    if (this.product.analysis_value1 == null)
    {
      this.product.analysis_value1 = "";
    }
    if (this.product.analysis_value2 == null)
    {
      this.product.analysis_value2 = "";
    }
    if (this.product.analysis_value3 == null)
    {
      this.product.analysis_value3 = "";
    }
    if (this.product.analysis_value4 == null)
    {
      this.product.analysis_value4 = "";
    }
    if (this.product.analysis_value9 == null)
    {
      this.product.analysis_value9 = "";
    }
    if (this.product.analysis_value10 == null)
    {
      this.product.analysis_value10 = "";
    }

    this.hi.analysis = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    if ((this.product.analysis_value1 != '') && (this.product.analysis_value1 != '""'))
    {
      this.hi.analysis[0] = JSON.parse(this.product.analysis_value1);
    }
    else {
      this.hi.analysis[0] = "";
    }

    if ((this.product.analysis_value2 != '') && (this.product.analysis_value2 != '""'))
    {
      this.hi.analysis[1] = JSON.parse(this.product.analysis_value2);
    }
    else {
      this.hi.analysis[2] = "";
    }

    if ((this.product.analysis_value3 != '') && (this.product.analysis_value3 != '""'))
    {
      this.hi.analysis[2] = JSON.parse(this.product.analysis_value3);
    }
    else {
      this.hi.analysis[2] = "";
    }

    if ((this.product.analysis_value4 != '') && (this.product.analysis_value4 != '""'))
    {
      this.hi.analysis[3] = JSON.parse(this.product.analysis_value4);
    }
    else {
      this.hi.analysis[3] = "";
    }

    if ((this.product.analysis_value9 != '') && (this.product.analysis_value9 != '""'))
    {
      this.hi.analysis[8] = JSON.parse(this.product.analysis_value9);
    }
    else {
      this.hi.analysis[8] = "";
    }

    if ((this.product.analysis_value10 != '') && (this.product.analysis_value10 != '""'))
    {
      this.hi.analysis[9] = JSON.parse(this.product.analysis_value10);
    }
    else {
      this.hi.analysis[9] = "";
    }

    this.getAnalysisRisk();
  }

  getLanguages(product) {
    this.productService.getOtherAvailableLangs(product.id);
    this.productService.getProductLangDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response != null) {
          this.availableLangs = response;
          for (var i = 0; i < this.availableLangs.length; i++) {
            if (this.currentLanguage == this.availableLangs[i].lang) {
              this.goOtherLang(this.availableLangs[i]);
            } else {

            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  editHI() {
    this.editHealthIndex = !this.editHealthIndex;
  }

  getAnalysisRisk() {

    var hiHealth = 0;
    var hiRisk = 0;
    var riskCounter = 0;

    this.hi.hi_value = this.product.risk_prio;

    if (this.hi.analysis[0] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[0].value;
      riskCounter = riskCounter + 1
    }

    if (this.hi.analysis[1] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[1].value;
      riskCounter = riskCounter + 1
    }

    if (this.hi.analysis[2] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[2].value;
      riskCounter = riskCounter + 1
    }

    if (this.hi.analysis[3] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[3].value;
      riskCounter = riskCounter + 1
    }

    if (this.hi.analysis[8] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[8].value;
      riskCounter = riskCounter + 1
    }

    if (this.hi.analysis[9] != "")
    {
      hiRisk = hiRisk + this.hi.analysis[9].value;
      riskCounter = riskCounter + 1
    }

    hiRisk = hiRisk + riskCounter;
    hiRisk = hiRisk / riskCounter;
    this.hi.hi_value = this.hi.hi_value + hiRisk;
    this.hi.hi_value = this.hi.hi_value / 2;

    if (this.hi.hi_value > 5)
    {
      this.hi.hi_value = 5;
    }

    this.hiListChartConfig.series[3].name = this.product.product_name;
    this.hiListChartConfig.series[3]['data'][0][0] = this.hi.hi_value;
    this.hiListChartConfig.series[3]['data'][0][1] =  this.product.risk_prio;
  }

  updateHI(){

    this.getAnalysisRisk();

    this.product.analysis_value1 = JSON.stringify(this.hi.analysis[0]);
    this.product.analysis_value2 = JSON.stringify(this.hi.analysis[1]);
    this.product.analysis_value3 = JSON.stringify(this.hi.analysis[2]);
    this.product.analysis_value4 = JSON.stringify(this.hi.analysis[3]);
    this.product.analysis_value5 = "";
    this.product.analysis_value6 = "";
    this.product.analysis_value7 = "";
    this.product.analysis_value8 = "";
    this.product.analysis_value9 = JSON.stringify(this.hi.analysis[8]);
    this.product.analysis_value10 = JSON.stringify(this.hi.analysis[9]);
    this.product.analysis_date = new Date();

    this.product.analysis_status = this.hi.status.id;

    this.editHealthIndex = false;

    this.productService.updateSingleProduct(this.product);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        this.ngOnInit();
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  toggleCheckboxIndoor(event) {
    this.showAnalysis3_1 = false;
    if (event.target.checked == true)
    {
      this.showAnalysis3_1 = true;
      this.showAnalysis3_2 = false;
    }
  }

  toggleCheckboxOutdoor(event) {
    this.showAnalysis3_2 = false;
    if (event.target.checked == true)
    {
      this.showAnalysis3_2 = true;
      this.showAnalysis3_1 = false;
    }
  }

  setAnalysisStatus(option) {
    this.hi.status = option;
  }

  setAnalysisUsage(option) {
    this.hi.analysis[8] = option;
  }

  setAnalysisChoice(option) {
    this.hi.analysis[0] = option;
  }

  setAnalysisStorage(option) {
    this.hi.analysis[1] = option;
  }

  setAnalysisUsageProduct(option) {
    this.hi.analysis[2] = option;
  }

  setAnalysisWaste(option) {
    this.hi.analysis[3] = option;
  }

  goOtherLang(id) {
    if(this.currentLanguage==id.lang&&id.override&&id.override==id.product_id){
      return;
    } else {
      this.currentLanguage = id.lang;
    }

    if(id == 0 || id == 1 || id == 2 || id == '0' || id == '1' || id=='2') {
      id = {};
      id.product_id = this.product_id_backup;
      id.lang = id;
    }
    else {
      var info = {productId: id.product_id, lang: id.lang, orgId: this.selectedOrganisation.main_organisation };
      this.productService.GetL(info);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: response => {
          if( response &&
              response[0].name &&
              response.length>0 &&
              response[0] != null &&
              response[0] != ""){

              response = response[0];
              this.product.product_name = response.name;
              this.product.handling_emissions = response.handling_emissions;
              this.product.handling_fire_measures = response.handling_fire_measures;
              this.product.handling = response.handling;
              this.product.handling_storage = response.handling_storage;
              this.product.handling_waste = response.handling_waste;
              this.product.ice.consumption = response.ice_consumption;
              this.product.ice.eye_contact = response.ice_eye_contact;
              this.product.ice.inhalation = response.ice_inhalation;
              this.product.ice.skin_contact = response.ice_skin_contact;
              this.product.protection.breath = response.prot_breath;
              this.product.protection.clothes = response.prot_clothes;
              this.product.protection.glasses = response.prot_glasses;
              this.product.protection.gloves = response.prot_gloves;
              this.product.protection.hygiene = response.prot_hygiene;
              this.product.sds_date = response.sds_date;
              this.product.sds_file_id = response.sds_file_id;
              this.product.risk_id = response.risk_id;
              this.product.sareq_number = response.sareq_number;
              this.product.fitness_for_duty = response.fitness_for_duty;
              this.product.information = response.information;
              this.product.health_control = response.health_control;
              this.product.no_special_demands = response.no_special_demands;
              this.product.training = response.training;
              this.product.articlenumber = response.supplier_article_number;
              this.product.id = response.id;
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        });
      }
  }

  get_unique_items(item){
    return _.uniq(item);
  }

  sortCphrases(product)
  {
    let uniqueItems = product.c_phrases.reduce((accumulator, currentItem) => {
      if ( !accumulator.find( item => item.c_phrase === currentItem.c_phrase) )
      {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, [] );

    let uniqueSortedItems = uniqueItems.sort(
      (a, b) => a.c_phrase.localeCompare( b.c_phrase )
    );
    this.product.c_phrases = uniqueSortedItems;
  }

  getCMRA_Category(product) {
    product.CMRA_Category=[];
    let h_phrases = product.phrases['H'];
    if((!h_phrases||h_phrases==null)&&product.allergic!=1){
      return;
    }

    if(_.toUpper(h_phrases).indexOf("H350")>-1||_.toUpper(h_phrases).indexOf("H351")>-1){
      product.CMRA_Category.push({'name':'C','title':'CARCINOGENIC'});
    }

    if(_.toUpper(h_phrases).indexOf("340")>-1||_.toUpper(h_phrases).indexOf("H341")>-1){
      product.CMRA_Category.push({'name':'M','title':'MUTAGENIC'});
    }

    if(_.toUpper(h_phrases).indexOf("360")>-1||_.toUpper(h_phrases).indexOf("H361")>-1||_.toUpper(h_phrases).indexOf("H362")>-1){
      product.CMRA_Category.push({'name':'R','title':'REPRODUCTIVE_TOXIC'});
    }

    if(_.toUpper(h_phrases).indexOf("H317")>-1||_.toUpper(h_phrases).indexOf("H334")>-1
        ||_.toUpper(h_phrases).indexOf("EUH208")>-1||_.toUpper(h_phrases).indexOf("EUH205")>-1
        ||_.toUpper(h_phrases).indexOf("EUH204")>-1||_.toUpper(h_phrases).indexOf("EUH202")>-1
        ||product.allergic==1)
    {
      product.CMRA_Category.push({'name':'A','title':'ALLERGIC'});
    }
  }

  getCmraTitleTranslation(CMRAC){
    if ( !CMRAC || typeof(CMRAC) === 'undefined'){
      return "";
    }
    else
    {
      return this.translate.instant(CMRAC);
    }
  }

  getLRBInfo( product ){
    this.assessmentChemicalService.getLRBInfo( product.LRB_id );
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) { }
        else {
          Object.assign(this.product, response);
        }
      },
      error: error => {
      }
    })
  }
}

