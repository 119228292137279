import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { PdfService } from 'src/app/services/pdf.service';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SupportService } from 'src/app/services/support.service';
declare var $: any;

@Component({
  selector: 'app-assessment-pdf',
  templateUrl: './assessment-pdf.component.html',
  styleUrls: ['./assessment-pdf.component.css']
})
export class AssessmentPdfComponent {
  @ViewChild('pdfContent') pdfContent: ElementRef;
  generatePdf() {
    this.pdfService.generatePDF(this.pdfContent.nativeElement);
  }

  @Input() assessment: any;
  @Input() pictures: any[];
  @Input() uniqueproducts: any[];

  pictureWidth = 800;
  pictureHeight = 800;
  organisation: any;
  imageLogo: any;
  tempworksteps: any[] = [];

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, private assessmentService: AssessmentService,
    private route: ActivatedRoute,private supportService: SupportService, private pdfService: PdfService) { }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.getLogoAsPdf();
    this.splitWorksteps();
    this.updateData();
  }

  getLogoAsPdf() {
    this.productService.getLogoAsTrustedResourceUrl(this.organisation.logo_file_id);
    this.productService
      .getLogoListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response != null) {
            this.imageLogo = response;
          }
        },
        error: (error) => {
        },
      });
  }

  splitWorksteps() {
    let item = 0;
    var isFirst = true;
    if (this.assessment && Array.isArray(this.assessment.worksteps) && this.assessment.worksteps.length > 0) {
      this.assessment.worksteps.forEach((workstep) => {
        item++;
        isFirst = true;
        if ( workstep.products && workstep.products.length > 0 ) {
          for (let i = 0; i < workstep.products.length;) {
            let numberOfProducts;
            if (isFirst && i < 1) {
              numberOfProducts = 3;
              isFirst = false;
          } else {
              numberOfProducts = 5;
          }
            let tempWorkstep
            if ( i > 0) {
              tempWorkstep = { ...workstep, products: workstep.products.slice(i, i + numberOfProducts), item: item, isNotFirst: true };
            } else {
              tempWorkstep = { ...workstep, products: workstep.products.slice(i, i + numberOfProducts), item: item, isNotFirst: false };
            }
            this.tempworksteps.push(tempWorkstep);
            i += numberOfProducts;
          }
        } else {
            let tempWorkstep
            tempWorkstep = { ...workstep, item: item, isNotFirst: false };
            this.tempworksteps.push(tempWorkstep);
        }
      });
    }
  }

  updateData() {
    // Iterate over each tempworkstep
    for (let tempWorkstep of this.tempworksteps) {
        // Iterate over each product in tempWorkstep.products
        for (let tempProduct of tempWorkstep.products) {
            // Find the corresponding product in uniqueproducts with the same product_id
            let matchingProduct = this.uniqueproducts.find(product => product.product_id === tempProduct.product_id);

            // If a match is found, update the pictograms of the tempProduct
            if (matchingProduct) {
                tempProduct.product_name = matchingProduct.product_name;
                tempProduct.supplier_name = matchingProduct.supplier_name;
                tempProduct.risk_prio = matchingProduct.risk_prio;
                tempProduct.allergic = matchingProduct.allergic;
                tempProduct.hygiene_limits = matchingProduct.hygiene_limits;
                tempProduct.pictograms = matchingProduct.pictograms;
            }
        }
    }
}
}
