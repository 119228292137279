import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { CoordinatorService } from 'src/app/services/coordinator.service';
import { AuthService } from 'src/app/auth/auth.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.css']
})
export class CoordinatorComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  showSubdepartments: boolean = false;
  showNotStarted: boolean = true;
  showOngoing: boolean = true;
  showDone: boolean = true;
  all: boolean = false;
  coordinators: any = [];
  coordinatorSubjectList: any = [];
  availableCoordinatorSubjectStatus: any;
  departmentArray=[];
  order=['asc','asc','asc','asc','asc','asc'];

  coordinatorSearch = {
    all:""
  };

  ngAfterViewInit(): void {
    $('#coordinatortable').tablesort();
  }

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private coordinatorService: CoordinatorService,
    private userService: UserService,
    private staticDataService: StaticDataService) { }

    ngOnInit(): void {
      this.loadingData = false;
      this.itemsPerPage=Number(localStorage.getItem('coordinatorItems'));
      if(!this.itemsPerPage||!_.isNumber(this.itemsPerPage)){
        this.itemsPerPage=5;
      }
      localStorage.removeItem('coordinatorItems');
      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

      if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
        this.logout();
      }

      this.showSubdepartments = JSON.parse(localStorage.getItem('showSubdepartments', ));
      if (this.showSubdepartments == null) {
        this.showSubdepartments = true;
        localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
      }

      this.sortDirection = localStorage.getItem('sortDirection', );
      if (this.sortDirection == null)
      {
        this.sortDirection = 'desc';
      }

      this.sortValue = localStorage.getItem('sortValue', );
      if (this.sortValue == null)
      {
        this.sortValue = 'due';
      }

      this.sortItem(this.sortValue,this.sortDirection);
      var data = localStorage.getItem('currentUser', );
      if (data == null || data == undefined)
      {
        this.logout();
      }
      else
      {
        this.currentUser = JSON.parse(data);
      }

      this.loadingData = true;

      this.getAllDepartments();
      this.getCoordinators();

      setTimeout(() => {
        this.availableCoordinatorSubjectStatus = this.staticDataService.getAvailableCoordinatorSubjectStatus();
      }, 100);

      // Change department
      this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
        this.utilService.departmentChangeClicked(this.router.url);
        this.ngOnInit();
      })
    }

    getItemsPerPage() {
      let info = JSON.stringify(
        {
          'userId':this.currentUser.id,
          'orgId':this.organisation.id
        });
      this.userService.getProductListSettings(info);
      this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
        next: user => {
          if(!user||user==null){
            if (localStorage.getItem('items_per_page') !== null){
              let itemsPerPage = Number(localStorage.getItem('items_per_page'));
              if (!isNaN(itemsPerPage)) {
                this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
                return;
              } else {
                return;
              }
            } else {
              return;
            }
          } else if(user.items_per_page &&
            localStorage.getItem('items_per_page') === null){
            this.itemsPerPage = user.items_per_page;
            localStorage.setItem('items_per_page', user.items_per_page);
          } else if(localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
            } else {

            }
          } else {

          }
        },
        error: error => {
          return;
        }
      })
    }

    getCoordinators() {
      this.coordinatorSubjectList = [];

      if (!this.showSubdepartments) {
        this.coordinatorService.getAll();
      }
      else {
        this.coordinatorService.getAllSubs();
      }
      this.coordinatorService.getAllCoordinatorDataListener().pipe(take(1)).subscribe({
        next: coordinators => {
          if ((coordinators == null) || coordinators.length == 0) {
            this.loadingData = false;
          }
          else {

            this.coordinators = coordinators;

            for (var i = 0; i < this.coordinators.length; i += 1) {

              if (this.coordinators[i].status === 0) {
                if (this.showNotStarted) {
                  this.coordinatorSubjectList.push(this.coordinators[i]);
                }
              } else if (this.coordinators[i].status > 0 && this.coordinators[i].status < 4) {
                if (this.showOngoing) {
                  this.coordinatorSubjectList.push(this.coordinators[i]);
                }
              } else if (this.coordinators[i].status === 4) {
                if (this.showDone) {
                  this.coordinatorSubjectList.push(this.coordinators[i]);
                }
              }
            }

            /*if (this.sortDirection === 'desc') {
              this.coordinatorSubjectList = _.orderBy(this.coordinatorSubjectList, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.coordinatorSubjectList = _.orderBy(this.coordinatorSubjectList, ['type', this.sortValue]);
            }*/

            this.sortDirection = localStorage.getItem('sortDirection', );
            if (this.sortDirection == null)
            {
              this.sortDirection = 'desc';
            }

            this.sortValue = localStorage.getItem('sortValue', );
            if (this.sortValue == null)
            {
              this.sortValue = 'due';
            }

            this.sortItem(this.sortValue,this.sortDirection);
            this.loadingData = false;
          }
          this.getItemsPerPage();
        },
        error: error => {
          this.loadingData = false;
          console.log("Error: ", error);
        }
      })
    }

    editcoordinator(subject){
      this.router.navigate(['/coordinatoredit', subject.id]);
    }

    toggleStatus(value) {
      if (value == 0) {
        this.showNotStarted = !this.showNotStarted;
      } else if (value == 1) {
        this.showOngoing = !this.showOngoing;
      } else if (value == 2) {
        this.showDone = !this.showDone;
      } else if (value == -1) {
        this.all=!this.all;
      }
      this.getCoordinators();
    }

    onToggleShowSubDepartments() {
      this.showSubdepartments = !this.showSubdepartments;
      localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
      this.getCoordinators();
    }

    getAllDepartments() {
      if (this.organisation) {
        this.organisationService.getAllDepartmentNames(this.organisation);
        //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: departments => {
            if (departments == null) {}
            else {
              this.listAlldepartments = departments;
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    }

    departFullName(department){
      return this.utilService.departFullName(department, this.listAlldepartments);
    }

    sortHeader(value) {
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc';
      }
      else
      {
        this.sortDirection = 'asc';
      }

      localStorage.setItem('sortDirection', this.sortDirection);
      localStorage.setItem('sortValue', value);
      window.location.reload();
    }

    coordinatorSelected(coordinator) {
      localStorage.removeItem('coordinatorDepartment');
      localStorage.setItem('previousSelectedDepartment', JSON.stringify({
        department: this.selectedDepartment,
        page: 'coordinator',
        timestamp: new Date().getTime()
      }));
        if (coordinator.departmentid != this.selectedOrganisation.id) {
          this.organisationService.changeDepartmentById(coordinator.departmentid);
                        this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                          this.utilService.departmentChangeClicked(this.router.url);
                          localStorage.setItem('departmentchanged','set');
                          localStorage.removeItem('coordinatorItems');
                          localStorage.setItem('coordinatorItems', this.itemsPerPage.toString());
                          this.router.navigate(['/coordinatoredit', coordinator.id]).then(() => { window.location.reload(); });
                        })
        } else {
          localStorage.removeItem('coordinatorItems');
          localStorage.setItem('coordinatorItems', this.itemsPerPage.toString());
          this.router.navigate(['/coordinatoredit', coordinator.id]);
        }
    }

    addCoordinator() {
      this.router.navigate(['/coordinatoradd']);
    }

    logout() {
      this.authService.logout();
    }

    sortItem(type,id){
      if(!_.isNumber(id)){
        let tempid=id;
        if(type=='name'){id=0}
        else if(type=='department'){id=1}
        else if(type=='prio'){id=2}
        else if(type=='status.name'){id=3}
        else if(type=='due'){id=4}
        else if(type=='responsible'){id=5}
        else{id=4}
        this.order[id]=tempid||'desc';
      }
      else{
        if(this.order[id]=='asc'){this.order[id]='desc'}else{this.order[id]='asc'}
        localStorage.setItem('sortDirection', this.order[id]);
        localStorage.setItem('sortValue', type);
      }


      if(id==5){
        if(this.order[id]=='asc'){
          this.coordinatorSubjectList=_.orderBy(this.coordinatorSubjectList, [( o ) => { if(o[type]!=null&&o[type]!=''){return o[type]}else{return 'zzz'} }], ['asc']);
        }else{
          this.coordinatorSubjectList=_.orderBy(this.coordinatorSubjectList, [( o ) => { if(o[type]!=null&&o[type]!=''){return o[type]}else{return '000'} }], ['desc']);
        }
      }else if(id==4){
        if(this.order[id]=='asc'){
          this.coordinatorSubjectList=_.orderBy(this.coordinatorSubjectList, [( o ) => { if(o[type]!=null&&o[type]!=''){return o[type]}else{return '9999-12-31'} }], ['asc']);
        }else{
          this.coordinatorSubjectList=_.orderBy(this.coordinatorSubjectList, [( o ) => { if(o[type]!=null&&o[type]!=''){return o[type]}else{return '1900-01-01'} }], ['desc']);
        }
      }else{
        this.coordinatorSubjectList=_.orderBy(this.coordinatorSubjectList,[type], [this.order[id]]);
      }
    }
  }
