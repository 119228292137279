import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-productapplication-add",
  templateUrl: "./productapplication-add.component.html",
  styleUrls: ["./productapplication-add.component.css"],
})

export class ProductapplicationAddComponent implements OnInit {
  itemsPerPage: number = null;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  products: any = [];
  productApplications: any = [];
  productsOverride: any = [];
  showSubdepartments: boolean = true;
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  order = ["asc", "asc","asc"];
  productapplicationSearch = {
    all:"",
    name: "",
    supplier_name: "",
    supplier_article_number: ""
  };
  sareqProducts: any=[];
  numberOfItems0  = 100;
  numberOfItems1  = 100;
  searchPageNumber0=1;
  totalAmount;
  orderBy = "";
  productSearch: boolean = false;
  productInSareq: boolean = true;
  productNotSareq: boolean = false;
  allDepartments: boolean = false;
  product: any = {
    name:null,
    supplier_name:null,
    id:null
  };
  listOfDepartments: any = [];
  listOfDepartmentsDuplicate: any = [];
  rowindex: number = null;
  departmentSearch: any = null;
  units: any;

  ngOnDestroy(): void {

  }

  constructor (
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private productApplicationService: ProductApplicationService,
    private sareqService: SareqService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );
    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.units = this.staticDataService.getUnitDefinitions();

    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartments")
    );

    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.itemsPerPage = 10;

    this.getAllDepartments();
    this.listOfDepartments = this.utilService.getAllSubdepartments(this.selectedOrganisation,false);
    this.listOfDepartmentsDuplicate=_.cloneDeep(this.listOfDepartments);

    this.organisationService.departmentChanged
    .pipe( take( 1 ) )
    .subscribe( ( msg: any ) => {
      this.loadingData = true;
      this.utilService.departmentChangeClicked(this.router.url);
      this.router.navigate(['/productapplicationlist']).then(() => {
        window.location.reload();
        })
      } );
      this.loadingData = false;
    }


  logout() {
    this.authService.logout();
  }

  getAllDepartments() {
    if (
      this.listAlldepartments == null ||
      (this.organisation &&
        this.organisation.id !=
          JSON.parse(localStorage.getItem("rootOrganisation")).id)
    ) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService
        .getOrganisationAllDepartmentNamesDataListener()
        .pipe(take(1))
        .subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  goToProductApplication() {
    this.router.navigate( [ "/productapplicationlist" ] );
  }

  searchProducts( tabb, orderby ){
    if ( orderby == '' && tabb == '' )
    {
      this.orderBy = '';
    }
    else if(orderby=='' && tabb=='Prev'||tabb=='Next') {

    } else {
      this.orderBy = orderby;
    }

    this.loadingData = true;
    if(tabb==''||tabb=='Prev'||tabb=='Next'){
      if(tabb=='Prev'){this.searchPageNumber0--;}
      if(tabb=='Next'){this.searchPageNumber0++;}
      if(tabb==''){this.searchPageNumber0=1;}
      if(!this.numberOfItems0){
        this.numberOfItems0=100;
      }
      var info = {
        'name': encodeURIComponent(this.productapplicationSearch.name==null?'':this.productapplicationSearch.name),
        'manufacturer': encodeURIComponent(this.productapplicationSearch.supplier_name==null?'':this.productapplicationSearch.supplier_name),
        'articlenumber': this.productapplicationSearch.supplier_article_number==null?'':this.productapplicationSearch.supplier_article_number,
        'orderBy': this.orderBy,
        'limit': this.numberOfItems0,
        'page': this.searchPageNumber0,
        'status': 'allproducts'
      };
    }
    this.sareqProducts=[];

    this.sareqService.getAllAndFilter(info);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: sareqProducts => {
        if(!sareqProducts||sareqProducts==null){
          this.loadingData = false;
      } else {
        if(!sareqProducts[sareqProducts.length-1].id){
          this.totalAmount = sareqProducts[sareqProducts.length-1];
          sareqProducts.splice(sareqProducts.length-1,1);
        }
        for(var i = 0; i < sareqProducts.length; i++) {
          if(sareqProducts[i].hidden != 1 && sareqProducts[i].override == null) {
            if(sareqProducts[i].secret_chemical != 1||
                this.selectedOrganisation.id==sareqProducts[i].secret_chemical_organisation) {
                this.sareqProducts.push(sareqProducts[i]);
            }
          }
          // this.sareqProducts.push(sareqProducts[i]);
        }
        this.products = this.sareqProducts;
        this.itemsPerPage = this.numberOfItems0;
        this.loadingData = false;
          setTimeout( () => {
            let element=document.getElementById('productapplicationSearchname');
              element.focus();
          },100)
        }
      },
      error: error => {
        Swal.fire("Error excel", "Error", "error");
        this.loadingData = false;
      }
    })
  }

  sortItem(type, id) {
    if (this.order[id] == "asc") {
      this.order[id] = "desc";
      this.productsOverride = _.orderBy(this.productsOverride, [type], ["asc"]);
    } else if (this.order[id] == "desc") {
      this.order[id] = "asc";
      this.productsOverride = _.orderBy(
        this.productsOverride,
        [type],
        ["desc"]
      );
    }
  }

  changeArticleSearch(){
    for (var i = 0; i < this.productapplicationSearch.supplier_article_number.length; i++)
    {
      this.productapplicationSearch.supplier_article_number = this.productapplicationSearch.supplier_article_number.replace(' ', '');
    }
    this.filterSearchItems();
  }

  filterSearchItems() {
    this.products=this.sareqProducts;
    if(this.productapplicationSearch.all=='') {
      this.productapplicationSearch.all=null;
    }
    if(this.productapplicationSearch.name=='') {
      this.productapplicationSearch.name=null;
    }
    if(this.productapplicationSearch.supplier_name=='') {
      this.productapplicationSearch.supplier_name=null;
    }
    if(this.productapplicationSearch.supplier_article_number=='') {
      this.productapplicationSearch.supplier_article_number=null;
    }
    if(this.productapplicationSearch.all!=null) {
      this.products=this.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productapplicationSearch.all.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.name!=null) {
      this.products=this.products.filter(o => o.name?.toLowerCase().indexOf(this.productapplicationSearch.name.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.supplier_name!=null) {
      this.products=this.products.filter(o => o.supplier_name?.toLowerCase().indexOf(this.productapplicationSearch.supplier_name.toLowerCase())>-1);
    }
    if(this.productapplicationSearch.supplier_article_number!=null) {
      this.products=this.products.filter(o => o.supplier_article_number?.toString().toLowerCase().indexOf(this.productapplicationSearch.supplier_article_number.toLowerCase())>-1);
    }

  }

  getSareqProducts() {
    this.sareqProducts = [];
    this.sareqService.getAll();
    this.sareqService.getSareqDataListener().pipe( take(1) ).subscribe( {
      next: sareqProducts => {
        for(var i = 0; i < sareqProducts.length; i++) {
          if(sareqProducts[i].hidden != 1 && sareqProducts[i].override == null) {
            if(sareqProducts[i].secret_chemical != 1||
                this.selectedOrganisation.id==sareqProducts[i].secret_chemical_organisation) {
                this.sareqProducts.push(sareqProducts[i]);
            }
          }
        }
        this.itemsPerPage = 10;
        setTimeout( () => {

          this.products = this.sareqProducts;
          this.loadingData = false;
        },500)
      },
      error: error => {
        Swal.fire("Error excel", "Error", "error");
      }
    })
  }

  onKeyTabbEvent(id): void {
    if(id=='button-searchproduct'){
      setTimeout(()=>{let element=document.getElementById('productapplicationSearchname');element.focus();},100);
    }
  }

  setItems(unit) {
    this.numberOfItems0 = unit;
    if(unit == "alla"){
      this.numberOfItems1 = 0;
    } else {
      this.numberOfItems1 = unit;
    }
  }

  getAmount(item){
    if(item=='1') {
      var amount = this.numberOfItems0*this.searchPageNumber0;
      if(amount<this.totalAmount) {
        return amount;
      } else {
        return this.totalAmount;
      }
    }
  }

  trackById(index:number, el:any) :number {
    return el;
  }

  addProduct(product) {
    for ( var n=0; n<this.products.length; n++ ) {
      if ( this.products[n].isSelected == true
            && this.products[n].id != product.id ) {
        delete this.products[n].isSelected;
      }
    }

    if ( product.isSelected == true ) {
      delete product.isSelected;
      this.productInSareq = true;
    } else {
      product.isSelected=true;
      this.productSearch = false;
      this.productInSareq = true;
      this.product.sareq_id = product.id;
      this.product.name = product.name;
      this.product.supplier_id = product.supplier_id;
      this.product.supplier_name = product.supplier_name;
    }
  }

  openSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    });
  }

  openGrbInNewWindow(product) {
    const filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    });
  }

  openExtSdsInNewWindow(product) {
    const filename = product.name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        } else {
          const newWindow = window.open(pdfContent, "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // If the new window couldn't be opened, fallback to opening in the same tab
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    });
  }

  changeProductNotSareq( ) {
    if ( this.productInSareq==true ) {
      this.productInSareq=false;
      this.product.notinsareq = true;
      this.product.sareq_id = null;
      this.product.supplier_id = null;
    } else {
      this.productInSareq=true;
      delete this.product.notinsareq;
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  selectThisDepartment(department){
    if ( department=="all" ) {
      this.listOfDepartments = this.listOfDepartmentsDuplicate;
      if ( this.allDepartments == true ) {
        this.allDepartments = false;
      } else {
        this.allDepartments = true;
      }
      for ( var n=0; n<this.listOfDepartments.length; n++ ) {
        this.listOfDepartments[n].selected = this.allDepartments;
      }
      return;
    }

    if ( department.selected == true ) {
      department.selected = false;
    } else {
      department.selected = true;
    }
  }

  submit(){
    this.loadingData = true;
    if ( this.product.name === ""||this.product.name === null||this.product.name === undefined ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("PRODUCT_NAME_IS_REQUIRED"), "", "error");
      return;
    }
    if ( this.product.supplier_name === ""||this.product.supplier_name === null||this.product.supplier_name === undefined ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("MANUFACTURER_IS_REQUIRED"), "", "error");
      return;
    }
    if ( this.productInSareq != false ) {
      if ( !this.product.sareq_id ) {
        this.loadingData = false;
        Swal.fire(this.translate.instant("CHOOSE_PRODUCT"), "", "error");
        return;
      }
    }
    this.product.department_id = this.selectedDepartment.id;
    var departments = [];
    for ( var n=0; n<this.listOfDepartments.length; n++ ) {
      if ( this.listOfDepartments[n].selected == true ) {
        departments.push(this.listOfDepartments[n].id);
      }
    }
    if ( departments.length == 0 ) {
      this.loadingData = false;
      Swal.fire(this.translate.instant("NO_DEPARTMENTS"), "", "error");
      return;
    }

    if ( this.product.product_application_mail_support && this.product.product_application_mail_support == true ) {
      this.product.product_application_mail_support = 1;
    } else {
      this.product.product_application_mail_support = 0;
    }
    this.product.departments = departments.join(",");
    this.product.user_id = this.currentUser.id;
    this.productApplicationService.createProductApplication( this.product );
    this.productApplicationService.getProductApplicationDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {
          this.loadingData = false;
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.loadingData = false;
          this.router.navigate( [ "/productapplicationlist" ] );
        }
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  gotoAllProductApplications(path) {
    this.router.navigate( [ "/productapplicationlist" ] );
  }

  departmentSearchItems() {
    this.listOfDepartments = this.listOfDepartmentsDuplicate;

    if ( this.departmentSearch == '' || this.departmentSearch == null ) {
      this.departmentSearch=null;
    }

    if( this.departmentSearch != null ){
      this.listOfDepartments = this.listOfDepartments.filter(o => o.name?.toLowerCase().indexOf( this.departmentSearch.toLowerCase() ) >-1 );
    }
  }

  setUnit(product, value) {
    for ( var i = 0; i < this.units.length; i++ ) {
      if (value == this.units[i].id) {
        product.unit = this.units[i].id;
        break;
      }
    }
  }

  changeProductApplicationMailSupport(event) {
    if ( event == true ) {
      this.product.product_application_mail_support = true;
    } else if ( event == false ) {
      this.product.product_application_mail_support = false;
    }
  }
}
