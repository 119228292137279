<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3 text-center">
      <div class="flex flex-row">
        <div class="text-gray-900 font-bold mr-1">{{"REPORT_ALLERGENIC" | translate}}{{" --- Kategori:"}}</div>
        <span *ngIf="category === 'allergic'">{{"ALLERGIC" | translate}}</span>
        <span *ngIf="category === 'information'">{{"INFORMATION_DEMAND" | translate}}</span>
        <span *ngIf="category === 'training'">{{"TRAINING" | translate}}</span>
        <span *ngIf="category === 'health_control'">{{"HEALTH_CONTROL" | translate}}</span>
        <span *ngIf="category === 'fitness_for_duty'">{{"FITNESS_FOR_DUTY" | translate}}</span>
        <span *ngIf="category === 'no_special_demands'">{{"NO_SPECIAL_DEMANDS" | translate}}</span>
        <span *ngIf="category === 'all'">{{"ALL" | translate}}</span>
      </div>
        <div class="text-gray-900 font-bold">{{"REPORT_ALLERGENIC" | translate}}</div>
        <!-- Modal Body -->
        <div class="flex flex-col center-items mt-10 p-2">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                <div class="loader"></div>
            </div>

            <div *ngIf="loadingData==false" class="overflow-auto border mb-20 border-gray-200">
              <div class="flex flex-row justify-start items-center w-full relative inline-block text-left z-20 mb-4" appDropdown>
                <label for="category">{{"CHOOSE_CATEGORY" | translate}}</label>:&nbsp;
                <button title="{{categoryName}}" type="button" class="button-white border border-gray-300">
                  <span class="text-sm">{{categoryName}}</span>
                  <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <a class="dropdown-menu-item" (click)="setCategory('allergic')" tabindex="1">{{"ALLERGIC" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('information')" tabindex="2">{{"INFORMATION_DEMAND" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('training')" tabindex="3">{{"TRAINING" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('health_control')" tabindex="4">{{"HEALTH_CONTROL" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('fitness_for_duty')" tabindex="5">{{"FITNESS_FOR_DUTY" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('no_special_demands')" tabindex="6">{{"NO_SPECIAL_DEMANDS" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('all')" tabindex="7">{{"ALL" | translate}}</a>
                    </div>
                </div>
              </div>
              <div *ngIf="category == 'all'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.all">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'allergic'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.allergic">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'information'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.information">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'training'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.training">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'health_control'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.health_control">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'fitness_for_duty'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.fitness_for_duty">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div *ngIf="category == 'no_special_demands'">
                <table datatable [dtOptions]="dtOptions" id="table">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                            <th class="table-row-header">{{"INFORMATION_DEMAND" | translate}}<br>(AFS 2023:10, 7 kap. 23 §)</th>
                            <th class="table-row-header">{{"TRAINING" | translate}}<br>(AFS 2023:10, 8 kap. 7-10 §)</th>
                            <th class="table-row-header">{{"HEALTH_CONTROL" | translate}}<br>(AFS 2023:10, 8 kap. 11 §)</th>
                            <th class="table-row-header">{{"FITNESS_FOR_DUTY" | translate}}<br>(AFS 2023:10, 8 kap. 12 §)</th>
                            <th class="table-row-header">{{"NO_SPECIAL_DEMANDS" | translate}}<br></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of reportInformation.products.no_special_demands">
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.allergic==1">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.information">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.training">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.health_control">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.fitness_for_duty">X</span>
                        </td>
                        <td class="table-row-column">
                          <span class="text-red-500" *ngIf="product.no_special_demands">X</span>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>

