import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { StaticDataService } from "src/app/services/static-data.service";
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  getInventoryDataUpdated = new Subject<any>();
  getInventoriesDataUpdated = new Subject<any>();
  getInventoryProductsDataUpdated = new Subject<any>();
  getNoSareqInventoryProductsDataUpdated = new Subject<any>();
  getInventoriesOngoingDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService,
              private staticDataService: StaticDataService, private sanitizer: DomSanitizer) { }

  createInventory(orgId, info) {
  
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/inventory/" + orgId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  getInventories(orgId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/' + orgId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoriesDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoriesDataUpdated.next(null);
      }
    });
  }

  getOngoingInventories(orgId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/' + orgId + "/ongoing", {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoriesOngoingDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoriesOngoingDataUpdated.next(null);
      }
    });

  }

  getAllInventoriesDepartment(orgId, departmentId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/' + orgId + '/' + departmentId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoriesDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoriesDataUpdated.next(null);
      }
    });
  }

  updateInventory(org_id, inventory_id, info) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.put<any>(BackendUrl + '/inventory/' + org_id + "/" + inventory_id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  deleteInventory(org_id, inventory_id) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    
    this.http.delete(BackendUrl + "/inventory/" + org_id + "/" + inventory_id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(true);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(true);
      }
    });
  }

  getInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/products/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  getNoSareqInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/productsnosareq/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getNoSareqInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getNoSareqInventoryProductsDataUpdated.next(null);
      }
    });
  }

  approveInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/productsapprove/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  setDoneInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/productsdone/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  setNotDoneInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/productsnotdone/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  updateInventoryProduct(orgId, inventoryId, info) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.put<any>(BackendUrl + '/inventory/product/' + orgId + '/' + inventoryId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  updateInventoryNoSareqProduct(orgId, inventoryId, info) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.put<any>(BackendUrl + '/inventory/productnosareq/' + orgId + '/' + inventoryId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  updateOrganisationProducts(orgId, inventoryId, info) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.put<any>(BackendUrl + '/inventory/orgproductsupdate/' + orgId + '/' + inventoryId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  addInventoryProduct(orgId, inventoryId, info)
  {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/inventory/product/" + orgId + "/" + inventoryId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  addInventoryProductNoSareq(orgId, inventoryId, info)
  {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/inventory/productnosareq/" + orgId + "/" + inventoryId, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getNoSareqInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getNoSareqInventoryProductsDataUpdated.next(null);
      }
    });
  }

  deleteInventoryProduct(orgId, inventoryId, id) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.delete<any>(BackendUrl + '/inventory/product/' + orgId + '/' + inventoryId + '/' + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  removeInventoryProductsDepartment(orgId, inventoryId, info) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.delete<any>(BackendUrl + '/inventory/product/' + orgId + '/' + inventoryId + '/departments', {headers: headers, body: info}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryDataUpdated.next(null);
      }
    });
  }

  removeInventoryProductNoSareq(orgId, inventoryId, id) {
    
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    
    this.http.delete(BackendUrl + '/inventory/productsnosareq/' + orgId + '/' + inventoryId + '/' + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getNoSareqInventoryProductsDataUpdated.next(true);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getNoSareqInventoryProductsDataUpdated.next(true);
      }
    });
  }

  syncInventoryProducts(orgId, inventoryId) {

    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/inventory/productsync/' + orgId + '/' + inventoryId, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getInventoryProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  getFileAsTrustedResourceUrl(org_id, inventoryId, product_id) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
  
    this.http.get(BackendUrl + '/inventory/productsnosareq/picture/' + org_id + '/' + inventoryId + '/' + product_id, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/image'});
        if (file.size !== 0)
        {
          const unsafeImg = URL.createObjectURL(file);
          var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
          this.getInventoryProductsDataUpdated.next(image);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  getFileAsTrustedResourceUrlSecond(org_id, inventoryId, product_id) {
  
    let token = localStorage.getItem('token');
    
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
  
    this.http.get(BackendUrl + '/inventory/productsnosareq/picturesecond/' + org_id + '/' + inventoryId + '/' + product_id, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/image'});
        if (file.size !== 0)
        {
          const unsafeImg = URL.createObjectURL(file);
          var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
          this.getInventoryProductsDataUpdated.next(image);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getInventoryProductsDataUpdated.next(null);
      }
    });
  }

  public getInventoryDataListener() {
    return this.getInventoryDataUpdated.asObservable();
  }

  public getInventoryOngoingDataListener() {
    return this.getInventoriesOngoingDataUpdated.asObservable();
  }

  public getInventoryProductsDataListener() {
    return this.getInventoryProductsDataUpdated.asObservable();
  }

  public getInventoriesDataListener() {
    return this.getInventoriesDataUpdated.asObservable();
  }

  public getNoSareqInventoryProductsDataListener() {
    return this.getNoSareqInventoryProductsDataUpdated.asObservable();
  }
}
