<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200 mb-4">
    <div class="flex flex-row justify-between mt-2 mb-2">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
            <path fill="#000000"
                  d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
          </svg>
        </button>
        <div class="flex flex-row">
            <a (click)="downloadList()" title="{{'DOWNLOAD_LIST' | translate}}" class="px-1 py-1 text-lg text-cdocblue cursor-pointer">
                <svg  class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 17H17.01M17.4 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H6.6M12 15V4M12 15L9 12M12 15L15 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <a (click)="uploadList()" title="{{'UPLOAD_LIST' | translate}}" class="px-1 py-1 text-lg text-cdocblue cursor-pointer">
                <svg class="w-7 h-7 mr-4" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
        </div>
    </div>

    <div *ngIf="loadingData==false" class="flex flex-col md:flex-row bg-cdocbanner p-2">
        <div class="w-1/4 mt-2 mb-2">
          <div class="flex flex-col px-4">
            <div class="card text-left bg-success text-white">
                <div class="card-body">
                  <h5 class="card-title">{{'NUMBER_OF_INVENTED_PRODUCTS' | translate}}</h5>
                  <p class="card-text display-4">{{ nbrOfInventedProducts }}({{productList.length+productListNoSareq.length}})</p>
                </div>
            </div>
          </div>
        </div>
        <div class="w-1/4 mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_ADDED_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{ nbrOfNewProducts }}({{productList.length+productListNoSareq.length}})</p>
                  </div>
              </div>
            </div>
        </div>
        <div class="w-1/4 text-left mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_REMOVED_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{ nbrOfRemovedProducts }}({{productList.length+productListNoSareq.length}})</p>
                  </div>
              </div>
            </div>
        </div>
        <div class="w-1/4 text-left mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_NO_SAREQ_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{productListNoSareq.length}}</p>
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-row justify-between items-center mt-4 mb-2 mx-4">
    <div class="flex flex-row">
        <!--<button class="button-cdocblue mr-2" title="{{'ADD_CHEMICAL' | translate}}" (click)="addChemical=true">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{ 'ADD_CHEMICAL' | translate }}</span>
        </button>-->
        <button class="button-cdocblue" title="{{'ADD_CHEMICAL' | translate}}" (click)="addChemicalNoSareq=true">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{ 'ADD_CHEMICAL' | translate }}</span>
        </button>
    </div>
    <label *ngIf="productList.length>0 && productCountDone==0" class="bg-red-500 rounded w-48 px-2 py-1 font-bold text-white text-sm text-center mt-2">{{ 'INVENTORY_MARKED_NOT_DONE' | translate }}</label>
    <label *ngIf="productList.length>0 && productCountDone>0" class="bg-green-500 rounded w-48 px-2 py-1 font-bold text-white text-sm text-center mt-2">{{ 'INVENTORY_MARKED_DONE' | translate }}</label>
</div>

<!-- Image Modal search product -->
<div *ngIf="addChemical" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-2 rounded-lg max-w-5xl w-full max-h-[90vh] overflow-y-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="addChemical = false" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <app-sareq-search (dataOutput)="addProduct($event)"></app-sareq-search>
    </div>
</div>

<!-- Form Container -->
<div *ngIf="addChemicalNoSareq" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-2 rounded-lg max-w-5xl w-full max-h-[90vh] overflow-y-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer mb-2" title="{{'CANCEL' | translate}}" (click)="addChemicalNoSareq = false" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="border border-gray-400 p-4">
            <div class="flex flex-col md:flex-row mb-4">
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"PRODUCT_NAME" | translate}}</label>
                    <input title="{{'PRODUCT_NAME' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'PRODUCT_NAME' | translate}}"
                        [(ngModel)]="productNoSareq.name" (ngModelChange)="productNoSareq.name = $event"
                        [ngModelOptions]="{standalone: true}">
                    </div>
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"SUPPLIER" | translate}}</label>
                    <input title="{{'SUPPLIER' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'SUPPLIER' | translate}}"
                        [(ngModel)]="productNoSareq.supplier_name" (ngModelChange)="productNoSareq.supplier_name = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"COMMENTS" | translate}}</label>
                    <input title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'COMMENTS' | translate}}"
                        [(ngModel)]="productNoSareq.comments" (ngModelChange)="productNoSareq.comments = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="flex flex-col md:flex-row mb-4">
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"ARTICLE_NUMBER" | translate}}</label>
                    <input title="{{'ARTICLE_NUMBER' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'ARTICLE_NUMBER' | translate}}"
                        [(ngModel)]="productNoSareq.article_nr" (ngModelChange)="productNoSareq.article_nr = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"AMOUNT" | translate}}</label>
                    <input title="{{'AMOUNT' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'AMOUNT' | translate}}"
                        [(ngModel)]="productNoSareq.amount_in_stock" (ngModelChange)="productNoSareq.amount_in_stock = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"ANNUAL_CONSUMPTION" | translate}}</label>
                    <input title="{{'ANNUAL_CONSUMPTION' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'ANNUAL_CONSUMPTION' | translate}}"
                        [(ngModel)]="productNoSareq.estimated_use_per_year" (ngModelChange)="productNoSareq.estimated_use_per_year = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/3 mr-4">
                    <label class="font-bold">{{"UNIT" | translate}}</label>
                    <div class="static inline-block text-left p-1 border border-gray-300" appDropdown>
                        <button type="button" class="flex justify-between items-center text-black-900 flex p-1 items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <span class="nav-home-text text-sm">{{productNoSareq.unit}}</span>
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static bg-white z-40" #dropdownMenuInside>
                        <div class="py-1">
                            <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item" (click)="setUnit(productNoSareq, unit.id)" tabindex="1">{{ unit.id }}</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col md:flex-row mb-4">
                <div class="flex flex-col w-2/4 mr-4">
                    <label class="font-bold">{{"PICTURE" | translate}} nr 1</label>
                    <input title="{{'UPLOAD' | translate}}" class="button-white border border-gray-300 mr-1" type="file" id="upload-file" (change)="incomingfilefront($event)"/>
                </div>
                <div class="flex flex-col w-2/4 mr-4">
                    <label class="font-bold">{{"PICTURE" | translate}} nr 2</label>
                    <input title="{{'UPLOAD' | translate}}" class="button-white border border-gray-300 mr-1" type="file" id="upload-file" (change)="incomingfileback($event)"/>
                </div>
            </div>
            <div class="flex flex-row justify-center mb-4 mt-4" (click)="addProductNotSareq(productNoSareq)">
                <button  class="button-green" title="{{'SAVE' | translate}}">
                    <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                    <span class="text-lg">{{ 'SAVE' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="( !loadingData && productList.length > 0 ) " class="px-4 py-2 mb-4">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" [ngClass]="{ 'pointer-events-none opacity-50': isTableDisabled }">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="productSearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('name',0)">{{"NAME" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('supplier_name',1)">{{"SUPPLIER" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('amount_in_stock',2)">{{"AMOUNT" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('estimated_use_per_year',3)">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('unit',4)">{{"UNIT" | translate}}</th>
                  <th class="table-row-header">{{"REMOVE" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('comments',5)">{{"COMMENTS" | translate}}</th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let product of productList | paginate: { id: 'main', 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                <tr>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngIf="product.new == 1"><img title="{{'NEW' | translate}}" class="w-4 h-4 mr-1" src="/images/icons/new-star-solid-svgrepo-com.svg" area-hidden="true" /></a>
                            <a *ngIf="product.status == 1"><img title="{{'STATUS' | translate}}" class="w-4 h-4 mr-1" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" /></a>
                            {{product.name}}
                        </div>
                    </td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td class="table-row-column"><input title="amount" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.amount_in_stock" (keydown.enter)="focusNextInput($event)"/> 
                        <br><p class="text-[8px] italic">({{product.origin_amount_in_stock}})</p>
                    </td> 
                    <td class="table-row-column"><input title="Estimated use per year" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.estimated_use_per_year" (keydown.enter)="focusNextInput($event)"/>
                        <br><p class="text-[8px] italic">({{product.origin_estimated_use_per_year}})</p>
                    </td>
                    <td class="table-row-column">
                        <div class="relative inline-block text-left p-1 w-10 border border-gray-300 bg-white" appDropdown>
                          <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                            <span class="nav-home-text text-sm">{{isObjectType(product.unit) ? product.unit.id : product.unit}}</span>
                            <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu w-10 z-20" #dropdownMenuInside>
                            <div class="py-1">
                              <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item w-10" (click)="setUnit(product, unit.id)" tabindex="1">{{ unit.id }}</div>
                            </div>
                          </div>
                        </div>
                        <br><p class="text-[8px] italic">({{product.origin_unit}})</p>
                    </td> 
                    <td *ngIf="product.new != 1" class="table-row-column">
                        <div class="py-1" *ngIf="product.remove == 1" (click)="removeProduct(product)">
                            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h48v48H0z" fill="none" tabindex="0"/>
                                <g id="Shopicon">
                                <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095 
                                    11.905,36.095 11.905,11.905 	"/>
                                <polygon fill="#ff0000" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                                </g>
                            </svg>
                        </div>
                        <div class="py-1" *ngIf="product.remove == 0" (click)="removeProduct(product)">
                            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" tabindex="0">
                              <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                            </svg>
                        </div>
                    </td>
                    <td *ngIf="product.new == 1" class="table-row-column">
                    </td>
                    <td class="table-row-column"><input title="comments" class="border border-gray-300 w-30 p-1" type="text" [(ngModel)]="product.comments" (keydown.enter)="focusNextInput($event)"/></td>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <button class="button-green-little" title="{{'SAVE' | translate}}" (click)="updateProduct(product)">
                                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                            </button>
                            <a *ngIf="product.new == 1 && product.status == 0" class="ml-2" title="{{'REMOVE' | translate}}" (click)="deleteProduct(product)">
                                <img title="{{'REMOVE' | translate}}" src="images/icons/remove-cross-svgrepo-com.svg" class="nav-home">
                            </a>
                        </div>
                    </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls id="main" (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="( !loadingData && productListNoSareq.length > 0 ) " class="px-4 py-2 mb-20">
        <div class="flex flex-col border border-gray-400 bg-gray-200 p-4 mb-2" (click)="productSareqSearch=!productSareqSearch">
            <div class="font-bold">
                {{"PRODUCT_MISSING_IN_INVENTORY" | translate}}<br>
                {{"PRODUCT_MISSING_IN_INVENTORY2" | translate}}
            </div>
        </div>
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" [ngClass]="{ 'pointer-events-none opacity-50': isTableDisabled }">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="productSearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('name',0)">{{"NAME" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('supplier_name',1)">{{"SUPPLIER" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('amount_in_stock',2)">{{"AMOUNT" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('unit',3)">{{"UNIT" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('estimated_use_per_year',4)">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('comments',5)">{{"ARTICLE_NUMBER" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('comments',6)">{{"COMMENTS" | translate}}</th>
                  <th class="table-row-header cursor-pointer">{{"PICTURES" | translate}}</th>
                  <th class="table-row-header">{{"REPLACE" | translate}}</th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let product of productListNoSareq | paginate: { id: 'noSareq', 'itemsPerPage': itemsPerPageNoSareq, 'currentPage': pageNumberNoSareq };let i = index">
                <tr>
                    <td class="table-row-column">{{product.product_name}}</td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td class="table-row-column"><input title="amount" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.amount_in_stock" (keydown.enter)="focusNextInput($event)"/> </td> 
                    <td class="table-row-column"><input title="Estimated use per year" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.estimated_use_per_year" (keydown.enter)="focusNextInput($event)"/></td>
                    <td class="table-row-column">
                        <div class="relative inline-block text-left p-1 w-10 border border-gray-300 bg-white" appDropdown>
                          <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                            <span class="nav-home-text text-sm">{{isObjectType(product.unit) ? product.unit.id : product.unit}}</span>
                            <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu w-10 z-20" #dropdownMenuInside>
                            <div class="py-1">
                              <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item w-10" (click)="setUnit(product, unit.id)" tabindex="1">{{ unit.id }}</div>
                            </div>
                          </div>
                        </div>
                    </td> 
                    <td class="table-row-column"><input title="article" class="border border-gray-300 p-1" type="text" [(ngModel)]="product.article_nr" (keydown.enter)="focusNextInput($event)"/></td>
                    <td class="table-row-column"><input title="comments" class="border border-gray-300 p-1" type="text" [(ngModel)]="product.comments" (keydown.enter)="focusNextInput($event)"/></td>
                    <td class="table-row-column">
                        <div class="flex flex-row" *ngIf="product.file_id || product.file_id_2">
                            <img *ngIf="(product.file_id!=null && product.file_id!='')" (click)="openPicture(product, 1)" title="{{'PICTURE' | translate}}" src="images/icons/picture-svgrepo-com.svg" class="h-6 w-6">
                            <img *ngIf="(product.file_id_2!=null && product.file_id_2!='')" (click)="openPicture(product, 2)" title="{{'PICTURE' | translate}}" src="images/icons/picture-svgrepo-com.svg" class="h-6 w-6 ml-2">
                        </div>
                    </td>
                    <!-- Image Modal -->
                    <div *ngIf="showImageModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div class="bg-white p-2 rounded-lg">
                            <div class="flex justify-end">
                                <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="showImageModal = false" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
                            </div>
                            <img title="{{'PICTURE' | translate}}" [src]="selectedImageUrl" class="max-w-full max-h-screen">
                        </div>
                    </div>
                    <td class="table-row-column">
                        <button class="button-cdocblue-little mr-1" title="{{'REPLACE' | translate}}" (click)="showImageModalSearchProduct=true">
                            <img title="{{'REPLACE' | translate}}" src="images/icons/switch-svgrepo-com.svg" class="h-6 w-6 p-1">
                        </button>
                    </td>
                    <!-- Image Modal search product -->
                    <div *ngIf="showImageModalSearchProduct" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div class="bg-white p-2 rounded-lg max-w-5xl w-full max-h-[90vh] overflow-y-auto">
                            <div class="flex justify-end">
                                <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="showImageModalSearchProduct = false" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
                            </div>
                            <app-sareq-search (dataOutput)="addProductRemoveProductNoSareq($event, product)"></app-sareq-search>
                        </div>
                    </div>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <button class="button-red-little" title="{{'REMOVE' | translate}}" (click)="removeNoSareqProduct(product)">
                                <img title="{{'REMOVE' | translate}}" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
                            </button>
                            <button class="button-green-little ml-1" title="{{'SAVE' | translate}}" (click)="updateNoSareqProduct(product)">
                                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                            </button>
                        </div>
                    </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPageNoSareq}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPageNoSareq=5;pageNumberNoSareq=1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPageNoSareq=10;pageNumberNoSareq=1" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPageNoSareq=25;pageNumberNoSareq=1" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPageNoSareq=100;pageNumberNoSareq=1" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls id="noSareq" (pageBoundsCorrection)="pageNumberNoSareq = 1" (pageChange)="pageNumberNoSareq = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="flex flex-row justify-center mb-20 mt-4">
        <!--<button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
            <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }} {{ 'ALL' | translate }}</span>
        </button>-->
        <button *ngIf="!isTableDisabled"class="button-cdocblue ml-1" title="{{'INVENTORY_DONE' | translate}}" (click)="inventorySetToDone()">
            <span class="text-white text-lg">{{ 'INVENTORY_DONE' | translate }}</span>
        </button>
        <button *ngIf="isTableDisabled"class="button-cdocblue ml-1" title="{{'UNLOCK_INVENTORY' | translate}}" (click)="inventorySetToDone()">
            <span class="text-white text-lg">{{ 'UNLOCK_INVENTORY' | translate }}</span>
        </button>
        <button *ngIf="productList.length>0 && productCountDone==0" class="button-green ml-1" title="{{'INVENTORY_MARK_DONE' | translate}}" (click)="inventoryMarkDone(1)">
            <span class="text-white text-lg">{{ 'INVENTORY_MARK_DONE' | translate }}</span>
        </button>
        <button *ngIf="productList.length>0 && productCountDone>0" class="button-green ml-1" title="{{'INVENTORY_MARK_NOT_DONE' | translate}}" (click)="inventoryMarkDone(0)">
            <span class="text-white text-lg">{{ 'INVENTORY_MARK_NOT_DONE' | translate }}</span>
        </button>
    </div>
</div>
