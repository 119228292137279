<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200 mb-4">
    <div class="flex flex-row justify-between mt-2 mb-2">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
            <path fill="#000000"
                  d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
          </svg>
        </button>
        <button class="button-cdocblue" title="{{'ADD_CHEMICAL' | translate}}" (click)="addChemical=!addChemical">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{ 'ADD_CHEMICAL' | translate }}</span>
        </button>
    </div>

    <!-- Form Container -->
    <div *ngIf="addChemical" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-4">
        <div class="flex flex-col border border-gray-400 bg-gray-200 p-4 mb-2" (click)="productSareqSearch=!productSareqSearch">
            <div class="font-bold">
                {{"SEARCH_PRODUCTAPPLICATION_HEADER" | translate}}
            </div>
            <p>{{"SEARCH_PRODUCTAPPLICATION_MESSAGE_1" | translate}}
                {{"SEARCH_PRODUCTAPPLICATION_MESSAGE_2" | translate}}<br/><br/>
            </p>
        </div>
        <div class="mb-4 mt-4" (click)="productSareqSearch=!productSareqSearch">
            <button *ngIf="!productSareqSearch" class="button-green" title="{{'SEARCH_PRODUCT' | translate}}">{{'SEARCH_PRODUCT' | translate}}</button>
            <button *ngIf="productSareqSearch" class="button-green" title="{{'HIDE_PRODUCTTABLE' | translate}}">{{'HIDE_PRODUCTTABLE' | translate}}</button>
        </div>
        <hr/>
        <div *ngIf="productSareqSearch">
            <app-sareq-search (dataOutput)="addProduct($event)"></app-sareq-search>
        </div>
        <div class="flex flex-col w-full mt-4">
            <div class="flex flex-col md:flex-row mb-4">
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"PRODUCT_NAME" | translate}}</label>
                    <input title="{{'PRODUCT_NAME' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'PRODUCT_NAME' | translate}}"
                        [(ngModel)]="productNoSareq.name" (ngModelChange)="productNoSareq.name = $event"
                        [ngModelOptions]="{standalone: true}">
                    </div>
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"SUPPLIER" | translate}}</label>
                    <input title="{{'SUPPLIER' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'SUPPLIER' | translate}}"
                        [(ngModel)]="productNoSareq.supplier_name" (ngModelChange)="productNoSareq.supplier_name = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"AMOUNT" | translate}}</label>
                    <input title="{{'AMOUNT' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'AMOUNT' | translate}}"
                        [(ngModel)]="productNoSareq.amount_in_stock" (ngModelChange)="productNoSareq.amount_in_stock = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"ANNUAL_CONSUMPTION" | translate}}</label>
                    <input title="{{'ANNUAL_CONSUMPTION' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'ANNUAL_CONSUMPTION' | translate}}"
                        [(ngModel)]="productNoSareq.estimated_use_per_year" (ngModelChange)="productNoSareq.estimated_use_per_year = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"UNIT" | translate}}</label>
                    <div class="static inline-block text-left p-1 border border-gray-300" appDropdown>
                        <button type="button" class="flex justify-between items-center text-black-900 flex p-1 items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span class="nav-home-text text-sm">{{productNoSareq.unit}}</span>
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static bg-white z-40" #dropdownMenuInside>
                          <div class="py-1">
                            <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item" (click)="setUnit(productNoSareq, unit.id)" tabindex="1">{{ unit.id }}</div>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="flex flex-col w-full md:w-1/6 mr-4">
                    <label class="font-bold">{{"COMMENTS" | translate}}</label>
                    <input title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2 text-sm" type="text" placeholder="{{'COMMENTS' | translate}}"
                        [(ngModel)]="productNoSareq.comments" (ngModelChange)="productNoSareq.comments = $event"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="flex flex-row mt-8">
                    <button class="button-green-little" title="{{'SAVE' | translate}}" (click)="addProductNotSareq(productNoSareq)">
                        <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                      </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loadingData==false" class="flex flex-col md:flex-row bg-cdocbanner p-2">
        <div class="w-1/6 mt-2 mb-2">
          <div class="flex flex-col px-4">
            <div class="card text-left bg-success text-white">
                <div class="card-body">
                  <h5 class="card-title">{{'NUMBER_OF_INVENTED_PRODUCTS' | translate}}</h5>
                  <p class="card-text display-4">{{ nbrOfInventedProducts }}({{productList.length+productListNoSareq.length}})</p>
                </div>
            </div>
          </div>
        </div>
        <div class="w-1/6 mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_ADDED_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{ nbrOfNewProducts }}({{productList.length+productListNoSareq.length}})</p>
                  </div>
              </div>
            </div>
        </div>
        <div class="w-1/6 text-left mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_REMOVED_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{ nbrOfRemovedProducts }}({{productList.length+productListNoSareq.length}})</p>
                  </div>
              </div>
            </div>
        </div>
        <div class="w-2/6 text-left mt-2 mb-2">
            <div class="flex flex-col px-4">
              <div class="card text-left bg-success text-white">
                  <div class="card-body">
                    <h5 class="card-title">{{'NUMBER_OF_NO_SAREQ_PRODUCTS' | translate}}</h5>
                    <p class="card-text display-4">{{productListNoSareq.length}}</p>
                  </div>
              </div>
            </div>
        </div>
        <div class="w-1/6 text-right m-4">
            <div class="relative inline-block text-left" appDropdown>
              <button title="{{'MENU' | translate}}" id="dropdownBtn" type="button" class="text-black flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <svg class="h-8 w-8 cursor-pointer"fill="#ffffff" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M.63 11.08zm.21.41v-.1zm.23.38L1 11.68zM1 11.68l-.11-.19zm-.21-.29c-.06-.1-.11-.21-.16-.31.05.1.1.21.16.31zm.32.54v-.06z"/>
                  <path d="m11.26 12.63 1.83 1.09a7.34 7.34 0 0 0 1-.94 7.48 7.48 0 0 0 1.56-2.86l-1.74-1A5.29 5.29 0 0 0 14 8a5.29 5.29 0 0 0-.08-.9l1.74-1a7.45 7.45 0 0 0-1.33-2.58
                  7.54 7.54 0 0 0-1.24-1.22l-1.83 1.04a6 6 0 0 0-1.11-.53v-2A8.55 8.55 0 0 0 7.94.53a8.39 8.39 0 0 0-2.26.3v2a7.23 7.23 0 0 0-1.12.54L2.78 2.28A7.46 7.46 0 0 0 .2 6.06l1.72 1a5.29 5.29
                  0 0 0-.08.9 5.29 5.29 0 0 0 .08.9l-1.73 1a8 8 0 0 0 .43 1.15c.05.1.1.21.16.31v.1l.11.19.12.19v.06a7.69 7.69 0 0 0 1.64 1.78l1.81-1.08a7.23 7.23 0 0 0 1.12.54v2a8.39 8.39 0 0 0 2.26.31 8.56
                  8.56 0 0 0 2.22-.3v-2a6 6 0 0 0 1.2-.48zm-2.39 1.52a7.57 7.57 0 0 1-.95.06 7.73 7.73 0 0 1-1-.06v-1.69a4.92 4.92 0 0 1-2.53-1.27l-1.54.92a6.22 6.22 0 0 1-1.08-1.61l1.56-.93a4.27 4.27 0 0 1
                  0-3.17l-1.56-.92a6.11 6.11 0 0 1 1.12-1.62l1.56.93A5 5 0 0 1 7 3.53V1.82a7.73 7.73 0 0 1 1-.06 7.57 7.57 0 0 1 .95.06v1.72a4.9 4.9 0 0 1 2.4 1.26l1.59-.94a6.31 6.31 0 0 1 1.11 1.62l-1.6.94a4.35
                  4.35 0 0 1 .3 1.58 4.44 4.44 0 0 1-.29 1.55l1.56.93a6.43 6.43 0 0 1-1.11 1.61l-1.58-.93a5 5 0 0 1-2.49 1.28z"/><path d="M7.92 5.49A2.59 2.59 0 0 0 5.25 8a2.59 2.59 0 0 0 2.67 2.51A2.6 2.6 0 0 0
                  10.6 8a2.6 2.6 0 0 0-2.68-2.51zM8 9.2A1.35 1.35 0 0 1 6.55 8 1.35 1.35 0 0 1 8 6.7 1.35 1.35 0 0 1 9.39 8 1.35 1.35 0 0 1 8 9.2z"/>
                </svg>
              </button>
              <div id="dropdownMenu" class="hidden dropdown-menu-help right-10 z-20" #dropdownMenuInside>
                  <div class="py-2">
                    <a (click)="downloadList()" class="dropdown-menu-help-item" tabindex="2">
                      {{"DOWNLOAD_LIST" | translate}}
                    </a>
                    <a (click)="uploadList()" class="dropdown-menu-help-item" tabindex="4">
                        {{"UPLOAD_LIST" | translate}}
                    </a>
                    <a (click)="inventorySetToDone()" class="dropdown-menu-help-item" tabindex="3">
                        {{"INVENTORY_DONE" | translate}}
                    </a>
                  </div>
            </div>
        </div>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="( !loadingData && productList.length > 0 ) " class="px-4 py-2 mb-4">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" [ngClass]="{ 'pointer-events-none opacity-50': isTableDisabled }">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="productSearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('name',0)">{{"NAME" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('supplier_name',1)">{{"SUPPLIER" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('amount_in_stock',2)">{{"AMOUNT" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('estimated_use_per_year',3)">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('unit',4)">{{"UNIT" | translate}}</th>
                  <th class="table-row-header">{{"REMOVE" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('comments',5)">{{"COMMENTS" | translate}}</th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let product of productList | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                <tr>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <a *ngIf="product.new == 1"><img title="{{'HYGIENE_LIMITS' | translate}}" class="w-4 h-4 mr-1" src="/images/icons/new-star-solid-svgrepo-com.svg" area-hidden="true" /></a>
                            <a *ngIf="product.status == 1"><img title="{{'HYGIENE_LIMITS' | translate}}" class="w-4 h-4 mr-1" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" /></a>
                            {{product.name}}
                        </div>
                    </td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td class="table-row-column"><input title="amount" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.amount_in_stock" /> 
                        <br><p class="text-[8px] italic">({{product.origin_amount_in_stock}})</p>
                    </td> 
                    <td class="table-row-column"><input title="Estimated use per year" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.estimated_use_per_year" />
                        <br><p class="text-[8px] italic">({{product.origin_estimated_use_per_year}})</p>
                    </td>
                    <td class="table-row-column">
                        <div class="relative inline-block text-left p-1 w-10 border border-gray-300 bg-white" appDropdown>
                          <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                            <span class="nav-home-text text-sm">{{isObjectType(product.unit) ? product.unit.id : product.unit}}</span>
                            <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu w-10 z-20" #dropdownMenuInside>
                            <div class="py-1">
                              <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item w-10" (click)="setUnit(product, unit.id)" tabindex="1">{{ unit.id }}</div>
                            </div>
                          </div>
                        </div>
                        <br><p class="text-[8px] italic">({{product.origin_unit}})</p>
                    </td> 
                    <td class="table-row-column">
                        <div class="py-1" *ngIf="product.remove == 1" (click)="removeProduct(product)">
                            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 48 48" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h48v48H0z" fill="none"/>
                                <g id="Shopicon">
                                <polygon points="30.953,11.905 30.953,8.095 8.095,8.095 8.095,39.905 39.905,39.905 39.905,20.75 36.095,20.75 36.095,36.095 
                                    11.905,36.095 11.905,11.905 	"/>
                                <polygon fill="#ff0000" points="41,7.172 24,24.172 17,17.172 14.171,20 21.172,27 21.171,27 24,29.828 26.828,27 43.828,10 	"/>
                                </g>
                            </svg>
                        </div>
                        <div class="py-1" *ngIf="product.remove == 0" (click)="removeProduct(product)">
                            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 3h18v18H3V3zm16 16V5H5v14h14z" fill="#000000"/>
                            </svg>
                        </div>
                    </td>
                    <td class="table-row-column"><input title="comments" class="border border-gray-300 w-30 p-1" type="text" [(ngModel)]="product.comments" /></td>
                    <td class="table-row-column">
                        <div class="flex flex-row">
                            <button class="button-green-little" title="{{'SAVE' | translate}}" (click)="updateProduct(product)">
                                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                            </button>
                        </div>
                    </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>

        <div class="flex flex-row justify-center mb-10 mt-4">
            <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
                <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                <span class="text-lg">{{ 'SAVE' | translate }} {{ 'ALL' | translate }}</span>
            </button>
        </div>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="( !loadingData && productListNoSareq.length > 0 ) " class="px-4 py-2 mb-20">
        <div class="flex flex-col border border-gray-400 bg-gray-200 p-4 mb-2" (click)="productSareqSearch=!productSareqSearch">
            <div class="font-bold">
                {{"PRODUCT_MISSING_IN_INVENTORY" | translate}}
            </div>
        </div>
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" [ngClass]="{ 'pointer-events-none opacity-50': isTableDisabled }">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row md:items-center">
                            <div class="mt-4 ml-2">
                                <input st-search="" type="search" [(ngModel)]="productSearch.all" class="prompt" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('name',0)">{{"NAME" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('supplier_name',1)">{{"SUPPLIER" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('amount_in_stock',2)">{{"AMOUNT" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('estimated_use_per_year',3)">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                  <th class="table-row-header cursor-pointer" (click)="sortInventories('comments',4)">{{"COMMENTS" | translate}}</th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let product of productListNoSareq | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
                <tr>
                    <td class="table-row-column">{{product.product_name}}</td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td class="table-row-column">{{product.amount_in_stock}}&nbsp;{{product.unit}}</td>
                    <td class="table-row-column">{{product.estimated_use_per_year}}&nbsp;{{product.unit}}</td>
                    <td class="table-row-column">{{product.comments}}</td>
                    <td class="table-row-column">
                        <button class="button-red-little  mr-1" title="{{'REMOVE' | translate}}" (click)="removeNoSareqProduct(product)">
                            <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="h-5 w-4">
                        </button>
                    </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
