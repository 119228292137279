import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: "root",
})

export class ProductApplicationService {
  getProductApplicationDataUpdated = new Subject<any>();
  addProductsApplicationDataUpdated = new Subject<any>();
  getSDSDataUpdated = new Subject<any>();

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private productConversionsService: ProductConversionsService
  ) {}

  createProductApplication(product) {
    if (localStorage.getItem("selectedOrganisation") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http
        .post<any>(
          BackendUrl + "/productapplication/create/" + orgId,
          product,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getProductApplicationDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getProductApplicationDataUpdated.next(null);
          },
        });
    }
  }

  getAllProductApplications( orgId, subOrgList ) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    var params = new HttpParams().set("subOrgList", subOrgList);
    this.http
      .get<any>(
        BackendUrl + "/productapplication/getAll/" + orgId,
        { headers: headers,
          params: params }
      )
      .pipe(take(1))
      .subscribe( {
        next: (response) => {
          this.getProductApplicationDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getProductApplicationDataUpdated.next(null);
        },
      });
  }

  getAllHistoryProductApplications(orgId) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .get<any>(
        BackendUrl + "/productapplication/getAllHistory/" + orgId,
        { headers: headers }
      )
      .pipe(take(1))
      .subscribe( {
        next: (response) => {
          this.getProductApplicationDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getProductApplicationDataUpdated.next(null);
        },
      });
  }

  getProductApplication(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .get<any>(
        BackendUrl + "/productapplication/getSingle/" + id,
        { headers: headers }
      )
      .pipe(take(1))
      .subscribe( {
        next: ( response ) => {
          this.getProductApplicationDataUpdated.next( response );
        },
        error: ( error ) => {
          if ( error.status === 401 ) {
            this.authService.logout();
          }
          this.getProductApplicationDataUpdated.next( null );
        },
      });
  }

  updateProductApplication(product) {
    if (localStorage.getItem("selectedOrganisation") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http
        .put<any>(
          BackendUrl + "/productapplication/update/" + orgId,
          product,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getProductApplicationDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getProductApplicationDataUpdated.next(null);
          },
        });
    }
  }

  addProductProductApplication( product ) {
    if (localStorage.getItem("selectedOrganisation") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http.put<any>(BackendUrl + "/productapplication/addproduct/" + orgId, product, { headers: headers })
        .pipe(take(1)).subscribe({
          next: (response) => {
            this.addProductsApplicationDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.addProductsApplicationDataUpdated.next(null);
          },
        });
    }
  }

  deleteProductApplication( id ) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders( {
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .delete<any>( BackendUrl + "/productapplication/delete/" + id, { headers: headers } )
        .pipe( take( 1 ) )
        .subscribe( {
          next: ( response ) => {
            this.getProductApplicationDataUpdated.next( response );
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getProductApplicationDataUpdated.next(null);
          },
    } );
  }

  uploadSds(fileInfo) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(BackendUrl + "/productapplication/uploadSds/", fileInfo, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSDSDataUpdated.next(response);
        },
        error: (error) => {
          this.getSDSDataUpdated.next(null);
        },
      });
    }

    deleteFileWithId(fileId) {
        let token = localStorage.getItem("token");
        const headers = new HttpHeaders({
          Accept: "application/json",
          Authorization: token,
        });

        this.http
          .delete(BackendUrl + "/productapplication/deleteSds/" + fileId + "/", {
            headers: headers,
          })
          .pipe(take(1)).subscribe({
            next: (response) => {
              this.getSDSDataUpdated.next(response);
            },
            error: (error) => {
              this.getSDSDataUpdated.next(null);
            },
          });
      }

    getsds(fileId) {
        let token = localStorage.getItem("token");
        const headers = new HttpHeaders({
          Accept: "application/json",
          Authorization: token,
        });

        this.http
          .get(BackendUrl + "/productapplication/getsds/" + fileId, {
            headers: headers,
            responseType: "arraybuffer",
          })
          .pipe(take(1)).subscribe({
            next: (response) => {
              var file = new Blob([response], { type: "application/pdf" });
              var fileURL = URL.createObjectURL(file);
              this.getSDSDataUpdated.next(fileURL);
            },
            error: (error) => {
              this.getSDSDataUpdated.next(null);
            },
          });
      }

  public getProductApplicationDataListener() {
    return this.getProductApplicationDataUpdated.asObservable();
  }

  public addProductsApplicationDataListener() {
    return this.addProductsApplicationDataUpdated.asObservable();
  }

  public getSDSDataListener() {
    return this.getSDSDataUpdated.asObservable();
  }
}
