import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "src/app/auth/auth.service";
import { SareqService } from 'src/app/services/sareq.service';
import { ProductApplicationService } from "src/app/services/productapplication.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InventoryService } from 'src/app/services/inventory.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import { ModalUploadFileComponent } from 'src/app/modals/modal-upload-file/modal-upload-file.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.css']
})
export class InventoryItemComponent implements OnInit{

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  inventoryId: any;
  department: any = {}
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  productList: any = [];
  productListNoSareq: any = [];
  pictureHeight = 50;
  pictureWidth = 50;
  productListtDuplicate: any = [];
  productListNoSareqDuplicate: any = [];
  units: any = [];
  nbrOfInventedProducts: number = 0;
  nbrOfNewProducts: number = 0;
  nbrOfRemovedProducts: number = 0;
  addChemical: boolean = false;
  productSareqSearch: boolean = false;
  inventory: any = {};
  isTableDisabled: boolean = false;
  productSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };
  productAddSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };
  productNoSareq = {
    name: "",
    supplier_name: "",
    comments: "",
    amount_in_stock: 0,
    estimated_use_per_year: 0,
    unit: "l"
  };
  order = ["asc", "asc","asc", "asc", "asc", "asc", ,"asc"];

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private productApplicationService: ProductApplicationService,
    private sareqService: SareqService,
    private inventoryService: InventoryService,
    private uploadService: FileUploadService,
    private router: Router,
    private modalService: NgbModal,
    private previousrouter: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.previousrouter.params.pipe(take(1)).subscribe((params) => {
      this.inventoryId = params["id"];
    });

    this.loadingData = true;
    this.addChemical = false;
    this.organisation = JSON.parse( localStorage.getItem( "rootOrganisation" ) );
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );

    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );

    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null) 
    {
      this.logout();
    }

    this.inventory = JSON.parse(
      localStorage.getItem("inventory")
    );

    let data = localStorage.getItem('currentUser', );
    if (data != null && data != undefined)
    {
      this.currentUser = JSON.parse(data);
    }

    data = localStorage.getItem('inventoryDepartment', );
    if (data != null && data != undefined)
    {
      this.department = JSON.parse(data);
    }

    this.units = this.staticDataService.getUnitDefinitions();
    this.getProducts();
  }

  logout() {
    this.authService.logout();
  }

  cancel() {
    localStorage.removeItem('inventoryDepartment');
    this.router.navigate(["/inventorylist"]);
  }

  isObjectType(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }

  setUnit(product, value) {
    for (var i = 0; i < this.units.length; i++) {
      if (value == this.units[i].id) {
        product.unit = this.units[i];
        break;
      }
    }
  }

  filterSearchItems() {
    this.productList = this.productListtDuplicate; 
    if ( this.productSearch.all == '' ) {
      this.productSearch.all=null;
    }
    if ( this.productSearch.all != null ) {
      this.productList = this.productList.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productSearch.all.toLowerCase())>-1);
    }
  }

  sortInventories ( property, sortIndex ) {
    this.productList = this.productListtDuplicate; 
    if ( this.order[ sortIndex ]== 'asc' ) {
      this.order[ sortIndex ] = 'desc';
      this.productList = _.orderBy( this.productList, [ property ], ['asc']);
    } else if ( this.order[ sortIndex ] == 'desc' ) {
      this.order[ sortIndex ] = 'asc';
      this.productList = _.orderBy( this.productList, [ property ], ['desc'] );
    }
  }

  getProducts() {
    this.inventoryService.getInventoryProducts(this.department.id, this.inventoryId);
    this.inventoryService.getInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        if (products != null && products != undefined && products.length > 0) {
          this.productList = products;
          this.nbrOfInventedProducts = this.productList.filter(product => product.status === 1).length;
          this.nbrOfNewProducts = this.productList.filter(product => product.new === 1).length;
          this.nbrOfRemovedProducts = this.productList.filter(product => product.remove === 1).length;
          this.productListtDuplicate = _.cloneDeep(this.productList);

          const hasApprovedProducts = this.productList.some(product => product.approved === 1);
          if (hasApprovedProducts) {
            this.isTableDisabled = true;
          }
        }
        this.getNoSareqProducts();
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  getNoSareqProducts() {
    this.inventoryService.getNoSareqInventoryProducts(this.department.id, this.inventoryId);
    this.inventoryService.getNoSareqInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        if (products != null && products != undefined && products.length > 0) {
          this.productListNoSareq = products;
          this.productListNoSareqDuplicate = _.cloneDeep(this.productListNoSareq);
        }
        this.updateProductList();
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  removeProduct(product) {
    if (product.remove == 0)
    {
      product.remove = 1;
    }
    else
    {
      product.remove = 0;
    }
  }

  updateProduct(product) {
    product.status = 1;
    this.inventoryService.updateInventoryProduct(this.department.id, this.inventoryId, product);
    this.inventoryService.getInventoryDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.nbrOfInventedProducts = this.productList.filter(product => product.status === 1).length;
        this.nbrOfNewProducts = this.productList.filter(product => product.new === 1).length;
        this.nbrOfRemovedProducts = this.productList.filter(product => product.remove === 1).length;
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  addProduct(product) {

    const productExists = this.productList.some(
      existingProduct => existingProduct.product_id === product.product_id
    );

    if (productExists) {
      Swal.fire(this.translate.instant("PRODUCT_ALREADY_EXISTS"),
        "\"" + product.name + "\" " + this.translate.instant("POST_ALREADY_EXISTS_ON_DEPARTMENT"),
        "error");
    }

    product.status = 0;
    this.inventoryService.addInventoryProduct(this.department.id, this.inventoryId, product);
    this.inventoryService.getInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.ngOnInit();
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  addProductNotSareq(product) {
    this.inventoryService.addInventoryProductNoSareq(this.department.id, this.inventoryId, product);
    this.inventoryService.getNoSareqInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.addChemical = false;
        this.getNoSareqProducts();
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  removeNoSareqProduct(product) {
    this.inventoryService.removeInventoryProductNoSareq(this.department.id, this.inventoryId, product.id);
    this.inventoryService.getNoSareqInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.getNoSareqProducts();
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  updateProductList() {
    this.inventoryService.syncInventoryProducts(this.department.id, this.inventoryId);
    this.inventoryService.getInventoryProductsDataListener().pipe(take(1)).subscribe({
      next: products => {
        this.loadingData = false;
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData  = false;
      }
    })
  }

  downloadList() {
    const worksheet = XLSX.utils.json_to_sheet(
      this.productList.map(product => ({
        [this.translate.instant("NAME")]: product.name,
        [this.translate.instant("SUPPLIER_NAME")]: product.supplier_name,
        [this.translate.instant("AMOUNT")]: product.amount_in_stock,
        [this.translate.instant("ANNUAL_CONSUMPTION")]: product.estimated_use_per_year,
        [this.translate.instant("UNIT")]: product.unit,
        [this.translate.instant("COMMENTS")]: product.comments,
        [this.translate.instant("REMOVE")]: product.remove,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ProductList');

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const data: Blob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });

    saveAs(data, 'ProductList.xlsx');
  }

  inventorySetToDone() {

    if (this.isTableDisabled == false) {

      const hasInactiveProduct = this.productList.some(product => product.status === 0);

      if (hasInactiveProduct || this.productListNoSareq.length > 0) {
        Swal.fire(this.translate.instant("INVENTORY_NOT_COMPLETE"),
          this.translate.instant("INVENTORY_NOT_COMPLETE_MESSAGE"),
          "error");
      }

      
      this.inventoryService.approveInventoryProducts(this.department.id, this.inventoryId);
      this.inventoryService.getInventoryProductsDataListener().pipe(take(1)).subscribe({
        next: products => {
          this.isTableDisabled = true;
          this.ngOnInit();
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData  = false;
        }
      })
    }
    else
    {
      this.isTableDisabled = false;
    }
  }

  async processAndUpdateProductList(data: any[]): Promise<void> {
    for (const row of data) {
      const existingProduct = this.productList.find(
        (product) => product.name === row[this.translate.instant("NAME")] && product.supplier_name === row[this.translate.instant("SUPPLIER_NAME")]
      );

      if (existingProduct) {
        // Update the existing product
        existingProduct.name = row[this.translate.instant("NAME")] || existingProduct.name;
        existingProduct.supplier_name = row[this.translate.instant("SUPPLIER_NAME")] || existingProduct.supplier_name;
        existingProduct.amount_in_stock = row[this.translate.instant("AMOUNT")] || existingProduct.amount_in_stock;
        existingProduct.estimated_use_per_year =
          row[this.translate.instant("ANNUAL_CONSUMPTION")] || existingProduct.estimated_use_per_year;
        existingProduct.unit = row[this.translate.instant("UNIT")] || existingProduct.unit;
        existingProduct.comments = row[this.translate.instant("COMMENTS")] || existingProduct.comments;
        existingProduct.remove = row[this.translate.instant("REMOVE")] || existingProduct.remove;
        existingProduct.status = 1;

        try {
          // Wait for the update to complete before continuing
          this.inventoryService.updateInventoryProduct(this.department.id, this.inventoryId, existingProduct);
  
          // Optionally fetch updated data
          const products = await this.inventoryService.getInventoryDataListener().pipe(take(1)).toPromise();

        } catch (error) {
          console.error('Error updating product:', error);
          this.loadingData = false;
          break; // Stop processing on error if required
        }
      } else {
        // Add a new product if it doesn't exist
        var newProduct = {
          name: row[this.translate.instant("NAME")] || null,
          supplier_name: row[this.translate.instant("SUPPLIER_NAME")] || null,
          amount_in_stock: row[this.translate.instant("AMOUNT")] || 0,
          estimated_use_per_year: row[this.translate.instant("ANNUAL_CONSUMPTION")] || 0,
          unit: row[this.translate.instant("UNIT")] || 'Unknown',
          comments: row[this.translate.instant("COMMENTS")] || '',
          remove: row[this.translate.instant("REMOVE")] || 0
        };

        this.addProductNotSareq(newProduct);
      }
    };
  }

  async submit() {

    if (this.productListNoSareq.length > 0) {
      Swal.fire(this.translate.instant("INVENTORY_NOT_COMPLETE"),
        this.translate.instant("INVENTORY_NOT_COMPLETE_MESSAGE2"),
        "error");
    }

    for (var i = 0; i < this.productList.length; i++) {
      this.productList[i].status = 1;

      // Wait for the update to complete before continuing
      this.inventoryService.updateInventoryProduct(this.department.id, this.inventoryId, this.productList[i]);
  
      // wait for response
      await this.inventoryService.getInventoryDataListener().pipe(take(1)).toPromise();
    }

    this.ngOnInit();
  }

  uploadList() {
    // Open the modal for file upload
    const modalRef = this.modalService.open(ModalUploadFileComponent);
    modalRef.componentInstance.no_upload = true;
  
    // Subscribe to the modal's output for the selected file
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((file: File) => {
      if (!file) {
        console.error('No file provided.');
        return;
      }
  
      const reader: FileReader = new FileReader();
  
      // Handle the file read operation
      reader.onload = (event: any) => {
        const binaryString: string = event.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
  
        // Assume the data is in the first sheet
        const sheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
  
        // Convert the sheet data to JSON
        const data = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        this.processAndUpdateProductList(data)
          .then(() => this.ngOnInit())
          .catch((error) => console.error('Error processing product list:', error));
      };
  
      // Read the file as a binary string
      reader.readAsBinaryString(file);
    });
  }
  
}
