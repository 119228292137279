<div *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)" class="flex flex-col md:flex-row max-w-screen">
  <div [hidden]="!loadingChangedProducts" class="flex flex-row w-full">
    <!-- Form Container -->
    <div class="flex flex-col bg-white w-1/1 rounded overflow-hidden shadow-lg mb-2 p-7">
      <p class="text-xl font-bold">{{"CHANGES_MADE_ON_THIS_DEPARTMENTS_PRODUCTS" | translate}}</p>
      <p class="text-lg mb-4">{{"SHOW_CHANGES_SYMBOLS" | translate}}</p>
      <hr/>

      <div class="flex flex-row mt-6">
        <div class="w-1/2">
          <label class="font-bold mb-1">{{"DATE_FROM" | translate}}</label><br>
          <input class="border border-gray p-1" type="date" [ngModel]="changedProductsFrom | date:'yyyy-MM-dd'"
              (ngModelChange)="$event?changedProductsFrom = $event:null" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="w-1/2">
          <label class="font-bold mb-1">{{"DATE_TO" | translate}}</label><br>
          <input class="border border-gray p-1" type="date" [ngModel]="changedProductsTo | date:'yyyy-MM-dd'"
                (ngModelChange)="$event?changedProductsTo= $event:null" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div><label *ngIf="errorDate!=''">{{errorDate}}</label></div>
      <div class="mt-6">
        <button class="button-green" type="button" (click)="getProducts()">{{"CHANGES_DONE" | translate}}</button>
      </div>
      <div class="mt-6">
        <label class="bg-blue-500 p-2 rounded text-white" *ngIf="changedProducts&&changedProducts?.length==0&&getProductsIsClicked&&!loadingChangedProducts">{{"NO_CHANGES_DONE" | translate}}!</label>
      </div>
      <div *ngIf="loadingChangedProducts" class="flex justify-center items-center mt-10">
        <div class="loader w-10 h-10"></div>
      </div>
      <table *ngIf="changedProducts&&changedProducts?.length>0&&getProductsIsClicked" class="table-auto bg-white mb-4">
        <thead class="bg-gray-200">
          <tr>
            <th class="table-row-header">{{"PRODUCT" | translate}}</th>
            <th class="table-row-header">{{"CHANGES" | translate}}</th>
            <th class="table-row-header">{{"DATE" | translate}}</th>
            <th class="table-row-header"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of changedProducts;">
            <td class="table-row-column" >{{item.name}}</td>
            <td class="table-row-column" >
              <ul>
                <li *ngFor="let items of item.value;">{{items}}</li>
                <li *ngIf="item.clp&&item?.clp?.length>0" id="change_clp">{{"ADDED_DANGERSYMBOLS" | translate}}:<br/>
                  <div class="flex">
                    <img *ngFor="let items of item.clp;" [src]="items">
                  </div>
                </li>
                <li *ngIf="item.clpRemoved&&item?.clpRemoved?.length>0" id="change_clp_removed">{{"REMOVED_DANGERSYMBOLS" | translate}}:<br/>
                  <div class="flex">
                    <img *ngFor="let items of item.clpRemoved;" [src]="items">
                  </div>
                </li>
                <li *ngIf="item.protective&&item?.protective?.length>0" id="change_protective">{{"ADDED_PROTECTIVESYMBOLS" | translate}}:<br/>
                  <div class="flex">
                    <img *ngFor="let items of item.protective;" [src]="items">
                  </div>
                </li>
                <li *ngIf="item.protectiveRemoved&&item?.protectiveRemoved?.length>0" id="change_protective_removed">{{"REMOVED_PROTECTIVESYMBOLS" | translate}}:<br/>
                  <div class="flex">
                    <img *ngFor="let items of item.protectiveRemoved;" [src]="items"><br/>
                  </div>
                </li>
              </ul>
            </td>
            <td class="table-row-column" >
              <ul>
                <li *ngFor="let items of item.date;">{{items}}</li>
              </ul>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

