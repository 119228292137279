<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="flex flex-row justify-between w-full">
  <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>

  <button *ngIf="currentUser&&!currentUser.is_readonly_user" (click)="addSareq()" class="button-cdocblue mt-4 mr-4">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
    <span class="text-white text-lg">{{"ADD" | translate}}</span>
  </button>
</div>

<div class="container mr-auto px-4 mt-2">
  <ul class="flex flex-col md:flex-row">
    <li class="mr-1">
      <a (click)="showTab('isDashboard')" [class.active]="isDashboard" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"DASHBOARD" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isSearch')" [class.active]="isSearch" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"SEARCH" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isAll')" [class.active]="isAll" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ALL" | translate}}</a>
    </li>
    <li *ngIf="currentUser&&currentUser.is_super_user" class="mr-1">
      <a (click)="showTab('isInspection')" [class.active]="isInspection" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SAREQ_STATUS_0" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isOngoing')" [class.active]="isOngoing" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SAREQ_STATUS_5" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isAvailable')" [class.active]="isAvailable" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SAREQ_STATUS_3" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isNew')" [class.active]="isNew" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SAREQ_STATUS_4" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="showTab('isReturned')" [class.active]="isReturned"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SAREQ_STATUS_2" | translate}}</a>
    </li>
  </ul>
</div>

<div *ngIf="isDashboard" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <app-sareq-dashboard></app-sareq-dashboard>
</div>

<div *ngIf="isSearch" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="table-search" st-safe-src="table-search">
          <thead class="bg-white">
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mt-4 mb-2">
                          <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (keydown.enter)="loadSareqList('all')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mt-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (keydown.enter)="loadSareqList('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mt-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (keydown.enter)="loadSareqList('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mt-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" id="columnbutton10" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" id="columnbutton11" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" id="columnbutton12" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" id="columnbutton13" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" id="columnbutton14" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" id="columnbutton15" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" id="columnbutton16" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" id="columnbutton17" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" id="columnbutton18" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" id="columnbutton19" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-row items-center mb-2 mr-4">
                    <button id="sareqSearch110button" class="button-green mr-1" title="{{'SEARCH_PRODUCT' | translate}}" (click)="checkIfItemsInSearchAndSearch()">
                      <span class="text-lg">{{ 'SEARCH_PRODUCT' | translate }}</span>
                    </button>
                    <button *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)" class="button-cdocblue"
                      title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                      <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                    </button>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (mouseenter)="showMinus6 = true" (mouseleave)="showMinus6 = false">{{"Revidera"}}
                  <sup>
                    <img *ngIf="showMinus6==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(9, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <svg *ngIf="product?.existsinorg" class="w-6 h-6 mr-1" width="800px" height="800px" viewBox="0 0 24 24" fill="#21BA45" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                    stroke="#21BA45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <button class="button-gray" title="{{'Revidera SDB'}}" (click)="reviseSdb(product)">
                  <span class="text-black whitespace-nowrap">{{"Revidera SDB"}}</span>
                </button>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
  </div>
</div>

<div *ngIf="isAll" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="allsareq" st-safe-src="allsareq">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)" [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                              class="button-green ml-20 sticky top-0 left-0"
                              title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]"  class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[16]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(16, true)" >
                        {{"LAST_UPDATED" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (mouseenter)="showMinus6 = true" (mouseleave)="showMinus6 = false">{{"Revidera"}}
                  <sup>
                    <img *ngIf="showMinus6==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(9, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"H_PHRASES" | translate}}
                </th>
                <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"CAS" | translate}}
                </th>
                <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"RISK_LEVEL" | translate}}
                </th>
                <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                  {{"SAFETY_EXT_DATA_SHEET" | translate}}
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[16]" (click)="sortItem('update_date');">{{"LAST_UPDATED" | translate}}</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <button class="button-gray" title="{{'Revidera SDB'}}" (click)="reviseSdb(product)">
                  <span class="text-black whitespace-nowrap">{{"Revidera SDB"}}</span>
                </button>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[16]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.update_date | date:"yyyy/MM/dd HH:mm:ss"}}</td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>

<div *ngIf="isInspection" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="displayedNotApprovedInspection" st-safe-src="displayedNotApprovedInspection">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH'| translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)"  [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                                class="button-green ml-20 sticky top-0 left-0"
                                title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header">{{"Godkänn"}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"H_PHRASES" | translate}}
                </th>
                <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"CAS" | translate}}
                </th>
                <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"RISK_LEVEL" | translate}}
                </th>
                <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                  {{"SAFETY_EXT_DATA_SHEET" | translate}}
                </th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <button class="button-gray" title="{{'Godkänn'}}" (click)="approveProduct(product)">
                  <span class="text-black whitespace-nowrap">{{"Godkänn"}}</span>
                </button>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>

<div *ngIf="isOngoing" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="displayedNotApprovedUpdated" st-safe-src="displayedNotApprovedUpdated">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH'| translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)"  [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                              class="button-green ml-20 sticky top-0 left-0"
                              title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
              <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                      {{"H_PHRASES" | translate}}
              </th>
              <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                {{"CAS" | translate}}
              </th>
              <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                {{"RISK_LEVEL" | translate}}
              </th>
              <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                {{"SAFETY_EXT_DATA_SHEET" | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>

<div *ngIf="isAvailable" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-safe-src="displayedOngoing">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH'| translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)"  [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                              class="button-green ml-20 sticky top-0 left-0"
                              title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"H_PHRASES" | translate}}
                </th>
                <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"CAS" | translate}}
                </th>
                <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"RISK_LEVEL" | translate}}
                </th>
                <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                  {{"SAFETY_EXT_DATA_SHEET" | translate}}
                </th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>

<div *ngIf="isNew" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-safe-src="displayedNew">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH'| translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)" [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                              class="button-green ml-20 sticky top-0 left-0"
                              title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"H_PHRASES" | translate}}
                </th>
                <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"CAS" | translate}}
                </th>
                <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"RISK_LEVEL" | translate}}
                </th>
                <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                  {{"SAFETY_EXT_DATA_SHEET" | translate}}
                </th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>

<div *ngIf="isReturned" class="mt-1 px-4 py-2 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-safe-src="displayedReturn">
          <thead class="bg-white">
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-4 mt-4 mb-2">
                        <input #searchSareqInput st-search="" type="search" [(ngModel)]="sareqSearch.all" class="prompt" (ngModelChange)="sareqSearch.all=$event;filterByType('all')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                    <div class="flex flex-col md:flex-row">
                      <div class="mr-4 mb-2">
                          <input st-search="" type="search" [(ngModel)]="sareqSearch.name" class="prompt" (ngModelChange)="sareqSearch.name=$event;filterByType('name')"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'PRODUCT_NAME' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.manufacturer" class="prompt" (ngModelChange)="sareqSearch.manufacturer=$event;filterByType('manufacturer')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'MANUFACTURER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.articlenumber" class="prompt" (ngModelChange)="sareqSearch.articlenumber=$event;filterByType('articlenumber')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'ARTICLE_NUMBER' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" [(ngModel)]="sareqSearch.responsible" class="prompt" (ngModelChange)="sareqSearch.responsible=$event;filterByType('responsible')"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'RESPONSIBLE' | translate}} ..." />
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'CHOOSE_COLUMNS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showSelectColumnsClicked(showSelectColumns)">
                        <span class="text-sm mr-1">{{"CHOOSE_COLUMNS" | translate}}</span>
                      </div>
                      <div class="flex items-center space-x-2 mr-4 mb-2">
                        <input title="{{'SHOW_MORE_SEARCH'| translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (click)="showAdvancedClicked(showAdvanced)" [checked]="showAdvanced==true">
                        <span class="text-sm mr-1">{{"SHOW_MORE_SEARCH"| translate}}</span>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showAdvanced==true">
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="false" id="searchProductList6" (input)="changeHprases()" [(ngModel)]="sareqSearch.h_phrases"  class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_H_PHRASE' | translate}} ..." />
                      </div>
                      <div class="mr-4 mb-2">
                        <input st-search="" type="search" fullTextSearch="exact" id="searchProductList8" [(ngModel)]="sareqSearch.cas"
                              (ngModelChange)="sareqSearch.cas=$event;filterByType('cas')"c lass="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                      </div>
                      <div class="static inline-block text-left" id="selectRisk" appDropdown>
                        <button type="button" class="button-gray">
                          <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                          </svg>
                          <span>{{"RISK_LEVEL" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let risk of searchRisk" (click)="searchByRisk(risk)" class="dropdown-menu-item" id="{{'sareqListSelectRisk'+risk}}" tabindex="1">{{ risk }} </div>
                        </div>
                      </div>
                      <div class="static inline-block text-left z-10" id="selectSDS" appDropdown>
                        <button type="button" class="button-gray">
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                            3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                            4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                            14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                            4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                        </svg>
                        <span>{{"SDS" | translate}}</span>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let SDS of searchSDS" (click)="searchByESDB(SDS.id)" data-value="SDS"
                                class="dropdown-menu-item w-auto" id="{{'productListesds'+SDS.name}}" tabindex="1">{{SDS.name | translate }}</div>
                        </div>
                      </div>
                      <div class="relative">
                        <button type="button" *ngIf=" this.displayedProductsFiltered && (this.displayedProductsFiltered.length > 0)"
                              class="button-green ml-20 sticky top-0 left-0"
                              title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                          <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row flex-wrap mt-2" *ngIf="showSelectColumns">
                      <button type="button" *ngIf="!extendedSareqListSettings[2]" id="columnbutton0" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(2, true)" >
                        {{"SDS/GRB"}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[3]" id="columnbutton1" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(3, true)" >
                        {{"SDS"}} {{"VERSION" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[4]" id="columnbutton2" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(4, true)" >
                        {{"SDS"}} {{"REVISION_DATE" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[12]" id="columnbutton3" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(12, true)" >
                        {{"HIDDEN" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[10]" id="columnbutton4" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(10, true)" >
                        {{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[13]" id="columnbutton5" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(13, true)" >
                        {{"REVCOMMENT" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" id="columnbutton6" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"HIDE_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[11]" id="columnbutton7" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(11, true)" >
                        {{"SHOW_REPLACED" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[14]" id="columnbutton8" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(14, true)" >
                        {{"UFI" | translate}}
                      </button>
                      <button type="button" *ngIf="!extendedSareqListSettings[15]" id="columnbutton9" class="button-gray" (click)="onToggleShowExtendedSareqListSettings(15, true)" >
                        {{"OVERRIDE" | translate}}
                      </button>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <span>{{"NUMBER_OF" | translate}}:{{' '+displayedProductsFiltered.length+' ( '+totalAmount+' ) '}}</span>
                </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortItem('name');">{{"NAME" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('alternative_name');">{{"ALTERNATIVE_NAME" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('supplier_name');">{{"MANUFACTURERS" | translate}}</th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[2]" (mouseenter)="showMinus2 = true" (mouseleave)="showMinus2 = false" st-sort="sds_file_id">{{"SDS/GRB"}}
                  <sup>
                    <img *ngIf="showMinus2==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(2, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" *ngIf="extendedSareqListSettings[3]" (mouseenter)="showMinus3 = true" (mouseleave)="showMinus3 = false">{{"SDS"}} {{"VERSION" | translate}}
                  <sup>
                    <img *ngIf="showMinus3==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(3, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('rework_date');" *ngIf="extendedSareqListSettings[4]" (mouseenter)="showMinus4 = true" (mouseleave)="showMinus4 = false">{{"SDS"}} {{"REVISION_DATE" | translate}}
                  <sup>
                    <img *ngIf="showMinus4==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(4, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('cdoc_date');" *ngIf="extendedSareqListSettings[6]">{{"CDOC"}} {{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('status');" *ngIf="extendedSareqListSettings[7]">{{"STATUS" |translate}}</th>
                <th class="table-row-header" (click)="sortItem('assigned');" *ngIf="extendedSareqListSettings[8]">{{"RESPONSIBLE" | translate}}</th>
                <th class="table-row-header" (click)="sortItem('hidden');" *ngIf="extendedSareqListSettings[12]" (mouseenter)="showMinus5 = true" (mouseleave)="showMinus5 = false">{{"HIDDEN" | translate}}
                  <sup>
                    <img *ngIf="showMinus5==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(12, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('supplier_article_number');" (mouseenter)="showMinus7 = true" (mouseleave)="showMinus7 = false" *ngIf="extendedSareqListSettings[10]">{{"MANUFACTURER_ARTICLE_NUMBER" | translate}}
                  <sup>
                    <img *ngIf="showMinus7==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(10, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('revision_comment');" (mouseenter)="showMinus8 = true" (mouseleave)="showMinus8 = false" *ngIf="extendedSareqListSettings[13]">{{"REVCOMMENT" | translate}}
                  <sup>
                    <img *ngIf="showMinus8==true" src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(13, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('ufi');" *ngIf="extendedSareqListSettings[14]">{{"UFI" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(14, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('overridename');" *ngIf="extendedSareqListSettings[15]">{{"OVERRIDE" | translate}}
                  <sup>
                    <img src="images/icons/minus-square-svgrepo-com.svg" aria-hidden="true" (click)="onToggleShowExtendedSareqListSettings(15, false)" class="w-4 h-4">
                  </sup>
                </th>
                <th class="table-row-header" (click)="sortItem('returcomment');">
                  {{"RETURCOMMENT" | translate}}
                </th>
                <th (click)="sortItem('h_phrases')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"H_PHRASES" | translate}}
                </th>
                <th (click)="sortItem('cas')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"CAS" | translate}}
                </th>
                <th (click)="sortItem('risk')" class="table-row-header" *ngIf="showAdvanced==true">
                  {{"RISK_LEVEL" | translate}}
                </th>
                <th (click)="sortItem('esdb_available')" class="table-row-header"  *ngIf="showAdvanced==true">
                  {{"SAFETY_EXT_DATA_SHEET" | translate}}
                </th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedProductsFiltered | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
              <td class="table-row-column" *ngIf="extendedSareqListSettings[0]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <span class="flex flex-row">
                  <img *ngIf="product.high_prio==1"  class="w-6 h-6 mr-1" tooltips tooltip-smart="true" title="{{'HIGH_PRIO_MESSAGE' | translate}}" src="/images/icons/high-priority-svgrepo-com.svg" area-hidden="true" />
                  <img *ngIf="product.lang==1" class="w-4 h-4 mr-1" src="/images/icons/flag-gb-svgrepo-com.svg" alt="Flag">
                  <img *ngIf="product.lang==2" class="w-4 h-4 mr-1" src="/images/icons/flag-fi-svgrepo-com.svg" alt="Flag">{{product.name}}
                </span>
              </td>
              <td class="table-row-column" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.alternative_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[1]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }" (click)="sareqSelected(product)">{{product.supplier_name}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[2]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">
                <div class="flex flex-row">
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.sds_file_id && product.sds_file_id != '' && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                  <img class="w-10 h-10 -mr-3" *ngIf="product.risk_id&&product.risk_id!=''&&product.risk_prio>-1&&product.risk_prio<6" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                </div>
              </td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[3]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.version}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[4]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.rework_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[6]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.cdoc_date | date:"yyyy/MM/dd"}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[7]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{"SAREQ_STATUS_" + product.status | translate}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[8]" (click)="sareqSelected(product)" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.assigned}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[12]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product?.hidden==1?getValueHidden(1):getValueHidden(0)}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[10]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.supplier_article_number}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[13]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.revision_comment}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[14]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.ufi}}</td>
              <td class="table-row-column" *ngIf="extendedSareqListSettings[15]" [ngStyle]="{'background-color':product.hidden == 1 ? '#ff9999' : '' }">{{product.overridename}}</td>
              <td class="table-row-column">{{product.returcomment}}</td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let hphrases of product.h_phrases">
                  {{hphrases}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span *ngFor="let cas of product.cas">
                  {{cas}}
                </span>
              </td>
              <td class="table-row-column" *ngIf="showAdvanced==true">
                <span> {{product.risk_prio}}</span>
              </td>
              <td *ngIf="showAdvanced==true" class="table-row-column">
                <span *ngIf="product.esdb_available===1">{{"YES" | translate}}</span>
                <span *ngIf="product.esdb_available!==1">{{"NO" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
  </div>
</div>

