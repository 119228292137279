
<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="overflow-auto mb-40 md:mb-10">
  <div class="flex flex-col min-h-screen border border-gray-300">
    <div class="flex flex-row justify-left">
      <div class="flex flex-col w-1/2 md:w-1/2 py-4">
        <button class="px-4" title="{{'BACK' | translate}}" (click)="goToProductList()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
          </svg>
        </button>
      </div>
      <div class="flex flex-col w-1/3 md:w-2/3 py-4">
        <div *ngIf="departmentList && (departmentList.length > 0)" class="relative inline-block text-left w-full" appDropdown>
          <button type="button"class="text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span class="text-sm">{{"CHANGE_PRODUCT_DEPARTMENT" | translate}}</span>
              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </button>
          <div class="hidden dropdown-menu right-1 overflow-y-auto max-h-[600px] md:left-1 z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
              <div class="py-1">
                  <div *ngFor="let department of departmentList"
                    class="dropdown-menu-item"
                    (click)="changeDepartment(department)"
                    [ngClass]="{'current': department.id === selectedDepartment.id}"
                    tabindex="1"><span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end py-4 mr-4">
        <a *ngIf="currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
          currentUser.is_useradmin || currentUser.is_super_user)" (click)="editProduct()" title="{{'EDIT' | translate}}" class="dropdown-menu-help-item">
          <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#000000"/>
            </svg>
        </a>
        <a *ngIf="subscribesToLabels" (click)="orderLabels()" title="{{'PRINT_LABELS' | translate}}" class="dropdown-menu-help-item ml-1">
            <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 16.75H16C15.8011 16.75 15.6103 16.671 15.4697 16.5303C15.329 16.3897 15.25 16.1989 15.25 16C15.25 15.8011 15.329 15.6103 15.4697 15.4697C15.6103 15.329 15.8011 15.25 16 15.25H18C18.3315 15.25 18.6495 15.1183 18.8839 14.8839C19.1183 14.6495 19.25 14.3315 19.25 14V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H6C5.66848 8.75 5.35054 8.8817 5.11612 9.11612C4.8817 9.35054 4.75 9.66848 4.75 10V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H8C8.19891 15.25 8.38968 15.329 8.53033 15.4697C8.67098 15.6103 8.75 15.8011 8.75 16C8.75 16.1989 8.67098 16.3897 8.53033 16.5303C8.38968 16.671 8.19891 16.75 8 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V10C3.25 9.27065 3.53973 8.57118 4.05546 8.05546C4.57118 7.53973 5.27065 7.25 6 7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V14C20.75 14.7293 20.4603 15.4288 19.9445 15.9445C19.4288 16.4603 18.7293 16.75 18 16.75Z" fill="#000000"/>
                <path d="M16 8.75C15.8019 8.74741 15.6126 8.66756 15.4725 8.52747C15.3324 8.38737 15.2526 8.19811 15.25 8V4.75H8.75V8C8.75 8.19891 8.67098 8.38968 8.53033 8.53033C8.38968 8.67098 8.19891 8.75 8 8.75C7.80109 8.75 7.61032 8.67098 7.46967 8.53033C7.32902 8.38968 7.25 8.19891 7.25 8V4.5C7.25 4.16848 7.3817 3.85054 7.61612 3.61612C7.85054 3.3817 8.16848 3.25 8.5 3.25H15.5C15.8315 3.25 16.1495 3.3817 16.3839 3.61612C16.6183 3.85054 16.75 4.16848 16.75 4.5V8C16.7474 8.19811 16.6676 8.38737 16.5275 8.52747C16.3874 8.66756 16.1981 8.74741 16 8.75Z" fill="#000000"/>
                <path d="M15.5 20.75H8.5C8.16848 20.75 7.85054 20.6183 7.61612 20.3839C7.3817 20.1495 7.25 19.8315 7.25 19.5V12.5C7.25 12.1685 7.3817 11.8505 7.61612 11.6161C7.85054 11.3817 8.16848 11.25 8.5 11.25H15.5C15.8315 11.25 16.1495 11.3817 16.3839 11.6161C16.6183 11.8505 16.75 12.1685 16.75 12.5V19.5C16.75 19.8315 16.6183 20.1495 16.3839 20.3839C16.1495 20.6183 15.8315 20.75 15.5 20.75ZM8.75 19.25H15.25V12.75H8.75V19.25Z" fill="#000000"/>
            </svg>
        </a>
        <div class="relative inline-block text-left dropdown-menu-help-item ml-1" appDropdown>
            <button title="{{'MENU' | translate}}" id="dropdownBtn" type="button" class="text-black flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <svg class="w-7 h-7 mr-2" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#000000"/>
                    <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#000000"/>
                    <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#000000"/>
                </svg>
            </button>
              <div id="dropdownMenu" class="hidden dropdown-menu right-10" #dropdownMenuInside>
                  <div class="py-2">
                    <a *ngIf="subscribesToRiskAssessment && allDepartmentsChoosen!=true" (click)="goToLRB(product)" id="itemProductButton14" class="dropdown-menu-item" tabindex="1">
                      {{"DO_LRB" | translate}}
                    </a>
                    <a *ngIf="subscribesToSubstitution && currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                      currentUser.is_useradmin || currentUser.is_super_user)" (click)="sendToSubstitution(product)" id="itemProductButton16" class="dropdown-menu-item" tabindex="3">
                        {{"SEND_TO_SUBSTITUTION" | translate}}
                    </a>
                    <a *ngIf="subscribesToStamping && currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                      currentUser.is_useradmin || currentUser.is_super_user)" (click)="uploadStampedFile()" id="itemProductButton17" class="dropdown-menu-item" tabindex="4">
                        {{"ADDSTAMPING" | translate}}
                    </a>
                    <a *ngIf="subscribesToProductAnalysis && allDepartmentsChoosen!=true &&
                      ((currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)"
                      (click)="goToRiskAnalys()" id="itemProductButton18" class="dropdown-menu-item" tabindex="6">
                        {{"RISK_ANALYSIS" | translate}}
                    </a>
                  </div>
              </div>
          </div>
    </div>
    </div>

    <div *ngIf="!loadingData && product" class="flex flex-row px-4 w-full py-2 mb-2 bg-cdocbanner">
      <div class="w-2/3 mt-2">
        <div class="flex flex-col">
          <p class="text-gray-200"><a (click)="showManufacturerInformation(product)" id="itemProductButton1"><strong>{{product.supplier_name}}</strong></a></p>
          <span class="text-white text-2xl font-bold mr-4">{{product?.product_name}}</span>
          <span *ngIf="product?.alternative_name" class="text-white font-bold">{{"ALTERNATIVE_NAME2" | translate}}: {{product.alternative_name}}</span>
          <span *ngIf="product?.ufi" class="text-white font-bold text-sm mt-1">{{"UFI" | translate}}: {{product?.ufi}}</span>
          <span *ngIf="product?.excluded" class="text-white font-bold text-sm mt-1">{{"EXCLUDED" | translate}}{{' '}}{{product?.excluded_comment}}</span>
          <label *ngIf="product?.replaced_by" class="bg-yellow rounded w-40 px-6 py-2 text-white mt-2">{{'Ersättare finns'}}</label>
          <label *ngIf="substitution!=null" class="bg-red-500 rounded w-60 px-6 py-2 font-bold text-white mt-2">{{ this.product.substitutionstatus }} substitution</label>
        </div>
      </div>
      <div *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_risk" class="hidden md:block w-1/5 text-right">
        <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==1"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_1_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==2"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_2_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product.LRB_risk&&product.LRB_risk==3"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_3_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==4"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_4_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==5"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_5_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
      </div>
      <div *ngIf="!subscribesToRiskAssessment||(product&&!product.LRB_risk)" class="hidden md:block w-1/5 text-right">
        <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==1"
              tooltips tooltip-smart="true" title="{{'RISK_1_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==2"
              tooltips tooltip-smart="true" title="{{'RISK_2_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==3"
              tooltips tooltip-smart="true" title="{{'RISK_3_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==4"
              tooltips tooltip-smart="true" title="{{'RISK_4_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==5"
              tooltips tooltip-smart="true" title="{{'RISK_5_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
      </div>
      <div class="w-2/6 mt-2">
        <strong class="text-white">{{"AVAILABLE_LANGUAGES" | translate}}</strong>
        <ul>
            <li *ngFor="let langs of availableLangs">
                <a class="text-brightblue cursor-pointer" (click)="goOtherLang(langs)" [ngClass]="availableLangs&&(availableLangs?.length==1||currentLanguage==langs.lang)?'active':'inactive'" id="itemProductButton2">{{"LANGUAGE_" + langs.lang | translate}}</a>
            </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col md:flex-row w-full px-4 py-2 mb-2">
      <div class="flex flex-row w-full md:w-1/2">
        <div class="flex flex-col w-1/3 text-sm">
          <span class="text-gray-500 text-lg font-bold">{{"DEPARTMENT" | translate}}</span>
          <span class="break-words text-sm italic mb-4" *ngIf="!allDepartmentsChoosen||subDepartmentsExist==false">{{selectedDepartment?.name}}</span>
          <span class="break-words text-xs italic mb-4" *ngIf="allDepartmentsChoosen&&subDepartmentsExist==true">{{"ALL_DEPARTMENTS_TEXT" | translate}}

          </span>
        </div>
        <div *ngIf="product" class="flex flex-col w-full text-sm px-4">
          <span class="text-gray-500 text-lg font-bold">{{"DESCRIPTION" | translate}}</span>
          <span class="break-words italic" *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product?.LRB_usage_area && product?.LRB_usage_area !== '')">{{product.LRB_usage_area}}</span>
          <span class="break-words italic" *ngIf="!(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_usage_area && product.LRB_usage_area !== '')">{{product.com_use}}</span>
        </div>
      </div>
      <div *ngIf="product" class="w-full md:w-1/2 flex flex-row flex-wrap px-4">
        <div *ngIf="( subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_cdoc_comment && product.LRB_cdoc_comment !== '')" class="flex flex-col text-sm w-full md:w-2/3">
          <span class="text-gray-500 text-lg font-bold">{{"COMMENTS" | translate}}</span>
          <span class="break-words text-sm italic mb-4">{{product?.LRB_cdoc_comment}}</span>
        </div>
        <div *ngIf="((product?.department_info != '') || (product?.observation != ''))" class="flex flex-col w-full md:w-1/3">
          <span class="text-gray-500 text-lg font-bold">{{"OBSERVATION" | translate}}</span>
          <span class="break-words text-sm italic">{{product?.observation}}</span>
          <span *ngIf="( product && product.department_info && product.department_info !== '')" class="break-words text-sm italic">{{product?.department_info}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!loadingData && product" class="flex flex-col md:flex-row w-full px-2 py-2 mb-4 bg-white">
      <div class="md:w-1/5 mt-2 mr-2">
        <table class="table-auto mb-4 w-full" id="cataloguetable">
          <tbody>
            <tr>
              <td *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_risk" class="table-row-column"><span class="text-sm">{{'LOCAL_RISK_LEVEL' | translate}}: <strong>{{product.LRB_risk}}</strong></span></td>
              <td *ngIf="!subscribesToRiskAssessment || aggregatedLevel==true || !product.LRB_risk" class="table-row-column"><span class="text-sm">{{'GENERAL_RISK_LEVEL' | translate}}: <strong>{{product.risk_prio ? product.risk_prio : "MISSING" | translate}}</strong></span></td>
            </tr>
            <tr *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_risk">
              <td class="table-row-column"><span class="text-sm">{{'GENERAL_RISK_LEVEL' | translate}}: <strong>{{product.risk_prio ? product.risk_prio : "MISSING" | translate}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf = "allDepartmentsChoosen == true" class="table-row-column">
                <span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}:{{' '}}</span>
                <ng-container *ngIf="productViewAllDepartments.amount_volume">
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_volume)}}{{' '}}{{'l'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.amount_weight">
                  <ng-container *ngIf="productViewAllDepartments.amount_volume">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_weight)}}{{' '}}{{'kg'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.amount_other">
                  <ng-container *ngIf="productViewAllDepartments.amount_volume || productViewAllDepartments.amount_weight">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_other)}}</strong></span>
                </ng-container>
              </td>
              <td *ngIf="allDepartmentsChoosen != true && !product.amount" class="table-row-column"><span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="allDepartmentsChoosen != true && product.amount" class="table-row-column"><span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}: <strong>{{showSwedishDecimalComma(product.amount)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf = "allDepartmentsChoosen == true" class="table-row-column">
                <span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}:{{' '}}</span>
                <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume">
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_volume)}}{{' '}}{{'l'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.annual_consumption_weight">
                  <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_weight)}}{{' '}}{{'kg'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.annual_consumption_other">
                  <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume || productViewAllDepartments.amount_weight">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_other)}}</strong></span>
                </ng-container>
              </td>
              <td *ngIf="allDepartmentsChoosen != true && !product.annual_consumption" class="table-row-column"><span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="allDepartmentsChoosen != true && product.annual_consumption" class="table-row-column"><span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}: <strong>{{showSwedishDecimalComma(product.annual_consumption)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf="!product.maximum_amount" class="table-row-column"><span class="text-sm">{{'MAXIMUM_AMOUNT' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="product.maximum_amount" class="table-row-column"><span class="text-sm">{{'MAXIMUM_AMOUNT' | translate}}: <strong>{{showSwedishDecimalComma(product.maximum_amount)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td class="table-row-column" *ngIf = "allDepartmentsChoosen != true"><span class="text-sm">{{'PRODUCT_GROUP' | translate}}: <strong>{{product.group_name ? product.group_name : "MISSING" | translate}}</strong></span></td>
              <td class="table-row-column" *ngIf="allDepartmentsChoosen == true">
                {{'PRODUCT_GROUP' | translate}}: <strong>
                <span class="text-sm">
                  <span *ngIf="getUniqueGroupNameList(productViewAllDepartments).length > 0; else missing">
                    {{ getUniqueGroupNameList(productViewAllDepartments) }}
                  </span>
                  <ng-template #missing>
                    {{ "MISSING" | translate }}
                  </ng-template>
                </span>
                </strong>
              </td>
            </tr>
            <tr *ngIf="product.CMRA_Category && product.CMRA_Category.length > 0">
              <td class="table-row-column"><span class="text-sm">{{"CMRA_CATEGORY" | translate}}:
                <span class="font-bold" *ngFor="let CMRA_Category_Item of product.CMRA_Category let lastitem = last" tooltips tooltip-smart="true"
                  title="{{getCmraTitleTranslation(CMRA_Category_Item.title)}}">{{CMRA_Category_Item.name}}
                  <span *ngIf="product?.CMRA_Category?.length>1&&!lastitem">{{","}}</span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td *ngIf="product.hygiene_limits" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'HYGIENE_LIMITS' | translate}}: </span>
                  <img title="{{'HYGIENE_LIMITS' | translate}}" class="w-4 h-4" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <td *ngIf="!product.hygiene_limits&&product.no_hygiene_limits" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'HYGIENE_LIMITS' | translate}}: </span>
                  <img title="{{'HYGIENE_LIMITS' | translate}}" class="w-3 h-3" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <div *ngIf="!product.hygiene_limits&&!product.no_hygiene_limits">
                <td class="table-row-column"><span class="text-sm">{{'HYGIENE_LIMITS' | translate}}:</span> <span class="text-sm"><strong>{{"MISSING" | translate}}</strong></span></td>
              </div>
            </tr >
            <tr>
              <td *ngIf="product.allergic&&product.allergic==1" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'ALLERGIC' | translate}}: </span>
                  <img title="{{'ALLERGIC' | translate}}" class="w-4 h-4" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <td *ngIf="product.allergic&&product.allergic==0" class="table-row-column text-lg">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'ALLERGIC' | translate}}: </span>
                  <img title="{{'ALLERGIC' | translate}}" class="w-3 h-3" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <div *ngIf="product.allergic!=1&&product.allergic!=0">
                <td class="table-row-column text-sm"><span class="text-sm">{{'ALLERGIC' | translate}}: <strong>{{"MISSING" | translate}}</strong></span></td>
              </div>
            </tr>
            <tr *ngIf="subscribesToFootprint&&showFootprint==true">
              <td class="table-row-column"><span class="text-sm">{{'FOOTPRINT_TO' | translate}}: <strong>{{footPrintText}}</strong></span></td>
            </tr>
            <tr *ngIf="subscribesToFootprint&&showFootprint==true">
              <td class="table-row-column"><span class="text-sm">{{"EMISSIONS" | translate}} {{"COMMENT" | translate}}: <strong>{{product.o_footprint_comment}}</strong></span></td>
            </tr>
            <tr>
              <td class="table-row-column"><span class="text-sm break-all">{{'ARTICLE_NUMBER' | translate}}:  <strong>{{product.articlenumber ? product.articlenumber : "MISSING" | translate}}</strong></span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="1/2 md:w-1/5 mt-2 mr-2">
        <table class="table-auto bg-white mb-4 w-full" id="cataloguetable">
          <tbody>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToSds && product && product.sds_file_id && product.sds_file_id!='' && product.sds_file_id!=null && ( product.esdb_available == 0 || ( product.esdb_available == 1 && ( product.esdb_file_id && product.esdb_file_id!='')))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_DATA_SHEET" | translate}}
                  </div>
                </td>
                <td class="table-row-column" *ngIf="subscribesToSds && product && product.sds_file_id && product.sds_file_id!='' && product.sds_file_id!=null && ( product.esdb_available == 1 && ( !product.esdb_file_id || product.esdb_file_id==''))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_EXT_DATA_SHEET" | translate}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToSds && product && !product.sds_file_id && product.sds_file_id=='' || product.sds_file_id!=null && ( product.esdb_available == 1 && ( product.esdb_file_id || product.esdb_file_id!=''))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_EXT_DATA_SHEET" | translate}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && subscribesToGrb && product && product?.LRB_id && product?.LRB_id!='' && product?.LRB_id!=null && product?.LRB_status == 3">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openLrbInNewWindow(product)" id="itemProductButton14">
                    <img title="LRB" class="w-4 h-8 mr-1" id="LRBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"LOCAL_RISK_ASSESSMENT" | translate}}
                  </div>
                </td>
                <td class="table-row-column" *ngIf="product.risk_id && ( !subscribesToRiskAssessment || aggregatedLevel==true || (subscribesToGrb && product && !( product?.LRB_id && product?.LRB_id!='' && product?.LRB_id!=null && product?.LRB_status == 3)))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openGrbInNewWindow(product)" id="itemProductButton8">
                    <img title="GRB" class="w-4 h-8 mr-1" id="GRBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"GENERAL_RISK_ASSESSMENT" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToStamping && product && product.sds_stamp_id&&showStampedDocument">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openStampedSdsInNewWindow(product)" id="itemProductButton12">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"STAMPED_SDS" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToSubstitution && product && product.substitutionid && product.substitutionfileid">
                <td class="table-row-column">
                  <button (click)="openSubstitutionInNewWindow(product.substitutionid)" [disabled]="loadingsub" [class.cursor-not-allowed]="loadingsub">
                    <ng-container *ngIf="!loadingsub; else loading">
                      <div class="flex items-center">
                        <img title="{{'SUBSTITUTION' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SUBSTITUTION" | translate}}
                      </div>
                    </ng-container>
                    <ng-template #loading>
                      <div class="flex items-center">
                        <svg class="w-4 h-8 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{"SUBSTITUTION" | translate}}
                      </div>
                    </ng-template>
                  </button>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="printTransportProduct()" id="itemProductButton3">
                    <img title="Transport" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"PRINT_TRANSPORT_INFO" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="printCargoDeclaration()" id="itemProductButton4">
                    <img title="cargo" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"PRINT_CARGODECLARATION_PDF" | translate}}
                    <sup><img title="pdf" class="w-4 h-4" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" /></sup>
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport&&product&&product.transport_un_file_id">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openTransportDocumentInNewWindow(product)" id="itemProductButton6">
                    <img title="Transport" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"DOWNLOAD_PACKAGEINSTRUCTION" | translate}}
                  </div>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="subscribesToSpecialDemands" class="1/1 md:w-1/5 mt-2 mr-2">
        <table class="table-auto bg-white mb-4 w-full">
          <thead>
            <tr>
              <td class="table-row-column">
                <div class="flex flex-row items-center">
                  <span class="text-sm font-bold">{{"SPECIAL_REQUIREMENTS" | translate}} </span>
                  <sup><img class="w-4 h-4" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" tooltips tooltip-smart="true" title="{{'SPECIAL_REQUIREMENTS_MESSAGE' | translate}}" /></sup>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="product.training">
              <td class="table-row-column"><span class="text-sm">{{'TRAINING' | translate}}: {{product.training}}</span></td>
            </tr>
            <tr *ngIf="product.health_control">
              <td class="table-row-column"><span class="text-sm">{{'HEALTH_CONTROL' | translate}}: {{product.health_control}}</span></td>
            </tr>
            <tr *ngIf="product.information">
              <td class="table-row-column"><span class="text-sm">{{'INFORMATION' | translate}}: {{product.information}}</span></td>
            </tr>
            <tr *ngIf="product.fitness_for_duty">
              <td class="table-row-column"><span class="text-sm">{{'FITNESS_FOR_DUTY' | translate}}: {{product.fitness_for_duty}}</span></td>
            </tr>
            <tr *ngIf="product.no_special_demands">
              <td class="table-row-column"><span class="text-sm">{{'NO_SPECIAL_DEMANDS' | translate}}: {{product.no_special_demands}}</span></td>
            </tr>
            <tr *ngIf="product.LRB_id">
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==1' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'YES' | translate}}</span></td>
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==0' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'NO' | translate}}</span></td>
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==2' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'NOT_ASSESSED' | translate}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="1/2 md:w-1/5 mt-2">
        <table class="table-auto bg-white mb-4 w-full">
          <thead>
            <tr>
              <td class="table-row-column">
                <div class="flex flex-row items-center">
                  <span class="text-sm font-bold">{{"RESTRICTION_LISTS" | translate}}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="product.list_reach_14">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_14' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_reach_17">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_17' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_reach_candidate">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_CANDIDATE' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_chem_prio_p">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_CHEM_PRIO_P' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_chem_prio_u">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_CHEM_PRIO_U' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_water_directive">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_WATER_DIRECTIVE' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_amv_a">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_AMV_A' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_amv_a">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_AMV_B' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToSIN&&product.list_sin">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_SIN' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToSLL&&product.list_sll">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_SLL' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToBASTA&&product.list_basta">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_BASTA' | translate}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="picture != null" class="md:w-1/5 mt-2 ml-2 mr-2 flex justify-center">
        <img class="max-w-1/5 h-auto" [src]="picture" alt="Responsive image" id="picture" />
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between px-4 w-full py-2 mb-4">
      <div class="flex flex-row">
        <span *ngFor="let pictogram of product?.pictograms" tooltips tooltip-smart="true" title="{{pictogram.description}}">
          <img title="{{pictogram.description}}" src="/images/pictograms/{{pictogram.filename}}-small.png" />
        </span>
        <span *ngFor="let protective of product?.protectiveArray" tooltips tooltip-smart="true" title="{{protective.description}}">
          <img title="{{protective.description}}" src="/images/protective/{{protective.filename}}-small.png" />
        </span>
      </div>
      <div *ngIf="!loadingData && product && subscribesToReportEnvironment" class="flex flex-col px-4 mb-4 mt-4 md:mt-0">
        <span class="font-bold">{{"ECOLABELS" | translate}}</span>
        <div class="flex flex-row">
          <img title="ecolabel" class="h-10 w-10 mr-1" *ngIf="product.eco_eu" src="/images/loggos/ecolabel-logo.png" />
          <img title="eco_svanen" *ngIf="product.eco_svanen" class="h-10 w-10 mr-1" src="/images/loggos/SE_Svanen_A_POS_RGB.png" />
          <img title="eco_GEC" *ngIf="product.eco_GEC" class="h-10 w-10 mr-1" src="/images/loggos/gec.jpg" />
          <div title="eco_eu" *ngIf="!product.eco_eu && !product.eco_svanen && !product.eco_GEC"> - {{"MISSING" | translate}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="!loadingData" class="flex flex-col max-w-screen bg-cdocbanner mb-20">
      <div class="container">
        <ul class="flex flex-col md:flex-row">
          <li class="mr-1">
              <a (click)="showFirstHelp()" [class.active]="isFirstHelp" id="itemProductButton19" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold active:font-bold cursor-pointer">
                {{"FIRST_AID" | translate}}
              </a>
          </li>
          <li class="mr-1">
            <a (click)="showProtective()" [class.active]="isProtective" id="itemProductButton20" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"PROTECTIVE_GEAR" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showHandling()" [class.active]="isHandling" id="itemProductButton39" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"HANDLING" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showWaste()" [class.active]="isWaste" id="itemProductButton40" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"EMISSIONS" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showFire()" [class.active]="isFire" id="itemProductButton41" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"FIRE" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showphrases()" [class.active]="isphrases" id="itemProductButton21" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"RISK_PHRASES" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToPHClassification" class="mr-1">
            <a (click)="showPhClassification()" [class.active]="isPhClassification" id="itemProductButton22" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"PhValueAndDosage" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showMixtures()" [class.active]="isMixtures" id="itemProductButton25" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"MIXTURES" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToTransport" class="mr-1">
            <a (click)="showTransport()" [class.active]="isTransport" id="itemProductButton26" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"DANGEROUS_GOODS" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToSpecialDemands" class="mr-1">
            <a (click)="showSpecialDemands()" [class.active]="isSpecialDemand" id="itemProductButton37" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{'SPECIAL_REQUIREMENTS' | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showMisc()" [class.active]="isMisc" id="itemProductButton39" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{'MISC' | translate}}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="product&&isFirstHelp" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"AT_INHALATION" | translate}}</span>
        <span class="text-gray-700">{{product.ice.inhalation}}</span>
        <span class="font-bold mt-2">{{"AT_SKIN_CONTACT" | translate}}</span>
        <span class="text-gray-700">{{product.ice.skin_contact}}</span>
        <span class="font-bold mt-2">{{"AT_EYE_CONTACT" | translate}}</span>
        <span class="text-gray-700">{{product.ice.eye_contact}}</span>
        <span class="font-bold mt-2">{{"AT_CONSUMPTION" | translate}}</span>
        <span class="text-gray-700">{{product.ice.consumption}}</span>
      </div>
      <div *ngIf="product&&isProtective" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"RESPIRATORY" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_breath_protection_own && product.LRB_breath_protection_own !== '')" class="text-gray-700">{{product.LRB_breath_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel==true) && product && product.LRB_breath_protection_own && product.LRB_breath_protection_own !== ''))" class="text-gray-700">{{product.protection.breath}}</span>
        <span class="font-bold mt-4">{{"HAND_PROTECTION" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_hand_protection_own && product.LRB_hand_protection_own !== '')" class="text-gray-700">{{product.LRB_hand_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_hand_protection_own && product.LRB_hand_protection_own !== ''))" class="text-gray-700">{{product.protection.gloves}}</span>
        <span class="font-bold mt-4">{{"EYE_PROTECTION" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false  && product && product.LRB_eye_protection_own && product.LRB_eye_protection_own !== '')" class="text-gray-700">{{product.LRB_eye_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_eye_protection_own && product.LRB_eye_protection_own !== ''))" class="text-gray-700">{{product.protection.glasses}}</span>
        <span class="font-bold mt-4">{{"PROTECTIVE_CLOTHING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_body_protection_own && product.LRB_body_protection_own !== '')" class="text-gray-700">{{product.LRB_body_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_body_protection_own && product.LRB_body_protection_own !== ''))" class="text-gray-700">{{product.protection.clothes}}</span>
        <span class="font-bold mt-4">{{"PROTECTIVE_AND_HYGIENE_MEASURES" | translate}}</span>
        <span class="text-gray-700">{{product.protection.hygiene}}</span>
      </div>
      <div *ngIf="product&&isHandling" class="flex flex-col bg-white px-4">
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_general_own && product.LRB_general_own !== '')" class="font-bold mt-4">{{"GENERAL" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_general_own && product.LRB_general_own !== '')" class="text-gray-700">{{product.LRB_general_own}}</span>
        <span class="font-bold mt-4">{{"HANDLING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_handling_own && product.LRB_handling_own !== '')" class="text-gray-700">{{product.LRB_handling_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_handling_own && product.LRB_handling_own !== ''))" class="text-gray-700">{{product.handling}}</span>
        <span class="font-bold mt-4">{{"STORAGE" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_storage_own && product.LRB_storage_own !== '')" class="text-gray-700">{{product.LRB_storage_own}}</span>
        <span *ngIf="!((subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_storage_own && product.LRB_storage_own !== ''))" class="text-gray-700">{{product.handling_storage}}</span>
        <span class="font-bold mt-4">{{"WASTE_HANDLING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_waste_own && product.LRB_waste_own !== '')" class="text-gray-700">{{product.LRB_waste_own}}</span>
        <span *ngIf="!((subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_waste_own && product.LRB_waste_own !== ''))" class="text-gray-700">{{product.handling_waste}}</span>
      </div>
      <div *ngIf="product&&isWaste" class="flex flex-col bg-white px-4">
        <span *ngIf="(product && product.handling_emissions && product.handling_emissions !== '')" class="font-bold mt-4">{{"WASTE_HANDLING_2" | translate}}</span>
        <span *ngIf="(product && product.handling_emissions && product.handling_emissions !== '')" class="text-gray-700">{{product.handling_emissions}}</span>
      </div>
      <div *ngIf="product&&isFire" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"FIRE_MEASURES" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_fire_own && product.LRB_fire_own !== '')" class="text-gray-700">{{product.LRB_fire_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_fire_own && product.LRB_fire_own !== ''))" class="text-gray-700">{{product.handling_fire_measures}}</span>
        <span class="font-bold mt-4">{{"EXTINGUISHING_AGENT" | translate}}</span>
        <span class="text-gray-700">{{product.fire_ext_agent}}</span>
        <span class="font-bold mt-4">{{"UNSUITABLE_EXTINGUISHING_AGENT" | translate}}</span>
        <span class="text-gray-700">{{product.fire_uns_ext_agent}}</span>
        <span class="font-bold mt-4">{{"COMBUSTION_PRODUCTS" | translate}}</span>
        <span class="text-gray-700">{{product.combustion_products}}</span>
      </div>
      <div *ngIf="product&&isphrases" class="flex flex-col bg-white px-4">
        <div class="flex flex-col md:flex-row mt-4">
          <span class="w-full md:w-1/2" *ngIf="product?.phrases?.H&&product?.phrases?.H?.length>0">
            <span class="font-bold mt-4">{{"H_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of get_unique_items(product.phrases.H)">
                    <td class="table-row-column">{{phrase}}</td>
                    <td class="table-row-column">{{"PHRASE_" + phrase | translate}}</td>
                </tr>
            </table>
          </span>
          <span class="w-full md:w-1/2" *ngIf="product?.phrases?.P&&product?.phrases?.P?.length>0">
            <span class="font-bold mt-4">{{"P_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of get_unique_items(product.phrases.P)">
                    <td class="table-row-column">{{phrase}}</td>
                    <td class="table-row-column">{{"PHRASE_" + phrase | translate}}</td>
                </tr>
            </table>
          </span>
        </div>
        <div class="flex flex-col md:flex-row mt-4">
          <span class="w-full md:w-1/2" *ngIf="product?.c_phrases&&product?.c_phrases?.length>0">
            <span class="font-bold mt-4">{{"C_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of product.c_phrases">
                    <td class="table-row-column">{{phrase.c_phrase}}</td>
                    <td class="table-row-column"></td>
                </tr>
            </table>
          </span>
          <span class="w-full md:w-1/2 font-bold mt-2">{{"OTHER_HAZARDS" | translate}}
            <p>{{product.other_dangers}}</p>
          </span>
        </div>
      </div>
      <div *ngIf="isPhClassification" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"PH_CLASSIFICATION" | translate}}</span>
        <span class="text-gray-700">{{product.classification.name}}</span>
        <span class="font-bold mt-4">{{"PH_VALUE" | translate}}</span>
        <span class="text-gray-700">{{product.ph_value}}</span>
        <span class="font-bold mt-4">{{"DOSERING" | translate}}</span>
        <span class="text-gray-700">{{product.dosering}}</span>
      </div>
      <div *ngIf="isMixtures" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{'MIXTURES' | translate}} / {{'SUBSTANCES' | translate}}</span>
        <div class="overflow-x-auto">
          <table class="table-auto mt-4">
              <tr>
                  <th class="table-row-header">{{'NAME' | translate}}</th>
                  <th class="table-row-header">{{'EG' | translate}}</th>
                  <th class="table-row-header">{{'CAS' | translate}}</th>
                  <th class="table-row-header">{{'H_PHRASES' | translate}}</th>
                  <th class="table-row-header">{{'RESTRICTION_LISTS' | translate}}</th>
                  <th class="table-row-header">{{'CONCENTRATION' | translate}}</th>
                  <th class="table-row-header">{{'HYGIENE_LIMITS' | translate}}</th>
                  <th class="table-row-header" *ngIf="subscribesToPop">{{'LIST_POP_SHORT' | translate}}</th>
                  <th class="table-row-header" *ngIf="subscribesToRohs">{{'LIST_ROHS_SHORT' | translate}}</th>
              </tr>
              <tr *ngFor="let mixture of product.mixtures">
                  <td class="table-row-column">{{mixture.name}}</td>
                  <td class="table-row-column">{{mixture.eg}}</td>
                  <td class="table-row-column">{{mixture.cas}}</td>
                  <td class="table-row-column">{{mixture.hphrases}}</td>
                  <td class="table-row-column">{{getRestriktionList(mixture)}}</td>
                  <td class="table-row-column">{{getPercent(mixture)}}</td>
                  <td class="table-row-column">{{getHGV(mixture)}}</td>
                  <td class="table-row-column" *ngIf="subscribesToPop"><span class="text-red-500" *ngIf="mixture.list_pop==1">X</span></td>
                  <td class="table-row-column" *ngIf="subscribesToRohs"><span class="text-red-500" *ngIf="mixture.list_rohs==1">X</span></td>
              </tr>
              <tr *ngFor="let substance of product.substances">
                <td class="table-row-column">{{substance.name}}</td>
                <td class="table-row-column">{{substance.eg}}</td>
                <td class="table-row-column">{{substance.cas}}</td>
                <td class="table-row-column">{{substance.hphrases}}</td>
                <td class="table-row-column">{{getRestriktionList(substance)}}</td>
                <td class="table-row-column">{{getPercent(substance)}}</td>
                <td class="table-row-column">{{getHGV(substance)}}</td>
                <td class="table-row-column" *ngIf="subscribesToPop"><span class="text-red-500" *ngIf="substance.list_pop==1">X</span></td>
                <td class="table-row-column" *ngIf="subscribesToRohs"><span class="text-red-500" *ngIf="substance.list_rohs==1">X</span></td>
            </tr>
          </table>
        </div>
      </div>
      <div *ngIf="isTransport&&subscribesToTransport&&!product.adr_not_classfied" class="flex flex-col bg-white px-4">
        <div class="flex flex-col md:flex-row">
          <span class="w-full md:w-1/3 text-2xl font-bold mt-6 items-center">{{"UN_NUMBER" | translate}}: {{product.transport_un_nbr}}</span>
          <div class="w-full md:w-2/3 mt-4">
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_1" src="/images/transport/{{product.transport_label_name_1}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_2" src="/images/transport/{{product.transport_label_name_2}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_3" src="/images/transport/{{product.transport_label_name_3}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_4" src="/images/transport/{{product.transport_label_name_4}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_5" src="/images/transport/{{product.transport_label_name_5}}-small.png" />
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">ADR/ADN/RID</span>
            <span class="flex flex-row font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_name}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"CLASSIFICATION_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_classification_code}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"DANGER_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_danger_code}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"TUNNEL_RESTRICTION_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_tunnel_restr}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"LIMITED_AMOUNTS" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_amount_limit}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ADR_SPECIAL_PROVISIONS" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_special_provision}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"PACKING_GROUP" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_packing}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.adr_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.adr_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.adr_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.adr_bulk_info}}</p>
          </div>
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">ICAO(IATA)</span>
            <span class="font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: </span>
            <p class="font-normal">{{product.iata_name}}</p>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.iata_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"PACKING_GROUP" | translate}}: <p class="font-normal"> &nbsp;{{product.iata_packing}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.iata_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.iata_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.iata_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.iata_bulk_info}}</p>
          </div>
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">IMDG/IMO</span>
            <span class="font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: </span>
            <p class="font-normal">{{product.imdg_name}}</p>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.imdg_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"EMS" | translate}}: <p class="font-normal"> &nbsp;{{product.imdg_ems}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"MARINE_ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.imdg_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.imdg_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.imdg_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.imdg_bulk_info}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="isSpecialDemand&&subscribesToSpecialDemands" class="flex flex-col bg-white px-4">
        <table class="table-auto mt-4 w-2/3">
          <tr>
            <th class="table-row-header">{{"SPECIAL_REQUIREMENTS" | translate}}</th>
            <th class="table-row-header">{{"REQUIRED" | translate}}</th>
            <th class="table-row-header">{{"COMMENT" | translate}}</th>
          </tr>
          <tr *ngIf="product.training">
            <td class="table-row-column">{{"TRAINING" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.training}}</td>
          </tr>
          <tr *ngIf="!product.training">
            <td class="table-row-column">{{"TRAINING" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.health_control">
            <td class="table-row-column">{{"HEALTH_CONTROL" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.health_control}}</td>
          </tr>
          <tr *ngIf="!product.health_control">
            <td class="table-row-column">{{"HEALTH_CONTROL" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.fitness_for_duty">
            <td class="table-row-column">{{"FITNESS_FOR_DUTY" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.fitness_for_duty}}</td>
          </tr>
          <tr *ngIf="!product.fitness_for_duty">
            <td class="table-row-column">{{"FITNESS_FOR_DUTY" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.information">
            <td class="table-row-column">{{"INFORMATION_DEMAND" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.information}}</td>
          </tr>
          <tr *ngIf="!product.information">
            <td class="table-row-column">{{"INFORMATION_DEMAND" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="!(product.no_special_demands==null || product.no_special_demands=='notset')">
            <td class="table-row-column">{{"NO_SPECIAL_DEMANDS" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.no_special_demands}}</td>
          </tr>
          <tr *ngIf="(product.no_special_demands==null || product.no_special_demands=='notset')">
            <td class="table-row-column">{{"NO_SPECIAL_DEMANDS" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
        </table>
      </div>
      <div *ngIf="isMisc" class="flex flex-col md:flex-row bg-white px-4">
        <div class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"DEPARTMENTS" | translate}}</span>
          <ul>
            <li *ngFor="let department of departmentList">
              <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>
            </li>
          </ul>
        <!--      -->
          <span class="font-bold mt-4"
                *ngIf="product.customerarticle||product.customerarticle_file_id">
            {{'PRODUCT_DOKUMENTATION' | translate}}
          </span>
          <div class="flex items-center space-x-2">
            <img *ngIf="product.customerarticle_file_id" (click)="openDocumentInNewWindow(product)" title="{{product.customerarticle_file_name}}" class="w-6 h-6 cursor-pointer" src="/images/icons/pdf-svgrepo-com.svg" aria-hidden="true" />
          </div>

        <!--      -->
        </div>
        <div class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"SAFETY_DATA_SHEET" | translate}} ({{"HISTORY" | translate}})</span>
          <div *ngIf="documentHistory.sds&&documentHistory?.sds?.length > 0">
            <ul>
              <li class="flex flex-row items-center cursor-pointer" *ngFor="let sds of documentHistory.sds | orderBy: 'sds.timestamp'" (click)="openHistoricalSds(sds)">
                <img title="{{'SAFETY_DATA_SHEET' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{sds.timestamp | date:'yyyy-MM-dd'}}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="subscribesToGrb" class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"GENERAL_RISK_ASSESSMENT" | translate}} ({{"HISTORY" | translate}})</span>
          <div *ngIf="documentHistory.grb&&documentHistory?.grb?.length > 0">
            <ul>
              <li class="flex flex-row items-center cursor-pointer" *ngFor="let grb of documentHistory.grb | orderBy: 'grb.timestamp'" (click)="openHistoricalGrb(grb)">
                <img title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{grb.last_changed | date:'yyyy-MM-dd'}}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="activityBasedDataSheets && allDepartmentsChoosen!=true" class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4 mb-2">{{"ACTIVITY_RISK_ASSESSMENTS" | translate}}</span>
          <div *ngIf="activityBasedDataSheets?.length > 0">
            <div *ngFor="let activityBasedDataSheet of activityBasedDataSheets; let i = index">
              <button (click)="openArbInNewWindow(activityBasedDataSheet, i)" [disabled]="loadingStates[i]" class="px-4 mb-2" [class.cursor-not-allowed]="loadingStates[i]">
                <ng-container *ngIf="!loadingStates[i]; else loading">
                  <div class="flex items-center">
                    <img title="{{'ACTIVITY_RISK_ASSESSMENTS' | translate}}" class="w-6 h-6 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{activityBasedDataSheet.heading}}
                  </div>
                </ng-container>
                <ng-template #loading>
                  <div class="flex items-center">
                    <svg class="w-6 h-6 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {{activityBasedDataSheet.heading}}
                  </div>
                </ng-template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
