import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class UtilService {
  departmentChangeClickedCalled:boolean=false;
  getUtilDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private router:Router, private translate: TranslateService,private fileUploadService:FileUploadService) { }

  departFullName(department, listAlldepartments) {
    if (department == '' || typeof(department) == 'undefined' || department == null) {
      return '';
    }

    if (typeof(listAlldepartments) == 'undefined') {
      return '';
    }

    var currentNode;
    var text='';

    // First is with object as input second with string/number
    // name is not an option as there can be many objects with same name
    if (typeof(department) == 'object') {
        currentNode = department;
    } else {
        // With id get object in vm.x
      for (var ii = 0; ii < listAlldepartments?.length; ii++) {
        if (listAlldepartments[ii].id == department) {
          currentNode = listAlldepartments[ii];
          break;
        }
      }
    }

    // Just a second test in case
    if (currentNode == '' || typeof(currentNode) == 'undefined' || currentNode == null) {
      return '';
    }

    // The name of the item im in on
    text += currentNode.name;
    if (currentNode) {
      while (currentNode&&currentNode.parent != 123) {
        // Get parents
        currentNode = Recursive(currentNode.parent);
        if (currentNode) {
          text = currentNode.name + '/' + text;
        } else {
          text = text;
        }
      }
    }
    // This doesnt happen before im finished with the path
    return text;

    function Recursive(id) {
      var listLength = listAlldepartments.length;
      for (var i = 0; i < listLength; i++) {
        if (listAlldepartments[i].id == id) {
          currentNode = listAlldepartments[i];
          return currentNode;
        }
      }
    }
  }

  departmentName(department, listAlldepartments) {
    if (department == '' || typeof(department) == 'undefined' || department == null) {
      return '';
    }

    if (typeof(listAlldepartments) == 'undefined') {
      return '';
    }

    var currentNode;
    var text='';

    // First is with object as input second with string/number
    // name is not an option as there can be many objects with same name
    if (typeof(department) == 'object') {
        currentNode = department;
    } else {
        // With id get object in vm.x
      for (var ii = 0; ii < listAlldepartments.length; ii++) {
        if (listAlldepartments[ii].id == department) {
          currentNode = listAlldepartments[ii];
          break;
        }
      }
    }

    // Just a second test in case
    if (currentNode == '' || typeof(currentNode) == 'undefined' || currentNode == null) {
      return '';
    }

    // The name of the item im in on
    text += currentNode.name;

    // This doesnt happen before im finished with the path
    return text;
  }

  departFullNameHeader(department, listAlldepartments) {
    if (department == '' || typeof(department) == 'undefined' || department == null) {
      return '';
    }

    if (typeof(listAlldepartments) == 'undefined') {
      return '';
    }

    var currentNode;
    var text = [];

    // First is with object as input second with string/number
    // name is not an option as there can be many objects with same name
    if (typeof(department) == 'object') {
        currentNode = department;
    } else {
        // With id get object in vm.x
      for (var ii = 0; ii < listAlldepartments.length; ii++) {
        if (listAlldepartments[ii].id == department) {
          currentNode = listAlldepartments[ii];
          break;
        }
      }
    }

    // Just a second test in case
    if (currentNode == '' || typeof(currentNode) == 'undefined' || currentNode == null) {
      return '';
    }

    // The name of the item im in on
    text.push({"name":currentNode.name, "id":currentNode.id});
    if (currentNode) {
      while (currentNode&&currentNode.parent != 123) {
        // Get parents
        currentNode = Recursive(currentNode.parent);
        if (currentNode) {
          text.push({"name":currentNode.name, "id":currentNode.id});
        } else {
          text = text;
        }
      }
    }
    // If it isnt reversed it will be in the wrong order
    return text.reverse();

    function Recursive(id) {
      var listLength = listAlldepartments.length;
      for (var i = 0; i < listLength; i++) {
        if (listAlldepartments[i].id == id) {
          currentNode = listAlldepartments[i];
          return currentNode;
        }
      }
    }
  }

  departFullNameAndParent(department, listAlldepartments) {
    if (department == '' || typeof(department) == 'undefined' || department == null) {
      return '';
    }

    if (typeof(listAlldepartments) == 'undefined') {
      return '';
    }

    var currentNode;
    var text='';
    var text2;
    var parent='';

    // First is with object as input second with string/number
    // name is not an option as there can be many objects with same name
    if (typeof(department) == 'object') {
        currentNode = department;
    } else {
        // With id get object in vm.x
      for (var ii = 0; ii < listAlldepartments.length; ii++) {
        if (listAlldepartments[ii].id == department) {
          currentNode = listAlldepartments[ii];
          parent=listAlldepartments[ii].parent;
          break;
        }
      }
    }

    // Just a second test in case
    if (currentNode == '' || typeof(currentNode) == 'undefined' || currentNode == null) {
      return '';
    }

    // The name of the item im in on
    text += currentNode.name;
    if (currentNode) {
      while (currentNode&&currentNode.parent != 123) {
        // Get parents
        currentNode = Recursive(currentNode.parent);
        if (currentNode) {
          text = currentNode.name + '/' + text;
        } else {
          text = text;
        }
      }
    }
    text2 = {'text':text,'parent':parent}
    // This doesnt happen before im finished with the path
    return text2;

    function Recursive(id) {
      var listLength = listAlldepartments.length;
      for (var i = 0; i < listLength; i++) {
        if (listAlldepartments[i].id == id) {
          currentNode = listAlldepartments[i];
          return currentNode;
        }
      }
    }
  }

  /**
  * Deserialize data from string to JSON
  */
  deserialize(data) {
    if (data !== undefined && data !== null && data !== "" && typeof(data) != 'undefined') {
      try {
        data = JSON.stringify(data);
      } catch (error) {
        console.log("(UtilService.deserialize): Error parsing data: ", data);
      }
    }
    return data;
  }

  deserializeAndTransformToSingle(data) {
    if (data !== undefined && data !== null) {
      try {
        data = JSON.stringify(data[0])
      } catch (error) {
        console.log("(UtilService.deserializeAndTransformToSingle): Error parsing data: ", data);
      }
    }
    return data;
  }

  /**
  * Deserialize data from string to JSON
  */
  serialize(data) {
    if (data !== undefined && data !== null && data !== "" && typeof(data) != 'undefined') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.log("(UtilService.serialize): Error parsing data: ", data);
      }
    }
    return data;
  }

  /**
  * Check if the current (selected) company is currently subscribing to the service specified by "identifier"
  *
  * @param identifier {string}     - Subscription to check for
  */
  subscribesTo(identifier) {
    var organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    var subscriptionActive = false;
    if (organisation) {
      if (identifier == 'subscription_distribution') {
        if (organisation.subscription_distribution) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_substitution') {
        if (organisation.subscription_substitution) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_purchase') {
        if (organisation.subscription_purchase) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_exposures') {
        if (organisation.subscription_exposures) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_projects') {
        if (organisation.subscription_projects) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_risk_assessment') {
        if (organisation.subscription_risk_assessment) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_checklist') {
        if (organisation.subscription_checklist) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_activities') {
        if (organisation.subscription_activities) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_catalogue') {
        if (organisation.subscription_catalogue) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_stamped_SDS') {
        if (organisation.subscription_stamped_SDS) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_samtec') {
        if (organisation.subscription_samtec) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_qrcode') {
        if (organisation.subscription_qrcode) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_lawpoint') {
        if (organisation.subscription_lawpoint) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_coordinator') {
        if (organisation.subscription_coordinator) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_products') {
        if (organisation.subscription_products) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_reports') {
        if (organisation.subscription_reports) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_sin') {
        if (organisation.subscription_sin) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_sll') {
        if (organisation.subscription_sll) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_basta') {
        if (organisation.subscription_basta) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_special_demands') {
        if (organisation.subscription_special_demands) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_enviroment') {
        if (organisation.subscription_report_enviroment) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_sds') {
        if (organisation.subscription_sds) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_grb') {
        if (organisation.subscription_grb) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_productsheet') {
        if (organisation.subscription_productsheet) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_classification') {
        if (organisation.subscription_classification) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_product_analysis') {
        if (organisation.subscription_product_analysis) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_transport') {
        if (organisation.subscription_transport) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_labels') {
        if (organisation.subscription_labels) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_revise_date') {
        if (organisation.subscription_revise_date) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_samtec') {
        if (organisation.subscription_samtec) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_freemium') {
        if (organisation.subscription_freemium) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_footprint') {
        if (organisation.subscription_footprint) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_simple') {
        if (organisation.subscription_report_simple) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_statutory') {
        if (organisation.subscription_report_statutory) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_fire') {
        if (organisation.subscription_report_fire) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_cmr') {
        if (organisation.subscription_report_cmr) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_notes') {
        if (organisation.subscription_report_notes) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_sds') {
        if (organisation.subscription_report_sds) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_grb') {
        if (organisation.subscription_report_grb) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_classification') {
        if (organisation.subscription_report_classification) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_purchase') {
        if (organisation.subscription_report_purchase) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_sds_activity') {
        if (organisation.subscription_report_sds_activity) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_sll') {
        if (organisation.subscription_report_sll) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_report_allergenic') {
        if (organisation.subscription_report_allergenic) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_education') {
        if (organisation.subscription_education) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_law_aptor') {
        if (organisation.subscription_law_aptor) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_mail_sds') {
        if (organisation.subscription_mail_sds) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_styren') {
        if (organisation.subscription_styren) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_product_application') {
        if (organisation.subscription_product_application) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_list_pop') {
        if (organisation.subscription_list_pop) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_list_rohs') {
        if (organisation.subscription_list_rohs) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_QRLogin') {
        if (organisation.subscription_QRLogin) {
          subscriptionActive = true;
        }
      }

      if (identifier == 'subscription_inventory') {
        if (organisation.subscription_inventory) {
          subscriptionActive = true;
        }
      }
    }

    return subscriptionActive;
  }

  onError(error:any){
    if(error.statusCode==503||error.status==503){
      var status = error.statusCode||error.status;
      Swal({
        title:this.translate.instant("PLEASE_LOGIN_AGAIN"),
        text:this.translate.instant("Authentisering misslyckades"),
        type: "warning",
        showCancelButton: false,
        confirmButtonText: this.translate.instant("Ok"),
        closeOnConfirm: true
      }, function () {
        this.router.navigate(['/login']);
      });
    }
    if(error.statusCode==401||error.status==401){
      var status = error.statusCode||error.status;
      Swal({
        title:this.translate.instant("PLEASE_LOGIN_AGAIN"),
        text:this.translate.instant("LOGGEDOUT_TIME"),
        type: "warning",
        showCancelButton: false,
        confirmButtonText: this.translate.instant("Ok"),
        closeOnConfirm: true
      }, function () {
        this.router.navigate(['/login']);
      });
    }
    if(error.statusCode==401||error.status==401){
      var status = error.statusCode||error.status;
      Swal({
        title:this.translate.instant("PLEASE_LOGIN_AGAIN"),
        text:this.translate.instant("LOGGEDOUT_TIME"),
        type: "warning",
        showCancelButton: false,
        confirmButtonText: this.translate.instant("Ok"),
        closeOnConfirm: true
      }, function () {
        this.router.navigate(['/login']);
      });
    }
  }

  // Sets the header and paramater of an object
  // params the parameters to set (usually a JSON list)
  // subpath the if it should be under root or not, in order to use backend
  // type as a string or as and JSON object
  setHeadersAndParamsObject(params?,subpath?,type?) {
    const accessToken = localStorage.getItem("token");
    const reqData = {
      headers: {
          Authorization: `${accessToken}`,
          Accept: "application/json"
      }
    };
    if(params&&params!=''){
      let reqParams = {};
      if(params) {
        Object.keys(params).map(k =>{
          reqParams[k] = params[k];
        });
      }
      if(type&&type=="s"){
        reqParams=JSON.stringify(reqParams);
      }else{
        reqParams=reqParams;
      }

      if(subpath&&subpath!=""){
        reqData['params']={};
        reqData['params'][subpath] = reqParams;
      }else{
        reqData['params'] = reqParams;
      }
    }
    return reqData;
  }

  /**
  * Try to convert a string to a Javascript Date object.
  *
  * @param dateAsString {string}     - String representation of date to be converted
  *
  * @return Date object representation of dateAsString, null if invalid date or dateAsString is not string
  */
  convertStringToDate(dateAsString) {
    if (_.isString(dateAsString)) {
      dateAsString = new Date(dateAsString);
      if (!_.isDate(dateAsString) || isNaN(dateAsString.getTime())) {
        dateAsString = null;
      }
    } else {
      dateAsString = null;
    }
    return dateAsString;
  }

  adjustDateForTimezoneOffset(date) {
    if (_.isObject(date) && typeof date.getMinutes === "function") {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    } else {
    }
    return date;
  }

  public getUtilDataListener() {
    return this.getUtilDataUpdated.asObservable();
  }

  sortByProperty(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  //Promise to resolve file_id before sending of to save
  uploadFile(file) {
    this.fileUploadService.upload(file);
    this.fileUploadService.getFileDataUpdated.pipe(take(1)).subscribe({
      next: response => {
        if (response == null) {
          this.getUtilDataUpdated.next(null);
        }
        else
        {
          this.getUtilDataUpdated.next(response);
        }
      },
      error: error => {
      }
    })
  }

  // *************************************
  // A function to get subdepartments
  // *** Note that parents are not included
  // list including the startpoint.
  // A recursive function starting from
  // startpoint and everything below
  //
  // indata is an organisation object
  // listofids choice object list or id list
  // outdata is list of ids if listofids true
  // or a list of organisations if listofids false
  // *************************************

  getAllSubdepartments(organisation,listofids){
    let startPoint = organisation;  // Startingpoint, changes with every recursion
    let searchId = [];              // To avoid duplicates
    let idArray=[];                 // The list of Id's
    let allOrganisations=[];        // List of organisation objects
    if(!organisation||!organisation.id){
      return;
    }
    idArray.push(organisation.id);
    allOrganisations.push(organisation);

    function getId (startPoint){
      searchId.push(startPoint.id);
      startPoint.orgs.forEach(element => {
        if(searchId.indexOf(element.id)<0){
          idArray.push(element.id);
          allOrganisations.push(element);
          getId(element);
        }
      });
    }

    // function must be declared before it is called
    // this time inline

    getId(startPoint);

    if(listofids)
    {
      return idArray;
    }
    else
    {
      return allOrganisations;
    }
  }

  getDepartmentObjectFromId(id,organisation){
    var thisId = id;  // where to start
    var startPoint = organisation;
    var found = 'false';
    var objectToFind;
    if(startPoint.id==thisId){
      found = 'true';
      objectToFind = startPoint;
    }

    function getId (startPoint){
      startPoint.orgs.forEach(element => {
        if(element.id==thisId&&found!='true'){
          found = 'true';
          objectToFind = element;
        }
        getId(element);
      });
    }
    getId(startPoint);

    if(found=='true'){
      return objectToFind;
    }
    else
    {
      return null;
    }
  }

  /*
  * When department is changed we want to go to different places depending on where its clicked
  * @param path: string, the url from where it's clicked
  */
  departmentChangeClicked( path:string ) {
    if ( path.includes( '/product/' ) && this.departmentChangeClickedCalled != true ) {
      this.departmentChangeClickedCalled = true;
      // This is done because can be called several times on the same click
      setTimeout( () => {
        this.departmentChangeClickedCalled = false;
      }, 5000 );
      this.router.navigate( [ '/productlist' ] );
    }
  }

  // Function to see if a cookie is set
  // and the timestamp is not expired
  // and the name is valid
  previousSelectedDepartmentIsValid( name ){
    const previousSelectedDepartment = localStorage.getItem('previousSelectedDepartment');
    if (!previousSelectedDepartment) {
      return false;
    }

    try {
      const { department, timestamp, page } = JSON.parse(previousSelectedDepartment);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp < 1 * 60 * 1000 && page === name) {
        return true;
      } else {
        localStorage.removeItem('previousSelectedDepartment');
        return false;
      }
    } catch (error) {
      console.error('Error parsing previousSelectedDepartment:', error);
      localStorage.removeItem('previousSelectedDepartment');
      return false;
    }
  }

  // The input could be a number or a string
  // if toSwedish is true it will convert to swedish format i.e 4,5
  // if toSwedish is false it will convert to english format i.e 4.5
  // With 3 decimals at most, if its less decimals just return the number in the correct format
  convertNumberFormat(value: string | number, toSwedish: boolean): string {

    if (value === null || value === undefined) {
      return ''; // or handle the null/undefined case as needed
    }

    let number: number;
    if (typeof value === 'number') {
      number = value;
    } else {
      number = parseFloat(value.replace(',', '.'));
    }

    if (toSwedish) {
      // Check if the number has more than 3 decimal places
      const decimalPart = number.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 3) {
        // Format to 3 decimal places
        return number.toFixed(3).replace('.', ',');
      } else {
        // Return the number as is, replacing the decimal point with a comma
        return number.toString().replace('.', ',');
      }
    } else {
      const decimalPart = number.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 3) {
        return number.toFixed(3);
      } else {
        return number.toString();
      }
    }
  }

  // ** A usual way to check if a device is mobile
  // ** This is not 100% accurate but it's a good way to check
  // ** This is not used at the moment
  isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
}


