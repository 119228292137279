import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../../auth/auth.service';
import { ManufacturerService } from "src/app/services/manufacturer.service";
import { AssessmentChemicalService } from 'src/app/services/assessment-chemical.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalPrintCargodeclarationComponent } from 'src/app/modals/modal-print-cargodeclaration/modal-print-cargodeclaration.component';
import { ModalEditProductComponent } from 'src/app/modals/modal-edit-product/modal-edit-product.component';
import { ModalUploadFileComponent } from 'src/app/modals/modal-upload-file/modal-upload-file.component';
import { ModalOrderLabelsComponent } from 'src/app/modals/modal-order-labels/modal-order-labels.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SareqService } from "src/app/services/sareq.service";
import { SupportService } from 'src/app/services/support.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticDataService } from "src/app/services/static-data.service";
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.css']
})
export class ProductItemComponent implements OnInit {

  loadingStates: { [key: number]: boolean } = {};
  loadingData: boolean = false;
  loadingButton: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  productId: any = null;
  product_id_backup: any = null;
  currentLanguage: any;
  product: any;
  subscribesToReportEnvironment: any;
  subscribesToSpecialDemands: any;
  subscribesToSubstitution: any;
  subscribesToStamping: any;
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToFootprint: any;
  subscribesToTransport: any;
  subscribesToPHClassification: any;
  subscribesToGrb: any;
  subscribesToRiskAssessment: any;
  listAlldepartments: any;
  subscribesToPop: any;
  subscribesToRohs: any;
  footPrintText: any = "";
  departmentString: any = "";
  showFootprint: any = "";
  picture: any = null;
  thisHeight: any;
  availableLangs: any;
  manufacturer: any;
  subscribesToSds: any;
  subscribesToProductsheet: any;
  subscribesToLabels: any;
  isFirstHelp: boolean = true;
  isProtective: boolean = false;
  isphrases: boolean = false;
  isPhClassification: boolean = false;
  isMixtures: boolean = false;
  isRiskActivity: boolean = false;
  isTransport: boolean = false;
  isSpecialDemand: boolean = false;
  isPhValueAndDosage: boolean = false;
  isHandling: boolean = false;
  isMisc: boolean = false;
  departmentList: any;
  documentHistory: any = null;
  showStampedDocument: boolean = false;
  substitution: any;
  subscribesToProductAnalysis: boolean = false;
  riskfile: any;
  editHealthIndex: boolean = false;
  hi: any = {};
  hiStatusOptionsList: any;
  hiOptionsList1: any;
  hiOptionsList2: any;
  hiOptionsList3: any;
  hiOptionsList4: any;
  hiOptionsList5: any;
  currentFiles: any;
  substitutionThisDepartment:boolean=true;
  showAnalysis0:boolean=false;
  showAnalysis1:boolean=false;
  showAnalysis2:boolean=false;
  showAnalysis3:boolean=false;
  showAnalysis3_1:boolean=false;
  showAnalysis3_2:boolean=false;
  showAnalysis4:boolean=false;
  showAnalysis5:boolean=false;
  activityBasedDataSheets;
  loadingsub: boolean=false;
  showSubdepartments: boolean=false;
  allDepartmentsChoosen: boolean=false;
  departmentIdWhereExists: any;
  productViewAllDepartments: any;

  ngAfterViewInit(): void {
  }

  constructor(private utilService: UtilService,
              private organisationService: OrganisationService,
              private translate: TranslateService,
              private authService: AuthService,
              private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute,
              private manufacturerService: ManufacturerService,
              private modalService: NgbModal,
              private sareqService: SareqService,
              private supportService: SupportService,
              private staticDataService: StaticDataService,
              private substitutionService: SubstitutionService,
              private assessmentService: AssessmentService,
              private assessmentChemicalService: AssessmentChemicalService,
              private formBuilder: FormBuilder) { }

ngOnDestroy(): void{
  if(this.router.url.indexOf('product')!=-1||this.router.url.indexOf('productlist')!=-1)
  {

  }
  else
  {
    localStorage.removeItem('searchFilterEco',);
    localStorage.removeItem('searchFilterRisk',);
    localStorage.removeItem('searchFilterPictogram',);
    localStorage.removeItem('searchFilter',);
  }
}

  ngOnInit(): void {

    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      const url = this.router.url;
      const match = url.match(/product\/([0-9a-fA-F-]{36})/);
      if (match) {
        let tmpProductId = match[1];
        if (tmpProductId == this.productId)
        {
          this.ngOnInit();
        }
      }
    })

    this.route.params.pipe(take(1)).subscribe(params => {
      this.productId = params['id'];

      this.picture = null;

      this.loadingData = true;

      this.product = null;

      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
      localStorage.getItem("showSubdepartments") == "true" ? this.showSubdepartments=true:this.showSubdepartments=false;
      if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
      {
        this.logout();
      }


      var data = localStorage.getItem('currentUser', );
      if (data == null || data == undefined)
      {
        this.logout();
      }
      else
      {
        this.currentUser = JSON.parse(data);
      }

      this.subscribesToReportEnvironment = this.utilService.subscribesTo('subscription_report_enviroment');
      this.subscribesToSpecialDemands = this.utilService.subscribesTo('subscription_special_demands');
      this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
      this.subscribesToStamping = this.utilService.subscribesTo('subscription_stamped_SDS');
      this.subscribesToSIN = this.utilService.subscribesTo('subscription_sin');
      this.subscribesToSLL = this.utilService.subscribesTo('subscription_sll');
      this.subscribesToBASTA = this.utilService.subscribesTo('subscription_basta');
      this.subscribesToFootprint = this.utilService.subscribesTo('subscription_footprint');
      this.subscribesToTransport = this.utilService.subscribesTo('subscription_transport');
      this.subscribesToPHClassification = this.utilService.subscribesTo('subscription_classification');
      this.subscribesToGrb = this.utilService.subscribesTo('subscription_grb');
      this.subscribesToSds = this.utilService.subscribesTo('subscription_sds');
      this.subscribesToProductsheet = this.utilService.subscribesTo('subscription_productsheet');
      this.subscribesToLabels = this.utilService.subscribesTo('subscription_labels');
      this.subscribesToProductAnalysis = this.utilService.subscribesTo('subscription_product_analysis');
      this.subscribesToRiskAssessment = this.utilService.subscribesTo('subscription_risk_assessment');
      this.subscribesToPop = this.utilService.subscribesTo('subscription_list_pop');
      this.subscribesToRohs = this.utilService.subscribesTo('subscription_list_rohs');

      this.getAllDepartments();
      this.getProduct();
      this.getProductDepartments();
      this.getSdsDocHistory();
      this.getActivityBasedDataSheets();
      this.getCdocFiles();
    });
  }

  logout() {
    this.authService.logout();
  }

  getProductWarning(product) {
    product.warnings = [];
    let h_phrases = product.phrases['H'];

    if (h_phrases?.includes("H360") || h_phrases?.includes("H360F") || h_phrases?.includes("H360FD") ||
        h_phrases?.includes("H360D") || h_phrases?.includes("H361") || h_phrases?.includes("H361f") ||
        h_phrases?.includes("H362") || h_phrases?.includes("H360Df") || h_phrases?.includes("H361fd") ||
        h_phrases?.includes("H360FD") || h_phrases?.includes("H360Fd") || h_phrases?.includes("H361d"))
    {
      product.warnings.push("Reproduktion");
    }

    if (h_phrases?.includes("H340") || h_phrases?.includes("H341"))
    {
      product.warnings.push("Mutagen");
    }

    if (h_phrases?.includes("H317") || h_phrases?.includes("H334") || h_phrases?.includes("EUH203") ||
        h_phrases?.includes("EUH204") || h_phrases?.includes("EUH205") || h_phrases?.includes("EUH208"))
    {
      product.warnings.push("Allergi");
    }

    if (h_phrases?.includes("H350") || h_phrases?.includes("H350") || h_phrases?.includes("H350i"))
    {
      product.warnings.push("Cancer");
    }

    if (h_phrases?.includes("H370") || h_phrases?.includes("H371") || h_phrases?.includes("H372") || h_phrases?.includes("H373"))
    {
      product.warnings.push("Organ");
    }

    if (h_phrases?.includes("H400") || h_phrases?.includes("H410") || h_phrases?.includes("H411") ||
        h_phrases?.includes("H412") || h_phrases?.includes("H413") || h_phrases?.includes("H420") ||
        h_phrases?.includes("EUH380") || h_phrases?.includes("EUH381") ||
        h_phrases?.includes("EUH430") || h_phrases?.includes("EUH431") ||
        h_phrases?.includes("EUH440") || h_phrases?.includes("EUH441") ||
        h_phrases?.includes("EUH450") || h_phrases?.includes("EUH451"))
    {
      product.warnings.push("Miljö");
    }

    if (h_phrases?.includes("H300") || h_phrases?.includes("H304") || h_phrases?.includes("H310") || h_phrases?.includes("H330"))
    {
      product.warnings.push("Dödligt");
    }
  }

  goToRiskAnalys() {
    this.router.navigate(['/productrisk', this.productId]);
  }

  getCdocFiles() {
    this.currentFiles = [];
    this.organisationService.getAllcDocFiles();
    this.organisationService.getOrganisationDataDocumentListener().pipe(take(1)).subscribe({
      next: documents => {
        if (documents == null) {
        }
        else {
          this.currentFiles = documents;
          for (var i = 0; i < this.currentFiles.length; i++)
          {
            if (this.currentFiles[i].filename) {
              this.currentFiles[i].filename = this.currentFiles[i].filename.substring(0, this.currentFiles[i].filename.indexOf(".pdf"));
            }
          }
        }
      },
      error: error => {
      }
    })
  }

  openGodsDoc()
  {
    var fileId = null;
    for (var i = 0; i < this.currentFiles.length; i++)
    {
      if (this.currentFiles[i].filename === "Godsdeklaration") {
        fileId = this.currentFiles[i].id;
        break;
      }
    }

    this.organisationService.getcDocFileWithId(fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {

        if (pdfContent == null) {
          Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${fileId}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
      }
    })
  }

  getRestriktionList(mixture) {
    var restriktionList = "";
     if (mixture.list_reach_14 == 1) {
       if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_REACH_14_SHORT");
     }
     if (mixture.list_reach_17 == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_REACH_17_SHORT");
     }
     if (mixture.list_reach_candidate == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE_SHORT");
     }
     if (mixture.list_chem_prio_p == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P_SHORT");
     }
     if (mixture.list_chem_prio_u == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U_SHORT");
     }
     if (mixture.list_water_directive == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE_SHORT");
     }
     if (mixture.list_amv_a == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_AMV_A_SHORT");
     }
     if (mixture.list_amv_b == 1) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_AMV_B_SHORT");
     }
     if (mixture.list_BASTA == 1 && this.subscribesToBASTA) {
        if(restriktionList!=""){restriktionList+=' ';}
       restriktionList += this.translate.instant("RESTRICTION_LIST_BASTA_SHORT");
     }
     if (mixture.list_sin == 1 && this.subscribesToSIN) {
        if(restriktionList!=""){restriktionList+=' ';}
        restriktionList += this.translate.instant("RESTRICTION_LIST_SIN_SHORT");
     }
     return restriktionList;
  }

  getPercent(mixture) {
    var percent="";
    var min = "";
    var max = "";

    if (!mixture.min) {
      min=""}else{min=mixture.min
    }

    if (!mixture.max) {
      max=""
    } else {
      max=mixture.max
    }

    percent = min +' - ' + max + ' %';
    return percent;
  }

  openHistoricalSds(sds) {
    this.productService.getSdsWithRiskIdAsTrustedResourceUrl(sds.sds_file_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openHistoricalGrb(grb) {
    this.productService.getGrbWithRiskIdAsTrustedResourceUrl(grb.file_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openGrbInNewWindow(product) {
    var filename = product.product_name + '_risk.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openLrbInNewWindow(product) {
    var filename = product.name + '_risk.pdf';
    this.assessmentChemicalService.getLrbAsTrustedResourceUrl(this.selectedDepartment.id, product, 'productitem');
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openSdsInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openProductSheetInNewWindow(product) {
    var filename = product.product_name + '_p.pdf';
    this.productService.getProductSheetAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"), this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"), this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openExtSdsInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }

        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openStampedSdsInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getStampedSdsAsTrustedResourceUrl(product.sds_stamp_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  goToLRB(product) {

    this.router.navigate(['/assessmentchemicaledit', product.id]);
  }

  editProduct() {

    const modalRef = this.modalService.open(ModalEditProductComponent);
    modalRef.componentInstance.thisProduct = this.product;

    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((result) => {
      if (result != null)
      {
        switch (result.status) {
          case 'update':
            if (_.isObject(result.product)) {
              this.product.amount = result.product.amount;
              this.product.annual_consumption = result.product.annual_consumption;
              this.product.maximum_amount = result.product.maximum_amount;
              this.product.department_info = result.product.department_info;
              this.product.unit?this.product.unit = result.product.unit.name:null;

              if(this.subscribesToFootprint){
                this.product.o_footprint_air=result.product.o_footprint_air;
                this.product.o_footprint_product=result.product.o_footprint_product;
                this.product.o_footprint_waste=result.product.o_footprint_waste;
                this.product.o_footprint_water=result.product.o_footprint_water;
                this.product.o_footprint_comment=result.product.o_footprint_comment;
                this.footPrintText="";
                this.footprinttextfunction();
              }

              if(_.isObject(result.product.group)&&result.product.group!=null){
                this.product.group_id = result.product.group.id;
                this.product.group_name = result.product.group.name;
              }

              this.productService.updateSingleProduct(this.product);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: pdfContent => {
                },
                error: error => {
                  console.log("Error: ", error);
                }
              })
            }
            break;

          case 'remove':
            if (_.isObject(result.product)) {
              var info = {
                productId: this.product.id,
                comment: result.comment,
                eco_eu:this.product.eco_eu||"",
                eco_svanen:this.product.eco_svanen||"",
                eco_GEC:this.product.eco_GEC||"",
                maximum_amount:this.product.maximum_amount||"",
                group_id:this.product.group_id||"",
                footprint_water:this.product.o_footprint_water==true?1:0,
                footprint_air:this.product.o_footprint_air==true?1:0,
                footprint_product:this.product.o_footprint_product==true?1:0,
                footprint_waste:this.product.o_footprint_waste==true?1:0,
                footprint_comment:this.product.o_footprint_comment||""
              }
              this.productService.removeSingleProduct(info);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: response => {
                  window.location.reload();
                },
                error: error => {
                  console.log("Error: ", error);
                }
              });
            }
            break;

          case 'exchangeandremove':
            this.router.navigate(['/product', result.replaced_by]).then(() => {
              window.location.reload();
            });
          break;

          case 'exchangeandkeep':

          break;

          default:

          break;
        }
      }
    })
  }

  orderRiskAssessment() {
    Swal.fire({
      title: this.translate.instant("ORDER_RISK_ASSESSMENT"),
      text: this.translate.instant("ORDER_RISK_ASSESSMENT_INFO") + "?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("CANCEL"),
    }).then((result) => {
      if (result.dismiss == 'cancel')
      {} else {
        var object=({
          email: this.currentUser.mail,
          cc: "",
          subject: "Beställ Riskbedömning",
          body:
            "Företag: " + this.selectedOrganisation.name + " (" + this.selectedOrganisation.id + ")\n" +
            "Produkt: " + this.product.product_name + " (" + this.product.id + ")\n" +
            "Beställare: Namn: " + this.currentUser.first_name + " " + this.currentUser.last_name + "\n" +
            "            Användarnamn: " + this.currentUser.username + "\n" +
            "            Email: " + this.currentUser.email + "\n" +
            "            Tel: " + this.currentUser.phone + "\n"
        });
        this.supportService.mail(object);
        this.supportService.getSupportDataListener().pipe(take(1)).subscribe({
          next: pdfContent => {
            Swal.fire({
              title: this.translate.instant("MESSAGE_SENT"),
              text: this.translate.instant("YOUR_MESSAGE_HAS_BEEN_SENT"),
              icon: "success",
              showCancelButton: false,
            });
          },
          error: error => {
            console.log("Error: ", error);
            Swal.fire({
              title: this.translate.instant("AN_ERROR_OCCURED"),
              text: this.translate.instant("YOUR_MESSAGE_HAS_BEEN_SENT"),
              icon: "error",
              showCancelButton: false,
            });
          }
        })
      }
    });
  }

  sendToSubstitution(product) {

    var organisation_id = this.selectedOrganisation.id;
    var department_id:any = this.selectedDepartment.id;
    var product_id = product.id;
    var issuer = this.currentUser.id;


    var object=({
        'organisation_id': organisation_id,
        'department_id': department_id,
        'product_id': product_id,
        'issuer': issuer
    });

    this.checkIfSubstitutionExists(object).subscribe(found => {
      if ( !found ) {
        this.organisationService.addSubstitution({object: object});
        this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
            next: response => {
              if (response != null) {
                Swal.fire(product.product_name, this.translate.instant("SEND_TO_SUBSTITUTION_INFO"),'')
              }
            },
            error: error => {
              console.log("Error: ", error);
            }
        })
      } else {
        Swal.fire(product.product_name, this.translate.instant("SEND_TO_SUBSTITUTION_INFO_ALREADY_EXISTS"),'');
        return;
      }
    });
  }

  uploadStampedFile() {

    const modalRef = this.modalService.open(ModalUploadFileComponent);

    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((file_id) => {

      this.product.uploadStampedDocument = file_id.filename;

      var depid=[];
      depid.push(this.selectedDepartment.id);

      var object;
      if (this.product.sds_stamp_id != "" && this.product.sds_stamp_id != null && this.product.sds_stamp_id!="null"){
        object=({
          'org_id': this.selectedOrganisation.id,
          'dep_id': depid,
          'file_id': file_id.filename,
          'sds_id': this.product.sds_id,
          'action':'update'
        });
      } else {
          object=({
            'org_id': this.selectedOrganisation.id,
            'dep_id': depid,
            'file_id': file_id.filename,
            'sds_id': this.product.sds_file_id,
            'sds_date': this.product.sds_file_id,
            'version': this.product.sds_version,
            'action':'create'
          });
      }

      this.organisationService.addEachFile(object);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          this.product.uploadStampedDocument = null;
          window.location.reload();
        },
        error: error => {
          console.log("Error: ", error);
          this.product.uploadStampedDocument = null;
        }
      })
    });
  }

  orderLabels() {
    const modalRef = this.modalService.open(ModalOrderLabelsComponent);

    modalRef.componentInstance.thisProduct = this.product;

    modalRef.componentInstance.passEntry.subscribe((result) => {
      if ( this.subscribesToRiskAssessment && this.showSubdepartments != true && result?.LRB_id && result?.LRB_id!='' && result?.LRB_id!=null && result?.LRB_status==3) {
        if(result.email.qrcode === true){
          this.createLabelsLRB('qrcode',result.email.static_token);
        }
        else if (result.email.size21 === true)
        {
          this.createLabelsLRB(21, null);
        }
        else if (result.email.size8 === true)
        {
          this.createLabelsLRB(8, null);
        }
        else if (result.email.size4 === true)
        {
          this.createLabelsLRB(4, null);
        }
        else if (result.email.size2 === true)
        {
          this.createLabelsLRB(2, null);
        }
        else if (result.email.printer === true)
        {
          this.createLabelsLRB(1, null);
        }
        else if (result.email.printer991 === true)
        {
          this.createLabelsLRB(19, null);
        }
        else if (result.email.printerqrcode === true)
        {
          this.createLabelsLRB('printerqrcode', result.email.static_token);
        }
        else if (result.email.size2b === true)
        {
          this.createLabelsLRB(22, null);
        }
        else if (result.email.size4b === true)
        {
          this.createLabelsLRB(44, null);
        }
        else if (result.email.size8b === true)
        {
          this.createLabelsLRB(88, null);
        }
        else if (result.email.size24 === true)
        {
          this.createLabelsLRB(24, null);
        }
        else
        {
          this.createLabelsLRB(20, null);
        }
      } else {
        if(result.email.qrcode === true){
          this.createLabels('qrcode',result.email.static_token);
        }
        else if (result.email.size21 === true)
        {
          this.createLabels(21, null);
        }
        else if (result.email.size8 === true)
        {
          this.createLabels(8, null);
        }
        else if (result.email.size4 === true)
        {
          this.createLabels(4, null);
        }
        else if (result.email.size2 === true)
        {
          this.createLabels(2, null);
        }
        else if (result.email.printer === true)
        {
          this.createLabels(1, null);
        }
        else if (result.email.printer991 === true)
        {
          this.createLabels(19, null);
        }
        else if (result.email.printerqrcode === true)
        {
          this.createLabels('printerqrcode', result.email.static_token);
        }
        else if (result.email.size2b === true)
        {
          this.createLabels(22, null);
        }
        else if (result.email.size4b === true)
        {
          this.createLabels(44, null);
        }
        else if (result.email.size8b === true)
        {
          this.createLabels(88, null);
        }
        else if (result.email.size24 === true)
        {
          this.createLabels(24, null);
        }
        else
        {
          this.createLabels(20, null);
        }
      }

    });
  }

  createLabels(size, token) {

    if ((size != 1) && (size != 19) && (size != 20) && (size!='qrcode') && (size!='printerqrcode'))
    {
      this.productService.fetchLabels(this.selectedDepartment.id, this.product.id, size,'');
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else if (size=='qrcode') {
      this.productService.fetchLabels(this.selectedDepartment.id, this.product.id, size, token);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else
    {
      this.productService.getLabelForPrinter(this.selectedDepartment.id, this.product.id, size, token);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: file => {
          if (file != null) {
            var opened=window.open(file,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  createLabelsLRB(size, token) {

    if ((size != 1) && (size != 19) && (size != 20) && (size!='qrcode') && (size!='printerqrcode'))
    {
      this.assessmentChemicalService.fetchLabels(this.selectedDepartment.id, this.product.id, size,'');
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else if (size=='qrcode') {
      this.assessmentChemicalService.fetchLabels(this.selectedDepartment.id, this.product.id, size, token);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else
    {
      this.assessmentChemicalService.getLabelForPrinter(this.selectedDepartment.id, this.product.id, size, token);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: file => {
          if (file != null) {
            var opened=window.open(file,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  goToProductList() {
    if (this.utilService.previousSelectedDepartmentIsValid('productview') == true) {
      const previousSelectedDepartment = localStorage.getItem('previousSelectedDepartment');
      if (previousSelectedDepartment) {
        const { department, timestamp, page } = JSON.parse(previousSelectedDepartment);
        this.organisationService.changeDepartmentById(department.id);
        localStorage.setItem('selectedDepartment', JSON.stringify(department));
        setTimeout(() => {
          this.router.navigate(['/productlist']);
        }, 2000);
      } else {
        localStorage.removeItem('previousSelectedDepartment');
        this.router.navigate(['/productlist']);
      }
    } else {
      localStorage.removeItem('previousSelectedDepartment');
      this.router.navigate(['/productlist']);
    }
  }

  getSdsDocHistory() {
    this.sareqService.getDocumentHistoryForSareqProduct(this.productId);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: docHistory => {
        if (docHistory == null) {
        }
        else
        {
          this.documentHistory = docHistory;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getActivityBasedDataSheets(){
    this.assessmentService.getActivityBasedDataSheets(this.productId);
    this.assessmentService.getAssessmentProductDataListener().pipe(take(1)).subscribe({
      next: (response) => {
        if (response === null) {

        }
        else
        {
          this.activityBasedDataSheets = response;
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })

  }

  showManufacturerInformation(product) {
    let object = { type: "get", id: this.product.supplier_id };
    this.manufacturerService.getSingle(object);
    this.manufacturerService.getManufacturerDataListener().pipe(take(1))
      .subscribe({next: (manufacturer) => {
          if (manufacturer == null) {
          } else {
            if (manufacturer) {
              Swal.fire({
                title: manufacturer[0].name,
                type: "info",
                allowOutsideClick: "true" ,
                html:
                "<div class='ui grid' style='overflow-y: scroll;overflow-x: hidden'>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 1:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_1 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 2:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_2 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("ZIP") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                          manufacturer[0].zip +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("CITY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].city +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("COUNTRY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].country +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("WEB_PAGE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].www +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("EMAIL") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].email +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("PHONE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].phone +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("FAX") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].fax +
                        "</div>" +
                    "</div>" +
                  "</div>"
              });
            }
          }
        },
        error: (error) => {},
    });
  }

  printTransportProduct() {
    this.productService.createTransportInfo(this.product.id);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"), this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"));
        console.log("Error: ", error);
      }
    })
  }

  printCargoDeclaration() {

    if (!this.product.transport_un_nbr || this.product.transport_un_nbr == "" || this.product.transport_un_nbr == null){
      Swal.fire(this.product.product_name, this.translate.instant("NO_TRANSPORT_INFO"),'info');
      return;
    }

    const modalRef = this.modalService.open(ModalPrintCargodeclarationComponent);
    modalRef.componentInstance.thisProduct = this.product;
  }

  openTransportDocumentInNewWindow(product) {
    this.productService.getTransportInfoAsTrustedUrl(product.transport_un_file_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"), this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"), this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"));
        console.log("Error: ", error);
      }
    })
  }

  getProductDepartments() {
    if (this.productId) {
      this.productService.getDepartmentsWhereProductAppearsByProductId(this.productId, true);
      this.productService.getProductDepartmentDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            departments.sort((a, b) => {
              if (a.id === this.selectedDepartment.id) {
                return -1;
              } else if (b.id === this.selectedDepartment.id) {
                return 1;
              } else {
                return a.name.localeCompare(b.name);
              }
            });
            this.departmentList = departments;

          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getProduct() {
    this.productViewAllDepartments = JSON.parse(localStorage.getItem('productViewAllDepartments'));
    if ( this.productViewAllDepartments && this.productViewAllDepartments.product == this.productId && this.productViewAllDepartments.organisation == this.organisation.id ) {
      this.allDepartmentsChoosen = true;
      this.departmentIdWhereExists = this.productViewAllDepartments.departments[0].id;
    } else {
      this.allDepartmentsChoosen = false;
    }
    localStorage.removeItem('productViewAllDepartments');
    if (this.allDepartmentsChoosen == true) {
      // As this is just basic info I go through this function

      this.getInfoAllDepartments(this.departmentIdWhereExists);
    } else if (this.selectedDepartment) {
      this.productService.getSingleProduct(this.selectedDepartment.id, this.productId);
      this.productService.getProductSingleDataListener().pipe(take(1)).subscribe({
        next: product => {
          if (product != null) {
            this.product = product;
            if ( this.showSubdepartments == false && this.subscribesToRiskAssessment && this.product?.LRB_id && this.product?.LRB_id!='' && this.product?.LRB_id!=null && this.product?.LRB_status == 3 ) {
              this.getLRBInfo ( this.product );
            }
            if ( product.c_phrases && product.c_phrases.length > 1)
            {
              // Sorting alphabetically and only unique items
              this.sortCphrases( product );
            }
            if ( this.subscribesToSpecialDemands )
            {
              this.getCMRA_Category( product );
            }
            this.product_id_backup = this.product.id;
            this.product.o_footprint_water = this.product.o_footprint_water==1?true:false;
            this.product.o_footprint_air = this.product.o_footprint_air==1?true:false;
            this.product.o_footprint_product = this.product.o_footprint_product==1?true:false;
            this.product.o_footprint_waste = this.product.o_footprint_waste==1?true:false;
            this.product.maximum_amount=this.formatNumbers(this.product.maximum_amount);
            this.product.annual_consumption=this.formatNumbers(this.product.annual_consumption);
            this.product.amount=this.formatNumbers(this.product.amount);
            this.currentLanguage = localStorage.getItem('usedLanguage');

            this.footprinttextfunction();

            switch (this.currentLanguage) {
              case 'sv':
                this.currentLanguage = 0;
              break;

              case 'en':
                this.currentLanguage = 1;
              break;

              case 'fi':
                this.currentLanguage = 2;
              break;

              default:
                this.currentLanguage = 0;
              break;
            }

            this.openPicture(this.product);
            this.getLanguages(this.product);
            this.getProductWarning(this.product);
            this.getSubstition(this.product);

            if(this.product.stamp_dep&&this.product.stamp_org&&(this.product.stamp_dep==this.selectedDepartment.id||this.product.stamp_org==this.selectedOrganisation.id)){
                this.showStampedDocument=true;
            } else {
                this.showStampedDocument=false;
            }
            this.loadingData = false;

          } else {

            this.loadingData = false;
            this.router.navigate(['/productlist']);
          }
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      });
    }
  }

  getLanguages(product) {
    this.productService.getOtherAvailableLangs(product.id);
    this.productService.getProductLangDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response != null) {
          this.availableLangs = response;
          for (var i = 0; i < this.availableLangs.length; i++) {
            if (this.currentLanguage == this.availableLangs[i].lang) {
              this.goOtherLang(this.availableLangs[i]);
            } else {

            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  goOtherLang(id) {
    if(this.currentLanguage==id.lang&&id.override&&id.override==id.product_id){
      return;
    } else {
      this.currentLanguage = id.lang;
    }

    if(id == 0 || id == 1 || id == 2 || id == '0' || id == '1' || id=='2') {
      id = {};
      id.product_id = this.product_id_backup;
      id.lang = id;
    }
    else {
      var info = {productId: id.product_id, lang: id.lang, orgId: this.selectedOrganisation.main_organisation };
      this.productService.GetL(info);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: response => {
          if( response &&
              response[0].name &&
              response.length>0 &&
              response[0] != null &&
              response[0] != ""){

              response = response[0];
              this.product.product_name = response.name;
              this.product.handling_emissions = response.handling_emissions;
              this.product.handling_fire_measures = response.handling_fire_measures;
              this.product.handling = response.handling;
              this.product.handling_storage = response.handling_storage;
              this.product.handling_waste = response.handling_waste;
              this.product.ice.consumption = response.ice_consumption;
              this.product.ice.eye_contact = response.ice_eye_contact;
              this.product.ice.inhalation = response.ice_inhalation;
              this.product.ice.skin_contact = response.ice_skin_contact;
              this.product.protection.breath = response.prot_breath;
              this.product.protection.clothes = response.prot_clothes;
              this.product.protection.glasses = response.prot_glasses;
              this.product.protection.gloves = response.prot_gloves;
              this.product.protection.hygiene = response.prot_hygiene;
              this.product.sds_date = response.sds_date;
              this.product.sds_file_id = response.sds_file_id;
              this.product.risk_id = response.risk_id;
              this.product.sareq_number = response.sareq_number;
              this.product.fitness_for_duty = response.fitness_for_duty;
              this.product.information = response.information;
              this.product.health_control = response.health_control;
              this.product.training = response.training;
              this.product.no_special_demands = response.no_special_demands;
              this.product.articlenumber = response.supplier_article_number;
              this.product.id = response.id;
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        });
      }
    }

  openPicture(product) {
    this.picture = null;
    this.productService.getPictureAsTrustedResourceUrl(product.id);
    this.productService.getProductPictureDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        this.picture = pdfContent;
      },
      error: error => {
        console.log("Error: ", error);
        this.picture = null;
      }
    });
  }

  showFirstHelp() {
    this.isFirstHelp = true;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showProtective() {
    this.isFirstHelp = false;
    this.isProtective = true;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showHandling() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = true;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showphrases() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = true;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showPhClassification() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=true;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showMixtures() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = true;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showRiskActivity() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = true;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showTransport() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = true;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showSpecialDemands() {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =true;
    this.isPhValueAndDosage=false;
    this.isMisc=false;
  }

  showPhValueAndDosage(){
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=true;
    this.isMisc=false;
  }

  showMisc( ) {
    this.isFirstHelp = false;
    this.isProtective = false;
    this.isHandling = false;
    this.isphrases = false;
    this.isPhClassification=false;
    this.isMixtures = false;
    this.isRiskActivity = false;
    this.isTransport = false;
    this.isSpecialDemand =false;
    this.isPhValueAndDosage=false;
    this.isMisc=true;
  }

  footprinttextfunction(){
    if (this.subscribesToFootprint) {

      if (this.product.o_footprint_water) {
        this.footPrintText = this.translate.instant("WATER");
      }

      if (this.product.o_footprint_air) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("AIR");
        } else {
          this.footPrintText+=', ' + this.translate.instant("AIR");
        }
      }

      if (this.product.o_footprint_product) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("PRODUCT");
        } else {
          this.footPrintText += ', ' + this.translate.instant("PRODUCT");
        }
      }

      if (this.product.o_footprint_waste) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("WASTE");
        } else {
          this.footPrintText += ', '+ this.translate.instant("WASTE");
        }
      }

      if (this.footPrintText == "" && (this.product.o_footprint_comment == null || this.product.o_footprint_comment == "")) {
        this.showFootprint = false;
      } else {
        this.showFootprint = true;
      }
    }
  }

  formatNumbers(string){
    // The database doesnt accept swedish decimal comma on numbers
    if(!string){ return; }
    else if(string.toString().indexOf(",")==-1) {
      string = Math.round(Number(string)*1000)/1000;
      return string;
    } else {
      string = Math.round(Number(string.replace(',','.'))*1000)/1000;
      return string;
    }
  }

  getSubstition(product) {

    var organisation_id = this.selectedOrganisation.id;
    var department_id:any = this.selectedDepartment.id;
    var product_id = product.id;
    var issuer = this.currentUser.id;

    var object=({
        'organisation_id': organisation_id,
        'department_id': department_id,
        'product_id': product_id,
        'issuer': issuer
    });

    this.substitution = null;

    var substitutionStatus = [
      this.translate.instant("NEW"),
      this.translate.instant("ONGOING"),
      this.translate.instant("FOR_REVIEW"),
      this.translate.instant("SAREQ_WAITING_FOR_PRODUCT"),
      this.translate.instant("FINISHED"),
      this.translate.instant("FINISHED_AND_SUBSTITUTED")
    ]

    this.substitutionService.checkIfSubstitutionExists( object );
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
        next: (response) => {
          if (response == null) {
          } else {
            this.substitutionService.getSingle(response[0].id);
            this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
              next: substitution => {
                if (substitution == null) {
                }
                else {
                    substitution = substitution[0];
                    this.substitution=substitution;
                    this.product.substitutionstatus=substitutionStatus[this.substitution.status];
                }
              },
              error: error => {
              }
            })
          }
        },
        error: (error) => {
        },
    });
  }

  checkIfSubstitutionExists(object): Observable<boolean> {
    this.substitutionService.checkIfSubstitutionExists( object );
    return new Observable<boolean>(observer => {
    this.substitutionService
      .getSubstitutionDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response == null) {
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        error: (error) => {
          console.log("checkIfSubstitutionExists: error");
          observer.next(true);
          observer.complete();
        },
      });
    });
  }

  openSubstitutionInNewWindow(id){
    this.loadingsub = true;
    this.substitutionService.getPDFAsTrustedResourceUrl(id, this.selectedDepartment.id);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          this.loadingsub = false;
          Swal.fire(this.translate.instant("COULD_NOT_GET_SUB"), this.translate.instant("COULD_NOT_GET_SUB_WARNING"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
          this.loadingsub = false;
        }
      },
      error: error => {
        this.loadingsub = false;
        console.log("Error: ", error);
        Swal.fire(this.translate.instant("COULD_NOT_GET_SUB"), this.translate.instant("COULD_NOT_GET_SUB_WARNING"), "error");
      }
    })
  }

  gotoActivityBasedDataSheet(activityBasedDataSheet){
    this.organisationService.setRiskAssessmentActivityList.next(true);
    this.router.navigate(['/assessmentedit', activityBasedDataSheet.assessment_id]);
  }

  openArbInNewWindow(activityBasedDataSheet, index) {
    this.loadingStates[index] = true;
    this.assessmentService.getArbAsTrustedResourceUrlProducts(activityBasedDataSheet.assessment_id, this.selectedDepartment.id);
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          this.loadingStates[index] = false;
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
          this.loadingStates[index] = false;
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingStates[index] = false;
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  get_unique_items(item){
    return _.uniq(item);
  }

  sortCphrases(product)
  {
    let uniqueItems = product.c_phrases.reduce((accumulator, currentItem) => {
      if ( !accumulator.find( item => item.c_phrase === currentItem.c_phrase) )
      {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, [] );

    let uniqueSortedItems = uniqueItems.sort(
      (a, b) => a.c_phrase.localeCompare( b.c_phrase )
    );
    this.product.c_phrases = uniqueSortedItems;
  }

  getCMRA_Category(product) {
    product.CMRA_Category=[];
    let h_phrases = product.phrases['H'];
    if((!h_phrases||h_phrases==null)&&product.allergic!=1){
      return;
    }

    if(_.toUpper(h_phrases).indexOf("H350")>-1||_.toUpper(h_phrases).indexOf("H351")>-1){
      product.CMRA_Category.push({'name':'C','title':'CARCINOGENIC'});
    }

    if(_.toUpper(h_phrases).indexOf("340")>-1||_.toUpper(h_phrases).indexOf("H341")>-1){
      product.CMRA_Category.push({'name':'M','title':'MUTAGENIC'});
    }

    if(_.toUpper(h_phrases).indexOf("360")>-1||_.toUpper(h_phrases).indexOf("H361")>-1||_.toUpper(h_phrases).indexOf("H362")>-1){
      product.CMRA_Category.push({'name':'R','title':'REPRODUCTIVE_TOXIC'});
    }

    if(_.toUpper(h_phrases).indexOf("H317")>-1||_.toUpper(h_phrases).indexOf("H334")>-1
        ||_.toUpper(h_phrases).indexOf("EUH208")>-1||_.toUpper(h_phrases).indexOf("EUH205")>-1
        ||_.toUpper(h_phrases).indexOf("EUH204")>-1||_.toUpper(h_phrases).indexOf("EUH202")>-1
        ||product.allergic==1)
    {
      product.CMRA_Category.push({'name':'A','title':'ALLERGIC'});
    }
  }

  getCmraTitleTranslation(CMRAC){
    if ( !CMRAC || typeof(CMRAC) === 'undefined'){
      return "";
    }
    else
    {
      return this.translate.instant(CMRAC);
    }
  }

  getLRBInfo( product ){
    this.assessmentChemicalService.getLRBInfo( product.LRB_id );
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) { }
        else {
          Object.assign(this.product, response);
        }
      },
      error: error => {
      }
    })
  }

  changeDepartment(dep) {
    localStorage.removeItem('productViewAllDepartments');
    if ( this.allDepartmentsChoosen==true || dep.id != this.selectedDepartment.id) {
      this.organisationService.changeDepartmentById(dep.id);
                    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                      this.utilService.departmentChangeClicked(this.router.url);
                      localStorage.setItem('departmentchanged','set');
                      this.router.navigate(['/product', this.productId]).then(() => { window.location.reload(); });
                    })
    }
  }

  getInfoAllDepartments(orgId) {
    let OrgId = orgId;
    this.productService.getSingleProduct ( OrgId, this.productId );
    this.productService.getProductSingleDataListener().pipe(take(1)).subscribe({
      next: product => {
        if (product != null) {
          this.product = product;
          console.log(this.product);
          if ( product.c_phrases && product.c_phrases.length > 1)
          {
            // Sorting alphabetically and only unique items
            this.sortCphrases( product );
          }
          if ( this.subscribesToSpecialDemands )
          {
            this.getCMRA_Category( product );
          }
          this.product_id_backup = this.product.id;
          this.product.o_footprint_water = this.product.o_footprint_water==1?true:false;
          this.product.o_footprint_air = this.product.o_footprint_air==1?true:false;
          this.product.o_footprint_product = this.product.o_footprint_product==1?true:false;
          this.product.o_footprint_waste = this.product.o_footprint_waste==1?true:false;
          this.product.maximum_amount=this.formatNumbers(this.product.maximum_amount);
          this.product.annual_consumption=this.formatNumbers(this.product.annual_consumption);
          this.product.amount=this.formatNumbers(this.product.amount);
          this.currentLanguage = localStorage.getItem('usedLanguage');

          this.footprinttextfunction();

          switch (this.currentLanguage) {
            case 'sv':
              this.currentLanguage = 0;
            break;

            case 'en':
              this.currentLanguage = 1;
            break;

            case 'fi':
              this.currentLanguage = 2;
            break;

            default:
              this.currentLanguage = 0;
            break;
          }

          this.openPicture(this.product);
          this.getLanguages(this.product);
          this.getProductWarning(this.product);

          if(this.product.stamp_dep&&this.product.stamp_org&&(this.product.stamp_dep==this.selectedDepartment.id||this.product.stamp_org==this.selectedOrganisation.id)){
              this.showStampedDocument=true;
          } else {
              this.showStampedDocument=false;
          }
          this.loadingData = false;

        } else {

          this.loadingData = false;
          this.router.navigate(['/productlist']);
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
      }
    });

  }

  getUniqueGroupNameList(item): string[] {
    if (item && item.groups) {
      let thisGroups = _.uniqBy(item.groups,'id');
      let names = thisGroups.map(group => group.name);
      return names;
    }
    return [];
  }

  showSwedishDecimalComma(number){
    return this.utilService.convertNumberFormat(number,true);
  }
}
