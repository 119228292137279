import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit {

  isHelp: boolean;
  isDocument: boolean;
  isVideo: boolean;
  currentFiles: any;
  editProfile: boolean;
  selectedFile: any;
  currentUser: any;
  loadingData: boolean;
  selectedFiles?: FileList;
  currentFile?: File;
  selectedLanguage: string = 'sv';
  selectedLanguageCode: any = 0;
  usedLanguage: any;
  languages = [
    { code: 'sv', name: 'Svenska' },
    { code: 'en', name: 'Engelska' },
    { code: 'fi', name: 'Finska' },
    // Add more languages as needed
  ];

  constructor(private authService:AuthService, private organisationService: OrganisationService, private userService: UserService,
              private uploadService: FileUploadService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.isHelp = true;
    this.isDocument = false;
    this.isVideo = false;
    this.editProfile = false;
    this.selectedFile = null;
    this.loadingData = false;

    this.loadcDocFiles();

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.usedLanguage = localStorage.getItem('usedLanguage');
    switch(this.usedLanguage) {
      case 'sv':
        this.usedLanguage = 0;
      break;

      case 'en':
        this.usedLanguage = 1;
      break;

      case 'fi':
        this.usedLanguage = 2;
      break;

      default:
        this.usedLanguage = 0;
      break;
    }
  }

  logout() {
    this.authService.logout();
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    this.selectedFile = this.selectedFiles[0];
  }

  showHelp() {
    this.isHelp = true;
    this.isDocument = false;
    this.isVideo = false;
  }

  showDocument() {
    this.isHelp = false;
    this.isDocument = true;
    this.isVideo = false;
  }

  showVideo() {
    this.isHelp = false;
    this.isDocument = false;
    this.isVideo = true;
  }

  enableEditInfo() {
    this.editProfile = true;
  }

  cancelEdit() {
    this.editProfile = false;
    this.selectedFile = null;
  }

  loadcDocFiles() {
    this.currentFiles = [];
    this.organisationService.getAllcDocFiles();
    this.organisationService.getOrganisationDataDocumentListener().pipe(take(1)).subscribe({
      next: documents => {
        if (documents == null) {
          Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
        }
        else {
          this.currentFiles = documents;
          for (var i = 0; i < this.currentFiles.length; i++)
          {
            this.currentFiles[i].filename = this.currentFiles[i].filename.substring(0, this.currentFiles[i].filename.indexOf(".pdf"));
          }
          this.currentFiles = this.currentFiles.filter(file => file.language === this.usedLanguage);
        }
      },
      error: error => {
        Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
      }
    })
  }

  openDocumentInNewWindow(fileId)
  {
    this.organisationService.getcDocFileWithId(fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {

        if (pdfContent == null) {
          Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${fileId}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire("Kan inte öppna dokument", this.translate.instant("Kan inte öppna dokument"), "error");
      }
    })
  }

  removeDocument(fileId) {

    this.organisationService.deletecDocFileWithId(fileId);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        this.loadcDocFiles();
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  uploadSelectedFile() {
    this.loadingData = true;
    switch(this.selectedLanguage) {
      case 'sv':
        this.selectedLanguageCode = 0;
      break;

      case 'en':
        this.selectedLanguageCode = 1;
      break;

      case 'fi':
        this.selectedLanguageCode = 2;
      break;

      default:
        this.selectedLanguageCode = 0;
      break;
    }
    if (this.selectedFile) {
        // Upload file to server and save its id
        this.uploadService.upload(this.selectedFile);
        this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
          next: response => {
            if (response.filename)
            {
              this.selectedFile.id = response.filename;
              var uploadedFile = {
                file_id: response.filename,
                original_filename: this.selectedFile.name,
                filename: this.selectedFile.name,
                file_extension: "pdf",
                language: this.selectedLanguageCode
              }

              this.organisationService.addcDocFile(uploadedFile);
              this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
                next: pdfContent => {
                  this.loadcDocFiles();
                },
                error: error => {
                  console.log("Error: ", error);
                  this.selectedFile = null;
                  this.loadingData = false;
                }
              })
            }
          },
          error: error => {
            console.log("Error: ", error);
            this.selectedFile = null;
            this.loadingData = false;
          }
      })
    }
  }

  savecDocInfo() {

    if(this.selectedFile)
    {
        this.uploadSelectedFile();
    }
    else
    {
      window.location.reload();
    }
  }
}
