//dropdown.directive.ts
import { Directive, HostListener, AfterViewInit, ElementRef, ContentChild, Renderer2, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective implements AfterViewInit{
    @ContentChild('dropdownMenuInside', { static: false }) childElementInside;
    @ContentChild('dropdownMenuMulti', { static: false }) childElementMulti;
    @ContentChild('dropdownSearch', { static: false }) childElementSearch;
    @Output() clickOutside = new EventEmitter<MouseEvent>();

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        // Access the child element here

        if (this.childElementInside) {
            this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
        }
    }

    @HostListener('document:click', ['$event']) MouseEvent(event: Event){
        const inputElement = this.elRef.nativeElement;
        const elements_multi = this.childElementInside.nativeElement.querySelectorAll('.multi-items');

        const clickedInside = this.elRef.nativeElement.contains(event.target);

        if (this.childElementSearch)
        {
            const clickedInsideChildSearch = this.childElementSearch.nativeElement.contains(event.target);

            if (clickedInsideChildSearch)
            {
                return;
            }
        }

        if (this.childElementInside) {

            if (this.childElementInside.nativeElement.classList.contains('hidden')) {
                if (clickedInside) {
                    this.renderer.removeClass(this.childElementInside.nativeElement, 'hidden');
                    if (this.childElementSearch) {

                      this.childElementSearch.nativeElement.focus();
                    }
                }
            }
            else {
                if (elements_multi.length == 0) {
                    this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
                }
                else {
                    if(!clickedInside) {
                        this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
                    }
                    else
                    {
                        this.childElementSearch.nativeElement.value = '';
                        this.childElementSearch.nativeElement.focus();
                    }
                }
            }
        }
    }

    @HostListener('keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
      const elements = this.childElementInside.nativeElement.querySelectorAll('.dropdown-menu-item');
        const elements_multi = this.childElementInside.nativeElement.querySelectorAll('.multi-items');
        const currentIndex = document.activeElement ? Array.from(elements).indexOf(document.activeElement) : -1;

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            const nextIndex = currentIndex + 1 < elements.length ? currentIndex + 1 : 0;
            elements[nextIndex].focus();
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : elements.length - 1;
            elements[prevIndex].focus();
        }
        else if (event.key === 'Enter') {

            // Check if the currently focused element is one of the dropdown items
            if (elements_multi.length == 0)
            {
                if (document.activeElement && Array.from(elements).includes(document.activeElement)) {
                    // Trigger a click event on the focused element
                    (document.activeElement as HTMLElement).click();
                    // Keep the focus on the dropdown container
                    this.elRef.nativeElement.querySelector('button').focus();
                    this.renderer.addClass(this.elRef.nativeElement.child(), 'hidden');
                }

                if (this.childElementInside) {
                    this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
                }
            }
            else
            {
                if (this.childElementSearch) {
                    this.childElementSearch.nativeElement.value = '';
                    this.childElementSearch.nativeElement.focus();
                }
            }
        } else if (event.key === 'Escape' || event.key === 'Tab') {
          if (elements_multi.length == 0) {
            if (document.activeElement && Array.from(elements).includes(document.activeElement)) {
              // Trigger a click event on the focused element
              this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
            }
            if (this.childElementInside) {
              this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
            }
          } else {
            if (this.childElementSearch) {
              this.renderer.addClass(this.childElementInside.nativeElement, 'hidden');
              this.childElementSearch.nativeElement.value = '';
              this.childElementSearch.nativeElement.focus();
            }
          }
          this.elRef.nativeElement.querySelector('button').focus();
        }

    }
}
