import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../auth/auth.service';
import { AssessmentChemicalService } from 'src/app/services/assessment-chemical.service';
import { SubstitutionService } from "src/app/services/substitution.service";
import { TranslateService } from '@ngx-translate/core';
import { ModalSelectProductDepartmentComponent } from 'src/app/modals/modal-select-product-department/modal-select-product-department.component';
import { ModalEditProductComponent } from 'src/app/modals/modal-edit-product/modal-edit-product.component';
import { ModalUploadFileComponent } from 'src/app/modals/modal-upload-file/modal-upload-file.component';
import { ModalOrderLabelsComponent } from 'src/app/modals/modal-order-labels/modal-order-labels.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { FilterPipe } from "src/app/shared/pipes/filterpipe.pipe";
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
import { Observable } from 'rxjs';
import { json } from 'stream/consumers';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit,AfterViewInit {

  itemsPerPage:number=10;
  pageNumber:number=1;
  loadingData: boolean=true;
  listAlldepartments: any;
  organisation: any;
  selectPictogram: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  companyArticles:any = [];
  currentUser: any;
  products: any=[];
  productsOverride: any=[];
  showSubdepartments: boolean=true;
  currentLanguage: any;
  searchFilter: any;
  searchRiskDisplay: any;
  searchPictogramDisplay: any;
  searchSpecial: any;
  searchRisk: any;
  searchPictogram: any;
  searchWarning: any;
  searchEcoLabels: any;
  searchSpecialDisplay: any;
  thissearchFilter: any;
  searchFilterRisk: any;
  searchFilterEco: any;
  searchFilterPictogram: any;
  searchFilterWarning: any;
  specialSearch: any;
  filterItems: any;
  sareqIds: any;
  Risk: any;
  Pictogram: any;
  ecolabel: any;
  productSelectedId: any = null;
  showExtendedProductSearchList: any;
  extendedProductListSettings: any;
  showSelectColumns: boolean = false;
  excluded: boolean = false;
  showMinus0: boolean = false;
  showMinus1: boolean = false;
  showMinus2: boolean = false;
  showMinus3: boolean = false;
  showMinus4: boolean = false;
  showMinus5: boolean = false;
  showMinus6: boolean = false;
  showMinus7: boolean = false;
  showMinus8: boolean = false;
  showMinus9: boolean = false;
  showMinus10: boolean = false;
  showMinus11: boolean = false;
  showMinus12: boolean = false;
  showMinus13: boolean = false;
  showMinus14: boolean = false;
  showMinus15: boolean = false;
  showMinus16: boolean = false;
  showMinus17: boolean = false;
  showMinus18: boolean = false;
  showMinus19: boolean = false;
  showMinus20: boolean = false;
  showMinus21: boolean = false;
  showMinus22: boolean = false;
  showMinus23: boolean = false;
  showMinus24: boolean = false;
  showMinus25: boolean = false;
  showMinus26: boolean = false;
  showMinus27: boolean = false;
  showMinus28: boolean = false;
  showMinus29: boolean = false;
  showMinus37: boolean = false;
  subscription_subscription_stamped_SDS: any;
  subscribesToFreemium: boolean = false;
  subscribesToRiskAssessment: any;
  subscription_revise_date: any;
  subscribesToReportEnvironment: any;
  subscribesToSpecialDemands: any;
  subscribesToTransport: any;
  subscribesToClassification: any;
  subscribesToSds: any;
  subscribesToGrb: any;
  subscribesToProductsheet: any;
  subscribesToProductAnalysis: any;
  subscribesToLabels: any;
  subscription_risk_assessment: any;
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToSubstitution: boolean;
  subscribesToStamping: any;
  restriktionList: any;
  sortOrder: any;
  searchText: any;
  sortDirection: any = null;
  sortValue: any = null;
  supplierSearch:any = "";
  CMRASearch:any = "";
  productSearch = {
    all: "",
    name: "",
    alternative_name: "",
    supplier_name: "",
    group_name: "",
    group_name_list: "",
    h_phrases: "",
    p_phrases: "",
    c_phrases: "",
    cas: "",
    articlenumber: "",
    CMRA_Category: ""
  };
  loadSubstitutionFinished:boolean = false;
  substitutionStatus:any;
  chemListArray:Array<string>=[];
  order:Array<String>=['desc','asc','asc','desc','asc',
                        'asc','desc','asc','asc','desc',
                        'asc','asc','desc','asc','asc',
                        'desc','asc','asc','desc','asc',
                        'asc','asc','asc','asc','asc',
                        'asc','asc','asc','asc','asc'];
  newsareqsearch;
  newstyrensearch;
  productGroupSearch;
  searchSDS=[];
  searchFoodApproved=[];
  items_per_page_setting = null;
  showMessage = false;
  productlist_columns_locked = 0;
  contextMenuVisible = false;
  contextMenuPosition = { x: 0, y: 0 };
  selectedProduct: any;
  contextMenuHeight = 500; // Height of the context menu in pixels
  showExcluded:boolean = false;
  subscribesToFootprint: any;
  footPrintText: any = "";
  showFootprint: any = "";


  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void{
    if(this.router.url.indexOf('product')!=-1||this.router.url.indexOf('productlist')!=-1){

    }else{
      localStorage.removeItem('searchFilterEco',);
      localStorage.removeItem('searchFilterRisk',);
      localStorage.removeItem('searchFilterPictogram',);
      localStorage.removeItem('searchFilterWarning',);
      localStorage.removeItem('searchFilter',);
    }
  }

  constructor(private utilService: UtilService,
              private organisationService: OrganisationService,
              private translate: TranslateService,
              private authService: AuthService,
              private productService: ProductService,
              private assessmentChemicalService: AssessmentChemicalService,
              private substitutionService: SubstitutionService,
              private router: Router,
              private userService:UserService,
              private modalService: NgbModal,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    // localStorage.removeItem('departmentchanged');
    this.loadingData = true;
    this.loadSubstitutionFinished = false;
    this.sortOrder = "";
    this.filterItems = [];
    this.searchFilterRisk = null;
    this.searchFilterPictogram = null;
    this.searchFilterEco = null;
    this.searchFilterWarning = null;

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null))
    {
      this.logout();
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'asc';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'name';
    }

    this.currentLanguage = localStorage.getItem('usedLanguage');
    switch(this.currentLanguage) {
      case 'sv':
       this.currentLanguage = 0;
      break;

      case 'en':
        this.currentLanguage = 1;
      break;

      case 'fi':
        this.currentLanguage = 2;
      break;

      default:
        this.currentLanguage = 0;
      break;
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.showSubdepartments = JSON.parse(localStorage.getItem('showSubdepartments', ));
    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
    }

    this.searchFilterRisk = JSON.parse(localStorage.getItem('searchFilterRisk', ));
    this.searchFilterPictogram = JSON.parse(localStorage.getItem('searchFilterPictogram', ));

    this.searchFilterEco = JSON.parse(localStorage.getItem('searchFilterEco', ));
    this.searchFilter = JSON.parse(localStorage.getItem('searchFilter', ));

    this.searchFilterWarning = JSON.parse(localStorage.getItem('searchFilterWarning', ));

    if (this.searchFilter == null) {
      this.searchFilter = [];
      this.searchFilter.Name="";
    }

    if (this.searchFilterRisk == null)
    {
      this.searchFilterRisk = [];
    }

    if (this.searchFilterPictogram == null)
    {
      this.searchFilterPictogram = [];
    }

    if (this.searchFilterEco == null)
    {
      this.searchFilterEco = [];
    }

    if (this.searchFilterWarning == null)
      {
        this.searchFilterWarning = [];
      }

    this.showExtendedProductSearchList = JSON.parse(localStorage.getItem('productListShowExtendedProductSearchList', ));
    this.extendedProductListSettings = JSON.parse(localStorage.getItem('productListExtendedProductListSettings', ));

    this.subscribesToRiskAssessment = this.getSubscription('subscription_risk_assessment');
    this.subscribesToFreemium = this.utilService.subscribesTo('subscription_freemium');
    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');
    this.subscribesToProductsheet = this.getSubscription('subscription_productsheet');
    this.subscribesToClassification = this.getSubscription('subscription_classification');
    this.subscribesToProductAnalysis = this.getSubscription('subscription_product_analysis');
    this.subscribesToTransport = this.getSubscription('subscription_transport');
    this.subscribesToSpecialDemands = this.getSubscription('subscription_special_demands');
    this.subscribesToReportEnvironment = this.getSubscription('subscription_report_enviroment');
    this.subscribesToLabels = this.getSubscription('subscription_labels');
    this.subscription_risk_assessment = this.getSubscription('subscription_risk_assessment');
    this.subscription_revise_date = this.getSubscription('subscription_revise_date');
    this.subscription_subscription_stamped_SDS = this.getSubscription('subscription_stamped_SDS');
    this.subscribesToSIN = this.getSubscription('subscription_sin');
    this.subscribesToSLL = this.getSubscription('subscription_sll');
    this.subscribesToBASTA = this.getSubscription('subscription_basta');
    this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
    this.subscribesToFootprint = this.utilService.subscribesTo('subscription_footprint');
    this.subscribesToStamping = this.utilService.subscribesTo('subscription_stamped_SDS');

    if(this.extendedProductListSettings == null)
    {
      this.extendedProductListSettings = [
      true,         // 0
      false,        // 1
      false,        // 2
      false,        // 3
      false,        // 4
      true,         // 5
      false,        // 6
      true,         // 7
      false,        // 8
      false,        // 9
      false,        // 10
      false,        // 11
      false,        // 12
      false,        // 13
      false,        // 14
      false,        // 15
      false,        // 16
      true,         // 17
      false,        // 18
      false,        // 19
      false,        // 20
      false,        // 21
      false,        // 22
      false,        // 23
      false,        // 24 (revise date)
      false,        // 25 (cas)
      false,         // 26 (own riskassessments)
      false,        // 27 (stamped sds date)
      false,        // 28 (observation (anmärkning))
      false,        // 29 (Restrictionlist)
      false,        // 30 (Latest Changed)
      false,        // 31 (Substitution)
      false,        // 32 (Classification)
      false,        // 33 (alternative names)
      false,        // 34 (HGV)
      false,        // 35 (Farokategori)
      false,        // 36 (Livsmedelsgodkänd)
      true];        // 37 (Varning)
    }

    this.getProductListSettings();

    this.chemListArray=['list_amv_a',
                        'list_amv_b',
                        'list_chem_limit', // (not used but for consistency)
                        'list_chem_prio_p',
                        'list_chem_prio_u',
                        'list_reach_14',
                        'list_reach_17',
                        'list_reach_candidate',
                        // 'list_sin',  (maybe not used but for consistency)
                        // 'list_sll',  (maybe used but for consistency)
                        'list_water_directive',
                        'list_BASTA'];
    if(this.subscribesToSIN){
      this.chemListArray.push('list_sin')
    }
    if(this.subscribesToSLL){
      this.chemListArray.push('list_sll')
    }




    this.initSearchFilter();

    this.getAllDepartments();
    this.loadTableData(this.selectedDepartment, false);

    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      if(this.router.url.indexOf('productlist')>0){
        this.ngOnInit();
      }
    })

    if(this.searchFilter){
      if(this.searchFilter[1]==2){
        //$('#selectRisk').dropdown('set text',this.searchFilter[0]);
      }
      if(this.searchFilter[1]==1){
        this.selectPictogram = ""
        switch (this.searchFilter[0]) {
          case "clp_is_corrosive":
            this.selectPictogram = "CORROSIVE";
            break;
          case "clp_is_gas_under_pressure":
            this.selectPictogram = "GASES_UNDER_PRESSURE";
            break;
          case "clp_is_caution":
            this.selectPictogram = "CAUTION";
            break;
          case "clp_is_explosive":
            this.selectPictogram = "EXPLOSIVE";
            break;
          case "clp_is_flammable":
            this.selectPictogram = "FLAMMABLE";
            break;
          case "clp_is_dangerous_to_the_environment":
            this.selectPictogram = "ENVIRONMENTAL_HAZARD";
            break;
          case "clp_is_oxidising":
            this.selectPictogram = "OXIDIZING";
            break;
          case "clp_is_longer_term_health_hazards":
            this.selectPictogram = "HAZARDS";
            break;
          case "clp_is_toxic":
            this.selectPictogram = "TOXIC";
            break;
          case "clp_none":
            this.selectPictogram = "NONE";
              break;
          default:
            this.selectPictogram = "";
            break;
        }
        this.selectPictogram = this.translate.instant(this.selectPictogram);
        //$('#selectPictogram').dropdown('set text',selectPictogram);
      }
      if(this.searchFilter[1]==3){
        //$('#selectEcoLabel').dropdown('set text',this.searchFilter[0]);
      }
    }
  }


  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    this.ngOnInit();
  }

  goToAddProduct() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var itemStr = sessionStorage.getItem('addProductOrganisation');
    if (itemStr) { // if there is a department in session storage, remove it
      sessionStorage.removeItem('addProductOrganisation');
    }
    var now = new Date();
    const item = {
      value: this.selectedOrganisation.id,
      expiry: now.getTime() + 3600000, // this is one hour
    };
    sessionStorage.setItem('addProductOrganisation', JSON.stringify(item));

    this.router.navigate(['/productadd']);
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  logout() {
    this.authService.logout();
  }

  displaylength(){

    if (this.sortOrder==""){
      this.sortOrder="ASC";
    }

    else if (this.sortOrder=="ASC"){
      this.sortOrder="DESC";
    }

    else if (this.sortOrder=="DESC"){
      this.sortOrder="";
    }
  }

  getProductWarning() {
    var n = 0;
    for (let i = 0; i < this.products.length; i++)
    {
      this.products[i].warnings = [];

      if (this.products[i].h_phrases?.includes("H360") || this.products[i].h_phrases?.includes("H360F") || this.products[i].h_phrases?.includes("H360FD") ||
         this.products[i].h_phrases?.includes("H360D") || this.products[i].h_phrases?.includes("H361") || this.products[i].h_phrases?.includes("H361f") ||
          this.products[i].h_phrases?.includes("H362") || this.products[i].h_phrases?.includes("H360Df") || this.products[i].h_phrases?.includes("H361fd") ||
          this.products[i].h_phrases?.includes("H360FD") || this.products[i].h_phrases?.includes("H360Fd") || this.products[i].h_phrases?.includes("H361d"))
      {
        this.products[i].warnings.push("Reproduktion");
      }

      if (this.products[i].h_phrases?.includes("H340") || this.products[i].h_phrases?.includes("H341"))
      {
        this.products[i].warnings.push("Mutagen");
      }

      if (this.products[i].h_phrases?.includes("H317") || this.products[i].h_phrases?.includes("H334") || this.products[i].h_phrases?.includes("EUH203") ||
          this.products[i].h_phrases?.includes("EUH204") || this.products[i].h_phrases?.includes("EUH205") || this.products[i].h_phrases?.includes("EUH208") ||
          this.products[i].allergic==1 || this.products[i].h_phrases?.includes("EUH202"))
      {
        this.products[i].warnings.push("Allergi");
        n++;
      }

      if (this.products[i].h_phrases?.includes("H350") || this.products[i].h_phrases?.includes("H351") || this.products[i].h_phrases?.includes("H350i"))
      {
        this.products[i].warnings.push("Cancer");
      }

      if (this.products[i].h_phrases?.includes("H370") || this.products[i].h_phrases?.includes("H371") || this.products[i].h_phrases?.includes("H372") || this.products[i].h_phrases?.includes("H373"))
      {
        this.products[i].warnings.push("Organ");
      }

      if (this.products[i].h_phrases?.includes("H400") || this.products[i].h_phrases?.includes("H410") || this.products[i].h_phrases?.includes("H411") ||
          this.products[i].h_phrases?.includes("H412") || this.products[i].h_phrases?.includes("H413") || this.products[i].h_phrases?.includes("H420") ||
          this.products[i].h_phrases?.includes("EUH380") || this.products[i].h_phrases?.includes("EUH381") ||
          this.products[i].h_phrases?.includes("EUH430") || this.products[i].h_phrases?.includes("EUH431") ||
          this.products[i].h_phrases?.includes("EUH440") || this.products[i].h_phrases?.includes("EUH441") ||
          this.products[i].h_phrases?.includes("EUH450") || this.products[i].h_phrases?.includes("EUH451"))
      {
        this.products[i].warnings.push("Miljö");
      }
    }
  }

  loadTableData(department, force) {

    if(_.isObject(department)) {

      this.getProducts().then(products => {
        // Just a small pause before going on
        // Not sure why its needed but seemed to work
        for(var n=0;n<10000;n++){

        }
        if (products != null) {

          this.productsOverride = products;

          var ids = [];
          for (var i = 0; i < this.productsOverride.length; i++) {
              ids.push({id: this.productsOverride[i].sareq_id, lang: this.currentLanguage});
          }

          for (var i = 0; i < this.productsOverride.length; i++ ) {
            if ( this.productsOverride[i].override != null) {
              this.productsOverride.splice(i, 1);
            }
          }
          this.getOtherLanguages(ids);
          this.products = [];
          this.sareqIds = [];
           setTimeout(() => {
            this.products = this.productsOverride;
            this.loadingData = false;
            this.specialSearch=JSON.parse(localStorage.getItem('specialSearch'));
            this.updateProductsFromDashbordSearch();
            if (this.searchFilter!=null&&typeof(this.searchFilter[1])!='undefined' ) {
              if (this.searchFilter[1] == 2) {
                  this.filterSearch(this.searchFilter[0], 2);
                  this.Risk = {selected: this.searchFilter[0]}
                  localStorage.removeItem('searchFilterRisk');
                  localStorage.setItem('searchFilterRisk', JSON.stringify(this.searchFilterRisk));
              }
              if (this.searchFilter[1] == 3) {
                this.filterSearch(this.searchFilter[0], 3);
                this.ecolabel = {selected: this.searchFilter[0]};
                localStorage.removeItem('searchFilterEco');
                localStorage.setItem('searchFilterEco', JSON.stringify(this.searchFilterEco));
              }
              if (this.searchFilter[1] == 1) {
                var itemToSearchFor = ""
                switch (this.searchFilter[0]) {
                  case "clp_is_corrosive":
                      itemToSearchFor = "CORROSIVE";
                      break;
                  case "clp_is_gas_under_pressure":
                      itemToSearchFor = "GASES_UNDER_PRESSURE";
                      break;
                  case "clp_is_caution":
                      itemToSearchFor = "CAUTION";
                      break;
                  case "clp_is_explosive":
                      itemToSearchFor = "EXPLOSIVE";
                      break;
                  case "clp_is_flammable":
                      itemToSearchFor = "FLAMMABLE";
                      break;
                  case "clp_is_dangerous_to_the_environment":
                      itemToSearchFor = "ENVIRONMENTAL_HAZARD";
                      break;
                  case "clp_is_oxidising":
                      itemToSearchFor = "OXIDIZING";
                      break;
                  case "clp_is_longer_term_health_hazards":
                      itemToSearchFor = "HAZARDS";
                      break;
                  case "clp_is_toxic":
                      itemToSearchFor = "TOXIC";
                      break;
                  case "clp_none":
                        itemToSearchFor = "NONE";
                        break;
                  default:
                      itemToSearchFor = "";
                      break;
                }
                if ( itemToSearchFor != "NONE") {
                  itemToSearchFor = this.translate.instant(itemToSearchFor);
                }
                this.filterSearch(itemToSearchFor, 1);
                this.Pictogram = {selected:itemToSearchFor};
                localStorage.removeItem('searchFilterPictogram');
                localStorage.setItem('searchFilterPictogram', JSON.stringify(itemToSearchFor));
              }

              if (this.searchFilter[1] == 4) {
                localStorage.removeItem('searchFilterRestriction');
                localStorage.setItem('searchFilterRestriction',this.searchFilter[0]);
              }

              if (this.searchFilter[1] == 0) {
                this.filterSearch(this.searchFilter[0], 0);
              }

              if (this.searchFilter[1] == 6) {
                this.filterSearch(this.searchFilter[0], 6);
              }
            } else {
              if (this.searchFilterPictogram.length > 0) {
                  this.filterSearch(this.searchFilterPictogram, 1);
                  //$('#selectPictogram').dropdown('set text', this.translate.instant(this.searchFilterPictogram.toString()));
              }

              if (this.searchFilterRisk.length > 0) {
                this.filterSearch(this.searchFilterRisk[0], 2);
                //$('#selectRisk').dropdown('set text', this.translate.instant(this.searchFilterRisk.toString()));
              }

              if (this.searchFilterEco.length > 0) {
                this.filterSearch(this.searchFilterEco[0], 3);
                //$('#selectEcoLabel').dropdown('set text', this.translate.instant(this.searchFilterEco.toString()));
              }
            }

            this.searchWarning = [this.translate.instant("ENVIRONMENTAL_HAZARD"),
                          this.translate.instant("MUTAGENIC2"),
                          this.translate.instant("CARCINOGENIC"),
                          this.translate.instant("SENSITIZATION"),
                          this.translate.instant("HAZARDS"),
                          this.translate.instant("REPRODINHIBIT")];

            this.searchPictogram = [this.translate.instant("CORROSIVE"),
                          this.translate.instant("GASES_UNDER_PRESSURE"),
                          this.translate.instant("CAUTION"),
                          this.translate.instant("EXPLOSIVE"),
                          this.translate.instant("FLAMMABLE"),
                          this.translate.instant("ENVIRONMENTAL_HAZARD"),
                          this.translate.instant("OXIDIZING"),
                          this.translate.instant("HAZARDS"),
                          this.translate.instant("TOXIC")];

            this.searchEcoLabels = [this.translate.instant("EU_FLOWER"),
                                    this.translate.instant("NORDIC_ECOLABELLED"),
                                    this.translate.instant("GOOD_ENVIROMENTAL_CHOICE")];

            this.searchSDS =["SDS_1","SDS_2"];
            this.searchFoodApproved =[this.translate.instant("ALL"),this.translate.instant("YES"),this.translate.instant("NO"),this.translate.instant("NOT_ASSESSED")];

            if (this.sortDirection === 'desc') {
              // this.products = _.orderBy(this.products, ['type'.toLowerCase(), this.sortValue]).reverse();
              this.products = _.orderBy(this.products,[item => item.name?.toLowerCase()],['desc']);
            }
            else if (this.sortDirection === 'asc') {
              // this.products = _.orderBy(this.products, ['type'.toLowerCase(), this.sortValue]);
              this.products = _.orderBy(this.products,[item => item.name?.toLowerCase()],['asc']);
            } else {
              this.products = _.orderBy(this.products,[item => item.name?.toLowerCase()],['asc']);
            }

            this.specialSearch=JSON.parse(localStorage.getItem('specialSearch'));
            if(this.specialSearch&&this.specialSearch!=''&&this.specialSearch!=null){
              this.searchFilter.Name = this.specialSearch;
              this.searchFilter[0]=this.specialSearch;
            }
            localStorage.removeItem('specialSearch');

            this.supplierSearch=JSON.parse(localStorage.getItem('supplierSearch'));
            if(this.supplierSearch&&this.supplierSearch!=''&&this.supplierSearch!=null){
              this.searchFilter.Name = this.supplierSearch;
              this.searchFilter[0]=this.supplierSearch;
              this.productSearch.supplier_name=this.supplierSearch;
              this.filterSearchItems();
            }
            localStorage.removeItem('supplierSearch');

            this.newsareqsearch=JSON.parse(localStorage.getItem('newsareqsearch'));
            if(this.newsareqsearch&&this.newsareqsearch!=''&&this.newsareqsearch!=null){
              this.searchFilter.Name=this.newsareqsearch;
              this.searchFilter[0]=this.newsareqsearch;
              this.productSearch.name=this.newsareqsearch;
              this.filterSearchItems();
            }
            localStorage.removeItem('newsareqsearch');

            this.newstyrensearch=JSON.parse(localStorage.getItem('newstyren'));
            if(this.newstyrensearch&&this.newstyrensearch!=''&&this.newstyrensearch!=null){
              this.searchFilter.Name=this.newstyrensearch;
              this.searchFilter[0]=this.newstyrensearch;
              this.productSearch.name=this.newstyrensearch;
              this.filterSearchItems();
            }
            localStorage.removeItem('newstyren');

            this.productGroupSearch = JSON.parse(localStorage.getItem('productGroupSearch'));
            if(this.productGroupSearch&&this.productGroupSearch!=''&&this.productGroupSearch!=null){
              this.searchFilter.Name = this.productGroupSearch;
              this.searchFilter[0]=this.productGroupSearch;
              if (this.showSubdepartments == true) {
                this.productSearch.group_name_list=this.productGroupSearch;
              } else {
                this.productSearch.group_name=this.productGroupSearch;
              }
              this.filterSearchItems();
            }
            localStorage.removeItem('productGroupSearch');

            this.productSearch = JSON.parse(localStorage.getItem('productSearch', ));
            if (this.productSearch == null)
            {
              this.productSearch = {
                all: "",
                name: "",
                alternative_name: "",
                supplier_name: "",
                group_name: "",
                group_name_list: "",
                h_phrases: "",
                p_phrases: "",
                c_phrases: "",
                cas: "",
                articlenumber: "",
                CMRA_Category: ""
              };
              localStorage.setItem('productSearch', JSON.stringify(this.productSearch));
            }
            this.filterSearchItems();
          },1000)

          this.sareqIds = [];
          for(var i = 0; i < this.productsOverride.length; i++) {
            this.sareqIds.push(this.productsOverride[i].sareq_id);
          }

          // Parse department_info_array for each product
          if (this.showSubdepartments == true) {
            this.productsOverride.forEach(product => {
              if (product.department_info_array) {
                product.parsed_department_info_array = JSON.parse(product.department_info_array.replace(/[\u0000-\u001F\u007F-\u009F]/g, ''));
              }
            });
          }

          setTimeout( () => {
            this.getHHPhrases();
            this.getPHPhrases();
            this.getCPhrases();
            this.getCAS();
            this.getRestrictionLists();
            this.getRiskAssessment();
            this.getOwnArticles();

            this.CMRASearch=JSON.parse(localStorage.getItem('CMRASearch'));
            if(this.CMRASearch&&this.CMRASearch!=''&&this.CMRASearch!=null){
              setTimeout(() => {
                if (this.CMRASearch == "C")
                  this.searchFilter.Name=this.translate.instant("CANCEROGENT");
                else if (this.CMRASearch == "M")
                  this.searchFilter.Name="Mutagent";
                else if (this.CMRASearch == "R")
                  this.searchFilter.Name=this.translate.instant("REPRODINHIBIT");
                else if (this.CMRASearch == "A")
                  this.searchFilter.Name=this.translate.instant("ALLERGIC");

                this.searchFilter[0]=this.CMRASearch;
                this.productSearch.CMRA_Category=this.CMRASearch;
                this.filterSearchItems();
              },2000)
            }
          },500)

          if(this.subscribesToSubstitution==true)
          {
            this.getAllSubstitutionSubjects();
          }
          if ( this.subscribesToSpecialDemands == true)
          {
            this.isAllergicOrSpecialDemands();
          }
        }
      });
    }
  }

  getOwnArticles() {

    this.organisationService.getProductArticleNumbers(this.organisation.id);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: article => {
        if (article == null) {}
        else {
          this.companyArticles = article;
          if (this.productsOverride) {
            for(var i = 0; i < this.productsOverride.length; i++)
            {
              if ((this.productsOverride[i].articlenumber !== null) && (this.productsOverride[i].articlenumber !== 'undefined') && (this.productsOverride[i].articlenumber))
              {
                for (var j = 0; j < this.productsOverride[i].articlenumber.length; j++)
                {
                  this.productsOverride[i].articlenumber = this.productsOverride[i].articlenumber.replace(' ', '');
                }

                if (this.companyArticles !== null)
                {
                  for (var j = 0; j < this.companyArticles.length; j++)
                  {
                    if (this.productsOverride[i].sareq_id == this.companyArticles[j].sareqid)
                    {
                      if(!(_.isArray(this.productsOverride[i].customerarticle))){
                        this.productsOverride[i].customerarticle = [];
                        this.productsOverride[i].customerarticle = this.companyArticles[j].articlenumber;
                      } else {
                        this.productsOverride[i].customerarticle = [];
                         this.productsOverride[i].customerarticle = this.companyArticles[j].articlenumber;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  updatedGrbFile(product) {
    if (product.grp_last_updated > product.assessment_update_date)
    {
      return true;
    }

    return false;
  }

  getRiskAssessment() {

    if (this.subscribesToRiskAssessment){
      if (_.isObject(this.selectedDepartment)) {
        if (this.showSubdepartments)
        {
          this.assessmentChemicalService.getProductsWithSuborganisations();
        }
        else
        {
          this.assessmentChemicalService.getProducts();
        }

        this.assessmentChemicalService.getAssessmentProductsDataListener().pipe(take(1)).subscribe({
          next: risk_products => {
            for(var i = 0; i < risk_products.length; i++) {
              for(var j = 0; j < this.productsOverride.length; j++) {
                if((risk_products[i].product_id == this.productsOverride[j].sareq_id) && (risk_products[i].LRB_status==3)) {
                  if(this.productsOverride[j].assessment_riskfile == undefined) {
                    this.productsOverride[j]['assessment_riskfile'] = [];
                  }
                  if(this.productsOverride[j].assessment_update_date == undefined) {
                    this.productsOverride[j]['assessment_update_date'] = null;
                  }
                  if(this.productsOverride[j].chemical_id == undefined) {
                    this.productsOverride[j]['chemical_id'] = [];
                  }
                  if(this.productsOverride[j].riskfileorganisation == undefined) {
                    this.productsOverride[j]['riskfileorganisation'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_mask == undefined) {
                    this.productsOverride[j]['LRB_image_mask'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_boots == undefined) {
                    this.productsOverride[j]['LRB_image_boots'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_breath == undefined) {
                    this.productsOverride[j]['LRB_image_breath'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_clothes == undefined) {
                    this.productsOverride[j]['LRB_image_clothes'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_gloves == undefined) {
                    this.productsOverride[j]['LRB_image_gloves'] = [];
                  }
                  if(this.productsOverride[j].LRB_image_glasses == undefined) {
                    this.productsOverride[j]['LRB_image_glasses'] = [];
                  }
                  if(this.productsOverride[j].LRB_status == undefined) {
                    this.productsOverride[j]['LRB_status'] = [];
                  }
                  if(this.productsOverride[j].protectiveArrayLRB == undefined) {
                    this.productsOverride[j]['protectiveArrayLRB'] = [];
                  }
                  if(this.productsOverride[j].LRB_food_approved == undefined) {
                    this.productsOverride[j]['LRB_food_approved'] = [];
                  }
                  if(this.productsOverride[j].grp_last_updated == undefined) {
                    this.productsOverride[j]['grp_last_updated'] = null;
                  }

                  if(!(risk_products[i].riskfile==""||risk_products[i].riskfile==null)){
                    this.productsOverride[j]['assessment_risk'] = risk_products[i].assessment_risk;
                    this.productsOverride[j]['assessment_riskfile'].push(risk_products[i].riskfile);
                    this.productsOverride[j]['assessment_update_date'] = risk_products[i].updated_date;
                    this.productsOverride[j]['chemical_id'].push(risk_products[i].chemical_id);
                    this.productsOverride[j]['riskfileorganisation'].push(risk_products[i].organisation_id);
                    this.productsOverride[j]['LRB_image_mask'] = risk_products[i].LRB_image_mask;
                    this.productsOverride[j]['LRB_image_boots'] = risk_products[i].LRB_image_boots;
                    this.productsOverride[j]['LRB_image_breath'] = risk_products[i].LRB_image_breath;
                    this.productsOverride[j]['LRB_image_clothes'] = risk_products[i].LRB_image_clothes;
                    this.productsOverride[j]['LRB_image_gloves'] = risk_products[i].LRB_image_gloves;
                    this.productsOverride[j]['LRB_image_glasses'] =risk_products[i].LRB_image_glasses;
                    this.productsOverride[j]['LRB_status'] = risk_products[i].LRB_status;
                    this.productsOverride[j]['protectiveArrayLRB'] = risk_products[i].protectiveArrayLRB;
                    this.productsOverride[j]['LRB_food_approved'] = risk_products[i].LRB_food_approved;
                    this.productsOverride[j]['grp_last_updated'] = risk_products[i].grp_last_updated;
                  }
                }
              }
            }
          },
          error: error => {
            this.loadingData = false;
            this.products = [];
            console.log("Error: ", error);
          }
        })
      }
    }
  }

  getRestrictionLists() {
    this.productService.getRestriktionListAllAdded(this.selectedOrganisation.id);
    this.productService.getProductRestrictionDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {}
        else {
          this.restriktionList = info;
          for (var infoLength = 0; infoLength < info.length; infoLength++){
            for (var productLength = 0; productLength < this.products.length; productLength++){
              if (this.products[productLength] != null) {
                if (this.products[productLength].sareq_id == info[infoLength].Sareq_id){
                    if (info[infoLength].list_amv_a_sum > 0){
                      this.products[productLength].list_amv_a = [];
                      this.products[productLength].list_amv_a = "1";
                    }

                    if (info[infoLength].list_amv_b_sum > 0){
                      this.products[productLength].list_amv_b = [];
                      this.products[productLength].list_amv_b = "1";
                    }
                    if (info[infoLength].list_reach_14_sum > 0){
                      this.products[productLength].list_reach_14 = [];
                      this.products[productLength].list_reach_14 = "1";
                    }

                    if (info[infoLength].list_reach_17_sum > 0){
                      this.products[productLength].list_reach_17 = [];
                      this.products[productLength].list_reach_17 = "1";
                    }

                    if (info[infoLength].list_reach_candidate_sum > 0){
                      this.products[productLength].list_reach_candidate = [];
                      this.products[productLength].list_reach_candidate = "1";
                    }

                    if (info[infoLength].list_BASTA_sum > 0){
                      this.products[productLength].list_BASTA = [];
                      this.products[productLength].list_BASTA = "1";
                    }

                    if (info[infoLength].list_chem_prio_p_sum > 0){
                      this.products[productLength].list_chem_prio_p = [];
                      this.products[productLength].list_chem_prio_p = "1";
                    }

                    if (info[infoLength].list_chem_prio_u_sum > 0){
                      this.products[productLength].list_chem_prio_u = [];
                      this.products[productLength].list_chem_prio_u = "1";
                    }

                    if (info[infoLength].list_water_directive_sum > 0){
                      this.products[productLength].list_water_directive = [];
                      this.products[productLength].list_water_directive = "1";
                    }
                    if (info[infoLength].list_sin_sum > 0){
                      this.products[productLength].list_sin = [];
                      this.products[productLength].list_sin = "1";
                    }
                    if (info[infoLength].list_sll_sum > 0){
                      this.products[productLength].list_sll = [];
                      this.products[productLength].list_sll = "1";
                  }

                    if (info[infoLength].list_chem_limit_sum > 0){
                      this.products[productLength].list_chem_limit = [];
                      this.products[productLength].list_chem_limit = "1";
                    }
                    continue;
                }
              }
            }
          }
          // This is used for sorting the icons
          this.setProduct();
          if (this.searchFilter[1] == 4) {
            var itemToSearchFor = this.searchFilter[0];
            this.filterSearch(itemToSearchFor, 4);
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getCAS() {
    this.productService.getCas(this.selectedOrganisation.id, this.sareqIds);
    this.productService.getProductCasDataListener().pipe(take(1)).subscribe({
      next: cas => {
        if (cas == null) {}
        else {
          for(var j = 0; j < this.productsOverride.length; j++) {
            this.productsOverride[j]['cas'] = [];
          }
          for(var i = 0; i < cas.length; i++) {
              for(var j = 0; j < this.productsOverride.length; j++) {
                  if(cas[i].sareq_id == this.productsOverride[j].sareq_id) {
                    this.productsOverride[j]['cas'].push(cas[i].cas);
                  }
              }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getPHPhrases() {
    this.productService.getPPhrases(this.selectedOrganisation.id, this.sareqIds);
    this.productService.getProductPphraseDataListener().pipe(take(1)).subscribe({
      next: pPhrases => {
        if (pPhrases == null) {}
        else {
          for(var i = 0; i < pPhrases.length; i++) {
            for(var j = 0; j < this.productsOverride.length; j++) {
                if(pPhrases[i].sareq_id == this.productsOverride[j].sareq_id) {
                    if(this.productsOverride[j].p_phrases == undefined) {
                      this.productsOverride[j]['p_phrases'] = [];
                    }
                    this.productsOverride[j]['p_phrases'].push(pPhrases[i].pphrases);
                }
            }
          }

          for(var j = 0; j < this.productsOverride.length; j++) {
            if(this.productsOverride[j]['p_phrases']&&this.productsOverride[j]['p_phrases'].length>1){
              this.productsOverride[j]['p_phrases'] = _.uniq(this.productsOverride[j]['p_phrases'].sort());
            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getHHPhrases() {
    this.productService.getHPhrases(this.selectedOrganisation.id, this.sareqIds);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: hPhrases => {
        if (hPhrases == null) {}
        else {
          for(var i = 0; i < hPhrases.length; i++){
            for(var j = 0; j < this.productsOverride.length; j++) {
                if(hPhrases[i].sareq_id == this.productsOverride[j].sareq_id) {
                    if(this.productsOverride[j].h_phrases == undefined) {
                      this.productsOverride[j]['h_phrases'] = [];
                    }

                    this.productsOverride[j]['h_phrases'].push(hPhrases[i].hphrases);
                }

            }
          }
          for(var j = 0; j < this.productsOverride.length; j++) {
            let temphphrases=[];
            let tempeuhphrases=[];
            if(this.productsOverride[j]['h_phrases']&&this.productsOverride[j]['h_phrases'].length>1){
              for(var k = 0; k < this.productsOverride[j]['h_phrases'].length; k++) {
                if(this.productsOverride[j]['h_phrases'][k].toLowerCase().indexOf('euh')>-1){
                  tempeuhphrases.push(this.productsOverride[j]['h_phrases'][k]);
                } else {
                  temphphrases.push(this.productsOverride[j]['h_phrases'][k]);
                }
              }
              this.productsOverride[j]['h_phrases'] = _.uniq(temphphrases.sort().concat(tempeuhphrases.sort()));
            }
          }
        }
        this.getCMRA_Category();
        setTimeout(() => {
        this.getProductWarning();
        }, 1000);
        if (this.searchFilterWarning.length > 0) {
          this.filterSearchWarning(this.searchFilterWarning);
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getCPhrases() {
    this.productService.getCPhrases(this.selectedOrganisation.id, this.sareqIds);
    this.productService.getProductCphraseDataListener().pipe(take(1)).subscribe({
      next: CPhrases => {
        if (CPhrases == null) {}
        else {
          for(var i = 0; i < CPhrases.length; i++) {
            for(var j = 0; j < this.productsOverride.length; j++) {
                if(CPhrases[i].sareq_id == this.productsOverride[j].sareq_id) {
                    if(this.productsOverride[j].c_phrases == undefined) {
                      this.productsOverride[j]['c_phrases'] = [];
                    }
                    this.productsOverride[j]['c_phrases'].push(CPhrases[i].cphrases);
                }
            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  setProduct() {
    var item;
    for (var productLength=0;productLength < this.products.length; productLength++){
      if(this.products[productLength].list_amv_a=="1"||
        this.products[productLength].list_amv_b=="1"||
        this.products[productLength].list_reach_14=="1"||
        this.products[productLength].list_reach_17=="1"||
        this.products[productLength].list_reach_candidate=="1"){
        item=0;
      }
      else if(this.products[productLength].list_chem_prio_p=="1"||
        this.products[productLength].list_chem_prio_u=="1"||
        this.products[productLength].list_water_directive=="1"||
        this.products[productLength].list_reach_17=="1"||
        this.products[productLength].list_reach_candidate=="1"){
        item=1;
      }
      else if(this.products[productLength].list_BASTA=="1"||
        this.products[productLength].list_sin=="1"||
        this.products[productLength].list_sll=="1"||
        this.products[productLength].list_reach_17=="1"||
        this.products[productLength].list_reach_candidate=="1"){
          item=2;
      }
      else{
          item=3;
      }
      this.products[productLength].restriktionList=[];
      this.products[productLength].restriktionList=item;
    }
  }

  openLrbInNewWindow(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var filename = product.name + '_risk.pdf';
    this.assessmentChemicalService.getLrbAsTrustedResourceUrl(this.selectedDepartment.id, product, 'product');
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openExtSdsInNewWindow(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var filename = product.name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openProductSheetInNewWindow(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var filename = product.name + '_p.pdf';
    this.productService.getProductSheetAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"), this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET"), this.translate.instant("COULD_NOT_GET_PRODUCT_SHEET_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openGrbInNewWindow(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var filename = product.name + '_risk.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        }
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openSdsInNewWindow(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (opened) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  filterSearchWarning(item)
  {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    this.products = this.productsOverride;
    let buffer = [];
    this.searchFilterWarning = item;
    for (let i = 0; i < this.products.length; i++)
    {
      if ((item == this.translate.instant("REPRODINHIBIT")) &&
               (this.products[i].h_phrases?.includes("H360") || this.products[i].h_phrases?.includes("H360D") || this.products[i].h_phrases?.includes("H361") || this.products[i].h_phrases?.includes("H360F") ||
               this.products[i].h_phrases?.includes("H362") || this.products[i].h_phrases?.includes("H360Df") || this.products[i].h_phrases?.includes("H361fd") || this.products[i].h_phrases?.includes("H360Df") ||
               this.products[i].h_phrases?.includes("H360FD") || this.products[i].h_phrases?.includes("H360Fd") || this.products[i].h_phrases?.includes("H361d")))
      {
        buffer.push(this.products[i]);
      }
      else if ((item == this.translate.instant("MUTAGENIC2")) && (this.products[i].h_phrases?.includes("H340") || this.products[i].h_phrases?.includes("H341")))
      {
        buffer.push(this.products[i]);
      }
      else if ((item == this.translate.instant("SENSITIZATION")) &&
               (this.products[i].h_phrases?.includes("H317") || this.products[i].h_phrases?.includes("H334") || this.products[i].h_phrases?.includes("EUH203") ||
                this.products[i].h_phrases?.includes("EUH204") || this.products[i].h_phrases?.includes("EUH205") || this.products[i].h_phrases?.includes("EUH208") ||
                this.products[i].allergic==1 || this.products[i].h_phrases?.includes("EUH202")))
      {
        buffer.push(this.products[i]);
      }
      else if ((item == this.translate.instant("CARCINOGENIC")) && (this.products[i].h_phrases?.includes("H350") || this.products[i].h_phrases?.includes("H351") || this.products[i].h_phrases?.includes("H350i")))
      {
        buffer.push(this.products[i]);
      }
      else if ((item == this.translate.instant("HAZARDS")) &&  (this.products[i].h_phrases?.includes("H370") || this.products[i].h_phrases?.includes("H371") || this.products[i].h_phrases?.includes("H372") ||
              this.products[i].h_phrases?.includes("H373")))
      {
        buffer.push(this.products[i]);
      }
      else if ((item == this.translate.instant("ENVIRONMENTAL_HAZARD")) &&  (this.products[i].h_phrases?.includes("H400") || this.products[i].h_phrases?.includes("H410") || this.products[i].h_phrases?.includes("H411") ||
                this.products[i].h_phrases?.includes("H412") || this.products[i].h_phrases?.includes("H413") || this.products[i].h_phrases?.includes("H420") ||
                this.products[i].h_phrases?.includes("EUH380") || this.products[i].h_phrases?.includes("EUH381") ||
                this.products[i].h_phrases?.includes("EUH430") || this.products[i].h_phrases?.includes("EUH431") ||
                this.products[i].h_phrases?.includes("EUH440") || this.products[i].h_phrases?.includes("EUH441") ||
                this.products[i].h_phrases?.includes("EUH450") || this.products[i].h_phrases?.includes("EUH451")))
      {
        buffer.push(this.products[i]);
      }
    }

    this.searchFilter[0] = "warnings";
    this.searchFilter.Name = item;
    this.products = buffer;
  }

  filterSearch(item, cat) {

    this.thissearchFilter = [];
    this.searchFilter = [];
    this.searchFilter.Name="";

    this.productSearch.name = '';
    this.productSearch.supplier_name = "";
    this.productSearch.group_name = "";
    this.productSearch.group_name_list = "";
    this.productSearch.h_phrases = "";
    this.productSearch.p_phrases = "";
    this.productSearch.cas = "";
    this.productSearch.articlenumber = "";
    this.productSearch.all = "";
    this.productSearch.alternative_name = "";
    localStorage.setItem('productSearch', JSON.stringify(this.productSearch));

    if ( cat==7 ) {
      this.products=this.productsOverride;
      var tempArray=[];
      switch(item) {
        case this.translate.instant("YES"):
          item = 1;
          break;
        case this.translate.instant("NO"):
          item = 0;
          break;
        case this.translate.instant("NOT_ASSESSED"):"MISC"
          item = 2;
          break;
        /*case this.translate.instant("MISC"):
          item = 3;
          break;*/
        case this.translate.instant("ALL"):
          item = 4;
          break;
      }
      if ( item == 0 || item == 1 || item == 2 ) {
        this.products.forEach(element => {
          if ( element.LRB_food_approved === item && element.LRB_status === 3 ) {
            tempArray.push( element );
          }
        } );
      } /* else if ( item == 3 ) {
        this.products.forEach(element => {
          if (![0, 1, 2].includes( element.LRB_food_approved ) && element.LRB_status === 3 ) {
            tempArray.push( element );
          }
        } );
      }*/ else if ( item == 4 ) {
        this.products.forEach(element => {
          if ( element.LRB_status === 3 ) {
            tempArray.push( element );
          }
        } );
      } else {
        return
      }

      this.products=tempArray;
      return;
    }

    if ( cat==6 ) {
      this.products=this.productsOverride;
      var tempArray=[];
      if (this.showSubdepartments == true) {
        this.products.forEach(element => {
          if (element.groups && Array.isArray(element.groups) && element.groups.some(group => group.name === item)) {
            tempArray.push(element);
          }
        } );
      } else {
        this.products.forEach(element => {
          if ( element.group_name == item ) {
            tempArray.push( element );
          }
        } );
      }

      this.searchFilter.Name = this.translate.instant("PRODUCT_GROUP") + ": " + item;
      this.products=tempArray;
      this.thissearchFilter[0] = item;
      this.searchFilter[0] = item;
      this.filterItems.push({
        'cat': cat,
        'item': item
      });
      this.products=tempArray;
      return;
    }

    if(cat==5){
      this.products=this.productsOverride;
      var tempArray=[];
      if(item=='SDS_1'){
        this.products.forEach(element => {
          if(element.esdb_available == 1||
              (element.esdb_file_id&&element.esdb_file_id!=null)
          ){
            tempArray.push(element);
          }
        });
      }

      if(item=='SDS_2'){
        this.products.forEach(element => {
          var date1 = new Date(element.cdoc_date);
          var date2 = new Date();
          var Time = date2.getTime() - date1.getTime();
          var Days = Time / (1000 * 3600 * 24); //Difference in Days
          if(Days<=30){
            tempArray.push(element);
          }
        });
      }
      this.products=tempArray;
      return;
    }

    if ( cat == 4 ) {
      var tempArray=[];
      this.products.forEach( element => {
        if ( item != 'no_list' ) {
          if ( element [ item ] == 1 ) {
            tempArray.push(element);
          }
        }
        if( item == 'no_list' ){
          var found=false;
          this.chemListArray.forEach( listitem => {
            if ( element[ listitem ] == 1 ) {
              found=true;
            }
          } );
          if( found == false ){
            tempArray.push( element );
          }
        }
      } );
      this.products=tempArray;
      this.searchFilter.Name = this.translate.instant( "RESTRICTION_LIST" )+":"+" "+this.getNameRestriction(item);
      this.thissearchFilter[0] = item;
      this.searchFilter[0] = item;
      this.filterItems.push({
        'cat': cat,
        'item': item
    });


      return;
    }

    var found=false;
    for (var Items = 0; Items < this.filterItems.length; Items++){
      if(this.filterItems[Items].cat==cat){
        found = true;
        this.filterItems[Items].item=item;
      }
    }

    if (found == false){
      this.filterItems.push({
          'cat': cat,
          'item': item
      });
    }

    var tmpItem = item;
    if(cat === 1) {

      this.searchPictogramDisplay = item;

      switch(item) {
      case this.translate.instant("CORROSIVE"):
        this.searchFilter[0] = "clp_is_corrosive";
        this.thissearchFilter[0] = "clp_is_corrosive";
        item = "clp_is_corrosive";
        break;

      case this.translate.instant("GASES_UNDER_PRESSURE")||"Gaser under tryck":
        this.searchFilter[0] = "clp_is_gas_under_pressure";
        this.thissearchFilter[0] = "clp_is_gas_under_pressure";
        item = "clp_is_gas_under_pressure";
        break;

      case this.translate.instant("CAUTION"):
        this.searchFilter[0] = "clp_is_caution";
        this.thissearchFilter[0] = "clp_is_caution";
        item = "clp_is_caution";
        break;

      case this.translate.instant("EXPLOSIVE"):
        this.searchFilter[0] = "clp_is_explosive";
        this.thissearchFilter[0] = "clp_is_explosive";
        item = "clp_is_explosive";
        break;

      case this.translate.instant("FLAMMABLE"):
        this.searchFilter[0] = "clp_is_flammable";
        this.thissearchFilter[0] = "clp_is_flammable";
        item = "clp_is_flammable";
        break;

      case this.translate.instant("ENVIRONMENTAL_HAZARD"):
        this.searchFilter[0] = "clp_is_dangerous_to_the_environment";
        this.thissearchFilter[0] = "clp_is_dangerous_to_the_environment";
        item = "clp_is_dangerous_to_the_environment";
        break;

      case this.translate.instant("OXIDIZING"):
        this.searchFilter[0] = "clp_is_oxidising";
        this.thissearchFilter[0] = "clp_is_oxidising";
        item = "clp_is_oxidising";
        break;

      case this.translate.instant("HAZARDS"):
        this.searchFilter[0] = "clp_is_longer_term_health_hazards";
        this.thissearchFilter[0] = "clp_is_longer_term_health_hazards";
        item = "clp_is_longer_term_health_hazards";
        break;

      case this.translate.instant("TOXIC"):
        this.searchFilter[0] = "clp_is_toxic";
        this.thissearchFilter[0] = "clp_is_toxic";
        item = "clp_is_toxic";
        break;

      case "NONE":
        this.searchFilter[0] = "NONE";
        this.thissearchFilter[0] = "NONE";
        item = "NONE";
        break;
      }

      this.searchFilterPictogram = tmpItem;
      this.selectPictogram = tmpItem;
      localStorage.setItem('searchFilterPictogram', JSON.stringify(this.searchFilterPictogram));
    }
    else if(cat === 2) {
      this.thissearchFilter[0] = item;
      this.searchFilter[0] = item;
      this.searchFilterRisk[0] = item;
      this.searchRiskDisplay = item;
      localStorage.setItem('searchFilterRisk', JSON.stringify(this.searchFilterRisk));
    }
    else if(cat === 3) {
      this.thissearchFilter[0] = item;
      this.searchFilter[0] = item;
      this.searchFilterEco[0] = item;
      localStorage.setItem('searchFilterEco', JSON.stringify(this.searchFilterEco));
    }

    for (var Items = 0; Items < this.filterItems.length; Items++){
      if (this.filterItems[Items].cat == cat){
        found = true;
        this.filterItems[Items].item = item;
      }
    }

    if (found == false){
      this.filterItems.push({
        'cat':cat,
        'item':item
      });
    }
    this.searchFilter[1] = cat;
    this.products = this.productsOverride;
    this.filterSearchItems();
  }

  updateProductsFromDashbordSearch(){
    //$('#selectSDS').dropdown('set text', "SDS");
    for(var Items = 0; Items < this.filterItems.length; Items++){

      this.thissearchFilter[0] = this.filterItems[Items].item;
      this.thissearchFilter[1] = this.filterItems[Items].cat;

      if (this.thissearchFilter !== null) {
        var buffer = [];
        switch (this.thissearchFilter[1]) {
          case 0:
            this.searchFilter.Name = this.specialSearch;
            switch(this.thissearchFilter[0]) {
                case 1:
                  for (var i = 0; i < this.products.length; i++) {
                    if ((this.products[i].training != null) && (this.products[i].training != "") &&
                      (this.products[i].training != " ")){
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 2:
                  for (var i = 0; i < this.products.length; i++) {
                    if ((this.products[i].health_control != null) && (this.products[i].health_control != "") &&
                      (this.products[i].health_control != " ")){
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 3:
                  for (var i = 0; i < this.products.length; i++) {
                    if ((this.products[i].fitness_for_duty != null) && (this.products[i].fitness_for_duty != "") &&
                      (this.products[i].fitness_for_duty != " ")){
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 4:
                  for ( var i = 0; i < this.products.length; i++ ) {
                    if ( ( this.products[i].no_special_demands != null ) && (this.products[i].no_special_demands != "" ) &&
                      (this.products[i].no_special_demands != " ")){
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 5:
                  for ( var i = 0; i < this.products.length; i++ ) {
                    if ( ( this.products[i].fitness_for_duty == null ) &&
                        ( this.products[i].health_control == null ) &&
                        ( this.products[i].information == null ) &&
                        ( this.products[i].training == null ) &&
                        ( this.products[i].no_special_demands == null ) &&
                        ( this.products[i].allergic == null ) ) {
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 0:
                  for (var i = 0; i < this.products.length; i++) {
                    if ((this.products[i].information != null) && (this.products[i].information != "") &&
                      (this.products[i].information != " ")){
                        buffer.push(this.products[i]);
                      }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

                case 'unknown':
                  for(var i = 0; i < this.products.length; i++) {
                    if( !this.products[i].training &&
                        !this.products[i].health_control &&
                        !this.products[i].fitness_for_duty &&
                        !this.products[i].information &&
                        !this.products[i].no_special_demands ) {
                      buffer.push(this.products[i]);
                    }
                  }
                  this.products = buffer;
                  localStorage.removeItem('searchFilter');
                break;

            }
            break;  // this.thissearchFilter[0]

          case 1: // this.thissearchFilter[1]
            if (this.searchFilter.Name.includes(this.selectPictogram))
            {
              this.searchFilter.Name = "";
            }

            if (this.searchFilter.Name == "")
            {
              this.searchFilter.Name += this.selectPictogram;
            }
            else
            {
              this.searchFilter.Name += ", " + this.selectPictogram;
            }
            for (var i = 0; i < this.products.length; i++) {
              for(var j = 0; j < this.products[i].pictograms.length; j++) {
                if(this.products[i].pictograms[j].name === this.thissearchFilter[0]) {
                  buffer.push(this.products[i]);
                }
              }
              if ( this.thissearchFilter[0] == "NONE" && this.products[i].pictograms.length == 0 ) {
                buffer.push(this.products[i]);
              }
            }
            this.products = buffer;
            localStorage.removeItem('searchFilter');
          break;

          case 2: // this.thissearchFilter[1]
            if (this.searchFilter.Name.includes(this.translate.instant("RISK_LEVEL")))
            {
              this.searchFilter.Name = "";
            }

            if (this.searchFilter.Name == "")
            {
              this.searchFilter.Name += this.translate.instant("RISK_LEVEL") + ' ' + this.thissearchFilter[0];
            }
            else
            {
              this.searchFilter.Name += ", " + this.translate.instant("RISK_LEVEL") + ' ' + this.thissearchFilter[0];
            }

            for (var i = 0; i < this.products.length; i++){
              if (this.products[i].risk_prio == this.thissearchFilter[0]) {
                buffer.push(this.products[i]);
              }
            }
            this.products = buffer;
            localStorage.removeItem('searchFilter');
          break;

          case 3: // this.thissearchFilter[0]

            if (this.searchFilter.Name.includes(this.translate.instant("ECOLABELS")))
            {
              this.searchFilter.Name = "";
            }

            if (this.searchFilter.Name == "")
            {
              this.searchFilter.Name += this.translate.instant("ECOLABELS") + ' ' + this.thissearchFilter[0];
            }
            else
            {
              this.searchFilter.Name += ", " + this.translate.instant("ECOLABELS") + ' ' + this.thissearchFilter[0];
            }

            for (var i = 0; i < this.products.length; i++){
              if (this.translate.instant("EU_FLOWER") == this.thissearchFilter[0] && this.products[i].eco_eu) {
                buffer.push(this.products[i]);
              }
              else if (this.translate.instant("NORDIC_ECOLABELLED") == this.thissearchFilter[0] && this.products[i].eco_svanen) {
                buffer.push(this.products[i]);
              }
              else if (this.translate.instant("GOOD_ENVIROMENTAL_CHOICE") == this.thissearchFilter[0] && this.products[i].eco_GEC) {
                buffer.push(this.products[i]);
              }
            }
            this.products = buffer;
            localStorage.removeItem('searchFilter');
          break;

          case 4: // this.thissearchFilter[1]
            if (this.searchFilter.Name.includes(this.translate.instant("RESTRICTIONLIST")))
            {
              this.searchFilter.Name = "";
            }

            this.searchFilter.Name = this.translate.instant("RESTRICTIONLIST") + ' ' + this.getNameRestriction(this.thissearchFilter[0]);
            this.products.forEach(element => {
              if(this.thissearchFilter[0]!='no_list'){
                if(element[this.thissearchFilter[0]]==1){
                  buffer.push(element);
                }
              }
              if(this.thissearchFilter[0]=='no_list'){
                var found = false;
                this.chemListArray.forEach(listitem => {
                  if(element[listitem]==1){
                    found = true;
                  }
                });
                if(found==false){
                  buffer.push(element);
                }
              }
            });
            this.products = buffer;
            localStorage.removeItem('searchFilter');
          break;

          default:   // this.thissearchFilter[1]
            break;
        }
      }

      this.thissearchFilter=[];
    }
  }

  onToggleShowExtendedProductListSettings(index, value) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    this.extendedProductListSettings[index] = value;
    localStorage.setItem('productListExtendedProductListSettings', JSON.stringify(this.extendedProductListSettings));
  }

  onToggleShowExtendedProductSearchList() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    this.showExtendedProductSearchList = !this.showExtendedProductSearchList;
    localStorage.setItem('productListShowExtendedProductSearchList', JSON.stringify(this.showExtendedProductSearchList));
  }

  onToggleShowExcluded() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    this.showExcluded = !this.showExcluded;
    if(this.showExcluded == true ) {
      this.products=this.productsOverride;
      var tempArray=[];
        this.products.forEach(element => {
          if(element.excluded == 1){
            tempArray.push(element);
          }
        });
      }
      else {
        this.products=this.productsOverride;
        var tempArray=[];
        this.products.forEach(element => {
            tempArray.push(element);
        });
      }
      this.products=tempArray;
      return;
  }

  onToggleShowSubDepartments() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    this.showSubdepartments = !this.showSubdepartments;
    if (this.showSubdepartments == true) {
      this.productSearch.group_name_list = this.productSearch.group_name;
      this.productSearch.group_name = "";
    } else {
      this.productSearch.group_name = this.productSearch.group_name_list;
      this.productSearch.group_name_list = "";
    }
    localStorage.setItem('productSearch', JSON.stringify(this.productSearch));
    this.filterItems.forEach(
      (listitems, index) => {
        if(listitems.cat==4){
          this.filterItems.splice(index,1);
          this.searchFilter=[];
        }
      }
    )
    localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
    this.loadTableData(this.selectedDepartment, true);

  }

  onToggleShowSelectColumns() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    this.showSelectColumns = !this.showSelectColumns;
  }

  clearDashboardSearch() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    this.searchPictogramDisplay = "";
    this.searchRiskDisplay = "";
    this.searchSpecial = "";
    this.thissearchFilter = [];
    this.searchFilterRisk = [];
    this.searchFilterPictogram = [];
    this.searchFilterEco = [];
    this.searchFilter = [];
    this.filterItems = [];
    this.products = this.productsOverride;
    if(this.supplierSearch!=""){
      this.supplierSearch="";
      this.productSearch.supplier_name="";
    }
    if(this.CMRASearch!=""){
      this.CMRASearch="";
      this.productSearch.CMRA_Category="";
    }
    if(this.newsareqsearch!=""){
      this.newsareqsearch="";
      this.productSearch.name="";
    }
    if(this.productGroupSearch!=""){
      this.productGroupSearch="";
      this.productSearch.group_name="";
      this.productSearch.group_name_list="";
    }

    if(this.newstyrensearch!=""){
      this.newstyrensearch="";
      this.productSearch.name="";
    }

    localStorage.removeItem('productSearch');
    localStorage.removeItem('searchFilterRisk');
    localStorage.removeItem('searchFilterPictogram');
    localStorage.removeItem('searchFilterEco');
    localStorage.removeItem('searchFilterWarning');
    localStorage.removeItem('newsareqsearch');
    localStorage.removeItem('newstyrensearch');
    localStorage.removeItem('CMRASearch');
    //$('#selectRisk').dropdown('set text', this.translate.instant("RISK_LEVEL"));
    //$('#selectPictogram').dropdown('set text', this.translate.instant("PICTOGRAM"));
    //$('#selectEcoLabel').dropdown('set text', this.translate.instant("ECOLABELS"));
    //$('#selectSDS').dropdown('set text', "SDS");
    this.filterSearchItems();
  }

  clearDashboardSearchFields() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    this.searchPictogramDisplay = "";
    this.searchRiskDisplay = "";
    this.searchSpecial = "";
    this.productSearch.name = '';
    this.productSearch.supplier_name = "";
    this.productSearch.group_name = "";
    this.productSearch.group_name_list = "";
    this.productSearch.h_phrases = "";
    this.productSearch.p_phrases = "";
    this.productSearch.cas = "";
    this.productSearch.articlenumber = "";
    this.productSearch.all = "";
    this.thissearchFilter = [];
    this.searchFilterRisk = [];
    this.searchFilterPictogram = [];
    this.searchFilterEco = [];
    this.searchFilter = [];
    this.filterItems = [];
    localStorage.removeItem('productSearch');
    localStorage.removeItem('searchFilterRisk');
    localStorage.removeItem('searchFilterPictogram');
    localStorage.removeItem('searchFilterEco');
    localStorage.removeItem('newsareqsearch');
    localStorage.removeItem('newstyrensearch');
    localStorage.removeItem('CMRASearch');
    this.products = this.productsOverride;
    //$('#selectRisk').dropdown('set text', this.translate.instant("RISK_LEVEL"));
    //$('#selectPictogram').dropdown('set text', this.translate.instant("PICTOGRAM"));
    //$('#selectEcoLabel').dropdown('set text', this.translate.instant("ECOLABELS"));
    //$('#selectSDS').dropdown('set text', "SDS");
  }

  changePprases() {
    if (this.productSearch.p_phrases.length > 2) {
      this.productSearch.p_phrases = this.productSearch.p_phrases.replace('P', '');
      this.productSearch.p_phrases = this.productSearch.p_phrases.replace('p', '');
      this.productSearch.p_phrases = 'P' + this.productSearch.p_phrases;
    }
    this.filterSearchItems();
  }

  changeHprases() {
    if (this.productSearch.h_phrases.length > 2 && this.productSearch.h_phrases.toLowerCase().indexOf('euh') > -1) {
        this.productSearch.h_phrases = this.productSearch.h_phrases.toLowerCase().replace('euh', '');
        this.productSearch.h_phrases = 'EUH' + this.productSearch.h_phrases;
    }
    else if (this.productSearch.h_phrases.length > 2) {
      this.productSearch.h_phrases = this.productSearch.h_phrases.replace('H', '');
      this.productSearch.h_phrases = this.productSearch.h_phrases.replace('h', '');
      this.productSearch.h_phrases = 'H' + this.productSearch.h_phrases;
    }

    this.filterSearchItems();

  }

  changeArticleSearch(){
    for (var i = 0; i < this.productSearch.articlenumber.length; i++)
    {
      this.productSearch.articlenumber = this.productSearch.articlenumber.replace(' ', '');
    }
    this.filterSearchItems();
  }

  initSearchFilter(){

    this.searchRiskDisplay = "";
    this.searchPictogramDisplay = "";
    this.searchSpecial = "";

    this.searchRisk =      ["1","2","3","4","5"];
    this.searchSpecialDisplay =   [this.translate.instant("INFORMATION_DEMAND"), this.translate.instant("TRAINING"), this.translate.instant("HEALTH_CONTROL"), this.translate.instant("FITNESS_FOR_DUTY"), this.translate.instant("NO_SPECIAL_DEMANDS"), this.translate.instant("HAS_NOT_BEEN_CHECKED")];
  }

  getOtherLanguages(products) {

    if (this.currentLanguage != 0) {

      this.productService.checkForOtherLanguages(this.organisation.id, products);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: otherLangs => {
          if (otherLangs == null) {}
          else {
            for(var i = 0; i < this.productsOverride.length; i++){
              for(var j = 0; j < otherLangs.length; j++) {
                if(this.productsOverride[i].sareq_id == otherLangs[j].override) {
                  this.productsOverride[i].name = otherLangs[j].name;
                  this.productsOverride[i].sds_file_id = otherLangs[j].sds_file_id;
                  this.productsOverride[i].risk_id = otherLangs[j].risk_id;
                  this.productsOverride[i].risk_prio = otherLangs[j].risk_prio;
                  this.productsOverride[i].overridden = true;
                  this.productsOverride[i].origProdId = this.productsOverride[i].id;
                  this.productsOverride[i].id = otherLangs[j].id;
                }
              }
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    } else {
      for( var i = 0; i < this.productsOverride.length; i++ ) {
        if( this.productsOverride[i].override != undefined ) {
          this.productsOverride.splice( i, 1 );
        }
      }
    }
  }

  saveSettings() {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    this.loadingData = true;
    let settingsAsString = '';
    for (let key in this.extendedProductListSettings) {
      settingsAsString += this.extendedProductListSettings[key] ? '1' : '0';
    }

    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id,
        'items_per_page':this.items_per_page_setting,
        'columnInfo':settingsAsString
      }
    );
    this.userService.saveProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        localStorage.removeItem('items_per_page');
        this.getProductListSettings();
        this.loadingData = false;
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }

  getProductListSettings() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser?.id,
        'orgId':this.organisation?.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              this.itemsPerPage = 10;
              return;
            }
          } else {
            this.itemsPerPage = 10;
            return;
          }
        } else if(user.items_per_page &&localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {
            this.itemsPerPage = 10;
          }
        } else {
          this.itemsPerPage = 10;
        }

        if(user.items_per_page){
          this.items_per_page_setting = user.items_per_page;
        }

        if(user.productlist_columns &&
            localStorage.getItem('productListExtendedProductListSettings') === null){
          let settingsAsString = user.productlist_columns;
          let settingsAsObject = {...this.extendedProductListSettings}; // create a copy of the original object
          let keys = Object.keys(settingsAsObject);
          for (let i = 0; i < settingsAsString.length; i++) {
            settingsAsObject[keys[i]] = settingsAsString[i] === '1' ? true : false;
          }
          this.extendedProductListSettings = settingsAsObject;
        }
        this.productlist_columns_locked = user.productlist_columns_locked;
      },
      error: error => {
        this.itemsPerPage = 10;
        this.loadingData = false;
      }
    })
  }

  getProducts() {
    return new Promise<any>(resolve => {
      if (this.showSubdepartments)
      {
        if (this.utilService.subscribesTo("subscription_stamped_SDS")) {

          this.productService.AllWithSuborganisationsMoreInformationStamped(this.selectedDepartment.id)
        } else {

          this.productService.AllWithSuborganisationsMoreInformation(this.selectedDepartment.id);
        }

      } else {

        if (this.utilService.subscribesTo("subscription_stamped_SDS")) {

          this.productService.AllMoreInformationStamped(this.selectedDepartment.id);
        } else {

          this.productService.AllMoreInformation(this.selectedDepartment.id);
        }
      }

      this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
        next: products => {
          resolve(products);
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
          this.utilService.onError(error);
        }
      });
    })
  };

  getAllDepartments() {
    if (this.listAlldepartments==null||this.organisation&&this.organisation.id!=JSON.parse(localStorage.getItem('rootOrganisation', )).id) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  stopListeningOnDepartmentChange() {

  }

  productSelected(product) {
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    if (localStorage.getItem('previousSelectedDepartment')) {
      localStorage.removeItem('previousSelectedDepartment');
    }
    localStorage.setItem('previousSelectedDepartment', JSON.stringify({
      department: this.selectedDepartment,
      page: 'productview',
      timestamp: new Date().getTime()
    }));
    if(this.currentUser.is_guest==1){
      return;
    }
    localStorage.setItem("searchFilterRisk", JSON.stringify(this.searchFilterRisk));
    localStorage.setItem("searchFilterPictogram", JSON.stringify(this.searchFilterPictogram));
    localStorage.setItem("searchFilterEco", JSON.stringify(this.searchFilterEco));
    localStorage.setItem("searchFilterWarning", JSON.stringify(this.searchFilterWarning));
    localStorage.setItem("searchFilter", JSON.stringify(this.searchFilter));

    // ID is returned either as "id" or "product_id", depending on which backend call is used
    if( product.overridden) {
      if(product.sareq_id!=product.origProdId){
        product.id = product.sareq_id;
      } else {
        product.id = product.origProdId;
      }
    }
    var id = product.id ? product.id : product.product_id ? product.product_id : null, parentElement = null;
    this.productSelectedId = id;

    // If we show products from sub departments, the selected product may not be on the current selected department
    if (_.isString(id)) {
      if (this.showSubdepartments) {

        var deps = this.utilService.getAllSubdepartments(this.selectedDepartment,true);
        if(deps.length!=1||deps.length>1&&deps.indexOf(product.departmentid)!= -1){
          var isInDepartment = false;
        } else {
          var isInDepartment = false;
        }
        this.productService.getDepartmentsWhereProductAppearsByProductId(id, isInDepartment);
        this.productService.getProductDepartmentDataListener().pipe(take(1)).subscribe({
          next: departments => {
            if (departments == null) {}
            else {
              if (departments.length === 1) {
                if (_.isString(product.organisation_id)) {
                  if (product.organisation_id !== this.selectedDepartment.id) {
                    this.organisationService.changeDepartmentById(product.organisation_id);
                    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                      this.utilService.departmentChangeClicked(this.router.url);
                      localStorage.setItem('departmentchanged','set');
                      this.router.navigate(['/product', this.productSelectedId]);
                    })
                  }
                  else
                  {
                    localStorage.setItem('departmentchanged','set');
                    this.router.navigate(['/product', this.productSelectedId]);
                  }
                }
              }
              else {

                const modalRef = this.modalService.open(ModalSelectProductDepartmentComponent);
                modalRef.componentInstance.productName = product.name + " " + this.translate.instant("POST_EXISTS_ON_MULTIPLE_DEPARTMENTS");
                modalRef.componentInstance.departments = departments;

                modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((selectedDepartment) => {
                  if (selectedDepartment != null)
                  {
                    this.organisationService.changeDepartmentById(selectedDepartment.id);
                    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                      this.utilService.departmentChangeClicked(this.router.url);
                      localStorage.setItem('departmentchanged','set');
                      this.router.navigate(['/product', this.productSelectedId]);
                    })
                  }
                })
              }
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
      else {
        this.router.navigate(['/product', this.productSelectedId]);
      }
    }
  }

  sortProducts(type,id){
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    if( id==28 ){
      const approvalMapping = {
        0: this.translate.instant("YES"),
        1: this.translate.instant("NO"),
        2: this.translate.instant("NOT_ASSESSED"),
        '-': '-'
      };

      // Split the products into three categories
      const status3Products = this.products.filter(product => product.LRB_status === 3);
      const otherStatusProducts = this.products.filter(product => product.LRB_status !== 3 && product.LRB_status !== null);
      const nullStatusProducts = this.products.filter(product => product.LRB_status === null);

      // Replace non 0,1,2 values with '-'
      status3Products.forEach( product => {
        if (![0, 1, 2].includes( product.LRB_food_approved ) ) {
          product.LRB_food_approved = '-';
        }
      } );
      otherStatusProducts.forEach( product => {
        if ( ![0, 1, 2].includes(product.LRB_food_approved ) ) {
          product.LRB_food_approved = '-';
        }
      } );

      // Sort the status3Products and otherStatusProducts arrays
      const sortFunction = (a, b) => {
        // Compare the LRB_food_approved values
        if (a.LRB_food_approved < b.LRB_food_approved) return -1;
        if (a.LRB_food_approved > b.LRB_food_approved) return 1;

        // If the LRB_food_approved values are equal, compare the translations
        const aTranslation = approvalMapping[a.LRB_food_approved] || '';
        const bTranslation = approvalMapping[b.LRB_food_approved] || '';
        return aTranslation.localeCompare(bTranslation);
      };

      status3Products.sort(sortFunction);
      otherStatusProducts.sort(sortFunction);

      if (this.order[id] == 'desc') {
        this.order[id] = 'asc';
        status3Products.reverse();
      } else {
        this.order[id] = 'desc';
      }

      this.products = [...status3Products, ...otherStatusProducts, ...nullStatusProducts];
    return;
  }

    if(id==0||id==10||id==7||id==19){
      if (id == 10 && type == 'group_name_list') {
        if (this.order[id] === 'asc') {
          this.order[id] = 'desc';
          this.products = _.orderBy(this.products, [product => product.groups && product.groups.length > 0 ? product.groups[0].name.toLowerCase() : ''], ['asc']);
        } else if (this.order[id] === 'desc') {
          this.order[id] = 'asc';
          this.products = _.orderBy(this.products, [product => product.groups && product.groups.length > 0 ? product.groups[0].name.toLowerCase() : ''], ['desc']);
        }
      } else {
        if(this.order[id]=='asc'){
          this.order[id]='desc';
          this.products=_.orderBy(this.products,[type], ['asc']);
        } else if(this.order[id]=='desc'){
          this.order[id]='asc';
          this.products=_.orderBy(this.products,[type], ['desc']);
        }
      }

    }else if(id==8||id==26){
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        this.products=_.orderBy(this.products, [( o ) => { if(o[type]!=null){return o[type]}else{return 999} }], ['asc']);
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        this.products=_.orderBy(this.products, [( o ) => { if(o[type]!=null){return o[type]}else{return -999} }], ['desc']);
      }
    }
      else if(id==25){
        if(this.order[id]=='asc'){
          this.order[id]='desc';
          this.products=_.orderBy(this.products, [( o ) => { if(o[type]!=""&&o[type]!=null){return o[type]}else{return 'ZZZ'} }], ['asc']);
        } else if(this.order[id]=='desc'){
          this.order[id]='asc';
          this.products=_.orderBy(this.products, [( o ) => { if(o[type]!=""&&o[type]!=null){return o[type]}else{return '-ZZZ'} }], ['desc']);
        }
    }else if(id==11){
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        this.products=_.orderBy(this.products, [( o ) => {
          if(o[type]&&o[type]!=null&&o[type]!='') {
            return o[type]
          } else if(o['adr_not_classfied']===1)
            {
              return 'ZZT' // Put this last but before the empty rows
            }
          else{
            return 'ZZZ' // Put this last
          }
        }], ['asc']);
      } else if(this.order[id]=='desc')
      {
        this.order[id]='asc';
        this.products=_.orderBy(this.products, [( o ) => {
          if(o[type]&&o[type]!=null&&o[type]!='')
          {
            return o[type]
          }
          else if(o['adr_not_classfied']===1)
          {
            return '-AAB' // Put this last but before the empty rows
          }
          else
          {
            return '-AAA' // Put this last
          }
        }], ['desc']);
      }
    }else if(id==16||id==24){
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        //this.products.sort((a, b) => (a[type]?.toLowerCase()||'' > b[type]?.toLowerCase()||'') ? -1 : 1);
        this.products.sort((a, b) => {
          const aValue = (typeof a[type] === 'string') ? a[type].toLowerCase() : '';
          const bValue = (typeof b[type] === 'string') ? b[type].toLowerCase() : '';

          if (aValue > bValue) return -1;
          if (aValue < bValue) return 1;
          return 0;
        });
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        //this.products.sort((a, b) => (a[type]?.toLowerCase()||'' > b[type]?.toLowerCase()||'') ? 1 : -1);
        this.products.sort((a, b) => {
          const aValue = (typeof a[type] === 'string') ? a[type].toLowerCase() : '';
          const bValue = (typeof b[type] === 'string') ? b[type].toLowerCase() : '';

          if (aValue > bValue) return 1;
          if (aValue < bValue) return -1;
          return 0;
        });
      }
    }
    else if(id==20){
      if ( this.order[id]=='asc' ) {
        this.order[id]='desc';
        this.products = _.orderBy ( this.products, [( o ) => {
          let training = o['training'] && o['training'] != null ? o['training']:'';
              training += o ['health_control'] && o['health_control'] != null ? o['health_control'] : '';
              training += o['fitness_for_duty'] && o['fitness_for_duty'] != null ? o['fitness_for_duty'] : '';
              training += o['information'] && o['information'] !=null ? o['information'] : '';
              training += o['no_special_demands'] && o['no_special_demands'] !=null ? o['no_special_demands'] : '';
          if(training==''){
            return 'ZZZ'
          }
          return training}], ['asc']);
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        this.products =_ .orderBy ( this.products,[ ( o ) => {
          let training = o['training'] && o['training'] != null ? o['training'] : '';
              training += o['health_control'] && o['health_control'] != null ? o['health_control'] : '';
              training += o['fitness_for_duty'] && o['fitness_for_duty'] != null ? o['fitness_for_duty'] : '';
              training += o['information'] && o['information'] != null ? o['information'] : '';
              training += o['no_special_demands'] && o['no_special_demands'] != null ? o['no_special_demands'] : '';
              if(training==''){
                return '-AAA'
              }
          return training}], ['desc']);
      }
    }
    else if(id==21||id==22||id==23){
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        this.products=_.orderBy(this.products,[( o ) => {return o[type]&&o[type]!=null&&o[type]!='undefined'?JSON.stringify(o[type]):'["ZZZ"]'}], ['asc']);
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        this.products=_.orderBy(this.products,[( o ) => {return o[type]&&o[type]!=null&&o[type]!='undefined'?JSON.stringify(o[type]):'["-AAA"]'}], ['desc']);
      }
    }
    else if(id == 4 || id == 5){
      if ( this.showSubdepartments == true ){
        for ( let i=0; i < this.products.length; i++ ){
          this.products[i].amount = "";
          this.products[i].annual_consumption = "";
          this.products[i].amount = this.products[i].amount_volume+this.products[i].amount_weight+this.products[i].amount_other;
          this.products[i].annual_consumption = this.products[i].annual_consumption_volume+this.products[i].annual_consumption_weight+this.products[i].annual_consumption_other;
        }
      }
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        this.products=_.orderBy(this.products,[( o ) => {
          if (o[type] === null || o[type] === 0 || o[type] === undefined) return Infinity;
          let value = typeof o[type] === 'string' ? parseFloat(o[type].replace(',', '.')) : o[type];
          return isNaN(value) ? Infinity : value;
        }], ['asc']);
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        this.products=_.orderBy(this.products,[( o ) => {
          if (o[type] === null || o[type] === 0 || o[type] === undefined) return -Infinity;
          let value = typeof o[type] === 'string' ? parseFloat(o[type].replace(',', '.')) : o[type];
          return isNaN(value) ? -Infinity : value;
        }], ['desc']);
      }
    }else if (id == 29) {
      if (this.order[id] === 'asc') {
        this.order[id] = 'desc';
        this.products.sort((a, b) => a[type] - b[type]);
      } else if (this.order[id] === 'desc') {
        this.order[id] = 'asc';
        this.products.sort((a, b) => b[type] - a[type]);
      }
    }
      else if(id == 24){
        if ( this.showSubdepartments == true ){
          for ( let i=0; i < this.products.length; i++ ){
            this.products[i].department_info = "";
            this.products[i].department_info = this.products.parsed_department_info.forEach(element => {
              if (element.info != null && element.info != undefined && element.info != ""){
                this.products[i].department_info += element.info + " ";
              }
            })
          }
        }
        if(this.order[id]=='asc'){
          this.order[id]='desc';
          this.products=_.orderBy(this.products,[( o ) => {
            if (o[type] === null || o[type] === 0 || o[type] === undefined) return Infinity;
            let value = typeof o[type] === 'string' ? parseFloat(o[type].replace(',', '.')) : o[type];
            return isNaN(value) ? Infinity : value;
          }], ['asc']);
        } else if(this.order[id]=='desc'){
          this.order[id]='asc';
          this.products=_.orderBy(this.products,[( o ) => {
            if (o[type] === null || o[type] === 0 || o[type] === undefined) return -Infinity;
            let value = typeof o[type] === 'string' ? parseFloat(o[type].replace(',', '.')) : o[type];
            return isNaN(value) ? -Infinity : value;
          }], ['desc']);
        }
      }
    else{
      if(this.order[id]=='asc'){
        this.order[id]='desc';
        //this.products.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? -1 : 1);
        this.products.sort((a, b) => {
          const aValue = (typeof a[type] === 'string') ? a[type].toLowerCase() : '';
          const bValue = (typeof b[type] === 'string') ? b[type].toLowerCase() : '';

          if (aValue > bValue) return -1;
          if (aValue < bValue) return 1;
          return 1;
        });
      } else if(this.order[id]=='desc'){
        this.order[id]='asc';
        //this.products.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? 1 : -1);
        this.products.sort((a, b) => {
          const aValue = (typeof a[type] === 'string') ? a[type].toLowerCase() : '';
          const bValue = (typeof b[type] === 'string') ? b[type].toLowerCase() : '';

          if (aValue > bValue) return 1;
          if (aValue < bValue) return -1;
          return -1;
        });
      }
    }
  }

  filterSearchItems(){
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
      return;
    }
    this.products=this.productsOverride;
    if(this.productSearch.all!=""){
      this.products=this.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productSearch.all.toLowerCase())>-1);
    }
    if(this.productSearch.name!=""){
      this.products=this.products.filter(o => o.name?.toLowerCase().indexOf(this.productSearch.name.toLowerCase())>-1);
    }
    if(this.productSearch.supplier_name!=""){
      this.products=this.products.filter(o => o.supplier_name?.toLowerCase().indexOf(this.productSearch.supplier_name.toLowerCase())>-1);
    }
    if(this.productSearch.group_name!=""){
      this.products=this.products.filter(o => o.group_name?.toString().toLowerCase().indexOf(this.productSearch.group_name.toLowerCase())>-1);
    }
    if(this.productSearch.group_name_list!=""){
      this.products = this.products.filter(product =>
        product.groups?.some(group =>
          group.name.toLowerCase().includes(this.productSearch.group_name_list.toLowerCase())
        )
      );
    }
    if ( this.productSearch.articlenumber != "" ) {
      this.products = this.products.filter( o => ( o.articlenumber?.concat( o.customerarticle ))
        .toString()
        .toLowerCase()
        .indexOf( this.productSearch.articlenumber.toLowerCase() ) >- 1 );
    }
    if(this.productSearch.h_phrases!=""){
      this.products=this.products.filter(o => o.h_phrases?.toString().toLowerCase().indexOf(this.productSearch.h_phrases.toLowerCase())>-1);
    }
    if(this.productSearch.p_phrases!=""){
      this.products=this.products.filter(o => o.p_phrases?.toString().toLowerCase().indexOf(this.productSearch.p_phrases.toLowerCase())>-1);
    }
    if(this.productSearch.c_phrases!=""){
      this.products=this.products.filter(o => o.c_phrases?.toString().toLowerCase().indexOf(this.productSearch.c_phrases.toLowerCase())>-1);
    }
    if(this.productSearch.cas!=""){
      this.products=this.products.filter(o => o.cas?.toString().toLowerCase().indexOf(this.productSearch.cas.toLowerCase())>-1);
    }
    if(this.productSearch.alternative_name!=""){
      this.products=this.products.filter(o => o.alternative_name?.toString().toLowerCase().indexOf(this.productSearch.alternative_name.toLowerCase())>-1);
    }
    if(this.productSearch.CMRA_Category!=""){
      this.products=this.products.filter(o => o.CMRA_Category_From_Dashboard?.toString().toLowerCase().indexOf(...this.productSearch.CMRA_Category.toLowerCase())>-1);
    }

    localStorage.setItem('productSearch', JSON.stringify(this.productSearch));
    this.updateProductsFromDashbordSearch();
  }

  getNameRestriction(name){
    switch(name) {
      case 'list_amv_a':
        name=this.translate.instant("RESTRICTION_LIST_AMV_A_SHORT");
        break;
      case 'list_amv_b':
        name=this.translate.instant("RESTRICTION_LIST_AMV_B_SHORT");
        break;
      case 'list_chem_limit':
        name=this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT_SHORT");
        break;
      case 'list_chem_prio_p':
        name=this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P_SHORT");
        break;
      case 'list_chem_prio_u':
        name=this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U_SHORT");
        break;
      case 'list_reach_14':
        name=this.translate.instant("RESTRICTION_LIST_REACH_14_SHORT");
        break;
      case 'list_reach_17':
        name=this.translate.instant("RESTRICTION_LIST_REACH_17_SHORT");
        break;
      case 'list_reach_candidate':
        name=this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE_SHORT");
        break;
      case 'list_sin':
        name=this.translate.instant("RESTRICTION_LIST_SIN_SHORT");
        break;
      case 'list_sll':
        name=this.translate.instant("RESTRICTION_LIST_SLL_SHORT");
        break;
      case 'list_water_directive':
        name=this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE_SHORT");
        break;
      case 'list_BASTA':
        name=this.translate.instant("RESTRICTION_LIST_BASTA_SHORT");
        break;
      case 'no_list':
        name=this.translate.instant("NO_RESTRICION_LIST");
        break;
    }
    return name;
  }

  formatNumbers(string){
    // The database doesnt accept swedish decimal comma on numbers
    if(!string){ return; }
    else if(string.toString().indexOf(",")==-1) {
      string = Math.round(Number(string)*1000)/1000;
      return string;
    } else {
      string = Math.round(Number(string.replace(',','.'))*1000)/1000;
      return string;
    }
  }

  formatUnit(string){
    // The database doesnt accept swedish decimal comma on numbers
    if(!string) {
      return;
    }
    else {
      return string;
    }
  }

  getAllSubstitutionSubjects() {
    var substitutionStatus = [
      this.translate.instant("NEW"),
      this.translate.instant("ONGOING"),
      this.translate.instant("FOR_REVIEW"),
      this.translate.instant("SAREQ_WAITING_FOR_PRODUCT"),
      this.translate.instant("FINISHED"),
      this.translate.instant("FINISHED_AND_SUBSTITUTED")
    ]

    var subDepartmentsIds = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
    var listOfIds="";
    var tempIds = "";
    subDepartmentsIds.forEach(function(i, idx, subDepartmentsIds){
      if (idx === subDepartmentsIds.length - 1){
          tempIds += "'"+i+"'";
      }else{
        tempIds += "'"+i+"'"+",";
      }
   });
    listOfIds="("+tempIds+")";

  if (this.selectedDepartment) {
    this.substitutionService.getAllForDashboard(listOfIds);
    this.substitutionService.getAllSubstitutionDataListener().pipe(take(1)).subscribe({
      next: substitutionSubjects => {
        if (substitutionSubjects != null)
        {
          setTimeout( () => {
            for(var i = 0;i<substitutionSubjects.length;i++){
              for(var j = 0;j<this.products.length;j++){
                if(substitutionSubjects[i].product_id==this.products[j].sareq_id){
                  this.products[j].substitutionstatus=substitutionStatus[substitutionSubjects[i].substatus];
                }
              }
            }
          this.loadSubstitutionFinished = true;
          },2000)
        }
      },
      error: error => {
        console.log("loadNews error: ", error);
      }
    })
  }
}

getCMRA_Category() {
  this.productsOverride.forEach(element => {
    element.CMRA_Category=[];
    element.CMRA_Category_From_Dashboard=[];
    if((!element.h_phrases||element.h_phrases==null)&&element.allergic!=1){
      return;
    }
    if(_.toUpper(element.h_phrases).indexOf("H350")>-1||_.toUpper(element.h_phrases).indexOf("H351")>-1){
      element.CMRA_Category.push({'name':'C','title':'CARCINOGENIC'});
      element.CMRA_Category_From_Dashboard.push('C');
    }
    if(_.toUpper(element.h_phrases).indexOf("340")>-1||_.toUpper(element.h_phrases).indexOf("H341")>-1){
      element.CMRA_Category.push({'name':'M','title':'MUTAGENIC'});
      element.CMRA_Category_From_Dashboard.push('M');
    }
    if(_.toUpper(element.h_phrases).indexOf("360")>-1||_.toUpper(element.h_phrases).indexOf("H361")>-1||_.toUpper(element.h_phrases).indexOf("H362")>-1){
      element.CMRA_Category.push({'name':'R','title':'REPRODUCTIVE_TOXIC'});
      element.CMRA_Category_From_Dashboard.push('R');
    }
    if(_.toUpper(element.h_phrases).indexOf("H317")>-1||_.toUpper(element.h_phrases).indexOf("H334")>-1
          ||_.toUpper(element.h_phrases).indexOf("EUH208")>-1||_.toUpper(element.h_phrases).indexOf("EUH205")>-1
          ||_.toUpper(element.h_phrases).indexOf("EUH204")>-1||_.toUpper(element.h_phrases).indexOf("EUH202")>-1
          ||_.toUpper(element.h_phrases).indexOf("EUH203")>-1||element.allergic==1)
      {
        element.CMRA_Category.push({'name':'A','title':'ALLERGIC'});
        element.CMRA_Category_From_Dashboard.push('A');
      }
  })
}

comparedates(product){
  if(!product.cdoc_date){
    return true;
  }
  var date1 = new Date(product.cdoc_date);
	var date2 = new Date();
  var Time = date2.getTime() - date1.getTime();
  var Days = Time / (1000 * 3600 * 24); //Difference in Days
  if(Days>30){
    return true;
  } else {
    return false;
  }

}

assessmentIsInOrg(product){
  if(product.riskfileorganisation.indexOf(this.selectedDepartment.id)>-1){
    return true;
  } else {
    return false;
  }
}

goToEditAllProducts() {
  if (this.contextMenuVisible == true) {
    this.contextMenuVisible = false;
    return;
  }
  if ( this.showSubdepartments == true) {
    Swal.fire(this.translate.instant("ACTION_NOT_ALLOWED"),this.translate.instant("EDIT_ALL_CHEMICAL_ERROR_MESSAGE"));
    return;
  } else {
    this.router.navigate(['/producteditall']);
  }
}

getCmraTitleTranslation(CMRAC){
  if ( !CMRAC || typeof(CMRAC) === 'undefined'){
    return "";
  }
  else
  {
    return this.translate.instant(CMRAC);
  }
}

  isAllergicOrSpecialDemands() {
    this.productsOverride.forEach( element => {
      if ( element.information === null &&
          element.training === null &&
          element.health_control === null &&
          element.fitness_for_duty === null &&
          element.no_special_demands === null &&
          element.allergic === null) {

        element.allergicOrSpecialDemandsFlag = true;
      }
    } )
  }

  onRightClick(event: MouseEvent, product: any) {
    if (!(this.currentUser && (this.currentUser.is_local_admin || this.currentUser.is_local_assignee ||
      this.currentUser.is_admin || this.currentUser.is_useradmin || this.currentUser.is_super_user))) {
      return;
    }
    if (this.contextMenuVisible == true) {
      this.contextMenuVisible = false;
    }
    event.preventDefault();
    const menuWidth = 400; // Adjust this value based on your context menu width
    const menuHeight = 400; // Adjust this value based on your context menu height

    // Calculate the position based on the click location
    const xPosition = event.clientX;
    const yPosition = event.clientY;

    // Ensure the context menu does not go out of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const adjustedXPosition = (xPosition + menuWidth > viewportWidth) ? (viewportWidth - menuWidth) : xPosition;
    const adjustedYPosition = (yPosition + menuHeight > viewportHeight) ? (viewportHeight - menuHeight) : yPosition;

    // Ensure the context menu does not go out of the viewport when scrolling
    const finalXPosition = Math.max(0, adjustedXPosition);
    const finalYPosition = Math.max(0, adjustedYPosition);

    this.contextMenuVisible = true;
    this.contextMenuPosition = { x: finalXPosition, y: finalYPosition };
    this.selectedProduct = product;
  }

  editProduct(product) {
    this.closeContextMenu();
    const modalRef = this.modalService.open(ModalEditProductComponent);
    modalRef.componentInstance.thisProduct = product;

    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((result) => {
      if (result != null)
      {
        switch (result.status) {
          case 'update':
            if (_.isObject(result.product)) {
              product.amount = result.product.amount;
              product.annual_consumption = result.product.annual_consumption;
              product.maximum_amount = result.product.maximum_amount;
              product.department_info = result.product.department_info;
              product.unit?product.unit = result.product.unit.name:null;

              if(this.subscribesToFootprint){
                product.o_footprint_air=result.product.o_footprint_air;
                product.o_footprint_product=result.product.o_footprint_product;
                product.o_footprint_waste=result.product.o_footprint_waste;
                product.o_footprint_water=result.product.o_footprint_water;
                product.o_footprint_comment=result.product.o_footprint_comment;
                this.footPrintText="";
                this.footprinttextfunction(product);
              }

              if(_.isObject(result.product.group)&&result.product.group!=null){
                product.group_id = result.product.group.id;
                product.group_name = result.product.group.name;
              }

              this.productService.updateSingleProduct(product);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: pdfContent => {
                },
                error: error => {
                  console.log("Error: ", error);
                }
              })
            }
            break;

          case 'remove':
            if (_.isObject(result.product)) {
              var info = {
                productId: product.id,
                comment: result.comment,
                eco_eu:product.eco_eu||"",
                eco_svanen:product.eco_svanen||"",
                eco_GEC:product.eco_GEC||"",
                maximum_amount:product.maximum_amount||"",
                group_id:product.group_id||"",
                footprint_water:product.o_footprint_water==true?1:0,
                footprint_air:product.o_footprint_air==true?1:0,
                footprint_product:product.o_footprint_product==true?1:0,
                footprint_waste:product.o_footprint_waste==true?1:0,
                footprint_comment:product.o_footprint_comment||""
              }
              this.productService.removeSingleProduct(info);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: response => {
                  this.router.navigate(['/productlist']);
                },
                error: error => {
                  console.log("Error: ", error);
                }
              });
            }
            break;

          case 'exchangeandremove':
            this.router.navigate(['/product', result.replaced_by]).then(() => {
              window.location.reload();
            });
          break;

          case 'exchangeandkeep':

          break;

          default:

          break;
        }
      }
    })
  }

  goToLRB(product) {
    this.closeContextMenu();
    this.router.navigate(['/assessmentchemicaledit', product.id]);
  }

  sendToSubstitution(product) {
    this.closeContextMenu();

    var organisation_id = this.selectedOrganisation.id;
    var department_id:any = this.selectedDepartment.id;
    var product_id = product.id;
    var issuer = this.currentUser.id;


    var object=({
        'organisation_id': organisation_id,
        'department_id': department_id,
        'product_id': product_id,
        'issuer': issuer
    });

    this.checkIfSubstitutionExists(object).subscribe(found => {
      if ( !found ) {
        this.organisationService.addSubstitution({object: object});
        this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
            next: response => {
              if (response != null) {
                Swal.fire(product.product_name, this.translate.instant("SEND_TO_SUBSTITUTION_INFO"),'')
              }
            },
            error: error => {
              console.log("Error: ", error);
            }
        })
      } else {
        Swal.fire(product.product_name, this.translate.instant("SEND_TO_SUBSTITUTION_INFO_ALREADY_EXISTS"),'');
        return;
      }
    });
  }

  checkIfSubstitutionExists(object): Observable<boolean> {
    this.substitutionService.checkIfSubstitutionExists( object );
    return new Observable<boolean>(observer => {
    this.substitutionService
      .getSubstitutionDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response == null) {
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        error: (error) => {
          console.log("checkIfSubstitutionExists: error");
          observer.next(true);
          observer.complete();
        },
      });
    });
  }

  uploadStampedFile(product) {
    this.closeContextMenu();
    const modalRef = this.modalService.open(ModalUploadFileComponent);

    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((file_id) => {

      product.uploadStampedDocument = file_id.filename;

      var depid=[];
      depid.push(this.selectedDepartment.id);

      var object;
      if (product.sds_stamp_id != "" && product.sds_stamp_id != null && product.sds_stamp_id!="null"){
        object=({
          'org_id': this.selectedOrganisation.id,
          'dep_id': depid,
          'file_id': file_id.filename,
          'sds_id': product.sds_id,
          'action':'update'
        });
      } else {
          object=({
            'org_id': this.selectedOrganisation.id,
            'dep_id': depid,
            'file_id': file_id.filename,
            'sds_id': product.sds_file_id,
            'sds_date': product.sds_file_id,
            'version': product.sds_version,
            'action':'create'
          });
      }

      this.organisationService.addEachFile(object);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          product.uploadStampedDocument = null;
          window.location.reload();
        },
        error: error => {
          console.log("Error: ", error);
          product.uploadStampedDocument = null;
        }
      })
    });
  }

  printWindow() {
    window.print();
  }

  orderLabels(product) {
    this.closeContextMenu();
    const modalRef = this.modalService.open(ModalOrderLabelsComponent);
    modalRef.componentInstance.thisProduct = product;
    modalRef.componentInstance.passEntry.subscribe((result) => {
      if ( this.subscribesToRiskAssessment && this.showSubdepartments != true && result?.LRB_id && result?.LRB_id!='' && result?.LRB_id!=null && result?.LRB_status==3) {
        if(result.email.qrcode === true){
          this.createLabelsLRB('qrcode',result.email.static_token, product);
        }
        else if (result.email.size21 === true)
        {
          this.createLabelsLRB(21, null, product);
        }
        else if (result.email.size8 === true)
        {
          this.createLabelsLRB(8, null, product);
        }
        else if (result.email.size4 === true)
        {
          this.createLabelsLRB(4, null, product);
        }
        else if (result.email.size2 === true)
        {
          this.createLabelsLRB(2, null, product);
        }
        else if (result.email.printer === true)
        {
          this.createLabelsLRB(1, null, product);
        }
        else if (result.email.printer991 === true)
        {
          this.createLabelsLRB(19, null, product);
        }
        else if (result.email.printerqrcode === true)
        {
          this.createLabelsLRB('printerqrcode', result.email.static_token, product);
        }
        else if (result.email.size2b === true)
        {
          this.createLabelsLRB(22, null, product);
        }
        else if (result.email.size4b === true)
        {
          this.createLabelsLRB(44, null, product);
        }
        else if (result.email.size8b === true)
        {
          this.createLabelsLRB(88, null, product);
        }
        else if (result.email.size24 === true)
        {
          this.createLabelsLRB(24, null, product);
        }
        else
        {
          this.createLabelsLRB(20, null, product);
        }
      } else {
        if(result.email.qrcode === true){
          this.createLabels('qrcode',result.email.static_token, product);
        }
        else if (result.email.size21 === true)
        {
          this.createLabels(21, null, product);
        }
        else if (result.email.size8 === true)
        {
          this.createLabels(8, null, product);
        }
        else if (result.email.size4 === true)
        {
          this.createLabels(4, null, product);
        }
        else if (result.email.size2 === true)
        {
          this.createLabels(2, null, product);
        }
        else if (result.email.printer === true)
        {
          this.createLabels(1, null, product);
        }
        else if (result.email.printer991 === true)
        {
          this.createLabels(19, null, product);
        }
        else if (result.email.printerqrcode === true)
        {
          this.createLabels('printerqrcode', result.email.static_token, product);
        }
        else if (result.email.size2b === true)
        {
          this.createLabels(22, null, product);
        }
        else if (result.email.size4b === true)
        {
          this.createLabels(44, null, product);
        }
        else if (result.email.size8b === true)
        {
          this.createLabels(88, null, product);
        }
        else if (result.email.size24 === true)
        {
          this.createLabels(24, null, product);
        }
        else
        {
          this.createLabels(20, null, product);
        }
      }

    });
  }

  createLabelsLRB(size, token, product) {

    if ((size != 1) && (size != 19) && (size != 20) && (size!='qrcode') && (size!='printerqrcode'))
    {
      this.assessmentChemicalService.fetchLabels(this.selectedDepartment.id, product.id, size,'');
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            // var popup = window.open('')
            // popup.location.href = pdfContent;
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else if (size=='qrcode') {
      this.assessmentChemicalService.fetchLabels(this.selectedDepartment.id, product.id, size, token);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            // var popup = window.open('')
            // popup.location.href = pdfContent;
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else
    {
      this.assessmentChemicalService.getLabelForPrinter(this.selectedDepartment.id, product.id, size, token);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: file => {
          if (file != null) {
            // var popup = window.open('')
            // popup.location.href = file;
            var opened=window.open(file,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  createLabels(size, token, product) {
    if ((size != 1) && (size != 19) && (size != 20) && (size!='qrcode') && (size!='printerqrcode'))
    {
      this.productService.fetchLabels(this.selectedDepartment.id, product.id, size,'');
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            // var popup = window.open('')
            // popup.location.href = pdfContent;
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else if (size=='qrcode') {
      this.productService.fetchLabels(this.selectedDepartment.id, product.id, size, token);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: pdfContent => {
          if (pdfContent != null) {
            // var popup = window.open('')
            // popup.location.href = pdfContent;
            var opened=window.open(pdfContent,"_blank");
            if(!opened){
              window.location.href=pdfContent;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
    else
    {
      this.productService.getLabelForPrinter(this.selectedDepartment.id, product.id, size, token);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: file => {
          if (file != null) {
            // var popup = window.open('')
            // popup.location.href = file;
            var opened=window.open(file,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      });
    }
  }

  goToRiskAnalys(product) {
    this.closeContextMenu();
    this.router.navigate(['/productrisk', product.id]);
  }

  closeContextMenu() {
    this.contextMenuVisible = false;
  }

  footprinttextfunction(product){
    if (this.subscribesToFootprint) {

      if (product.o_footprint_water) {
        this.footPrintText = this.translate.instant("WATER");
      }

      if (product.o_footprint_air) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("AIR");
        } else {
          this.footPrintText+=', ' + this.translate.instant("AIR");
        }
      }

      if (product.o_footprint_product) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("PRODUCT");
        } else {
          this.footPrintText += ', ' + this.translate.instant("PRODUCT");
        }
      }

      if (product.o_footprint_waste) {
        if (this.footPrintText == "") {
          this.footPrintText = this.translate.instant("WASTE");
        } else {
          this.footPrintText += ', '+ this.translate.instant("WASTE");
        }
      }

      if (this.footPrintText == "" && (product.o_footprint_comment == null || product.o_footprint_comment == "")) {
        this.showFootprint = false;
      } else {
        this.showFootprint = true;
      }
    }
  }

  getUniqueGroupNameList(item): string[] {
    if (item && item.groups) {
      let thisGroups = _.uniqBy(item.groups,'id');
      let names = thisGroups.map(group => group.name);
      return names;
    }
    return [];
  }

 @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if ( target.closest('.form-checkbox') ) {console.log('a');return;}
    if (this.contextMenuVisible && !target.closest('.context-menu')) {
      this.closeContextMenu();
    }
  }
}
