<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col border border-gray-200">
    <div class="flex flex-row">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
            <path fill="#000000"
                    d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
            </svg>
        </button>
        <div class="flex flex-row justify-center items-center w-full">
            <span class="text-xl font-bold px-8 py-2 text-black">{{'PROJECTS' | translate}}</span>
        </div>
        <div class="flex flex-row">
            <a (click)="mailMaterialList()" title="{{'SEND_MATERIALLIST_AS_MAIL' | translate}}" class="px-1 py-1 text-lg text-cdocblue cursor-pointer">
                <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z" fill="#080341"/>
                </svg>
            </a>
            <a (click)="printMaterialList()" title="{{'PRINT_MATERIAL_LIST' | translate}}" class="px-§ py-1 text-lg text-cdocblue cursor-pointer">
                <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.75H16C15.8011 16.75 15.6103 16.671 15.4697 16.5303C15.329 16.3897 15.25 16.1989 15.25 16C15.25 15.8011 15.329 15.6103 15.4697 15.4697C15.6103 15.329 15.8011 15.25 16 15.25H18C18.3315 15.25 18.6495 15.1183 18.8839 14.8839C19.1183 14.6495 19.25 14.3315 19.25 14V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H6C5.66848 8.75 5.35054 8.8817 5.11612 9.11612C4.8817 9.35054 4.75 9.66848 4.75 10V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H8C8.19891 15.25 8.38968 15.329 8.53033 15.4697C8.67098 15.6103 8.75 15.8011 8.75 16C8.75 16.1989 8.67098 16.3897 8.53033 16.5303C8.38968 16.671 8.19891 16.75 8 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V10C3.25 9.27065 3.53973 8.57118 4.05546 8.05546C4.57118 7.53973 5.27065 7.25 6 7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V14C20.75 14.7293 20.4603 15.4288 19.9445 15.9445C19.4288 16.4603 18.7293 16.75 18 16.75Z" fill="#000000"/>
                    <path d="M16 8.75C15.8019 8.74741 15.6126 8.66756 15.4725 8.52747C15.3324 8.38737 15.2526 8.19811 15.25 8V4.75H8.75V8C8.75 8.19891 8.67098 8.38968 8.53033 8.53033C8.38968 8.67098 8.19891 8.75 8 8.75C7.80109 8.75 7.61032 8.67098 7.46967 8.53033C7.32902 8.38968 7.25 8.19891 7.25 8V4.5C7.25 4.16848 7.3817 3.85054 7.61612 3.61612C7.85054 3.3817 8.16848 3.25 8.5 3.25H15.5C15.8315 3.25 16.1495 3.3817 16.3839 3.61612C16.6183 3.85054 16.75 4.16848 16.75 4.5V8C16.7474 8.19811 16.6676 8.38737 16.5275 8.52747C16.3874 8.66756 16.1981 8.74741 16 8.75Z" fill="#000000"/>
                    <path d="M15.5 20.75H8.5C8.16848 20.75 7.85054 20.6183 7.61612 20.3839C7.3817 20.1495 7.25 19.8315 7.25 19.5V12.5C7.25 12.1685 7.3817 11.8505 7.61612 11.6161C7.85054 11.3817 8.16848 11.25 8.5 11.25H15.5C15.8315 11.25 16.1495 11.3817 16.3839 11.6161C16.6183 11.8505 16.75 12.1685 16.75 12.5V19.5C16.75 19.8315 16.6183 20.1495 16.3839 20.3839C16.1495 20.6183 15.8315 20.75 15.5 20.75ZM8.75 19.25H15.25V12.75H8.75V19.25Z" fill="#000000"/>
                </svg>
            </a>
            <div class="relative inline-block text-left dropdown-menu-help-item" appDropdown>
                <button title="{{'MENU' | translate}}" id="dropdownBtn" type="button" class="text-black flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <svg class="w-7 h-7 mr-2" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#1C274C"/>
                        <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#1C274C"/>
                        <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#1C274C"/>
                    </svg>
                </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu right-10" #dropdownMenuInside>
                      <div class="py-2">
                          <a (click)="copyMaterialList()" id="materiallistItemButton9" class="dropdown-menu-help-item" tabindex="1">
                              {{'COPY' | translate}}
                          </a>
                      </div>
                  </div>
              </div>
        </div>
    </div>

    <div *ngIf="project" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
        <div class="w-2/3 mt-2">
          <div class="flex flex-col px-4">
            <p class="text-gray-200"><a><strong>{{project.name}}</strong></a></p>
            <span class="text-white text-sm mr-4">{{project.number}}</span>
          </div>
        </div>
    </div>

    <div *ngIf="project" class="flex flex-col w-full p-4 mb-20">
        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
              <div class="mb-4 flex flex-col md:flex-row space-x-0 md:space-x-4">
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"PROJECT" | translate}}</label><br>
                    <input title="{{'PROJECT' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="project.name" (ngModelChange)="project.name = $event" [ngModelOptions]="{standalone: true}" type="text" id="materiallistAddInput0">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"PROJECT_NUMBER" | translate}}</label><br>
                    <input title="{{'PROJECT_NUMBER' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="project.number" (ngModelChange)="project.number = $event" [ngModelOptions]="{standalone: true}" type="text" id="materiallistAddInput1">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"ISSUER" | translate}}</label><br>
                    <input title="{{'ISSUER' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="project.issuer" (ngModelChange)="project.issuer = $event" [ngModelOptions]="{standalone: true}" type="text" id="materiallistAddInput2">
                </div>
                <div class="flex-1">
                    <label class="font-bold mb-1">{{"DATE" | translate}}</label><br>
                    <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="project.project_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?project.project_date = $event:null" [ngModelOptions]="{standalone: true}" id="materiallistAddInput3">
                </div>
              </div>
            </div>
        </div>
        <!-- Form Container -->
        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"COMMENT" | translate}}</label><br>
                    <input title="{{'COMMENT' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="project.comments" (ngModelChange)="project.comments = $event" [ngModelOptions]="{standalone: true}" type="text" id="materiallistAddInput4">
                </div>
            </div>
        </div>

        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="overflow-x-auto">
                <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="materialtable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <div class="flex flex-col md:flex-row md:items-center">
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="projectSearch.all" class="prompt"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                    </div>
                                    <div class="flex items-center space-x-2 mt-4 md:m-4">
                                        <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                        <span class="text-sm mr-1 ">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <div class="flex flex-col md:flex-row">
                                    <div class="mb-4 mr-4">
                                        <input st-search="" type="search" [(ngModel)]="projectSearch.name" class="prompt"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                                    </div>
                                    <div class="mb-4">
                                        <input st-search="" type="search" [(ngModel)]="projectSearch.supplier_name" class="prompt"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header sort-header" st-sort="name" id="materiallistAddTable1THCol1">{{"NAME" | translate}}</th>
                            <th class="table-row-header sort-header" st-sort="supplier_name" id="materiallistAddTable1THCol2">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of organisationProducts
                            | filter : projectSearch.all
                            | filterBy  : ['name'] : projectSearch.name
                            | filterBy  : ['supplier_name'] : projectSearch.supplier_name
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber } let i=index;">
                            <td class="table-row-column" id="{{'materiallistAddTable1TDCol1Row'+i}}">{{product.name}}</td>
                            <td class="table-row-column" id="{{'materiallistAddTable1TDCol1Row'+i}}">{{product.supplier_name}}</td>
                            <td class="py-1 px-2 border-t border-gray-400">
                                <div class="flex flex-col md:flex-row">
                                    <a [ngStyle]="product.isSelected && {'background-color': 'rgb(217, 232, 202)'}"
                                       [ngStyle]="product.disabled && {'background-color': '#e74c3c'}"
                                       (click)="$event.stopPropagation(); incProductRows(product)" id="{{'materiallistAddButton7Row'+i}}">
                                        <img title="{{'ADD' | translate}}" class="w-8 h-8 bg-green-500 cursor-pointer" src="/images/icons/plus-square-svgrepo-com.svg" area-hidden="true" />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="p-6" colspan="1">
                                <div class="static inline-block text-left" appDropdown>
                                    <button type="button" class="button-gray">
                                        <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                        <div class="py-1">
                                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="1">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="overflow-x-auto">
                <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="materialtable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header sort-header" st-sort="name" id="materiallistAddTable2THCol1">{{"NAME" | translate}}</th>
                            <th class="table-row-header sort-header" st-sort="supplier_name" id="materiallistAddTable2THCol2">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header" id="materiallistAddTable2THCol3">{{"COLOR" | translate}}</th>
                            <th class="table-row-header" id="materiallistAddTable2THCol4">{{"SURFACE" | translate}}</th>
                            <th class="table-row-header" id="materiallistAddTable2THCol6"></th>
                            <th class="table-row-header" id="materiallistAddTable2THCol6"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of project.products let i=index;">
                            <td class="table-row-column" id="{{'materiallistAddTable2TDCol1Row'+i}}">{{product.name}}</td>
                            <td class="table-row-column" id="{{'materiallistAddTable2TDCol2Row'+i}}">{{product.supplier_name}}</td>
                            <td (click)="editProduct(product)" class="table-row-column">
                                <input title="edit" class="md:w-full border border-gray-300 p-2" name="color" ngControl="color" [(ngModel)]="product.color" type="text" id="{{'materiallistAddInput9Row'+i}}">
                            </td>
                            <td (click)="editProduct(product)" class="table-row-column">
                                <input title="edit" class="md:w-full border border-gray-300 p-2" name="surface" ngControl="surface" [(ngModel)]="product.surface" type="text" id="{{'materiallistAddInput10Row'+i}}">
                            </td>
                            <td class="table-row-column" (click)="$event.stopPropagation(); deleteProduct(project.id, product)">
                                <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                                    21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                                    5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                                    3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </td>
                            <td class="table-row-column">
                              <svg (click)="moveRowUp(i)" *ngIf="i > 0" class="h-6 w-6 cursor-pointer hover:fill-current hover:text-red-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4L8 8H11V16H13V8H16L12 4Z" fill="currentColor"/>
                              </svg>
                              <svg (click)="moveRowDown(i)" *ngIf="i < project.products.length - 1" class="h-6 w-6 cursor-pointer hover:fill-current hover:text-red-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20L16 16H13V8H11V16H8L12 20Z" fill="currentColor"/>
                              </svg>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="flex flex-row justify-center mb-20 mt-4">
            <button class="button-red" title="{{'REMOVE' | translate}}" (click)="delete()">
                <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
                <span class="text-lg">{{ 'REMOVE' | translate }}</span>
            </button>
            <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
              <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
              <span class="text-lg">{{ 'SAVE' | translate }}</span>
            </button>
        </div>
    </div>
</div>


