import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "src/app/services/util.service";
import { SareqService } from "src/app/services/sareq.service";
import { SubstanceService } from "src/app/services/substance.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { FileUploadService } from 'src/app/services/file-upload.service';
import * as _ from "lodash";
import { Observable } from "rxjs";
declare var $: any;

@Component({
  selector: "app-edit-substance",
  templateUrl: "./edit-substance.component.html",
  styleUrls: ["./edit-substance.component.css"],
})

export class EditSubstanceComponent implements OnInit {
  substanceId: any; // Test
  allSubstances = [];
  substance;
  h;
  r;
  showHPhrase: boolean;
  showRPhrase: boolean;
  message: string;
  newSubstance: boolean;
  substanceinfo: any;
  editItem: boolean = false;
  searchH: string = "";
  searchR: string = "";
  filenames: any = [];
  substanceFile: any;
  loadingData:boolean=false;
  hygiene_limits: 0;
  no_hygiene_limits: 0;
  currentUser: any;
  nameBeforeEdit: string;
  continueWithWarning: boolean = true;

  hphrase = [
    "H200",
    "H201",
    "H202",
    "H203",
    "H204",
    "H205",
    "H220",
    "H221",
    "H222",
    "H223",
    "H224",
    "H225",
    "H226",
    "H227",
    "H228",
    "H229",
    "H230",
    "H231",
    "H240",
    "H241",
    "H242",
    "H250",
    "H251",
    "H252",
    "H260",
    "H261",
    "H270",
    "H271",
    "H272",
    "H280",
    "H281",
    "H290",
    "H300",
    "H301",
    "H302",
    "H303",
    "H304",
    "H310",
    "H311",
    "H312",
    "H314",
    "H315",
    "H317",
    "H318",
    "H319",
    "H330",
    "H331",
    "H332",
    "H334",
    "H335",
    "H336",
    "H340",
    "H341",
    "H350",
    "H351",
    "H360",
    "H361",
    "H362",
    "H370",
    "H371",
    "H372",
    "H373",
    "H400",
    "H410",
    "H411",
    "H412",
    "H413",
    "H420",
    "EUH001",
    "EUH014",
    "EUH018",
    "EUH019",
    "EUH029",
    "EUH031",
    "EUH032",
    "EUH044",
    "EUH059",
    "EUH066",
    "EUH070",
    "EUH071",
    "EUH201",
    "EUH201A",
    "EUH202",
    "EUH203",
    "EUH204",
    "EUH205",
    "EUH206",
    "EUH207",
    "EUH208",
    "EUH209",
    "EUH209A",
    "EUH210",
    "EUH212",
    "EUH380",
    "EUH381",
    "EUH401",
    "EUH430",
    "EUH431",
    "EUH440",
    "EUH441",
    "EUH450",
    "EUH451"
  ];

  rphrase = [
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
    "R7",
    "R8",
    "R9",
    "R10",
    "R11",
    "R12",
    "R13",
    "R14",
    "R15",
    "R16",
    "R17",
    "R18",
    "R19",
    "R20",
    "R21",
    "R22",
    "R23",
    "R24",
    "R25",
    "R26",
    "R27",
    "R28",
    "R29",
    "R30",
    "R31",
    "R32",
    "R33",
    "R34",
    "R35",
    "R36",
    "R37",
    "R38",
    "R39",
    "R40",
    "R41",
    "R42",
    "R43",
    "R44",
    "R45",
    "R46",
    "R47",
    "R48",
    "R49",
    "R50",
    "R51",
    "R52",
    "R53",
    "R54",
    "R55",
    "R56",
    "R57",
    "R58",
    "R59",
    "R60",
    "R61",
    "R62",
    "R63",
    "R64",
    "R65",
    "R66",
    "R67",
    "R68",
  ];

  filteredhphrase = this.hphrase;
  filteredrphrase = this.rphrase;

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private translate: TranslateService,
    private utilService: UtilService,
    private sareqService: SareqService,
    private substanceService: SubstanceService,
    private formBuilder:FormBuilder,
    private uploadService: FileUploadService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadingData=true;
    this.newSubstance = true;

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // If clicked on from Sareq, new substance,
    // didnt find a better solution
    if (this.previousrouter.routeConfig) {
      if(this.previousrouter.routeConfig.path=="addnewsubstance")
      {
        this.substanceId = "new";
      }
      else
      {
        this.previousrouter.params.pipe(take(1)).subscribe(params =>
        {
          this.substanceId = params['id'];
        });
      }
    }

    this.substanceService.getAll();
    this.substanceService.getSubstanceDataListener().pipe(take(1)).subscribe({
      next: substances => {
        if (substances != null) {
          this.allSubstances = substances;

          if (this.substanceId != 'new')
          {
            this.newSubstance = false;
            this.loadSubstance();
          }
          this.loadingData=false;
        }
      },
      error: error => {
        this.loadingData=false;
      }
    })

    this.substance = {
      name: "",
      cas: "",
      eg: "",
      sareq: "",
      hphrases: [],
      rphrases: [],
      list_amv_a: 0,
      list_amv_b: 0,
      list_chem_limit: 0,
      list_chem_prio_p: 0,
      list_chem_prio_u: 0,
      list_reach_14: 0,
      list_reach_17: 0,
      list_reach_candidate: 0,
      list_water_directive: 0,
      list_sin: 0,
      list_sll: 0,
      list_BASTA: 0,
      comment: '',
      hygiene_limits: null,
      no_hygiene_limits: null,
      hygiene_limits_comment:"",
      NGV_ppm:null,
      NGV_koncentration:null,
      KGV_ppm:null,
      KGV_koncentration:null,
      list_pop: 0,
      list_rohs: 0
    };
  }

  setHPhrases(hphrase) {
    this.substance.hphrases.push(hphrase);
  }

  removeHPhrases(hphrase) {
    const indexToRemove = this.substance.hphrases.findIndex(item => item === hphrase);

    if (indexToRemove !== -1) {
      this.substance.hphrases.splice(indexToRemove, 1);
    }
  }

  onSearchChangeHphrases(searchValue: string): void {
    this.filteredhphrase =  this.hphrase;

    if (searchValue !== "")
    {
        this.filteredhphrase = this.hphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  removeRPhrases(rphrase) {
    const indexToRemove = this.substance.r_phrases.findIndex(item => item === rphrase);

    if (indexToRemove !== -1) {
      this.substance.rphrases.splice(indexToRemove, 1);
    }
  }

  onSearchChangeRphrases(searchValue: string): void {
    this.filteredrphrase =  this.rphrase;

    if (searchValue !== "")
    {
        this.filteredrphrase = this.rphrase.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  loadSubstance() {
    this.substanceService.getSingle(this.substanceId);
    this.substanceService.getSubstanceDataListener().pipe(take(1))
      .subscribe({
        next: (thissubstances) => {
          if (thissubstances == null) {
          } else {
            if (thissubstances) {
              this.substance = thissubstances;
              this.nameBeforeEdit = this.substance.name;
              this.substance["sareq"] = this.substance.substance_number;
              this.sareqService.getRestriktionlist(this.substance);
              this.sareqService.getSareqDataListener().pipe(take(1))
                .subscribe({
                  next: (substanceinfo) => {
                    this.substanceinfo = substanceinfo[0];
                    this.getList();
                  },
                  error: (error) => {},
              });
            }
          }
        },
        error: (error) => {},
    });
  }

  cancel() {
    this.router.navigate(["/substances"]);
  }

  getRestriktionlist() {
    return new Observable((observer) => {
      this.sareqService.getRestriktionlist(this.substance);
      this.sareqService.getSareqDataListener().pipe(take(1))
        .subscribe({
          next: (substanceinfo) => {
            this.substanceinfo = substanceinfo[0];
            this.getList();
            observer.next();
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
          },
      });
    });
  }

  save() {
    this.getRestriktionlist().subscribe({
      next: () => {
        var found = true;
    if(this.substanceFile){
      found = false;
      if(this.substance.substancefiles){
        this.substance.substancefiles.forEach(element =>
        {
          if(element.file_name==this.substanceFile.name)
          {
            found = true;
          }
        })
      }
    }
    if(found==false){
      alert("Filuppladdning pågår");
      return;
    }

    this.loadingData=true;
    /*if (this.newSubstance) {
      if (this.substance.cas != "" && this.substance.eg != "" && iscas(this.substance.cas) && iseg(this.substance.eg)) {
        for (var n = 0; n < this.allSubstances.length; n++) {

          if (
            this.substance.cas == this.allSubstances[n].cas &&
            this.substance.eg == this.allSubstances[n].eg
          ) {
            Swal.fire({
              title:this.translate.instant("CAS_EG_EXISTS_HEADER_WARNING"),
              html:
              this.translate.instant("CAS_EG_EXISTS_MESSAGE_WARNING"),
              icon: "error",
              showCancelButton: true,
              confirmButtonText: this.translate.instant("OK"),
              cancelButtonText: this.translate.instant("ABORT"),
            }).then((result) => {
              if (result.isConfirmed) {
                // Continue to next step
              } else {
                this.loadingData=false;
                return;
              }
            });
          }
        }
      }
    }*/
    //this.substance = this.utilService.serialize(this.substance);
    if (this.newSubstance) {
      this.handleSubstanceCheck().then(() => {
      if (this.continueWithWarning == false) {
        this.continueWithWarning = true;
        return;
      }
      this.substanceService.checkIfSubstanceExists(this.substance);
      this.substanceService.getSubstanceDataCheckListener().pipe(take(1)).subscribe({
        next: (responce) => {
          let name = "";
          if (responce == null) { }
          else {
            for (let i = 0; i < responce.length; i++) {
              // For now Im ignoring the case of the name being part of the string and just care about when its exactly equal
              if ( responce[i].name.trim().toLowerCase() == this.substance.name.trim().toLowerCase() ) {
                name = responce[i].name;
                this.loadingData=false;
                break;  // This will exit the loop as soon as a match is found, might be more but I ignore them
              }
            }
          }
          if ( name != "") {
            Swal.fire({
              title:this.translate.instant("SUBSTANCE_EXISTS_HEADER_WARNING"),
              html:
                this.translate.instant("SUBSTANCE_EXISTS_MESSAGE_WARNING")+ "<br>" +
                this.translate.instant("SEARCHED_SUBSTANCE") + ": " + this.substance.name + "<br>" +
                this.translate.instant("FOUND_SUBSTANCE") + ": " + name,
              icon: "error",
              showCancelButton: true,
              confirmButtonText: this.translate.instant("OK"),
              cancelButtonText: this.translate.instant("ABORT"),
            }).then((result) => {
              if (result.isConfirmed) {
                this.substanceService.createSubstanceWithId(this.substance);
                this.loadingData=false;
              } else {
                this.loadingData=false; // This is when I press abort
                return;
              }
            });

          } else {
            this.substanceService.createSubstanceWithId(this.substance);  // This is when theres no match
            this.loadingData=false;
          }
        },
        error: error => {
          this.loadingData = false;
        }
        });
      }).catch((error) => {
        console.error('Error in handleSubstanceCheck:', error);
        this.loadingData = false;
      });
    } else if (this.substance.name != this.nameBeforeEdit) {
      this.substanceService.checkIfSubstanceExists(this.substance);
      this.substanceService.getSubstanceDataCheckListener().pipe(take(1)).subscribe({
        next: (responce) => {
          let name = "";
          if (responce == 'error') {
            Swal.fire(this.translate.instant("AN_ERROR_OCCURED"));
            return;
          }
          if (responce == null) { }
            else {
              for (let i = 0; i < responce.length; i++) {
                if ( responce[i].name.trim().toLowerCase() == this.substance.name.trim().toLowerCase() ) {
                  name = responce[i].name;
                  break;
                }
              }
            }
            if ( name != "") {
              Swal.fire({
                title:this.translate.instant("SUBSTANCE_EXISTS_HEADER_WARNING"),
                html:
                  this.translate.instant("SUBSTANCE_EXISTS_MESSAGE_WARNING")+ "<br>" +
                  this.translate.instant("SEARCHED_SUBSTANCE") + ": " + this.substance.name + "<br>" +
                  this.translate.instant("FOUND_SUBSTANCE") + ": " + name,
                icon: "error",
                showCancelButton: true,
                confirmButtonText: this.translate.instant("OK"),
                cancelButtonText: this.translate.instant("ABORT"),
              }).then((result) => {
                if (result.isConfirmed) {
                  this.substanceService.updateSubstanceWithId(this.substanceId, this.substance);
                  this.loadingData=false;
                } else {
                  this.loadingData=false;
                  return;
                }
              });
            } else {
              this.substanceService.updateSubstanceWithId(this.substanceId, this.substance);
              this.loadingData=false;
            }
          },
            error: error => {
              this.loadingData = false;
            }
          });
        } else {
          this.substanceService.updateSubstanceWithId(this.substanceId, this.substance);
          this.loadingData=false;
        }
      },
      error: (error) => {
        // Handle any errors from getRestriktionlist()
      },
    });

      }

  delete() {
    this.loadingData=true;
    Swal.fire({
      title: "Ta bort ämne?",
      text: "Är du säker på att du vill ta bort ämnet?",
      type: "warning",
      icon: "error",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: this.translate.instant("OK"),
      cancelButtonText: this.translate.instant("CANCEL"),
      closeOnConfirm: true,
    }).then((result) => {
      this.loadingData=false;
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed)
      {
        this.substanceService.deleteSubstanceWithId(this.substanceId);
        this.loadingData=false;
      } else if (result.isDenied)
      {
        this.loadingData=false;
      }
    });
  }

  // Didnt feel like repeating for every checkbox
  showCheckboxClicked(number) {
    switch (number) {
      case 0:
        this.substance.hygiene_limits = !this.substance.hygiene_limits;
        if(this.substance.hygiene_limits==true&&this.substance.no_hygiene_limits==true){this.substance.no_hygiene_limits=false}
        if (this.substance.hygiene_limits == false) {
          this.substance.hygiene_limits_comment = "";
        }
        break;
      case 1:
        this.substance.no_hygiene_limits = !this.substance.no_hygiene_limits;
        if(this.substance.no_hygiene_limits==true&&this.substance.hygiene_limits==true){this.substance.hygiene_limits=false}
        this.substance.ngv_ppm = null;
        this.substance.ngv_koncentration = null;
        this.substance.kgv_ppm = null;
        this.substance.kgv_koncentration = null;
        break;
      default:
        break;
    }
  }

  getList() {
    if (this.substanceinfo.items[0] == "1") {
      this.substance.list_amv_a = "1";
    }
    if (this.substanceinfo.items[1] == "1") {
      this.substance.list_amv_b = "1";
    }
    if (this.substanceinfo.items[2] == "1") {
      this.substance.list_reach_14 = "1";
    }
    if (this.substanceinfo.items[3] == "1") {
      this.substance.list_reach_17 = "1";
    }
    if (this.substanceinfo.items[4] == "1") {
      this.substance.list_reach_candidate = "1";
    }
    if (this.substanceinfo.items[5] == "1") {
      this.substance.list_chem_limit = "1";
    }
    if (this.substanceinfo.items[6] == "1") {
      this.substance.list_chem_prio_p = "1";
    }
    if (this.substanceinfo.items[7] == "1") {
      this.substance.list_chem_prio_u = "1";
    }
    if (this.substanceinfo.items[8] == "1") {
      this.substance.list_sin = "1";
    }
    if (this.substanceinfo.items[9] == "1") {
      this.substance.list_water_directive = "1";
    }
    if (this.substanceinfo.items[10] == "1") {
      this.substance.list_BASTA = "1";
    }
    if (this.substanceinfo.items[11] == "1") {
      this.substance.list_pop = "1";
    }
    if (this.substanceinfo.items[12] == "1") {
      this.substance.list_rohs = "1";
    }
  }

  incomingfile(event) {
    this.substanceFile = event.target.files[0];
    if (this.substanceFile != null) {
      this.uploadService.upload(this.substanceFile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.substanceFile = null;
          }
          else {
            if (!this.substance.substancefiles)
            {
              this.substance.substancefiles = [];
            }
            var file_id = {"file_id": result.filename, "file_name": this.substanceFile.name};
            this.substance.substancefiles.push(file_id);
            this.filenames.push(this.substanceFile.name);
          }
        },
        error: error => {
          this.substanceFile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  fileRemove(index){
    this.substance.substancefiles.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  logout() {
    this.authService.logout();
  }

  async handleSubstanceCheck() {
    for (let n = 0; n < this.allSubstances.length; n++) {
      if (
        this.substance.cas == this.allSubstances[n].cas &&
        this.substance.eg == this.allSubstances[n].eg
      ) {
        const result = await Swal.fire({
          title: this.translate.instant("CAS_EG_EXISTS_HEADER_WARNING"),
          html: this.translate.instant("CAS_EG_EXISTS_MESSAGE_WARNING"),
          icon: "error",
          showCancelButton: true,
          confirmButtonText: this.translate.instant("OK"),
          cancelButtonText: this.translate.instant("ABORT"),
        });

        if (result.isConfirmed) {
          this.continueWithWarning = true;
          return;
        } else {
          this.continueWithWarning = false;
          this.loadingData = false;
          return;
        }
      }
    }

    // Continue with the rest of your logic here if no match is found
    console.log("No match found, continue with the rest of the logic");
  }
}

function iscas(cas) {
  if (cas == "") {
    return true;
  }
  var casparts = cas.split("-");
  if (casparts.length != 3) {
    return false;
  }
  if (isNaN(casparts[0]) || casparts[0].length < 2 || casparts[0].length > 7) {
    return false;
  }
  if (isNaN(casparts[1]) || casparts[1].length != 2) {
    return false;
  }
  if (isNaN(casparts[2]) || casparts[2].length != 1) {
    return false;
  }
  var caspartsadded = casparts[0] + casparts[1];
  var i = 1;
  var sum = 0;
  for (var n = caspartsadded.length - 1; n > -1; n--) {
    sum += Number(caspartsadded[n] * i);
    i++;
  }
  if (sum % 10 != casparts[2]) {
    return false;
  }
  return true;
}



// Correct EG
function iseg(eg) {
  if (eg == "") {
    return true;
  }
  var egparts = eg.split("-");
  if (egparts.length != 3) {
    return false;
  }
  if (isNaN(egparts[0]) || egparts[0].length != 3) {
    return false;
  }
  if (isNaN(egparts[1]) || egparts[1].length != 3) {
    return false;
  }
  if (isNaN(egparts[2]) || egparts[2].length != 1) {
    return false;
  }
  var egpartsadded = egparts[0] + egparts[1];
  var i = 1;
  var sum = 0;
  for (var n = 0; n < 6; n++) {
    sum += Number(egpartsadded[n] * i);
    i++;
  }
  sum = sum - Math.floor(sum / 11) * 11;
  if (sum != egparts[2]) {
    return false;
  }
  return true;
}
