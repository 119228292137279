import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { UtilService } from "src/app/services/util.service";
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from "@ngx-translate/core";
import { take } from 'rxjs/operators';
import * as _ from "lodash";

@Component({
  selector: 'app-modal-add-inventory',
  templateUrl: './modal-add-inventory.component.html',
  styleUrls: ['./modal-add-inventory.component.css']
})
export class ModalAddInventoryComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  organisation: any;
  selectedDepartment: any;
  currentUser: any;
  departmentSearch: any = null;
  listOfDepartmentsDuplicate: any = [];
  allDepartments: boolean = false;
  loadingData: boolean = false;
  inventoryEdit: boolean = false;
  @Input() listOfDepartments;
  @Input() listAlldepartments;
  @Input() inventory;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private utilService: UtilService, private translate: TranslateService,
              private productService: ProductService, private authService: AuthService) { }

  ngOnInit(): void {

    this.loadingData = true;

    this.organisation = JSON.parse( localStorage.getItem( "rootOrganisation" ) );
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );

    let data = localStorage.getItem('currentUser', );
    if (data != null && data != undefined)
    {
      this.currentUser = JSON.parse(data);
    }

    if ( this.inventory == null ) {
      this.inventory = {
        start_date: "",
        end_date: "",
        issuer: this.currentUser.id,
        name: "INV",
        status: 0,
        comments: "",
        departments: [],
        organisation_id: this.organisation.id,
      };
      this.inventoryEdit = false;
    }
    else
    {
      this.inventoryEdit = true;
    }

    this.listOfDepartmentsDuplicate=_.cloneDeep(this.listOfDepartments);

    this.checkifDepartmentSelected(this.inventory.departments);
  }

  ngOnDestroy(): void {
  }

  departmentSearchItems() {
    this.listOfDepartments = this.listOfDepartmentsDuplicate;
    if ( this.departmentSearch == '' || this.departmentSearch == null ) {
      this.departmentSearch=null;
    }

    if( this.departmentSearch != null ){
      this.listOfDepartments = this.listOfDepartments.filter(o => o.name?.toLowerCase().indexOf( this.departmentSearch.toLowerCase() ) >-1 );
    }
  }

  checkifDepartmentSelected(departments){ 
    if ( departments == null || departments == "" ) {
      this.loadingData = false;
      return;
    }

    var departmentArray = departments.split(",");
    for ( var n=0; n<this.listOfDepartments.length; n++ ) {
      if ( departmentArray.includes(this.listOfDepartments[n].id) ) {
        this.listOfDepartments[n].selected = true;
      }
    }

    if ( this.listOfDepartments.length == departmentArray.length ) {
      this.allDepartments = true;
    }

    this.loadingData = false;
  }

  selectThisDepartment(department){
    if ( department=="all" ) {
      this.listOfDepartments = this.listOfDepartmentsDuplicate;
      if ( this.allDepartments == true ) {
        this.allDepartments = false;
      } else {
        this.allDepartments = true;
      }
      for ( var n=0; n<this.listOfDepartments.length; n++ ) {
        this.listOfDepartments[n].selected = this.allDepartments;
      }
      return;
    }

    if ( department.selected == true ) {
      department.selected = false;
    } else {
      department.selected = true;
    }

    // Force Angular to detect changes by replacing the array reference
    this.listOfDepartments = [...this.listOfDepartments];
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  save() {
    var departments = [];
    this.inventory.departmentArray = [];
    this.inventory.departments = '';
    for ( var n=0; n<this.listOfDepartments.length; n++ ) {
      if ( this.listOfDepartments[n].selected == true ) {
        departments.push(this.listOfDepartments[n].id);
        this.inventory.departmentArray.push(this.listOfDepartments[n]);
      }
    }

    if ( departments.length == 0 ) {
      Swal.fire(this.translate.instant("NO_DEPARTMENTS"), "", "error");
      return;
    }

    if ( this.inventory.start_date == "" || this.inventory.end_date == "" ) {
      Swal.fire(this.translate.instant("DATES_ARE_MISSING"), "", "error");
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to ignore time

    const startDate = new Date(this.inventory.start_date);
    const endDate = new Date(this.inventory.end_date);

    // Check if start date is before today
    if (startDate < today) {
      Swal.fire(
        this.translate.instant("INVENTORY"),
        this.translate.instant("START_DATE_CANNOT_BE_IN_PAST"),
        "error"
      );
      return; // Stop execution
    }

    // 🚨 Check if end date is before start date
    if (endDate < startDate) {
      Swal.fire(
        this.translate.instant("INVENTORY"),
        this.translate.instant("END_DATE_CANNOT_BE_BEFORE_START_DATE"),
        "error"
      );
      return; // ❌ Stop execution
    }

    this.inventory.departments = departments.join(",");

    this.passEntry.emit(this.inventory);
    this.activeModal.close(ModalAddInventoryComponent);
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalAddInventoryComponent);
  }
}
